<form [formGroup]="filtersForm">
  <angular2-multiselect
    fixSearchBug
    fixAccents
    id="cpvs-selector"
    class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
    name="sections"
    formControlName="sections"
    [data]="dropdownSectoresList"
    [(ngModel)]="selectedSectoresItems"
    [settings]="dropdownSectoresSettings"
    (onSelect)="onItemSelect($event)"
  >
    <!-- <c-badge>
      <ng-template let-item="item">
        <label style="color: #333; min-width: 150px; font-weight: 500" *ngIf="cpvIncludesDescription(item.itemName)">
          {{ item.itemName }}
        </label>
        <label style="color: #333; min-width: 150px; font-weight: 500" *ngIf="!cpvIncludesDescription(item.itemName)">
          {{ item.itemName }} <ng-container>- {{ (getCpvItem(item.id) | async)?.descripcion }}</ng-container>
        </label>
      </ng-template>
    </c-badge> -->
  </angular2-multiselect>
  <span>Escribe 3 dígitos (CPV) en el buscador para encontrar resultados</span>
</form>
