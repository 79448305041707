<div fxLayout="row" fxLayoutAlign="space-around center">
  <h3 fxFlex="96%">
    Fuentes de prensa escrita <span *ngIf="press.length > 0">- {{ press.length }}</span>
    <span style="margin-left: 5px" *ngIf="isAllPressItemsSelected">(TODAS)</span>
  </h3>
  <div fxFlex="4%">
    <button
      id="button-chips-autocomplete-toggle"
      type="button"
      mat-icon-button
      matTooltip="{{ !hidePress ? 'Mostar' : 'Ocultar' }} fuentes"
      (click)="hidePress = !hidePress"
    >
      <mat-icon color="primary">{{ !hidePress ? 'expand_more' : 'expand_less' }}</mat-icon>
    </button>
  </div>
</div>
<app-chips-autocomplete
  [label]="label"
  [hint]="hintPress"
  [list]="press_spiders"
  [selectedItems]="pressList"
  (itemsUpdatedEvent)="updatePressItems($event)"
  (allItemsSelected)="allPressItemsSelected($event)"
  [ngStyle]="{ display: hidePress ? 'block' : 'none' }"
></app-chips-autocomplete>
<mat-divider></mat-divider>
<div fxLayout="row" fxLayoutAlign="space-around center">
  <h3 fxFlex="96%">
    Fuentes de prensa digital <span *ngIf="digital.length > 0">- {{ digital.length }}</span>
    <span style="margin-left: 5px" *ngIf="isAllDigitalItemsSelected">(TODAS)</span>
  </h3>
  <div fxFlex="4%">
    <button
      id="button-chips-autocomplete-toggle"
      type="button"
      mat-icon-button
      matTooltip="{{ !hideDigital ? 'Mostar' : 'Ocultar' }} fuentes"
      (click)="hideDigital = !hideDigital"
    >
      <mat-icon color="primary">{{ !hideDigital ? 'expand_more' : 'expand_less' }}</mat-icon>
    </button>
  </div>
</div>
<app-chips-autocomplete
  [label]="label"
  [hint]="hintDigital"
  [list]="digital_spiders"
  [selectedItems]="digitalList"
  (itemsUpdatedEvent)="updateDigitalItems($event)"
  (allItemsSelected)="allDigitalItemsSelected($event)"
  [ngStyle]="{ display: hideDigital ? 'block' : 'none' }"
></app-chips-autocomplete>
