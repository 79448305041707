<h2>{{ mode == 'edit' ? 'Modificación de Alerta' : 'Creación de  Alerta' }}</h2>
<div class="input_container">
  <mat-form-field>
    <mat-select [(ngModel)]="period" placeholder="Frecuencia de generación de alerta">
      <mat-option *ngFor="let option of options_period" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="period > 30">
    <mat-select [(ngModel)]="instant_execution" placeholder="Momento de generación de la alerta">
      <mat-option *ngFor="let option of options_instant_execution" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<button [disabled]="CrearButton" *ngIf="period" (click)="addAlerta()" [disabled]="formisvalid" mat-button>
  {{ mode == 'edit' ? 'Modificar Alerta' : 'Crear Alerta' }}
</button>
<button
  [disabled]="DelButton"
  *ngIf="mode == 'edit'"
  type="submit"
  (click)="delAlerta()"
  [disabled]="formisvalid"
  mat-button
>
  Borrar Alerta
</button>
