import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  Injector,
} from '@angular/core';
import { RowLayoutComponent } from './row-layout.component';
import { WidgetConfig } from '../../models/widget-config';

@Component({
  selector: 'col-layout',
  templateUrl: './col-layout.component.html',
  styleUrls: ['./col-layout.component.css'],
})
export class ColLayoutComponent {
  @ViewChild('clayout', { read: ViewContainerRef }) target: ViewContainerRef;
  @Input() wconfig?;
  extra_layout: string = 'wrap';
  layout_alignment: string = 'start center';
  layout_gap: string = '0px';
  flex: number = 0;
  constructor(private cfr: ComponentFactoryResolver, public injector: Injector) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.wconfig && this.wconfig.config['layouts']) {
      this.generateLayout(this.wconfig.config['layouts']);
    } else if (this.wconfig && this.wconfig.config['widgets']) this.generateLayout(this.wconfig.config['widgets']);
  }

  createComponent(factory) {
    return this.target.createComponent(factory);
  }

  generateLayout(layouts: Array<any>) {
    if (layouts && layouts.length)
      layouts.forEach((layout) => {
        if (layout['width']) this.flex += layout['width'];
        layout = Object.assign(layout, { query: this.wconfig.config['query'] });
        let wc = { config: layout },
          factory = this.cfr.resolveComponentFactory((wc as WidgetConfig).getConstructor()),
          comp = this.createComponent(factory);
        comp.instance['wconfig'] = wc;
      });
  }
}
