import {
  Agenda,
  AgendaEvent,
  AgendaFilters,
  PaginatedResults,
  RegionMappings,
  SearchFilters,
  SearchType,
  SpiderMappings,
  TopicMappings,
  UIStatus,
  AppNotification,
} from '../models/app.state.model';

export class Login {
  static readonly type = '[Login] action';

  constructor(public payload: any) {}
}

export class ToggleSideMenu {
  static readonly type = '[ToggleSideMenu] action';

  constructor() {}
}

export class ShowInteresting {
  static readonly type = '[ShowInteresting] action';

  constructor() {}
}

export class HideInteresting {
  static readonly type = '[HideInteresting] action';

  constructor() {}
}

export class UnsetInteresting {
  static readonly type = '[UnsetInteresting] action';

  constructor() {}
}

export class ShowSideMenu {
  static readonly type = '[ShowSideMenu] action';

  constructor() {}
}

export class HideSideMenu {
  static readonly type = '[HideSideMenu] action';

  constructor() {}
}

export class AddTitle {
  static readonly type = '[AddTitle] action';

  constructor(public payload: { title: string; show_title: boolean }) {}
}

export class ShowTitle {
  static readonly type = '[ShowTitle] action';

  constructor() {}
}

export class HideTitle {
  static readonly type = '[HideTitle] action';

  constructor() {}
}

export class ShowDates {
  static readonly type = '[ShowDates] action';

  constructor() {}
}

export class HideDates {
  static readonly type = '[HideDates] action';

  constructor() {}
}

export class ShowSearchText {
  static readonly type = '[ShowSearchText] action';

  constructor() {}
}

export class HideSearchText {
  static readonly type = '[HideSearchText] action';

  constructor() {}
}

export class ToggleSubForm {
  static readonly type = '[ToggleSubForm] action';

  constructor() {}
}

export class ShowSubForm {
  static readonly type = '[ShowSubForm] action';

  constructor() {}
}

export class HideSubForm {
  static readonly type = '[HideSubForm] action';

  constructor() {}
}

export class UpdateSearchContent {
  static readonly type = '[UpdateSearchContent] action';

  constructor(public payload: { content: string }) {}
}

export class UpdateSearchFilters {
  static readonly type = '[UpdateSearchFilters] action';

  constructor(public payload: { filters: SearchFilters }) {}
}

export class ShowRegulatorioSubForm {
  static readonly type = '[ShowRegulatorioSubForm] action';

  constructor() {}
}

export class ToggleRegulatorioSubForm {
  static readonly type = '[ToggleRegulatorioSubForm] action';

  constructor() {}
}

export class HideRegulatorioSubForm {
  static readonly type = '[HideRegulatorioSubForm] action';

  constructor() {}
}

export class AddHistoryUrl {
  static readonly type = '[AddHistoryUrl] action';

  constructor(public payload: string) {}
}

export class AddSpiderMappings {
  static readonly type = '[AddSpiderMappings] action';

  constructor(public payload: SpiderMappings[]) {}
}

export class AddTopicMappings {
  static readonly type = '[AddTopicMappings] action';

  constructor(public payload: TopicMappings[]) {}
}

export class AddCategoriesTopics {
  static readonly type = '[AddCategoriesTopics] action';

  constructor(public payload: any[]) {}
}

export class UpdateUIStatus {
  static readonly type = '[UpdateUIStatus] action';

  constructor(public payload: UIStatus) {}
}

export class AddRegionMappings {
  static readonly type = '[AddRegionMappings] action';

  constructor(public payload: RegionMappings[]) {}
}

export class AddSelectedRegion {
  static readonly type = '[AddSelectedRegions] action';

  constructor(public payload: RegionMappings[]) {}
}

export class UpdateAgendaFilters {
  static readonly type = '[UpdateAgendaFilters] action';

  constructor(public payload: AgendaFilters) {}
}

export class GetAgendas {
  static readonly type = '[GetAgendas] action';

  constructor(public payload: { items: AgendaEvent[] }) {}
}

export class GetAgendasEvents {
  static readonly type = '[GetAgendasEvents] action';

  constructor(public payload: AgendaEvent[]) {}
}

export class ShowAgendasTutorial {
  static readonly type = '[ShowAgendasTutorial] action';

  constructor(public payload: boolean) {}
}

export class GetInitiativesNameMapping {
  static readonly type = '[GetInitiativesNameMapping] action';

  constructor(public payload: { key: string; value: string }[]) {}
}

export class SetSearchType {
  static readonly type = '[SetSearchType] action';

  constructor(public payload: SearchType) {}
}

export class SetNotifications {
  static readonly type = '[SetNotifications] action';

  constructor(public payload: AppNotification[]) {}
}

export class UpdateNotifications {
  static readonly type = '[UpdateNotifications] action';

  constructor(public payload: AppNotification) {}
}
