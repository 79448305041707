import { Initiative } from '../models/initiative.state.model';

export class ShowCalendar {
  static readonly type = '[ShowCalendar] action';

  constructor() {}
}

export class ShowList {
  static readonly type = '[ShowList] action';

  constructor() {}
}

export class GetInitiatives {
  static readonly type = '[GetInitiatives] action';

  constructor(public payload: Initiative[]) {}
}

export class GetOpenInitiatives {
  static readonly type = '[GetOpenInitiatives] action';

  constructor(public payload: { iniciativas: Initiative[]; total: number }) {}
}

export class FollowInitiative {
  static readonly type = '[FollowInitiative] action';

  constructor(public payload: Initiative[]) {}
}

export class SetInitiative {
  static readonly type = '[SetInitiative] action';

  constructor(public payload: Initiative) {}
}

export class AddInitiative {
  static readonly type = '[AddInitiative] action';

  constructor() {}
}

export class DeleteInitiative {
  static readonly type = '[DeleteInitiative] action';

  constructor() {}
}

export class AddCustomInitiative {
  static readonly type = '[AddCustomInitiative] action';

  constructor(public payload) {}
}

export class AddPastProcedure {
  static readonly type = '[AddPastProcedure] action';

  constructor(public payload) {}
}

export class AddDeadline {
  static readonly type = '[AddDeadline] action';

  constructor(public payload) {}
}

export class EditInitiative {
  static readonly type = '[EditInitiative] action';

  constructor(public payload: Initiative) {}
}

export class ToggleInitiativeMenuFilters {
  static readonly type = '[ToggleInitiativeMenuFilters] action';
}
