import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subvention, SubventionMinimi } from '@app/modules/ceoe/models/subvention';
import { SubventionsService } from '@app/modules/ceoe/services/subventions.service';
import {
  GetSubvention,
  GetSubventions,
  UpdateSubventionFavorite,
} from '@app/modules/ceoe/store/subventions/subventions.actions';
import { SubventionsStateService } from '@app/modules/ceoe/store/subventions/subventions.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-subventions-search-detail',
  templateUrl: './subventions-search-detail.component.html',
  styleUrls: ['./subventions-search-detail.component.scss'],
})
export class SubventionsSearchDetailComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  componentDestroyed$: Subject<boolean> = new Subject();
  id = '';
  subvention?: Subvention;
  minimisDataSource: MatTableDataSource<SubventionMinimi> = new MatTableDataSource();
  displayedColumns: string[] = [
    'numeroConvocatoria',
    'convocante',
    'reglamento',
    'sectorProducto',
    'instrumento',
    'fechaConcesion',
    'fechaRegistro',
    'beneficiario',
    'sectorActividad',
    'ayudaEquivalente',
  ];
  pageIndex = 0;
  pageSize = 30;
  loading = true;
  loadingMinimis = true;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<SubventionsSearchDetailComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public matData: number,
    private activatedRoute: ActivatedRoute,
    private actions: Actions,
    private router: Router,
    private location: Location,
    private subventionsStateService: SubventionsStateService,
    private subventionsService: SubventionsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    setTimeout(() => {
      this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetSubvention)).subscribe(() => {
        setTimeout(() => {
          this.loading = false;
          this.subvention = this.subventionsStateService.subvention;
          this.changeDetectorRef.detectChanges();
        }, 0);
      });

      this.actions
        .pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(UpdateSubventionFavorite))
        .subscribe(() => {
          setTimeout(() => {
            this.loading = false;
            this.subvention = this.subventionsStateService.subvention;
            this.changeDetectorRef.detectChanges();
          }, 0);
        });

      this.subventionsStateService.getSubvention(Number(this.matData));
    }, 0);
  }

  ngOnInit(): void {
    this.searchMinimis();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  searchMinimis(page?: number): void {
    this.loadingMinimis = true;

    if (!page) {
      this.pageIndex = 0;
    }

    this.subventionsService
      .getSubventionMinimis({ subventionId: this.matData, page: this.pageIndex + 1, size: this.pageSize })
      .subscribe((data) => {
        this.minimisDataSource = new MatTableDataSource(data.items);

        setTimeout(() => {
          if (this.paginator) {
            this.paginator.pageIndex = this.pageIndex;
            this.paginator.length = data.total;
          }

          this.loadingMinimis = false;
          this.changeDetectorRef.detectChanges();
        }, 0);
      });
  }

  minimisPageEvent(evt: PageEvent): void {
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.searchMinimis(this.pageIndex);
  }

  saveItem() {
    if (this.subvention.favorito) {
      this.subventionsStateService.updateSubventionFavoriteeDelete(this.subvention.id);
    } else {
      this.subventionsStateService.updateSubventionFavoriteAdd(this.subvention.id);
    }
  }

  return(): void {
    this.location.back();
  }

  downloadSummary() {
    //
  }
}
