import { Component, OnInit, Inject } from '@angular/core';
import { MagazineStateService } from '@app/modules/documents/store/magazine/magazine.service';
import { DraftStateService } from '@app/modules/documents/store/draft/draft.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentStateService } from '@app/modules/documents/store/report/documents.service';

@Component({
  selector: 'app-doc-to-draft',
  templateUrl: './doc-to-draft.component.html',
  styleUrls: ['./doc-to-draft.component.less'],
})
export class DocToDraftComponent implements OnInit {
  magazineID: number;
  draftID: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public magazineService: MagazineStateService,
    private _draftStateService: DraftStateService,
    private _documentStateService: DocumentStateService
  ) {}

  ngOnInit(): void {
    this.magazineService.getMagazines();
    this._documentStateService.getDocument(this.data.docID);
  }

  updateDraft($event) {
    this.magazineID = $event.option.value.magazine;
    this.draftID = $event.option.value.draft;
    this._draftStateService.getDraft(this.magazineID, this.draftID);
  }

  addDocument() {
    let newDraft = JSON.parse(JSON.stringify(this._draftStateService.selectedDraft));
    newDraft.data.push({
      category: 'Brainy',
      docs: [
        {
          id: this._documentStateService.document.docId,
          title: this._documentStateService.document.titleParsed,
          source: this._documentStateService.document.spiderId.toString(),
          abstract: this._documentStateService.document.abstract,
          date: this._documentStateService.document.dateParsed,
        },
      ],
    });

    this._draftStateService.editDraft(newDraft);
  }
}
