<h1 mat-dialog-title>Editar alerta</h1>
<div mat-dialog-content>
  <form [formGroup]="filtersForm">
    <mat-form-field>
      <mat-label>Título</mat-label>
      <input matInput formControlName="titulo" placeholder="Título de la alerta" />
    </mat-form-field>
    <span *ngIf="filters">
      <div class="section" *ngFor="let filter of filters.filters; let i = index" [formArrayName]="filter.attr">
        <span *ngIf="i < filters.filters.length - 2">
          <span
            *ngIf="filter.attr !== 'comunidades' && filter.attr !== 'provincias' && filter.attr !== 'sector_economico'"
          >
            <p class="title">{{ filter.seccion }}</p>
            <div
              class="checkboxes"
              *ngFor="let control of filtersForm.controls[filter.attr].controls"
              [formGroup]="castToFormGroup(control)"
            >
              <input type="checkbox" formControlName="checked" />
              <mat-label>{{ control.get('label')?.value }}</mat-label>
            </div>
          </span>
        </span>
      </div>

      <span class="section section-dropdowns">
        <div *ngFor="let filter of filters.filters; let i = index">
          <span *ngIf="i < filters.filters.length - 2">
            <span *ngIf="filter.attr === 'comunidades'">
              <angular2-multiselect
                fixSearchBug
                fixAccents
                class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
                name="comunidades"
                [formControlName]="filter.attr + 'Dropdown'"
                [data]="dropdownComunidadesList"
                [(ngModel)]="selectedComunidadesItems"
                [settings]="dropdownComunidadesSettings"
              ></angular2-multiselect>
            </span>
            <span *ngIf="filter.attr === 'provincias'">
              <angular2-multiselect
                fixSearchBug
                fixAccents
                class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
                name="provincias"
                [formControlName]="filter.attr + 'Dropdown'"
                [data]="dropdownProvinciasList"
                [(ngModel)]="selectedProvinciasItems"
                [settings]="dropdownProvinciasSettings"
              ></angular2-multiselect>
            </span>
            <span *ngIf="filter.attr === 'sector_economico'">
              <angular2-multiselect
                fixSearchBug
                fixAccents
                class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
                name="sector_economico"
                [formControlName]="filter.attr + 'Dropdown'"
                [data]="dropdownSectorEconomicoList"
                [(ngModel)]="selectedSectorEconomicoItems"
                [settings]="dropdownSectorEconomicoSettings"
              ></angular2-multiselect>
            </span>
          </span>
        </div>
      </span>

      <div class="section">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>Filtros avanzados</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="section" *ngFor="let filter of filters.filters; let i = index" [formArrayName]="filter.attr">
              <span *ngIf="i >= filters.filters.length - 2">
                <p class="title">{{ filter.seccion }}</p>
                <div
                  class="checkboxes"
                  *ngFor="let control of filtersForm.controls[filter.attr].controls"
                  [formGroup]="castToFormGroup(control)"
                >
                  <input type="checkbox" formControlName="checked" />
                  <mat-label>{{ control.get('label')?.value }}</mat-label>
                </div>
              </span>
            </div>
            <mat-form-field class="section">
              <mat-label>Fecha de publicación</mat-label>
              <mat-date-range-input [formGroup]="filtersPublishmentDateRangeForm" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="dd/mm/aaaa" />
                <input matEndDate formControlName="end" placeholder="dd/mm/aaaa" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <mat-form-field class="section">
              <mat-label>Fecha de finalización del periodo de solicitud</mat-label>
              <mat-date-range-input [formGroup]="filtersFinalizationDateRangeForm" [rangePicker]="picker2">
                <input matStartDate formControlName="start" placeholder="dd/mm/aaaa" />
                <input matEndDate formControlName="end" placeholder="dd/mm/aaaa" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-date-range-picker #picker2></mat-date-range-picker>
            </mat-form-field>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </span>
  </form>
</div>
<div mat-dialog-actions class="actions">
  <button mat-button [mat-dialog-close]="true">Cancelar</button>
  <button mat-flat-button cdkFocusInitial color="primary" [disabled]="loading" (click)="save()">
    <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
    Guardar
  </button>
</div>
