import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { SubventionsState } from './subventions.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Subvention, SubventionsFilters, SubventionsGetRequest, SubventionsGetResponse } from '../../models/subvention';
import { SubventionsService } from '../../services/subventions.service';
import {
  DeleteAlert,
  GetSubventionsAlerts,
  GetFilters,
  GetSubvention,
  GetSubventions,
  GetSubventionsFavorites,
  PostAlert,
  PutAlert,
  ToggleSubventionsMenu,
  UpdateSubventionFavorite,
  UpdateSubventionsMenuFilters,
} from './subventions.actions';

@Injectable({ providedIn: 'root' })
export class SubventionsStateService {
  @Select(SubventionsState.subventions)
  public subventions$: Observable<SubventionsGetResponse>;

  @SelectSnapshot(SubventionsState.subventions)
  public subventions: SubventionsGetResponse;

  @Select(SubventionsState.alerts)
  public alerts$: Observable<SubventionsGetResponse>;

  @SelectSnapshot(SubventionsState.alerts)
  public alerts: SubventionsGetResponse;

  @Select(SubventionsState.favorites)
  public favorites$: Observable<SubventionsGetResponse>;

  @SelectSnapshot(SubventionsState.favorites)
  public favorites: SubventionsGetResponse;

  @Select(SubventionsState.subvention)
  public subvention$: Observable<Subvention>;

  @SelectSnapshot(SubventionsState.subvention)
  public subvention: Subvention;

  @Select(SubventionsState.openedMenu)
  public openedMenu$: Observable<boolean>;

  @SelectSnapshot(SubventionsState.openedMenu)
  public openedMenu: boolean;

  @Select(SubventionsState.filters)
  public filters$: Observable<SubventionsFilters>;

  @SelectSnapshot(SubventionsState.filters)
  public filters: SubventionsFilters;

  constructor(private _snackbarStateService: SnackbarStateService, private subventionsService: SubventionsService) {}

  @Dispatch()
  public getSubventions = (request: SubventionsGetRequest) => {
    return this.subventionsService.getSubventions(request).pipe(
      map((response) => {
        return new GetSubventions(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getSubventionsAlerts = (request: SubventionsGetRequest) => {
    return this.subventionsService.getAlerts(request).pipe(
      map((response) => {
        return new GetSubventionsAlerts(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public postAlert = (request: SubventionsGetRequest) => {
    return this.subventionsService.postAlert(request).pipe(
      map((response) => {
        return new PostAlert(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public deleteAlert = (request: number) => {
    return this.subventionsService.deleteAlert(request).pipe(
      map(() => {
        return new DeleteAlert(request);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public putAlert = (request: SubventionsGetRequest, id: number) => {
    return this.subventionsService.putAlert(request, id).pipe(
      map((response) => {
        return new PutAlert(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getSubventionsFavorites = (request: SubventionsGetRequest) => {
    return this.subventionsService.getSubventionsFavorites(request).pipe(
      map((response) => {
        return new GetSubventionsFavorites(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getSubvention = (request: number) => {
    return this.subventionsService.getSubvention(request).pipe(
      map((response) => {
        return new GetSubvention(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getFilters = () => {
    return this.subventionsService.getFilters().pipe(
      map((response) => {
        return new GetFilters(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public updateSubventionFavoriteAdd = (request: number) => {
    return this.subventionsService.updateSubventionFavoriteAdd(request).pipe(
      map(() => {
        return new UpdateSubventionFavorite(request);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public updateSubventionFavoriteeDelete = (request: number) => {
    return this.subventionsService.updateSubventionFavoriteDelete(request).pipe(
      map(() => {
        return new UpdateSubventionFavorite(request);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public toggleSubventionsMenu = () => {
    return new ToggleSubventionsMenu();
  };

  @Dispatch()
  public updateSubventionsMenuFilters = (request: SubventionsFilters) => {
    return new UpdateSubventionsMenuFilters(request);
  };
}
