import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Cpv } from '@app/modules/ceoe/models/tender';
import { TendersService } from '@app/modules/ceoe/services/tenders.service';
import { GetCpv, GetCpvs } from '@app/modules/ceoe/store/tenders/tenders.actions';
import { TendersStateService } from '@app/modules/ceoe/store/tenders/tenders.service';
import { UtilsService } from '@app/services/utils.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-tenders-search-filters-cpvs',
  templateUrl: './tenders-search-filters-cpvs.component.html',
  styleUrls: ['./tenders-search-filters-cpvs.component.scss'],
})
export class TendersSearchFiltersCPVSComponent implements OnInit, OnChanges {
  @Input()
  selectedSectoresInput = [];

  @Output()
  selectedSectoresOutput: EventEmitter<string[]> = new EventEmitter<string[]>();

  filtersForm: FormGroup;
  filtersDateRangeForm: FormGroup;
  loading = false;

  dropdownSectoresList = [];
  selectedSectoresItems = [];
  dropdownSectoresSettings = {
    text: 'Sectores',
    noDataLabel: '',
    searchPlaceholderText: 'Buscar...',
    enableSearchFilter: true,
    badgeShowLimit: 5,
    showCheckbox: true,
  };

  constructor(
    public tendersStateService: TendersStateService,
    public tendersService: TendersService,
    private fb: FormBuilder,
    private actions: Actions,
    private utilsService: UtilsService,
    private cdr: ChangeDetectorRef
  ) {
    this.filtersForm = this.fb.group({
      sections: [[]],
    });

    this.filtersForm.controls.sections.valueChanges.subscribe((v) => {
      this.selectedSectoresOutput.emit(v.map((v) => v.itemName));
    });

    setTimeout(() => {
      this.setSearchInputListener();
    }, 0);
  }

  ngOnInit(): void {
    // this.populateSections();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedSectoresInput &&
      changes.selectedSectoresInput.currentValue &&
      changes.selectedSectoresInput.currentValue.length > 0
    ) {
      this.filtersForm.controls.sections.setValue(
        (changes.selectedSectoresInput.currentValue as string[]).map((cv) => {
          return {
            id: Number(cv.split(' - ')[0]),
            itemName: cv,
            name: this.utilsService.removeDiacritics(cv),
          };
        })
      );
    } else {
      this.filtersForm.controls.sections.setValue([]);
    }
  }

  populateSections(): void {
    this.actions.pipe(ofActionSuccessful(GetCpvs)).subscribe(() => {
      this.setDropdownItems();
    });

    this.tendersStateService.getCpvs();
  }

  setDropdownItems(): void {
    // this.dropdownSectoresList = this.tendersStateService.cpvs.map((c) => {
    //   return {
    //     id: Number(c.code),
    //     itemName: c.code,
    //     name: this.utilsService.removeDiacritics(c.descripcion),
    //   };
    // });
  }

  setSearchInputListener(): void {
    const inputElement: any = document.querySelector('#cpvs-selector input.c-input');
    inputElement.setAttribute('type', 'number');

    const inputObservable = fromEvent(inputElement, 'input');

    inputObservable.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      if (inputElement.value && inputElement.value.length >= 3) {
        this.setLoader();
        this.getCpvItemsDetail(inputElement.value);
      }
    });
  }

  onItemSelect(item: any): void {
    // this.getCpvItemsDetail(item);
  }

  getCpvItemsDetail(code: string): void {
    this.tendersService.getCpvsDetailItems({ code }).subscribe((data) => {
      // (document.querySelector('#cpvs-selector input.c-input') as HTMLInputElement).value = '';
      this.removeLoader();
      this.cdr.detectChanges();

      this.dropdownSectoresList = data.items.map((i) => {
        return {
          id: Number(i.code),
          itemName: `${i.code} - ${i.descripcion}`,
          name: this.utilsService.removeDiacritics(i.descripcion),
        };
      });
    });
  }

  setLoader(): void {
    this.dropdownSectoresList = [];
    const container = document.createElement('div');
    const loader = document.createElement('div');
    const text = document.createElement('p');
    container.appendChild(loader);
    container.appendChild(text);
    container.className = 'container-loader';
    container.style.display = 'flex';
    container.style.alignItems = 'flex-end';
    container.style.gap = '2px';
    loader.className = 'custom-pure-loader ';
    text.textContent = 'Cargando cpvs...';
    const parentElement: any = document.querySelector('#cpvs-selector .lazyContainer');
    parentElement.insertBefore(container, parentElement.firstChild);
  }

  removeLoader(): void {
    document.querySelector('#cpvs-selector .lazyContainer .container-loader')?.remove();
    this.setDropdownItems();

    var clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    document.querySelector('#cpvs-selector input.c-input').dispatchEvent(clickEvent);
    this.cdr.detectChanges();
  }

  getCpvItem(code: string): Observable<{ code: string; descripcion: string } | undefined> {
    return this.tendersService.getCpvsDetailItems({ code }).pipe(
      map((data) => {
        return data.items.find((item) => item.code === code);
      })
    );
  }

  cpvIncludesDescription(text: string): boolean {
    return text.includes(' - ');
  }
}
