import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-business-statistics-section-detail-table',
  templateUrl: './business-statistics-section-detail-table.component.html',
  styleUrls: ['./business-statistics-section-detail-table.component.scss'],
})
export class BusinessStatisticsSectionDetailTableComponent implements OnChanges {
  @Input() data?: any;

  displayedColumns = ['Frecuencia'];
  tableData = [];
  showTable = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data.currentValue) {
      this.setTableData();
      this.showTable = true;
    } else {
      this.showTable = false;
    }
  }

  setTableData(): void {
    this.tableData = [];
    this.displayedColumns = ['Frecuencia'];

    this.data.data.forEach((d) => {
      d.serie.forEach((s) => {
        const indexItem = this.tableData.findIndex((d) => d.name === s.name);

        if (indexItem === -1) {
          this.tableData.push({
            name: s.name,
            values: [s.valor],
          });
        } else {
          this.tableData[indexItem].values.push(s.valor);
        }
      });
    });

    this.displayedColumns.push(...this.getColumnsTitles());
  }

  getColumnsTitles(): string[] {
    return this.data.data.map((d) => d.title).reduce((acc, val) => acc.concat(val), []);
  }

  downloadExcel(): void {
    if (this.tableData && this.tableData.length > 0) {
      this.downloadFile(
        new Blob([`\ufeff${this.jsonToCSV()}`], { type: 'text/csv;charset=utf-8;' }),
        `${this.data.indicator}_estadísticas.csv`
      );
    }
  }

  jsonToCSV(): string {
    const csvData: string[] = [];

    csvData.push(this.displayedColumns.join(';'));

    this.tableData.forEach((td, i) => {
      csvData.push(`${td.name};${td.values.join(';')}`);
    });

    return csvData.join('\n');
  }

  downloadFile(data: Blob, filename: string): void {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
