import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AppStatusService {
  public page_change: EventEmitter<any> = new EventEmitter();
  public query_change: EventEmitter<any> = new EventEmitter();
  public search_finished: EventEmitter<any> = new EventEmitter();
  public sector_reports_search_launched: EventEmitter<any> = new EventEmitter();
  public panel_open: EventEmitter<any> = new EventEmitter();
  public paneldoc_open: EventEmitter<any> = new EventEmitter();
  public panel_close: EventEmitter<any> = new EventEmitter();
  public paneldoc_close: EventEmitter<any> = new EventEmitter();
  public search_launch: EventEmitter<any> = new EventEmitter();
  public favorite_save: EventEmitter<any> = new EventEmitter();
  public favorite_delete: EventEmitter<any> = new EventEmitter();
  public reset_search: EventEmitter<any> = new EventEmitter();
  public show_subform: EventEmitter<any> = new EventEmitter();
  public brain_click: EventEmitter<any> = new EventEmitter();
  public complete_loadconfig: EventEmitter<any> = new EventEmitter();
  public map_ready: EventEmitter<any> = new EventEmitter();
  public change_layer: EventEmitter<any> = new EventEmitter();
  public nav_toggle: EventEmitter<any> = new EventEmitter();
  public nav_close: EventEmitter<any> = new EventEmitter();
  public open_map: EventEmitter<any> = new EventEmitter();
  public map_change: EventEmitter<any> = new EventEmitter();
  public add_layer: EventEmitter<any> = new EventEmitter();

  public is_panel_opened: boolean = false;

  public current_page: string = '';
  public current_query: Object = {};
  public dateParsed_start = moment().startOf('month').toISOString();
  public dateParsed_end = moment().endOf('month').toISOString();

  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.page_change.emit(event.url);
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
    this.page_change.subscribe((page) => {
      this.current_page = page;
    });
    this.query_change.subscribe((query) => {
      this.current_query = query;
    });
    this.panel_open.subscribe(() => {
      this.is_panel_opened = true;
    });

    this.panel_close.subscribe(() => {
      this.is_panel_opened = false;
    });

    this.search_launch.subscribe((args) => {
      this.dateParsed_start = args.start ? args.start : this.dateParsed_start;
      this.dateParsed_end = args.end ? args.end : this.dateParsed_end;
    });
  }
}
