import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { DateRange } from '@angular/material/datepicker';
import { IndicadorService } from '@app/modules/estadisticas/services/indicador.service';
import moment from 'moment';

@Component({
  selector: 'app-business-statistics-section-detail-search',
  templateUrl: './business-statistics-section-detail-search.component.html',
  styleUrls: ['./business-statistics-section-detail-search.component.scss'],
})
export class BusinessStatisticsSectionDetailSearchComponent implements OnInit {
  @Input() themeId: number;
  @Input() categoryId: number;

  @Output() sendData$: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  indicatorsOptions: any[] = [];
  indicatorsOptionsFiltered: any[] = [];
  loadingIndicators = true;
  ambitsOptions: any[] = [];
  loadingAmbits = true;
  difusionOptions: any[] = [];
  loadingDifusions = true;
  periodsOptions: any[] = ['Mensual', 'Trimestral', 'Anual'];
  periodsOptionsValid: any[] = [];
  loadingPeriods = true;
  showCalendarStart = true;
  showCalendarEnd = true;
  minDate;
  maxDate;
  loadingData = false;

  constructor(
    private indicadorService: IndicadorService,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('es-ES');
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
    this.form = this.fb.group({
      indicator: [null, [Validators.required]],
      indicatorsSearch: ['', []],
      ambit: [null, [Validators.required]],
      difusion: [null, [Validators.required]],
      period: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
    });

    this.form.controls.indicator.valueChanges.subscribe((indicator: string) => {
      this.form.patchValue({
        ambit: null,
        difusion: null,
        period: null,
        startDate: null,
        endDate: null,
      });

      this.sendData$.emit(undefined);

      this.loadingAmbits = true;
      this.loadingDifusions = true;
      this.loadingPeriods = true;

      this.getProperties();
    });

    this.form.controls.indicatorsSearch.valueChanges.subscribe((search: string) => {
      if (search) {
        this.indicatorsOptionsFiltered = this.indicatorsOptions.filter((io) =>
          io.toLowerCase().includes(search.toLowerCase())
        );
      } else {
        this.indicatorsOptionsFiltered = this.indicatorsOptions;
      }
    });
  }

  ngOnInit(): void {
    this.getIndicators();
  }

  getIndicators(): void {
    const request = {
      categoria: this.categoryId,
    };

    this.indicadorService.getBusinessStatisticsIndicators(request).subscribe((data) => {
      this.indicatorsOptions = data.map((d) => d.indicador);
      this.indicatorsOptionsFiltered = this.indicatorsOptions;
      this.loadingIndicators = false;
    });
  }

  getProperties(): void {
    const indicator = this.indicatorsOptions.find((io) => io === this.form.controls.indicator.value);

    const request = {
      indicador: indicator,
    };

    this.indicadorService.getBusinessStatisticsProperties(request).subscribe((data) => {
      this.ambitsOptions = data.ambito;
      this.loadingAmbits = false;

      if (this.ambitsOptions.length === 1) {
        this.form.controls.ambit.setValue(this.ambitsOptions[0]);
      }

      this.difusionOptions = data.difusion;
      this.loadingDifusions = false;

      if (this.difusionOptions.length === 1) {
        this.form.controls.difusion.setValue(this.difusionOptions[0]);
      }

      this.periodsOptionsValid = data.periodicidad;
      this.loadingPeriods = false;

      if (this.periodsOptionsValid.length === 1) {
        this.form.controls.period.setValue(this.periodsOptionsValid[0]);
      }

      this.minDate = new Date(data.min_date);
      this.maxDate = new Date(data.max_date);

      this.form.controls.startDate.setValue(this.minDate);
      this.form.controls.endDate.setValue(this.maxDate);

      this.search(false);

      setTimeout(() => {
        this.hideCalendarPeriodControls();
      }, 0);
    });
  }

  search(touchForm = true): void {
    if (touchForm) {
      this.form.markAllAsTouched();
    }

    if (this.form.valid) {
      this.loadingData = true;
      this.sendData$.emit(undefined);

      const request = {
        id_tematica: this.themeId,
        id_categoria: this.categoryId,
        indicador: this.form.controls.indicator.value,
        ambito: this.form.controls.ambit.value,
        difusion: this.form.controls.difusion.value,
        periodicidad: this.form.controls.period.value,
        min_date: moment(this.form.controls.startDate.value).format('YYYY-MM-DD HH:mm:ss'),
        max_date: moment(this.form.controls.endDate.value).format('YYYY-MM-DD HH:mm:ss'),
      };

      this.indicadorService.getBusinessStatisticsData(request).subscribe((data) => {
        this.sendData$.emit(data);
        this.loadingData = false;
      });
    }
  }

  clearFilters(): void {
    this.form.patchValue({
      indicator: '',
      indicatorsSearch: '',
      ambit: [],
      difusion: null,
      period: null,
      startDate: this.minDate,
      endDate: this.maxDate,
    });
  }

  indicatorsFilteredIncludes(indicator: any): boolean {
    return this.indicatorsOptionsFiltered.find((iof) => iof === indicator) != undefined;
  }

  onPeriodSelectedDateStart(date: Date): void {
    date.setMonth(date.getMonth());
    date.setFullYear(date.getFullYear());
    date.setDate(1);

    this.form.controls.startDate.setValue(date);

    this.refreshCalendarStart();
    this.refreshCalendarEnd();
  }

  onPeriodSelectedDateEnd(date: Date): void {
    date.setMonth(date.getMonth());
    date.setFullYear(date.getFullYear());
    date.setDate(1);

    this.form.controls.endDate.setValue(date);

    this.refreshCalendarStart();
    this.refreshCalendarEnd();
  }

  refreshCalendarStart(): void {
    this.showCalendarStart = false;

    setTimeout(() => {
      this.showCalendarStart = true;
      setTimeout(() => {
        this.hideCalendarPeriodControls();
      }, 0);
    }, 0);
  }

  refreshCalendarEnd(): void {
    this.showCalendarEnd = false;

    setTimeout(() => {
      this.showCalendarEnd = true;
      setTimeout(() => {
        this.hideCalendarPeriodControls();
      }, 0);
    }, 0);
  }

  hideCalendarPeriodControls(): void {
    const elements: NodeListOf<HTMLDivElement> = document.querySelectorAll('mat-calendar .mat-calendar-period-button');

    if (elements) {
      elements.forEach((div: HTMLDivElement) => {
        div.style.display = 'none';
      });
    }
  }
}
