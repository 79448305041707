import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment, URLQA } from '@env/environment';
import { DocumentPayload } from '@app/modules/documents/store/models/document.state.model';
import { QueryParams } from '@app/modules/main/components/search/services/search.service';

export interface SearchServiceResult {
  hits: any[];
  total: number;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  static readonly itemUrl = environment.documentItem;
  static readonly itemsUrl = environment.documentSearchUrl;
  static readonly qaEndpointsUrl = URLQA + 'documents/search/';
  static readonly qaEndpointDocumentUrl = URLQA + 'documents/';

  constructor(private http: HttpClient) {}

  private mapSources(queryParams: QueryParams): QueryParams {
    let request: any = {};
    Object.entries(queryParams).forEach(([key, value]) => {
      if (key == 'spiderIds' || key == 'docTopics' || key == 'docTopicsRegion')
        request[key] = value.map((item) => item.id);
      else request[key] = value;
    });

    return request;
  }

  public getAll(
    queryParams: QueryParams,
    sort?: string,
    order?: string,
    page?: number,
    types?: string[]
  ): Observable<SearchServiceResult> {
    let requestParams: QueryParams = this.mapSources(queryParams);

    if (sort != null) {
      requestParams.sort = [order === 'desc' ? '-' + sort : sort];
      requestParams.from = page * requestParams.size;
    }

    if (order === '') delete requestParams.sort;

    if (isNaN(requestParams.from)) requestParams.from = 0;

    requestParams.docTypes = types;
    return this.http.post<SearchServiceResult>(
      environment.documentSearchUrl,
      this.mapSources(requestParams),
      httpOptions
    );
  }

  public getDocuments(payload: DocumentPayload): Observable<SearchServiceResult> {
    return this.http.post<SearchServiceResult>(SearchService.itemsUrl, payload, httpOptions);
  }

  public getDocument(docId: string): Observable<any> {
    return this.http.get<any>(SearchService.itemUrl + docId);
  }

  public request(queryConfig: any): Observable<any> {
    return this.http.post(environment.documentSearchUrl, queryConfig, httpOptions);
  }
}
