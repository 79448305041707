import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Subvention, SubventionsFilters, SubventionsGetResponse, SubventionsStateModel } from '../../models/subvention';
import {
  DeleteAlert,
  GetSubventionsAlerts,
  GetFilters,
  GetSubvention,
  GetSubventions,
  GetSubventionsFavorites,
  PostAlert,
  PutAlert,
  ToggleSubventionsMenu,
  UpdateSubventionFavorite,
  UpdateSubventionsMenuFilters,
} from './subventions.actions';

@Injectable({
  providedIn: 'root',
})
@State<SubventionsStateModel>({
  name: 'subventions',
  defaults: {
    subventions: {
      items: [],
      page: 0,
      size: 0,
      total: 0,
      total_pages: 0,
    },
    favorites: {
      items: [],
      page: 0,
      size: 0,
      total: 0,
      total_pages: 0,
    },
    alerts: {
      items: [],
      page: 0,
      size: 0,
      total: 0,
      total_pages: 0,
    },
    filters: {
      filters: undefined,
      selectedFilters: {},
      openedMenu: true,
    },
    openedMenu: true,
  },
})
export class SubventionsState {
  @Selector()
  static subventions(state: SubventionsStateModel): SubventionsGetResponse {
    return state.subventions;
  }

  @Selector()
  static alerts(state: SubventionsStateModel): SubventionsGetResponse {
    return state.alerts;
  }

  @Selector()
  static favorites(state: SubventionsStateModel): SubventionsGetResponse {
    return state.favorites;
  }

  @Selector()
  static subvention(state: SubventionsStateModel): Subvention {
    return state.subvention;
  }

  @Selector()
  static openedMenu(state: SubventionsStateModel): boolean {
    return state.openedMenu;
  }

  @Selector()
  static filters(state: SubventionsStateModel): SubventionsFilters {
    return state.filters;
  }

  @Action(GetSubventions)
  getSubventions(
    { getState, patchState }: StateContext<SubventionsStateModel>,
    response: { payload: SubventionsGetResponse }
  ) {
    const state = getState();
    let subventions = state.subventions;
    subventions = response.payload;
    patchState({ subventions });
  }

  @Action(GetSubventionsAlerts)
  getSubventionsAlerts(
    { getState, patchState }: StateContext<SubventionsStateModel>,
    response: { payload: SubventionsGetResponse }
  ) {
    const state = getState();
    let alerts = state.alerts;
    alerts = response.payload;
    patchState({ alerts });
  }

  @Action(DeleteAlert)
  deleteAlert({ getState, patchState }: StateContext<SubventionsStateModel>, response: { payload: number }) {
    const state = JSON.parse(JSON.stringify(getState()));

    const alerts = state.alerts as SubventionsGetResponse;
    const alertIndex = alerts.items.findIndex((i) => i.id === response.payload);

    if (alertIndex !== -1) {
      alerts.items.splice(alertIndex, 1);
    }

    patchState({ alerts });
  }

  @Action(PostAlert)
  postAlert({ getState, patchState }: StateContext<SubventionsStateModel>, response: { payload: Subvention }) {
    const state = JSON.parse(JSON.stringify(getState()));
    const alerts = state.alerts as SubventionsGetResponse;
    alerts.items.push(response.payload);
    patchState({ alerts });
  }

  @Action(PutAlert)
  putAlert({ getState, patchState }: StateContext<SubventionsStateModel>, response: { payload: Subvention }) {
    const state = JSON.parse(JSON.stringify(getState()));
    const alerts = state.alerts as SubventionsGetResponse;
    const alertIndex = alerts.items.findIndex((i) => i.id === response.payload.id);

    if (alertIndex !== -1) {
      alerts.items[alertIndex] = response.payload;
      patchState({ alerts });
    }
  }

  @Action(GetSubventionsFavorites)
  getSubventionsFavorites(
    { getState, patchState }: StateContext<SubventionsStateModel>,
    response: { payload: SubventionsGetResponse }
  ) {
    const state = getState();
    let favorites = state.favorites;
    favorites = response.payload;
    patchState({ favorites });
  }

  @Action(GetSubvention)
  getSubvention({ getState, patchState }: StateContext<SubventionsStateModel>, response: { payload: Subvention }) {
    const state = getState();
    let subvention = state.subvention;
    subvention = response.payload;
    patchState({ subvention });
  }

  @Action(ToggleSubventionsMenu)
  toggleSubventionsMenu({ getState, patchState }: StateContext<SubventionsStateModel>) {
    const state = getState();
    const openedMenu = !state.openedMenu;
    patchState({ openedMenu });
  }

  @Action(GetFilters)
  getFilters(
    { getState, patchState }: StateContext<SubventionsStateModel>,
    response: {
      payload: {
        id: number;
        seccion: string;
        attr: string;
        items: {
          label: string;
          value: number;
        }[];
      }[];
    }
  ) {
    const state = JSON.parse(JSON.stringify(getState()));
    let filters = state.filters as SubventionsFilters;
    filters.filters = response.payload;
    patchState({ filters });
  }

  @Action(UpdateSubventionsMenuFilters)
  updateSubventionsMenuFilters(
    { getState, patchState }: StateContext<SubventionsStateModel>,
    response: { payload: SubventionsFilters }
  ) {
    const state = getState();
    let filters = state.filters;
    filters = response.payload;
    patchState({ filters });
  }

  @Action(UpdateSubventionFavorite)
  updateSubventionFavorite(
    { getState, patchState }: StateContext<SubventionsStateModel>,
    response: { payload: number }
  ) {
    const state = JSON.parse(JSON.stringify(getState()));

    const subventions = state.subventions as SubventionsGetResponse;
    let subvention = state.subvention as Subvention;
    const subventionIndex = subventions.items.findIndex((i) => i.id === response.payload);

    if (subventionIndex !== -1) {
      subventions.items[subventionIndex] = {
        ...subventions.items[subventionIndex],
        favorito: !subventions.items[subventionIndex].favorito,
      };
      patchState({ subventions });
    }

    if (subvention) {
      subvention = { ...subvention, favorito: !subvention.favorito };
      patchState({ subvention });
    }
  }
}
