import {
  Component,
  Inject,
  OnInit,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { HtmltextPipe } from '@app/pipes/htmltext.pipe';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppStatusService } from '@app/services/app-status.service';
import { SearchService } from '@app/modules/main/components/search/services/search.service';
import { defaultOptions } from 'ngx-extended-pdf-viewer';

export interface DialogData {
  srcimg: string;
}

@Component({
  selector: 'doc-body',
  templateUrl: './docbody.component.html',
  styleUrls: ['./docbody.component.css'],
})
export class DocBodyComponent implements OnInit, OnChanges {
  @Input() doc;
  @Input() lookfortext;
  @Input() querytext: string;
  @Input() searchterms: string[];
  @Input() page: number;

  bodyhtml: string;

  lastpattern: string = '';
  nextocurrence: number = 0;
  maxocurrences: number = 0;
  ocurrences: any = [];
  elems: any;
  picture: boolean = true;
  favorito: boolean = false;
  isLoadingResults = true;
  isRateLimitReached = false;
  pdfSrc: string;

  constructor(
    private appStatus: AppStatusService,
    private sService: SearchService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    defaultOptions.externalLinkTarget = 2;
  }

  ngOnInit() {
    this.bodyhtml = this.bodytohtml(this.doc.bodyParsed);
    this.pdfSrc = this.doc.s3_pdf;
    this.isLoadingResults = false;
    if (this.doc.docType == 'pdf')
      setTimeout(() => {
        document.getElementById('mypdf').getElementsByClassName('zoom')[0]['style']['height'] = '870px';
      }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    const querytext: SimpleChange = changes.querytext;
    if (querytext && querytext.currentValue && querytext.currentValue != '') {
      this.textsearch(querytext.currentValue);
    }

    const searchterms: SimpleChange = changes.searchterms;
    if (searchterms && searchterms.currentValue) {
      this.resetOcurrences();
      if (this.lookfortext !== undefined) {
        this.textsearch(this.lookfortext);
      }
    }

    const page: SimpleChange = changes.page;
    if (page && page.currentValue) {
      this.goToPage(page.currentValue);
    }
  }

  resetOcurrences() {
    this.ocurrences = [];
    if (this.searchterms) {
      this.searchterms.forEach((element) => {
        this.ocurrences.push({ word: element, ocurrence: this.count(this.bodyhtml, element) });
      });
    }
  }

  bodytohtml(doc: string) {
    var thepipe: HtmltextPipe = new HtmltextPipe();
    return thepipe.transform(doc);
  }

  resetSearch() {
    this.bodyhtml = this.bodytohtml(this.doc.bodyParsed);
    this.changeDetector.detectChanges();
  }

  search(stringToSearch: string) {}

  textsearch(pattern: string) {
    if (this.doc.docType == 'pdf') {
      this.textsearchPDF(pattern);
    } else {
      this.textsearchHTML(pattern);
    }
  }

  goToPage(page: number) {
    if (window['PDFViewerApplication']) window['PDFViewerApplication'].page = page || 1;
  }

  textsearchPDF(pattern: string) {
    let findBar = window['PDFViewerApplication'] ? window['PDFViewerApplication'].findBar : undefined;
    if (findBar) {
      if (!findBar.opened) {
        findBar.toggleButton.click();
      }
      findBar.findField.value = pattern;
      findBar.findNextButton.click();
    }
  }

  textsearchHTML(pattern: string) {
    var tmptext: string;

    if (this.lastpattern != pattern) {
      this.resetOcurrences();
      //miramos si es un termino nuevo de la búsqueda (no esta en ocurrences)
      var isnew = true;
      this.ocurrences.forEach((element) => {
        if (element.word == pattern) isnew = false;
        //this.ocurrences.push({ 'word':element,'ocurrence' : this.count(this.bodyhtml,element)});
      });

      //si es nuevo lo añadimos a ocurrences con su numero de repeticiones
      if (isnew) this.ocurrences.push({ word: pattern, ocurrence: this.count(this.bodyhtml, pattern) });

      this.lastpattern = pattern;
      this.resetSearch();

      tmptext = this.bodyhtml.replace(
        new RegExp(pattern, 'gi'),
        "<span class='ocurrence'><strong>" + pattern + '</strong></span>'
      );
      this.bodyhtml = tmptext;
      this.changeDetector.detectChanges();
      this.elems = document.querySelectorAll('.ocurrence');

      this.maxocurrences = 0;
      this.ocurrences.forEach((element) => {
        if (element.word == pattern) this.maxocurrences = element.ocurrence;
      });

      this.nextocurrence = 0;
      if (this.elems.length > 0) {
        this.elems[this.nextocurrence].scrollIntoView({ block: 'center', behavior: 'smooth' });
        //this.elems[this.nextocurrence].classList.add('encontrado');
        this.elems[this.nextocurrence].style.animation = 'highlight 1s';
        var el = this.elems[this.nextocurrence];
        setTimeout(function () {
          el.style.animation = '';
        }, 1000);
        this.nextocurrence++;

        if (this.nextocurrence >= this.maxocurrences) this.nextocurrence = 0;
      }
    } else {
      if (this.elems && this.elems.length > 0) {
        this.elems[this.nextocurrence].scrollIntoView({ block: 'center', behavior: 'smooth' });
        //this.elems[this.nextocurrence].classList.add('encontrado');
        this.elems[this.nextocurrence].style.animation = 'highlight 1s';
        var el = this.elems[this.nextocurrence];
        setTimeout(function () {
          el.style.animation = '';
        }, 1000);

        this.nextocurrence++;
        if (this.nextocurrence >= this.maxocurrences) this.nextocurrence = 0;
      }
    }
  }

  count(main_str, sub_str) {
    main_str += '';
    sub_str += '';
    if (sub_str.length <= 0) {
      return main_str.length + 1;
    }
    var subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    return (main_str.match(new RegExp(subStr, 'gi')) || []).length;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogImg, {
      width: '500px',
      data: { srcimg: this.doc.s3_screenshot },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  addfav() {
    this.sService.saveFavorite(this.doc.titleParsed, this.doc.docID).subscribe((ev) => {
      this.doc._favorite = true;
      this.appStatus.favorite_save.emit(this.doc.docID);
    });
  }

  delfav() {
    this.sService.deleteFavorite(this.doc.docID).subscribe((ev) => {
      this.doc._favorite = false;
      this.appStatus.favorite_delete.emit(this.doc.docID);
    });
  }

  pagesLoaded() {
    if (this.doc.categories) {
      let found = false;
      for (let i = this.doc.categories.length - 1; i >= 0 && !found; i--) {
        found = this.doc.categories[i].context;
        if (found && this.doc.categories[i].terms.length > 0) {
          let position = JSON.parse(this.doc.categories[i].terms[0]);
          if (window['PDFViewerApplication']) window['PDFViewerApplication'].page = position.start;
        }
      }
    }
  }
}

@Component({
  selector: 'dialog-img-dialog',
  templateUrl: './dialog.img.html',
  styleUrls: ['./dialog.img.css'],
})
export class DialogImg {
  constructor(public dialogRef: MatDialogRef<DialogImg>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
