import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AppStateService } from '@app/state/app/app.service';
import { KC_REALM_BRAINY, KC_REALM_CEOE, getRealm } from '@app/init/keycloak-init.factory';

@Component({
  selector: 'app-homehelp',
  templateUrl: './homehelp.component.html',
  styleUrls: ['./homehelp.component.css'],
})
export class HomeHelpComponent implements OnInit {
  getRealm = getRealm;
  KC_REALM_CEOE = KC_REALM_CEOE;
  KC_REALM_BRAINY = KC_REALM_BRAINY;
  environment = environment;

  constructor(
    private _appStateService: AppStateService
  ) { }

  ngOnInit() {
    this._appStateService.addTitle('Página de ayuda');
    this._appStateService.hideDates();
    this._appStateService.hideSearchText();
    this._appStateService.hideSubForm();
  }

  goToLink(url: string) {
    window.open(`${window.location.origin}/${url}`);
  }
}
