import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  AfterViewInit,
  Inject,
  Injector,
  PLATFORM_ID,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, interval, merge, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Item } from '@app/models/Item';
import { DialogfavComponent } from '@modules/documents/components/dialogfav/dialogfav.component';
import $ from 'jquery';
import _moment from 'moment';
import { environment } from '@env/environment';
import { AppStateService } from '@app/state/app/app.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SearchFilters } from '@app/state/models/app.state.model';
import Utils from '@app/utils/utils';
import { URL } from '@env/environment';
import categoriesColorsJson from '@assets/categories-colors.json';
import { on } from 'process';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { DocumentService } from '../../services/document.service';
import { AppStatusService } from '@app/services/app-status.service';
import { AppDataService } from '@app/services/app-data.service';
import {
  QueryParams,
  SearchService,
  SearchServiceResult,
} from '@app/modules/main/components/search/services/search.service';
import { SearchRequest } from '@app/modules/main/components/search/services/search.request.service';
import { SpidersService } from '@app/modules/main/components/search/services/spiders.service';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import rainbowColorsJson from '@assets/rainbow-colors.json';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MatDatepicker } from '@angular/material/datepicker';
import { MenuItem, NavItem } from '@app/models/User';
import { MatSelectChange } from '@angular/material/select';
import { SearchTypeDialogComponent } from './search-type-dialog/search-type-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SearchTypeOperatorsDialogComponent } from './search-type-operators-dialog/search-type-operators-dialog.component';
import { P } from '@angular/cdk/keycodes';
import { CalendarRangePresetsComponent } from '@app/components/calendar-range-presets/calendar-range-presets.component';
import { MainSearch } from '@app/modules/main/components/search/search.component';
import redYellowGreenColorsGradientsJson from '@assets/red-yellow-green-colors-gradients.json';
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('picker') picker;
  rangePresetSelected = '';

  mclick = 0;
  rainbowColors: string[] = rainbowColorsJson;
  redYellowGreenColorsGradients: string[] = redYellowGreenColorsGradientsJson;

  readonly LOGOURL: string = environment.logoApp;

  private sub: QueryParams;
  componentDestroyed$: Subject<boolean> = new Subject();
  documentpanel: any;
  doc_item: any;
  selected: string = '1';
  scoreValue = 60;
  candidatesValue = 30;
  value = '';
  lookfortext: string;
  previous_state: string;
  searchRequest: any;
  searchObservable: Observable<SearchServiceResult>;
  items: Item[] = [];
  showing: boolean = false;
  categoriesColors: { category: string; color: string }[] = categoriesColorsJson;
  pagechange = false;

  displayedColumns: string[] = ['title', 'dateParsed'];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  firstLoad = true;
  verbarra = true;
  sortselect: string = '1';
  subscriptions = [];
  urlparams: any;
  change_subscription: any;
  show_subform: boolean;
  toolbar_fixed: boolean = false;
  hide_search: boolean = true;
  subscription: any;
  clipping: boolean = false;
  sectorReports: boolean = false;
  regulatorio: boolean = false;
  vigilancia: boolean = false;
  europa: boolean = false;
  spidersSectorReports: any;
  showFiltersInSearchInput = false;
  sectorsReportsSearchIds = [];
  withSavedSearch?: number;

  calendarRangePresetsComponent = CalendarRangePresetsComponent;

  private data_lock: number = 0;
  private previous_hash: string;
  public show: boolean = false;
  public exec_query: boolean = true;
  static CATEGORIES_ALL = 37;
  static REGIONS_ALL = 1;
  static CONTEXTS_ALL = Infinity;

  range = new FormGroup({
    start: new FormControl(_moment().startOf('month').toISOString()),
    end: new FormControl(_moment().endOf('month').toISOString()),
  });

  clippingFG = new FormGroup({
    escrita: new FormControl(true),
    digital: new FormControl(true),
  });

  searchTypeFG = new FormGroup({
    searchType: new FormControl('literal'),
  });

  searchTypeOptions = [
    { label: 'Literal', value: 'literal' },
    { label: 'Semántica', value: 'semantic' },
  ];

  //Dropdown Contexts
  dropdownContextList = [];
  selectedContextItems = [];
  dropdownContextSettings = {
    text: 'Selección de contextos',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  //Dropdown categories
  dropdownCategoryList = [];
  selectedCategoryItems = [];
  dropdownCategorySettings = {
    text: 'Selección de tema',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Eliminar Selección',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  //Dropdown Spiders
  dropdownSpiderGroupsList = [];
  selectedSpiderGroupsItems = [];
  dropdownSpiderGroupsSettings = {
    text: 'Selección de fuentes',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Eliminar Selección',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    groupBy: 'group',
    selectGroup: true,
    showCheckbox: true,
  };

  //Dropdown Regions
  dropdownRegionsList = [];
  selectedRegionsItems = [];
  dropdownRegionsSettings = {
    text: 'Selección de ámbito geográfico',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Eliminar Selección',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('inputsearch') inputsearch: ElementRef;
  @ViewChild('tooltip') tooltip: ElementRef;

  constructor(
    public authStateService: AuthStateService,
    public _appStateService: AppStateService,
    public router: Router,
    private sService: SearchService,
    private searchRequestService: SearchRequest,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private appStatus: AppStatusService,
    private searchParamsService: SearchService,
    private osBar: MatSnackBar,
    private dialog: MatDialog,
    private sAppData: AppDataService,
    private sSpider: SpidersService,
    private documentService: DocumentService,
    private matBottomSheet: MatBottomSheet
  ) {
    this.route.queryParams.subscribe((params) => {
      setTimeout(() => {
        this.withSavedSearch = Number(params.withSavedSearch);
      }, 500);
    });

    this.appStatus.sector_reports_search_launched.subscribe((ids) => {
      this.sectorsReportsSearchIds = ids;
    });

    this.subscriptions.push(
      this.searchRequestService.searchParams.subscribe((searchRequest) => {
        // this.withSavedSearch = null;

        this.scoreValue = searchRequest.score ? searchRequest.score * 100 : null;
        this.candidatesValue = searchRequest.candidates;

        if (this.paginator) {
          this.paginator.pageIndex = 0;
          this.searchRequest.from = 0;
        }

        if (searchRequest.sort) {
          this.sortselect = String(searchRequest.sort);
        }

        this.search(searchRequest);
        this.value = searchRequest.text;
      })
    );

    this.subscriptions.push(
      this.appStatus.show_subform.subscribe((showing) => {
        this.showing = showing;
      })
    );

    this.subscriptions.push(
      this.appStatus.favorite_save.subscribe((docid) => {
        let linea: any = this.items.find((row: any) => {
          return row.id_source == docid;
        });
        linea._favorite = true;
      })
    );

    this.subscriptions.push(
      this.appStatus.favorite_delete.subscribe((docid) => {
        let linea: any = this.items.find((row: any) => {
          return row.id_source == docid;
        });
        linea._favorite = false;
      })
    );

    this.subscriptions.push(
      this.appStatus.search_launch.subscribe((search) => {
        console.warn('🚀 ~ search:', search);
        if (this.paginator) {
          this.paginator.pageIndex = 0;
        }

        if (this.searchRequest) {
          this.searchRequest.from = 0;
        }

        if (search.searchType) {
          this.searchTypeFG.controls.searchType.setValue(search.searchType);
        }

        if (search.sort) {
          // this.cambiaOrden({ value: search.sort });
          this.selected = String(search.sort);
        }

        $('#bloquelistado').scrollTop(0);
      })
    );

    this.subscriptions.push(
      this.appStatus.page_change.subscribe(() => {
        if (location.hash != this.previous_state) {
          this.firstLoad = true;
          this.previous_state = this.router.url;
        }
      })
    );

    let cleanUpTopics = (item) => {
      if (item.id && item.id != MainSearch.CATEGORIES_ALL) {
        let name = item.visibleName ? item.visibleName : item.name;
        return {
          id: item.topics && item.topics.length > 0 ? item.topics[0] : item.id,
          itemName: name.replace('-', '').trim(),
        };
      }
      return undefined;
    },
      cleanUpRegions = (item) => {
        if (item.regions && item.regions[0] && item.regions[0] != MainSearch.REGIONS_ALL) {
          let name = item.visibleName ? item.visibleName : item.name;
          return {
            id: item.regions[0],
            itemName: name.replace('-', '').trim(),
          };
        }
        return undefined;
      },
      cleanUpContexts = (item) => {
        if (item.id && item.id != MainSearch.CONTEXTS_ALL) {
          let name = item.visibleName ? item.visibleName : item.name;
          return {
            id: item.topics && item.topics.length > 0 ? item.topics[0] : item.id,
            itemName: name.replace('-', '').trim(),
          };
        }
        return undefined;
      },
      cleanUpSpiders = (group) => {
        let mappings = {
          spiders_id: { next: null, values: {} },
          spider_groups_id: { next: null, values: {} },
        };

        for (let i = group.spiders.length - 1; i >= 0; i--) {
          let id = group.spiders[i].id;
          mappings.spiders_id.values[id] = group.spiders[i].visibleName ?? group.spiders[i].name;
          mappings.spider_groups_id.values[id] = group.visibleName ?? group.name;
        }

        return this.prepareSpiders(mappings);
      };

    appStatus.page_change.pipe(takeUntil(this.componentDestroyed$)).subscribe((page) => {
      page = this.removeQueryParamsFromUrl(page);

      if (!this.pagechange) {
        this.pagechange = true;
        this.regulatorio = false;
        this.vigilancia = false;
        this.europa = false;

        if (!page.match(/documents/) || !page.match(/dashboard/) || !page.match(/sector-reports/)) {
          this.clipping = false;
        }

        if (!page.match(/documents/) || !page.match(/dashboard/) || !page.match(/clipping/)) {
          this.sectorReports = false;
        }

        if (!page.match(/regulatorio/) && !page.match(/vigilancia/) && !page.match(/europe/)) {
          this.selectedContextItems = [];
        }

        if (!page.match(/regulatorio/) && !page.match(/vigilancia/) && !page.match(/europe/) && 0 >= this.data_lock) {
          this.data_lock = 3;
          if (!sAppData.loaded) {
            sAppData.load();
          } else {
            sAppData.loadFilters();
          }

          this.sAppData.filters_loaded.subscribe((mappings) => {
            this.dropdownSpiderGroupsList = this.prepareSpiders(mappings);
            this.data_lock--;
          });

          this.dropdownCategoryList = this.prepare(
            authStateService.userConfig.config.Documentos.Topics.map(cleanUpTopics)
          );
          this.data_lock--;

          this.dropdownRegionsList = this.prepare(
            authStateService.userConfig.config.Documentos.Regions.map(cleanUpRegions)
          );
          this.data_lock--;
        }

        if (page.match(/dashboard/)) {
          this._appStateService.addTitle('Dashboards');
          this.exec_query = false;
          this.value = '';
        } else {
          this.exec_query = true;
        }

        if (page == 'documents_home') {
          this.hide_search = true;
        }

        if (page.match(/clipping/)) {
          this._appStateService.addTitle('Prensa');
          this.clipping = true;
        }

        if (page.match(/sector-reports/)) {
          this._appStateService.addTitle('Informes Sectoriales');
          this.sectorReports = true;
          this.spidersSectorReports = [
            {
              id: '3778',
            },
          ];
        }

        if (page.match(/regulatorio/)) {
          this._appStateService.addTitle('Seguimiento legislativo');
          if (page != this.previous_hash) {
            // this.resetSearch();
            this.previous_hash = page;
          }

          this.regulatorio = true;

          let item = this.authStateService.userConfig.interface_config.menu_items
            .find((item) => item.path.match(/regulatorio/) != undefined)
            .items.filter((item) => item.path == page);

          if (item && item.length) {
            let fn = (item) => {
              if (0 >= this.data_lock && item[0].config) {
                this.data_lock = item[0].config.paths.length;

                item[0].config.paths
                  .map((path) => path.slice(1))
                  .forEach((path) => {
                    let url = `${URL}${page.slice(1)}/${path}`;

                    if (path === 'topicsEu') {
                      url = environment.topicsUrlEurovoc;
                    }

                    let response = [];

                    if (url.includes('spiderGroups')) {
                      response =
                        this.authStateService.userConfig.config.Regulatorio[page.slice(1).split('/')[1]].SpiderGroups;
                    } else if (url.includes('topics')) {
                      response =
                        this.authStateService.userConfig.config.Regulatorio[page.slice(1).split('/')[1]].Topics;
                    } else if (url.includes('regions')) {
                      response =
                        this.authStateService.userConfig.config.Regulatorio[page.slice(1).split('/')[1]].Regions;
                    } else if (url.includes('contexts')) {
                      response =
                        this.authStateService.userConfig.config.Regulatorio[page.slice(1).split('/')[1]].Context;
                    }

                    let auxMap = {
                      topics: 'topics_id',
                      spiderGroups: 'spider_groups_id',
                      regions: 'regions_id',
                      contexts: 'contexts_id',
                    },
                      aux = {
                        contexts: {
                          clean: cleanUpContexts,
                          dropdown: 'dropdownContextList',
                        },
                        topics: {
                          clean: cleanUpTopics,
                          dropdown: 'dropdownCategoryList',
                        },
                        spiderGroups: {
                          clean: cleanUpSpiders,
                          dropdown: 'dropdownSpiderGroupsList',
                        },
                        regions: {
                          clean: cleanUpRegions,
                          dropdown: 'dropdownRegionsList',
                        },
                      },
                      toMapping = {};

                    response.forEach((item) => {
                      let i = aux[path.replace('topicsEu', 'topics')].clean(item);
                      if (i) {
                        toMapping[i.id] = i.itemName;
                      }
                    });

                    this.sAppData.cleanMappings(auxMap[path]);
                    this.sAppData.addMappings(auxMap[path], toMapping);
                    this[aux[path.replace('topicsEu', 'topics')].dropdown] = this.prepare(
                      response.map(aux[path.replace('topicsEu', 'topics')].clean)
                    );
                    this.data_lock--;
                  });
              } else
                setTimeout(() => {
                  fn(item);
                }, 1000);
            };
            fn(item);
          }
        }

        if (page.match(/europe/)) {
          this._appStateService.addTitle('Europa');
          if (page != this.previous_hash) {
            // this.resetSearch();
            this.previous_hash = page;
          }

          this.europa = true;

          let item = this.authStateService.userConfig.interface_config.menu_items
            .find((item) => item.id === 7)
            .items.filter((item) => page.includes(item.path));

          if (item && item.length) {
            let fn = (item) => {
              if (0 >= this.data_lock && item[0].items.find((i) => i.path === page).config) {
                this.data_lock = item[0].items.find((i) => i.path === page).config.paths.length;

                item[0].items
                  .find((i) => i.path === page)
                  .config.paths.map((path) => path.slice(1))
                  .forEach((path) => {
                    let url = `${URL}${page.slice(1)}/${path}`;

                    if (path === 'topicsEu') {
                      url = environment.topicsUrlEurovoc;
                    }

                    let response = [];

                    if (url.includes('spiderGroups')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].SpiderGroups;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].SpiderGroups;
                      }
                    } else if (url.includes('topics')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Topics;
                      } else {
                        this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                          page.slice(1).split('/')[2]
                        ][page.slice(1).split('/')[3]].Topics;
                      }
                    } else if (url.includes('regions')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Regions;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].Regions;
                      }
                    } else if (url.includes('contexts')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Context;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].Context;
                      }
                    }

                    let auxMap = {
                      topics: 'topics_id',
                      spiderGroups: 'spider_groups_id',
                      regions: 'regions_id',
                      contexts: 'contexts_id',
                    },
                      aux = {
                        contexts: {
                          clean: cleanUpContexts,
                          dropdown: 'dropdownContextList',
                        },
                        topics: {
                          clean: cleanUpTopics,
                          dropdown: 'dropdownCategoryList',
                        },
                        spiderGroups: {
                          clean: cleanUpSpiders,
                          dropdown: 'dropdownSpiderGroupsList',
                        },
                        regions: {
                          clean: cleanUpRegions,
                          dropdown: 'dropdownRegionsList',
                        },
                      },
                      toMapping = {};

                    response.forEach((item) => {
                      let i = aux[path.replace('topicsEu', 'topics')].clean(item);
                      if (i) {
                        toMapping[i.id] = i.itemName;
                      }
                    });

                    this.sAppData.cleanMappings(auxMap[path]);
                    this.sAppData.addMappings(auxMap[path], toMapping);
                    this[aux[path.replace('topicsEu', 'topics')].dropdown] = this.prepare(
                      response.map(aux[path.replace('topicsEu', 'topics')].clean)
                    );
                    this.data_lock--;
                  });
              } else
                setTimeout(() => {
                  fn(item);
                }, 1000);
            };
            fn(item);
          }
        }

        if (page.match(/vigilancia/)) {
          this._appStateService.addTitle('Vigilancia legislativa');
          if (page != this.previous_hash) {
            // this.resetSearch();
            this.previous_hash = page;
          }

          this.vigilancia = true;

          let item = this.authStateService.userConfig.interface_config.menu_items
            .find((item) => item.id === 6)
            .items.filter((item) => page.includes(item.path));

          if (item && item.length) {
            let fn = (item) => {
              if (0 >= this.data_lock && item[0].items.find((i) => i.path === page).config) {
                this.data_lock = item[0].items.find((i) => i.path === page).config.paths.length;

                item[0].items
                  .find((i) => i.path === page)
                  .config.paths.map((path) => path.slice(1))
                  .forEach((path) => {
                    let url = `${URL}${page.slice(1)}/${path}`;

                    if (path === 'topicsEu') {
                      url = environment.topicsUrlEurovoc;
                    }

                    let response = [];

                    if (url.includes('spiderGroups')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].SpiderGroups;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].SpiderGroups;
                      }
                    } else if (url.includes('topics')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Topics;
                      } else {
                        this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                          page.slice(1).split('/')[2]
                        ][page.slice(1).split('/')[3]].Topics;
                      }
                    } else if (url.includes('regions')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Regions;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].Regions;
                      }
                    } else if (url.includes('contexts')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Context;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].Context;
                      }
                    }

                    let auxMap = {
                      topics: 'topics_id',
                      spiderGroups: 'spider_groups_id',
                      regions: 'regions_id',
                      contexts: 'contexts_id',
                    },
                      aux = {
                        contexts: {
                          clean: cleanUpContexts,
                          dropdown: 'dropdownContextList',
                        },
                        topics: {
                          clean: cleanUpTopics,
                          dropdown: 'dropdownCategoryList',
                        },
                        spiderGroups: {
                          clean: cleanUpSpiders,
                          dropdown: 'dropdownSpiderGroupsList',
                        },
                        regions: {
                          clean: cleanUpRegions,
                          dropdown: 'dropdownRegionsList',
                        },
                      },
                      toMapping = {};

                    response.forEach((item) => {
                      let i = aux[path.replace('topicsEu', 'topics')].clean(item);
                      if (i) {
                        toMapping[i.id] = i.itemName;
                      }
                    });

                    this.sAppData.cleanMappings(auxMap[path]);
                    this.sAppData.addMappings(auxMap[path], toMapping);
                    console.warn('🚀 ~ response:', response);
                    this[aux[path.replace('topicsEu', 'topics')].dropdown] = this.prepare(
                      response.map(aux[path.replace('topicsEu', 'topics')].clean)
                    );
                    this.data_lock--;
                  });
              } else
                setTimeout(() => {
                  fn(item);
                }, 1000);
            };
            fn(item);
          }
        }

        if (page == 'clipped') {
          this.clipping = true;
        } else if (page == 'sector-reports') {
          this.sectorReports = true;
        }
      }

      setTimeout(() => {
        this.pagechange = false;
      }, 500);
    });

    appStatus.reset_search.subscribe((ev) => {
      this.start.setValue(_moment().startOf('month').toISOString());
      this.end.setValue(_moment().endOf('month').toISOString());

      if (ev && ev.match(/clipping/)) {
        this.start.setValue(_moment().toISOString());
        this.end.setValue(_moment().toISOString());

        this.show = true;
        this.clipping = true;
        this.hide_search = true;
      } else if (ev && ev.match(/sector-reports/)) {
        this.show = true;
        this.sectorReports = true;
        this.hide_search = true;
      }
      // this.resetSearch();
    });

    this._appStateService.searchFilters$.subscribe((data) => {
      setTimeout(() => {
        this.setSearchFilters(data);
      }, 100);
    });

    this.start.valueChanges.subscribe((_) => {
      this.handleDateUpdated();
    });

    this.end.valueChanges.subscribe((_) => {
      this.handleDateUpdated();
    });

    setTimeout(() => {
      if (this.router.url.match(/clipping/)) {
        const spidersClippingSelected = [];

        if (this.clipping) {
          if (this.clippingFG.controls.escrita.value) {
            spidersClippingSelected.push(
              ...this.authStateService.userConfig.config.Prensa.Spiders.escrita.map((d) => {
                return { id: d.id };
              })
            );
          }
          if (this.clippingFG.controls.digital.value) {
            spidersClippingSelected.push(
              ...this.authStateService.userConfig.config.Prensa.Spiders.digital.map((d) => {
                return { id: d.id };
              })
            );
          }
        }

        this._appStateService.updateSearchFilters({
          dates: {
            start: _moment().toISOString(),
            end: _moment().toISOString(),
          },
          spiders: this.clipping ? spidersClippingSelected : [...this.selectedSpiderGroupsItems],
          categories: [...this.selectedCategoryItems],
          regions: [...this.selectedRegionsItems],
          contexts: [...this.selectedContextItems],
          clippingsSelected: {
            escrita: this.clippingFG.controls.escrita.value,
            digital: this.clippingFG.controls.digital.value,
          },
          searchType: this.searchTypeFG.controls.searchType.value,
        });
      }
    }, 1000);
  }

  get start() {
    return this.range.get('start');
  }

  get end() {
    return this.range.get('end');
  }

  cleanUpSelectors(paths) {
    paths.forEach((path) => {
      let aux = {
        topics: { lists: ['dropdownCategoryList', 'selectedCategoryItems'] },
        regions: { lists: ['dropdownRegionsList', 'selectedRegionsItems'] },
        contexts: { lists: ['dropdownContextList', 'selectedContextItems'] },
        spiderGroups: {
          lists: ['dropdownSpiderGroupsList', 'selectedSpiderGroupsItems'],
        },
      };
      for (let i = aux[path].lists.length - 1; i >= 0; i--) this[aux[path].lists[i]] = [];
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this.subscriptions.splice(0, this.subscriptions.length);
    this._appStateService.hideSearchText();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnInit() {
    this._appStateService.showTitle();
    this._appStateService.showDates();
    this._appStateService.hideSearchText();
    this._appStateService.hideSubForm();
    // this.resetSearch();
    this.withSavedSearch = null;
    var openwithparams = false;

    this.start.setValue(_moment().startOf('month').toISOString());
    this.end.setValue(_moment().endOf('month').toISOString());

    if (this.router.url.match(/clipping/)) {
      this.start.setValue(_moment().toISOString());
      this.end.setValue(_moment().toISOString());
    }

    if (
      this.router.url.match(/clipping/) ||
      this.router.url.match(/regulatorio/) ||
      this.router.url.match(/vigilancia/) ||
      this.router.url.match(/europe/) ||
      this.router.url.match(/sector-reports/)
    ) {
      openwithparams = true;
      this.appStatus.page_change.emit(this.router.url);
    }

    this.route.params.subscribe((params) => {
      if (params.id != null) {
        var doctempitem;
        doctempitem = { id_source: params.id };

        if (params.searchtext != null) {
          doctempitem = { id_source: params.id, searchtext: params.searchtext };
        }

        openwithparams = true;
        this.opendocument(doctempitem);
      }
    });

    let params: QueryParams = JSON.parse(localStorage.getItem('params'));

    if (params) {
      if (params.text != '') this.value = params.text;
    }

    if (!openwithparams) this.appStatus.page_change.emit('documents_home');

    this.listenCategoriesMultiselectDropdown();
  }

  ngAfterViewInit() { }

  setSearchFilters(filters: SearchFilters): void {
    this.selectedSpiderGroupsItems = [...filters.spiders];
    this.selectedCategoryItems = [...filters.categories];
    this.selectedRegionsItems = [...filters.regions];
    this.selectedContextItems = [...filters.contexts];

    this.start.setValue(filters.dates.start);
    this.end.setValue(filters.dates.end);

    this.value = this._appStateService.content;
  }

  findDocuments(canChangeOrder = false) {
    const spidersClippingSelected = [];

    if (this.clipping) {
      if (this.clippingFG.controls.escrita.value) {
        spidersClippingSelected.push(
          ...this.authStateService.userConfig.config.Prensa.Spiders.escrita.map((d) => {
            return { id: d.id };
          })
        );
      }
      if (this.clippingFG.controls.digital.value) {
        spidersClippingSelected.push(
          ...this.authStateService.userConfig.config.Prensa.Spiders.digital.map((d) => {
            return { id: d.id };
          })
        );
      }
    }

    if (!canChangeOrder) {
      if (this.searchTypeFG.controls.searchType.value === 'literal') {
        this.selected = '1';
      } else if (this.searchTypeFG.controls.searchType.value === 'semantic') {
        this.selected = '3';
      }
    }

    this._appStateService.showSearchText();

    this._appStateService.updateSearchContent(this.value || '');
    this._appStateService.updateSearchFilters({
      dates: {
        start: this.start.value,
        end: this.end.value,
      },
      spiders: this.clipping ? spidersClippingSelected : [...this.selectedSpiderGroupsItems],
      categories: [...this.selectedCategoryItems],
      regions: [...this.selectedRegionsItems],
      contexts: [...this.selectedContextItems],
      clippingsSelected: {
        escrita: this.clippingFG.controls.escrita.value,
        digital: this.clippingFG.controls.digital.value,
      },
      searchType: this.searchTypeFG.controls.searchType.value,
      sort: Number(this.selected),
    });
    console.warn('🚀 ~ this.selected:', this.selected);

    if (this.router.url.match(/clipping/)) this.appStatus.page_change.emit('clipped');
    else if (this.router.url.match(/sector-reports/)) this.appStatus.page_change.emit('sector-reports');
    else if (this.router.url.match(/regulatorio/)) this.appStatus.page_change.emit(location.hash.slice(1));
    else if (this.router.url.match(/vigilancia/)) this.appStatus.page_change.emit(location.hash.slice(1));
    else if (this.router.url.match(/europe/)) this.appStatus.page_change.emit(location.hash.slice(1));
    else this.appStatus.page_change.emit('documents');

    this.appStatus.search_launch.emit({
      text_search: this.value,
      start: this.start.value,
      end: this.end.value,
      spiderIds: this.clipping ? spidersClippingSelected : [...this.selectedSpiderGroupsItems],
      docTopics: [...this.selectedCategoryItems],
      docTopicsRegion: [...this.selectedRegionsItems],
      docContexts: [...this.selectedContextItems],
      clippingsSelected: {
        escrita: this.clippingFG.controls.escrita.value,
        digital: this.clippingFG.controls.digital.value,
      },
      score: this.scoreValue / 100,
      candidates: this.candidatesValue,
      searchType: this.searchTypeFG.controls.searchType.value,
      sort: Number(this.selected),
    });

    this.hideSectorReportsImageAndText();
  }

  cambiaOrden(event) {
    this.sortselect = event.value;
    this.searchRequest.from = 0;
    this.findDocuments(true);
    this.paginator.pageIndex = 0;
  }

  opendocument(docrow: any) {
    const isInTheLastMonthOrMore = _moment(docrow.date_published, 'dddd, DD [de] MMMM [de] YYYY HH:mm').isBetween(
      _moment().subtract(30, 'days'),
      _moment().add(999, 'years'),
      'days',
      '[]'
    );

    if (!this.clipping || (this.clipping && isInTheLastMonthOrMore)) {
      this.appStatus.paneldoc_open.emit();
      this.verbarra = false;
      this.lookfortext = this.value;
      this.doc_item = docrow;
      this.previous_state = location.hash;
      if (docrow.searchtext != null)
        window.history.replaceState(
          {},
          '',
          '#/documents/open/' + this.doc_item.id_source + '/' + this.doc_item.searchtext
        );
      else window.history.replaceState({}, '', '#/documents/open/' + this.doc_item.id_source);
      this.documentpanel = docrow;
    } else {
      this.dialog.open(ClippingClickDialog);
    }
  }

  clearPosParam() {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {},
    });
  }

  closepanel() {
    this.clearPosParam();
    this.documentpanel = undefined;
    this.verbarra = true;
    this.appStatus.paneldoc_close.emit();
    if (this.previous_state) {
      window.history.replaceState({}, '', this.previous_state);
      this.previous_state = undefined;
    }
  }

  addfav(row: any) {
    this.sService.saveFavorite(row.title, row.id_source).subscribe((ev) => {
      row._favorite = true;
    });
  }

  delfav(row: any) {
    this.sService.deleteFavorite(row.id_source).subscribe((ev) => {
      row._favorite = false;
    });
  }

  resetSearch() {
    this.value = '';
    if (this.router.url.match(/regulatorio/)) this.appStatus.page_change.emit('regulatorio');
    if (this.router.url.match(/vigilancia/)) this.appStatus.page_change.emit('vigilancia');
    if (this.router.url.match(/europe/)) this.appStatus.page_change.emit('europe');
    else if (this.router.url.match(/sector-reports/)) this.appStatus.page_change.emit('sector-reports');
    else this.appStatus.page_change.emit('documents_home');
    this.appStatus.reset_search.emit(this.router.url);
    if (!this.firstLoad) {
      this.osBar.open('Borrando criterios de búsqueda...', '', {
        duration: 2000,
      });
      this.firstLoad = true;
    }
    //this.searchRequest.text_search = "";

    this._appStateService.hideSearchText();
    document.querySelector('.main-search-container .advanced-filters')?.remove();
    this.showSectorReportsImageAndText();
  }

  change_dates(event, row) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DateClickDialog, {
      data: {
        selected_date: row.date_published,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.warn('🚀 ~ search_launch change dates');
        this.paginator.pageIndex = 0;
        this.appStatus.search_launch.emit({
          start: _moment(row.date_published.format('YYYYMMDDT00:00:00'), 'YYYYMMDDThh:mm:ss'),
          end: _moment(row.date_published.format('YYYYMMDDT23:00:00'), 'YYYYMMDDThh:mm:ss'),
        });
        this.searchRequest.from = 0;
      }
    });
  }

  modalFav(ev: any) {
    let widthdialog = 400;

    const dialogRef = this.dialog.open(DialogfavComponent, {
      width: widthdialog + 'px',
      height: '200px',
      data: {
        name: '',
        data: JSON.parse(localStorage.getItem('params')),
        url: this.router.url,
        tipo: this.getTipoAndOrigenSearch().tipo,
        origen: this.getTipoAndOrigenSearch().origen,
        withSavedSearch: this.withSavedSearch,
      },
    });

    dialogRef.updatePosition({
      top: ev.pageY + 'px',
      left: ev.pageX - widthdialog + 'px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      // if (result == 1) this.favorite_search = 1;
    });
  }

  public getSelectedSpiderIds(selectedSpiderGroupsItems) {
    let ids = [];
    selectedSpiderGroupsItems.forEach((item) => {
      item.id.split(',').forEach((id) => {
        ids.push({
          id: id,
          itemName: item.itemName ? item.itemName.trim() : '',
          group: item.group,
        });
      });
    });
    return ids;
  }

  private mapSpiderIds(listOfIds) {
    let aggrs = {},
      ret = [];
    listOfIds.forEach((item) => {
      if (!aggrs[item.itemName])
        aggrs[item.itemName] = {
          id: [],
          itemName: item.itemName,
          group: this.sAppData.map('spider_groups_id', item.id),
        };
      aggrs[item.itemName].id.push(item.id);
    });
    Object.keys(aggrs).forEach((name) => {
      aggrs[name].id = aggrs[name].id.join(',');
    });
    return Object.values(aggrs);
  }

  private prepare(arr) {
    // arr = arr && arr[0] && arr[0].constructor.toString().match(/Array/) ? arr[0] : arr;
    return !arr ? undefined : [].concat(...arr).filter((item) => item != undefined);
  }

  private prepareSpiders(mappings) {
    let spiders_id = (mappings['spiders_id'] && mappings['spiders_id'].values) || [],
      spider_groups_id = (mappings['spider_groups_id'] && mappings['spider_groups_id'].values) || [],
      aux = [];

    if (this.clipping) {
      let filteredSpiders = {};

      [
        ...this.authStateService.userConfig.config.Prensa.Spiders.escrita.map((d) => {
          return { id: d.id };
        }),
        ...this.authStateService.userConfig.config.Prensa.Spiders.digital.map((d) => {
          return { id: d.id };
        }),
      ].forEach((item) => {
        const id = item.id.toString();
        if (spiders_id[id]) {
          filteredSpiders[id] = spiders_id[id];
        }
      });

      spiders_id = filteredSpiders;
    }

    Object.keys(spiders_id).forEach((key) => {
      aux.push({
        id: key,
        itemName: spiders_id[key],
        group: spider_groups_id[key],
      });
    });

    aux = aux.sort((a, b) => {
      let _a = Utils.cleanUpDiacritic(a.group).toUpperCase(),
        _b = Utils.cleanUpDiacritic(b.group).toUpperCase(),
        _ia = Utils.cleanUpDiacritic(a.itemName).toUpperCase(),
        _ib = Utils.cleanUpDiacritic(b.itemName).toUpperCase();
      if (_a < _b) return -1;
      if (_a > _b) return 1;
      if (_ia < _ib) return -1;
      if (_ia > _ib) return 1;
      return 0;
    });
    let last_group = undefined,
      last_name = undefined,
      id = [],
      aux2 = [];

    aux.splice(0, aux.length).forEach((item) => {
      if (last_name == undefined) {
        last_name = item.itemName;
        last_group = item.group;
      }
      if (last_name != item.itemName && last_group == item.group) {
        aux2.push({
          id: id.join(','),
          itemName: last_name,
          group: last_group,
        });
        id = [item.id];
        last_name = item.itemName;
      } else if (last_group == item.group) {
        id.push(item.id);
      } else {
        aux2.push({
          id: id.join(','),
          itemName: last_name,
          group: last_group,
        });
        id = [item.id];
        last_name = item.itemName;
        last_group = item.group;
      }
    });
    aux2.push({
      id: id.join(','),
      itemName: last_name,
      group: last_group,
    });
    //this.dropdownSpiderGroupsList = aux2;
    return [...aux2];
  }

  updateManualPage(index) {
    this.paginator.pageIndex = index - 1;
    this.paginator.nextPage();
  }

  pageEvent(evt) {
    document.getElementById('bloquelistado').scrollTo(0, 0);
  }

  private search(searchRequest?: SearchRequest): void {
    this.firstLoad = false;
    this.changeDetector.detectChanges();
    this.searchRequest = searchRequest;
    this.appStatus.query_change.emit(searchRequest);

    if (this.change_subscription) this.change_subscription.unsubscribe();

    this.change_subscription = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.sService!.getAll(
            this.searchRequest,
            this.sortselect,
            this.sort.direction,
            this.paginator.pageIndex,
            ['html', 'pdf']
          );
        }),
        map((data: any) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total;

          return data.hits;
        }),
        catchError((error) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.appStatus.search_finished.emit();
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.items = data.map((element) => {
          var bodyparsestr: string;
          if (element.highlight && (element.highlight['bodyParsed'] || element.highlight['bodyParsed.exact'])) {
            let key = element.highlight['bodyParsed.exact'] ? 'bodyParsed.exact' : 'bodyParsed';
            bodyparsestr = element.highlight[key] ? element.highlight[key].join('') : element.source.abstract;
          } else if (element.source && element.source['abstract']) {
            bodyparsestr = element.source['abstract'];
          } else {
          }
          let cats = {};
          let taxs = {};
          if (element.source.categories) {
            element.source.categories.forEach((x) => {
              if (this.sAppData.map('topics_id', x.id) !== x.id) cats[this.sAppData.map('topics_id', x.id)] = true;
            });
          }
          if (element.source.taxonomies) {
            element.source.taxonomies.forEach((x) => {
              if (this.sAppData.map('topics_id', x.id) !== x.id) taxs[this.sAppData.map('topics_id', x.id)] = true;
            });
          }

          const docSource = this.dropdownSpiderGroupsList.find((s) => s.id == element.source.spiderId);

          return {
            id_source: element.source.docId,
            id_db: element.id,
            date_published: _moment(element.source.dateParsed).format('LLLL'),
            title: element.source.titleParsed,
            abstract: element.source.abstract,
            highligth: bodyparsestr,
            lang: element.source.lang,
            _favorite: element.source._favorite,
            source: {
              name: element.source.spiderId,
              type: element.source.docType,
            },
            link: element.source.url,
            entities: element.source.entities,
            is_echo: false,
            location: null,
            doc_source: docSource ? docSource.itemName : '-',
            score: element.score,
            docType: element.source.docType,
            categories:
              this.dropdownCategoryList
                .filter((el) => element.source.categories?.map((c) => c.id).includes(el.id))
                .map((sci) => sci.itemName) || [],
            taxonomies:
              this.dropdownCategoryList
                .filter((el) => element.source.taxonomies?.map((c) => c.id).includes(el.id))
                .map((sci) => sci.itemName) || [],
          };
        });

        this.appStatus.search_finished.emit();
      });
  }

  handleAngularMultiselectHeight(id: string): void {
    const select = $(`#${id} .list-area div:last-child`)[0];

    if (select) {
      const windowHeight = $(window).height();
      const selectTopDistance = select.getBoundingClientRect().top;

      if (select.clientHeight > 0 && windowHeight > 0 && selectTopDistance > 0) {
        select.style.maxHeight = `${windowHeight - selectTopDistance - 24}px`;
      }
    }
  }

  getCategoryColor(category: any): string {
    if (!this.router.url.includes('sector-reports/search/')) {
      const categorySelected = this.categoriesColors.find((cc) => cc.category === category);
      return categorySelected ? categorySelected.color : '#00000010';
    } else {
      let categorySelected = this._appStateService.categories_topics.findIndex((ct) => ct.name === category);

      // if (!this.sectorsReportsSearchIds.includes((categorySelected as any)?.id)) {
      //   categorySelected = -1;
      // }

      return categorySelected !== -1 ? `${this.rainbowColors[categorySelected]}20` : '#00000010';
    }
  }

  listenCategoriesMultiselectDropdown(): void {
    setTimeout(() => {
      const cBtn = document.querySelector('#am2-3 .c-btn');

      if (cBtn) {
        const observer = new MutationObserver((mutations_list) => {
          mutations_list.forEach((mutation) => {
            mutation.addedNodes.forEach(() => {
              if (cBtn.querySelector('.c-list')) {
                for (const child of [].slice.call(cBtn.querySelector('.c-list').children)) {
                  child.style.backgroundColor = this.getCategoryColor(child.querySelector('.c-label').innerText);
                }
              }
            });
          });
        });

        observer.observe(cBtn, { subtree: true, childList: true });
      }
    }, 0);
  }

  hideSectorReportsImageAndText(): void {
    const container = document.getElementById('sector-reports-search-container');

    if (container) {
      const imgElement: HTMLImageElement = container.querySelector('img');
      const textElement: HTMLDivElement = container.querySelector('.text');

      imgElement.style.display = 'none';
      textElement.style.display = 'none';
    }
  }

  showSectorReportsImageAndText(): void {
    const container = document.getElementById('sector-reports-search-container');

    const imgElement: HTMLImageElement = container.querySelector('img');
    const textElement: HTMLDivElement = container.querySelector('.text');

    imgElement.style.display = 'block';
    textElement.style.display = 'flex';
  }

  viewHelp(): void {
    // this.dialog.open(HelpSearchDialogComponent, {
    //   width: '600px',
    //   data: {},
    // });

    const item = this.authStateService.userConfig.interface_config.menu_items
      .find((mi) => mi.id == 99)
      .items.find((i) => i.title === 'Uso buscador');

    window.open(`${window.location.origin}/${item.path}`);
  }

  openedDatepicker(): void {
    this.insertDatepickerRangePresetsComponent();
  }

  closedDatepicker(): void {
    this.deleteDatepickerRangePresetsComponent();
  }

  insertDatepickerRangePresetsComponent(): void {
    const pStyle =
      'margin: 0; width: 100%; height: 40px; display: flex; align-items: center; padding: 0 14px; border-radius: 4px; font-size: 14px; font-weight: 500; color: #1F2937; cursor: pointer;';

    document.querySelector('mat-calendar').insertAdjacentHTML(
      'beforebegin',
      `<div id="datepicker-range-presets" style="padding: 20px; padding-right: 0; display: flex; flex-direction: column;">
          <p id="datepicker-range-presets-0" style="${pStyle}">Hoy</p>
          <p id="datepicker-range-presets-1" style="${pStyle}">Semana actual</p>
          <p id="datepicker-range-presets-2" style="${pStyle}">Últimas 2 semanas</p>
          <p id="datepicker-range-presets-3" style="${pStyle}">Mes actual</p>
          <p id="datepicker-range-presets-4" style="${pStyle}">Último semestre</p>
          <p id="datepicker-range-presets-5" style="${pStyle}">Año actual</p>
        </div`
    );

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-0').addEventListener('click', () => {
      this.start.setValue(_moment().toISOString());
      this.end.setValue(_moment().toISOString());
      this.reOpenCalendar();
    });

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-1').addEventListener('click', () => {
      this.start.setValue(_moment().startOf('week').toISOString());
      this.end.setValue(_moment().endOf('week').toISOString());
      this.reOpenCalendar();
    });

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-2').addEventListener('click', () => {
      this.start.setValue(_moment().subtract(2, 'weeks').toISOString());
      this.end.setValue(_moment().toISOString());
      this.reOpenCalendar();
    });

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-3').addEventListener('click', () => {
      this.start.setValue(_moment().startOf('month').toISOString());
      this.end.setValue(_moment().endOf('month').toISOString());
      this.reOpenCalendar();
    });

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-4').addEventListener('click', () => {
      this.start.setValue(_moment().subtract(6, 'months').toISOString());
      this.end.setValue(_moment().toISOString());
      this.reOpenCalendar();
    });

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-5').addEventListener('click', () => {
      this.start.setValue(_moment().startOf('year').toISOString());
      this.end.setValue(_moment().endOf('year').toISOString());
      this.reOpenCalendar();
    });

    this.handleDateUpdated();
  }

  deleteDatepickerRangePresetsComponent(): void {
    document.querySelector('#datepicker-range-presets')?.remove();
  }

  handleDateUpdated(): void {
    if (_moment(this.start.value).toISOString() && _moment(this.end.value).toISOString()) {
      this.rangePresetSelected = '';

      document
        ?.querySelector('#datepicker-range-presets')
        ?.querySelectorAll('p')
        ?.forEach((preset) => {
          preset.classList.remove('selected');
        });

      if (
        _moment(this.start.value).toISOString().split('T')[0] == _moment().toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-0')?.classList.add('selected');
        this.rangePresetSelected = 'Hoy';
      } else if (
        _moment(this.start.value).toISOString().split('T')[0] ==
        _moment().startOf('week').toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().endOf('week').toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-1')?.classList.add('selected');
        this.rangePresetSelected = 'Semana actual';
      } else if (
        _moment(this.start.value).toISOString().split('T')[0] ==
        _moment().subtract(2, 'weeks').toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-2')?.classList.add('selected');
        this.rangePresetSelected = 'Últimas 2 semanas';
      } else if (
        _moment(this.start.value).toISOString().split('T')[0] ==
        _moment().startOf('month').toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().endOf('month').toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-3')?.classList.add('selected');
        this.rangePresetSelected = 'Mes actual';
      } else if (
        _moment(this.start.value).toISOString().split('T')[0] ==
        _moment().subtract(6, 'months').toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-4')?.classList.add('selected');
        this.rangePresetSelected = 'Último semestre';
      } else if (
        _moment(this.start.value).toISOString().split('T')[0] ==
        _moment().startOf('year').toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().endOf('year').toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-5')?.classList.add('selected');
        this.rangePresetSelected = 'Año actual';
      }
    }
  }

  reOpenCalendar() {
    this.picker.close();

    setTimeout(() => {
      this.picker.open();
    }, 0);
  }

  getModuleTitle(): string {
    const activeSubItem = this.findActiveSubItem();

    if (activeSubItem.parent.id === 6 || activeSubItem.parent.id === 7) {
      // Vigilancia legislativa
      if (this.router.url.startsWith('/vigilancia/tnor')) {
        return `${activeSubItem?.child?.items.find((i) => this.router.url.startsWith(i.path)).parent_module} | ${activeSubItem?.child?.items.find((i) => this.router.url.startsWith(i.path)).title
          }`;
      } else {
        return activeSubItem?.child?.items.find((i) => this.router.url.includes(i.path)).title ?? '';
      }
    } else {
      return activeSubItem?.parent?.header ?? '';
    }
  }

  findActiveSubItem(): { parent: MenuItem; child: NavItem } | null {
    for (const menuItem of this.authStateService.userConfig.interface_config.menu_items) {
      for (const subItem of menuItem.items) {
        if (subItem.path && window.location.href.includes(subItem.path)) {
          return {
            parent: menuItem,
            child: subItem,
          };
        }
      }
    }

    return null;
  }

  getNumberOfPages(): number {
    if (this.paginator) {
      return Math.ceil(this.resultsLength / this.paginator.pageSize);
    }

    return 0;
  }

  getActualPage(): number {
    if (this.paginator) {
      this.paginator.pageIndex;
    }

    return 0;
  }

  openSearchTypeDialog(): void {
    this.matBottomSheet.open(SearchTypeDialogComponent, {
      autoFocus: false,
      panelClass: 'panel-bottom-sheet-w-100',
    });
  }

  openSearchTypeOperatorsDialog(): void {
    const dialog = this.dialog.open(SearchTypeOperatorsDialogComponent, {
      autoFocus: false,
      width: '100%',
      maxWidth: '790px',
      data: {
        text: this.value,
      },
    });

    dialog.afterClosed().subscribe((data) => {
      this.value = data;
    });
  }

  getTipoAndOrigenSearch(): { tipo: string; origen: string } {
    if (this.clipping) {
      return {
        tipo: 'clipping',
        origen:
          (this._appStateService.searchFilters.clippingsSelected.escrita &&
            this._appStateService.searchFilters.clippingsSelected.digital) ||
            (!this._appStateService.searchFilters.clippingsSelected.escrita &&
              !this._appStateService.searchFilters.clippingsSelected.digital)
            ? 'ambas'
            : this._appStateService.searchFilters.clippingsSelected.escrita
              ? 'escrita'
              : this._appStateService.searchFilters.clippingsSelected.digital
                ? 'digital'
                : '',
      };
    } else if (this.router.url.includes('documents')) {
      return {
        origen: 'documents',
        tipo: 'documents',
      };
    } else if (this.router.url.includes('regulatorio')) {
      return {
        origen: this.findActiveSubItem().child.title,
        tipo: 'regulatorio',
      };
    } else if (this.router.url.includes('vigilancia')) {
      return {
        origen: this.findActiveSubItem().child.title,
        tipo: 'vigilancia',
      };
    } else if (this.router.url.includes('europe')) {
      const europeMenuItem = this.authStateService.userConfig.interface_config.menu_items.find((mi) => mi.id === 7);

      if (europeMenuItem) {
        switch (europeMenuItem.header) {
          case 'Europa':
            return {
              origen: this.findActiveSubItem().child.title,
              tipo: 'europe',
            };
          case 'Unión Europea':
            return {
              origen: this.findActiveSubItem().child.title,
              tipo: 'europe-union',
            };
        }
      }
    }

    return {
      tipo: '',
      origen: '',
    };
  }

  clickTopSearch() {
    document.getElementById('main-search-icon')?.click();
  }

  getScoreColorByPercent(percent: string): string {
    const numPercent = Number(percent.replace('%', '').trim());
    const gradientLength = this.redYellowGreenColorsGradients.length;
    const index = Math.floor((numPercent / 100) * (gradientLength - 1));
    const color = this.redYellowGreenColorsGradients[index];
    return color;
  }

  removeQueryParamsFromUrl(url: string): string {
    return url.replace(/\?.*$/, '');
  }
}

export interface DateDataDialog {
  selected_date: any;
}

@Component({
  selector: 'date-click-dialog',
  templateUrl: 'date-click-dialog.html',
})
export class DateClickDialog {
  constructor(public dialogRef: MatDialogRef<DateClickDialog>, @Inject(MAT_DIALOG_DATA) public data: DateDataDialog) { }
}

@Component({
  selector: 'clipping-click-dialog',
  templateUrl: 'clipping-click-dialog.html',
})
export class ClippingClickDialog {
  constructor(public dialogRef: MatDialogRef<ClippingClickDialog>) { }
}
