import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, mergeMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import moment from 'moment';
import { Item } from '@app/models/Item';
import { environment } from '@env/environment';
import { AppStateService } from '@app/state/app/app.service';
import { DocumentService } from '../../services/document.service';
import { AppStatusService } from '@app/services/app-status.service';
import { SearchService, SearchServiceResult } from '@app/modules/main/components/search/services/search.service';
import { SearchRequest } from '@app/modules/main/components/search/services/search.request.service';
import { Spider } from '@app/models';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fav-docs',
  templateUrl: './fav-docs.component.html',
  styleUrls: ['./fav-docs.component.scss'],
})
export class FavDocsComponent implements OnInit {
  readonly LOGOURL: string = environment.logoApp;

  documentpanel: any;
  doc_item: any;
  selected: string;
  value = '';

  searchRequest: SearchRequest;
  searchObservable: Observable<SearchServiceResult>;
  items: Item[] = [];

  displayedColumns: string[] = ['title'];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  noFavs = true;
  verbarra = true;
  firstLoad: boolean;
  sortselect: string = '1';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private authStateService: AuthStateService,
    private sService: SearchService,
    private changeDetector: ChangeDetectorRef,
    private appStatus: AppStatusService,
    private _appStateService: AppStateService,
    private documentService: DocumentService,
    private router: Router
  ) {}

  ngOnInit() {
    this._appStateService.showTitle();
    this._appStateService.hideDates();
    this._appStateService.hideSearchText();
    this._appStateService.hideSubForm();

    this.search();

    this.appStatus.favorite_delete.subscribe((ev) => {
      let myfilter = this.items;
      this.items = myfilter.filter((item) => {
        return item.id_source != ev;
      });

      this.closepanel();
    });
  }

  opendocument(docrow: any) {
    // this.router.navigateByUrl(`documents/open/${docrow.id_source}`);
    this.verbarra = false;
    this.doc_item = docrow;
    this.documentpanel = docrow;
    this.changeDetector.detectChanges();
  }

  closepanel() {
    this.documentpanel = undefined;
    this.verbarra = true;
    this._appStateService.showTitle();
    this._appStateService.hideDates();
    this._appStateService.hideSubForm();

    setTimeout(() => this._appStateService.hideSearchText(), 100);
  }

  private search(searchRequest?: SearchRequest): void {
    this.noFavs = false;
    this.changeDetector.detectChanges();
    this.searchRequest = searchRequest;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.sService!.getFavorites();
        }),
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.count;

          return data.results;
        }),
        catchError((error) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.items = data.map((element) => {
          if (element.document) {
            return {
              id_source: element.docId,
              id_db: element.id,
              date_published: moment(element.document.dateParsed).format('LLLL'),
              title: element.document.titleParsed || '',
              abstract: element.document.abstract || '',
              highligth: element.document.abstract || '',
              lang: element.document.lang || '',
              doc_source: this.findSpiderById(element.document.spiderId)?.name ?? '-',
              _favorite: element.document._favorite || '',
              source: {
                name: element.document.spiderId || '',
                type: element.document.docType || '',
              },
              link: element.document.url || '',
              entities: element.document.entities || '',
              is_echo: false,
              location: null,
            };
          }

          this.documentService.refreshToolbarCounterFavoriteDocuments$.next(this.items.length);

          return {
            title: element.name,
            date_parsed: moment(element.created_at).format('LL'),
          };
        });
      });
  }

  findSpiderById(id: number): Spider | undefined {
    for (const mapping of this.authStateService.userConfig.config.Documentos.SpiderGroups.map(
      (sg) => sg.spiders
    ).reduce((acc, val) => acc.concat(val), [])) {
      if (mapping.id == id) {
        return mapping;
      }
    }

    return undefined;
  }
}
