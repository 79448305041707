import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";
import {
  PalliativeCaresFilters,
  PalliativeCaresFiltersBackend,
  PalliativeCaresGetResponse,
  PalliativeCaresStateModel,
} from "../models/regulatory";
import {
  GetPalliativeCares,
  GetPalliativeCaresFiltersBackend,
  TogglePalliativeCaresMenuFilters,
  UpdatePalliativeCaresFilters,
} from "./regulatory.actions";

@Injectable({
  providedIn: "root",
})
@State<PalliativeCaresStateModel>({
  name: "palliativeCares",
  defaults: {
    palliativeCares: {
      items: [],
      page: 0,
      size: 0,
      total: 0,
      total_pages: 0,
    },
    openedPalliativeCaresMenuFilters: true,
  },
})
export class PalliativeCaresState {
  @Selector()
  static palliativeCares(
    state: PalliativeCaresStateModel
  ): PalliativeCaresGetResponse {
    return state.palliativeCares;
  }

  @Selector()
  static palliativeCaresFilters(
    state: PalliativeCaresStateModel
  ): PalliativeCaresFilters {
    return state.palliativeCaresFilters;
  }

  @Selector()
  static palliativeCaresFiltersBackend(
    state: PalliativeCaresStateModel
  ): PalliativeCaresFiltersBackend {
    return state.palliativeCaresFiltersBackend;
  }

  @Selector()
  static openedPalliativeCaresMenuFilters(
    state: PalliativeCaresStateModel
  ): boolean {
    return state.openedPalliativeCaresMenuFilters;
  }

  @Action(GetPalliativeCares)
  getPalliativeCares(
    { getState, patchState }: StateContext<PalliativeCaresStateModel>,
    response: { payload: PalliativeCaresGetResponse }
  ) {
    const state = getState();
    let palliativeCares = state.palliativeCares;
    palliativeCares = response.payload;
    patchState({ palliativeCares });
    // patchState({
    //   palliativeCares: {
    //     items: palliativeCares as any,
    //     page: 1,
    //     size: 30,
    //     total: 5,
    //     total_pages: 1,
    //   },
    // });
  }

  @Action(UpdatePalliativeCaresFilters)
  updatePalliativeCaresFilters(
    { getState, patchState }: StateContext<PalliativeCaresStateModel>,
    response: { payload: PalliativeCaresFilters }
  ) {
    const state = getState();
    let palliativeCaresFilters = state.palliativeCaresFilters;
    palliativeCaresFilters = response.payload;
    patchState({ palliativeCaresFilters });
  }

  @Action(GetPalliativeCaresFiltersBackend)
  getPalliativeCaresFiltersBackend(
    { getState, patchState }: StateContext<PalliativeCaresStateModel>,
    response: { payload: PalliativeCaresFiltersBackend }
  ) {
    const state = getState();
    let palliativeCaresFiltersBackend = state.palliativeCaresFiltersBackend;
    palliativeCaresFiltersBackend = response.payload;
    patchState({ palliativeCaresFiltersBackend });
  }

  @Action(TogglePalliativeCaresMenuFilters)
  togglePalliativeCaresMenuFilters({
    getState,
    patchState,
  }: StateContext<PalliativeCaresStateModel>) {
    const state = getState();
    const openedPalliativeCaresMenuFilters =
      !state.openedPalliativeCaresMenuFilters;
    patchState({ openedPalliativeCaresMenuFilters });
  }
}
