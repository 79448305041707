import { ENTER } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert, TenderSearch, TendersFilters } from '@app/modules/ceoe/models/tender';
import { GetProvinces, GetTenders } from '@app/modules/ceoe/store/tenders/tenders.actions';
import { TendersStateService } from '@app/modules/ceoe/store/tenders/tenders.service';
import { OperatorsDialogComponent } from '@app/modules/shared/components/operators-dialog/operators-dialog.component';
import { UtilsService } from '@app/services/utils.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import moment from 'moment';

export interface Chip {
  name: string;
  type: 'text' | 'operator';
  editing: boolean;
}

@Component({
  selector: 'app-tenders-search-filters',
  templateUrl: './tenders-search-filters.component.html',
  styleUrls: ['./tenders-search-filters.component.scss'],
})
export class TendersSearchFiltersComponent implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER];

  filtersForm: FormGroup;
  filtersDateRangeForm: FormGroup;
  objectChipFormArray: FormArray;
  loading = false;
  applyingFilters = false;
  selectedSectoresInput = [];
  selectedOrganismoInput = [];

  dropdownProvinciasList = [];
  selectedProvinciasItems = [];
  dropdownProvinciasSettings = {
    text: 'Provincias',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  constructor(
    public tendersStateService: TendersStateService,
    private fb: FormBuilder,
    private actions: Actions,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.filtersForm = this.fb.group({
      provinces: [[]],
      expediente: [''],
      objeto: [],
      organismo: [''],
      importe_minimo: [],
      importe_maximo: [],
    });

    this.objectChipFormArray = this.fb.array([]);

    this.filtersDateRangeForm = this.fb.group({
      start: [moment('01/01/2023', 'DD/MM/YYYY').toDate()],
      end: [moment().toDate()],
    });

    const subscription$ = this.activatedRoute.queryParams.subscribe((params) => {
      setTimeout(() => {
        // this.location.replaceState('/tenders/search');
        subscription$.unsubscribe();
      }, 50);

      this.applyingFilters = true;
      if (params.withFiltersFromSavedSearches || params.withFiltersFromAlerts) {
        let tenderSearch: TenderSearch | undefined = undefined;
        let tenderAlert: Alert | undefined = undefined;

        if (params.withFiltersFromSavedSearches) {
          tenderSearch = this.tendersStateService.tenderSearchs.items.find(
            (ts) => ts.id === Number(params.withFiltersFromSavedSearches)
          );

          setTimeout(() => {
            if (tenderSearch) {
              this.selectedProvinciasItems = tenderSearch.provincias.map((p) => {
                return this.dropdownProvinciasList.find((dp) => dp.id === Number(p));
              });

              this.selectedSectoresInput = tenderSearch.cpvs;
              this.selectedOrganismoInput = tenderSearch.organismo;

              this.filtersForm.patchValue({
                expediente: tenderSearch.expediente,
                objeto: tenderSearch.objeto,
                organismo: tenderSearch.organismo,
                importe_minimo: tenderSearch.importe_minimo,
                importe_maximo: tenderSearch.importe_maximo,
              });

              this.setObjetoFieldValues(tenderSearch.objeto);

              this.filtersDateRangeForm.patchValue({
                start: tenderSearch.publicacion_desde
                  ? moment(tenderSearch.publicacion_desde, 'DD/MM/YYYY').toDate()
                  : undefined,
                end: tenderSearch.publicacion_hasta
                  ? moment(tenderSearch.publicacion_hasta, 'DD/MM/YYYY').toDate()
                  : undefined,
              });

              this.applyFilters();
            }
            this.applyingFilters = false;
          }, 2000);
        } else if (params.withFiltersFromAlerts) {
          tenderAlert = this.tendersStateService.alerts.items.find(
            (ts) => ts.id === Number(params.withFiltersFromAlerts)
          );

          setTimeout(() => {
            if (tenderAlert) {
              this.selectedProvinciasItems = tenderAlert.busqueda.provincias.map((p) => {
                return this.dropdownProvinciasList.find((dp) => dp.id === Number(p));
              });

              this.selectedSectoresInput = tenderAlert.busqueda.cpvs;
              this.selectedOrganismoInput = tenderAlert.busqueda.organismo;

              this.filtersForm.patchValue({
                expediente: tenderAlert.busqueda.expediente,
                objeto: tenderAlert.busqueda.objeto,
                organismo: tenderAlert.busqueda.organismo,
                importe_minimo: tenderAlert.busqueda.importes[0].de,
                importe_maximo: tenderAlert.busqueda.importes[0].hasta,
              });

              this.setObjetoFieldValues(tenderAlert.busqueda.objeto);

              this.filtersDateRangeForm.patchValue({
                start: tenderAlert.busqueda.publicacion_desde
                  ? moment(tenderAlert.busqueda.publicacion_desde, 'DD/MM/YYYY').toDate()
                  : undefined,
                end: tenderAlert.busqueda.publicacion_hasta
                  ? moment(tenderAlert.busqueda.publicacion_hasta, 'DD/MM/YYYY').toDate()
                  : undefined,
              });

              this.applyFilters();
            }
            this.applyingFilters = false;
          }, 2000);
        }
      } else {
        setTimeout(() => {
          this.selectedProvinciasItems = this.tendersStateService.tendersFilters.provincias.map((p) => {
            return this.dropdownProvinciasList.find((dp) => dp.id === Number(p));
          });

          this.selectedSectoresInput = this.tendersStateService.tendersFilters.cpvs;
          this.selectedOrganismoInput = this.tendersStateService.tendersFilters.organismo;

          this.filtersForm.patchValue({
            expediente: this.tendersStateService.tendersFilters.expediente,
            objeto: this.tendersStateService.tendersFilters.objeto,
            organismo: this.tendersStateService.tendersFilters.organismo,
            importe_minimo: this.tendersStateService.tendersFilters.importe_minimo,
            importe_maximo: this.tendersStateService.tendersFilters.importe_maximo,
          });

          this.setObjetoFieldValues(this.tendersStateService.tendersFilters.objeto);

          this.filtersDateRangeForm.patchValue({
            start: this.tendersStateService.tendersFilters.publicacion_desde
              ? moment(this.tendersStateService.tendersFilters.publicacion_desde, 'DD/MM/YYYY').toDate()
              : moment('01/01/2023', 'DD/MM/YYYY').toDate(),

            end: this.tendersStateService.tendersFilters.publicacion_hasta
              ? moment(this.tendersStateService.tendersFilters.publicacion_hasta, 'DD/MM/YYYY').toDate()
              : moment().toDate(),
          });

          this.applyFilters();
          this.applyingFilters = false;
        }, 2000);
      }
    });

    this.actions.pipe(ofActionSuccessful(GetTenders)).subscribe(() => {
      this.loading = false;
    });
  }

  ngOnInit(): void {
    this.populateProvinces();
  }

  applyFilters(): void {
    this.filtersForm.markAllAsTouched();
    this.filtersDateRangeForm.markAllAsTouched();

    if (this.filtersForm.valid && this.filtersDateRangeForm.valid) {
      this.loading = true;

      const startDate: Date | undefined = this.filtersDateRangeForm.controls.start.value;
      const endDate: Date | undefined = this.filtersDateRangeForm.controls.end.value;
      const object = [];

      const firstObject = this.objectChipFormArray.at(0) as FormGroup;
      const lastObject = this.objectChipFormArray.at(this.objectChipFormArray.length - 1) as FormGroup;

      if (firstObject && firstObject.controls.type.value === 'operator') {
        this.removeObjeto(0);
      }

      if (lastObject && lastObject.controls.type.value === 'operator') {
        this.removeObjeto(this.objectChipFormArray.length - 1);
      }

      this.objectChipFormArray.controls.forEach((c) => {
        const fg = c as FormGroup;
        object.push({
          name: fg.controls.name.value,
          type: fg.controls.type.value,
        });
      });

      const request: TendersFilters = {
        provincias: this.selectedProvinciasItems.map((spi) => String(spi.id)),
        cpvs: this.selectedSectoresInput.map((ssi) => String(ssi)),
        organismo: this.selectedOrganismoInput.map((soi) => String(soi)),
        publicacion_desde: startDate ? moment(startDate).format('DD/MM/YYYY').toString() : undefined,
        publicacion_hasta: endDate ? moment(endDate).format('DD/MM/YYYY').toString() : undefined,
        expediente: this.filtersForm.controls.expediente.value,
        objeto: object,
        importe_minimo: this.filtersForm.controls.importe_minimo.value,
        importe_maximo: this.filtersForm.controls.importe_maximo.value,
      };

      this.tendersStateService.updateTendersFilters(request);

      setTimeout(() => {
        this.loading = false;
      }, 3000);
    }
  }

  populateProvinces(): void {
    this.actions.pipe(ofActionSuccessful(GetProvinces)).subscribe(() => {
      this.dropdownProvinciasList = this.tendersStateService.provinces.map((p) => {
        return {
          id: p.code,
          itemName: p.nombre,
          name: this.utilsService.removeDiacritics(p.nombre),
        };
      });
    });

    this.tendersStateService.getProvinces();
  }

  resetFilters(): void {
    this.filtersForm.patchValue({
      provinces: [],
      expediente: '',
      objeto: '',
      organismo: '',
      importe_minimo: undefined,
      importe_maximo: undefined,
    });

    this.objectChipFormArray = this.fb.array([]);

    this.filtersDateRangeForm.patchValue({
      start: moment('01/01/2023', 'DD/MM/YYYY').toDate(),
      end: moment().toDate(),
    });

    this.selectedSectoresInput = [];
    this.selectedOrganismoInput = [];

    if (this.router.url.includes('withFiltersFromAlerts')) {
      this.router.navigate(['tenders', 'search']);
    }
  }

  setSelectedSectores(data: any[]): void {
    this.selectedSectoresInput = data;
  }

  setSelectedOrganismo(data: string[]): void {
    this.selectedOrganismoInput = data;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  showOperatorsDialog(): void {
    this.dialog.open(OperatorsDialogComponent, {
      width: '100%',
      maxWidth: '650px',
    });
  }

  addObjeto(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.objectChipFormArray.push(this.fb.group({ name: [value.trim()], type: 'text', editing: false }));
      this.objectChipFormArray.push(this.fb.group({ name: ['AND'], type: 'operator', editing: false }));
    }

    if (input) {
      input.value = '';
    }
  }

  addOperator(): void {
    const lastObject = this.objectChipFormArray.at(this.objectChipFormArray.length - 1) as FormGroup;

    if (lastObject && lastObject.controls.type.value !== 'operator') {
      this.objectChipFormArray.push(this.fb.group({ name: ['AND'], type: 'operator', editing: false }));
    }
  }

  removeObjeto(index: number): void {
    this.objectChipFormArray.removeAt(index);
  }

  editObjeto(index: number): void {
    this.objectChipFormArray.at(index).get('editing')?.setValue(true);

    if (this.objectChipFormArray.at(index).get('type')?.value === 'operator') {
      if (this.objectChipFormArray.at(index).get('name')?.value === 'AND') {
        this.objectChipFormArray.at(index).get('name')?.setValue('OR');
      } else {
        this.objectChipFormArray.at(index).get('name')?.setValue('AND');
      }
    }
  }

  saveObjeto(index: number): void {
    const chipFormGroup = this.objectChipFormArray.at(index);
    if (chipFormGroup.valid) {
      const name = chipFormGroup.get('name')?.value;
      this.objectChipFormArray.at(index).get('name')?.setValue(name);
      this.objectChipFormArray.at(index).get('editing')?.setValue(false);
    }
  }

  splitStringWithOperators(input: string): string[] {
    const regex = /\s*(AND|OR)\s*/;
    return input.split(regex).filter((str) => str.trim() !== '');
  }

  setObjetoFieldValues(values: any): void {
    values.forEach((v) => {
      const formGroup = this.fb.group({
        name: v.name,
        type: v.type,
        editing: false,
      });

      this.objectChipFormArray.push(formGroup);
    });
  }
}
