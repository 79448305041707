import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchService } from '@app/modules/main/components/search/services/search.service';

@Component({
  selector: 'app-dialogalert',
  templateUrl: './dialogalert.component.html',
  styleUrls: ['./dialogalert.component.css'],
})
export class DialogAlertComponent implements OnInit {
  mode: string;
  id: number;
  period: number;
  instant_execution: number;
  CrearButton: boolean = false;
  DelButton: boolean = false;

  public options_period = [
    { id: 15, name: '15 minutos' },
    { id: 30, name: '30 minutos' },
    { id: 60, name: '1 hora' },
    { id: 120, name: '2 horas' },
    { id: 240, name: '4 horas' },
    { id: 480, name: '8 horas' },
    { id: 1440, name: '24 horas' },
    { id: 10080, name: '7 días' },
  ];
  public options_instant_execution = [
    { id: 0, name: 'A en punto' },
    { id: 15, name: 'A y cuarto' },
    { id: 30, name: 'A y media' },
    { id: 45, name: 'A menos cuarto' },
  ];

  thedata: any;

  constructor(
    public dialogRef: MatDialogRef<DialogAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sService: SearchService
  ) {
    if (data.alert != null) {
      this.period = data.alert.period;
      this.instant_execution = data.alert.instant_execution;
    }
  }

  ngOnInit() {}

  delAlerta() {
    this.DelButton = true;
    if (this.period) {
      let param = { name: this.thedata.name, data: this.thedata.query };
      this.sService.saveAlertSearch(this.id, param).subscribe((ev) => {
        this.dialogRef.close(null);
      });
    }
  }

  addAlerta() {
    this.CrearButton = true;
    if (this.period) {
      if (this.instant_execution == undefined || this.period <= 30) this.instant_execution = 0;

      let alert = { period: this.period, instant_execution: this.instant_execution };
      let param = { name: this.data.name, data: this.data.query, alert: alert };
      this.sService.saveAlertSearch(this.id, param).subscribe((ev) => {
        this.dialogRef.close(alert);
      });
    }
  }
}
