import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import jwt_decode from 'jwt-decode';
import { KeycloakToken } from '@app/modules/documents/store/models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private keycloakService: KeycloakService) {}

  async getToken(): Promise<string | null> {
    try {
      const isLoggedIn = await this.keycloakService.isLoggedIn();

      if (isLoggedIn) {
        return await this.keycloakService.getToken();
      } else {
        await this.keycloakService.login();
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async getTokenInfo(): Promise<KeycloakToken | null> {
    const token = await this.getToken();

    if (token) {
      try {
        return jwt_decode(token);
      } catch (error) {
        console.error('Failed to decode token', error);
        return null;
      }
    }

    return null;
  }
}
