import { Component, OnInit, Input } from '@angular/core';
import { SpiderStateService } from '../../store/spider/spider.service';

export interface Section {
  name: string;
  updated: Date;
}

@Component({
  selector: 'app-latest-spiders',
  templateUrl: './latest-spiders.component.html',
  styleUrls: ['./latest-spiders.component.less'],
})
export class LatestSpidersComponent implements OnInit {
  @Input() spiders: number[];

  constructor(public spiderStateService: SpiderStateService) {}

  ngOnInit(): void {
    this.spiderStateService.getLatestSpiders();
    this.spiderStateService.getImportantSpiders({ spiders: this.spiders });
  }
}
