import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiselectSearchFixDirective } from './app-multiselect-search-fix.directive';
import { MultiselectSearchAccentsDirective } from './app-multiselect-search-accents.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [MultiselectSearchFixDirective, MultiselectSearchAccentsDirective],
  exports: [MultiselectSearchFixDirective, MultiselectSearchAccentsDirective],
})
export class DirectivesModule {}
