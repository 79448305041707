import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogYesNo } from '../../models/DialogYesNo';

@Component({
  selector: 'app-msgyesno',
  templateUrl: './msgyesno.component.html',
  styleUrls: ['./msgyesno.component.css'],
})
export class MsgyesnoComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<MsgyesnoComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogYesNo) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}
}
