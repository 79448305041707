import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SubventionsFilters } from '@app/modules/ceoe/models/subvention';
import { PostAlert, PutAlert } from '@app/modules/ceoe/store/subventions/subventions.actions';
import { SubventionsStateService } from '@app/modules/ceoe/store/subventions/subventions.service';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import moment from 'moment';

@Component({
  selector: 'app-subventions-search-create-alert-dialog',
  templateUrl: './subventions-search-create-alert-dialog.component.html',
  styleUrls: ['./subventions-search-create-alert-dialog.component.scss'],
})
export class SubventionsSearchCreateAlertDialogComponent {
  saveSearchForm: FormGroup;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<SubventionsSearchCreateAlertDialogComponent>,
    private subventionsStateService: SubventionsStateService,
    private snackbarStateService: SnackbarStateService,
    private actions: Actions,
    private router: Router
  ) {
    this.saveSearchForm = this.fb.group({
      titulo: [
        this.subventionsStateService.alerts.items.find((ts) => ts.id === Number(this.data.withFiltersFromAlerts))
          ?.titulo ?? '',
        Validators.required,
      ],
    });
  }

  save(): void {
    this.saveSearchForm.markAllAsTouched();

    if (this.saveSearchForm.valid) {
      this.loading = true;

      if (!this.data.withFiltersFromAlerts) {
        this.actions.pipe(ofActionSuccessful(PostAlert)).subscribe(() => {
          this.close();
          this.loading = false;
          this.snackbarStateService.show('Alerta creada correctamente', 3000, 'cerrar');

          this.router.navigate(['subventions', 'search'], {
            queryParams: {
              withFiltersFromAlerts:
                this.subventionsStateService.alerts.items[this.subventionsStateService.alerts.items.length - 1].id,
            },
          });
        });

        this.subventionsStateService.postAlert(this.getRequest());
      } else {
        this.actions.pipe(ofActionSuccessful(PutAlert)).subscribe(() => {
          this.close();
          this.loading = false;
          this.snackbarStateService.show('Alerta creada correctamente', 3000, 'cerrar');
        });

        this.subventionsStateService.putAlert(this.getRequest(), this.data.withFiltersFromAlerts);
      }
    }
  }

  getRequest(): any {
    return {
      titulo: this.saveSearchForm.controls.titulo.value,
      // quien: this.subventionsStateService.filters.selectedFilters.quien,
      // accion: this.subventionsStateService.filters.selectedFilters.accion,
      texto: this.subventionsStateService.filters.selectedFilters.texto,
      presupuesto: this.subventionsStateService.filters.selectedFilters.presupuesto,
      estado: this.subventionsStateService.filters.selectedFilters.estado,
      fondos: this.subventionsStateService.filters.selectedFilters.fondos,
      instrumento_ayuda: this.subventionsStateService.filters.selectedFilters.instrumento_ayuda,
      comunidades: this.subventionsStateService.filters.selectedFilters.comunidades,
      provincias: this.subventionsStateService.filters.selectedFilters.provincias,
      sector_economico: this.subventionsStateService.filters.selectedFilters.sector_economico,
      organismo_convocante: this.subventionsStateService.filters.selectedFilters.organismo_convocante,
      finalidad: this.subventionsStateService.filters.selectedFilters.finalidad,
      beneficiarios: this.subventionsStateService.filters.selectedFilters.beneficiarios,
      fecha_publicacion_desde: this.subventionsStateService.filters.selectedFilters.fecha_publicacion_desde,
      fecha_publicacion_hasta: this.subventionsStateService.filters.selectedFilters.fecha_publicacion_hasta,
      fecha_finalizacion_desde: this.subventionsStateService.filters.selectedFilters.fecha_finalizacion_desde,
      fecha_finalizacion_hasta: this.subventionsStateService.filters.selectedFilters.fecha_finalizacion_hasta,
    };
  }

  close(): void {
    this.matDialogRef.close();
  }
}
