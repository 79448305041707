import { Component, OnInit, Inject, ElementRef, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentService } from '../../services/document.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialogemail',
  templateUrl: './dialogemail.component.html',
  styleUrls: ['./dialogemail.component.css'],
})
export class DialogEmailComponent implements OnInit {
  menssage: string;
  emailto: string;

  email = new FormControl('', [Validators.required, Validators.email]);

  getErrorMessage() {
    return this.email.hasError('required')
      ? 'Es un campo requerido'
      : this.email.hasError('email')
      ? 'No parece un email válido'
      : '';
  }

  constructor(
    private sServiceDoc: DocumentService,
    public dialogRef: MatDialogRef<DialogEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  enviarEmail() {
    if (this.menssage.length > 0) {
      this.sServiceDoc.emailSend(this.menssage, this.email.value, this.data.docid).subscribe((ev) => {
        this.dialogRef.close(ev);
      });
    }
  }
}
