<h1 mat-dialog-title>Modificar título</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Título de la noticia</mat-label>
    <input matInput [(ngModel)]="data.title" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancelar</button>
  <button mat-button [mat-dialog-close]="data.title" cdkFocusInitial>Actualizar</button>
</div>
