import { Subvention, SubventionsFilters, SubventionsGetResponse } from '../../models/subvention';

export class GetSubventions {
  static readonly type = '[GetSubventions] action';
  constructor(public payload: SubventionsGetResponse) {}
}

export class GetSubventionsAlerts {
  static readonly type = '[GetSubventionsAlerts] action';
  constructor(public payload: SubventionsGetResponse) {}
}

export class DeleteAlert {
  static readonly type = '[DeleteAlert] action';
  constructor(public payload: number) {}
}

export class PostAlert {
  static readonly type = '[PostAlert] action';
  constructor(public payload: Subvention) {}
}

export class PutAlert {
  static readonly type = '[PutAlert] action';
  constructor(public payload: Subvention) {}
}

export class GetSubventionsFavorites {
  static readonly type = '[GetSubventionsFavorites] action';
  constructor(public payload: SubventionsGetResponse) {}
}

export class GetSubvention {
  static readonly type = '[GetSubvention] action';
  constructor(public payload: Subvention) {}
}

export class GetFilters {
  static readonly type = '[GetFilters] action';
  constructor(
    public payload: {
      id: number;
      seccion: string;
      attr: string;
      items: {
        label: string;
        value: number;
      }[];
    }[]
  ) {}
}

export class ToggleSubventionsMenu {
  static readonly type = '[ToggleSubventionsMenu] action';
}

export class UpdateSubventionsMenuFilters {
  static readonly type = '[UpdateSubventionsMenuFilters] action';
  constructor(public payload: SubventionsFilters) {}
}

export class UpdateSubventionFavorite {
  static readonly type = '[UpdateSubventionFavorite] action';
  constructor(public payload: number) {}
}
