<div class="container">
  <!-- <p class="title">Buscador de subvenciones - {{ id }}</p> -->
  <div class="loader" *ngIf="loading">
    <mat-spinner [diameter]="32"></mat-spinner>
    <p>Cargando datos...</p>
  </div>
  <div class="container-card" *ngIf="!loading && collectiveAgreement">
    <div class="header">
      <p>{{ collectiveAgreement.denominacion }}</p>
      <!-- <button mat-icon-button (click)="saveItem()" [ngClass]="{ favorite: subvention.favorito }">
        <mat-icon>folder_special</mat-icon>
      </button> -->
    </div>
    <div class="content">
      <div class="sections">
        <div class="section">
          <mat-icon>pin</mat-icon>
          <div class="content">
            <p class="name">Cód. Convenio</p>
            <p class="value">{{ collectiveAgreement.cod_convenio }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>flag</mat-icon>
          <div class="content">
            <p class="name">Naturaleza</p>
            <p class="value">{{ collectiveAgreement.naturaleza }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>event</mat-icon>
          <div class="content">
            <p class="name">Publicación</p>
            <p class="value">
              {{ collectiveAgreement.fecha_publicacion | date }}
            </p>
          </div>
        </div>
        <div class="section">
          <mat-icon>event</mat-icon>
          <div class="content">
            <p class="name">Vigencia</p>
            <p class="value">
              {{ collectiveAgreement.fecha_vigencia.from | date }} - {{ collectiveAgreement.fecha_vigencia.to | date }}
            </p>
          </div>
        </div>
        <div class="section">
          <mat-icon>category</mat-icon>
          <div class="content">
            <p class="name">Estado de vigencia</p>
            <p class="value">{{ collectiveAgreement.estado_vigencia }}</p>
          </div>
        </div>
        <!-- <div class="section">
          <mat-icon>location_on</mat-icon>
          <div class="content">
            <p class="name">Ámbito geográfico</p>
            <p class="value">{{ collectiveAgreement.location }}</p>
          </div>
        </div> -->
        <div class="section">
          <mat-icon>public</mat-icon>
          <div class="content">
            <p class="name">Ámbito territorial</p>
            <p class="value">{{ collectiveAgreement.ambito_territorial }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>person</mat-icon>
          <div class="content">
            <p class="name">Ámbito personal</p>
            <p class="value">{{ collectiveAgreement.ambito_personal }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>apartment</mat-icon>
          <div class="content">
            <p class="name">Ámbito funcional</p>
            <p class="value">{{ collectiveAgreement.ambito_funcional }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>paid</mat-icon>
          <div class="content">
            <p class="name">CNAE</p>
            <p class="value">{{ getCNAESMapped() }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>groups</mat-icon>
          <div class="content">
            <p class="name">Centrales sindicales</p>
            <p class="value">• {{ collectiveAgreement.sindicatos.join('\n• ') }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>schedule</mat-icon>
          <div class="content">
            <p class="name">Horas</p>
            <p class="value">Año: {{ collectiveAgreement.horas.year || '-' }}</p>
            <p class="value">Semana: {{ collectiveAgreement.horas.week || '-' }}</p>
          </div>
        </div>
        <div class="section section-full-row">
          <mat-icon>percent</mat-icon>
          <div class="content">
            <p class="name">Variación salarial</p>
            <p class="value">{{ collectiveAgreement.variacion_salarial }}</p>
            <p
              class="value"
              *ngIf="
                collectiveAgreement.periodo_condiciones_salariales?.from ||
                collectiveAgreement.periodo_condiciones_salariales?.to
              "
            >
              Período de aplicación:
              {{
                collectiveAgreement.periodo_condiciones_salariales.from
                  ? (collectiveAgreement.periodo_condiciones_salariales.from | date)
                  : '*'
              }}
              -
              {{
                collectiveAgreement.periodo_condiciones_salariales.to
                  ? (collectiveAgreement.periodo_condiciones_salariales.to | date)
                  : '*'
              }}
            </p>
            <!-- <p
              class="value"
              *ngIf="
                !collectiveAgreement.periodo_condiciones_salariales?.from &&
                !collectiveAgreement.periodo_condiciones_salariales?.to
              "
            >
              -
            </p> -->
          </div>
        </div>
        <div class="section section-full-row" style="min-width: 100% !important">
          <table mat-table [dataSource]="centralesSindicalesTableData">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center; gap: 10px; min-height: 56px">
                  <mat-icon>business</mat-icon>
                  <p style="margin: 0">Centrales sindicales</p>
                </div>
              </th>
              <td mat-cell *matCellDef="let row">{{ row.name }}</td>
            </ng-container>
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef>N° total de representantes</th>
              <td mat-cell *matCellDef="let row">{{ row.total }}</td>
            </ng-container>
            <ng-container matColumnDef="mujeres">
              <th mat-header-cell *matHeaderCellDef>N° de mujeres</th>
              <td mat-cell *matCellDef="let row">{{ row.mujeres }}</td>
            </ng-container>
            <ng-container matColumnDef="hombres">
              <th mat-header-cell *matHeaderCellDef>N° de hombres</th>
              <td mat-cell *matCellDef="let row">{{ row.hombres }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="centralesSindicalesDisplayedColumns"></tr>
            <th scope="col" mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
              <p style="margin: 0">No hay datos</p>
            </th>
            <tr mat-row *matRowDef="let row; columns: centralesSindicalesDisplayedColumns"></tr>
          </table>
        </div>
        <div class="section section-full-row" style="min-width: 100% !important; margin-bottom: 16px">
          <table mat-table [dataSource]="representantesEmpresarialesTableData">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center; gap: 10px; min-height: 56px">
                  <mat-icon>groups</mat-icon>
                  <p style="margin: 0">Representación empresarial</p>
                </div>
              </th>
              <td mat-cell *matCellDef="let row">{{ row.name }}</td>
            </ng-container>
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef>N° total de representantes</th>
              <td mat-cell *matCellDef="let row">{{ row.total }}</td>
            </ng-container>
            <ng-container matColumnDef="mujeres">
              <th mat-header-cell *matHeaderCellDef>N° de mujeres</th>
              <td mat-cell *matCellDef="let row">{{ row.mujeres }}</td>
            </ng-container>
            <ng-container matColumnDef="hombres">
              <th mat-header-cell *matHeaderCellDef>N° de hombres</th>
              <td mat-cell *matCellDef="let row">{{ row.hombres }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="representantesEmpresarialesDisplayedColumns"></tr>
            <th scope="col" mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
              <p style="margin: 0">No hay datos</p>
            </th>
            <tr mat-row *matRowDef="let row; columns: representantesEmpresarialesDisplayedColumns"></tr>
          </table>
        </div>
        <!-- <div class="section">
          <mat-icon>public</mat-icon>
          <div class="content">
            <p class="name">Retribución salarial</p>
            <p class="value">*** FALTA ***</p>
          </div>
        </div> -->
      </div>
      <div class="pdf">
        <div class="chips">
          <mat-accordion class="chip" (click)="$event.stopPropagation()" *ngFor="let voz of collectiveAgreement.voces">
            <mat-expansion-panel style="overflow: visible">
              <mat-expansion-panel-header [ngStyle]="{ 'background-color': getVozColor(voz, '99') }">
                <mat-panel-title>{{ voz.name }}</mat-panel-title>
              </mat-expansion-panel-header>
              <div class="data" [ngStyle]="{ 'background-color': getVozColor(voz, '80') }">
                <p
                  [id]="voz.name + '-' + voz.value"
                  class="term"
                  *ngFor="let term of voz.terms"
                  (click)="search(voz, term)"
                >
                  {{ term }}
                </p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- <p
          style="
            font-weight: 400;
            background: #f1f1f1;
            border-radius: 6px;
            padding: 16px;
            max-height: 600px;
            overflow: auto;
          "
          [innerHTML]="textSafe"
          *ngIf="text && textSafe && !loading"
        ></p> -->
        <ngx-extended-pdf-viewer
          #mypdf
          id="mypdf"
          [src]="collectiveAgreement.s3_file_url"
          [useBrowserLocale]="true"
          [handTool]="false"
          [showHandToolButton]="true"
          [spread]="true"
          [width]="'600px'"
          [height]="'900px'"
          style="width: 100%; max-width: 880px"
          *ngIf="collectiveAgreement.s3_file_url"
        >
        </ngx-extended-pdf-viewer>
      </div>
      <!-- <a (click)="viewOfficialPublishment(collectiveAgreement.publicacion_oficial)">Ver publicación oficial</a> -->
      <!-- <button mat-button color="primary" (click)="downloadSummary()">
        <mat-icon>download</mat-icon>
        Descargar resumen
      </button> -->
    </div>
  </div>
  <!-- <button mat-flat-button class="return-button" (click)="return()">
    <mat-icon>chevron_left</mat-icon>
    <p>Volver</p>
  </button> -->
</div>
