import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-help-search-dialog',
  templateUrl: './help-search-dialog.component.html',
  styleUrls: ['./help-search-dialog.component.scss'],
})
export class HelpSearchDialogComponent {
  constructor(public dialogRef: MatDialogRef<HelpSearchDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
