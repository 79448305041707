import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { URL, URLQA, environment } from '@env/environment';
import _moment from 'moment';
import { IndicadorSearchRequest } from '../store/models/indicador.state.model';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, delay, mergeMap, retryWhen, take } from 'rxjs/operators';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { UtilsService } from '@app/services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class IndicadorService {
  RETRY_TIME = [500, 550, 600, 650, 700, 750];
  MAX_RETRIES = 5;

  indicadoresEpaURL = URLQA + 'indicadores/epa';
  indicadoresIpcURL = environment.mapas + '/api/ine/ipc';

  constructor(
    private _http: HttpClient,
    private snackbarStateService: SnackbarStateService,
    private utilsService: UtilsService
  ) {}

  public getIndicadoresEpa() {
    return this._http.get<any>(this.indicadoresEpaURL);
  }

  public getIndicadoresEpaResult(payload: IndicadorSearchRequest) {
    return this._http.post<any>(this.indicadoresEpaURL, payload);
  }

  public getIndicadoresIpc() {
    return this._http.get<any>(this.indicadoresIpcURL);
  }

  public getIndicadoresIpcResult(payload: IndicadorSearchRequest) {
    return this._http.post<any>(this.indicadoresIpcURL, payload);
  }

  getBusinessStatisticsCategories(request: { tematica: number }): Observable<any> {
    return this._http.post<any>(`${environment.statsCategories}`, request).pipe(
      retryWhen((errors) => {
        let retryCount = 0;

        return errors.pipe(
          mergeMap((error) => {
            if (retryCount < this.MAX_RETRIES) {
              retryCount++;
              return timer(this.RETRY_TIME[Math.floor(Math.random() * 3)]);
            } else {
              throw error;
            }
          }),
          take(this.MAX_RETRIES)
        );
      })
    );
    // return of(this.getBusinessStatisticsFakeKPIs()).pipe(delay(1000));
  }

  getBusinessStatisticsKPIs(request: { tematica: number }): Observable<any> {
    return this._http.post<any>(`${environment.statsKPIs}`, request).pipe(
      retryWhen((errors) => {
        let retryCount = 0;

        return errors.pipe(
          mergeMap((error) => {
            if (retryCount < this.MAX_RETRIES) {
              retryCount++;
              return timer(this.RETRY_TIME[Math.floor(Math.random() * 3)]);
            } else {
              throw error;
            }
          }),
          take(this.MAX_RETRIES)
        );
      })
    );
    // return of(this.getBusinessStatisticsFakeKPIs()).pipe(delay(1000));
  }

  getBusinessStatisticsPanels(request: { tematica: number }): Observable<any> {
    return this._http.post<any>(`${environment.statsPanel}`, request).pipe(
      retryWhen((errors) => {
        let retryCount = 0;

        return errors.pipe(
          mergeMap((error) => {
            if (retryCount < this.MAX_RETRIES) {
              retryCount++;
              return timer(this.RETRY_TIME[Math.floor(Math.random() * 3)]);
            } else {
              throw error;
            }
          }),
          take(this.MAX_RETRIES)
        );
      })
    );
    // return of(this.getBusinessStatisticsFakePanels()).pipe(delay(1000));
  }

  getBusinessStatisticsIndicators(request: { categoria: number }): Observable<any> {
    return this._http.post<any>(`${environment.statsIndicators}`, request).pipe(
      retryWhen((errors) => {
        let retryCount = 0;

        return errors.pipe(
          mergeMap((error) => {
            if (retryCount < this.MAX_RETRIES) {
              retryCount++;
              return timer(this.RETRY_TIME[Math.floor(Math.random() * 3)]);
            } else {
              throw error;
            }
          }),
          take(this.MAX_RETRIES)
        );
      })
    );
    // return of(this.getBusinessStatisticsFakeIndicators()).pipe(delay(1000));
  }

  getBusinessStatisticsProperties(request: { indicador: string }): Observable<any> {
    return this._http.post<any>(`${environment.statsProperty}`, request).pipe(
      retryWhen((errors) => {
        let retryCount = 0;

        return errors.pipe(
          mergeMap((error) => {
            if (retryCount < this.MAX_RETRIES) {
              retryCount++;
              return timer(this.RETRY_TIME[Math.floor(Math.random() * 3)]);
            } else {
              throw error;
            }
          }),
          take(this.MAX_RETRIES)
        );
      })
    );
    // return of(this.getBusinessStatisticsFakeProperties()).pipe(delay(1000));
  }

  getBusinessStatisticsData(request: any): Observable<any> {
    return this._http.post<any>(`${environment.statsData}`, request).pipe(
      retryWhen((errors) => {
        let retryCount = 0;

        return errors.pipe(
          mergeMap((error) => {
            if (retryCount < this.MAX_RETRIES) {
              retryCount++;
              return timer(this.RETRY_TIME[Math.floor(Math.random() * 3)]);
            } else {
              throw error;
            }
          }),
          take(this.MAX_RETRIES)
        );
      })
    );
    // return of(this.getBusinessStatisticsFakeData()).pipe(delay(1000));
  }

  getBusinessStatisticsFakeKPIs(): any[] {
    return [
      {
        indicador: 'Resultado económico bruto de la explotación (proxi del EBITDA)',
        icon: 'bar_chart',
        explicacion: 'Ganancias netas antes de intereses, impuestos, depreciación y amortización.',
        actual_value: 16.5,
        increment_value: -30,
        unit: '%',
      },
      {
        indicador: 'Cifra de negocios Indice general',
        icon: 'trending_up',
        explicacion: 'Tendencia general de facturación en el mercado general.',
        actual_value: 132.631,
        increment_value: -11.38,
        unit: '%',
      },
      {
        indicador: 'ICE Situación, expectativas e indice de confianza',
        icon: 'visibility',
        explicacion: 'Índice que mide el sentimiento actual y las expectativas futuras.',
        actual_value: 3.6,
        increment_value: -3.3,
        unit: '%',
      },
      {
        indicador: 'Tipo de interés. Nuevas operaciones. SNF. Otros créditos entre 250 mil y 1 millón de euros',
        icon: 'attach_money',
        explicacion: 'Tasa de interés para nuevas operaciones de crédito en un rango específico.',
        actual_value: 4.543,
        increment_value: 4.61,
        unit: '%',
      },
      {
        indicador: 'Empresas inscritas a la Seguridad Social',
        icon: 'people',
        explicacion: 'Empresas registradas.',
        actual_value: 1322573,
        increment_value: -0.85,
        unit: 'empresas',
      },
    ];
  }

  getBusinessStatisticsFakePanels(): any[] {
    return [
      {
        id_categoria: 1,
        categoria: 'Ingresos empresariales',
        data: {
          indicator: 'Valor de la producción (incluidas subvenciones)',
          graph_type: 'lines',
          data: [
            {
              title: 'CBT. Valor de la producción (incluidas subvenciones). Total empresas. Datos acumulados. Tasa',
              serie: [
                {
                  name: '2022 Diciembre',
                  valor: 37.6,
                },
                {
                  name: '2023 Marzo',
                  valor: 6.4,
                },
                {
                  name: '2023 Junio',
                  valor: -2.8,
                },
              ],
            },
          ],
        },
      },
      {
        id_categoria: 2,
        categoria: 'Costes empresariales',
        data: {
          indicator: 'Consumos intermedios (incluidos tributos)',
          graph_type: 'lines',
          data: [
            {
              title: 'CBT. Consumos intermedios (incluidos tributos). Total empresas. Datos acumulados. Tasa',
              serie: [
                {
                  name: '2022 Diciembre',
                  valor: 44.6,
                },
                {
                  name: '2023 Marzo',
                  valor: -0.2,
                },
                {
                  name: '2023 Junio',
                  valor: -8.4,
                },
              ],
            },
          ],
        },
      },
      {
        id_categoria: 3,
        categoria: 'Resultados empresariales',
        data: {
          indicator: 'Resultado económico bruto de la explotación (proxi del EBITDA)',
          graph_type: 'lines',
          data: [
            {
              title: 'CBT. Resultado económico bruto de la explotación. Total empresas. Datos acumulados. Tasa',
              serie: [
                {
                  name: '2022 Diciembre',
                  valor: 39.7,
                },
                {
                  name: '2023 Marzo',
                  valor: 46.5,
                },
                {
                  name: '2023 Junio',
                  valor: 16.5,
                },
              ],
            },
          ],
        },
      },
      {
        id_categoria: 4,
        categoria: 'Rentabilidad empresarial',
        data: {
          indicator: 'Rentabilidad ordinaria del activo neto',
          graph_type: 'lines',
          data: [
            {
              title: 'CBT. R.1 Rentabilidad ordinaria del activo neto. Total',
              serie: [
                {
                  name: '2022 Diciembre',
                  valor: 6.3,
                },
                {
                  name: '2023 Marzo',
                  valor: 4.3,
                },
                {
                  name: '2023 Junio',
                  valor: 4.8,
                },
              ],
            },
          ],
        },
      },
      {
        id_categoria: 5,
        categoria: 'Márgenes empresariales',
        data: {
          indicator: 'Margen de Explotación (REB/INCN)',
          graph_type: 'lines',
          data: [
            {
              title: 'CBT. R.5 Margen de explotación, (REB / INCN). Total',
              serie: [
                {
                  name: '2022 Diciembre',
                  valor: 9.6,
                },
                {
                  name: '2023 Marzo',
                  valor: 10.5,
                },
                {
                  name: '2023 Junio',
                  valor: 10.9,
                },
              ],
            },
          ],
        },
      },
      {
        id_categoria: 6,
        categoria: 'Endeudamiento empresarial',
        data: {
          indicator: 'Endeudamiento RAC/AN',
          graph_type: 'lines',
          data: [
            {
              title: 'CBT. E.1 Endeudamiento, RAC/AN (precios corrientes, saldo final). Total. Año actual',
              serie: [
                {
                  name: '2022 Diciembre',
                  valor: 44.8,
                },
                {
                  name: '2023 Marzo',
                  valor: 44.1,
                },
                {
                  name: '2023 Junio',
                  valor: 43.6,
                },
              ],
            },
          ],
        },
      },
      {
        id_categoria: 7,
        categoria: 'Facturación empresarial',
        data: {
          indicator: 'Cifra de negocios  Indice general',
          graph_type: 'lines',
          data: [
            {
              title: 'Total Nacional. Indice general ICNE. Índice. ',
              serie: [
                {
                  name: '2022 Diciembre',
                  valor: 156.753,
                },
                {
                  name: '2023 Enero',
                  valor: 138.556,
                },
                {
                  name: '2023 Febrero',
                  valor: 137.551,
                },
                {
                  name: '2023 Marzo',
                  valor: 156.926,
                },
                {
                  name: '2023 Abril',
                  valor: 137.302,
                },
                {
                  name: '2023 Mayo',
                  valor: 149.833,
                },
                {
                  name: '2023 Junio',
                  valor: 153.038,
                },
                {
                  name: '2023 Julio',
                  valor: 149.657,
                },
                {
                  name: '2023 Agosto',
                  valor: 132.631,
                },
              ],
            },
          ],
        },
      },
      {
        id_categoria: 8,
        categoria: 'Confianza empresarial',
        data: {
          indicator: 'ICE Situación, expectativas e indice de confianza',
          graph_type: 'lines',
          data: [
            {
              title: 'Total Nacional. Situación del trimestre anterior. Base 2013. ',
              serie: [
                {
                  name: '2023 Enero',
                  valor: 1.1,
                },
                {
                  name: '2023 Abril',
                  valor: -1.5,
                },
                {
                  name: '2023 Julio',
                  valor: 6.9,
                },
                {
                  name: '2023 Octubre',
                  valor: 3.6,
                },
              ],
            },
          ],
        },
      },
      {
        id_categoria: 9,
        categoria: 'Financiación empresarial',
        data: {
          indicator: 'Tipo de interés. Nuevas operaciones. SNF. Otros créditos entre 250 mil y 1 millón de euros',
          graph_type: 'lines',
          data: [
            {
              title:
                'Tipo de interés. Nuevas operaciones. EC y EFC. TEDR. SNF. Crédito. Descubiertos y líneas de crédito',
              serie: [
                {
                  name: '2022 Diciembre',
                  valor: 2.678,
                },
                {
                  name: '2023 Enero',
                  valor: 3.245,
                },
                {
                  name: '2023 Febrero',
                  valor: 3.299,
                },
                {
                  name: '2023 Marzo',
                  valor: 3.541,
                },
                {
                  name: '2023 Abril',
                  valor: 3.725,
                },
                {
                  name: '2023 Mayo',
                  valor: 3.868,
                },
                {
                  name: '2023 Junio',
                  valor: 4.137,
                },
                {
                  name: '2023 Julio',
                  valor: 4.287,
                },
                {
                  name: '2023 Agosto',
                  valor: 4.343,
                },
                {
                  name: '2023 Septiembre',
                  valor: 4.543,
                },
              ],
            },
          ],
        },
      },
      {
        id_categoria: 10,
        categoria: 'Dinamismo empresarial',
        data: {
          indicator: 'Empresas inscritas a la Seguridad Social',
          graph_type: 'lines',
          data: [
            {
              title: ' ',
              serie: [
                {
                  name: '2022 Diciembre',
                  valor: 1329897,
                },
                {
                  name: '2023 Enero',
                  valor: 1313133,
                },
                {
                  name: '2023 Febrero',
                  valor: 1316527,
                },
                {
                  name: '2023 Marzo',
                  valor: 1322734,
                },
                {
                  name: '2023 Abril',
                  valor: 1338654,
                },
                {
                  name: '2023 Mayo',
                  valor: 1336531,
                },
                {
                  name: '2023 Junio',
                  valor: 1331470,
                },
                {
                  name: '2023 Julio',
                  valor: 1325910,
                },
                {
                  name: '2023 Agosto',
                  valor: 1317509,
                },
                {
                  name: '2023 Septiembre',
                  valor: 1333972,
                },
                {
                  name: '2023 Octubre',
                  valor: 1322573,
                },
              ],
            },
          ],
        },
      },
    ];
  }

  getBusinessStatisticsFakeIndicators(): any[] {
    return [
      {
        id: 13,
        indicador: 'ICE Situación, expectativas e indice de confianza',
        difusion: 'Tasa de variación',
      },
    ];
  }

  getBusinessStatisticsFakeProperties(): any {
    return {
      ambito: ['España'],
      difusion: ['Índice', 'Tasa de variación'],
      periodicidad: ['Trimestral'],
      min_date: '2013-01-01 00:00:00',
      max_date: '2023-10-01 00:00:00',
    };
  }

  getBusinessStatisticsFakeData(): any {
    return {
      indicator: 'ICE Situación, expectativas e indice de confianza',
      graph_type: 'lines',
      data: [
        {
          title: 'Total Nacional. Índice. Base 2013. ',
          serie: [
            {
              name: '2022 Octubre',
              valor: 127.2,
            },
            {
              name: '2022 Julio',
              valor: 131,
            },
            {
              name: '2022 Abril',
              valor: 121.9,
            },
            {
              name: '2022 Enero',
              valor: 127,
            },
            {
              name: '2021 Octubre',
              valor: 130.3,
            },
            {
              name: '2021 Julio',
              valor: 128.9,
            },
            {
              name: '2021 Abril',
              valor: 114.8,
            },
            {
              name: '2021 Enero',
              valor: 109.3,
            },
            {
              name: '2020 Octubre',
              valor: 105.5,
            },
            {
              name: '2020 Julio',
              valor: 95.5,
            },
            {
              name: '2020 Abril',
              valor: 95.5,
            },
            {
              name: '2020 Enero',
              valor: 130.6,
            },
            {
              name: '2019 Octubre',
              valor: 131.1,
            },
            {
              name: '2019 Julio',
              valor: 135,
            },
            {
              name: '2019 Abril',
              valor: 132.9,
            },
            {
              name: '2019 Enero',
              valor: 132.5,
            },
            {
              name: '2018 Octubre',
              valor: 133.9,
            },
            {
              name: '2018 Julio',
              valor: 136.2,
            },
            {
              name: '2018 Abril',
              valor: 135.5,
            },
            {
              name: '2018 Enero',
              valor: 134.9,
            },
            {
              name: '2017 Octubre',
              valor: 135.4,
            },
            {
              name: '2017 Julio',
              valor: 136.9,
            },
            {
              name: '2017 Abril',
              valor: 134.2,
            },
            {
              name: '2017 Enero',
              valor: 131.8,
            },
            {
              name: '2016 Octubre',
              valor: 131.4,
            },
            {
              name: '2016 Julio',
              valor: 131.2,
            },
            {
              name: '2016 Abril',
              valor: 130.5,
            },
            {
              name: '2016 Enero',
              valor: 129.7,
            },
            {
              name: '2015 Octubre',
              valor: 131.4,
            },
            {
              name: '2015 Julio',
              valor: 131.2,
            },
            {
              name: '2015 Abril',
              valor: 128.1,
            },
            {
              name: '2015 Enero',
              valor: 123.2,
            },
            {
              name: '2014 Octubre',
              valor: 123.2,
            },
            {
              name: '2014 Julio',
              valor: 123,
            },
            {
              name: '2014 Abril',
              valor: 118.3,
            },
            {
              name: '2014 Enero',
              valor: 113.9,
            },
            {
              name: '2013 Octubre',
              valor: 113,
            },
            {
              name: '2013 Julio',
              valor: 109.3,
            },
            {
              name: '2013 Abril',
              valor: 103.6,
            },
          ],
        },
      ],
    };
  }
}
