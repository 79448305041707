import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import { Logout, Me, UpdatePassword, ResetMe, ViewMyData } from './auth.actions';
import { AuthStateModel } from '../models/auth.state.model';
import { UserConfig } from '@app/models/User';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
@State<AuthStateModel>({
  name: 'userIdentity',
  defaults: {
    user_id: undefined,
    username: undefined,
    config: undefined,
  },
})
export class AuthState {
  constructor(private router: Router, private keycloakService: KeycloakService) {}

  @Selector()
  static config(state: AuthStateModel) {
    return state.config;
  }

  @Selector()
  static isAdmin(state: AuthStateModel): boolean {
    return !!state.config.is_staff;
  }

  @Selector()
  static menuItems(state: AuthStateModel) {
    return state.config.interface_config.menu_items;
  }

  @Selector()
  static template(state: AuthStateModel) {
    return state.config.interface_config.template;
  }

  @Action(Me)
  meByState(ctx: StateContext<AuthStateModel>, response: { payload: UserConfig }) {
    ctx.patchState({ config: response.payload });
  }

  @Action(ResetMe)
  resetMeByState(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ config: undefined });
  }

  @Action(Logout)
  logoutByState(ctx: StateContext<AuthStateModel>) {
    const kcInstance = this.keycloakService.getKeycloakInstance();

    if (kcInstance.idToken) {
      window.location.href = `${kcInstance.authServerUrl}/realms/${kcInstance.realm}/protocol/openid-connect/logout?post_logout_redirect_uri=${window.location.origin}/&id_token_hint=${kcInstance.idToken}`;
    } else {
      window.location.reload();
    }

    ctx.setState(null);
  }

  @Action(UpdatePassword)
  updatePasswordByState(ctx: StateContext<AuthStateModel>) {
    const kcInstance = this.keycloakService.getKeycloakInstance();
    window.location.href = `${kcInstance.authServerUrl}/realms/${kcInstance.realm}/account/password`;
    ctx.setState(null);
  }

  @Action(ViewMyData)
  viewMyDataByState(ctx: StateContext<AuthStateModel>) {
    const kcInstance = this.keycloakService.getKeycloakInstance();
    window.location.href = `${kcInstance.authServerUrl}/realms/${kcInstance.realm}/account/`;
    ctx.setState(null);
  }
}
