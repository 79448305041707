<form class="search" [formGroup]="form">
  <div class="header">
    <mat-icon>search</mat-icon>
    <p>Buscador</p>
  </div>
  <div class="content">
    <div class="section" *ngIf="!loadingIndicators">
      <p>Tipo de datos:</p>
      <div class="data">
        <div class="fields">
          <mat-form-field appearance="fill">
            <mat-label>Indicador</mat-label>
            <mat-select
              panelClass="select-panel select-panel-text-wrap-options"
              formControlName="indicator"
              (closed)="form.controls.indicatorsSearch.setValue('')"
              (opened)="inputIndicatorsSearch.focus()"
              [disabled]="loadingIndicators"
            >
              <mat-form-field
                appearance="fill"
                class="search-container"
                style="margin: 0; position: sticky; top: 0; z-index: 999; background: white; max-width: 100% !important"
              >
                <mat-label>Buscar...</mat-label>
                <input
                  matInput
                  formControlName="indicatorsSearch"
                  (keydown)="$event.stopPropagation()"
                  #inputIndicatorsSearch
                />
              </mat-form-field>
              <mat-option
                *ngFor="let indicator of indicatorsOptions"
                [value]="indicator"
                [ngStyle]="{
                  display: indicatorsFilteredIncludes(indicator) ? 'flex' : 'none'
                }"
              >
                {{ indicator }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Ámbito</mat-label>
            <mat-select
              panelClass="select-panel select-panel-text-wrap-options"
              formControlName="ambit"
              [disabled]="loadingAmbits"
            >
              <mat-option *ngFor="let ambit of ambitsOptions" [value]="ambit">
                {{ ambit }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Difusión</mat-label>
            <mat-select
              panelClass="select-panel select-panel-text-wrap-options"
              formControlName="difusion"
              [disabled]="loadingDifusions"
            >
              <mat-option *ngFor="let difusion of difusionOptions" [value]="difusion">
                {{ difusion }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      style="display: flex; align-items: center; gap: 12px; padding: 16px"
      *ngIf="(form.controls.indicator.value && loadingPeriods) || loadingIndicators"
    >
      <mat-spinner [diameter]="24"></mat-spinner>
      <p style="margin: 0">Cargando datos...</p>
    </div>
    <div class="divider" *ngIf="form.controls.indicator.value && !loadingPeriods"></div>
    <div class="section" *ngIf="form.controls.indicator.value && !loadingPeriods">
      <p>Periodo de búsqueda:</p>
      <div class="data">
        <div class="fields">
          <div class="block">
            <div class="header">
              <p>Fecha de inicio</p>
              <mat-icon (click)="resetStartDate()">delete</mat-icon>
            </div>
            <mat-calendar
              [startView]="form.controls.startDate.value ? 'year' : 'multi-year'"
              [minDate]="minDate"
              [maxDate]="form.controls.endDate.value || maxDate"
              [startAt]="form.controls.startDate.value"
              [selected]="form.controls.startDate.value"
              (monthSelected)="onPeriodSelectedDateStart($event)"
              *ngIf="showCalendarStart"
            ></mat-calendar>
          </div>
          <div class="block">
            <div class="header">
              <p>Fecha de fin</p>
              <mat-icon (click)="resetEndDate()">delete</mat-icon>
            </div>
            <mat-calendar
              [startView]="form.controls.endDate.value ? 'year' : 'multi-year'"
              [minDate]="form.controls.startDate.value || minDate"
              [maxDate]="maxDate"
              [startAt]="form.controls.endDate.value"
              [selected]="form.controls.endDate.value"
              (monthSelected)="onPeriodSelectedDateEnd($event)"
              *ngIf="showCalendarEnd"
            ></mat-calendar>
          </div>
          <div class="block">
            <div class="header">
              <p>Periodicidad</p>
            </div>
            <mat-radio-group formControlName="period">
              <mat-radio-button
                color="primary"
                [value]="period"
                [disabled]="!periodsOptionsValid.includes(period)"
                *ngFor="let period of periodsOptions"
              >
                {{ period }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
  </div>
  <div class="buttons">
    <button mat-button color="primary" (click)="clearFilters()">Restablecer filtros</button>
    <button mat-flat-button color="primary" [disabled]="loadingData" (click)="search()">
      <mat-spinner [diameter]="24" *ngIf="loadingData"></mat-spinner>
      Buscar resultados
    </button>
  </div>
</form>
