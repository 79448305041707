<form class="container" #magazineForm="ngForm" [formGroup]="formGroup">
  <mat-form-field class="form-full-width" appearance="fill">
    <mat-label>Nombre</mat-label>
    <input autocomplete="off" matInput type="text" formControlName="name" required />
    <button mat-button matSuffix mat-icon-button (click)="name.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint>Introduzca un nombre para identificar la revista</mat-hint>
    <mat-error *ngIf="name.invalid">
      <span *ngIf="name.errors.required || name.invalid">Campo requerido.</span>
    </mat-error>
  </mat-form-field>
  <mat-divider></mat-divider>
  <app-press-spiders-select
    (spiders)="setSpiders($event)"
    [selectedSpiders]="magazine?.spiders"
  ></app-press-spiders-select>
  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <h3 fxFlex="96%">Temas</h3>
    <div fxFlex="4%">
      <button
        type="button"
        mat-icon-button
        [matTooltip]="hideCategories ? 'Mostar temas' : 'Ocultar temas'"
        (click)="hideCategories = !hideCategories"
      >
        <mat-icon color="primary">{{ hideCategories ? 'expand_more' : 'expand_less' }}</mat-icon>
      </button>
    </div>
  </div>
  <div
    class="content"
    cdkDropList
    lockAxis="y"
    (cdkDropListDropped)="dropSectionQuestion($event, i)"
    *ngIf="!hideCategories"
  >
    <div
      formArrayName="searches"
      cdkDrag
      cdkDragBoundary=".content"
      cdkDragPreviewClass="magazineSettings-cdk-drag-drop-list-preview"
      *ngFor="let item of searches['controls']; let i = index"
      class="filledGroup magazineSettings-cdk-drag-drop-list-preview"
    >
      <div [formGroupName]="i">
        <div style="display: flex; flex-wrap: wrap; align-items: center; gap: 6px">
          <mat-icon cdkDragHandle>drag_indicator</mat-icon>
          <mat-slide-toggle class="toggles" formControlName="escrita" color="primary">Prensa escrita</mat-slide-toggle>
          <mat-slide-toggle class="toggles" formControlName="digital" color="primary">Prensa digital</mat-slide-toggle>
          <button
            type="button"
            mat-icon-button
            matTooltip="Eliminar tema"
            style="margin-left: auto"
            (click)="deleteSearch(i)"
          >
            <mat-icon color="primary">delete</mat-icon>
          </button>
        </div>
        <mat-form-field class="form-full-width" appearance="fill">
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="category" required />
          <button
            mat-button
            matSuffix
            mat-icon-button
            *ngIf="item.controls.category.value"
            (click)="item.controls.category.setValue('')"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="form-full-width" appearance="fill">
          <mat-label>Sección</mat-label>
          <mat-select
            panelClass="select-panel select-panel-text-wrap-options"
            [compareWith]="sectionCompareValue"
            formControlName="section"
          >
            <mat-option [value]="[]">-</mat-option>
            <mat-option *ngFor="let section of sections" [value]="section.value">
              {{ section.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form-full-width" appearance="fill">
          <mat-label>Búsquedas</mat-label>
          <textarea matInput formControlName="search" rows="6" cols="80"></textarea>
          <button
            mat-button
            matSuffix
            mat-icon-button
            *ngIf="item.controls.search.value"
            (click)="item.controls.search.setValue('')"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error class="error-validator" *ngIf="item.controls.search.hasError('required')">
            Campo requerido
          </mat-error>
          <mat-error
            class="error-validator"
            *ngIf="!item.controls.search.hasError('required') && item.controls.search.hasError('minAlphanumeric')"
          >
            Se requiere un mínimo de {{ item.controls.search.getError('minAlphanumeric').required }} caracteres
            alfanuméricos
          </mat-error>
        </mat-form-field>
        <section>
          <mat-form-field>
            <mat-label>Máximo de artículos</mat-label>
            <input
              matInput
              type="number"
              numeric
              min="0"
              step="1"
              formControlName="max_articles"
              (keypress)="numberOnly($event)"
            />
          </mat-form-field>
          <div class="form-field-container">
            <mat-form-field>
              <mat-label>Comprobación de nuevos documentos (Frecuencia)</mat-label>
              <input
                matInput
                type="number"
                numeric
                min="0"
                step="1"
                formControlName="alert_period"
                (keypress)="numberOnly($event)"
              />
            </mat-form-field>
            <p>minutos</p>
          </div>
        </section>
      </div>
    </div>
    <button mat-button matTooltip="Añadir tema" (click)="addSearch()" class="marginTopButton">
      Nuevo tema <mat-icon color="primary">add</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <button
    mat-flat-button
    color="warn"
    type="submit"
    (click)="deleteMagazine(deleteMagazine)"
    class="marginTopButton"
    *ngIf="id.value"
  >
    BORRAR REVISTA
  </button>
  <button mat-flat-button color="primary" type="submit" (click)="submitForm()" class="marginTopButton">GUARDAR</button>
</form>
