<h2>Uso del buscador</h2>
<p class="description">
  <span>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fermentum consectetur ipsum, vitae porta nulla
    hendrerit quis. Maecenas commodo non magna sed vulputate. Quisque luctus in ex at rhoncus. Suspendisse egestas velit
    sit amet blandit fringilla.
  </span>
  <span>
    Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In commodo ante dictum,
    imperdiet sapien eget, interdum orci. Mauris auctor, odio eget convallis semper, massa purus facilisis justo,
    imperdiet pretium neque quam eu lectus.
  </span>
  <span>
    Aliquam eget diam vel ligula pretium semper vel non nisi. Nullam vel dignissim diam. Vestibulum a lectus id augue
    molestie aliquam. Aenean id ligula facilisis, semper massa vel, porta nibh.
  </span>
</p>
<div class="example-button-row">
  <button mat-button (click)="dialogRef.close(false)" mat-dialog-close>Cerrar</button>
</div>
