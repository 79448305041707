import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import { IndicadorStateModel, Indicador, Comparable, IndicadorSearchResult } from '../models/indicador.state.model';
import { UIStatus } from '@app/state/models/app.state.model';
import {
  UpdateIndicadoresUIStatus,
  AddIndicadoresEpa,
  AddIndicadorEpa,
  AddIndicadoresEpaResult,
  AddIndicadoresIpc,
  AddIndicadoresIpcResult,
  AddIndicadorIpc,
} from './indicador.actions';

@Injectable({
  providedIn: 'root',
})
@State<IndicadorStateModel>({
  name: 'indicadores',
  defaults: {
    uiState: UIStatus.notInitializated,
    indicadores: [],
    selectedIndicador: undefined,
    searchResults: undefined,
  },
})
export class IndicadorState {
  @Selector()
  static uiState(state: IndicadorStateModel): UIStatus | null {
    return state.uiState;
  }

  @Selector()
  static indicadores(state: IndicadorStateModel): Indicador[] | null {
    return state.indicadores;
  }

  @Selector()
  static selectedIndicador(state: IndicadorStateModel): Indicador | null {
    return state.selectedIndicador;
  }

  @Selector()
  static searchResults(state: IndicadorStateModel): IndicadorSearchResult[] | null {
    return state.searchResults;
  }

  @Selector()
  static selectedIndicadorComparables(state: IndicadorStateModel): Comparable[] | null {
    return state.selectedIndicador.comparables;
  }

  constructor() { }

  @Action(UpdateIndicadoresUIStatus)
  updateUIStatusByState(ctx: StateContext<IndicadorStateModel>, response: { payload: UIStatus }) {
    ctx.patchState({ uiState: response.payload });
  }

  @Action(AddIndicadoresEpa)
  addIndicadoresByState({ patchState }: StateContext<IndicadorStateModel>, response: { payload }) {
    patchState({ indicadores: response.payload });
  }

  @Action(AddIndicadoresEpaResult)
  addIndicadoresResultByState({ patchState }: StateContext<IndicadorStateModel>, response: { payload }) {
    patchState({ searchResults: response.payload });
  }

  @Action(AddIndicadorEpa)
  addSelectedIndicadorByState({ getState, patchState }: StateContext<IndicadorStateModel>, response: { payload }) {
    let provincias = JSON.parse(JSON.stringify(getState())).searchResults;
    let periodos = [];

    let provinciasArray = Object.keys(provincias).map((index) => {
      let provincia = provincias[index];
      let transpose = provincia.data[0].map((_, colIndex) => provincia.data.map(row => row[colIndex]));
      periodos = transpose[0];
      periodos[0] = 'Comparable';

      transpose = transpose
        .filter((item) => item[0] == response.payload)
        .map((item) => {
          item[0] = provincia.name;
          return item;
        });

      return transpose;
    });

    provinciasArray.pop();
    // provinciasArray.unshift(periodos);
    patchState({ selectedIndicador: provinciasArray });
  }

  @Action(AddIndicadoresIpc)
  addIndicadoresIpcByState({ patchState }: StateContext<IndicadorStateModel>, response: { payload }) {
    patchState({ indicadores: response.payload });
  }

  @Action(AddIndicadoresIpcResult)
  addIndicadoresIpcResultByState({ patchState }: StateContext<IndicadorStateModel>, response: { payload }) {
    patchState({ searchResults: response.payload });
  }

  @Action(AddIndicadorIpc)
  addSelectedIndicadorIpcByState({ getState, patchState }: StateContext<IndicadorStateModel>, response: { payload }) {
    let provincias = JSON.parse(JSON.stringify(getState())).searchResults;
    let periodos = [];

    let provinciasArray = Object.keys(provincias).map((index) => {
      let provincia = provincias[index];
      let transpose = provincia.data[0].map((_, colIndex) => provincia.data.map(row => row[colIndex]));
      periodos = transpose[0];
      periodos[0] = 'Comparable';

      transpose = transpose
        .filter((item) => item[0] == response.payload)
        .map((item) => {
          item[0] = provincia.name;
          return item;
        });

      return transpose;
    });

    provinciasArray.pop();
    // provinciasArray.unshift(periodos);
    patchState({ selectedIndicador: provinciasArray });
  }
}
