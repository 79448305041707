import { Component, OnInit } from '@angular/core';
import { AppStateService } from '@app/state/app/app.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  tileButtonLabel: string = 'Continuar';
  environment = environment;

  constructor(
    public authStateService: AuthStateService,
    private _appStateService: AppStateService,
    private router: Router
  ) { }

  ngOnInit() {
    this._appStateService.addTitle('Inicio');
    this._appStateService.hideDates();
    this._appStateService.hideSearchText();
    this._appStateService.hideSubForm();
    this._appStateService.addHistoryUrl(undefined);
    this._appStateService.unsetInteresting();
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  navigateToBuyUrl(url: string) {
    window.open(url, '_blank');
  }

  getCardsFiltered(): any[] {
    return this.authStateService.userConfig.interface_config.menu_items.filter((mi) => mi.active.toString() == 'true');
  }
}
