import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { CollectiveAgreementsStateService } from '../../store/collective-agreements/collective-agreements.service';
import {
  GetCollectiveAgreements,
  UpdateCollectiveAgreementsMenuFilters,
} from '../../store/collective-agreements/collective-agreements.actions';
import { CollectiveAgreementsGetRequest } from '../../models/collective-agreement';
import { CollectiveAgreementsSearchDetailComponent } from './collective-agreements-search-detail/collective-agreements-search-detail.component';

@Component({
  selector: 'app-collective-agreements-search',
  templateUrl: './collective-agreements-search.component.html',
  styleUrls: ['./collective-agreements-search.component.scss'],
})
export class CollectiveAgreementsSearchComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  componentDestroyed$: Subject<boolean> = new Subject();
  displayedColumns: string[] = ['card'];
  pageIndex = 0;
  loading = true;

  constructor(
    public collectiveAgreementsStateService: CollectiveAgreementsStateService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private actions: Actions,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private router: Router
  ) {
    this.actions
      .pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetCollectiveAgreements))
      .subscribe(() => {
        this.loading = false;

        setTimeout(() => {
          if (this.paginator) {
            this.paginator.pageIndex = this.pageIndex;
            this.paginator.length = this.collectiveAgreementsStateService.collectiveAgreements.total;
          }
        }, 0);

        document.querySelector('.container .header .title').scrollIntoView({ block: 'start', inline: 'start' });
      });

    setTimeout(() => {
      this.actions
        .pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(UpdateCollectiveAgreementsMenuFilters))
        .subscribe(() => {
          this.pageIndex = 0;
          this.collectiveAgreementsStateService.getCollectiveAgreements(this.getCollectiveAgreementsRequest());
        });
    }, 0);
  }

  ngOnInit(): void {
    this.pageIndex = 0;
    this.collectiveAgreementsStateService.getCollectiveAgreements(this.getCollectiveAgreementsRequest());
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  pageEvent(evt: PageEvent): void {
    this.loading = true;
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.collectiveAgreementsStateService.getCollectiveAgreements(this.getCollectiveAgreementsRequest());
  }

  getCollectiveAgreementsRequest(): CollectiveAgreementsGetRequest {
    return {
      page: this.pageIndex + 1,
      size: 30,
      search: (this.collectiveAgreementsStateService.filters.selectedFilters?.search as string) || '',
      cod_convenio: (this.collectiveAgreementsStateService.filters.selectedFilters?.cod_convenio as string) || '',
      denominacion: (this.collectiveAgreementsStateService.filters.selectedFilters?.denominacion as string) || '',
      nif: (this.collectiveAgreementsStateService.filters.selectedFilters?.nif as string) || '',
      estado_vigencia:
        (this.collectiveAgreementsStateService.filters.selectedFilters?.estado_vigencia as string[]) || [],
      naturaleza: (this.collectiveAgreementsStateService.filters.selectedFilters?.naturaleza as string[]) || [],
      ambito_funcional:
        (this.collectiveAgreementsStateService.filters.selectedFilters?.ambito_funcional as string[]) || [],
      ambito_territorial:
        (this.collectiveAgreementsStateService.filters.selectedFilters?.ambito_territorial as string[]) || [],
      location: (this.collectiveAgreementsStateService.filters.selectedFilters?.location as string[]) || [],
      voces: (this.collectiveAgreementsStateService.filters.selectedFilters?.voces as string[]) || [],
      ambito_personal:
        (this.collectiveAgreementsStateService.filters.selectedFilters?.ambito_personal as string[]) || [],
      cnae: (this.collectiveAgreementsStateService.filters.selectedFilters?.cnae as string[]) || [],
      sindicatos: (this.collectiveAgreementsStateService.filters.selectedFilters?.sindicatos as string[]) || [],
      fecha_vigencia: {
        from:
          !this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_vigencia?.from ||
          this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_vigencia?.from === '' ||
          this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_vigencia?.from === 'Fecha inválida'
            ? undefined
            : (this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_vigencia?.from as string).split(
                'T'
              )[0],
        to:
          !this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_vigencia?.to ||
          this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_vigencia?.to === '' ||
          this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_vigencia?.to === 'Fecha inválida'
            ? undefined
            : (this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_vigencia?.to as string).split(
                'T'
              )[0],
      },
      fecha_publicacion: {
        from:
          !this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_publicacion?.from ||
          this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_publicacion?.from === '' ||
          this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_publicacion?.from === 'Fecha inválida'
            ? undefined
            : (this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_publicacion?.from as string).split(
                'T'
              )[0],
        to:
          !this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_publicacion?.to ||
          this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_publicacion?.to === '' ||
          this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_publicacion?.to === 'Fecha inválida'
            ? undefined
            : (this.collectiveAgreementsStateService.filters.selectedFilters?.fecha_publicacion?.to as string).split(
                'T'
              )[0],
      },
      variacion_salarial_pactada:
        this.collectiveAgreementsStateService.filters.selectedFilters?.variacion_salarial_pactada &&
        (this.collectiveAgreementsStateService.filters.selectedFilters?.variacion_salarial_pactada as boolean[])
          .length > 0
          ? (this.collectiveAgreementsStateService.filters.selectedFilters?.variacion_salarial_pactada as boolean[])[0]
          : null,
    };
  }

  openDetail(id: number): void {
    this._bottomSheet.open(CollectiveAgreementsSearchDetailComponent, {
      autoFocus: false,
      panelClass: 'panel-bottom-sheet-w-100-h-100',
      data: id,
    });
  }

  arraySplitChildItems(input: string[]): string[] {
    const output: string[] = [];

    for (const item of input) {
      if (item.includes(',')) {
        const childItems = item.split(',');
        output.push(...childItems);
      } else {
        output.push(item);
      }
    }

    return output;
  }

  getVozColor(voz: any, alpha: string): string {
    if (this.collectiveAgreementsStateService.filters.filters) {
      const color = `${
        this.collectiveAgreementsStateService.filters.filters
          .find((f) => f.attr === 'voces')
          .items.find((v) => v.label === voz.name).color
      }${alpha}`;

      return color;
    }
  }

  changeDisplay(elementId: string, display: string): void {
    const element = document.getElementById(elementId);

    if (element) {
      element.style.display = display;
    }
  }
}
