import { Injectable, EventEmitter } from '@angular/core';
import { QueryParams } from './search.service';
import moment from 'moment';

export interface DateParam {
  begin: moment.Moment;
  end: moment.Moment;
}

export interface ISearchParamsService {
  texts: string[];
  dates: DateParam;
  sources: Source[];
  categories: Source[];
  geos: Source[];
}

export interface Source {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class SearchRequest {
  sort: string;
  from: number = 0;
  size: number = 30;
  docTypes: string[];
  searchParams = new EventEmitter<QueryParams>();
}
