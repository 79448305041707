import { CommonModule, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEsp } from '@app/modules/documents/mat-paginator-int';
import { WindowRef } from '@app/modules/documents/services/window.ref.service';
import { MappingIDPipe } from '@app/pipes/mapping-id.pipe';
import { MappingPipe } from '@app/pipes/mapping.pipe';
import { SubventionsFavoritesComponent } from './subventions-favorites/subventions-favorites.component';
import { SubventionsHomeComponent } from './subventions-home/subventions-home.component';
import { SubventionsRoutingModule } from './subventions-routing.module';
import { SubventionsSearchCreateAlertDialogComponent } from './subventions-search/subventions-search-create-alert-dialog/subventions-search-create-alert-dialog.component';
import { SubventionsSearchDetailComponent } from './subventions-search/subventions-search-detail/subventions-search-detail.component';
import { SubventionsSearchFiltersComponent } from './subventions-search/subventions-search-filters/subventions-search-filters.component';
import { SubventionsSearchComponent } from './subventions-search/subventions-search.component';
import { SubventionsSupportComponent } from './subventions-support/subventions-support.component';
import { SubventionsAlertsComponent } from './subventions-alerts/subventions-alerts.component';
import { SubventionsComponent } from './subventions.component';
import { SubventionsAlertsEditDialogComponent } from './subventions-alerts/subventions-alerts-edit-dialog/subventions-alerts-edit-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from '@app/modules/shared/shared.module';

registerLocaleData(es);

@NgModule({
  imports: [CommonModule, SharedModule, SubventionsRoutingModule],
  declarations: [
    SubventionsComponent,
    SubventionsHomeComponent,
    SubventionsSupportComponent,
    SubventionsSearchComponent,
    SubventionsSearchFiltersComponent,
    SubventionsSearchDetailComponent,
    SubventionsFavoritesComponent,
    SubventionsSearchCreateAlertDialogComponent,
    SubventionsAlertsComponent,
    SubventionsAlertsEditDialogComponent,
  ],
  exports: [SubventionsSearchFiltersComponent],
  providers: [
    WindowRef,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    MappingPipe,
    MappingIDPipe,
  ],
})
export class SubventionsModule {}
