import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectorReportsComponent } from './sector-reports.component';
import { SectorReportsListComponent } from './sector-reports-list/sector-reports-list.component';
import { SectorReportsListSectionComponent } from './sector-reports-list/sector-reports-list-section/sector-reports-list-section.component';
import { SectorReportsListSectionReportsComponent } from './sector-reports-list/sector-reports-list-section/sector-reports-list-section-reports/sector-reports-list-section-reports.component';
import { SectorReportsSearchComponent } from './sector-reports-search/sector-reports-search.component';

export const SectorReportsRoutes: Routes = [
  { path: '', component: SectorReportsComponent },
  { path: 'search/:groupTopicId', component: SectorReportsSearchComponent },
  { path: 'list/:id', component: SectorReportsListComponent },
  { path: 'list/:id/:idSection', component: SectorReportsListSectionComponent },
  { path: 'list/:id/:idSection/reports', component: SectorReportsListSectionReportsComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(SectorReportsRoutes)],
  exports: [RouterModule],
})
export class SectorReportsRoutingModule {}
