import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of as ObservableOf, Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient) {}

  logout(url?: string) {
    return ObservableOf(null);
  }

  updatePassword() {
    return ObservableOf(null);
  }

  viewMyData() {
    return ObservableOf(null);
  }

  me(): Observable<any> {
    return this.http.get<any>(`${environment.userConfig}`);
  }
}
