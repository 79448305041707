import { Component, OnDestroy, OnInit } from '@angular/core';
import { DraftStateService } from '@app/modules/documents/store/draft/draft.service';
import { ActivatedRoute } from '@angular/router';
import { AppStateService } from '@app/state/app/app.service';
import { DraftContent } from '@app/modules/documents/store/models/draft.state.model';
import { FormControl } from '@angular/forms';
import _moment from 'moment';
import { SetDraft } from '@app/modules/documents/store/draft/draft.actions';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DraftService } from '@app/services/draft.service';
import { MatDialog } from '@angular/material/dialog';
import { MagazineViewSendDialogComponent } from './magazine-view-send-dialog/magazine-view-send-dialog.component';

@Component({
  selector: 'app-magazine-view',
  templateUrl: './magazine-view.component.html',
  styleUrls: ['./magazine-view.component.less'],
})
export class MagazineViewComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  categories = [];
  magazineID: number;
  draftID: number;
  catID?: string;
  docID?: string;
  docArticleID: number = 0;
  s3_file_url: string | undefined;
  draftContent: DraftContent | undefined;
  isChecked: boolean = true;
  covers_date: FormControl = new FormControl(new Date());
  position_covers_date: FormControl = new FormControl(true);
  mode: 'edit' | 'visor' = 'edit';
  smartphoneView = false;

  constructor(
    public appStateService: AppStateService,
    public draftStateService: DraftStateService,
    public draftService: DraftService,
    private _route: ActivatedRoute,
    private dialog: MatDialog,
    private actions: Actions
  ) {
    this.appStateService.hideSideMenu();
    this.appStateService.addTitle('Revista de prensa');

    this._route.queryParams.subscribe((params) => {
      if (params.visor) {
        this.mode = 'visor';
        this.setVisorMode();
      }
    });
  }

  ngOnInit(): void {
    this.deleteLoadingAnimation();
    this.appStateService.showTitle();
    this.appStateService.hideDates();
    this.appStateService.hideSearchText();
    this.appStateService.hideSubForm();

    this._route.paramMap.subscribe((params) => {
      this.magazineID = +params.get('magazineID');
      this.draftID = +params.get('draftID');
      this.catID = params.get('catId');
      this.docID = params.get('docId');
      this.appStateService.addHistoryUrl(`clipping/magazine/${this.magazineID}/draft/${this.draftID}`);
      this.draftStateService.getDraft(+params.get('magazineID'), +params.get('draftID'));

      if (this.catID != undefined && this.docID != undefined) {
        this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(SetDraft)).subscribe(() => {
          const doc = this.draftStateService.selectedDraft.data[this.catID]?.docs[this.docID];

          if (doc) {
            this.openDetail(doc);
          }
        });
      }
    });

    if (window.innerWidth <= 800) {
      this.smartphoneView = true;
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  openDetail(article) {
    this.docArticleID = article.id;
    this.s3_file_url = article.s3_file_url;
    this.draftContent = article;
  }

  linkMagazine() {}

  showSendDialog() {
    this.dialog
      .open(MagazineViewSendDialogComponent, {
        width: '100%',
        maxWidth: '350px',
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.draftStateService.generateDraftPdf(
            this.magazineID,
            this.draftID,
            this.isChecked ? _moment(this.covers_date.value).format('YYYY-MM-DD') : '',
            this.position_covers_date.value ? 'end' : 'start',
            data.pdf,
            data.email
          );
        }
      });
  }

  setVisorMode(): void {
    const toolbar = document.getElementById('main-app-toolbar');
    const sidenavContainer = document.getElementsByClassName('main-app-sidenav-container').item(0) as HTMLElement;

    if (toolbar) {
      toolbar.style.display = 'none';
    }

    if (sidenavContainer) {
      sidenavContainer.style.setProperty('margin-top', '0', 'important');
      sidenavContainer.style.height = '100vh';
    }
  }

  deleteLoadingAnimation(): void {
    (document.querySelector('#loading-animation') as HTMLDivElement).style.display = 'none';
  }
}
