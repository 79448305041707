import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PRESSURL, environment } from '@env/environment';
import { Magazine } from '@app/modules/documents/store/models/magazine.state.model';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { pressUserCreditsRequest, pressUserCreditsResponse } from '@app/models/magazine.model';

@Injectable({
  providedIn: 'root',
})
export class MagazineService {
  static readonly url = PRESSURL;

  constructor(private http: HttpClient, private _auth: AuthStateService) {}

  getMagazines() {
    return this.http.get(`${MagazineService.url}dossiers`);
  }

  newMagazine(payload: Magazine) {
    return this.http.post(`${MagazineService.url}dossiers`, payload);
  }

  editMagazine(payload: Magazine) {
    return this.http.put(`${MagazineService.url}dossiers/${payload.id}`, payload);
  }

  deleteMagazine(payload: Magazine) {
    return this.http.delete(`${MagazineService.url}dossiers/${payload.id}`);
  }

  getMagazineDrafts(payload: Magazine) {
    return this.http.get(`${MagazineService.url}dossiers/${payload.id}/drafts`);
  }

  getMagazineDraft(payload: Magazine) {
    const token_brainy = { token: '' };
    return this.http.post(`${MagazineService.url}dossiers/${payload.id}/search`, token_brainy);
  }

  getMagazineSections(): Observable<any> {
    return this.http.get<any>(`${environment.magazineSections}`);
  }

  getUserCredits(request: pressUserCreditsRequest): Observable<pressUserCreditsResponse> {
    return this.http.get<pressUserCreditsResponse>(
      `${environment.pressUserCredits}?start_date=${request.start_date}&end_date=${request.end_date}`
    );
  }

  getDossierEstimatedPrice(request: any): Observable<any> {
    return this.http.post<any>(`${environment.pressEstimateDossier}`, request);
  }

  postDossierGenerate(request: any): Observable<any> {
    return this.http.post<any>(`${environment.pressGenerate}`, request);
  }
}
