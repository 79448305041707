<div class="check-buttons">
  <button mat-button id="chips-autocomplete-select-all" color="primary" (click)="checkAll()">Seleccionar todos</button>
  <button mat-button id="chips-autocomplete-deselect-all" color="warn" (click)="uncheckAll()">Quitar todos</button>
</div>
<form #form="ngForm" [formGroup]="formGroup">
  <mat-form-field class="chip-list-component" appearance="fill">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-list #chipList aria-label="Seleccione usuarios">
      <mat-chip
        *ngFor="let item of selectedItems"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(item)"
      >
        {{ item }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Seleccione elementos"
        #itemInput
        [formControl]="items"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
      />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let item of filteredList | async" [value]="item">
        {{ item }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint>{{ hint }}</mat-hint>
  </mat-form-field>
</form>
