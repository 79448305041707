import { Injectable, EventEmitter, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserIdentity } from '../models/UserIdentity';
import { Agenda, AgendaEvent, PaginatedResults } from '@app/state/models/app.state.model';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  public mappings: Object = {};
  public loaded: boolean = false;
  public urlsCopy: Object;
  static KEYS: Array<string> = ['spiders_id', 'spider_groups_id', 'topics_id', 'regions_id'];
  static LAST_KEY: string = 'regions_id';
  static URLS: Object;

  public filters_loaded: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private router: Router,
    private authStateService: AuthStateService
  ) {
    setTimeout(() => {
      this.changeURLS({
        spiders_id: environment.spidersGroupUrl,
        spider_groups_id: environment.spidersGroupUrl,
        regions_id: environment.regionsUrl,
      });

      this.resetMe();
    }, 0);
  }

  changeURLS(urls: any): void {
    AppDataService.URLS = urls;
    this.urlsCopy = urls;
  }

  resetMe(): void {
    this.injector.get(AuthStateService).resetMe();
  }

  load() {
    setTimeout(() => {
      if (!this.loaded) {
        this.loadFilters();
        this.loaded = true;
      }
    }, 0);
  }

  getSpiderGroups() {
    return this.http.get<any>('');
  }

  getCategories() {
    const userConfig = this.injector.get(AuthStateService).userConfig;

    if (userConfig) {
      switch (userConfig.interface_config.categories) {
        case 'legacy':
          return this.http.get<any>(environment.topicsUrlLegacy, {});
        case 'eurovoc':
          return this.http.get<any>(environment.topicsUrlEurovoc, {});
      }
    } else {
      return this.http.get<any>(environment.topicsUrlLegacy, {});
    }
  }

  getAgendas(request: { path: string }) {
    return this.http.get<{ items: AgendaEvent[] }>(`${environment.agendasFilters}?path=${request.path}`);
  }

  getAgendasEvents(request: { spiderIds: string[]; from: string; to: string }) {
    return this.http.get<AgendaEvent[]>(
      `${environment.agendasEvents}?spiderIds=${request.spiderIds}&from=${request.from}&to=${request.to}`
    );
  }

  downloadWeeklyAgenda(request: {
    spiderIds: string[];
    from: string;
    to: string;
    export: 'xls' | 'pdf';
  }): Observable<Blob> {
    return this.http.get<Blob>(
      `${environment.agendasEvents}?spiderIds=${request.spiderIds}&from=${request.from}&to=${request.to}&export=${request.export}`,
      { responseType: 'blob' as 'json' }
    );
  }

  loadFilters() {
    if (!this.router.url.includes('/login') && !this.router.url.includes('/tenders') && this.router.url !== '/') {
      let config = UserIdentity.model().load(),
        progress = AppDataService.KEYS.length;

      AppDataService.KEYS.forEach((mapping_key) => {
        let self = this;
        if (
          (mapping_key != 'spider_groups_id' && mapping_key != 'spiders_id') ||
          !config ||
          !config.config ||
          !config.config.interface_config ||
          !config.config.interface_config.spiderconfig
        ) {
          setTimeout(() => {
            this.get(AppDataService.URLS[mapping_key], mapping_key, (resolve) => {
              if (--progress == 0) {
                // this.loaded = true;
                this.filters_loaded.emit(this.mappings);
              }
            });
          }, 0);
        } else {
          this.mappings[mapping_key] = { values: {} };
          let values = this.assign(
            ...config.config.interface_config.spiderconfig.map((group) => {
              let aux = this.assign(
                ...group.group.children.map((child) => {
                  let ret = {};
                  child.spiders.forEach((spider_id) => {
                    ret[spider_id] = mapping_key == 'spider_groups_id' ? group.group.name : child.name;
                  });
                  return ret;
                })
              );
              return aux;
            })
          );
          this.addMappings(mapping_key, values);
          if (--progress == 0) {
            //                      this.loaded = true;
            this.filters_loaded.emit(this.mappings);
          }
        }
      });
    }
  }

  addMappings(mapping_key, values) {
    this.mappings[mapping_key].values = Object.assign(this.mappings[mapping_key].values, values);
  }

  cleanMappings(mapping_key) {
    this.mappings[mapping_key] = { values: [] };
    return this;
  }

  assign(...assign) {
    let r = {};
    if (assign.length > 1) {
      return Object.assign.apply(this, assign);
    } else if (assign.length == 1) return assign[0];
  }

  get(url, mapping_key, resolve) {
    let res = [];

    if (this.injector.get(AuthStateService).userConfig) {
      if (url.includes('spiderGroups')) {
        if (this.router.url.includes('regulatorio')) {
          res =
            this.injector.get(AuthStateService).userConfig.config.Regulatorio[this.router.url.slice(1).split('/')[1]]
              .SpiderGroups;
        } else {
          res = this.injector.get(AuthStateService).userConfig.config.Documentos.SpiderGroups;
        }
      } else if (url.includes('topics')) {
        if (this.router.url.includes('regulatorio')) {
          res =
            this.injector.get(AuthStateService).userConfig.config.Regulatorio[this.router.url.slice(1).split('/')[1]]
              .Topics;
        } else if (this.router.url.includes('clipping')) {
          res = this.injector.get(AuthStateService).userConfig.config.Prensa.Topics;
        } else {
          res = this.injector.get(AuthStateService).userConfig.config.Documentos.Topics;
        }
      } else if (url.includes('regions')) {
        if (this.router.url.includes('regulatorio')) {
          res =
            this.injector.get(AuthStateService).userConfig.config.Regulatorio[this.router.url.slice(1).split('/')[1]]
              .Regions;
        } else if (this.router.url.includes('clipping')) {
          res = this.injector.get(AuthStateService).userConfig.config.Prensa.Regions;
        } else {
          res = this.injector.get(AuthStateService).userConfig.config.Documentos.Regions;
        }
      } else if (url.includes('contexts')) {
        if (this.router.url.includes('regulatorio')) {
          res =
            this.injector.get(AuthStateService).userConfig.config.Regulatorio[this.router.url.slice(1).split('/')[1]]
              .Context;
        }
      }

      if (mapping_key && !this.mappings[mapping_key]) this.mappings[mapping_key] = { total: res.length, values: {} };
      this.mappings[mapping_key]['next'] = null;
      let values = {};

      if (mapping_key == 'spider_groups_id' || mapping_key == 'spiders_id') {
        values = this.assign(
          ...res.map((spider_group) => {
            let aux: Array<Object> =
              spider_group.spiders.map((spider) => {
                let ret = {};
                ret[spider.id] =
                  mapping_key == 'spider_groups_id'
                    ? spider_group.visibleName && spider_group.visibleName != ''
                      ? spider_group.visibleName
                      : spider_group.name
                    : spider.visibleName && spider.visibleName != ''
                    ? spider.visibleName
                    : spider.name;
                ret[spider.id] = ret[spider.id].replace(/^-\s*/, '');
                return ret;
              }) || [];

            return aux.length ? this.assign(...aux) : {};
          })
        );
      } else if (mapping_key == 'topics_id') {
        values = this.assign(
          ...res.map((topic_group) => {
            let aux: Array<Object> =
              topic_group.topics.map((topic) => {
                let ret = {};
                ret[topic] =
                  topic_group.visibleName && topic_group.visibleName != '' ? topic_group.visibleName : topic_group.name;
                ret[topic] = ret[topic].replace(/^-\s*/, '');
                return ret;
              }) || [];
            return aux.length ? this.assign(...aux) : {};
          })
        );
      } else {
        values = this.assign(
          ...res.map((group) => {
            let key = mapping_key.replace('_id', ''),
              aux = {};
            if (group[key].length > 0) {
              aux = this.assign(
                ...group[key].map((item_id) => {
                  let ret = {};
                  ret[item_id] = group.visibleName && group.visibleName != '' ? group.visibleName : group.name;
                  ret[item_id] = ret[item_id].replace(/^-\s*/, '');
                  return ret;
                })
              );
            }
            return aux;
          })
        );
      }

      this.addMappings(mapping_key, values);
    }
    resolve(true);
  }

  map(type, key) {
    return type && this.mappings[type] && this.mappings[type].values[key] ? this.mappings[type].values[key] : key;
  }
}

export function AppDataFactory(provider: AppDataService) {
  return () => provider.load();
}
