import { Component } from '@angular/core';
import { KC_REALM_BRAINY, KC_REALM_CEOE, getRealm } from '@app/init/keycloak-init.factory';

@Component({
  selector: 'app-insufficient-permissions',
  templateUrl: './insufficient-permissions.component.html',
  styleUrls: ['./insufficient-permissions.component.css'],
})
export class InsufficientPermissionsComponent {
  getRealm = getRealm;
  KC_REALM_CEOE = KC_REALM_CEOE;
  KC_REALM_BRAINY = KC_REALM_BRAINY;
}
