<div class="example-container-parent" [ngStyle]="{ 'padding-bottom': firstLoad ? '200px' : '32px' }">
  <div class="example-container">
    <div class="fist-form" *ngIf="firstLoad">
      <mat-grid-list cols="1">
        <p class="main-title" *ngIf="getModuleTitle()">
          {{ getModuleTitle() }}
        </p>
        <mat-card class="mat-elevation-z0">
          <!-- <img mat-card-image class="search-logo" src="{{ LOGOURL }}" alt="Logo" /> -->
          <mat-card-content>
            <button
              type="button"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Buscar"
              (click)="$event.stopPropagation(); findDocuments()"
            >
              <mat-icon>search</mat-icon>
            </button>
            <mat-form-field class="example-form-field">
              <input
                #inputsearch
                autocomplete="off"
                matInput
                matTooltip="Para realizar búsquedas sin texto pulsa la tecla Enter o haz click en la lupa..."
                type="text"
                placeholder="Introduce un texto de búsqueda"
                [(ngModel)]="value"
                (keyup.enter)="findDocuments()"
              />
              <button
                mat-button
                *ngIf="value"
                matSuffix
                mat-icon-button
                aria-label="Limpiar"
                (click)="$event.stopPropagation(); value = ''"
              >
                <mat-icon>close</mat-icon>
              </button>
              <button
                mat-button
                matSuffix
                mat-icon-button
                matTooltip="Mostrar editor de búsquedas"
                aria-label="Tipo de buscador"
                (click)="$event.stopPropagation(); openSearchTypeOperatorsDialog()"
                *ngIf="!router.url.includes('vigilancia/') && !router.url.includes('europe/')"
              >
                <mat-icon style="width: 24px; height: 24px; font-size: 24px; color: var(--color-primary-main)"
                  >grid_view</mat-icon
                >
              </button>
            </mat-form-field>
          </mat-card-content>
          <div class="advanced-filters">
            <div
              class="search-type-container"
              [formGroup]="searchTypeFG"
              *ngIf="router.url.includes('vigilancia/') || router.url.includes('europe/')"
            >
              <p class="title-field">Tipo de búsqueda</p>
              <mat-radio-group formControlName="searchType">
                <mat-radio-button
                  color="primary"
                  [value]="searchType.value"
                  *ngFor="let searchType of searchTypeOptions"
                >
                  {{ searchType.label }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div
              fxFlex="1"
              style="max-width: 100% !important"
              [ngStyle]="{
                'max-width': !sectorReports ? '48.5% !important' : '100% !important'
              }"
            >
              <form [formGroup]="range" *ngIf="_appStateService.dates_visible$ | async">
                <p class="title-field">Periodo de búsqueda</p>
                <mat-form-field class="my-form-field" appearance="fill">
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [formControl]="start" readonly placeholder="Fecha inicio" />
                    <input matEndDate [formControl]="end" readonly placeholder="Fecha fin" style="border: none" />
                  </mat-date-range-input>
                  <p
                    *ngIf="rangePresetSelected"
                    style="
                      opacity: 0.85;
                      font-size: 13.5px;
                      position: absolute;
                      top: -6.5px;
                      right: 2px;
                      background: #dbdcdd;
                      border-radius: 3px;
                      overflow: hidden;
                      padding: 6px;
                    "
                  >
                    {{ rangePresetSelected }}
                  </p>
                  <mat-datepicker-toggle
                    id="overview-search-datepicker-toggle"
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker (opened)="openedDatepicker()" (closed)="closedDatepicker()" #picker>
                  </mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
            <div
              fxFlex="1"
              [ngStyle]="{
                display: !sectorReports ? 'block' : 'none'
              }"
            >
              <p class="title-field" *ngIf="clipping">Tipo de prensa</p>
              <section class="section-checkboxes" [formGroup]="clippingFG" *ngIf="clipping">
                <mat-checkbox formControlName="escrita" color="primary">Escrita</mat-checkbox>
                <mat-checkbox formControlName="digital" color="primary">Digital</mat-checkbox>
              </section>
              <p class="title-field" *ngIf="!clipping && !sectorReports">Fuentes</p>
              <angular2-multiselect
                fixSearchBug
                fixAccents
                id="am2-1"
                class="angular2-multiselect-search angular2-multiselect-search-contexts"
                name="spiderGroups"
                [data]="dropdownSpiderGroupsList"
                [(ngModel)]="selectedSpiderGroupsItems"
                [settings]="dropdownSpiderGroupsSettings"
                (click)="handleAngularMultiselectHeight('am2-1')"
                *ngIf="!clipping && !sectorReports"
              >
              </angular2-multiselect>
            </div>
            <div fxFlex="1" *ngIf="regulatorio || vigilancia">
              <p class="title-field">Contextos</p>
              <angular2-multiselect
                fixSearchBug
                fixAccents
                id="am2-2"
                class="angular2-multiselect-search"
                name="contexts"
                [data]="dropdownContextList"
                [(ngModel)]="selectedContextItems"
                [settings]="dropdownContextSettings"
                (click)="handleAngularMultiselectHeight('am2-2')"
              >
              </angular2-multiselect>
            </div>
            <div fxFlex="1" *ngIf="!vigilancia && !europa">
              <p class="title-field">Temas</p>
              <angular2-multiselect
                fixSearchBug
                fixAccents
                id="am2-3"
                class="angular2-multiselect-search"
                name="categories"
                [data]="dropdownCategoryList"
                [(ngModel)]="selectedCategoryItems"
                [settings]="dropdownCategorySettings"
                (click)="handleAngularMultiselectHeight('am2-3')"
              >
                <c-item>
                  <ng-template let-item="item">
                    <label
                      style="padding: 4px 8px; border-radius: 4px"
                      [style.background-color]="getCategoryColor(item.itemName)"
                    >
                      {{ item.itemName }}</label
                    >
                  </ng-template>
                </c-item>
              </angular2-multiselect>
            </div>
            <div fxFlex="1" *ngIf="!europa">
              <p class="title-field">Región</p>
              <angular2-multiselect
                fixSearchBug
                fixAccents
                id="am2-4"
                class="angular2-multiselect-search"
                name="regions"
                [data]="dropdownRegionsList"
                [(ngModel)]="selectedRegionsItems"
                [settings]="dropdownRegionsSettings"
                (click)="handleAngularMultiselectHeight('am2-4')"
              >
              </angular2-multiselect>
            </div>
            <div class="btn-search-container">
              <!-- <button mat-button (click)="viewHelp()">
                <mat-icon fontSet="material-icons-outlined">info</mat-icon>
                Ver funcionamiento
              </button> -->
              <button mat-button color="primary" class="btn-search" (click)="findDocuments()">Buscar</button>
            </div>
          </div>
        </mat-card>
      </mat-grid-list>
    </div>

    <div class="example-loading-shade" *ngIf="(isLoadingResults || isRateLimitReached) && !firstLoad">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      <div class="no-data" *ngIf="isRateLimitReached">
        <img src="assets/imgs/404-illustration-no-data.svg" />
        <p class="title">Sin datos</p>
        <p class="description">Hemos encontrado un problema con las comunicaciones, inténtelo de nuevo más tarde.</p>
      </div>
    </div>

    <div class="example-loading-shade" *ngIf="resultsLength == 0 && !isLoadingResults && !firstLoad">
      <div class="no-data">
        <img src="assets/imgs/404-illustration-no-data.svg" />
        <p class="title">Sin datos</p>
        <p class="description">
          No se han encontrado resultados con los filtros actuales. Prueba a realizar otra búsqueda.
        </p>
      </div>
    </div>

    <div
      [ngClass]="verbarra ? 'example-table-container' : 'ocultabarra'"
      class="example-table-container"
      *ngIf="!firstLoad"
      id="bloquelistado"
    >
      <p class="main-title" [ngStyle]="{ display: isLoadingResults || isRateLimitReached ? 'none' : 'block' }">
        {{ getModuleTitle() }}
      </p>
      <table
        mat-table
        [dataSource]="items"
        class="example-table table-documents"
        matSort
        matSortActive="dateParsed"
        matSortDirection="desc"
        [ngStyle]="{ display: isLoadingResults || isRateLimitReached ? 'none' : 'block' }"
      >
        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <div *ngIf="showing" style="height: 90px"></div>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="abstractRow">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <h2 (click)="opendocument(row)">
                  {{ row.title.trim() }}
                </h2>
                <div
                  [ngStyle]="{
                    background: getScoreColorByPercent(row.score | percent: '1.0-0'),
                    padding: '4px',
                    color: 'rgb(37, 37, 37)',
                    'border-radius': '4px',
                    'font-weight': 500,
                    display: 'flex',
                    'align-items': 'center',
                    gap: '4px',
                    height: 'fit-content',
                    'margin-top': '4px'
                  }"
                  matTooltip="Relevancia"
                  *ngIf="
                    value &&
                    searchTypeFG.controls.searchType.value === 'semantic' &&
                    row.score &&
                    scoreValue > 0 &&
                    selected == 3 &&
                    (router.url.includes('vigilancia/') || router.url.includes('europe/'))
                  "
                >
                  <mat-icon>ads_click</mat-icon>
                  <span>
                    {{ row.score | percent: '1.0-0' }}
                  </span>
                </div>
                <mat-icon
                  class="icontype"
                  style="margin-top: 8px; margin-left: 12px"
                  svgIcon="{{ row.docType }}"
                ></mat-icon>
                <button
                  mat-icon-button
                  color="primary"
                  style="box-shadow: none; margin-left: 12px"
                  (click)="addfav(row); $event.stopPropagation()"
                  *ngIf="!row._favorite"
                >
                  <mat-icon class="futurofavorito" style="color: var(--tailwind-color-gray-300)">
                    folder_special
                  </mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="primary"
                  style="margin-left: 8px"
                  *ngIf="row._favorite"
                  (click)="delfav(row); $event.stopPropagation()"
                >
                  <mat-icon class="favorito"> folder_special </mat-icon>
                </button>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="source_row">Extraído de {{ row.doc_source }}</div>
                <div class="date_row" (click)="change_dates($event, row); $event.stopPropagation()">
                  {{ row.date_published }}
                </div>
              </div>
              <p class="abstractRow" [innerHTML]="row.highligth.trim()"></p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="dateParsed">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: flex-end;
              gap: 6px;
              margin: 0;
              padding: 6px;
            "
          >
            <mat-form-field
              *ngIf="
                searchTypeFG.controls.searchType.value === 'semantic' &&
                selected == 3 &&
                (router.url.includes('vigilancia/') || router.url.includes('europe/'))
              "
            >
              <mat-label style="min-width: 80px">Elementos</mat-label>
              <input
                matInput
                id="input-candidates-search"
                type="number"
                [min]="0"
                [max]="100"
                [(value)]="candidatesValue"
                (focusout)="clickTopSearch()"
              />
            </mat-form-field>
            <div
              class="slider-container"
              *ngIf="
                searchTypeFG.controls.searchType.value === 'semantic' &&
                selected == 3 &&
                (router.url.includes('vigilancia/') || router.url.includes('europe/'))
              "
            >
              <p>Relevancia ({{ scoreValue || 0 }}%)</p>
              <mat-slider
                id="input-score-search"
                [min]="0"
                [max]="100"
                [step]="5"
                [thumbLabel]="true"
                [(ngModel)]="scoreValue"
                (change)="clickTopSearch()"
              ></mat-slider>
            </div>
            <mat-form-field style="display: flex; align-items: center">
              <mat-label style="min-width: 80px">Ordenar por</mat-label>
              <mat-select [(value)]="selected" (selectionChange)="cambiaOrden($event)">
                <mat-option value="1">Fecha descendente</mat-option>
                <mat-option value="2">Fecha ascendente</mat-option>
                <mat-option value="3">Relevancia</mat-option>
              </mat-select>
            </mat-form-field>
            <span class="example-spacer"></span>
            <button mat-button matSuffix mat-icon-button matRipple class="myicon" (click)="modalFav($event)">
              <mat-icon
                *ngIf="!withSavedSearch"
                matTooltip="Guardar búsqueda en favoritos"
                aria-hidden="false"
                aria-label="Guardar búsqueda en favoritos"
                >star_border</mat-icon
              >
              <mat-icon
                *ngIf="withSavedSearch"
                class="active_fav_search"
                matTooltip="Guardar búsqueda en favoritos"
                aria-hidden="false"
                aria-label="Guardar búsqueda en favoritos"
                color="primary"
                >star</mat-icon
              >
            </button>
            <button mat-stroked-button color="primary" (click)="resetSearch()">Restablecer la búsqueda</button>
          </th>
          <div class="divider"></div>

          <td valign="top" mat-cell *matCellDef="let row">
            <div
              class="categorias"
              *ngIf="
                authStateService.userConfig.interface_config.categories === 'legacy' ||
                router.url.includes('sector-reports/search/')
              "
            >
              <p *ngFor="let category of row.categories" [style.background-color]="getCategoryColor(category)">
                {{ category }}
              </p>
            </div>
            <div class="categorias" *ngIf="authStateService.userConfig.interface_config.categories === 'eurovoc'">
              <p *ngFor="let taxonomy of row.taxonomies" [style.background-color]="getCategoryColor(taxonomy)">
                {{ taxonomy }}
              </p>
            </div>
            <div class="divider"></div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div
      class="paginator"
      [ngStyle]="{ display: isLoadingResults || isRateLimitReached ? 'none' : 'flex' }"
      *ngIf="!firstLoad"
    >
      <mat-paginator [length]="resultsLength" [pageSize]="30" (page)="pageEvent($event)"></mat-paginator>
      <!-- <div class="page-select">
        <mat-form-field>
          <mat-label>Página</mat-label>
          <input
            matInput
            type="number"
            [min]="1"
            [max]="getNumberOfPages()"
            [value]="getActualPage() + 1"
            #pageInput
            (keyup.enter)="
              pageInput.value >= 1 && pageInput.value <= getNumberOfPages()
                ? updateManualPage(pageInput.value - 1)
                : null
            "
          />
        </mat-form-field>
      </div> -->
    </div>
  </div>
</div>
<app-paneldoc
  [doc_item]="doc_item"
  [lookfortext]="lookfortext"
  *ngIf="documentpanel !== undefined"
  (close)="closepanel()"
></app-paneldoc>
