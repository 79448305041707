<h1 mat-dialog-title>Cadenas de texto</h1>
<div class="description">
  Combina diferentes cadenas de texto para obtener resultados en base a las palabras y los operadores empleados.
</div>
<div mat-dialog-content class="content">
  <p class="title">Operadores</p>
  <div class="section">
    <mat-chip class="type-operator">AND</mat-chip>
    <p class="desc">Asocia términos para encontrar registros que cumplan ambas cadenas</p>
  </div>
  <div class="section">
    <mat-chip class="type-operator">OR</mat-chip>
    <p class="desc">Asocia términos para encontrar registros que cumplan alguna de las cadenas</p>
  </div>
  <p class="title">Acciones</p>
  <div class="section">
    <img src="assets/imgs/operator_and_cursor.png" />
    <p class="desc">Si clicas sobre un operador, este cambiará al siguiente disponible</p>
  </div>
  <div class="section">
    <img src="assets/imgs/text_texto_cursor.png" />
    <p class="desc">Si clicas sobre un texto, podrás editar su valor</p>
  </div>
</div>
<div class="actions">
  <button mat-flat-button [mat-dialog-close]="true" cdkFocusInitial color="primary">Cerrar</button>
</div>
