import { Component, OnInit, ChangeDetectorRef, Injector } from '@angular/core';
import { BaseChart } from '@modules/br-widgets/components/charts/base/base.component';
import { Formater } from '@modules/br-widgets/utils/formater';
import rainbowColorsJson from '@assets/rainbow-colors.json';
import { UtilsService } from '@app/services/utils.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
@Component({
  selector: 'app-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.css'],
})
export class LinechartComponent extends BaseChart implements OnInit {
  rainbowColors: string[] = this.utilsService.extendArray(rainbowColorsJson, 10);

  constructor(
    public injector: Injector,
    private formater: Formater,
    public changeDetector: ChangeDetectorRef,
    private authStateService: AuthStateService,
    private utilsService: UtilsService
  ) {
    super(injector, changeDetector);
  }

  refresh(config, response) {
    this.isLoading = true;

    this.results = new Array({ name: 'month', series: this.formater.format(config, response) });

    this.options.xAxis = {
      type: 'category',
      data: this.results[0].series.map((a) => a.name),
    };

    this.options.yAxis = {
      type: 'value',
    };

    this.options.tooltip = {
      show: true,
      trigger: 'axis',
    };

    const userEchartsColors =
      this.authStateService.userConfig.interface_config.company.color_palette?.echarts?.split(',') ??
      this.rainbowColors;

    this.options.series = [
      {
        color: userEchartsColors,
        data: this.results[0].series,
        type: 'line',
      },
    ];

    this.options.dataZoom = [
      {
        id: 'dataZoomX',
        type: 'slider',
        xAxisIndex: [0],
        filterMode: 'filter',
      },
    ];

    this.echartsInstance.setOption(this.options);
    this.isLoading = false;
    this.seriesLoaded.emit('linechart');
  }

  onChartInit(e) {
    this.echartsInstance = e;
    this.loadData();
  }

  loadData() {
    super.ngAfterViewInit();
    if (this.wconfig.config) {
      this.xAxisLabel = this.wconfig.config['config']['xAxisLabel'];
      this.yAxisLabel = this.wconfig.config['config']['yAxisLabel'];
      let config = Object.assign({}, this.wconfig.config['query']);
      config['aggregations'] = this.wconfig.config['aggregations'];
      // remove this
      delete config['dateParsed_start'];
      delete config['dateParsed_end'];
      this.update(config, (config, response) => {
        this.refresh(config, response);
      });
    }
  }
}
