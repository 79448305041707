<div class="container-generating" *ngIf="generating">
  <div class="header">
    <p>{{ matData.data.title }}</p>
    <p>Estamos generando el informe... Espere un momento por favor.</p>
  </div>
  <ng-lottie width="450px" [options]="{ path: '/assets/animation_robot_person.json' }"></ng-lottie>
</div>

<div class="container" *ngIf="!generating" [formGroup]="form">
  <div class="header">
    <mat-form-field appearance="fill" class="form-field-no-padding-bottom">
      <mat-label>Título del informe</mat-label>
      <input matInput formControlName="title" />
      <mat-error class="error-validator" *ngIf="form.controls.title.hasError('required')">Campo requerido</mat-error>
    </mat-form-field>
    <button mat-button color="primary" class="btn-same-color btn-same-color-primary" (click)="exportDoc()">
      <mat-icon>download</mat-icon>
      Exportar Informe
    </button>
    <button mat-flat-button color="primary" class="btn-same-color btn-same-color-primary" (click)="saveDoc()">
      <mat-icon>save</mat-icon>
      Guardar
    </button>
  </div>
  <quill-editor class="quill-editor-gray" formControlName="text" placeholder="Insertar texto"></quill-editor>
</div>
