import { Component, OnInit, Inject, ElementRef, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, take } from 'rxjs/operators';
import { DocumentService } from '../../services/document.service';
import { SearchService } from '@app/modules/main/components/search/services/search.service';
import { Router } from '@angular/router';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';

@Component({
  selector: 'app-dialogfav',
  templateUrl: './dialogfav.component.html',
  styleUrls: ['./dialogfav.component.css'],
})
export class DialogfavComponent implements OnInit {
  name: string;

  constructor(
    public dialogRef: MatDialogRef<DialogfavComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sService: SearchService,
    private snackbarStateService: SnackbarStateService,
    private documentService: DocumentService,
    private router: Router
  ) {}

  ngOnInit() {}

  addFavSearch() {
    if (this.name.length > 0) {
      this.data.name = this.name;

      // if (this.router.url.includes('clipping/digital')) {
      //   this.data.data.section = 'clipping/digital';
      // } else if (this.router.url.includes('clipping/escrita')) {
      //   this.data.data.section = 'clipping/escrita';
      // } else {
      //   this.data.data.section = 'general';
      // }

      if (this.data.withSavedSearch) {
        this.sService.saveFavoriteSearch(this.data).subscribe((ev) => {
          this.dialogRef.close(1);
        });
      } else {
        this.sService.saveFavoriteSearch(this.data).subscribe((ev) => {
          this.snackbarStateService.show('Búsqueda guardada correctamente', 5000);
          this.dialogRef.close(1);
        });
      }
    }
  }
}
