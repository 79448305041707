import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditMagazine, GetMagazines } from '@app/modules/documents/store/magazine/magazine.actions';
import { MagazineStateService } from '@app/modules/documents/store/magazine/magazine.service';
import { MagazineService } from '@app/services/magazine.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-thumb-estimated-cost',
  templateUrl: './thumb-estimated-cost.component.html',
  styleUrls: ['./thumb-estimated-cost.component.scss'],
})
export class ThumbEstimatedCostComponent {
  componentDestroyed$: Subject<boolean> = new Subject();

  magazineSelected?;
  form: FormGroup;
  generating = false;
  loadingEstimatedPrice = true;
  estimatedPrice = 0;
  estimatedPriceData = [];

  constructor(
    public dialogRef: MatDialogRef<ThumbEstimatedCostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private magazineStateService: MagazineStateService,
    private magazineService: MagazineService,
    private actions: Actions,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      checkboxes: this.fb.array([]),
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(EditMagazine)).subscribe(() => {
      setTimeout(() => {
        this.magazineStateService.getMagazines();
      }, 0);
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetMagazines)).subscribe(() => {
      setTimeout(() => {
        this.generating = false;
        this.dialogRef.close(this.magazineSelected);
      }, 0);
    });

    this.magazineService.getDossierEstimatedPrice(this.data).subscribe((res) => {
      this.estimatedPriceData = res.data;

      this.checkboxes.valueChanges.subscribe((values) => {
        this.estimatedPrice = res.data.reduce((sum, item, index) => {
          return values[index] ? sum + item.credits : sum;
        }, 0);
      });

      this.addCheckboxes();
      this.loadingEstimatedPrice = false;
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  get checkboxes() {
    return this.form.get('checkboxes') as FormArray;
  }

  addCheckboxes() {
    this.data.searches.forEach((s) => this.checkboxes.push(new FormControl(s.show)));
  }

  hasAtLeastOneCheckboxChecked(): boolean {
    return this.checkboxes.controls.some((control) => control.value === true);
  }

  submit() {
    const magazine = JSON.parse(JSON.stringify(this.data));

    magazine.searches.forEach((search, i) => {
      magazine.searches[i].show = this.checkboxes.at(i).value;
    });

    this.magazineSelected = magazine;
    this.generating = true;

    this.magazineStateService.editMagazine(this.magazineSelected);
  }
}
