import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { CollectiveAgreementsState } from './collective-agreements.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import {
  GetFilters,
  GetCollectiveAgreement,
  GetCollectiveAgreements,
  ToggleCollectiveAgreementsMenu,
  UpdateCollectiveAgreementsMenuFilters,
} from './collective-agreements.actions';
import { CollectiveAgreementsService } from '../../services/collective-agreements.service';
import {
  CollectiveAgreement,
  CollectiveAgreementsFilters,
  CollectiveAgreementsGetRequest,
  CollectiveAgreementsGetResponse,
} from '../../models/collective-agreement';

@Injectable({ providedIn: 'root' })
export class CollectiveAgreementsStateService {
  @Select(CollectiveAgreementsState.collectiveAgreements)
  public collectiveAgreements$: Observable<CollectiveAgreementsGetResponse>;

  @SelectSnapshot(CollectiveAgreementsState.collectiveAgreements)
  public collectiveAgreements: CollectiveAgreementsGetResponse;

  @Select(CollectiveAgreementsState.collectiveAgreement)
  public collectiveAgreement$: Observable<CollectiveAgreement>;

  @SelectSnapshot(CollectiveAgreementsState.collectiveAgreement)
  public collectiveAgreement: CollectiveAgreement;

  @Select(CollectiveAgreementsState.openedMenu)
  public openedMenu$: Observable<boolean>;

  @SelectSnapshot(CollectiveAgreementsState.openedMenu)
  public openedMenu: boolean;

  @Select(CollectiveAgreementsState.filters)
  public filters$: Observable<CollectiveAgreementsFilters>;

  @SelectSnapshot(CollectiveAgreementsState.filters)
  public filters: CollectiveAgreementsFilters;

  constructor(
    private _snackbarStateService: SnackbarStateService,
    private collectiveAgreementsService: CollectiveAgreementsService
  ) {}

  @Dispatch()
  public getCollectiveAgreements = (request: CollectiveAgreementsGetRequest) => {
    return this.collectiveAgreementsService.getCollectiveAgreements(request).pipe(
      map((response) => {
        return new GetCollectiveAgreements(response);
      }),
      catchError((error) => {
        // if (error.status === 404) {
        //   return of(
        //     new GetCollectiveAgreements({
        //       ...request,
        //       total: 0,
        //       total_pages: 0,
        //       items: [],
        //     })
        //   );
        // }
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getCollectiveAgreement = (request: string) => {
    return this.collectiveAgreementsService.getCollectiveAgreement(request).pipe(
      map((response) => {
        return new GetCollectiveAgreement(
          // response.map((r) => {
          //   return {
          //     ...r,
          //     cnae: [
          //       '6499 - Otros servicios financieros, excepto seguros y fondos de pensiones n.c.o.p.',
          //       '4611 - Intermediarios del comercio de materias primas agrarias, animales vivos',
          //       'Test',
          //     ],
          //   };
          // })[0]
          Array.isArray(response) ? response[0] : response
        );
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getFilters = () => {
    return this.collectiveAgreementsService.getFilters().pipe(
      map((response) => {
        return new GetFilters(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public toggleCollectiveAgreementsMenu = () => {
    return new ToggleCollectiveAgreementsMenu();
  };

  @Dispatch()
  public updateCollectiveAgreementsMenuFilters = (request: CollectiveAgreementsFilters) => {
    return new UpdateCollectiveAgreementsMenuFilters(request);
  };
}
