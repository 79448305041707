import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgLayer } from '../../lib/ng-map.lib';

@Component({
  selector: 'ng-layer-selector-item',
  templateUrl: './ng-layer-selector-item.component.html',
  styleUrls: ['./ng-layer-selector-item.component.scss'],
})
export class NgLayerSelectorItemComponent implements OnInit {
  @Input() layer: NgLayer | null = null;
  @Output() onToggle: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onChange(uuid) {
    this.onToggle.emit(uuid);
  }

  onChangeStack(builtLayer) {
    this.onToggle.emit(builtLayer);
  }
}
