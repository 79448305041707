import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TendersAlertsComponent } from './tenders-alerts/tenders-alerts.component';
import { TendersFavoritesComponent } from './tenders-favorites/tenders-favorites.component';
import { TendersHomeComponent } from './tenders-home/tenders-home.component';
import { TendersSavedSearchsComponent } from './tenders-saved-searchs/tenders-saved-searchs.component';
import { TendersSearchDetailComponent } from './tenders-search/tenders-search-detail/tenders-search-detail.component';
import { TendersSearchComponent } from './tenders-search/tenders-search.component';
import { TendersSupportComponent } from './tenders-support/tenders-support.component';
import { TendersComponent } from './tenders.component';

// export const TendersRoutes: Routes = [
//   {
//     path: '',
//     component: TendersComponent,
//     children: [
//       { path: '', component: TendersHomeComponent },
//       { path: 'search', component: TendersSearchComponent },
//       // { path: 'search/:id', component: TendersSearchDetailComponent },
//       { path: 'alerts', component: TendersAlertsComponent },
//       { path: 'favorites', component: TendersFavoritesComponent },
//       { path: 'saved-searchs', component: TendersSavedSearchsComponent },
//       { path: 'support', component: TendersSupportComponent },
//     ],
//   },
// ];

export const TendersRoutes: Routes = [
  { path: '', component: TendersHomeComponent },
  { path: 'search', component: TendersSearchComponent },
  // { path: 'search/:id', component: TendersSearchDetailComponent },
  { path: 'alerts', component: TendersAlertsComponent },
  { path: 'favorites', component: TendersFavoritesComponent },
  { path: 'saved-searchs', component: TendersSavedSearchsComponent },
  // { path: 'support', component: TendersSupportComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(TendersRoutes)],
  exports: [RouterModule],
})
export class TendersRoutingModule {}
