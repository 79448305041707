import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { forkJoin, of } from 'rxjs';
import { Favorite } from '@app/models/Favorite';
import { DialogAlertComponent } from '@modules/documents/components/dialogalert/dialogalert.component';
import { MsgyesnoComponent } from '@app/components/msgyesno/msgyesno.component';
import { AppStateService } from '@app/state/app/app.service';
import { AppStatusService } from '@app/services/app-status.service';
import { AppDataService } from '@app/services/app-data.service';
import { SearchService } from '@app/modules/main/components/search/services/search.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { TendersService } from '@app/modules/ceoe/services/tenders.service';
import { SubventionsService } from '@app/modules/ceoe/services/subventions.service';
import { TendersStateService } from '@app/modules/ceoe/store/tenders/tenders.service';
import { SubventionsStateService } from '@app/modules/ceoe/store/subventions/subventions.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { DeleteAlert as DeleteAlertTenders } from '@app/modules/ceoe/store/tenders/tenders.actions';
import { DeleteAlert as DeleteAlertSubventions } from '@app/modules/ceoe/store/subventions/subventions.actions';
import { Location } from '@angular/common';
import { DeleteAlert } from '@app/modules/vigilancia/store/calendar-pp/calendar-pp.actions';
import { CalendarPPStateService } from '@app/modules/vigilancia/store/calendar-pp/calendar-pp.service';
import { NavItem } from '@app/models/User';
import { catchError, tap } from 'rxjs/operators';
import { VigilanciaService } from '@app/modules/vigilancia/services/vigilancia.service';
import { getRealm, KC_REALM_CEOE } from '@app/init/keycloak-init.factory';

@Component({
  selector: 'app-fav-search',
  templateUrl: './fav-search.component.html',
  styleUrls: ['./fav-search.component.scss'],
})
export class FavSearchComponent implements OnInit {
  tabs: {
    label: string;
    identifier:
    | 'documents'
    | 'clipping'
    | 'regulatorio'
    | 'vigilancia'
    | 'ppublic'
    | 'europe'
    | 'europe-union'
    | 'licitaciones'
    | 'ayudas_y_subvenciones';
  }[] = [];
  allItems: any;
  moduleSelected:
    | 'documents'
    | 'clipping'
    | 'regulatorio'
    | 'vigilancia'
    | 'ppublic'
    | 'europe'
    | 'europe-union'
    | 'licitaciones'
    | 'ayudas_y_subvenciones';
  displayedColumns: string[] = ['name', 'origin', 'createdAt', 'tools'];
  isLoadingResults = true;
  response: boolean;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private sService: SearchService,
    private router: Router,
    private appStatus: AppStatusService,
    private _appStateService: AppStateService,
    public dialog: MatDialog,
    private authStateService: AuthStateService,
    private tendersService: TendersService,
    private vigilanciaService: VigilanciaService,
    private calendarPPStateService: CalendarPPStateService,
    private subventionsService: SubventionsService,
    private tendersStateService: TendersStateService,
    private subventionsStateService: SubventionsStateService,
    private location: Location,
    private actions: Actions,
    private injector: Injector
  ) { }

  ngOnInit() {
    this.checkRoles();
    this.getFavorites();
  }

  manAlert(id: number, ev, mode: string) {
    const item = this.allItems[this.moduleSelected].find((item) => item.id === id);
    const dialogRef = this.dialog.open(DialogAlertComponent, {
      width: '400px',
      height: '260px',
      data: { name: item.name, query: item.data, alert: mode === 'edit' ? item.alert : null },
    });

    dialogRef.componentInstance.thedata = {
      name: item.name,
      query: item.data,
      alert: mode === 'edit' ? item.alert : null,
    };
    dialogRef.componentInstance.mode = mode;
    dialogRef.componentInstance.id = id;

    let ypos = ev.pageY;
    let maxheight = ev.view.innerHeight;
    if (ypos + 260 > maxheight) ypos = maxheight - 260;
    dialogRef.updatePosition({ top: ypos + 'px', left: ev.pageX - 400 + 'px' });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined)
        if (result != null) item.alert = result;
        else item.alert = null;
    });
  }

  editAlert(id: number, ev) {
    //
  }

  doSearch(row: any) {
    if (
      this.moduleSelected === 'documents' ||
      this.moduleSelected === 'regulatorio' ||
      this.moduleSelected === 'vigilancia' ||
      this.moduleSelected === 'europe' ||
      this.moduleSelected === 'europe-union' ||
      this.moduleSelected === 'clipping'
    ) {
      this.router.navigateByUrl(`${row.url}?withSavedSearch=${row.id}`).then(() => {
        this.location.replaceState(row.url);
      });

      const searchpost = {
        text_search: row.data.text,
        start: row.data.dateParsed_start,
        end: row.data.dateParsed_end,
        spiderIds: (row.data.spiderIds || []).map((_id) => ({
          id: _id,
          itemName: this.injector.get(AppDataService).map('spiders_id', _id),
        })),
        docTopics: (row.data.docTopics || []).map((_id) => ({
          id: _id,
          itemName: this.injector.get(AppDataService).map('topics_id', _id),
        })),
        docContexts: (row.data.docContexts || []).map((_id) => ({
          id: _id,
          itemName: this.injector.get(AppDataService).map('contexts_id', _id),
        })),
        docTopicsRegion: (row.data.docTopicsRegion || []).map((_id) => ({
          id: _id,
          itemName: this.injector.get(AppDataService).map('regions_id', _id),
        })),
        clippingsSelected: {
          escrita: false,
          digital: false,
        },
        score: row.data.score,
        candidates: row.data.candidates,
        searchType: row.data.searchType,
        sort: this.sService.getSortFilterIdentifier(row.data.sort),
      };

      if (this.moduleSelected === 'clipping') {
        if (row.origen === 'ambas') {
          searchpost.clippingsSelected = {
            escrita: true,
            digital: true,
          };
        } else if (row.origen === 'escrita') {
          searchpost.clippingsSelected = {
            escrita: true,
            digital: false,
          };
        } else if (row.origen === 'digital') {
          searchpost.clippingsSelected = {
            escrita: false,
            digital: true,
          };
        }
      }

      setTimeout(() => {
        this.appStatus.search_launch.emit(searchpost);
        this._appStateService.showSearchText();
      }, 100);
    } else if (this.moduleSelected === 'licitaciones') {
      this.router.navigate(['tenders', 'search'], { queryParams: { withFiltersFromAlerts: row.id } });
    } else if (this.moduleSelected === 'ayudas_y_subvenciones') {
      this.router.navigate(['subventions', 'search'], { queryParams: { withFiltersFromAlerts: row.id } });
    } else if (this.moduleSelected === 'ppublic') {
      if (getRealm() !== KC_REALM_CEOE) {
        this.router.navigate(['regulatorio', 'calendarPp'], { queryParams: { withFiltersFromAlerts: row.id } });
      } else {
        this.router.navigate(['vigilancia', 'tnor', 'fa', 'calendarPp'], { queryParams: { withFiltersFromAlerts: row.id } });
      }
    }
  }

  deleteSearch(id: number) {
    this.moduleSelected = this.getActiveIdentifier();
    if (
      this.moduleSelected === 'documents' ||
      this.moduleSelected === 'regulatorio' ||
      this.moduleSelected === 'vigilancia' ||
      this.moduleSelected === 'europe' ||
      this.moduleSelected === 'europe-union' ||
      this.moduleSelected === 'clipping'
    ) {
      this.sService.deleteFavoriteSearch(id).subscribe(() => {
        this.allItems[this.getActiveIdentifier()] = this.allItems[this.getActiveIdentifier()].filter(
          (item) => item.id !== id
        );

        this.setItems();
      });
    } else if (this.moduleSelected === 'licitaciones') {
      this.actions.pipe(ofActionSuccessful(DeleteAlertTenders)).subscribe(() => {
        this.allItems[this.getActiveIdentifier()] = this.allItems[this.getActiveIdentifier()].filter(
          (item) => item.id !== id
        );

        this.setItems();
      });

      this.tendersStateService.deleteAlert(id);
    } else if (this.moduleSelected === 'ayudas_y_subvenciones') {
      this.actions.pipe(ofActionSuccessful(DeleteAlertSubventions)).subscribe(() => {
        this.allItems[this.getActiveIdentifier()] = this.allItems[this.getActiveIdentifier()].filter(
          (item) => item.id !== id
        );

        this.setItems();
      });

      this.subventionsStateService.deleteAlert(id);
    } else if (this.moduleSelected === 'ppublic') {
      this.actions.pipe(ofActionSuccessful(DeleteAlert)).subscribe(() => {
        this.allItems[this.getActiveIdentifier()] = this.allItems[this.getActiveIdentifier()].filter(
          (item) => item.id !== id
        );

        this.setItems();
      });

      this.calendarPPStateService.deleteAlert(id);
    }
  }

  openDialog(id: number): void {
    const dialogRef = this.dialog.open(MsgyesnoComponent, {
      width: '350px',
      data: {
        message: 'La búsqueda guardada y todas sus alertas relacionadas se eliminarán ¿está seguro?',
        response: this.response,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.response = result;
      if (result) {
        this.deleteSearch(id);
      }
    });
  }

  getFavorites() {
    const identifiers = this.tabs.map((t) => t.identifier);

    forkJoin(
      identifiers.map((i) => {
        if (i === 'licitaciones') {
          return this.tendersService.getAlerts({ page: 1, size: 999 }).pipe(catchError(() => of(this.getEmptyPaginationObject())));
        } else if (i === 'ayudas_y_subvenciones') {
          return this.subventionsService.getAlerts({ page: 1, size: 999, abierta: true }).pipe(catchError(() => of(this.getEmptyPaginationObject())));
        } else if (i === 'ppublic') {
          return this.vigilanciaService!.getAlerts({ page: 1, size: 999, ccaa: '', min: '', search: '', type: '' }).pipe(catchError(() => of(this.getEmptyPaginationObject())));
        } else {
          return this.sService!.getFavoriteSearch({ tipo: i }).pipe(catchError(() => of(this.getEmptyPaginationObject())));
        }
      })
    ).subscribe({
      next: (data) => {
        this.allItems = data.reduce((acc, curr, index) => {
          if (curr) {
            acc[this.tabs[index].identifier] =
              curr.results ||
              curr.items.map((i) => {
                return {
                  ...i,
                  origen: i.origen ?? this.tabs[index].label,
                };
              }) ||
              [];
          } else {
            acc[this.tabs[index].identifier] = [];
          }
          return acc;
        }, {});

        this.setItems();
        this.isLoadingResults = false;
      },
    });
  }

  getEmptyPaginationObject() {
    return {
      items: [],
      page: 1,
      size: 999,
      total: 0,
      total_pages: 0
    }
  }

  setItems(): void {
    this.moduleSelected = this.getActiveIdentifier();
    this.allItems[this.moduleSelected] = this.allItems[this.getActiveIdentifier()];
  }

  checkRoles(): void {
    const mainModulesAllowedHeaders = [
      'Documentos',
      'Seguimiento Legislativo',
      'Vigilancia Legislativa',
      'Europa',
      'Unión Europea',
      'Prensa',
      'Licitaciones',
      'Ayudas y subvenciones',
    ];

    this.authStateService.userConfig.interface_config.menu_items
      .filter((mi) => mainModulesAllowedHeaders.includes(mi.header) && mi.is_allowed === 'true' && mi.query_favorite === 'true')
      .forEach((parentItem) => {
        let identifier:
          | 'documents'
          | 'clipping'
          | 'regulatorio'
          | 'vigilancia'
          | 'europe'
          | 'europe-union'
          | 'licitaciones'
          | 'ayudas_y_subvenciones';

        if (!this.moduleSelected) {
          this.moduleSelected = identifier;
        }

        switch (parentItem.header) {
          case 'Documentos':
            identifier = 'documents';
            break;
          case 'Seguimiento Legislativo':
            identifier = 'regulatorio';
            break;
          case 'Vigilancia Legislativa':
            identifier = 'vigilancia';
            break;
          case 'Europa':
            identifier = 'europe';
            break;
          case 'Unión Europea':
            identifier = 'europe-union';
            break;
          case 'Prensa':
            identifier = 'clipping';
            break;
          case 'Licitaciones':
            identifier = 'licitaciones';
            break;
          case 'Ayudas y subvenciones':
            identifier = 'ayudas_y_subvenciones';
            break;
        }

        this.tabs.push({ label: parentItem.header, identifier });
      });

    let ppublicModule: NavItem | null = null;

    if (getRealm() === KC_REALM_CEOE) {
      ppublicModule = this.authStateService.userConfig.interface_config.menu_items
        .find((mi) => mi.header === 'Vigilancia Legislativa')?.items?.find(i => i.path === '/vigilancia/tnor')?.items?.find(i => i.path === '/vigilancia/tnor/fa/calendarPp');
    } else {
      ppublicModule = this.authStateService.userConfig.interface_config.menu_items
        .find((mi) => mi.header === 'Seguimiento Legislativo')?.items?.find(i => i.path === '/regulatorio/calendarPp');
    }

    if (ppublicModule?.query_favorite === 'true') {
      this.tabs.push({ label: 'Participación Pública', identifier: 'ppublic' });
    }
  }

  getActiveIdentifier():
    | 'documents'
    | 'clipping'
    | 'regulatorio'
    | 'vigilancia'
    | 'ppublic'
    | 'europe'
    | 'europe-union'
    | 'licitaciones'
    | 'ayudas_y_subvenciones' {
    return this.tabs.find(
      (tab) =>
        tab.label ===
        (document.querySelector('.mat-tab-label-active .mat-tab-label-content') as HTMLDivElement).textContent
    )?.identifier;
  }
}
