<form [formGroup]="filtersForm">
  <div class="header">
    <p>Filtros</p>
    <a color="primary" (click)="resetFilters()">Restablecer filtros</a>
  </div>
  <p class="loader" *ngIf="applyingFilters">
    <mat-spinner [diameter]="20" *ngIf="loading || applyingFilters"></mat-spinner>
    Aplicando filtros...
  </p>
  <!-- <mat-form-field appearance="fill">
    <input matInput formControlName="texto" />
    <input matInput />
  </mat-form-field> -->
  <span *ngIf="filters">
    <div class="section" *ngFor="let filter of filters.filters; let i = index">
      <span *ngIf="i < filters.filters.length">
        <mat-form-field class="section" style="width: 100%" *ngIf="filter.type === 1 || filter.type === 2">
          <mat-label>{{ filter.label }}</mat-label>
          <input matInput type="text" [formControlName]="filter.attr" />
        </mat-form-field>
        <mat-form-field class="section" style="width: 100%" *ngIf="filter.type === 5">
          <mat-label>{{ filter.label }}</mat-label>
          <input matInput type="text" [matDatepicker]="picker" [formControlName]="filter.attr" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="filter.attr.invalid">
            <span *ngIf="filter.attr.errors.required || filter.attr.invalid">Campo requerido.</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="section" style="width: 100%" *ngIf="filter.type === 6">
          <mat-label>{{ filter.label }}</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [formControlName]="filter.attr + 'Start'" placeholder="dd/mm/aaaa" />
            <input matEndDate [formControlName]="filter.attr + 'End'" placeholder="dd/mm/aaaa" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-checkbox color="primary" [formControlName]="filter.attr" *ngIf="filter.type === 7">
          {{ filter.label }}
        </mat-checkbox>
      </span>
    </div>

    <span class="section section-dropdowns">
      <div *ngFor="let filter of filters.filters; let i = index">
        <span *ngIf="i < filters.filters.length">
          <span *ngIf="filter.type === 3 || filter.type === 4 || filter.type === 8">
            <angular2-multiselect
              fixSearchBug
              fixAccents
              class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width panel-always-at-top"
              [ngClass]="'c-token-ceoe'"
              [name]="filter.attr"
              [formControlName]="filter.attr + 'Dropdown'"
              [data]="this[filter.attr + 'List']"
              [(ngModel)]="this['selected' + filter.attr + 'Items']"
              [settings]="this['dropdown' + filter.attr + 'Settings']"
            >
              <!-- <c-badge>
                <ng-template let-item="item">
                  <label
                    [ngStyle]="
                      item.hasOwnProperty('color') && item.color
                        ? { padding: '4px 8px', 'border-radius': '4px', 'background-color': item.color + '50' }
                        : {}
                    "
                  >
                    {{ item.itemName }}
                  </label>
                </ng-template>
              </c-badge> -->
              <!-- <c-item>
                <ng-template let-item="item">
                  <label
                    [ngStyle]="
                      item.hasOwnProperty('color') && item.color
                        ? { padding: '4px 8px', 'border-radius': '4px', 'background-color': item.color + '50' }
                        : {}
                    "
                  >
                    {{ item.itemName }}
                  </label>
                </ng-template>
              </c-item> -->
            </angular2-multiselect>
          </span>
        </span>
      </div>
    </span>

    <!-- <div class="section">
      <mat-accordion>
        <mat-expansion-panel style="overflow: visible">
          <mat-expansion-panel-header>
            <mat-panel-title>Filtros avanzados</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="section" *ngFor="let filter of filters.filters; let i = index" [formArrayName]="filter.attr">
            <span *ngIf="i >= filters.filters.length - 2">
              <span *ngIf="filter.attr !== 'finalidad'">
                <p class="title">{{ filter.seccion }}</p>
                <div
                  class="checkboxes"
                  *ngFor="let control of filtersForm.controls[filter.attr].controls"
                  [formGroup]="castToFormGroup(control)"
                >
                  <input type="checkbox" formControlName="checked" />
                  <mat-label
                    (click)="
                      castToFormGroup(control).controls.checked.setValue(
                        !castToFormGroup(control).controls.checked.value
                      )
                    "
                    >{{ control.get('label')?.value }}</mat-label
                  >
                </div>
              </span>
            </span>
          </div>

          <span class="section section-dropdowns">
            <div *ngFor="let filter of filters.filters; let i = index">
              <span *ngIf="i >= filters.filters.length - 2">
                <span *ngIf="filter.attr === 'finalidad'">
                  <angular2-multiselect
                    fixSearchBug fixAccents
                    class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
                    name="finalidad"
                    [formControlName]="filter.attr + 'Dropdown'"
                    [data]="dropdownFinalidadList"
                    [(ngModel)]="selectedFinalidadItems"
                    [settings]="dropdownFinalidadSettings"
                  ></angular2-multiselect>
                </span>
              </span>
            </div>
          </span>

          <mat-form-field class="section">
            <mat-label>Fecha de publicación</mat-label>
            <mat-date-range-input [formGroup]="filtersPublishmentDateRangeForm" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="dd/mm/aaaa" />
              <input matEndDate formControlName="end" placeholder="dd/mm/aaaa" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <mat-form-field class="section">
            <mat-label>Fecha de finalización del periodo de solicitud</mat-label>
            <mat-date-range-input [formGroup]="filtersFinalizationDateRangeForm" [rangePicker]="picker2">
              <input matStartDate formControlName="start" placeholder="dd/mm/aaaa" />
              <input matEndDate formControlName="end" placeholder="dd/mm/aaaa" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>
          </mat-form-field>
        </mat-expansion-panel>
      </mat-accordion>
    </div> -->
  </span>
  <button mat-flat-button color="primary" [disabled]="loading || applyingFilters" (click)="applyFilters()">
    <mat-spinner [diameter]="20" *ngIf="loading || applyingFilters"></mat-spinner>
    Aplicar filtros
  </button>
  <button
    mat-stroked-button
    style="width: fit-content; padding: 0 6px; font-size: 15px; font-weight: 500"
    (click)="toggleMenuFilters()"
  >
    <mat-icon style="font-size: 20px; width: 20px; height: 20px">keyboard_double_arrow_right</mat-icon>
    Ocultar menú de filtros
  </button>
</form>
