import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DraftContent } from '@app/modules/documents/store/models/draft.state.model';

@Component({
  selector: 'app-doc-detail',
  templateUrl: './doc-detail.component.html',
  styleUrls: ['./doc-detail.component.less'],
})
export class DocDetailComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { docID: number; s3_file_url: string; draftContent: DraftContent }
  ) {}

  ngOnInit(): void {}
}
