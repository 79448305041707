import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import _moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import {
  CollectiveAgreement,
  CollectiveAgreementsGetRequest,
  CollectiveAgreementsGetResponse,
} from '../models/collective-agreement';

@Injectable({
  providedIn: 'root',
})
export class CollectiveAgreementsService {
  constructor(private _http: HttpClient) {}

  getCollectiveAgreements(request: CollectiveAgreementsGetRequest): Observable<CollectiveAgreementsGetResponse> {
    return this._http.post<CollectiveAgreementsGetResponse>(`${environment.collectiveAgreementsSearch}`, request);
  }

  getCollectiveAgreement(id: string): Observable<CollectiveAgreement[] | CollectiveAgreement> {
    return this._http.get<CollectiveAgreement[] | CollectiveAgreement>(
      `${environment.collectiveAgreementsSearch}${id}`
    );
  }

  getFilters(): Observable<
    {
      id: number;
      seccion: string;
      attr: string;
      items: {
        label: string;
        value: number;
      }[];
    }[]
  > {
    return this._http.get<
      {
        id: number;
        seccion: string;
        attr: string;
        items: {
          label: string;
          value: number;
        }[];
      }[]
    >(`${environment.collectiveAgreementsFilters}`);
  }
}
