import { Component, OnInit } from '@angular/core';
import { KC_REALM_BRAINY, KC_REALM_CEOE, getRealm } from '@app/init/keycloak-init.factory';

@Component({
  selector: 'app-subventions-support',
  templateUrl: './subventions-support.component.html',
  styleUrls: ['./subventions-support.component.scss'],
})
export class SubventionsSupportComponent implements OnInit {
  getRealm = getRealm;
  KC_REALM_CEOE = KC_REALM_CEOE;
  KC_REALM_BRAINY = KC_REALM_BRAINY;

  constructor() {}

  ngOnInit(): void {}
}
