export class BrainyDoc {
  id_source: string;
  docId: string;
  date_collected: string;
  date_refresh: string;
  date_published: string;
  date_src_updated: string;
  date_db_updated: string;
  last_status_code: number;
  last_status_message: string;
  source: string;
  sourceName: string;
  source_domain: string;
  titleParsed: string;
  bodyParsed: string;
  url: string;
  lang: string;
  docType: string;
  spiderId: number;
  docTopicsRegion: number[];
  docTopics: number[];
  categories: [];
  entities: [];
  is_echo: boolean;
  location: {};
  s3_file_url: string;
  interesting: boolean;
  group?: string;
  dateParsed?: string;
  abstract?: string;
}
