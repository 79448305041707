import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { UtilsService } from '@app/services/utils.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import moment from 'moment';
import { Subvention, SubventionsFilters } from '../../models/subvention';
import { GetFilters } from '../../store/subventions/subventions.actions';
import { SubventionsStateService } from '../../store/subventions/subventions.service';
import { GetSubventionsAlerts, PostAlert } from '../../store/subventions/subventions.actions';
import { SubventionsAlertsEditDialogComponent } from './subventions-alerts-edit-dialog/subventions-alerts-edit-dialog.component';

@Component({
  selector: 'app-subventions-alerts',
  templateUrl: './subventions-alerts.component.html',
  styleUrls: ['./subventions-alerts.component.scss'],
})
export class SubventionsAlertsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = ['subventions'];
  pageIndex = 0;
  loading = true;
  loadingFilters = true;

  filters?: SubventionsFilters;
  filtersForm: FormGroup;
  filtersPublishmentDateRangeForm: FormGroup;
  filtersFinalizationDateRangeForm: FormGroup;

  dropdownComunidadesList = [];
  selectedComunidadesItems = [];
  dropdownComunidadesSettings = {
    text: 'Comunidades',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  dropdownSectorEconomicoList = [];
  selectedSectorEconomicoItems = [];
  dropdownSectorEconomicoSettings = {
    text: 'Sector económico',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  dropdownProvinciasList = [];
  selectedProvinciasItems = [];
  dropdownProvinciasSettings = {
    text: 'Provincias',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  constructor(
    public subventionsStateService: SubventionsStateService,
    private actions: Actions,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private utilsService: UtilsService
  ) {
    this.actions.pipe(ofActionSuccessful(GetSubventionsAlerts)).subscribe(() => {
      this.loading = false;

      setTimeout(() => {
        if (this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.length = this.subventionsStateService.alerts.total;
        }
      }, 0);
    });

    this.filtersForm = this.fb.group({
      titulo: ['', Validators.required],
      // quien: this.fb.array([]),
      // accion: this.fb.array([]),
      presupuesto: this.fb.array([]),
      fondos: this.fb.array([]),
      instrumento_ayuda: this.fb.array([]),
      comunidades: this.fb.array([]),
      provincias: this.fb.array([]),
      sector_economico: this.fb.array([]),
      organismo_convocante: this.fb.array([]),
      estado: this.fb.array([]),
      beneficiarios: this.fb.array([]),
      finalidad: this.fb.array([]),

      comunidadesDropdown: [[]],
      provinciasDropdown: [[]],
      sector_economicoDropdown: [[]],
    });

    this.selectedComunidadesItems = this.filtersForm.controls.comunidadesDropdown.value;
    this.selectedProvinciasItems = this.filtersForm.controls.provinciasDropdown.value;
    this.selectedSectorEconomicoItems = this.filtersForm.controls.sector_economicoDropdown.value;

    this.filtersPublishmentDateRangeForm = this.fb.group({
      start: [],
      end: [],
    });

    this.filtersFinalizationDateRangeForm = this.fb.group({
      start: [],
      end: [],
    });

    this.actions.pipe(ofActionSuccessful(GetFilters)).subscribe(() => {
      this.loading = false;
    });

    setTimeout(() => {
      if (this.subventionsStateService.filters.filters) {
        this.setData();
        this.filters = this.subventionsStateService.filters;
        this.populateDropdowns();
        this.loadingFilters = false;
      }

      this.actions.pipe(ofActionSuccessful(GetFilters)).subscribe(() => {
        setTimeout(() => {
          this.setData();
          this.filters = this.subventionsStateService.filters;
          this.populateDropdowns();
          this.loadingFilters = false;
        });
      });
    });
  }

  ngOnInit(): void {
    this.pageIndex = 0;
    this.subventionsStateService.getSubventionsAlerts({
      abierta: true,
      page: this.pageIndex + 1,
      size: 30,
    });
  }

  populateDropdowns(): void {
    this.dropdownComunidadesList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'comunidades')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });

    this.dropdownProvinciasList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'provincias')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });

    this.dropdownSectorEconomicoList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'sector_economico')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
  }

  edit(row: Subvention): void {
    this.dialog.open(SubventionsAlertsEditDialogComponent, {
      width: '100%',
      maxWidth: '1000px',
      data: row,
    });
  }

  delete(row: Subvention) {
    this.subventionsStateService.deleteAlert(row.id);
  }

  pageEvent(evt: PageEvent): void {
    this.loading = true;
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.subventionsStateService.getSubventionsAlerts({
      abierta: true,
      page: this.pageIndex + 1,
      size: 30,
    });
  }

  saveAlert(): void {
    this.filtersForm.markAllAsTouched();

    if (
      this.filtersForm.valid &&
      this.filtersPublishmentDateRangeForm.valid &&
      this.filtersFinalizationDateRangeForm.valid
    ) {
      this.loading = true;

      const request = {
        titulo: this.filtersForm.controls.titulo.value,

        // quien: (this.filtersForm.controls.quien as FormArray)
        //   .getRawValue()
        //   .filter((v) => v.checked)
        //   .map((v) => String(v.value))
        //   .reduce((acc, val) => acc.concat(val), []),

        // accion: (this.filtersForm.controls.accion as FormArray)
        //   .getRawValue()
        //   .filter((v) => v.checked)
        //   .map((v) => String(v.value))
        //   .reduce((acc, val) => acc.concat(val), []),

        presupuesto: (this.filtersForm.controls.presupuesto as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => v.value)
          .reduce((acc, val) => acc.concat(val), []),

        fondos: (this.filtersForm.controls.fondos as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => v.value)
          .reduce((acc, val) => acc.concat(val), []),

        instrumento_ayuda: (this.filtersForm.controls.instrumento_ayuda as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => String(v.value))
          .reduce((acc, val) => acc.concat(val), []),

        comunidades: this.filtersForm.controls.comunidadesDropdown.value
          .map((v) => String(v.id))
          .reduce((acc, val) => acc.concat(val), []),

        provincias: this.filtersForm.controls.provinciasDropdown.value
          .map((v) => String(v.id))
          .reduce((acc, val) => acc.concat(val), []),

        sector_economico: this.filtersForm.controls.sector_economicoDropdown.value
          .map((v) => String(v.id))
          .reduce((acc, val) => acc.concat(val), []),

        organismo_convocante: (this.filtersForm.controls.organismo_convocante as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => String(v.value))
          .reduce((acc, val) => acc.concat(val), []),

        estado: (this.filtersForm.controls.estado as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => String(v.value))
          .reduce((acc, val) => acc.concat(val), []),

        finalidad: (this.filtersForm.controls.finalidad as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => String(v.value))
          .reduce((acc, val) => acc.concat(val), []),

        beneficiarios: (this.filtersForm.controls.beneficiarios as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => String(v.value))
          .reduce((acc, val) => acc.concat(val), []),

        fecha_publicacion_desde: moment(this.filtersPublishmentDateRangeForm.controls.start.value)
          .format('DD/MM/YYYY')
          .toString(),
        fecha_publicacion_hasta: moment(this.filtersPublishmentDateRangeForm.controls.end.value)
          .format('DD/MM/YYYY')
          .toString(),

        fecha_finalizacion_desde: moment(this.filtersFinalizationDateRangeForm.controls.start.value)
          .format('DD/MM/YYYY')
          .toString(),
        fecha_finalizacion_hasta: moment(this.filtersFinalizationDateRangeForm.controls.end.value)
          .format('DD/MM/YYYY')
          .toString(),
      };

      const subscription$ = this.actions.pipe(ofActionSuccessful(PostAlert)).subscribe(() => {
        subscription$.unsubscribe();

        this.pageIndex = 0;
        this.subventionsStateService.getSubventionsAlerts({
          abierta: true,
          page: this.pageIndex + 1,
          size: 30,
        });

        this.resetAlertform();

        this.loading = false;

        setTimeout(() => {
          this.filtersForm.markAsUntouched();
        }, 0);
      });

      this.subventionsStateService.postAlert(request as any);
    }
  }

  search(row: any) {
    this.subventionsStateService.updateSubventionsMenuFilters({
      ...this.subventionsStateService.filters,
      selectedFilters: row.busqueda as any,
    });

    setTimeout(() => {
      this.router.navigate(['subventions', 'search'], { queryParams: { setData: true } });
    }, 250);
  }

  setData(): void {
    this.subventionsStateService.filters.filters.forEach((filter) => {
      const pushItems = (this.filtersForm.controls[filter.attr] as FormArray).length === 0;
      filter.items.forEach((item, i) => {
        if (pushItems) {
          (this.filtersForm.controls[filter.attr] as FormArray).controls.push(
            this.fb.group({
              checked: false,
              label: item.label,
              value: Array.isArray(item.value) ? [item.value] : item.value,
            })
          );
        } else {
          (this.filtersForm.controls[filter.attr] as FormArray).at(i).patchValue({
            checked: false,
            label: item.label,
            value: Array.isArray(item.value) ? [item.value] : item.value,
          });
        }
      });
    });
  }

  resetAlertform(): void {
    this.filtersForm.patchValue({
      titulo: '',
      comunidadesDropdown: [],
      provinciasDropdown: [],
      sector_economicoDropdown: [],
    });

    // this.filtersForm.controls.quien = this.fb.array([]);
    // this.filtersForm.controls.accion = this.fb.array([]);
    this.filtersForm.controls.presupuesto = this.fb.array([]);
    this.filtersForm.controls.fondos = this.fb.array([]);
    this.filtersForm.controls.instrumento_ayuda = this.fb.array([]);
    this.filtersForm.controls.comunidades = this.fb.array([]);
    this.filtersForm.controls.provincias = this.fb.array([]);
    this.filtersForm.controls.sector_economico = this.fb.array([]);
    this.filtersForm.controls.organismo_convocante = this.fb.array([]);
    this.filtersForm.controls.estado = this.fb.array([]);
    this.filtersForm.controls.beneficiarios = this.fb.array([]);
    this.filtersForm.controls.finalidad = this.fb.array([]);

    this.filtersPublishmentDateRangeForm.patchValue({
      start: undefined,
      end: undefined,
    });

    this.filtersFinalizationDateRangeForm.patchValue({
      start: undefined,
      end: undefined,
    });

    setTimeout(() => {
      this.setData();
    }, 100);
  }

  getLabelById(identifier: string, id: any): string {
    if (!this.subventionsStateService.filters || !this.subventionsStateService.filters.filters) return '';

    const item = (this.subventionsStateService.filters.filters as any).find((f) => f.attr === identifier);

    if (!item) return '';

    if (item.items[0].value[0].from || item.items[0].value[0].to) {
      return item.items.find((i) => i.value[0].from === id.from || i.value[0].to === id.to).label;
    } else {
      return item.items.find((i) => i.value.toString() == this.arraySplitChildItems(id.toString()).toString()).label;
    }
  }

  castToFormGroup(ac: AbstractControl): FormGroup {
    return ac as FormGroup;
  }

  arraySplitChildItems(item: string): string[] {
    const output: string[] = [];

    if (item.includes(',')) {
      const childItems = item.split(',');
      output.push(...childItems);
    } else {
      output.push(item);
    }

    return output;
  }
}
