import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import _moment from 'moment';
import { Observable, Subject, of, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError, delay } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { saveAs } from 'file-saver';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class RegulatoryDraftsService {
  socket: Socket;
  dataReceived: Subject<any> = new Subject<any>();

  constructor(
    private _http: HttpClient,
    private _snackbarStateService: SnackbarStateService,
    private keycloakService: KeycloakService
  ) {}

  getRegulatoryDrafts(request: { page: number; size: number }): Observable<any> {
    return this._http.get<any>(`${environment.regulatoryDrafts}?page=${request.page}&size=${request.size}`);
    // return of(this.getFakeRegulatoryDrafts()).pipe(delay(1000));
  }

  getRegulatoryDraft(request: { id: number }): Observable<any> {
    // return this._http.get<any>(
    //   `https://test.reports.brainyinteligencia.com/regulatory/drafts/${request.id}`
    // );
    return of(this.getFakeRegulatoryDrafts().items[0]).pipe(delay(1000));
  }

  postRegulatoryDraft(request: { date: string; title: string; categorias: number[] }): Observable<any> {
    return this._http.post<any>(`${environment.regulatoryDrafts}`, request);
    // return of({ id: 1 }).pipe(delay(1000));
  }

  putRegulatoryDraft(request: { id: number; date: string; title: string; categorias: number[] }): Observable<any> {
    return this._http.put<any>(`${environment.regulatoryDrafts}/${request.id}`, request);
    // return of({ id: 1 }).pipe(delay(1000));
  }

  deleteRegulatoryDraft(request: { id: number }): Observable<any> {
    return this._http.delete<any>(`${environment.regulatoryDrafts}/${request.id}`);
  }

  // downloadRegulatoryDraft(request: { id: number; date: string }): Observable<Blob> {
  //   return this._http.get<Blob>(`${environment.regulatoryDrafts}/${request.id}/doc?date=${request.date}`, {
  //     responseType: 'blob' as 'json',
  //   });
  // }

  downloadRegulatoryDraft(request: {
    id: number;
    date: string;
    draft_color: string;
    draft_font: string;
  }): Observable<{ uuid: string }> {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();

    return this._http.get<{ uuid: string }>(
      `${environment.regulatoryDrafts}/${request.id}/doc?date=${request.date}&draft_color=${encodeURIComponent(
        request.draft_color
      )}&draft_font=${request.draft_font}&refresh_token=${keycloakInstance.refreshToken}`
    );
  }

  getRegulatoryDraftsFilterCCAA(): Observable<any> {
    return this._http.get<any>(`${environment.regulatoryDrafts}/filtros/ccaa`);
  }

  getRegulatoryDraftsFilterCommissions(): Observable<any> {
    return this._http.get<any>(`${environment.regulatoryDrafts}/filtros/commissions`);
  }

  getRegulatoryDraftsFilterContexts(): Observable<any> {
    return this._http.get<any>(`${environment.regulatoryDrafts}/filtros/contexts`);
  }

  getRegulatoryDraftsFilterCounselings(): Observable<any> {
    return this._http.get<any>(`${environment.regulatoryDrafts}/filtros/counselings`);
  }

  getRegulatoryDraftsFilterMinisterios(): Observable<any> {
    return this._http.get<any>(`${environment.regulatoryDrafts}/filtros/ministerios`).pipe(
      catchError((error) => {
        if (error.status === 404) {
          console.error('Not found:', error);
          return of({});
        }
        return throwError(error);
      })
    );
  }

  connect(url: string): void {
    this.socket = io(url, {
      transports: ['websocket'],
    });
  }

  sendData(eventName: string, data: any): void {
    this.socket.emit(eventName, data);

    if (!this.socket.hasListeners(eventName)) {
      this.socket.on(eventName, (data) => {
        // console.log('Received message:', event.data);
        this.dataReceived.next(data);

        switch (eventName) {
          case 'pickup_result':
            const dataMapped: {
              elapsed: number;
              status: string;
              status_code: number;
              url: string;
            } = data;

            switch (dataMapped.status_code) {
              case 200:
                // this.downloadFile(dataMapped.url);
                break;
              default:
                this._snackbarStateService.show('No hay datos disponibles para la fecha seleccionada', 5000, 'cerrar');
                break;
            }
            break;
        }
      });
    }
  }

  closeConnection(): void {
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.disconnect();
    }
  }

  downloadFile(url: string): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', `draft.docx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  getFakeRegulatoryDrafts(): {
    page: number;
    total: number;
    size: number;
    items: {
      id: number;
      title: string;
      created_at: string;
      sections: {
        title: string;
        documents: {
          id: number;
          id_source: number;
          name: string;
          extract: string;
          created_at: string;
          docType: string;
          text: string;
          _favorite: boolean;
          categories: {
            value: number;
            label: string;
          }[];
        }[];
      }[];
    }[];
  } {
    return {
      page: 1,
      total: 3,
      size: 30,
      items: [
        {
          id: 1,
          title: 'Borrador 23/10/2023',
          created_at: '23/10/2023',
          sections: [
            {
              title: 'Boletín Oficial del Estado',
              documents: [
                {
                  id: 1,
                  id_source: 1,
                  name: '1 Un detingut per empentar l’alcaldessa de Ripoll i agredir al seu company',
                  extract: 'Gobierno de Aragón - Prensa',
                  created_at: '23/10/2023',
                  docType: 'html',
                  text: 'Un jove va ser detingut divendres al vespre per la Policia Local de Ripoll, després d’haver-se barallat amb el company i pare dels fills de l’alcaldessa del municipi, Sílvia Orriols, i d’empentar-la a ella. L’incident es va produir pels volts de les vuit de la tarda, quan Orriols i David Subirana tornaven amb el seu gos cap a casa seva des del centre del poble. En passar per la plaça Gran, un jove que estava amb uns grups de joves que sortien del carrer de la Fruita, va interpel·lar a crits a la parella amb insults com ara “feixistes”. Després que Subirana s’hi encarés, va començar una baralla amb empentes i cops de puny, on va acabar per terra amb la samarreta esparracada, una contusió sota un ull, i lesions a l’esquena. Sílvia Orriols va interposar-s’hi i també va rebre alguna empenta. Aquesta circumstància va afavorir la detenció del jove, acusat d’“atemptat contra l’autoritat”, un cop la policia local va arribar al lloc dels incidents, en un dels moments de major trànsit de persones de tota la setmana.',
                  _favorite: true,
                  categories: [
                    {
                      value: 1,
                      label: 'Test label 1',
                    },
                    {
                      value: 2,
                      label: 'Test label 2',
                    },
                  ],
                },
                {
                  id: 2,
                  id_source: 2,
                  name: '2 Un detingut per empentar l’alcaldessa de Ripoll i agredir al seu company',
                  extract: 'Gobierno de Aragón - Prensa',
                  created_at: '23/10/2023',
                  docType: 'html',
                  text: 'Un jove va ser detingut divendres al vespre per la Policia Local de Ripoll, després d’haver-se barallat amb el company i pare dels fills de l’alcaldessa del municipi, Sílvia Orriols, i d’empentar-la a ella. L’incident es va produir pels volts de les vuit de la tarda, quan Orriols i David Subirana tornaven amb el seu gos cap a casa seva des del centre del poble. En passar per la plaça Gran, un jove que estava amb uns grups de joves que sortien del carrer de la Fruita, va interpel·lar a crits a la parella amb insults com ara “feixistes”. Després que Subirana s’hi encarés, va començar una baralla amb empentes i cops de puny, on va acabar per terra amb la samarreta esparracada, una contusió sota un ull, i lesions a l’esquena. Sílvia Orriols va interposar-s’hi i també va rebre alguna empenta. Aquesta circumstància va afavorir la detenció del jove, acusat d’“atemptat contra l’autoritat”, un cop la policia local va arribar al lloc dels incidents, en un dels moments de major trànsit de persones de tota la setmana.',
                  _favorite: false,
                  categories: [],
                },
                {
                  id: 3,
                  id_source: 3,
                  name: '3 Un detingut per empentar l’alcaldessa de Ripoll i agredir al seu company',
                  extract: 'Gobierno de Aragón - Prensa',
                  created_at: '23/10/2023',
                  docType: 'pdf',
                  text: 'Un jove va ser detingut divendres al vespre per la Policia Local de Ripoll, després d’haver-se barallat amb el company i pare dels fills de l’alcaldessa del municipi, Sílvia Orriols, i d’empentar-la a ella. L’incident es va produir pels volts de les vuit de la tarda, quan Orriols i David Subirana tornaven amb el seu gos cap a casa seva des del centre del poble. En passar per la plaça Gran, un jove que estava amb uns grups de joves que sortien del carrer de la Fruita, va interpel·lar a crits a la parella amb insults com ara “feixistes”. Després que Subirana s’hi encarés, va començar una baralla amb empentes i cops de puny, on va acabar per terra amb la samarreta esparracada, una contusió sota un ull, i lesions a l’esquena. Sílvia Orriols va interposar-s’hi i també va rebre alguna empenta. Aquesta circumstància va afavorir la detenció del jove, acusat d’“atemptat contra l’autoritat”, un cop la policia local va arribar al lloc dels incidents, en un dels moments de major trànsit de persones de tota la setmana.',
                  _favorite: false,
                  categories: [],
                },
              ],
            },
            {
              title: 'Congreso',
              documents: [
                {
                  id: 4,
                  id_source: 4,
                  name: '1 Un detingut per empentar l’alcaldessa de Ripoll i agredir al seu company',
                  extract: 'Gobierno de Aragón - Prensa',
                  created_at: '23/10/2023',
                  docType: 'html',
                  text: 'Un jove va ser detingut divendres al vespre per la Policia Local de Ripoll, després d’haver-se barallat amb el company i pare dels fills de l’alcaldessa del municipi, Sílvia Orriols, i d’empentar-la a ella. L’incident es va produir pels volts de les vuit de la tarda, quan Orriols i David Subirana tornaven amb el seu gos cap a casa seva des del centre del poble. En passar per la plaça Gran, un jove que estava amb uns grups de joves que sortien del carrer de la Fruita, va interpel·lar a crits a la parella amb insults com ara “feixistes”. Després que Subirana s’hi encarés, va començar una baralla amb empentes i cops de puny, on va acabar per terra amb la samarreta esparracada, una contusió sota un ull, i lesions a l’esquena. Sílvia Orriols va interposar-s’hi i també va rebre alguna empenta. Aquesta circumstància va afavorir la detenció del jove, acusat d’“atemptat contra l’autoritat”, un cop la policia local va arribar al lloc dels incidents, en un dels moments de major trànsit de persones de tota la setmana.',
                  _favorite: false,
                  categories: [],
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
