import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AppStateService } from '@app/state/app/app.service';
import { SpiderState } from './spider.state';
import { GetImportantSpiders, GetLatestSpiders } from './spider.actions';
import { Spider } from '../models/spider.state.model';
import { SpiderService } from '@app/services/spiders.service';

@Injectable({ providedIn: 'root' })
export class SpiderStateService {
  @Select(SpiderState.important)
  public important$: Observable<Spider[]>;

  @Select(SpiderState.latest)
  public latest$: Observable<Spider[]>;

  @SelectSnapshot(SpiderState.important)
  public important: Spider[];

  @SelectSnapshot(SpiderState.latest)
  public latest: Spider[];

  constructor(
    public appStateService: AppStateService,
    private _snackbarStateService: SnackbarStateService,
    private _spiderService: SpiderService
  ) {}

  @Dispatch()
  public getImportantSpiders = (payload: { spiders: number[] }) => {
    return this._spiderService.getLatestSpidersById(payload).pipe(
      map((response: any) => new GetImportantSpiders(response.data)),
      catchError((error) => {
        let errorMsg = 'Problema al obtener las fuentes destacadas, inténtelo más tarde.';
        this._snackbarStateService.show(errorMsg, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getLatestSpiders = () => {
    return this._spiderService.getLatestSpiders().pipe(
      map((response: any) => new GetLatestSpiders(response.data)),
      catchError((error) => {
        let errorMsg = 'Problema al obtener las últimas fuentes, inténtelo más tarde.';
        this._snackbarStateService.show(errorMsg, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };
}
