export class ColorPalette {
  static color_palettes = [
    ['#fee8c8', '#fdbb84', '#e34a33'],
    ['#ece7f2', '#a6bddb', '#2b8cbe'],
    ['#f7fcb9', '#addd8e', '#31a354'],
    ['#efedf5', '#bcbddc', '#756bb1'],
    ['#e7e1ef', '#c994c7', '#dd1c77'],
  ];

  public static palette(num: number) {
    if (num >= ColorPalette.color_palettes.length) {
      // Gray palette
      return ['#999999', '#777777', '#555555'];
    } else return ColorPalette.color_palettes[num];
  }
}
