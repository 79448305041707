import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, Injector } from '@angular/core';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { AppDataService } from '@app/services/app-data.service';
import { BaseChart } from '@modules/br-widgets/components/charts/base/base.component';
import rainbowColorsJson from '@assets/rainbow-colors.json';
import { UtilsService } from '@app/services/utils.service';

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css'],
})
export class PiechartComponent extends BaseChart {
  options: any;
  rainbowColors: string[] = this.utilsService.extendArray(rainbowColorsJson, 10);

  constructor(
    public injector: Injector,
    public changeDetector: ChangeDetectorRef,
    private authStateService: AuthStateService,
    private utilsService: UtilsService
  ) {
    super(injector, changeDetector);
  }

  format(config, response) {
    let res = [];

    if (
      !response ||
      !response.aggregations ||
      !response.aggregations.aggr_spider_id ||
      !response.aggregations.aggr_spider_id.buckets ||
      !config ||
      !config.aggregations ||
      !config.aggregations.length
    ) {
      return res;
    }

    res = response.aggregations.aggr_spider_id.buckets.map((item) => {
      return {
        name: this.injector.get(AppDataService).map(config.aggregations[0].map_name_with, item.key),
        value: item.doc_count,
      };
    });

    return res;
  }

  refresh(config, response) {
    let aux = {};
    this.format(config, response).forEach((item) => {
      if (!aux[item.name]) aux[item.name] = 0;
      aux[item.name] += item.value;
    });
    this.results = [];
    for (let key in aux) this.results.push({ name: key, value: aux[key] });

    this.results = this.results.sort((a, b) => (a.value > b.value ? -1 : 1));

    let otros = { name: 'Otros', value: 0 };
    this.results = this.results.map((element, index) => {
      if (index < 6) return element;
      else otros.value = otros.value + element.value;
    });

    this.results.push(otros);

    this.options['tooltip'] = {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    };

    this.options['legend'] = {
      orient: 'vertical',
      left: 'right',
      top: '40%',
      data: this.results.map((a: any) => {
        if (a) return a.name;
      }),
    };

    const userEchartsColors =
      this.authStateService.userConfig.interface_config.company.color_palette?.echarts?.split(',') ??
      this.rainbowColors;

    this.options['series'] = [
      {
        name: '',
        type: 'pie',
        radius: '55%',
        center: ['27%', '60%'],
        data: this.results.map((r, i) => {
          return {
            ...r,
            itemStyle: {
              color: i < userEchartsColors.length ? userEchartsColors[i] : this.rainbowColors[i],
            },
          };
        }),
        label: {
          show: false,
          position: 'center',
        },
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ];

    this.echartsInstance.setOption(this.options);
    this.isLoading = false;
    this.seriesLoaded.emit('pie');
  }

  loadData() {
    let config = Object.assign({}, this.wconfig.config['query']);
    config['aggregations'] = this.wconfig.config['aggregations'];
    // remove this
    delete config['dateParsed_start'];
    delete config['dateParsed_end'];

    this.update(config, (config, response) => {
      return this.refresh(config, response);
    });
  }

  onChartInit(e) {
    this.echartsInstance = e;
    this.loadData();
  }
}
