import { Injectable } from '@angular/core';
import { TextanalysisService } from './textanalysis.service';
@Injectable({
  providedIn: 'root',
})
export class StrfuzzycompService {
  constructor(private taService: TextanalysisService) {}

  jw_ssim(s1: string, s2: string) {
    if (!s1 || !s2 || s1.trim() == '' || s2.trim() == '') return 0;
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();
    let arr_s1: string[] = s1.trim().split(' '),
      arr_s2: string[] = s2.trim().split(' '),
      s: number = 0;

    for (let pos in arr_s1) {
      if (arr_s1[pos].trim() != '') {
        let arr: number[] = [],
          i = 0;
        for (let pos2 in arr_s2) {
          if (arr_s2[pos2].trim() != '')
            arr[i++] =
              this.taService.jaro_winkler(arr_s1[pos], arr_s2[pos2]) /
              (1 + this.taService.levenshtein(arr_s1[pos], arr_s2[pos2]));
        }
        s += Math.max(...arr);
      }
    }
    return Math.min(1, s / Math.max(s1.length, s2.length));
  }

  jw_ssim2(s1: string, s2: string) {
    if (!s1 || !s2 || s1.trim() == '' || s2.trim() == '') return 0;

    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    let arr_s1: string[] = s1.trim().split(' '),
      arr_s2: string[] = s2.trim().split(' '),
      s = 0;

    for (let pos in arr_s1) {
      if (arr_s1[pos].trim() != '') {
        let arr: number[] = [],
          i = 0;
        for (let pos2 in arr_s2) {
          if (arr_s2[pos2].trim() != '') arr[i++] = this.taService.jaro_winkler(arr_s1[pos], arr_s2[pos2]);
        }
        s += Math.max(...arr);
      }
    }
    return s / Math.max(s1.length, s2.length);
  }

  le_ssim(s1: string, s2: string) {
    if (s1.trim() == '' || s2.trim() == '') return 0;

    let arr_s1 = s1.trim().split(' '),
      arr_s2 = s2.trim().split(' '),
      s = 0;

    for (let pos in arr_s1) {
      if (arr_s1[pos].trim() != '') {
        let arr: number[] = [],
          i = 0;
        for (let pos2 in arr_s2) {
          if (arr_s2[pos2].trim() != '')
            arr[i++] =
              1 - this.taService.levenshtein(arr_s1[pos], arr_s2[pos2]) / Math.max(arr_s1.length, arr_s2.length);
        }
        s += Math.max(...arr);
      }
    }
    return Math.min(1, s / Math.max(s1.length, s2.length));
  }
}
