import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppStatusService } from '@app/services/app-status.service';
import { AddCategoriesTopics } from '@app/state/app/app.actions';
import { AppStateService } from '@app/state/app/app.service';
import rainbowColorsJson from '@assets/rainbow-colors.json';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sector-reports-search',
  templateUrl: './sector-reports-search.component.html',
  styleUrls: ['./sector-reports-search.component.scss'],
})
export class SectorReportsSearchComponent implements OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  rainbowColors: string[] = rainbowColorsJson;
  categoriesSelected = [];
  loading = true;

  constructor(
    public appService: AppStateService,
    private appStateService: AppStateService,
    private appStatus: AppStatusService,
    private route: ActivatedRoute,
    private actions: Actions
  ) {
    this.appService.hideDates();

    this.route.params.subscribe((params) => {
      this.loading = true;
      this.getCategories(params.groupTopicId);

      setTimeout(() => {
        this.handleAppOverview(false);
        this.handleMainSearchContainer(false);
      }, 0);
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(AddCategoriesTopics)).subscribe(() => {
      this.categoriesSelected = appService.categories_topics.map((ct) => ct.id);
      this.search();
    });

    this.appStatus.sector_reports_search_launched.subscribe((data) => {
      this.categoriesSelected = data.categories;
      this.search(data.text_search, { start: data.dateParsed_start, end: data.dateParsed_end });
    });

    this.appStatus.search_finished.subscribe(() => {
      setTimeout(() => {
        this.handleAppOverview(true);
        this.loading = false;
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.handleMainSearchContainer(true);
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getCategories(id: string): void {
    this.appService.addStateCategoriesTopics(parseInt(id));
  }

  handleAppOverview(show: boolean): void {
    const container = document.getElementById('sector-reports-search-container');

    const appOverviewElement: HTMLDivElement = container.querySelector('app-overview');

    appOverviewElement.style.opacity = show ? '1' : '0';
  }

  handleMainSearchContainer(show: boolean): void {
    const searchContainerElement: HTMLDivElement = document.querySelector('main-search .search-container');

    searchContainerElement.style.opacity = show ? '1' : '0';
    searchContainerElement.style.pointerEvents = show ? 'auto' : 'none';
  }

  search(text_search = '', dates?: { start: Date; end: Date }): void {
    let request = {
      text_search,
      start: dates?.start,
      end: dates?.end,
      docTopics: this.appService.categories_topics
        .filter((c) => this.categoriesSelected.includes(c.id))
        .map((c) => {
          return {
            id: c.id,
            itemName: c.name,
          };
        })
        .reduce((acc, val) => acc.concat(val), []),
    };

    setTimeout(() => {
      this.appStatus.search_launch.emit(request);
    }, 550);
  }

  selectCategory(id: number): void {
    const index = this.categoriesSelected.findIndex((c) => c === id);

    if (index === -1) {
      this.categoriesSelected.push(id);
    } else {
      this.categoriesSelected.splice(index, 1);
    }
  }
}
