import { Component, OnDestroy } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SetSearchType } from '@app/state/app/app.actions';
import { AppStateService } from '@app/state/app/app.service';
import { SearchType } from '@app/state/models/app.state.model';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'search-type-dialog',
  templateUrl: './search-type-dialog.component.html',
  styleUrls: ['./search-type-dialog.component.scss'],
})
export class SearchTypeDialogComponent implements OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public appStateService: AppStateService,
    private matBottomSheetRef: MatBottomSheetRef<SearchTypeDialogComponent>,
    private actions: Actions
  ) {
    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(SetSearchType)).subscribe(() => {
      setTimeout(() => {
        this.matBottomSheetRef.dismiss();
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  howToUse(searchType: SearchType): void {
    window.open(`${window.location.origin}/assets/imgs/search-type-illustration.svg`);
  }

  setSearchType(searchType: SearchType): void {
    this.appStateService.setSearchType(searchType);
  }
}
