import { Draft } from '../models/draft.state.model';

export class GetDrafts {
  static readonly type = '[GetDrafts] action';

  constructor(public payload: Draft[]) {}
}

export class GetDraft {
  static readonly type = '[GetDraft] action';

  constructor(public payload: Draft) {}
}

export class SetDraft {
  static readonly type = '[SetDraft] action';

  constructor(public payload: Draft) {}
}

export class AddDraft {
  static readonly type = '[AddDraft] action';

  constructor(public payload: Draft) {}
}

export class EditDraft {
  static readonly type = '[EditDraft] action';

  constructor(public payload: Draft) {}
}

export class DeleteDraft {
  static readonly type = '[DeleteDraft] action';

  constructor(public payload: { dossier_id: number; draft_id: number }) {}
}

export class GenerateDraftPdf {
  static readonly type = '[GenerateDraftPdf] action';

  constructor(public payload: { dossier_id: number; draft_id: number }) {}
}
