import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { UtilsService } from '@app/services/utils.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Alert, AlertPostRequest, Cpv, Province, Tender } from '../../models/tender';
import {
  GetTendersAlerts,
  GetCpvs,
  GetImports,
  GetProvinces,
  GetTenders,
  PostAlert,
} from '../../store/tenders/tenders.actions';
import { TendersStateService } from '../../store/tenders/tenders.service';
import { TendersAlertsEditDialogComponent } from './tenders-alerts-edit-dialog/tenders-alerts-edit-dialog.component';
import { ENTER } from '@angular/cdk/keycodes';
import { OperatorsDialogComponent } from '@app/modules/shared/components/operators-dialog/operators-dialog.component';
import { MatChipInputEvent } from '@angular/material/chips';

export interface Chip {
  name: string;
  type: 'text' | 'operator';
  editing: boolean;
}

@Component({
  selector: 'app-tenders-alerts',
  templateUrl: './tenders-alerts.component.html',
  styleUrls: ['./tenders-alerts.component.scss'],
})
export class TendersAlertsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  readonly separatorKeysCodes: number[] = [ENTER];

  displayedColumns: string[] = ['tenders'];
  pageIndex = 0;
  loading = true;
  selectedSectoresInput = [];
  selectedOrganismoInput = [];

  alertForm: FormGroup;
  objectChipFormArray: FormArray;

  dropdownProvinciasList = [];
  selectedProvinciasItems = [];
  dropdownProvinciasSettings = {
    text: 'Provincias',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  dropdownSectoresList = [];
  selectedSectoresItems = [];
  dropdownSectoresSettings = {
    text: 'Sectores',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  dropdownImportesList = [];
  selectedImportesItems = [];
  dropdownImportesSettings = {
    text: 'Importes',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  constructor(
    public tendersStateService: TendersStateService,
    private actions: Actions,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private utilsService: UtilsService
  ) {
    this.actions.pipe(ofActionSuccessful(GetTendersAlerts)).subscribe(() => {
      this.loading = false;

      setTimeout(() => {
        if (this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.length = this.tendersStateService.alerts.total;
        }
      }, 0);
    });

    this.alertForm = this.fb.group({
      titulo: ['', Validators.required],
      provinces: [[]],
      sections: [[]],
      imports: [[]],
      objeto: [''],
      organismo: [''],
    });

    this.objectChipFormArray = this.fb.array([]);
  }

  ngOnInit(): void {
    this.pageIndex = 0;
    this.tendersStateService.getTendersAlerts({
      page: this.pageIndex + 1,
      size: 30,
    });

    this.populateProvinces();
    this.populateSections();
    this.populateImports();
  }

  edit(row: Alert): void {
    this.dialog.open(TendersAlertsEditDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      data: row,
    });
  }

  delete(row: Tender) {
    this.tendersStateService.deleteAlert(row.id);
  }

  pageEvent(evt: PageEvent): void {
    this.loading = true;
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.tendersStateService.getTendersAlerts({
      page: this.pageIndex + 1,
      size: 30,
    });
  }

  saveAlert(): void {
    this.alertForm.markAllAsTouched();

    if (this.alertForm.valid) {
      this.loading = true;

      const object = [];

      const firstObject = this.objectChipFormArray.at(0) as FormGroup;
      const lastObject = this.objectChipFormArray.at(this.objectChipFormArray.length - 1) as FormGroup;

      if (firstObject && firstObject.controls.type.value === 'operator') {
        this.removeObjeto(0);
      }

      if (lastObject && lastObject.controls.type.value === 'operator') {
        this.removeObjeto(this.objectChipFormArray.length - 1);
      }

      this.objectChipFormArray.controls.forEach((c) => {
        const fg = c as FormGroup;
        object.push({
          name: fg.controls.name.value,
          type: fg.controls.type.value,
        });
      });

      const request: AlertPostRequest = {
        provincias: this.selectedProvinciasItems.map((spi) => String(spi.id)),
        cpvs: this.selectedSectoresInput.map((ssi) => String(ssi)),
        organismo: this.selectedOrganismoInput ? this.selectedOrganismoInput[0] ?? '' : '',
        importes: this.selectedImportesItems.map((sii) => this.tendersStateService.imports[sii.id]),
        objeto: object,
        titulo: this.alertForm.controls.titulo.value,
        expediente: undefined,
        publicacion_desde: undefined,
        publicacion_hasta: undefined,
      };

      this.tendersStateService.postAlert(request);

      this.actions.pipe(ofActionSuccessful(PostAlert)).subscribe(() => {
        this.pageIndex = 0;
        this.tendersStateService.getTendersAlerts({
          page: this.pageIndex + 1,
          size: 30,
        });

        this.resetAlertform();
        this.loading = false;

        setTimeout(() => {
          this.alertForm.markAsUntouched();
        }, 0);
      });
    }
  }

  getProvinceById(province: Province): Province {
    return this.dropdownProvinciasList.find((p) => p.id === Number(province));
  }

  getCpvById(cpv: Cpv): Cpv {
    return this.dropdownSectoresList.find((c) => c.id === Number(cpv));
  }

  populateProvinces(): void {
    this.actions.pipe(ofActionSuccessful(GetProvinces)).subscribe(() => {
      this.dropdownProvinciasList = this.tendersStateService.provinces.map((p) => {
        return {
          id: p.code,
          itemName: p.nombre,
          name: this.utilsService.removeDiacritics(p.nombre),
        };
      });
    });

    this.tendersStateService.getProvinces();
  }

  populateSections(): void {
    this.actions.pipe(ofActionSuccessful(GetCpvs)).subscribe(() => {
      this.dropdownSectoresList = this.tendersStateService.cpvs.map((c) => {
        return {
          id: Number(c.code),
          itemName: c.descripcion,
          name: this.utilsService.removeDiacritics(c.descripcion),
        };
      });
    });

    this.tendersStateService.getCpvs();
  }

  populateImports(): void {
    this.actions.pipe(ofActionSuccessful(GetImports)).subscribe(() => {
      this.dropdownImportesList = this.tendersStateService.imports.map((c, i) => {
        return {
          id: i,
          itemName: `De ${c.de}€ a ${c.hasta}€`,
        };
      });
    });

    this.tendersStateService.getImports();
  }

  search(row: Alert) {
    this.router.navigate(['tenders', 'search'], { queryParams: { withFiltersFromAlerts: row.id } });
  }

  resetAlertform(): void {
    this.alertForm.patchValue({
      titulo: '',
      provinces: [],
      sections: [],
      imports: [],
      objeto: '',
      organismo: '',
    });

    this.objectChipFormArray = this.fb.array([]);
    this.selectedSectoresInput = [];
    this.selectedOrganismoInput = [];
  }

  setSelectedSectores(data: any[]): void {
    this.selectedSectoresInput = data;
  }

  setSelectedOrganismo(data: any[]): void {
    this.selectedOrganismoInput = data;
  }

  showOperatorsDialog(): void {
    this.dialog.open(OperatorsDialogComponent, {
      width: '100%',
      maxWidth: '650px',
    });
  }

  addObjeto(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.objectChipFormArray.push(this.fb.group({ name: [value.trim()], type: 'text', editing: false }));
      this.objectChipFormArray.push(this.fb.group({ name: ['AND'], type: 'operator', editing: false }));
    }

    if (input) {
      input.value = '';
    }
  }

  addOperator(): void {
    const lastObject = this.objectChipFormArray.at(this.objectChipFormArray.length - 1) as FormGroup;

    if (lastObject && lastObject.controls.type.value !== 'operator') {
      this.objectChipFormArray.push(this.fb.group({ name: ['AND'], type: 'operator', editing: false }));
    }
  }

  removeObjeto(index: number): void {
    this.objectChipFormArray.removeAt(index);
  }

  editObjeto(index: number): void {
    this.objectChipFormArray.at(index).get('editing')?.setValue(true);

    if (this.objectChipFormArray.at(index).get('type')?.value === 'operator') {
      if (this.objectChipFormArray.at(index).get('name')?.value === 'AND') {
        this.objectChipFormArray.at(index).get('name')?.setValue('OR');
      } else {
        this.objectChipFormArray.at(index).get('name')?.setValue('AND');
      }
    }
  }

  saveObjeto(index: number): void {
    const chipFormGroup = this.objectChipFormArray.at(index);
    if (chipFormGroup.valid) {
      const name = chipFormGroup.get('name')?.value;
      this.objectChipFormArray.at(index).get('name')?.setValue(name);
      this.objectChipFormArray.at(index).get('editing')?.setValue(false);
    }
  }

  splitStringWithOperators(input: string): string[] {
    const regex = /\s*(AND|OR)\s*/;
    return input.split(regex).filter((str) => str.trim() !== '');
  }

  setObjetoFieldValues(values: any): void {
    values.forEach((v) => {
      const formGroup = this.fb.group({
        name: v.name,
        type: v.type,
        editing: false,
      });

      this.objectChipFormArray.push(formGroup);
    });
  }

  getObjectValues(values: any[]): string {
    return values.map((v) => v.name).join(' ');
  }
}
