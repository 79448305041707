<div class="container-main">
  <div class="header">
    <div class="text">
      <p class="title">Mi panel de estadísticas empresariales</p>
      <p class="desc">Bienvenido a tu panel general de analíticas</p>
    </div>
    <mat-spinner [diameter]="24" *ngIf="loadingKPIs || loadingGraphs"></mat-spinner>
  </div>
  <div class="kpis" *ngIf="!loadingKPIs">
    <div class="kpi" *ngFor="let kpi of kpis">
      <div class="header">
        <div class="icon">
          <mat-icon>{{ kpi.icon }}</mat-icon>
        </div>
        <div class="title" matTooltipClass="tooltip-bigger-font-size" [matTooltip]="kpi.indicador">
          {{ kpi.indicador }}
        </div>
      </div>
      <div class="description">{{ kpi.explicacion }}</div>
      <div class="data">
        <p
          class="value"
          [ngClass]="{ decrease: kpi.actual_value < 0, increase: kpi.actual_value > 0 }"
          *ngIf="kpi.is_percentage"
        >
          {{ kpi.actual_value | number: '1.1-1' }}<span class="unit">%</span>
          <span class="unit" style="margin-left: 6px">{{ kpi.unit }}</span>
        </p>
        <p class="value" *ngIf="!kpi.is_percentage">
          {{ kpi.actual_value | number }} <span class="unit">{{ kpi.unit }}</span>
        </p>
        <div>
          <p class="source">Fuente: {{ kpi.fuente }}</p>
          <!-- <p class="date_comparation">{{ kpi.how_to }}</p> -->
        </div>
        <div
          class="comparation"
          *ngIf="kpi.increment_value != 0"
          [ngClass]="{ decrease: kpi.increment_value < 0, increase: kpi.increment_value > 0 }"
        >
          <p *ngIf="kpi.increment_value < 0">Descenso del {{ kpi.increment_value | number: '1.2-2' }}%</p>
          <p *ngIf="kpi.increment_value > 0">Incremento del {{ kpi.increment_value | number: '1.2-2' }}%</p>
          <mat-icon *ngIf="kpi.increment_value < 0">trending_down</mat-icon>
          <mat-icon *ngIf="kpi.increment_value > 0">trending_up</mat-icon>
          <mat-icon matTooltipClass="tooltip-bigger-font-size" [matTooltip]="kpi.how_to" *ngIf="kpi.how_to">
            help
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="kpis" *ngIf="loadingKPIs">
    <div class="kpi" *ngFor="let kpi of [0, 1, 2, 3, 4]">
      <div class="ngx-skeleton-loader" style="height: 25%"></div>
      <div class="ngx-skeleton-loader" style="height: 75%"></div>
    </div>
  </div>
  <div class="graphs" *ngIf="!loadingGraphs">
    <ng-container *ngFor="let graph of graphs; let i = index">
      <app-business-statistics-section-detail-graph
        [data]="graph"
        [page]="'home'"
        [themeId]="themeId"
      ></app-business-statistics-section-detail-graph>
      <!-- <div class="space-w-100" *ngIf="i === 2"></div> -->
    </ng-container>
  </div>
  <div class="graphs" *ngIf="loadingGraphs">
    <div class="graph">
      <div class="ngx-skeleton-loader" style="height: 20%"></div>
      <div class="ngx-skeleton-loader" style="height: 80%"></div>
    </div>
    <div class="graph">
      <div class="ngx-skeleton-loader" style="height: 20%"></div>
      <div class="ngx-skeleton-loader" style="height: 80%"></div>
    </div>
    <div class="graph">
      <div class="ngx-skeleton-loader" style="height: 20%"></div>
      <div class="ngx-skeleton-loader" style="height: 80%"></div>
    </div>
    <div class="space-w-100"></div>
    <div class="graph">
      <div class="ngx-skeleton-loader" style="height: 20%"></div>
      <div class="ngx-skeleton-loader" style="height: 80%"></div>
    </div>
    <div class="graph">
      <div class="ngx-skeleton-loader" style="height: 20%"></div>
      <div class="ngx-skeleton-loader" style="height: 80%"></div>
    </div>
  </div>
</div>
