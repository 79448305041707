<div class="container">
  <div class="header">
    <p class="title">Buscador de subvenciones</p>
    <div class="buttons">
      <button
        mat-flat-button
        (click)="nextGeneration()"
        [ngClass]="{
          active: nextGenerationFiltersAreActive()
        }"
      >
        <p>Next Generation</p>
      </button>
      <button mat-flat-button (click)="createAlert()">
        <mat-icon>star</mat-icon>
        <p *ngIf="!withFiltersFromAlerts">Crear búsqueda y alerta</p>
        <p *ngIf="withFiltersFromAlerts">Editar búsqueda y alerta</p>
      </button>
    </div>
    <div class="buttons actions">
      <button
        mat-flat-button
        color="primary"
        [disabled]="
          !subventionsStateService.subventions ||
          subventionsStateService.subventions.items.length === 0 ||
          loading ||
          downloadingExcel ||
          downloadingPdf
        "
        (click)="exportFile('pdf')"
      >
        <mat-spinner [diameter]="24" *ngIf="downloadingPdf"></mat-spinner>
        <mat-icon *ngIf="!downloadingPdf">download</mat-icon>
        Exportar a Pdf
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="
          !subventionsStateService.subventions ||
          subventionsStateService.subventions.items.length === 0 ||
          loading ||
          downloadingExcel ||
          downloadingPdf
        "
        (click)="exportFile('xls')"
      >
        <mat-spinner [diameter]="24" *ngIf="downloadingExcel"></mat-spinner>
        <mat-icon *ngIf="!downloadingExcel">download</mat-icon>
        Exportar a Excel
      </button>
    </div>
  </div>
  <table mat-table [dataSource]="(subventionsStateService.subventions$ | async).items">
    <ng-container matColumnDef="card">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" [ngClass]="{ 'mat-column-card-next-generation': row.next_generation }">
        <a class="container-card" (click)="openDetail(row.id)">
          <div class="header">
            <img src="assets/imgs/europe-flag.svg" class="prefix-img" *ngIf="row.next_generation" />
            <p>{{ row.titulo }}</p>
            <button mat-icon-button (click)="saveItem($event, row)" [ngClass]="{ favorite: row.favorito }">
              <mat-icon>folder_special</mat-icon>
            </button>
          </div>
          <div class="content">
            <div class="sections">
              <div class="section">
                <mat-icon>public</mat-icon>
                <div class="content">
                  <p class="name">Ámbito geográfico</p>
                  <p class="value">{{ row.ambito }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>event</mat-icon>
                <div class="content">
                  <p class="name">Plazo</p>
                  <p class="value" *ngIf="row.fecha_cierre">{{ row.fecha_cierre | date }}</p>
                  <p class="value" *ngIf="!row.fecha_cierre">n/d</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>euro</mat-icon>
                <div class="content">
                  <p class="name">Presupuesto</p>
                  <p class="value">{{ row.presupuesto | number }} €</p>
                </div>
              </div>
            </div>
            <a (click)="openDetail(row.id)">Más información</a>
          </div>
        </a>
      </td>
    </ng-container>
    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr> -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <th mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
      <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
      <!-- <p style="margin: 0" *ngIf="!loading">No se han encontrado datos</p> -->
    </th>
  </table>
  <mat-paginator
    [pageIndex]="pageIndex"
    pageSize="30"
    (page)="pageEvent($event)"
    fixed
    [ngStyle]="{ opacity: (subventionsStateService.subventions$ | async).total > 0 ? 1 : 0 }"
  ></mat-paginator>
  <div class="no-data" *ngIf="!loading && (subventionsStateService.subventions$ | async).total === 0">
    <img src="assets/imgs/404-illustration-no-data.svg" />
    <p class="title">Sin datos</p>
    <p class="description">
      No se han encontrado resultados con los filtros actuales. Prueba a realizar otra búsqueda.
    </p>
  </div>
</div>
