import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchItem } from '../magazine-draft/magazine-draft.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, throwError } from 'rxjs';
import { HttpHeaders, HttpEventType, HttpClient } from '@angular/common/http';
import { finalize, map, catchError } from 'rxjs/operators';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { PRESSURL } from '@env/environment';
import _moment from 'moment';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';

@Component({
  selector: 'app-custom-document',
  templateUrl: './custom-document.component.html',
  styleUrls: ['./custom-document.component.less'],
})
export class CustomDocumentComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    abstract: new FormControl('', [Validators.required]),
    source: new FormControl('', [Validators.required]),
    date: new FormControl(_moment().startOf('day').toISOString(), [Validators.required]),
    s3_file_url: new FormControl([], [Validators.required]),
    docType: new FormControl('custom', [Validators.required]),
  });

  fileName = '';
  uploadProgress: number | undefined;
  uploadSub: Subscription | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { searchItem: SearchItem },
    public dialogRef: MatDialogRef<CustomDocumentComponent>,
    private http: HttpClient,
    private _authStateService: AuthStateService,
    private _snackbarStateService: SnackbarStateService
  ) {}

  ngOnInit(): void {}

  get title() {
    return this.formGroup.get('title');
  }

  get abstract() {
    return this.formGroup.get('abstract');
  }

  get source() {
    return this.formGroup.get('source');
  }

  get date() {
    return this.formGroup.get('date');
  }

  get s3_file_url() {
    return this.formGroup.get('s3_file_url');
  }

  get docType() {
    return this.formGroup.get('docType');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      this._snackbarStateService.show('Revise los campos obligatorios', 5000, 'cerrar');
      return;
    }

    this.dialogRef.close(this.formGroup.value);
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append('file', file);

      const upload$ = this.http
        .post(PRESSURL + 'upload', formData, {
          reportProgress: true,
          observe: 'events',
          headers: new HttpHeaders({
            Authorization: `Bearer ${''}`,
          }),
        })
        .pipe(
          finalize(() => {
            this.reset();
          }),
          catchError((error) => {
            this._snackbarStateService.show('Error al subir el archivo', 5000, 'cerrar');
            return throwError(error);
          })
        );

      this.uploadSub = upload$.subscribe((event: any) => {
        if (event.type == HttpEventType.UploadProgress && event.total) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
        if (event.type == HttpEventType.Response) {
          this.s3_file_url.setValue(event.body.data.s3_path);
        }
      });
    }
  }

  cancelUpload() {
    if (this.uploadSub) this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    if (this.uploadProgress && this.uploadSub) {
      this.uploadProgress = undefined;
      this.uploadSub.unsubscribe();
    }
  }
}
