import { BrainyDoc } from '@app/models/Brainy.document';

export class GetDocuments {
  static readonly type = '[GetDocuments] action';

  constructor(public payload: any) {}
}

export class AddDocumentsToCategory {
  static readonly type = '[AddDocumentsToCategory] action';

  constructor(public payload: any) {}
}

export class UpdateTabDocuments {
  static readonly type = '[UpdateTabDocuments] action';

  constructor(public payload: any) {}
}

export class UnsetDocuments {
  static readonly type = '[UnsetDocuments] action';

  constructor() {}
}

export class ToggleInteresting {
  static readonly type = '[ToggleInteresting] action';

  constructor(public docId: string) {}
}

export class AddSelectedDocument {
  static readonly type = '[AddSelectedDocument] action';

  constructor(public payload: BrainyDoc) {}
}
