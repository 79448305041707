<div class="container-main">
  <div style="display: flex; align-items: center; gap: 12px; padding: 16px" *ngIf="!category">
    <mat-spinner [diameter]="24"></mat-spinner>
    <p style="margin: 0">Cargando datos...</p>
  </div>
  <div class="header" *ngIf="category">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <p>{{ category.categoria }}</p>
  </div>
  <app-business-statistics-section-detail-search
    [themeId]="themeId"
    [categoryId]="categoryId"
    (sendData$)="setData($event)"
  ></app-business-statistics-section-detail-search>
  <app-business-statistics-section-detail-graph
    [data]="data"
    [page]="'detail'"
    [themeId]="themeId"
  ></app-business-statistics-section-detail-graph>
  <app-business-statistics-section-detail-table [data]="data"></app-business-statistics-section-detail-table>
</div>
