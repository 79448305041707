<span mat-menu-item>
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Seleccione región</mat-label>
      <mat-select [formControl]="region" (selectionChange)="regionSelected($event)">
        <mat-optgroup *ngFor="let region of all_regions" [label]="region.ccaa">
          <mat-option *ngFor="let provincia of region.provincias" [value]="provincia.id">{{
            provincia.viewName
          }}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </form>
</span>
