<div class="documents-theme documents-theme-ceoe">
  <mat-toolbar id="main-app-toolbar" color="primary" layout="row">
    <!-- <button
      mat-icon-button
      (click)="appStateService.toggleSideMenu()"
      *ngIf="(appStateService.history_url$ | async) === undefined && appStateService.show_title$ | async"
    >
      <mat-icon>menu</mat-icon>
    </button> -->
    <a mat-icon-button *ngIf="appStateService.history_url$ | async as history_url" [routerLink]="history_url">
      <mat-icon>arrow_back</mat-icon>
    </a>
    <!-- <mat-icon *ngIf="!(appStateService.show_title$ | async)" svgIcon="brain" class="icon-barnav"></mat-icon> -->
    <!-- <h1 *ngIf="appStateService.show_title$ | async" class="app-namenav">{{ appStateService.title$ | async }}</h1> -->
    <img
      [src]="authStateService.userConfig.interface_config.logo"
      style="max-width: 170px; height: 100%; object-fit: contain; cursor: pointer"
      routerLink="/home"
    />
    <button
      mat-stroked-button
      class="view-modules-btn"
      (mouseover)="changeDisplay('view-modules-tooltip', 'flex')"
      (mouseout)="changeDisplay('view-modules-tooltip', 'none')"
    >
      <mat-icon>view_module</mat-icon>
      Mis módulos
      <div id="view-modules-tooltip" class="tooltip">
        <a routerLink="/home" (click)="changeDisplay('view-modules-tooltip', 'none')"
          ><mat-icon>chevron_left</mat-icon> Volver al inicio</a
        >
        <div class="cards">
          <div
            class="card"
            [ngClass]="{ locked: card.is_allowed == 'false' }"
            (click)="card.is_allowed == 'true' ? navigateTo(card.path) : navigateToBuyUrl(card.url_buy)"
            *ngFor="let card of getCardsFiltered()"
          >
            <mat-icon class="lock" *ngIf="card.is_allowed == 'false'">lock</mat-icon>
            <img [src]="'assets/icons/' + card.icon + '.svg'" *ngIf="card.icon" />
            <div class="content">
              <p class="label">{{ card.header }}</p>
              <p class="desc">{{ card.description }}</p>
            </div>
            <div class="view">
              <mat-icon>arrow_forward</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </button>
    <span class="flex-spacer"></span>
    <main-search class="full-width"></main-search>
  </mat-toolbar>
  <mat-sidenav-container
    class="main-app-sidenav-container"
    autosize
    [ngClass]="(authStateService.userConfig$ | async) ? 'sidenav-container' : 'sidenav-container full'"
    fullscreen
  >
    <mat-sidenav
      #snav
      mode="side"
      [opened]="hasAnySubItemActive() && !hasAnyReservedUrl()"
      *ngIf="authStateService.userConfig$ | async as userConfig"
    >
      <mat-nav-list>
        <app-nav-list [menu_items]="userConfig.interface_config.menu_items"></app-nav-list>
        <!-- <p class="version">v{{ appVersion }}</p> -->
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content [ngStyle]="{ height: getMatSidenavContentHeight() }">
      <router-outlet></router-outlet>
      <button
        mat-mini-fab
        matTooltip="Expandir menú de filtros"
        color="primary"
        *ngIf="
          !(tendersStateService.openedMenuTendersFilters$ | async) &&
          url.startsWith('/tenders/search') &&
          !url.startsWith('/tenders/search/')
        "
        (click)="tendersStateService.toggleTendersMenuFilters()"
        style="position: fixed; z-index: 999; bottom: 6px; right: 6px"
      >
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>
      <button
        mat-mini-fab
        matTooltip="Expandir menú de filtros"
        color="primary"
        *ngIf="
          !(subventionsStateService.filters$ | async).openedMenu &&
          url.startsWith('/subventions/search') &&
          !url.startsWith('/subventions/search/')
        "
        (click)="togglesubventionsMenuFilters()"
        style="position: fixed; z-index: 999; bottom: 6px; right: 6px"
      >
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>
      <button
        mat-mini-fab
        matTooltip="Expandir menú de filtros"
        color="primary"
        *ngIf="
          !(collectiveAgreementsStateService.filters$ | async).openedMenu &&
          url.startsWith('/collective-agreements/search') &&
          !url.startsWith('/collective-agreements/search/')
        "
        (click)="toggleCollectiveAgreementsMenuFilters()"
        style="position: fixed; z-index: 999; bottom: 6px; right: 6px"
      >
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>
    </mat-sidenav-content>
    <mat-sidenav
      #snav
      class="sidenav-tenders-search"
      style="width: 350px; background-color: white !important"
      [ngStyle]="{ display: (tendersStateService.openedMenuTendersFilters$ | async) ? 'block' : 'none' }"
      mode="side"
      position="end"
      [disableClose]="true"
      [opened]="tendersStateService.openedMenuTendersFilters$ | async"
      *ngIf="url.startsWith('/tenders/search') && !url.startsWith('/tenders/search/')"
      class="search-filters-sidenav"
    >
      <app-tenders-search-filters></app-tenders-search-filters>
    </mat-sidenav>
    <mat-sidenav
      #snav
      style="width: 350px; background-color: white !important"
      [ngStyle]="{ display: (subventionsStateService.filters$ | async).openedMenu ? 'block' : 'none' }"
      mode="side"
      position="end"
      [disableClose]="true"
      [opened]="(subventionsStateService.filters$ | async).openedMenu"
      *ngIf="url.startsWith('/subventions/search') && !url.startsWith('/subventions/search/')"
      class="search-filters-sidenav"
    >
      <app-subventions-search-filters></app-subventions-search-filters>
    </mat-sidenav>
    <mat-sidenav
      #snav
      style="width: 350px; background-color: white !important"
      [ngStyle]="{ display: (collectiveAgreementsStateService.filters$ | async).openedMenu ? 'block' : 'none' }"
      mode="side"
      position="end"
      [disableClose]="true"
      [opened]="(collectiveAgreementsStateService.filters$ | async).openedMenu"
      *ngIf="url.startsWith('/collective-agreements/search') && !url.startsWith('/collective-agreements/search/')"
      class="search-filters-sidenav"
    >
      <app-collective-agreements-search-filters></app-collective-agreements-search-filters>
    </mat-sidenav>
    <mat-sidenav
      #snav
      class="sidenav-tenders-search"
      style="width: 350px; background-color: white !important"
      mode="side"
      position="end"
      [disableClose]="true"
      [opened]="tendersStateService.openedMenuTendersFilters$ | async"
      *ngIf="url.startsWith('/sector-reports/search')"
      class="search-filters-sidenav"
    >
      <app-sector-reports-search-filters></app-sector-reports-search-filters>
    </mat-sidenav>
  </mat-sidenav-container>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#60869b"
  fullScreen="true"
  type="ball-clip-rotate"
  name="main"
>
  <p style="font-size: 20px; color: #60869b">cargando...</p>
</ngx-spinner>
