<div class="container">
  <div class="loader" style="width: 100%" *ngIf="loading">
    <div style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <mat-spinner [diameter]="20"></mat-spinner>
      <p style="margin: 0">Cargando datos...</p>
    </div>
  </div>
  <mat-accordion *ngIf="!loading">
    <mat-expansion-panel [expanded]="isPanelExpanded">
      <mat-expansion-panel-header [ngClass]="{ 'mat-expansion-panel-header-stepper-index-1': stepperIndex === -1 }">
        <mat-panel-title>{{
          stepperIndex === -1
            ? '¿Cómo quieres generar el informe?'
            : template
            ? dataForm.controls.title.value + ' - ' + template.n_report + ' Informes'
            : dataForm.controls.title.value
        }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="content">
        <div class="desc">
          <p>
            Indica un título y una descripción a partir de la cual se generará el informe con todas la secciones creadas
          </p>
          <button
            mat-button
            color="primary"
            class="btn-same-color btn-same-color-primary"
            (click)="viewReports()"
            *ngIf="template && stepperIndex !== -1"
          >
            Ver informes
          </button>
        </div>
        <form [formGroup]="dataForm">
          <mat-form-field appearance="fill">
            <mat-label>Título del informe</mat-label>
            <input matInput formControlName="title" />
            <mat-error class="error-validator" *ngIf="dataForm.controls.title.hasError('required')">
              Campo requerido
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Descripción para generar el informe</mat-label>
            <textarea
              matInput
              formControlName="description"
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="20"
            ></textarea>
            <mat-error class="error-validator" *ngIf="dataForm.controls.description.hasError('required')">
              Campo requerido
            </mat-error>
          </mat-form-field>
        </form>
        <button
          mat-flat-button
          color="primary"
          class="btn-same-color btn-same-color-primary"
          (click)="completeIntroductionStep()"
          *ngIf="stepperIndex < 0"
        >
          Siguiente
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="main-content">
    <div class="stepper">
      <mat-vertical-stepper
        [linear]="false"
        [selectedIndex]="stepperIndex"
        (selectionChange)="selectionStepperChange($event)"
        #stepper
        [style.display]="loading ? 'none' : 'block'"
      >
        <mat-step *ngFor="let section of sectionsFormArray.controls; let i = index" [stepControl]="section">
          <ng-template matStepLabel>
            <!-- {{ section.value | json }}
          {{ utilsService.castAbstractControlToFormGroup(section).value | json }} -->
            <div
              class="step-header-content"
              [formGroup]="section"
              [ngClass]="{ 'completed-step': section.valid && section.controls.questions.length > 0 }"
            >
              <p>{{ utilsService.castAbstractControlToFormGroup(section).controls.title.value || 'Sección' }}</p>
              <p></p>
              <div class="list" *ngIf="getSectionQuestionsFormArray(i).controls.length > 0">
                <p
                  class="item"
                  *ngFor="let sectionQuestion of getSectionQuestionsFormArray(i).controls; let i2 = index"
                >
                  <span *ngIf="utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.question.value"
                    >• {{ utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.question.value }}</span
                  >
                </p>
              </div>
            </div>
          </ng-template>
        </mat-step>
      </mat-vertical-stepper>
      <div class="buttons" *ngIf="!loading && stepperIndex !== -1">
        <div>
          <button
            mat-flat-button
            color="primary"
            class="btn-same-color btn-same-color-primary"
            (click)="addSectionBtn()"
          >
            <mat-icon>add</mat-icon>
            Añadir nueva sección
          </button>
          <button
            mat-flat-button
            color="primary"
            class="btn-same-color btn-same-color-primary"
            (click)="generateReport()"
          >
            Generar informe
          </button>
        </div>
        <button
          mat-flat-button
          color="primary"
          class="btn-same-color btn-same-color-primary"
          [disabled]="saving"
          (click)="saveTemplate()"
        >
          <mat-spinner [diameter]="20" style="display: inline-flex; margin-right: 12px" *ngIf="saving"></mat-spinner>
          <mat-icon *ngIf="!saving">save</mat-icon>
          Guardar plantilla
        </button>
      </div>
    </div>
    <div class="divider" *ngIf="!loading && stepperIndex >= 0"></div>
    <!-- <div class="step-content" [formGroup]="dataForm" *ngIf="stepperIndex === 0 && !loading">
      <div class="header">
        <p>¿Cómo quieres generar el informe?</p>
        <p>
          Indica un título y una descripción a partir de la cual se generará el informe con todas la secciones creadas
        </p>
      </div>
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Título del informe</mat-label>
          <input matInput formControlName="title" />
          <mat-error class="error-validator" *ngIf="dataForm.controls.title.hasError('required')">
            Campo requerido
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Descripción para generar el informe</mat-label>
          <textarea matInput formControlName="description"></textarea>
          <mat-error class="error-validator" *ngIf="dataForm.controls.description.hasError('required')">
            Campo requerido
          </mat-error>
        </mat-form-field>
      </div>
      <button
        mat-flat-button
        color="primary"
        class="btn-same-color btn-same-color-primary"
        (click)="completeIntroductionStep()"
      >
        Siguiente
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div> -->
    <div class="step-content steps-content-forms" [formGroup]="sectionsForm" *ngIf="!loading && stepperIndex >= 0">
      <div class="header">
        <div class="row">
          <p>
            {{
              utilsService.castAbstractControlToFormGroup(sectionsFormArray.at(stepperIndex)).controls.title.value ||
                'Nueva sección'
            }}
          </p>
          <button
            mat-flat-button
            color="primary"
            class="btn-same-color btn-same-color-primary"
            (click)="addSectionAnswer(stepperIndex)"
          >
            <mat-icon>add</mat-icon>
            Añadir pregunta
          </button>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Opciones">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="deleteSection(stepperIndex)">
              <mat-icon>delete</mat-icon>
              <span>Eliminar sección</span>
            </button>
          </mat-menu>
        </div>
        <p class="desc">
          Formula distintos tipos preguntas para generar el informe final y obtén una respuesta de la IA basada en las
          respuestas que hayas obtenido.
        </p>
      </div>
      <div class="content" formArrayName="sections">
        <div
          class="cards"
          *ngFor="let section of sectionsFormArray.controls; let i = index"
          [formGroup]="utilsService.castAbstractControlToFormGroup(section)"
          [ngStyle]="{ display: i === stepperIndex ? 'flex' : 'none' }"
        >
          <mat-form-field appearance="fill" *ngIf="i === stepperIndex">
            <mat-label>Título de la sección</mat-label>
            <input matInput formControlName="title" type="text" [readonly]="mode === 'view'" />
            <mat-error
              class="error-validator"
              *ngIf="utilsService.castAbstractControlToFormGroup(section).controls.title.hasError('required')"
            >
              Campo requerido
            </mat-error>
          </mat-form-field>
          <div class="card" *ngIf="getSectionQuestionsFormArray(i).length > 0">
            <div>
              <div>
                <div class="content" cdkDropList lockAxis="y" (cdkDropListDropped)="dropSectionQuestion($event, i)">
                  <div
                    class="data"
                    cdkDrag
                    cdkDragBoundary=".content"
                    cdkDragPreviewClass="sectorReport-cdk-drag-drop-list-preview"
                    [cdkDragDisabled]="mode === 'view'"
                    [formGroup]="utilsService.castAbstractControlToFormGroup(sectionQuestion)"
                    *ngFor="let sectionQuestion of getSectionQuestionsFormArray(i).controls; let i2 = index"
                  >
                    <div class="header">
                      <mat-icon cdkDragHandle>drag_indicator</mat-icon>
                      <mat-form-field appearance="fill" class="form-field-no-padding-bottom">
                        <mat-label>Fuente</mat-label>
                        <mat-select formControlName="source" (selectionChange)="setPeriods(i, i2, $event)">
                          <mat-option *ngFor="let source of sources.items" [value]="source.id">
                            {{ source.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          class="error-validator"
                          *ngIf="
                            utilsService
                              .castAbstractControlToFormGroup(sectionQuestion)
                              .controls.source.hasError('required')
                          "
                        >
                          Campo requerido
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field
                        appearance="fill"
                        class="form-field-no-padding-bottom"
                        [disabled]="
                          utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.source.value == null
                        "
                      >
                        <mat-label>Periodo de búsqueda</mat-label>
                        <mat-select formControlName="period">
                          <mat-option
                            *ngFor="
                              let period of utilsService.castAbstractControlToFormGroup(sectionQuestion).controls
                                .periodsList.value
                            "
                            [value]="period"
                          >
                            {{ period }}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          class="error-validator"
                          *ngIf="
                            utilsService
                              .castAbstractControlToFormGroup(sectionQuestion)
                              .controls.period.hasError('required')
                          "
                        >
                          Campo requerido
                        </mat-error>
                      </mat-form-field>
                      <button mat-icon-button (click)="deleteSectionQuestion(i, i2)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                    <p
                      class="font-desc"
                      *ngIf="utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.source.value != null"
                    >
                      {{
                        getSource(utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.source.value)
                          ?.description
                      }}
                    </p>
                    <div class="content">
                      <mat-form-field appearance="fill" class="form-field-no-padding-bottom">
                        <mat-label>Pregunta</mat-label>
                        <input
                          [id]="'input-section-' + i + '-question-' + i2"
                          matInput
                          formControlName="question"
                          type="text"
                          [readonly]="mode === 'view'"
                        />
                        <mat-error
                          class="error-validator"
                          *ngIf="
                            utilsService
                              .castAbstractControlToFormGroup(sectionQuestion)
                              .controls.question.hasError('required')
                          "
                        >
                          Campo requerido
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <button
                      mat-button
                      color="primary"
                      class="btn-same-color btn-same-color-primary"
                      (click)="generateAnswer(i, i2)"
                      *ngIf="
                        utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.loadingAnswer.value ===
                          false &&
                        utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.answer.value === null
                      "
                    >
                      <mat-icon>auto_awesome</mat-icon>
                      Generar respuesta
                    </button>
                    <div class="answer">
                      <mat-form-field
                        appearance="standard"
                        class="form-field-no-padding-bottom"
                        *ngIf="
                          utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.loadingAnswer.value ===
                            false &&
                          utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.answer.value !== null
                        "
                      >
                        <mat-label>Respuesta</mat-label>
                        <textarea
                          matInput
                          formControlName="answer"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="5"
                          cdkAutosizeMaxRows="5"
                        ></textarea>
                        <mat-error
                          class="error-validator"
                          *ngIf="
                            utilsService
                              .castAbstractControlToFormGroup(sectionQuestion)
                              .controls.answer.hasError('required')
                          "
                        >
                          Campo requerido
                        </mat-error>
                      </mat-form-field>
                      <button
                        mat-button
                        color="primary"
                        class="btn-same-color btn-same-color-primary"
                        (click)="generateAnswer(i, i2)"
                        *ngIf="
                          utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.loadingAnswer.value ===
                            false &&
                          utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.answer.value !== null
                        "
                      >
                        <mat-icon>sync</mat-icon>
                      </button>
                    </div>
                    <div
                      class="loader"
                      style="width: 100%"
                      *ngIf="
                        utilsService.castAbstractControlToFormGroup(sectionQuestion).controls.loadingAnswer.value ===
                        true
                      "
                    >
                      <div style="display: flex; align-items: center; gap: 12px; padding: 16px">
                        <mat-spinner [diameter]="20"></mat-spinner>
                        <p style="margin: 0">Generando respuesta...</p>
                      </div>
                    </div>
                    <!-- <quill-editor
                      class="quill-editor-gray"
                      formControlName="text"
                      placeholder="Insertar texto"
                      [readOnly]="
                        mode === 'view' ||
                        utilsService.castAbstractControlToFormGroup(sectorReport).controls.text.value ===
                          'GENERANDO RESUMEN...'
                      "
                    ></quill-editor> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <button
        mat-flat-button
        color="warn"
        class="btn-same-color btn-same-color-primary"
        (click)="deleteSection(stepperIndex)"
      >
        <mat-icon>delete</mat-icon>
        Eliminar sección
      </button> -->
    </div>
    <!-- <div class="form">
      <div class="header">
        <mat-icon (click)="router.navigate(['sector-reports', 'list', this.params.id])">arrow_back</mat-icon>
        <h3>{{ item.title }}</h3>
      </div>
      <div class="loader" style="width: 100%" *ngIf="loading">
        <div style="display: flex; align-items: center; gap: 12px; padding: 16px">
          <mat-spinner [diameter]="20"></mat-spinner>
          <p style="margin: 0">Cargando datos...</p>
        </div>
      </div>
      <form [formGroup]="form" *ngIf="!loading">
        <div class="cards">
          <div class="card">
            <mat-form-field appearance="fill">
              <mat-label>Título del informe</mat-label>
              <input matInput formControlName="title" type="text" [readonly]="mode === 'view'" />
              <mat-error class="error-validator" *ngIf="form.controls.title.hasError('required')">
                Campo requerido
              </mat-error>
            </mat-form-field>
            <div
              class="content"
              formArrayName="sectorReports"
              cdkDropList
              lockAxis="y"
              (cdkDropListDropped)="dropSectorReport($event, i)"
            >
              <div
                class="data"
                cdkDrag
                cdkDragBoundary=".content"
                cdkDragPreviewClass="sectorReport-cdk-drag-drop-list-preview"
                [cdkDragDisabled]="mode === 'view'"
                [formGroup]="utilsService.castAbstractControlToFormGroup(sectorReport)"
                *ngFor="let sectorReport of sectorReportsFormArray.controls; let i = index"
              >
                <div class="header">
                  <mat-icon cdkDragHandle *ngIf="mode === 'edit'">drag_indicator</mat-icon>
                  <mat-form-field appearance="fill" class="form-field-no-padding-bottom">
                    <mat-label>Título de la sección</mat-label>
                    <input matInput formControlName="title" type="text" [readonly]="mode === 'view'" />
                    <mat-error
                      class="error-validator"
                      *ngIf="
                        utilsService.castAbstractControlToFormGroup(sectorReport).controls.title.hasError('required')
                      "
                    >
                      Campo requerido
                    </mat-error>
                  </mat-form-field>
                  <button mat-icon-button (click)="deleteSectorReport(i)" *ngIf="mode === 'edit'">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                <mat-form-field appearance="fill">
                  <mat-label>Fuente</mat-label>
                  <mat-select formControlName="source">
                    <mat-option *ngFor="let source of sources.items" [value]="source.id" (click)="setSource(i, source)">
                      {{ source.source }} - {{ source.title }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    class="error-validator"
                    *ngIf="utilsService.castAbstractControlToFormGroup(sectorReport).controls.source.hasError('required')"
                  >
                    Campo requerido
                  </mat-error>
                </mat-form-field>
                <quill-editor
                  class="quill-editor-gray"
                  formControlName="text"
                  placeholder="Insertar texto"
                  [readOnly]="
                    mode === 'view' ||
                    utilsService.castAbstractControlToFormGroup(sectorReport).controls.text.value ===
                      'GENERANDO RESUMEN...'
                  "
                ></quill-editor>
                <div class="fields">
                  <div
                    class="field"
                    *ngIf="utilsService.castAbstractControlToFormGroup(sectorReport).controls.updated_at.value"
                  >
                    <p class="label">Última actualización</p>
                    <p class="value">
                      {{ utilsService.castAbstractControlToFormGroup(sectorReport).controls.updated_at.value | date }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="actions" *ngIf="mode === 'edit'">
                <button
                  mat-button
                  color="primary"
                  class="btn-same-color btn-same-color-primary"
                  (click)="addSectorReport()"
                >
                  <mat-icon>add</mat-icon>
                  Añadir sección
                </button>
              </div>
            </div>
          </div>
          <div class="actions" *ngIf="mode === 'edit'">
            <div style="flex: 1">
              <button
                mat-flat-button
                color="primary"
                class="btn-same-color btn-same-color-primary"
                [disabled]="exporting"
                (click)="generateDoc()"
              >
                <mat-icon *ngIf="exporting">
                  <mat-spinner [diameter]="20"></mat-spinner>
                </mat-icon>
                <mat-icon *ngIf="!exporting">description</mat-icon>
                Generar documento
              </button>
            </div>
            <button
              mat-flat-button
              color="warn"
              class="btn-same-color btn-same-color-warn"
              [disabled]="deleting"
              (click)="delete()"
              *ngIf="params.idSection !== 'create'"
            >
              <mat-icon *ngIf="deleting">
                <mat-spinner [diameter]="20"></mat-spinner>
              </mat-icon>
              <mat-icon *ngIf="!deleting">delete</mat-icon>
              Eliminar informe
            </button>
            <button
              mat-flat-button
              color="primary"
              class="btn-same-color btn-same-color-primary"
              [disabled]="saving"
              (click)="save()"
            >
              <mat-icon *ngIf="saving">
                <mat-spinner [diameter]="20"></mat-spinner>
              </mat-icon>
              <mat-icon *ngIf="!saving">save</mat-icon>
              Guardar informe
            </button>
          </div>
        </div>
      </form>
    </div> -->
    <!-- <div class="menu">
      <h3 style="font-weight: 500">Últimas fuentes</h3>
      <div class="loader" *ngIf="loadingSourcesLatest">
        <div style="display: flex; align-items: center; gap: 12px; padding: 16px">
          <mat-spinner [diameter]="20"></mat-spinner>
          <p style="margin: 0">Cargando datos...</p>
        </div>
      </div>
      <div class="content" *ngIf="!loadingSourcesLatest">
        <div class="item" *ngFor="let source of sourcesLatest">
          <p>{{ source.title }}</p>
          <div class="desc">
            <mat-icon>description</mat-icon>
            <div>
              <p>
                Ratio de reducción <span>{{ '87%' }}</span>
              </p>
              <p>{{ source.date }}</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
