<h1 mat-dialog-title>Crear alerta</h1>
<div mat-dialog-content>
  <form [formGroup]="saveSearchForm">
    <mat-form-field>
      <mat-label>Título de la alerta</mat-label>
      <input matInput id="vigilancia-calendar-pp-create-alert-title-input" formControlName="titulo" />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="actions-2">
  <button mat-button [mat-dialog-close]="true">Cancelar</button>
  <button
    id="vigilancia-calendar-pp-create-alert-alert-btn"
    mat-flat-button
    cdkFocusInitial
    color="primary"
    [disabled]="loading"
    (click)="save()"
  >
    Guardar
  </button>
</div>
