import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';

@Component({
  selector: 'app-magazine-view-send-dialog',
  templateUrl: './magazine-view-send-dialog.component.html',
  styleUrls: ['./magazine-view-send-dialog.component.scss'],
})
export class MagazineViewSendDialogComponent {
  filters: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<MagazineViewSendDialogComponent>,
    public authStateService: AuthStateService,
    private fb: FormBuilder
  ) {
    this.filters = this.fb.group({
      pdf: [true, [Validators.required]],
      email: [this.authStateService.userConfig.interface_config.client_id === 60, [Validators.required]],
    });
  }

  send(): void {
    this.filters.markAllAsTouched();

    if (this.filters.valid) {
      this.dialogRef.close(this.filters.value);
    }
  }
}
