import { Region } from './Region';
import { Topic } from './Topic';
import { SpiderGroup } from './SpiderGroup';
import { Spider } from './Spider';

export class GridSetup {
  cell_height: number;
  cell_width: number;
  maxcols: number;
  maxrows: number;
}

export class Query {
  dateParsed_start: string;
  dateParsed_end: string;
  text: string;
  docTypes: string[];
}

export class Aggregation {
  map_name_with: string;
  field: string;
  type: string;
  name_aggregation: string;
  size: number;
  query: Query;
}

export class Widget {
  aggregations: Aggregation[];
  cols: number;
  rows: number;
  y: number;
  x: number;
  dragEnabled: boolean;
  resizeEnabled: boolean;
  title: string;
  type: string;
  query: Query;
}

export class Layout {
  type: string;
  query: any;
  widgets: Widget[];
}

export class GridWidget {
  layouts: Layout[];
  subtitle: string;
  query: Query;
  gridsetup: GridSetup;
  title: string;
  description: string;
}

export class AllowedPath {
  show: boolean;
  path: string;
  active: boolean;
}

export class NavItem {
  title: string;
  path: string;
  icon: string;
  target: string;
  header: string;
  parent_module: string;
  query_favorite: string;
  items: NavItem[];
}

export class MenuItem {
  id: number;
  subtitle: string;
  path: string;
  icon: string;
  svg: string;
  header: string;
  description: string;
  items: NavItem[];
  active: boolean;
  is_allowed: string;
  query_favorite: string;
}

export class Config {
  Regulatorio: {
    [key: string]: {
      SpiderGroups: SpiderGroup[];
      Topics: Topic[];
      Regions: Region[];
      Context: Topic[];
    };
  };
  Europa: {
    [key: string]: {
      SpiderGroups: SpiderGroup[];
      Topics: Topic[];
      Regions: Region[];
      Context: Topic[];
    };
  };
  Vigilancia: {
    [key: string]: any;
  };
  Prensa: {
    Spiders: {
      digital: Spider[];
      escrita: Spider[];
    };
    Topics: Topic[];
    Regions: Region[];
  };
  Documentos: {
    SpiderGroups: SpiderGroup[];
    Topics: Topic[];
    Regions: Region[];
  };
}

export class InterfaceConfig {
  categories: 'legacy' | 'eurovoc';
  menu_items: MenuItem[];
  reports: GridWidget[];
  dashboards: GridWidget[];
  client_id: number;
  template: string;
  cover_template: string;
  multimedia_template: string;
  ia_search: string;
  digital_template: string;
  search_template: string;
  index_template: string;
  header_template: string;
  mail_sender: string;
  legal_notice: string;
  logo: string;
  company: {
    name: string;
    company_font: string;
    drafts_font: string;
    ccaa: {
      name: string;
      code: string;
    };
    provincia: {
      name: string;
      code: string;
    };
    color_palette: {
      theme: {
        primary: string;
        accent: string;
      };
      echarts: string;
    };
  };
}

export class UserConfig {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  is_active: boolean;
  last_login: Date;
  config: Config;
  interface_config: InterfaceConfig;
  email: string;
}

export class User {
  userid: number;
  username: string;
  config: UserConfig;
}
