<div class="info-drawer">
  <mat-list dense>
    <div mat-subheader *ngIf="(spiderStateService.important$ | async).length > 0">Fuentes destacadas</div>
    <mat-list-item *ngFor="let important of spiderStateService.important$ | async">
      <mat-icon *ngIf="important" mat-list-icon>highlight</mat-icon>
      <div *ngIf="important" mat-line [matTooltip]="important.name">{{ important.name }}</div>
      <div *ngIf="important" mat-line>Actualizado a {{ important.datetime | date }}</div>
    </mat-list-item>
    <mat-divider *ngIf="(spiderStateService.important$ | async).length > 0"></mat-divider>
    <div mat-subheader>Últimas fuentes</div>
    <mat-list-item *ngFor="let latest of spiderStateService.latest$ | async">
      <mat-icon mat-list-icon>list</mat-icon>
      <div mat-line [matTooltip]="latest.name">{{ latest.name }}</div>
      <div mat-line>Actualizado a {{ latest.datetime | date }}</div>
    </mat-list-item>
  </mat-list>
</div>
