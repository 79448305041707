<!-- UI V1 -->
<!-- <div class="container" fxLayout="row wrap" fxLayoutGap="32px grid">
  <div
    *ngFor="let item of authStateService.menuItems$ | async; let i = index; let last = last"
    fxFlex="1 0 30%"
    [ngClass]="{
      'section-card-container-hide':
        !item.active || (item.path === '/documents' && environment.styles.sidebar === 'ceoe')
    }"
  >
    <mat-card
      matRipple
      *ngIf="item.active || (item.path !== '/documents' && environment.styles.sidebar === 'ceoe')"
      [routerLink]="item.path"
      class="section-card"
    >
      <mat-card-header>
        <div mat-card-avatar fxLayoutAlign="center center">
          <mat-icon *ngIf="!item.svg" mat-list-icon class="icon-32">{{ item.icon }}</mat-icon>
          <mat-icon *ngIf="item.svg" mat-list-icon class="icon-32" svgIcon="{{ item.svg }}"></mat-icon>
        </div>
        <mat-card-title>{{ item.header | uppercase }}</mat-card-title>
        <mat-card-subtitle>{{ item.subtitle | uppercase }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p mat-line class="item-content">{{ item.description }}</p>
      </mat-card-content>
    </mat-card>
  </div>
</div> -->

<!-- UI V2 -->
<div class="container-main">
  <div class="container">
    <p class="title">Mis módulos</p>
    <div class="cards">
      <div
        [class]="'card card-path-' + card.path"
        [ngClass]="{ locked: card.is_allowed == 'false' }"
        (click)="card.is_allowed == 'true' ? navigateTo(card.path) : navigateToBuyUrl(card.url_buy)"
        *ngFor="let card of getCardsFiltered()"
      >
        <mat-icon class="lock" *ngIf="card.is_allowed == 'false'">lock</mat-icon>
        <div class="header">
          <img [src]="'assets/icons/' + card.icon + '.svg'" *ngIf="card.icon" />
          <p class="label">{{ card.header }}</p>
        </div>
        <div class="line"></div>
        <p class="desc">{{ card.description }}</p>
        <div class="line"></div>
        <div class="view">
          <p>Ver módulo</p>
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
