<div class="errorbox" *ngIf="isRateLimitReached">
  Hemos encontrado un problema con las comunicaciones, inténtelo de nuevo más tarde...
</div>
<div
  *ngIf="!isRateLimitReached"
  echarts
  [options]="options"
  [theme]="theme"
  [autoResize]="autoResize"
  [loading]="isLoading"
  [loadingOpts]="loadingOpts"
  (chartInit)="onChartInit($event)"
  #map_container
  style="height: 100%"
></div>
