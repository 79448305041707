import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { AppStateService } from '@app/state/app/app.service';
import { filter, map } from 'rxjs/operators';
import { SubventionsStateService } from '../store/subventions/subventions.service';

interface SidenavItem {
  name: string;
  icon: string;
  path: string;
  selected: boolean;
}

@Component({
  selector: 'app-subventions',
  templateUrl: './subventions.component.html',
  styleUrls: ['./subventions.component.scss'],
})
export class SubventionsComponent implements OnInit, OnDestroy {
  sidenavItems: SidenavItem[] = [
    {
      name: 'Inicio',
      icon: 'home',
      path: '',
      selected: false,
    },
    {
      name: 'Buscador de subvenciones',
      icon: 'search',
      path: 'search',
      selected: false,
    },
    {
      name: 'Alertas',
      icon: 'notifications',
      path: 'alerts',
      selected: false,
    },
    {
      name: 'Subvenciones favoritas',
      icon: 'favorite',
      path: 'favorites',
      selected: false,
    },
    {
      name: 'Soporte',
      icon: 'help_center',
      path: 'support',
      selected: false,
    },
  ];
  url = this.router.url;

  constructor(
    public appStateService: AppStateService,
    public authStateService: AuthStateService,
    public subventionsStateService: SubventionsStateService,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter((event: unknown): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.slice(1))
      )
      .subscribe(() => {
        this.url = this.router.url;
        this.getSelectedItem();
      });

    this.appStateService.addTitle('Subvenciones');

    if (this.appStateService.sidemenuOpened) {
      this.appStateService.toggleSideMenu();
    }

    this.getSelectedItem();
  }

  ngOnInit(): void {
    this.subventionsStateService.getFilters();
    this.subventionsStateService.updateSubventionsMenuFilters({
      ...this.subventionsStateService.filters,
      openedMenu: this.subventionsStateService.filters.openedMenu,
    });

    this.enableSubventionStyles();
  }

  ngOnDestroy(): void {
    this.disableSubventionStyles();
  }

  toggleMenuFilters(): void {
    this.subventionsStateService.updateSubventionsMenuFilters({
      ...this.subventionsStateService.filters,
      openedMenu: true,
    });
  }

  getSelectedItem(): void {
    const url = this.router.url.split('/').splice(2);
    this.selectItem(
      this.sidenavItems.find((si) => si.path.startsWith((url.length > 0 ? url[0].split('?')[0] : '') || ''))
    );
  }

  selectItem(sidenavItem: SidenavItem): void {
    this.sidenavItems.map((si) => (si.selected = false));
    sidenavItem.selected = true;
  }

  enableSubventionStyles(): void {
    const toolbar = document.getElementsByTagName('mat-toolbar')[0];
    toolbar.classList.add('mat-toolbar-tenders');
  }

  disableSubventionStyles(): void {
    const toolbar = document.getElementsByTagName('mat-toolbar')[0];
    toolbar.classList.remove('mat-toolbar-tenders');
  }
}
