import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-user-data',
  templateUrl: './error-user-data.component.html',
  styleUrls: ['./error-user-data.component.css'],
})
export class ErrorUserDataComponent implements OnInit {
  ngOnInit(): void {
    this.deleteLoadingAnimation();
  }

  deleteLoadingAnimation(): void {
    (document.querySelector('#loading-animation') as HTMLDivElement).style.display = 'none';
  }
}
