import { Magazine } from '../models/magazine.state.model';
import { Draft } from '../models/draft.state.model';

export class GetMagazines {
  static readonly type = '[GetMagazines] action';

  constructor(public payload: Magazine[]) {}
}

export class SetMagazine {
  static readonly type = '[SetMagazine] action';

  constructor(public payload: Magazine) {}
}

export class AddMagazine {
  static readonly type = '[AddMagazine] action';

  constructor(public payload: Magazine) {}
}

export class EditMagazine {
  static readonly type = '[EditMagazine] action';

  constructor(public payload: Magazine) {}
}

export class DeleteMagazine {
  static readonly type = '[DeleteMagazine] action';

  constructor(public payload: Magazine) {}
}

export class GetMagazineDrafts {
  static readonly type = '[GetMagazineDrafts] action';

  constructor(public payload: any) {}
}

export class GetMagazineDraft {
  static readonly type = '[GetMagazineDraft] action';

  constructor(public payload: Magazine) {}
}
