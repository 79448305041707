import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CALENDARURL, URL, environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InitiativesService {
  static readonly initiativesUrl = URL + 'congreso/get_iniciativas/';
  static readonly initiativesCCAUrl = CALENDARURL + 'favoritos/';
  static readonly addInitiativeUrl = URL + 'congreso/add/';
  static readonly addPastProcedureUrl = URL + 'congreso/past_procedure/';
  static readonly addDeadLineUrl = URL + 'congreso/deadlines/';
  static readonly addOpenInitiativesUrl = URL + 'congreso/iniciativas/';
  static readonly addOpenInitiativesCCAAUrl = URL + 'iniciativa/cca';
  static readonly addOpenInitiativesCCAUrl = CALENDARURL + 'search';
  static readonly followInitiativeUrl = CALENDARURL + 'favoritos/';
  static readonly initiativesNameMapping = URL + 'iniciativas/name_mapping';

  constructor(private http: HttpClient) {}

  getInitiatives() {
    return this.http.get(InitiativesService.initiativesUrl);
  }

  getInitiativesCCA() {
    return this.http.get(InitiativesService.initiativesCCAUrl);
  }

  addInitiatives(payload: { title: string; url: string }) {
    return this.http.post(InitiativesService.addInitiativeUrl, payload);
  }

  deleteInitiative(payload: number) {
    return this.http.delete(InitiativesService.addOpenInitiativesUrl + payload);
  }

  addCustomInitiative(payload: { title: string }) {
    return this.http.post(InitiativesService.addInitiativeUrl, payload);
  }

  addPastProcedure(payload) {
    return this.http.post(InitiativesService.addPastProcedureUrl, payload);
  }

  addDeadLine(payload) {
    return this.http.post(InitiativesService.addDeadLineUrl, payload);
  }

  getOpenInitiatives(payload) {
    return this.http.post(InitiativesService.addOpenInitiativesUrl, payload);
  }

  getOpenInitiativesCCAA(payload) {
    return this.http.get(
      `${InitiativesService.addOpenInitiativesCCAAUrl}?page=${payload.page}&size=${payload.size}&cca=${payload.ccaa}&pending=${payload.pending}&result=${payload.result}`
    );
  }

  getOpenInitiativesCCA(payload) {
    return this.http.post(
      `${InitiativesService.addOpenInitiativesCCAUrl}?page=${payload.page}&size=${payload.size}`,
      payload
    );
  }

  getOpenInitiativesFavorites() {
    return this.http.get(`${InitiativesService.initiativesCCAUrl}`);
  }

  followInitiative(payload) {
    return this.http.post(InitiativesService.followInitiativeUrl + payload, {});
  }

  unfollowInitiative(payload) {
    return this.http.delete(InitiativesService.followInitiativeUrl + payload);
  }

  getInitiativesNameMapping(): Observable<{ key: string; value: string }[]> {
    return this.http.get<{ key: string; value: string }[]>(InitiativesService.initiativesNameMapping);
  }

  getCalendarFilters(request: { path: string }): Observable<any> {
    return this.http.get<any>(`${environment.calendarFilters}?path=${request.path}`);
  }
}
