<h1 mat-dialog-title>Guardar búsqueda</h1>
<div mat-dialog-content>
  <form [formGroup]="saveSearchForm">
    <mat-form-field>
      <mat-label>Título de la búsqueda</mat-label>
      <input matInput formControlName="titulo" />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="actions">
  <button mat-button [mat-dialog-close]="true">Cancelar</button>
  <button mat-flat-button cdkFocusInitial color="primary" [disabled]="loading" (click)="save()">Guardar</button>
</div>
