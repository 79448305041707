import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
  selector: 'ng-pyramid',
  templateUrl: './ng-pyramid.component.html',
  styleUrls: ['./ng-pyramid.component.css'],
})
export class NgPyramidComponent extends BaseChartComponent implements OnInit, AfterViewInit {
  ngOnInit() {}

  getRequest() {
    return { uri: this.wconfig.config['uri'] };
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  refresh(config, response) {
    if (response) {
      this.options = [];
      var j = 0,
        ds = Object.values(response)[0];
      //            for (var key in ds) {
      let opt = {};
      //                if (ds.hasOwnProperty(key)) {
      //for (var i=1;i<ds[key][0].length;i++) {
      //    series.push({"type": type})
      //}
      let builderJson = ds;
      opt['title'] = {
        text: this.wconfig.config['title'],
      };
      opt['tooltip'] = {
        trigger: 'axis',
      };
      opt['grid'] = [
        {
          top: '1%',
          width: '50%',
          bottom: '1%',
          left: '1%',
          right: '1%',
          containLabel: true,
        },
        {
          top: '1%',
          width: '50%',
          bottom: '1%',
          left: '51.5%',
          right: '1%',
          containLabel: true,
        },
      ];
      opt['xAxis'] = [
        {
          type: 'value',
          inverse: true,
          max: builderJson['max'] + builderJson['max'] * 0.2,
          axisLabel: {
            show: true,
          },
          splitLine: {
            show: true,
            lineStyle: { type: 'solid' },
          },
        },
        {
          type: 'value',
          max: builderJson['max'] + builderJson['max'] * 0.2,
          gridIndex: 1,
          splitLine: {
            show: true,
            lineStyle: { type: 'solid' },
          },
        },
      ];

      opt['yAxis'] = [
        {
          type: 'category',
          data: Object.keys(builderJson['Hombres']),
          axisLabel: {
            interval: 0,
            rotate: 0,
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        {
          gridIndex: 1,
          type: 'category',
          data: Object.keys(builderJson['Mujeres']),
          axisLabel: {
            interval: 0,
            rotate: 0,
            show: true,
            fontWeight: 'bolder',
            fontSize: 14,
          },
          splitLine: {
            show: false,
          },
        },
      ];

      opt['series'] = [
        {
          type: 'bar',
          stack: 'Hombre',
          name: 'Hombres',
          itemStyle: { color: '#2f4554' },
          z: 3,
          label: {
            normal: {
              position: 'left',
              show: true,
            },
          },
          data: Object.keys(builderJson['Hombres']).map(function (key) {
            return builderJson['Hombres'][key];
          }),
        } /*, {
                        type: 'bar',
                        stack: 'Hombre',
                        silent: true,
                        itemStyle: {
                            normal: {
                                color: '#eee'
                            }
                        },
                        data: Object.keys(builderJson.Hombres).map(function (key) {
                            return (builderJson.max + (builderJson.max*.2)) - builderJson.Hombres[key];
                        })
                    }*/,
        {
          type: 'bar',
          stack: 'Mujer',
          name: 'Mujeres',
          itemStyle: { color: '#c23531' },
          z: 3,
          xAxisIndex: 1,
          yAxisIndex: 1,
          label: {
            normal: {
              position: 'right',
              show: true,
            },
          },
          data: Object.keys(builderJson['Mujeres']).map(function (key) {
            return builderJson['Mujeres'][key];
          }),
        } /*,{
                        type: 'bar',
                        stack: 'Mujer',
                        silent: true,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: '#eee'
                            }
                        },
                        data: Object.keys(builderJson.Mujeres).map(function (key) {
                            return (builderJson.max + (builderJson.max*.2))  - builderJson.Mujeres[key];
                        })
                    }*/,
      ];
      //opt.dataset.source = newds;
      this.options = opt; //push({options: opt, view: {cols: this.wconfig.config.cols/2, rows: this.wconfig.config.rows} });
      //              }
      j++;
      //            }
      let f = () => {
        if (!this.echartsInstance) setTimeout(f, 500);
        else this.echartsInstance.setOption(this.options);
      };
      f();
      this.seriesLoaded.emit('pyramid');
    }
  }
  onChartInit(e) {
    this.echartsInstance = e;
  }
}
