<h2>Incluir en búsquedas favoritas</h2>
<div class="input_container">
  <mat-form-field>
    <input
      required
      #inputText
      matInput
      [(ngModel)]="name"
      placeholder="Nombre de la búsqueda favorita..."
      (keyup.enter)="addFavSearch()"
    />
  </mat-form-field>
</div>
<button
  *ngIf="name && name.length > 0"
  type="submit"
  (click)="addFavSearch()"
  mat-dialog-close
  [disabled]="formisvalid"
  mat-button
>
  Guardar
</button>
