import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  getComputedStyle(variable: string): string {
    const styles = getComputedStyle(document.documentElement);
    return styles.getPropertyValue(variable);
  }

  removeDiacritics(text: string) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  castAbstractControlToFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  scrollSelectPanelToTop(): void {
    const selectPanel = document.getElementsByClassName('select-panel-multiple-options')[0];

    if (selectPanel) {
      const interval$ = interval(0).subscribe(() => {
        setTimeout(() => interval$.unsubscribe(), 500);
        selectPanel.scrollTo(0, 0);
      });
    }
  }

  getRandomString(minLength, maxLength) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  extendArray<T>(array: T[], x: number): T[] {
    const result: T[] = [];

    for (let i = 0; i < x; i++) {
      result.push(...array);
    }

    return result;
  }

  capitalizeFirstLetterOfEachWord(str: string): string {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }
}
