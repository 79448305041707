import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL, environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class SpiderService {
  static readonly spiderUrl = `${URL}spiders`;

  constructor(private http: HttpClient) {}

  getLatestSpiders() {
    return this.http.get(`${SpiderService.spiderUrl}/latest`);
  }

  getLatestSpidersById(payload) {
    return this.http.post(`${SpiderService.spiderUrl}/latest`, payload);
  }
}
