import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';

import { environment, FRONTURL } from '@env/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import _moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InitiativeStateService } from '../../store/initiative/initiative.service';

export interface ReportDialog {
  name: string;
  searchs: string[];
}

@Component({
  selector: 'manual-form-dialog',
  templateUrl: './manual-form.dialog.html',
  styleUrls: ['./manual-form.dialog.scss'],
})
export class ManualFormDialog implements OnInit {
  readonly LOGOURL: string = FRONTURL + environment.logoMail;
  formGroup: FormGroup;

  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;

  constructor(
    private _initiativeStateService: InitiativeStateService,
    public dialogRef: MatDialogRef<ManualFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ReportDialog
  ) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      id: new FormControl(''),
      title: new FormControl('', [Validators.required]),
      section: new FormControl(''),
      myfilename: new FormControl('Selecione archivo', [Validators.required]),
    });

    if (this.data) {
      this.formGroup.patchValue(this.data);
    }
  }

  get title() {
    return this.formGroup.get('title');
  }

  get section() {
    return this.formGroup.get('section');
  }

  get myfilename() {
    return this.formGroup.get('myfilename');
  }

  addNorm() {
    if (this.formGroup.invalid) return;
    this._initiativeStateService.addCustomInitiative(this.formGroup.value);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.myfilename.setValue('');
      Array.from(fileInput.target.files).forEach((file: File) => {
        this.myfilename.setValue(file.name);
      });

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          // Return Base64 Data URL
          const imgBase64Path = e.target.result;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);

      // Reset File Input to Selct Same file again
      this.uploadFileInput.nativeElement.value = '';
    } else {
      this.myfilename.setValue('Seleccionar archivo');
    }
  }
}
