import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
  selector: 'ng-grid',
  templateUrl: './ng-grid.component.html',
  styleUrls: ['./ng-grid.component.css'],
})
export class NgGridComponent extends BaseChartComponent implements OnInit, AfterViewInit {
  items: Array<any> = [];

  ngOnInit() {}

  getRequest() {
    return { uri: this.wconfig.config['uri'] };
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  refresh(config, response) {
    if (response && response.data) {
      let rdata = [...response.data],
        rheader = [...response.header],
        config = this.wconfig.config;
      this.items = [];
      for (var row = rdata.length - 1; row >= 0; row--)
        for (
          var col = config['slice'] ? config['slice'][1] || rdata[row].length - 1 : rdata[row].length - 1;
          col >= config['slice'] ? config['slice'][0] : 0;
          col--
        ) {
          this.items.push({
            title: rheader[col],
            data: rdata[row][col],
          });
        }
    }
    this.seriesLoaded.emit('ng-grid');
  }
}
