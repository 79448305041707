<div class="container">
  <!-- <p class="title">Buscador de subvenciones - {{ id }}</p> -->
  <div class="loader" *ngIf="loading">
    <mat-spinner [diameter]="32"></mat-spinner>
    <p>Cargando datos...</p>
  </div>
  <div class="container-card" [ngClass]="{ 'container-card-next-generation': subvention?.next_generation }">
    <div class="header" *ngIf="!loading && subvention">
      <img src="assets/imgs/europe-flag.svg" class="prefix-img" *ngIf="subvention.next_generation" />
      <p>{{ subvention.titulo }}</p>
      <button mat-icon-button (click)="saveItem()" [ngClass]="{ favorite: subvention.favorito }">
        <mat-icon>folder_special</mat-icon>
      </button>
    </div>
    <div class="content" *ngIf="!loading && subvention">
      <div class="sections">
        <div class="section">
          <mat-icon>home</mat-icon>
          <div class="content">
            <p class="name">Órgano convocante</p>
            <p class="value">{{ subvention.organo }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>public</mat-icon>
          <div class="content">
            <p class="name">Ámbito geográfico</p>
            <p class="value">{{ subvention.ambito }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>event</mat-icon>
          <div class="content">
            <p class="name">Fecha de último registro</p>
            <p class="value">{{ subvention.fecha_publicacion | date }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>event</mat-icon>
          <div class="content">
            <p class="name">Plazo</p>
            <p class="value" *ngIf="subvention.fecha_apertura && subvention.fecha_cierre">
              {{ subvention.fecha_apertura | date }} - {{ subvention.fecha_cierre | date }}
            </p>
            <p class="value" *ngIf="!subvention.fecha_apertura && subvention.fecha_cierre">
              - / {{ subvention.fecha_cierre | date }}
            </p>
            <p class="value" *ngIf="subvention.fecha_apertura && !subvention.fecha_cierre">
              {{ subvention.fecha_apertura | date }} / -
            </p>
            <p class="value" *ngIf="!subvention.fecha_apertura && !subvention.fecha_cierre">n/d</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>label_important</mat-icon>
          <div class="content">
            <p class="name">Área de gasto</p>
            <p class="value">{{ subvention.resumen.finalidad }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>window</mat-icon>
          <div class="content">
            <p class="name">Sector económico</p>
            <p class="value">{{ subvention.resumen.sector_economico }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>euro</mat-icon>
          <div class="content">
            <p class="name">Presupuesto</p>
            <p class="value">{{ subvention.presupuesto | number }} €</p>
          </div>
        </div>
        <div class="section" *ngIf="subvention.ayuda_max">
          <img src="assets/imgs/SVG/euro_max.svg" />
          <div class="content">
            <p class="name">Ayuda máxima a recibir</p>
            <p class="value" *ngIf="subvention.ayuda_max">{{ subvention.ayuda_max | number }} €</p>
          </div>
        </div>
        <div class="section" *ngIf="subvention.presupuesto_min">
          <img src="assets/imgs/SVG/euro_min.svg" />
          <div class="content">
            <p class="name">Presupuesto mínimo del proyecto</p>
            <p class="value" *ngIf="subvention.presupuesto_min">{{ subvention.presupuesto_min | number }} €</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>people</mat-icon>
          <div class="content">
            <p class="name">Beneficiarios</p>
            <p class="value">{{ subvention.resumen.beneficiario.join(', ') }}</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="section section-long">
          <mat-icon>info</mat-icon>
          <div class="content">
            <p class="name">Información adicional</p>
            <a class="value" *ngIf="subvention.base_reg" [href]="subvention.base_reg" target="_blank"
              >Bases reguladoras</a
            >
            <a class="value" *ngIf="subvention.sede" [href]="subvention.sede" target="_blank">Sede electrónica</a>
            <a class="value" *ngIf="subvention.url" [href]="subvention.url" target="_blank" style="white-space: normal">
              BDNS {{ subvention.bdns }}
            </a>
          </div>
        </div>
        <div class="divider"></div>
        <div class="section section-long">
          <mat-icon>description</mat-icon>
          <div class="content">
            <p class="name">Documentos</p>
            <p class="value" style="max-width: 100%" *ngFor="let document of subvention.documentos">
              {{ document.titulo }}: <a [href]="document.url" target="_blank">Ver documento</a>
            </p>
            <p *ngIf="subvention.documentos.length === 0">- Sin documentos -</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="section section-long">
          <mat-icon>topic</mat-icon>
          <div class="content" style="width: 100%">
            <p class="name">Convocatoria</p>
            <div
              class="value"
              style="max-width: 100%"
              *ngFor="let announcement of subvention.convocatoria; let i = index"
            >
              <p class="value" style="max-width: 100%">{{ announcement.titulo }}</p>
              <p class="value" style="max-width: 100%">- {{ announcement.diario }} -</p>
              <a [href]="announcement.url" target="_blank" style="max-width: 100%; margin-left: 0">Ver convocatoria</a>
              <p style="max-width: 100%; font-weight: 400">{{ announcement.date | date }}</p>
              <div class="divider" *ngIf="i < subvention.convocatoria.length - 1"></div>
            </div>
            <p *ngIf="subvention.convocatoria.length === 0">- Sin convocatorias -</p>
            <a *ngIf="subvention.concesiones_url" [href]="subvention.concesiones_url" target="_blank"
              >Ver concesiones</a
            >
          </div>
        </div>
      </div>
      <!-- <button mat-button color="primary" (click)="downloadSummary()">
        <mat-icon>download</mat-icon>
        Descargar resumen
      </button> -->
    </div>
    <div class="divider"></div>
    <div class="section section-long" style="padding: 16px; background: white">
      <mat-icon>inventory</mat-icon>
      <div class="content" style="width: 100%">
        <p class="name">Minimis</p>
        <div class="value" style="max-width: none; max-height: 550px">
          <table mat-table [dataSource]="minimisDataSource">
            <ng-container matColumnDef="numeroConvocatoria">
              <th mat-header-cell *matHeaderCellDef>Código BDNS</th>
              <td mat-cell *matCellDef="let row">{{ row.numeroConvocatoria }}</td>
            </ng-container>
            <ng-container matColumnDef="convocante">
              <th mat-header-cell *matHeaderCellDef>Convocante</th>
              <td mat-cell *matCellDef="let row">{{ row.convocante }}</td>
            </ng-container>
            <ng-container matColumnDef="reglamento">
              <th mat-header-cell *matHeaderCellDef>Reglamento</th>
              <td mat-cell *matCellDef="let row">{{ row.reglamento }}</td>
            </ng-container>
            <ng-container matColumnDef="sectorProducto">
              <th mat-header-cell *matHeaderCellDef>Sector de Producto</th>
              <td mat-cell *matCellDef="let row">{{ row.sectorProducto }}</td>
            </ng-container>
            <ng-container matColumnDef="instrumento">
              <th mat-header-cell *matHeaderCellDef>Instrumento de ayuda</th>
              <td mat-cell *matCellDef="let row">{{ row.instrumento }}</td>
            </ng-container>
            <ng-container matColumnDef="fechaConcesion">
              <th mat-header-cell *matHeaderCellDef>Fecha de concesion</th>
              <td mat-cell *matCellDef="let row">{{ row.fechaConcesion | date }}</td>
            </ng-container>
            <ng-container matColumnDef="fechaRegistro">
              <th mat-header-cell *matHeaderCellDef>Fecha de registro</th>
              <td mat-cell *matCellDef="let row">{{ row.fechaRegistro | date }}</td>
            </ng-container>
            <ng-container matColumnDef="beneficiario">
              <th mat-header-cell *matHeaderCellDef>Beneficiario</th>
              <td mat-cell *matCellDef="let row">{{ row.beneficiario }}</td>
            </ng-container>
            <ng-container matColumnDef="sectorActividad">
              <th mat-header-cell *matHeaderCellDef>Sector de actividad NACE</th>
              <td mat-cell *matCellDef="let row">{{ row.sectorActividad || '' }}</td>
            </ng-container>
            <ng-container matColumnDef="ayudaEquivalente">
              <th mat-header-cell *matHeaderCellDef>Ayuda equivalente</th>
              <td mat-cell *matCellDef="let row">
                <p style="min-width: 68px; margin: 0">{{ row.ayudaEquivalente | number: '1.2-2' }} €</p>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <th
              scope="col"
              mat-row
              *matNoDataRow
              class="no-data-row"
              style="display: flex; align-items: center; gap: 12px; padding: 16px; min-width: 120px"
            >
              <mat-spinner [diameter]="24" *ngIf="loadingMinimis"></mat-spinner>
              <p style="margin: 0" *ngIf="!loadingMinimis && minimisDataSource.data.length === 0">No hay datos</p>
            </th>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            (page)="minimisPageEvent($event)"
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <!-- <button mat-flat-button class="return-button" (click)="return()">
    <mat-icon>chevron_left</mat-icon>
    <p>Volver</p>
  </button> -->
</div>
