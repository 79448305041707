import { CommonModule, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEsp } from '@app/modules/documents/mat-paginator-int';
import { WindowRef } from '@app/modules/documents/services/window.ref.service';
import { MappingIDPipe } from '@app/pipes/mapping-id.pipe';
import { MappingPipe } from '@app/pipes/mapping.pipe';
import { TendersRoutingModule } from './tenders-routing.module';
import { TendersComponent } from './tenders.component';
import { TendersHomeComponent } from './tenders-home/tenders-home.component';
import { TendersSearchComponent } from './tenders-search/tenders-search.component';
import { TendersAlertsComponent } from './tenders-alerts/tenders-alerts.component';
import { TendersFavoritesComponent } from './tenders-favorites/tenders-favorites.component';
import { TendersSavedSearchsComponent } from './tenders-saved-searchs/tenders-saved-searchs.component';
import { TendersSupportComponent } from './tenders-support/tenders-support.component';
import { TendersSearchSaveSearchDialogComponent } from './tenders-search/tenders-search-save-search-dialog/tenders-search-save-search-dialog.component';
import { TendersSearchFiltersComponent } from './tenders-search/tenders-search-filters/tenders-search-filters.component';
import { TendersSearchDetailComponent } from './tenders-search/tenders-search-detail/tenders-search-detail.component';
import { TendersAlertsEditDialogComponent } from './tenders-alerts/tenders-alerts-edit-dialog/tenders-alerts-edit-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TendersSearchFiltersCPVSComponent } from './tenders-search/tenders-search-filters/tenders-search-filters-cpvs/tenders-search-filters-cpvs.component';
import { TendersSearchFiltersOrganismComponent } from './tenders-search/tenders-search-filters/tenders-search-filters-organism/tenders-search-filters-organism.component';
import { SharedModule } from '@app/modules/shared/shared.module';

registerLocaleData(es);

@NgModule({
  imports: [CommonModule, SharedModule, TendersRoutingModule],
  declarations: [
    TendersComponent,
    TendersHomeComponent,
    TendersSearchComponent,
    TendersAlertsComponent,
    TendersAlertsEditDialogComponent,
    TendersFavoritesComponent,
    TendersSavedSearchsComponent,
    TendersSupportComponent,
    TendersSearchSaveSearchDialogComponent,
    TendersSearchFiltersComponent,
    TendersSearchDetailComponent,
    TendersSearchFiltersCPVSComponent,
    TendersSearchFiltersOrganismComponent,
  ],
  exports: [TendersSearchFiltersComponent],
  providers: [
    WindowRef,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    MappingPipe,
    MappingIDPipe,
  ],
})
export class TendersModule {}
