import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { GeoService } from '@app/services/geo.service';
import { UtilsService } from '@app/services/utils.service';
import rainbowColorsJson from '@assets/rainbow-colors.json';
import * as echarts from 'echarts';

@Component({
  selector: 'app-business-statistics-section-detail-graph',
  templateUrl: './business-statistics-section-detail-graph.component.html',
  styleUrls: ['./business-statistics-section-detail-graph.component.scss'],
})
export class BusinessStatisticsSectionDetailGraphComponent implements OnChanges {
  @Input() data?: any;
  @Input() page: 'home' | 'detail';
  @Input() themeId?: number;

  rainbowColors: string[] = this.utilsService.extendArray(rainbowColorsJson, 10);
  options;
  dates = [];
  form: FormGroup;
  canUseMap;
  showGraph = false;

  // First string -> Back label. Second string -> Front label to replace
  communityMap = new Map<string, string>([
    ['Asturias (Principado de)', 'Principado de Asturias'],
    ['Balears (Illes)', 'Islas Baleares'],
    ['Canarias', 'Canarias'],
    ['Cantabria', 'Cantabria'],
    ['CastillaLa Mancha', 'Castilla - La Mancha'],
    ['Comunitat Valenciana', 'Comunidad Valenciana'],
    ['Extremadura', 'Extremadura'],
    ['Galicia', 'Galicia'],
    ['Madrid (Comunidad de)', 'Comunidad de Madrid'],
    ['Navarra (Comunidad Foral de)', 'Comunidad Foral de Navarra'],
    ['Rioja (La)', 'La Rioja'],
    ['Ceuta', 'Ceuta'],
    ['Melilla', 'Melilla'],
    ['Cataluña', 'Cataluña'],
    ['Murcia (Región de)', 'Región de Murcia'],
    ['País Vasco', 'País Vasco'],
    ['Andalucía', 'Andalucía\n'],
    ['Aragón', 'Aragón'],
    ['Castilla y León', 'Castilla y León'],
  ]);

  constructor(
    private utilsService: UtilsService,
    private router: Router,
    private fb: FormBuilder,
    private authStateService: AuthStateService,
    private geoservice: GeoService
  ) {
    this.form = this.fb.group({
      date: [null, []],
    });

    this.form.controls.date.valueChanges.subscribe(() => {
      this.setDates();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data.currentValue) {
      this.form.patchValue({
        date: null,
      });

      this.handleGraphTypes();
    }
  }

  handleGraphTypes(): void {
    // this.data.graph_type = 'map';
    this.setCanUseMap();

    switch (this.data.data.graph_type ?? this.data.graph_type) {
      case 'lines' || 'bars':
        this.setLineBarData();
        break;
      case 'map':
        this.setMapData();
        break;
    }

    this.showGraph = true;
  }

  setLineBarData(): void {
    const userEchartsColors =
      this.authStateService.userConfig.interface_config.company.color_palette?.echarts?.split(',') ??
      this.rainbowColors;

    this.options = {
      title: {
        text: this.page === 'home' ? this.data.categoria : this.page === 'detail' ? this.data.indicator : '',
        textStyle: {
          width: '90%',
          overflow: 'truncate',
          fontSize: '17',
          fontWeight: 600,
          color: '#111827',
        },
        subtext:
          this.page === 'home'
            ? `Fuente: ${this.data.data.fuente}` ?? ''
            : this.page === 'detail'
            ? this.data.data.map((d) => d.title).join(', ')
            : '',
        subtextStyle: {
          fontSize: '13',
        },
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        show:
          (this.page === 'home' && this.data.data.data.length > 1) ||
          (this.page === 'detail' && this.data.data.length > 1),
        top: this.page === 'home' ? '370' : this.page === 'detail' ? '560' : '',
        width: 'auto',
        left: '40',
        height: 'auto',
        orient: 'horizontal',
        itemGap: 10,
        type: 'scroll',
        maxHeight: '50',
        icon: 'roundRect',
        data:
          this.page === 'home'
            ? this.data.data.data.map((d) => d.title)
            : this.page === 'detail'
            ? this.data.data.map((d) => d.title)
            : '',
      },
      dataZoom: [
        {
          type: 'slider',
          top:
            this.page === 'home'
              ? this.data.data.data.length > 1
                ? '315'
                : '365'
              : this.page === 'detail'
              ? this.data.data.length > 1
                ? '510'
                : '560'
              : '',
          fillerColor: '#00000015',
          backgroundColor: '#00000025',
          areaStyle: {
            color: '#00000025',
          },
          handleStyle: {
            color: '#00000090',
          },
        },
      ],
      grid: {
        left: '3%',
        right: '4%',
        bottom:
          (this.page === 'home' && this.data.data.data.length > 1) ||
          (this.page === 'detail' && this.data.data.length > 1)
            ? '110'
            : '55',
        containLabel: true,
      },
      toolbox: {
        showTitle: false,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Descargar Imagen',
          },
        },
      },
      xAxis: {
        type: 'category',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        data:
          this.page === 'home'
            ? this.data.data.data[0].serie.map((s) => s.name)
            : this.page === 'detail'
            ? this.data.data[0].serie.map((s) => s.name)
            : '',
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series:
        this.page === 'home'
          ? this.data.data.data.map((d, i) => {
              const data = d.serie.map((s) => s.valor);
              return {
                type: this.getEchartGraphType(this.data.data.graph_type),
                name: d.title,
                color: [[...userEchartsColors, ...this.rainbowColors][i * 4]],
                areaStyle: {
                  opacity: 0.05,
                },
                data,
              };
            })
          : this.page === 'detail'
          ? this.data.data.map((d, i) => {
              return {
                type: this.getEchartGraphType(this.data.graph_type),
                name: d.title,
                color: [[...userEchartsColors, ...this.rainbowColors][i * 4]],
                areaStyle: {
                  opacity: 0.05,
                },
                data: d.serie.map((s) => s.valor),
              };
            })
          : [],
    };
  }

  setMapData(): void {
    setTimeout(() => {
      this.geoservice.geo('spain-communities').subscribe((geo) => {
        echarts.registerMap('Spain-Communities', geo, {
          Canarias: {
            left: -14,
            top: 35,
            width: 5,
          },
        });

        this.setMapOptions();
      });
    }, 0);
  }

  setMapOptions(): void {
    const data = [];

    this.data.data
      .filter((s) => s.title.toLowerCase() !== 'total')
      .forEach((d) => {
        d.serie
          .filter((s) => s.name === this.form.controls.date.value)
          .forEach((s) => {
            data.push({
              name: this.replaceCommunity(d.title),
              value: s.valor,
            });
          });
      });

    this.options = {
      title: {
        text: this.page === 'home' ? this.data.categoria : this.page === 'detail' ? this.data.indicator : '',
        textStyle: {
          width: '90%',
          overflow: 'truncate',
          fontSize: '17',
          fontWeight: 600,
          color: '#111827',
        },
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        // formatter: function (params) {
        //   let value = (params.value + "").split("."),
        //     _value =
        //       value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, "$1,") || 0;
        //   return params.seriesName + "<br/>" + params.name + ": " + _value;
        // },
      },
      visualMap: {
        right: 'right',
        min: Math.min(...data.map((d) => d.value)),
        max: Math.max(...data.map((d) => d.value)),
        text: ['Mayor', 'Menor'],
        calculable: true,
      },
      toolbox: {
        show: true,
        //orient: 'vertical',
        right: 'right',
        top: 'top',
        feature: {
          saveAsImage: {
            show: true,
            title: 'Descargar Imagen',
          },
        },
      },
      series: [
        {
          name: 'Datos',
          type: 'map',
          roam: false,
          map: 'Spain-Communities',
          height: 300,
          emphasis: {
            label: {
              show: true,
            },
          },
          data,
        },
      ],
    };
  }

  getEchartGraphType(graphType: string): string {
    switch (graphType) {
      case 'lines':
        return 'line';
      case 'bars':
        return 'bar';
      case 'map':
        return 'map';
      default:
        return '';
    }
  }

  setDates(): void {
    if (this.page === 'detail' && this.canUseMap) {
      this.dates = this.data.data[0].serie.map((s) => s.name);

      if (!this.form.controls.date.value) {
        this.form.controls.date.setValue(this.dates[0]);
      }

      if (this.data.graph_type === 'map') {
        this.setMapData();
      }
    }
  }

  replaceCommunity(community: string): string {
    return this.communityMap.get(community) || '';
  }

  setCanUseMap(): void {
    if (this.page === 'detail') {
      this.canUseMap = this.data.data.some((d) => this.communityMap.has(d.title));
      this.setDates();
    }
  }

  changeGraph(): void {
    if (this.data.graph_type === 'map') {
      this.data.graph_type = 'lines';
      this.setLineBarData();
    } else {
      this.data.graph_type = 'map';
      this.setMapData();
    }
  }

  navigateToDetail(): void {
    this.router.navigate(['business-statistics', this.themeId, this.data.id_categoria]);
  }
}
