import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchRequest } from './search.request.service';
import { environment } from '@env/environment';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { DocumentService } from '@app/modules/documents/services/document.service';
import { delay, take } from 'rxjs/operators';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

export interface SearchServiceResult {
  x: any[];
  hits: any[];
  total: number;
}

export interface QueryParams {
  docTopics?: any[];
  docTopicsEu?: any[];
  spiderIds?: any[];
  docTopicsRegion?: any[];
  docContexts?: any[];
  docTypes?: string[];
  text?: string;
  title?: boolean;
  body?: boolean;
  dateParsed_start?: string;
  dateParsed_end?: string;
  from?: number;
  size?: number;
  sort?: string[];
  aggregations?: any;
  embeds?: boolean;
  score?: number;
  candidates?: number;
  searchType?: 'literal' | 'semantic';
}

@Injectable({
  providedIn: 'root',
})
export class SearchService implements QueryParams {
  docTopics: any[] = [];
  spiderIds: any[] = [];
  docTopicsRegion: any[] = [];
  docContexts: any[] = [];
  docTypes: string[] = [];
  text: string;
  title: boolean;
  body: boolean;
  dateParsed_start: string;
  dateParsed_end: string;
  from: number = 0;
  size: number = 30;
  sort: string[];
  aggregations: any;
  embeds: boolean;
  score: number;
  candidates: number;
  searchType: 'literal' | 'semantic';

  static readonly itemsUrl = environment.documentSearchUrl;

  constructor(
    private http: HttpClient,
    private searchRequest: SearchRequest,
    private injector: Injector,
    private documentService: DocumentService,
    private router: Router
  ) {
    //this.loadParamsFromLocalStorage();
  }

  private mapSources(queryParams: QueryParams): QueryParams {
    let request: any = {};
    Object.entries(queryParams).forEach(([key, value]) => {
      if (key == 'spiderIds' || key == 'docTopics' || key == 'docTopicsRegion' || key == 'docContexts')
        request[key] = value.map((item) => item.id);
      else request[key] = value;
    });

    return request;
  }

  private parseSources(queryParams: any): any {
    let parsedQuery: any = queryParams;
    parsedQuery.data.spiderIds = parsedQuery.data.spiderIds.map((item) => {
      return item.id;
    });
    parsedQuery.data.docTopics = parsedQuery.data.docTopics.map((item) => {
      return item.id;
    });
    parsedQuery.data.docTopicsRegion = parsedQuery.data.docTopicsRegion.map((item) => {
      return item.id;
    });
    parsedQuery.data.docContexts = parsedQuery.data.docContexts.map((item) => item.id);
    return parsedQuery;
  }

  public getAll(
    queryParams: QueryParams,
    sort?: string,
    order?: string,
    page?: number,
    types?: string[]
  ): Observable<SearchServiceResult> {
    let requestParams: QueryParams = this.mapSources(queryParams);

    if (sort != null) {
      requestParams.sort = this.getSortFilterMapped(sort);
      requestParams.from = page * requestParams.size;
    }

    if (order === '') delete requestParams.sort;

    if (isNaN(requestParams.from)) requestParams.from = 0;

    requestParams.docTypes = types;

    switch (this.injector.get(AuthStateService).userConfig.interface_config.categories) {
      case 'legacy':
        requestParams.docTopicsEu = [];
        break;
      case 'eurovoc':
        requestParams.docTopicsEu = requestParams.docTopics;
        requestParams.docTopics = [];
        break;
    }

    if (this.router.url.includes('vigilancia/') || this.router.url.includes('europe/')) {
      if (this.searchType === 'literal' || !requestParams.sort.includes('-_score')) {
        requestParams.title = true;
        delete requestParams.embeds;
        delete requestParams.body;
        delete requestParams.score;
        delete requestParams.candidates;
      } else if (this.searchType === 'semantic') {
        requestParams.embeds = true;
        delete requestParams.title;
        delete requestParams.body;
      }
    } else {
      delete requestParams.embeds;
      delete requestParams.title;
      delete requestParams.body;
      delete requestParams.score;
      delete requestParams.candidates;
    }

    delete requestParams.searchType;

    return this.http.post<SearchServiceResult>(
      this.router.url.includes('vigilancia/') || this.router.url.includes('europe/')
        ? 'https://ia.brainyinteligencia.com/api/v1/regulatory/search'
        : environment.documentSearchUrl,
      requestParams,
      httpOptions
    );
  }

  public getFavorites(): Observable<any> {
    return this.http.get<any>(environment.favDocsUrl, httpOptions);
  }

  public saveFavorite(name: string, id: string): Observable<any> {
    this.documentService.refreshToolbarCounterFavoriteDocuments$.pipe(delay(250), take(1)).subscribe((counter) => {
      this.documentService.refreshToolbarCounterFavoriteDocuments$.next(counter + 1);
    });
    let postparam = { name: name, docId: id };
    return this.http.post<any>(environment.favDocsUrl, postparam, httpOptions);
  }

  public deleteFavorite(id: string): Observable<any> {
    this.documentService.refreshToolbarCounterFavoriteDocuments$.pipe(delay(250), take(1)).subscribe((counter) => {
      this.documentService.refreshToolbarCounterFavoriteDocuments$.next(counter - 1);
    });
    return this.http.delete<any>(environment.favDocsUrl + id + '/', httpOptions);
  }

  public getFavoriteSearch(request: {
    tipo:
      | 'documents'
      | 'clipping'
      | 'regulatorio'
      | 'vigilancia'
      | 'europe'
      | 'europe-union'
      | 'licitaciones'
      | 'ayudas_y_subvenciones';
  }): Observable<any> {
    return this.http.get<any>(`${environment.documentFavoritesUrl}?tipo=${request.tipo}`, httpOptions);
  }

  public saveFavoriteSearch(search: any): Observable<any> {
    this.documentService.refreshToolbarCounterFavorites$.pipe(delay(250), take(1)).subscribe((counter) => {
      this.documentService.refreshToolbarCounterFavorites$.next(counter + 1);
    });

    if (this.router.url.includes('vigilancia/') || this.router.url.includes('europe/')) {
      if (this.searchType === 'literal') {
        search.data.title = true;
        delete search.data.embeds;
        delete search.data.body;
        delete search.data.score;
        delete search.data.candidates;
      } else if (this.searchType === 'semantic') {
        search.data.embeds = true;
        delete search.data.title;
        delete search.data.body;
      }
    } else {
      delete search.data.embeds;
      delete search.data.title;
      delete search.data.body;
      delete search.data.score;
      delete search.data.candidates;
    }

    search.data.sort = this.getSortFilterMapped(search.data.sort);

    return this.http.post<any>(environment.documentFavoritesUrl, this.parseSources(search), httpOptions);
  }

  public deleteFavoriteSearch(id): Observable<any> {
    this.documentService.refreshToolbarCounterFavorites$.pipe(delay(250), take(1)).subscribe((counter) => {
      this.documentService.refreshToolbarCounterFavorites$.next(counter - 1);
    });
    return this.http.delete(environment.documentFavoritesUrl + id + '/', httpOptions);
  }

  public saveAlertSearch(id, search: any): Observable<any> {
    this.documentService.refreshToolbarCounterAlerts$.pipe(delay(250), take(1)).subscribe((counter) => {
      this.documentService.refreshToolbarCounterAlerts$.next(counter + 1);
    });
    return this.http.put<any>(environment.documentFavoritesUrl + id + '/', search, httpOptions);
  }

  public request(queryConfig: any): Observable<any> {
    return this.http.post(environment.documentSearchUrl, queryConfig, httpOptions);
  }

  public objectSetup(object: QueryParams) {
    this.text = object.text;
    this.title = object.title;
    this.body = object.body;
    this.dateParsed_start = object.dateParsed_start;
    this.dateParsed_end = object.dateParsed_end;
    this.spiderIds = object.spiderIds;
    this.docTopics = object.docTopics;
    this.docTopicsRegion = object.docTopicsRegion;
    this.docContexts = object.docContexts;
    this.embeds = object.embeds;
    this.candidates = object.candidates;
    this.score = object.score;
    this.sort = object.sort;
    this.searchType = object.searchType;
  }

  private getQueryParamsOnly(): QueryParams {
    let queryParams: QueryParams = {
      docTopics: this.docTopics,
      spiderIds: this.spiderIds,
      docTopicsRegion: this.docTopicsRegion,
      docContexts: this.docContexts,
      docTypes: this.docTypes,
      text: this.text,
      title: this.title,
      body: this.body,
      dateParsed_start: this.dateParsed_start,
      dateParsed_end: this.dateParsed_end,
      from: this.from,
      size: this.size,
      sort: this.sort,
      aggregations: this.aggregations,
      embeds: this.embeds,
      score: this.score,
      candidates: this.candidates,
      searchType: this.searchType,
    };

    return queryParams;
  }

  private loadParamsFromLocalStorage(): void {
    let params: QueryParams = JSON.parse(localStorage.getItem('params'));

    if (params !== null) this.objectSetup(params);
  }

  public saveParamsInLocalStorage(): void {
    localStorage.setItem('params', JSON.stringify(this.getQueryParamsOnly()));
  }

  public clearAllParamsFromLocalStorage() {
    localStorage.removeItem('params');
    this.loadParamsFromLocalStorage();
  }

  resetQuery(): QueryParams {
    this.clearAllParamsFromLocalStorage();
    let query: QueryParams = {
      size: this.size,
      text: '',
      title: true,
      body: null,
      spiderIds: [],
      docTopics: [],
      docTopicsRegion: [],
      docContexts: [],
      dateParsed_start: '',
      dateParsed_end: '',
      embeds: false,
      score: 0.6,
      candidates: 30,
      searchType: 'literal',
    };

    return query;
  }

  public buildQuery(): QueryParams {
    let query: QueryParams = {
      size: this.size,
      text: this.text || '',
      title: this.title || true,
      body: this.body || null,
      spiderIds: this.spiderIds || [],
      docTopics: this.docTopics || [],
      docContexts: this.docContexts || [],
      docTopicsRegion: this.docTopicsRegion || [],
      dateParsed_start: this.dateParsed_start || '',
      dateParsed_end: this.dateParsed_end || '',
      embeds: this.embeds || false,
      sort: this.sort,
      score: this.score !== null && this.score !== undefined ? this.score : 0.6,
      candidates: this.candidates || 30,
      searchType: this.searchType || 'literal',
    };

    return query;
  }

  private filteredQuery(): QueryParams {
    let cleanQuery: QueryParams = {};

    Object.entries(this.buildQuery()).forEach(([key, value]) => {
      if (value && value !== null && value.length !== 0) {
        cleanQuery[key] = value;
      }
    });

    return cleanQuery;
  }

  public emitParams(queryParams?: QueryParams): void {
    let emitData = typeof queryParams !== 'undefined' ? queryParams : this.filteredQuery();
    this.searchRequest.searchParams.emit(emitData);
  }

  public search(text: string, starting: string, ending: string) {
    const qry: QueryParams = {};
    qry.text = text;
    qry.size = 30;
    qry.dateParsed_start = starting;
    qry.dateParsed_end = ending;

    let emitData = typeof qry !== 'undefined' ? qry : this.filteredQuery();
    this.searchRequest.searchParams.emit(emitData);
  }

  getCategories(topicGroupId: number): Observable<any> {
    return this.http.get<any>(`${environment.sectorReportsCategories}/${topicGroupId}`);
    // return of(this.getFakeCategories()).pipe(delay(1000));
  }

  getSortFilterMapped(sort?: string | number): string[] {
    if (sort == 1) {
      return ['-dateParsed'];
    } else if (sort == 2) {
      return ['dateParsed'];
    } else if (sort == 3) {
      return ['-_score'];
    } else {
      return ['-dateParsed'];
    }
  }

  getSortFilterIdentifier(sort?: string[]): number {
    if (sort && sort.includes('-dateParsed')) {
      return 1;
    } else if (sort && sort.includes('dateParsed')) {
      return 2;
    } else if (sort && sort.includes('-_score')) {
      return 3;
    } else {
      return 1;
    }
  }
}
