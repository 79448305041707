import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DraftStateService } from '@app/modules/documents/store/draft/draft.service';

@Component({
  selector: 'app-articledetail-articles-list',
  templateUrl: './articledetail-articles-list.component.html',
  styleUrls: ['./articledetail-articles-list.component.scss'],
})
export class ArticledetailArticlesListComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public matData: { articleId?: string },
    private matBottomSheetRef: MatBottomSheetRef<ArticledetailArticlesListComponent>,
    public draftStateService: DraftStateService
  ) {}

  setArticle(article: any): void {
    this.matBottomSheetRef.dismiss(article);
  }

  hasDocSelected(category: any): boolean {
    return category.docs.some((doc: any) => this.matData.articleId === doc.id);
  }
}
