<form [formGroup]="filtersForm" style="margin-bottom: 120px">
  <div class="header">
    <p>Filtros</p>
    <button mat-stroked-button color="primary" (click)="resetFilters()">Restablecer filtros</button>
  </div>
  <p class="loader" *ngIf="applyingFilters">
    <mat-spinner [diameter]="20" *ngIf="loading || applyingFilters"></mat-spinner>
    Aplicando filtros...
  </p>
  <mat-form-field appearance="fill">
    <mat-label>Buscar por palabra clave</mat-label>
    <input matInput formControlName="texto" />
  </mat-form-field>
  <span *ngIf="filters">
    <div class="section" *ngFor="let filter of filters.filters; let i = index" [formArrayName]="filter.attr">
      <span *ngIf="i < filters.filters.length - 2">
        <span
          *ngIf="
            filter.attr !== 'comunidades' &&
            filter.attr !== 'provincias' &&
            filter.attr !== 'sector_economico' &&
            filter.attr !== 'fondos' &&
            filter.attr !== 'finalidad'
          "
        >
          <p class="title">{{ filter.seccion }}</p>
          <div
            class="checkboxes"
            *ngFor="let control of filtersForm.controls[filter.attr].controls"
            [formGroup]="castToFormGroup(control)"
          >
            <input type="checkbox" formControlName="checked" />
            <mat-label
              (click)="
                castToFormGroup(control).controls.checked.setValue(!castToFormGroup(control).controls.checked.value)
              "
              >{{ control.get('label')?.value }}</mat-label
            >
          </div>
        </span>
      </span>
    </div>

    <span class="section section-dropdowns">
      <div *ngFor="let filter of filters.filters; let i = index">
        <span *ngIf="i < filters.filters.length - 2">
          <span *ngIf="filter.attr === 'fondos'">
            <angular2-multiselect
              fixSearchBug
              fixAccents
              class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
              name="fondos"
              [formControlName]="filter.attr + 'Dropdown'"
              [data]="dropdownFondosList"
              [(ngModel)]="selectedFondosItems"
              [settings]="dropdownFondosSettings"
            ></angular2-multiselect>
          </span>
          <span *ngIf="filter.attr === 'comunidades'">
            <angular2-multiselect
              fixSearchBug
              fixAccents
              class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
              name="comunidades"
              [formControlName]="filter.attr + 'Dropdown'"
              [data]="dropdownComunidadesList"
              [(ngModel)]="selectedComunidadesItems"
              [settings]="dropdownComunidadesSettings"
            ></angular2-multiselect>
          </span>
          <span *ngIf="filter.attr === 'provincias'">
            <angular2-multiselect
              fixSearchBug
              fixAccents
              class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
              name="provincias"
              [formControlName]="filter.attr + 'Dropdown'"
              [data]="dropdownProvinciasList"
              [(ngModel)]="selectedProvinciasItems"
              [settings]="dropdownProvinciasSettings"
            ></angular2-multiselect>
          </span>
          <span *ngIf="filter.attr === 'sector_economico'">
            <angular2-multiselect
              fixSearchBug
              fixAccents
              class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
              name="sector_economico"
              [formControlName]="filter.attr + 'Dropdown'"
              [data]="dropdownSectorEconomicoList"
              [(ngModel)]="selectedSectorEconomicoItems"
              [settings]="dropdownSectorEconomicoSettings"
            ></angular2-multiselect>
          </span>
        </span>
      </div>
    </span>

    <div class="section">
      <mat-accordion>
        <mat-expansion-panel
          [expanded]="advancedFiltersOpened"
          style="overflow: visible"
          (click)="advancedFiltersOpened = !advancedFiltersOpened"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Filtros avanzados</mat-panel-title>
          </mat-expansion-panel-header>
          <span (click)="$event.stopPropagation()">
            <div class="section" *ngFor="let filter of filters.filters; let i = index" [formArrayName]="filter.attr">
              <span *ngIf="i >= filters.filters.length - 2">
                <span *ngIf="filter.attr !== 'finalidad'">
                  <p class="title" [ngStyle]="{ height: advancedFiltersOpened ? 'auto' : 0 }">{{ filter.seccion }}</p>
                  <ng-container *ngFor="let control of filtersForm.controls[filter.attr].controls">
                    <div
                      class="checkboxes"
                      [ngStyle]="{ height: advancedFiltersOpened ? 'auto' : 0 }"
                      [formGroup]="castToFormGroup(control)"
                      *ngIf="control.get('label')?.value"
                    >
                      <input type="checkbox" formControlName="checked" />
                      <mat-label
                        (click)="
                          castToFormGroup(control).controls.checked.setValue(
                            !castToFormGroup(control).controls.checked.value
                          )
                        "
                        >{{ control.get('label')?.value }}</mat-label
                      >
                    </div>
                  </ng-container>
                </span>
              </span>
            </div>
            <span class="section section-dropdowns" [ngStyle]="{ height: advancedFiltersOpened ? 'auto' : 0 }">
              <div *ngFor="let filter of filters.filters; let i = index">
                <span *ngIf="i >= filters.filters.length - 2">
                  <span *ngIf="filter.attr === 'finalidad'">
                    <angular2-multiselect
                      fixSearchBug
                      fixAccents
                      class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
                      name="finalidad"
                      [formControlName]="filter.attr + 'Dropdown'"
                      [data]="dropdownFinalidadList"
                      [(ngModel)]="selectedFinalidadItems"
                      [settings]="dropdownFinalidadSettings"
                    ></angular2-multiselect>
                  </span>
                </span>
              </div>
            </span>
            <mat-form-field class="section" style="width: 100%">
              <mat-label>Fecha de publicación</mat-label>
              <mat-date-range-input [formGroup]="filtersPublishmentDateRangeForm" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="dd/mm/aaaa" />
                <input matEndDate formControlName="end" placeholder="dd/mm/aaaa" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <mat-form-field class="section" style="width: 100%">
              <mat-label>Fecha de finalización del periodo de solicitud</mat-label>
              <mat-date-range-input [formGroup]="filtersFinalizationDateRangeForm" [rangePicker]="picker2">
                <input matStartDate formControlName="start" placeholder="dd/mm/aaaa" />
                <input matEndDate formControlName="end" placeholder="dd/mm/aaaa" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-date-range-picker #picker2></mat-date-range-picker>
            </mat-form-field>
          </span>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </span>
  <div
    class="actions"
    style="
      position: fixed;
      bottom: 0;
      background: white;
      padding: 16px 6px;
      font-size: 15px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding-bottom: 16px;
      width: calc(300px - 16px);
    "
  >
    <button
      mat-flat-button
      color="primary"
      style="width: 100%"
      [disabled]="loading || applyingFilters"
      (click)="applyFilters()"
    >
      <mat-spinner [diameter]="20" *ngIf="loading || applyingFilters"></mat-spinner>
      Aplicar filtros
    </button>
    <button mat-stroked-button style="width: 100%" (click)="toggleMenuFilters()">
      <mat-icon style="font-size: 20px; width: 20px; height: 20px">keyboard_double_arrow_right</mat-icon>
      Ocultar menú de filtros
    </button>
  </div>
</form>
