import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { AppStateService } from '@app/state/app/app.service';
import { filter, map } from 'rxjs/operators';
import { CollectiveAgreementsStateService } from '../store/collective-agreements/collective-agreements.service';
import { Subject } from 'rxjs';
import { Actions } from '@ngxs/store';

interface SidenavItem {
  name: string;
  icon: string;
  path: string;
  selected: boolean;
}

@Component({
  selector: 'app-collective-agreements',
  templateUrl: './collective-agreements.component.html',
  styleUrls: ['./collective-agreements.component.scss'],
})
export class CollectiveAgreementsComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  sidenavItems: SidenavItem[] = [
    {
      name: 'Buscador de convenios colectivos',
      icon: 'search',
      path: 'search',
      selected: false,
    },
  ];
  url = this.router.url;

  constructor(
    public appStateService: AppStateService,
    public authStateService: AuthStateService,
    public collectiveAgreementsStateService: CollectiveAgreementsStateService,
    private actions: Actions,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter((event: unknown): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.slice(1))
      )
      .subscribe(() => {
        this.url = this.router.url;
        this.getSelectedItem();
      });

    this.appStateService.addTitle('Convenios colectivos');

    // if (this.appStateService.sidemenuOpened) {
    //   this.appStateService.toggleSideMenu();
    // }

    this.getSelectedItem();
  }

  ngOnInit(): void {
    // this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetFilters)).subscribe(() => {
    //   setTimeout(() => {
    //     this.router.navigate(['collective-agreements', 'search']);
    //   }, 0);
    // });

    this.collectiveAgreementsStateService.getFilters();
    this.collectiveAgreementsStateService.updateCollectiveAgreementsMenuFilters({
      ...this.collectiveAgreementsStateService.filters,
      openedMenu: this.collectiveAgreementsStateService.filters.openedMenu,
    });

    this.enableCollectiveAgreementsStyles();
  }

  ngOnDestroy(): void {
    this.disableCollectiveAgreementsStyles();

    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  toggleMenuFilters(): void {
    this.collectiveAgreementsStateService.updateCollectiveAgreementsMenuFilters({
      ...this.collectiveAgreementsStateService.filters,
      openedMenu: true,
    });
  }

  getSelectedItem(): void {
    const url = this.router.url.split('/').splice(2);
    this.selectItem(
      this.sidenavItems.find((si) => si.path.startsWith((url.length > 0 ? url[0].split('?')[0] : '') || ''))
    );
  }

  selectItem(sidenavItem: SidenavItem): void {
    this.sidenavItems.map((si) => (si.selected = false));
    sidenavItem.selected = true;
  }

  enableCollectiveAgreementsStyles(): void {
    const toolbar = document.getElementsByTagName('mat-toolbar')[0];
    toolbar.classList.add('mat-toolbar-tenders');
  }

  disableCollectiveAgreementsStyles(): void {
    const toolbar = document.getElementsByTagName('mat-toolbar')[0];
    toolbar.classList.remove('mat-toolbar-tenders');
  }
}
