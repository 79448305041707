<div class="container">
  <div *ngIf="!docID && !s3_file_url" fxLayout="column" fxLayoutAlign="start center" class="no-doc">
    <!-- <img class="brainy-logo" src="{{ LOGOURL }}" alt="Logo" /> -->
    <h2>Selecciona un artículo</h2>
    <p>Pulsa en un artículo para previsualizar su contenido</p>
    <svg width="138" height="124" viewBox="0 0 138 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M70.6741 0.270215L66.1838 1.42953L10.8627 15.7125L6.37244 16.8719C4.19292 17.4372 2.32638 18.845 1.18232 20.7865C0.0382691 22.7279 -0.289866 25.0444 0.269905 27.2278L19.7407 102.82C20.3054 105.002 21.7116 106.871 23.6507 108.016C25.5899 109.162 27.9037 109.49 30.0845 108.93L30.0961 108.927L94.3745 92.3313L94.3861 92.3283C96.5657 91.763 98.4322 90.3551 99.5763 88.4137C100.72 86.4723 101.048 84.1557 100.489 81.9724L81.0178 6.37998C80.4532 4.19788 79.047 2.32913 77.1078 1.18373C75.1687 0.0383172 72.8549 -0.290212 70.6741 0.270215Z"
        fill="#F2F2F2"
      />
      <path
        d="M71.2849 2.64052L65.9665 4.01365L12.3015 17.8691L6.98317 19.2422C5.43107 19.6448 4.10186 20.6473 3.28715 22.0299C2.47243 23.4124 2.23876 25.062 2.63738 26.6169L22.1082 102.209C22.5103 103.763 23.5117 105.094 24.8926 105.91C26.2735 106.725 27.9212 106.959 29.4742 106.56L29.4858 106.557L93.7643 89.9615L93.776 89.9585C95.3281 89.5559 96.6572 88.5533 97.4719 87.1708C98.2866 85.7883 98.5203 84.1386 98.1217 82.5838L78.6508 6.99143C78.2487 5.43752 77.2474 4.10675 75.8665 3.29108C74.4856 2.47541 72.8379 2.24145 71.2849 2.64052Z"
        fill="white"
      />
      <path
        d="M67.2686 27.119L34.7517 35.5144C34.3885 35.6081 34.003 35.5536 33.68 35.3628C33.3569 35.172 33.1228 34.8605 33.0292 34.4969C32.9355 34.1333 32.99 33.7474 33.1806 33.424C33.3711 33.1005 33.6822 32.8662 34.0454 32.7724L66.5623 24.3771C66.9254 24.2837 67.3106 24.3384 67.6334 24.5293C67.9561 24.7201 68.19 25.0314 68.2836 25.3948C68.3772 25.7582 68.3229 26.1439 68.1326 26.4673C67.9422 26.7906 67.6315 27.025 67.2686 27.119Z"
        fill="#F2F2F2"
      />
      <path
        d="M74.1097 30.2878L35.9441 40.1415C35.5809 40.2353 35.1954 40.1808 34.8723 39.99C34.5493 39.7992 34.3152 39.4877 34.2216 39.1241C34.1279 38.7605 34.1824 38.3745 34.3729 38.0511C34.5635 37.7277 34.8746 37.4933 35.2378 37.3996L73.4034 27.5458C73.7666 27.4521 74.1521 27.5066 74.4751 27.6974C74.7982 27.8882 75.0323 28.1997 75.1259 28.5633C75.2196 28.9269 75.1651 29.3128 74.9745 29.6363C74.784 29.9597 74.4728 30.194 74.1097 30.2878Z"
        fill="#F2F2F2"
      />
      <path
        d="M72.6104 47.8552L40.0935 56.2505C39.9137 56.297 39.7265 56.3075 39.5426 56.2815C39.3587 56.2555 39.1817 56.1935 39.0218 56.099C38.8618 56.0045 38.722 55.8794 38.6104 55.7308C38.4987 55.5823 38.4173 55.4131 38.371 55.2331C38.3246 55.053 38.3141 54.8656 38.3401 54.6815C38.366 54.4974 38.428 54.3202 38.5223 54.1601C38.6167 54 38.7417 53.86 38.8901 53.7482C39.0385 53.6364 39.2074 53.555 39.3872 53.5086L71.9041 45.1132C72.084 45.0668 72.2712 45.0563 72.455 45.0823C72.6389 45.1083 72.8159 45.1703 72.9759 45.2648C73.1358 45.3592 73.2756 45.4843 73.3873 45.6329C73.4989 45.7815 73.5803 45.9506 73.6266 46.1307C73.673 46.3107 73.6835 46.4981 73.6575 46.6822C73.6316 46.8663 73.5696 47.0435 73.4753 47.2036C73.3809 47.3638 73.256 47.5037 73.1076 47.6155C72.9592 47.7273 72.7902 47.8088 72.6104 47.8552Z"
        fill="#F2F2F2"
      />
      <path
        d="M79.4505 51.0241L41.2849 60.8779C41.1051 60.9243 40.9179 60.9348 40.734 60.9088C40.5501 60.8828 40.3731 60.8208 40.2132 60.7263C40.0532 60.6318 39.9134 60.5067 39.8018 60.3582C39.6901 60.2096 39.6088 60.0405 39.5624 59.8604C39.516 59.6804 39.5055 59.493 39.5315 59.3089C39.5575 59.1248 39.6194 58.9476 39.7138 58.7874C39.8081 58.6273 39.9331 58.4873 40.0815 58.3756C40.2299 58.2638 40.3988 58.1823 40.5786 58.1359L78.7442 48.2822C79.1074 48.1884 79.4929 48.2429 79.8159 48.4337C80.139 48.6245 80.3731 48.936 80.4667 49.2996C80.5604 49.6632 80.5059 50.0492 80.3153 50.3726C80.1248 50.696 79.8137 50.9304 79.4505 51.0241Z"
        fill="#F2F2F2"
      />
      <path
        d="M77.9512 68.5915L45.4343 76.9869C45.0713 77.0803 44.686 77.0256 44.3631 76.8348C44.0403 76.644 43.8064 76.3326 43.7128 75.9692C43.6192 75.6057 43.6735 75.2199 43.8639 74.8966C44.0543 74.5733 44.3651 74.3389 44.7281 74.2449L77.245 65.8495C77.6082 65.7558 77.9937 65.8103 78.3167 66.0011C78.6397 66.1919 78.8738 66.5034 78.9675 66.867C79.0611 67.2306 79.0067 67.6166 78.8161 67.94C78.6255 68.2634 78.3144 68.4977 77.9512 68.5915Z"
        fill="#F2F2F2"
      />
      <path
        d="M84.7915 71.7605L46.6259 81.6142C46.4461 81.6608 46.2588 81.6715 46.0747 81.6456C45.8907 81.6197 45.7136 81.5577 45.5535 81.4633C45.3935 81.3688 45.2535 81.2437 45.1418 81.0951C45.03 80.9464 44.9486 80.7772 44.9022 80.5971C44.8558 80.4169 44.8453 80.2294 44.8714 80.0452C44.8974 79.861 44.9594 79.6837 45.0539 79.5236C45.1484 79.3634 45.2735 79.2234 45.4221 79.1116C45.5706 78.9999 45.7397 78.9185 45.9197 78.8722L84.0853 69.0185C84.4485 68.9247 84.834 68.9792 85.157 69.17C85.48 69.3609 85.7141 69.6723 85.8078 70.0359C85.9014 70.3995 85.847 70.7855 85.6564 71.1089C85.4658 71.4323 85.1547 71.6667 84.7915 71.7605Z"
        fill="#F2F2F2"
      />
      <path
        d="M29.2013 44.1227L17.6939 47.0937C17.5197 47.1384 17.335 47.1122 17.1801 47.0207C17.0253 46.9293 16.913 46.7801 16.8679 46.6058L14.2264 36.3505C14.1817 36.1762 14.2079 35.9912 14.2992 35.8362C14.3906 35.6812 14.5396 35.5688 14.7137 35.5236L26.2211 32.5526C26.3952 32.5078 26.58 32.5341 26.7348 32.6255C26.8897 32.717 27.002 32.8662 27.0471 33.0405L29.6886 43.2957C29.7333 43.4701 29.7071 43.6551 29.6157 43.8101C29.5244 43.9651 29.3753 44.0775 29.2013 44.1227Z"
        fill="#E6E6E6"
      />
      <path
        d="M34.5421 64.859L23.0347 67.83C22.8605 67.8748 22.6758 67.8485 22.5209 67.7571C22.3661 67.6656 22.2538 67.5164 22.2087 67.3421L19.5672 57.0869C19.5225 56.9125 19.5487 56.7276 19.6401 56.5725C19.7314 56.4175 19.8805 56.3051 20.0545 56.26L31.5619 53.2889C31.7361 53.2442 31.9208 53.2704 32.0757 53.3619C32.2305 53.4533 32.3428 53.6025 32.3879 53.7768L35.0294 64.0321C35.0741 64.2064 35.0479 64.3914 34.9565 64.5464C34.8652 64.7014 34.7161 64.8138 34.5421 64.859Z"
        fill="#E6E6E6"
      />
      <path
        d="M39.8829 85.5953L28.3755 88.5663C28.2014 88.6111 28.0166 88.5849 27.8618 88.4934C27.7069 88.4019 27.5946 88.2527 27.5496 88.0785L24.908 77.8232C24.8633 77.6489 24.8895 77.4639 24.9809 77.3089C25.0722 77.1538 25.2213 77.0414 25.3953 76.9963L36.9028 74.0253C37.0769 73.9805 37.2616 74.0067 37.4165 74.0982C37.5713 74.1897 37.6836 74.3389 37.7287 74.5131L40.3702 84.7684C40.4149 84.9427 40.3887 85.1277 40.2974 85.2827C40.206 85.4378 40.057 85.5502 39.8829 85.5953Z"
        fill="#E6E6E6"
      />
      <path
        d="M115.522 19.4321H49.1162C46.8647 19.4347 44.7062 20.3312 43.1141 21.9252C41.5221 23.5191 40.6266 25.6802 40.624 27.9343V106C40.6266 108.254 41.5221 110.415 43.1141 112.009C44.7062 113.603 46.8647 114.499 49.1162 114.502H115.522C117.773 114.499 119.932 113.603 121.524 112.009C123.116 110.415 124.011 108.254 124.014 106V27.9343C124.011 25.6802 123.116 23.5191 121.524 21.9252C119.932 20.3312 117.773 19.4347 115.522 19.4321Z"
        fill="#E6E6E6"
      />
      <path
        d="M115.521 21.8799H49.1158C47.5125 21.8817 45.9753 22.5202 44.8416 23.6552C43.7079 24.7903 43.0702 26.3292 43.0684 27.9345V106C43.0702 107.605 43.7079 109.144 44.8416 110.279C45.9753 111.414 47.5125 112.053 49.1158 112.055H115.521C117.125 112.053 118.662 111.414 119.795 110.279C120.929 109.144 121.567 107.605 121.569 106V27.9345C121.567 26.3293 120.929 24.7903 119.795 23.6552C118.662 22.5202 117.125 21.8817 115.521 21.8799Z"
        fill="white"
      />
      <path
        d="M105.528 64.7765H71.947C71.7612 64.7767 71.5771 64.7402 71.4053 64.6692C71.2336 64.5981 71.0775 64.4939 70.946 64.3624C70.8145 64.2309 70.7102 64.0747 70.639 63.9028C70.5679 63.731 70.5312 63.5467 70.5312 63.3607C70.5312 63.1746 70.5679 62.9904 70.639 62.8185C70.7102 62.6466 70.8145 62.4904 70.946 62.359C71.0775 62.2275 71.2336 62.1232 71.4053 62.0521C71.5771 61.9811 71.7612 61.9446 71.947 61.9448H105.528C105.903 61.9452 106.262 62.0946 106.527 62.3601C106.792 62.6255 106.94 62.9854 106.94 63.3607C106.94 63.7359 106.792 64.0958 106.527 64.3613C106.262 64.6267 105.903 64.7761 105.528 64.7765Z"
        fill="#E6E6E6"
      />
      <path
        d="M111.361 69.5548H71.947C71.7612 69.555 71.5771 69.5186 71.4053 69.4475C71.2336 69.3764 71.0775 69.2722 70.946 69.1407C70.8145 69.0092 70.7102 68.853 70.639 68.6812C70.5679 68.5093 70.5312 68.325 70.5312 68.139C70.5312 67.9529 70.5679 67.7687 70.639 67.5968C70.7102 67.4249 70.8145 67.2688 70.946 67.1373C71.0775 67.0058 71.2336 66.9015 71.4053 66.8305C71.5771 66.7594 71.7612 66.7229 71.947 66.7231H111.361C111.736 66.7231 112.096 66.8723 112.361 67.1378C112.626 67.4034 112.775 67.7635 112.775 68.139C112.775 68.5145 112.626 68.8746 112.361 69.1401C112.096 69.4057 111.736 69.5548 111.361 69.5548Z"
        fill="#E6E6E6"
      />
      <path
        d="M105.528 86.1911H71.947C71.7612 86.1913 71.5771 86.1548 71.4053 86.0837C71.2336 86.0127 71.0775 85.9084 70.946 85.7769C70.8145 85.6454 70.7102 85.4893 70.639 85.3174C70.5679 85.1455 70.5312 84.9613 70.5312 84.7752C70.5312 84.5891 70.5679 84.4049 70.639 84.233C70.7102 84.0612 70.8145 83.905 70.946 83.7735C71.0775 83.642 71.2336 83.5378 71.4053 83.4667C71.5771 83.3956 71.7612 83.3592 71.947 83.3594H105.528C105.903 83.3594 106.263 83.5085 106.528 83.7741C106.793 84.0396 106.942 84.3997 106.942 84.7752C106.942 85.1507 106.793 85.5108 106.528 85.7764C106.263 86.0419 105.903 86.1911 105.528 86.1911Z"
        fill="#E6E6E6"
      />
      <path
        d="M111.361 90.9694H71.947C71.7612 90.9696 71.5771 90.9331 71.4053 90.8621C71.2336 90.791 71.0775 90.6867 70.946 90.5553C70.8145 90.4238 70.7102 90.2676 70.639 90.0957C70.5679 89.9238 70.5312 89.7396 70.5312 89.5535C70.5312 89.3675 70.5679 89.1832 70.639 89.0114C70.7102 88.8395 70.8145 88.6833 70.946 88.5518C71.0775 88.4203 71.2336 88.3161 71.4053 88.245C71.5771 88.174 71.7612 88.1375 71.947 88.1377H111.361C111.547 88.1375 111.731 88.174 111.903 88.245C112.075 88.3161 112.231 88.4203 112.362 88.5518C112.494 88.6833 112.598 88.8395 112.669 89.0114C112.74 89.1832 112.777 89.3675 112.777 89.5535C112.777 89.7396 112.74 89.9238 112.669 90.0957C112.598 90.2676 112.494 90.4238 112.362 90.5553C112.231 90.6867 112.075 90.791 111.903 90.8621C111.731 90.9331 111.547 90.9696 111.361 90.9694Z"
        fill="#E6E6E6"
      />
      <path
        d="M64.4243 71.724H52.5404C52.3606 71.7238 52.1883 71.6522 52.0611 71.5249C51.934 71.3976 51.8625 71.2251 51.8623 71.0451V60.4543C51.8625 60.2743 51.934 60.1017 52.0611 59.9744C52.1883 59.8472 52.3606 59.7756 52.5404 59.7754H64.4243C64.6041 59.7756 64.7765 59.8472 64.9036 59.9744C65.0307 60.1017 65.1022 60.2743 65.1024 60.4543V71.0451C65.1022 71.2251 65.0307 71.3976 64.9036 71.5249C64.7765 71.6522 64.6041 71.7238 64.4243 71.724Z"
        fill="#E6E6E6"
      />
      <path
        d="M64.4243 93.1385H52.5404C52.3606 93.1383 52.1883 93.0667 52.0611 92.9395C51.934 92.8122 51.8625 92.6396 51.8623 92.4596V81.8688C51.8625 81.6888 51.934 81.5163 52.0611 81.389C52.1883 81.2617 52.3606 81.1901 52.5404 81.1899H64.4243C64.6041 81.1901 64.7765 81.2617 64.9036 81.389C65.0307 81.5163 65.1022 81.6888 65.1024 81.8688V92.4596C65.1022 92.6396 65.0307 92.8122 64.9036 92.9395C64.7765 93.0667 64.6041 93.1383 64.4243 93.1385Z"
        fill="#E6E6E6"
      />
      <path
        d="M105.564 40.9376H80.8068C80.4317 40.9376 80.072 40.7885 79.8068 40.5229C79.5416 40.2574 79.3926 39.8973 79.3926 39.5218C79.3926 39.1463 79.5416 38.7862 79.8068 38.5206C80.072 38.2551 80.4317 38.106 80.8068 38.106H105.564C105.94 38.106 106.299 38.2551 106.564 38.5206C106.83 38.7862 106.979 39.1463 106.979 39.5218C106.979 39.8973 106.83 40.2574 106.564 40.5229C106.299 40.7885 105.94 40.9376 105.564 40.9376Z"
        fill="#CCCCCC"
      />
      <path
        d="M111.398 45.7164H80.8068C80.621 45.7164 80.4372 45.6798 80.2656 45.6087C80.094 45.5375 79.9381 45.4332 79.8068 45.3018C79.6755 45.1703 79.5713 45.0142 79.5002 44.8424C79.4292 44.6706 79.3926 44.4865 79.3926 44.3006C79.3926 44.1147 79.4292 43.9306 79.5002 43.7588C79.5713 43.587 79.6755 43.4309 79.8068 43.2995C79.9381 43.168 80.094 43.0637 80.2656 42.9925C80.4372 42.9214 80.621 42.8848 80.8068 42.8848H111.398C111.773 42.8848 112.133 43.0339 112.398 43.2995C112.663 43.565 112.812 43.9251 112.812 44.3006C112.812 44.6761 112.663 45.0362 112.398 45.3018C112.133 45.5673 111.773 45.7164 111.398 45.7164Z"
        fill="#CCCCCC"
      />
      <path
        d="M75.3278 51.5069H52.5033C52.3235 51.5067 52.1512 51.4351 52.024 51.3079C51.8969 51.1806 51.8254 51.008 51.8252 50.828V32.9938C51.8254 32.8138 51.8969 32.6413 52.024 32.514C52.1512 32.3867 52.3235 32.3151 52.5033 32.3149H75.3278C75.5075 32.3151 75.6799 32.3867 75.807 32.514C75.9342 32.6413 76.0057 32.8138 76.0059 32.9938V50.828C76.0057 51.008 75.9342 51.1806 75.807 51.3079C75.6799 51.4351 75.5075 51.5067 75.3278 51.5069Z"
        fill="var(--color-primary-main)"
      />
    </svg>
    <button mat-flat-button color="primary" (click)="viewArticles()" *ngIf="smartphoneView">
      Ver todos los artículos
    </button>
  </div>
  <div *ngIf="documentStateService.document$ | async as document">
    <button
      mat-flat-button
      color="primary"
      style="
        display: flex;
        justify-content: center;
        min-width: 180px;
        margin: 0 auto;
        margin-bottom: 10px;
        text-align: center;
      "
      (click)="viewArticles()"
      *ngIf="smartphoneView"
    >
      Ver todos los artículos
    </button>
    <div>
      <h1>{{ draftContent.title }}</h1>
      <h4 fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <span>{{ draftContent.source | mapping }}</span>
        <mat-icon>query_builder</mat-icon>
        <span>{{ draftContent.date | date }}</span>
      </h4>
    </div>
    <span [ngSwitch]="document.docType">
      <div *ngSwitchCase="'pdf'">
        <ngx-extended-pdf-viewer
          #mypdf
          id="mypdf"
          [src]="document.s3_pdf"
          [useBrowserLocale]="true"
          [handTool]="false"
          [showHandToolButton]="true"
          [spread]="true"
          [height]="'1024px'"
        >
        </ngx-extended-pdf-viewer>
      </div>
      <div *ngSwitchCase="'html'">
        <div [innerHTML]="document.bodyParsed"></div>
      </div>
      <div *ngSwitchDefault class="no-doc" fxLayout="column" fxLayoutAlign="center center">
        <div>
          <!-- <img class="brainy-logo" src="{{ LOGOURL }}" alt="Logo" /> -->
          <h2>Tipo de documento no soportado</h2>
        </div>
      </div>
    </span>
  </div>
  <!-- <div *ngIf="draftContent?.s3_file_url" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width">
      <h1>{{ draftContent.title }}</h1>
      <h4 fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <span>{{ draftContent.source | mapping }}</span>
        <mat-icon>query_builder</mat-icon>
        <span>{{ draftContent.date | date }}</span>
      </h4>
    </div>
    <span [ngSwitch]="draftContent.docType" class="full-width">
      <div *ngSwitchCase="'multimedia'" class="full-width" style="display: flex; flex-direction: column">
        <a
          [href]="draftContent.s3_file_url"
          target="_blank"
          style="width: fit-content; margin-bottom: 12px; font-size: 15px; font-weight: 500"
          >Abrir página en otra pestaña</a
        >
        <iframe width="100%" height="1024px" [src]="draftContent.s3_file_url | safe: 'resourceUrl'"></iframe>
      </div>
      <div *ngSwitchDefault class="full-width">
        <ngx-extended-pdf-viewer
          #mypdf
          id="mypdf"
          [src]="draftContent.s3_file_url"
          [useBrowserLocale]="true"
          [handTool]="false"
          [showHandToolButton]="true"
          [spread]="true"
          [height]="'1024px'"
        >
        </ngx-extended-pdf-viewer>
      </div>
    </span>
  </div> -->
</div>
