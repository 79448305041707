import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnChanges,
  SimpleChanges,
  ComponentRef,
  OnDestroy,
} from '@angular/core';
import { AppStatusService } from '@app/services/app-status.service';
import { AppStateService } from '@app/state/app/app.service';

@Component({
  selector: 'app-paneldoc',
  templateUrl: './paneldoc.component.html',
  styleUrls: ['./paneldoc.component.css'],
})
export class PaneldocComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('target', { read: ViewContainerRef, static: false }) target: ViewContainerRef;
  @Output() close = new EventEmitter();

  @Input() doc_item;
  @Input() lookfortext;
  @Input() openwithsearch;

  docID: string;
  docLoaded = false;

  constructor(
    private appStatus: AppStatusService,
    private cfr: ComponentFactoryResolver,
    private _appStateService: AppStateService
  ) {}

  ngOnInit() {
    this._appStateService.hideSideMenu();
    this._appStateService.hideTitle();
    this._appStateService.hideSearchText();

    this.openwithsearch = false;
    this.docID = this.doc_item.id_source;
    if (this.doc_item.searchtext != null) {
      this.lookfortext = this.doc_item.searchtext;
      this.openwithsearch = true;
    }
  }

  ngOnDestroy(): void {
    this._appStateService.showSideMenu();
    this._appStateService.showTitle();
    this._appStateService.showSearchText();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.paneldoc_config) this.appStatus.paneldoc_open.emit();
  }

  addDocument(docid: string, doctype: string) {}

  destroy() {
    this.appStatus.paneldoc_close.emit();
    this.close.emit(null);
  }
}
