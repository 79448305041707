import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { pressUserCreditsRequest, pressUserCreditsResponse } from '@app/models/magazine.model';
import { MagazineService } from '@app/services/magazine.service';
import _moment from 'moment';

@Component({
  selector: 'app-magazine-credits',
  templateUrl: './magazine-credits.component.html',
  styleUrls: ['./magazine-credits.component.scss'],
})
export class MagazineCreditsComponent {
  range = new FormGroup({
    start: new FormControl(_moment().startOf('month').toISOString(), [Validators.required]),
    end: new FormControl(_moment().endOf('month').toISOString(), [Validators.required]),
  });

  loading = true;
  credits?: pressUserCreditsResponse;
  chartsData?;
  loadingOpts: object = {
    text: '',
    color: '#c23531',
  };

  constructor(
    public dialogRef: MatDialogRef<MagazineCreditsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private magazineService: MagazineService
  ) {
    this.save();
  }

  save(): void {
    this.range.markAllAsTouched();

    if (this.range.valid) {
      this.loading = true;

      const request: pressUserCreditsRequest = {
        start_date: this.range.controls.start.value,
        end_date: this.range.controls.end.value,
      };

      this.magazineService.getUserCredits(request).subscribe((data) => {
        this.credits = data;
        // this.setChartsData();
        this.loading = false;
      });
    }
  }

  // setChartsData(): void {
  //   this.chartsData = {
  //     tooltip: {
  //       trigger: 'axis',
  //       axisPointer: {
  //         type: 'shadow',
  //       },
  //     },
  //     legend: {
  //       left: 0,
  //       data: ['Gastado', 'Restante'],
  //     },
  //     grid: {
  //       left: 0,
  //       containLabel: true,
  //     },
  //     xAxis: {
  //       type: 'value',
  //     },
  //     yAxis: {
  //       type: 'category',
  //       data: [''],
  //     },
  //     series: [
  //       {
  //         name: 'Gastado',
  //         type: 'bar',
  //         stack: 'stack',
  //         label: {
  //           show: true,
  //           position: 'insideRight',
  //         },
  //         data: [this.credits.total],
  //       },
  //       {
  //         name: 'Restante',
  //         type: 'bar',
  //         stack: 'stack',
  //         label: {
  //           show: true,
  //           position: 'insideRight',
  //         },
  //         data: [this.credits.user_credits - this.credits.total],
  //       },
  //     ],
  //   };
  // }
}
