export enum UIInitiativeStatus {
  calendar,
  list,
}

export interface Document {
  label: string;
  date: Date;
  pag: number;
  link: string;
}

export interface UserDocument {
  titulo: string;
  filename: string;
  date: String;
  s3_file_url: string;
}

export interface PastProcedure {
  comision: string;
  status: string;
  from: Date;
  to: Date;
}

export interface JournalSession {
  date: Date;
  page: number;
  link: string;
  details: string[];
  subject: string;
}

export interface Initiative {
  id?: number;
  name: string;
  url?: string;
  freeFile?: string;
  files?: number[];
  section?: string;
  title?: string;
  legislature?: string;
  presented?: Date;
  date?: Date;
  qualified?: Date;
  link?: string;
  type?: string;
  user_docs?: UserDocument[];
  documents?: Document[];
  author?: string;
  processing_type?: string;
  actual_status?: string;
  resultadoTramitación?: 'string';
  competent_committee?: { link: string; committee_name: string };
  deadlines?: any[];
  past_procedures?: PastProcedure[];
  source_initiatives?: { code: string; link: string };
  speakers?: any[];
  journal_session?: JournalSession[];
  result?: string;
  siguiendo?: boolean;
}

export interface InitiativeStateModel {
  openInitiatives: {
    initiatives: Initiative[];
    total: number;
  };
  initiatives: Initiative[];
  selectedInitiative?: Initiative;
  uiStatus: UIInitiativeStatus;
  menuFilters: InitiativeMenuFilters;
}

export interface InitiativeMenuFilters {
  opened: boolean;
}
