<mat-toolbar fxLayout="row" fxLayoutGap="12px" [ngStyle]="{ display: mode === 'edit' ? 'flex' : 'none' }">
  <div class="title" *ngIf="mode === 'edit'">
    <mat-checkbox color="primary" [(ngModel)]="isChecked"> Incluir portadas de fecha </mat-checkbox>
    <mat-form-field *ngIf="isChecked">
      <input matInput [matDatepicker]="picker" [formControl]="covers_date" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="toggle" *ngIf="isChecked && mode === 'edit'">
    <p>Al inicio</p>
    <mat-slide-toggle color="primary" [formControl]="position_covers_date"> </mat-slide-toggle>
    <p>Al final</p>
  </div>
</mat-toolbar>
<div
  fxLayout="row"
  fxLayoutGap="12px"
  class="container"
  [ngStyle]="{ height: mode === 'edit' ? (isChecked ? 'calc(100% - 105px)' : 'calc(100% - 56px)') : '100%' }"
>
  <div fxFlex="calc(50% - 24px)" class="row-container left" *ngIf="!smartphoneView">
    <mat-list *ngIf="draftStateService.selectedDraft$ | async as dossier">
      <div *ngFor="let category of dossier.data; let i = index">
        <div *ngIf="category.docs.length > 0">
          <div mat-subheader [ngClass]="i === 0 ? 'foment' : 'foment color' + i">
            {{ category.category }} ({{ category.docs.length }} artículos)
          </div>
          <mat-list-item
            *ngFor="let article of category.docs; let i = index"
            [ngClass]="{ docs: docArticleID !== article.id && !s3_file_url, selected: docArticleID === article.id }"
            (click)="openDetail(article)"
          >
            <mat-icon mat-list-icon>feed</mat-icon>
            <div mat-line>
              <strong>{{ article.source | mapping }}</strong> - {{ article.date | date }}
            </div>
            <div mat-line>
              <strong>{{ article.title }}</strong>
            </div>
            <div mat-line>{{ article.abstract }}</div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </div>
      </div>
    </mat-list>
  </div>
  <div fxFlex="calc(50% - 24px)" class="row-container right">
    <app-articledetail
      [docID]="docArticleID"
      [s3_file_url]="s3_file_url"
      [draftContent]="draftContent"
      [smartphoneView]="smartphoneView"
      (changeArticle)="openDetail($event)"
    ></app-articledetail>
  </div>
</div>
<span class="pdf" *ngIf="mode === 'edit'">
  <button mat-fab color="primary" matTooltip="Enviar revista" (click)="showSendDialog()">
    <mat-icon>send</mat-icon>
  </button>
</span>
<!-- <span class="link">
  <button mat-fab color="accent" matTooltip="Generar enlace de descarga" (click)=linkMagazine()>
    <mat-icon>share</mat-icon>
  </button>
</span> -->
