import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubventionsAlertsComponent } from './subventions-alerts/subventions-alerts.component';
import { SubventionsFavoritesComponent } from './subventions-favorites/subventions-favorites.component';
import { SubventionsHomeComponent } from './subventions-home/subventions-home.component';
import { SubventionsSearchComponent } from './subventions-search/subventions-search.component';

export const SubventionsRoutes: Routes = [
  { path: '', component: SubventionsHomeComponent },
  { path: 'search', component: SubventionsSearchComponent },
  // { path: 'search/:id', component: SubventionsSearchDetailComponent },
  // { path: 'support', component: SubventionsSupportComponent },
  { path: 'favorites', component: SubventionsFavoritesComponent },
  { path: 'alerts', component: SubventionsAlertsComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(SubventionsRoutes)],
  exports: [RouterModule],
})
export class SubventionsRoutingModule {}
