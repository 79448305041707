import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AppStateService } from '@app/state/app/app.service';

import _moment from 'moment';
import { CalendarView, CalendarEvent, DAYS_OF_WEEK } from 'angular-calendar';
import { colors } from '@app/utils/colors';
import { NormFormDialog } from './norm-form.component';
import { MatDialog } from '@angular/material/dialog';

import { isSameMonth, isSameDay } from 'date-fns';
import { InitiativeStateService } from '../../store/initiative/initiative.service';
import { UIInitiativeStatus, Initiative } from '../../store/models/initiative.state.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { RegionMappings, UIStatus } from '@app/state/models/app.state.model';
import { Subject, Subscription, interval, zip } from 'rxjs';
import { InitiativeDetailComponent } from './initiative-detail/initiative-detail.component';
import { ManualFormDialog } from './manual-form.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import {
  AddInitiative,
  DeleteInitiative,
  FollowInitiative,
  GetInitiatives,
  GetOpenInitiatives,
} from '../../store/initiative/initiative.actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { InitiativesService } from '../../services/initiatives.service';
import { takeUntil } from 'rxjs/operators';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { Router } from '@angular/router';
import { MenuItem, NavItem } from '@app/models/User';
import { MatTabChangeEvent } from '@angular/material/tabs';

_moment.updateLocale('es', {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0,
  },
});

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [animate('0.2s 100ms ease-out'), style({ transform: 'translateX(-100%)' })]),
      transition(':leave', [style({ transform: 'translateX(100%)' }), animate('0.2s 100ms ease-in')]),
    ]),
  ],
})
export class CalendarComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  tabs = [
    {
      label: 'Configurar seguimiento',
      identifier: 'list',
    },
    {
      label: 'Mostrar calendario',
      identifier: 'calendar',
    },
  ];

  componentDestroyed$: Subject<boolean> = new Subject();
  openInitiativesDataSource: MatTableDataSource<Initiative> = new MatTableDataSource();
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  UIInitiativeView = UIInitiativeStatus;
  filtersForm: FormGroup;
  subItemName = '';

  subscription: Subscription;
  initiatives: Initiative[];

  pageIndex = 0;
  pageSize = 30;

  displayedColumns: string[] = ['presented', 'title', 'tools'];

  dropdownRegionsList = [];
  selectedRegionsItems = [];
  dropdownRegionsSettings = {
    text: 'Selección de Región',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 1,
    selectGroup: false,
    showCheckbox: false,
    singleSelection: true,
  };

  constructor(
    public appStateService: AppStateService,
    public dialog: MatDialog,
    public router: Router,
    private actions: Actions,
    private fb: FormBuilder,
    public initiativeStateService: InitiativeStateService,
    private initiativesService: InitiativesService,
    private authStateService: AuthStateService,
    public changeDetector: ChangeDetectorRef
  ) {
    this.filtersForm = this.fb.group({
      0: [true],
      1: [true],
      2: [true],
      pending: [true],
      search: [''],
      reference: [''],
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(AddInitiative)).subscribe(() => {
      setTimeout(() => {
        this.initiativeStateService.getInitiatives();
        this.search(this.pageIndex);
      }, 0);
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(DeleteInitiative)).subscribe(() => {
      setTimeout(() => {
        this.initiativeStateService.getInitiatives();
        this.search(this.pageIndex);
      }, 0);
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(FollowInitiative)).subscribe(() => {
      setTimeout(() => {
        this.search(this.pageIndex);
      }, 0);
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetInitiatives)).subscribe(() => {
      setTimeout(() => {
        this.setCalendarViewEvents();
      }, 0);
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetOpenInitiatives)).subscribe(() => {
      setTimeout(() => {
        this.setCalendarViewEvents();
      }, 0);
    });

    this.filtersForm.controls.reference.valueChanges.subscribe((text) => {
      if (text) {
        this.filtersForm.controls['0'].disable();
        this.filtersForm.controls['1'].disable();
        this.filtersForm.controls['2'].disable();
        this.filtersForm.controls.pending.disable();
      } else {
        this.filtersForm.controls['0'].enable();
        this.filtersForm.controls['1'].enable();
        this.filtersForm.controls['2'].enable();
        this.filtersForm.controls.pending.enable();
      }
      this.filtersForm.markAllAsTouched();
    });

    interval(1000)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        if (this.dropdownRegionsList.length === 0 || this.selectedRegionsItems[0]?.id == 10) {
          this.filtersForm.controls.reference.setValue('');
        }

        this.changeDetector.detectChanges();
      });

    this.subItemName = this.getSubitemName();
    this.setRegions();
  }

  ngOnInit(): void {
    this.appStateService.showTitle();
    this.appStateService.hideDates();
    this.appStateService.hideSearchText();
    this.appStateService.hideSubForm();
    this.appStateService.updateUIStatus(UIStatus.loading);
    this.showList();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.subscription?.unsubscribe();
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    this.initiativeStateService.setInitiative(event.meta);
    const dialogRef = this.dialog.open(InitiativeDetailComponent, {
      width: '95%',
      maxWidth: '1200px',
      height: '90%',
    });
  }

  showInitiative(initiative: Initiative): void {
    // Get initiative by events list because it has more fields
    this.initiativeStateService.setInitiative(initiative);
    const dialogRef = this.dialog.open(InitiativeDetailComponent, {
      width: '95%',
      maxWidth: '1200px',
      height: '90%',
    });
  }

  followInitiative(initiative: Initiative): void {
    if (this.selectedRegionsItems.length === 0 || this.selectedRegionsItems[0].id == 10) {
      if (!initiative.siguiendo) {
        this.initiativeStateService.addInitiative({ title: initiative.title, url: initiative.link });
      } else {
        this.initiativeStateService.deleteInitiative(initiative.id);
      }
    } else {
      if (!initiative.siguiendo) {
        this.initiativeStateService.followInitiative(initiative.id);
      } else {
        this.initiativeStateService.unfollowInitiative(initiative.id);
      }
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent<any>[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NormFormDialog, {
      width: '650px',
    });
  }

  openManualDialog(): void {
    const dialogRef = this.dialog.open(ManualFormDialog, {
      width: '650px',
    });
  }

  showCalendar(): void {
    this.initiativeStateService.showCalendar();
  }

  showList(): void {
    setTimeout(() => {
      this.paginator.pageIndex = this.pageIndex;
      this.paginator.length = this.initiativeStateService.openInitiatives.total;
    }, 0);
    this.initiativeStateService.showList();
  }

  pageEvent(evt: PageEvent): void {
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.search(this.pageIndex);
  }

  searchOpenInitiatives(): void {
    this.initiativeStateService.getOpenInitiatives({
      page: this.pageIndex + 1,
      size: this.pageSize,
      sections: [
        this.filtersForm.controls['0'].value ? 'Real Decreto-Ley.' : null,
        this.filtersForm.controls['1'].value ? 'Proyecto de ley.' : null,
        this.filtersForm.controls['2'].value ? 'Proposición de ley de Grupos Parlamentarios del Congreso.' : null,
      ].filter((v) => v),
      result: 0,
      pending: this.filtersForm.controls.pending.value,
      normativa_id: this.filtersForm.controls.search.value,
      ref: this.filtersForm.controls.reference.value,
    });
  }

  searchOpenInitiativesCCA(): void {
    // Mapping -> PPL: Proposición de Ley, PRL: Proyecto de Ley, DRL: Decreto Ley
    this.initiativeStateService.getOpenInitiativesCCA({
      page: this.pageIndex + 1,
      size: this.pageSize,
      type: [
        this.filtersForm.controls['0'].value ? 'DRL' : null,
        this.filtersForm.controls['1'].value ? 'PRL' : null,
        this.filtersForm.controls['2'].value ? 'PPL' : null,
      ].filter((v) => v),
      cca: this.selectedRegionsItems.map((r) => r.id).filter((r) => r !== 1),
      pending: this.filtersForm.controls.pending.value,
      text: this.filtersForm.controls.search.value,
      ref: this.filtersForm.controls.reference.value,
    });
  }

  setRegions(): void {
    // this.dropdownRegionsList = JSON.parse(
    //   JSON.stringify([
    //     ...this.authStateService.userConfig.config.Documentos.Regions.map((obj) => {
    //       return JSON.parse(
    //         JSON.stringify({
    //           ...obj,
    //           id: obj.regions[0],
    //           itemName: obj.name.replace('> ', '').replace('- ', '').trim(),
    //         })
    //       );
    //     }).filter((r) => r.name !== 'Europa' && r.name !== 'Ceuta' && r.name !== 'Melilla'),
    //   ])
    // );

    this.initiativesService
      .getCalendarFilters({
        path: this.router.url.slice(1),
      })
      .subscribe((data) => {
        if (data.items.length > 0) {
          this.dropdownRegionsList = data.items[0].items.map((i) => {
            return {
              ...i,
              id: i.value[0],
              itemName: i.label.replace('> ', '').replace('- ', '').trim(),
            };
          });

          this.selectedRegionsItems.push(
            this.dropdownRegionsList.find((r) => r.id === 10) ?? this.dropdownRegionsList[0]
          );
        }

        this.search();

        this.appStateService.addStateSelectedRegions([
          { id: 1, name: 'España', visibleName: 'España', order: 2, regions: [10] },
        ]);

        this.setTitle();

        if (this.selectedRegionsItems.length === 0 || this.selectedRegionsItems[0].id === 10) {
          this.initiativeStateService.getInitiatives();
        }

        setTimeout(() => {
          this.changeDetector.detectChanges();
        }, 0);
      });
  }

  search(page?: number): void {
    if (!page) {
      this.pageIndex = 0;
    }

    let initiatives: Initiative[] = [];

    if (this.selectedRegionsItems.length === 0 || this.selectedRegionsItems[0].id === 10) {
      this.searchOpenInitiatives();
    } else {
      this.searchOpenInitiativesCCA();
    }

    const subscription = this.actions
      .pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetOpenInitiatives))
      .subscribe(() => {
        initiatives.push(...this.initiativeStateService.openInitiatives.initiatives);

        initiatives = initiatives.map((i) => {
          return {
            ...i,
            siguiendo: i.siguiendo || this.initiativeStateService.initiatives.map((fav) => fav.id).includes(i.id),
          };
        });

        this.openInitiativesDataSource = new MatTableDataSource(initiatives);

        setTimeout(() => {
          if (this.paginator) {
            this.paginator.pageIndex = this.pageIndex;
            this.paginator.length = this.initiativeStateService.openInitiatives.total;
            this.setTitle();
            subscription.unsubscribe();
          }
        }, 0);
      });
  }

  setCalendarViewEvents(): void {
    this.events = [];

    if (
      (this.selectedRegionsItems.length > 0 && this.selectedRegionsItems[0].id !== 10) ||
      this.router.url.includes('regulatorio/calendar')
    ) {
      this.initiativesService
        .getOpenInitiativesFavorites()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((data: any) => {
          data.items.map((initiative) => {
            if (initiative.date) {
              if (!this.events.find((e) => e.meta.id === initiative.id)) {
                this.events.push({
                  title: `[${initiative.type}] - ${initiative.parlament} - ${initiative.title}`,
                  color: colors.blue,
                  start: _moment(initiative.date).toDate(),
                  meta: {
                    ...initiative,
                    tramits: initiative.tramits.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
                    events: initiative.events.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
                  },
                });

                initiative.events.forEach((event) => {
                  this.events.push({
                    title: `[${initiative.type}] - ${initiative.parlament} - ${event.title}`,
                    color: colors.blue,
                    start: _moment(event.date).toDate(),
                    meta: {
                      ...initiative,
                      tramits: initiative.tramits.sort(
                        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
                      ),
                      events: initiative.events.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
                    },
                  });
                });
              }
            }
          });
        });
    }

    if (
      this.selectedRegionsItems.length === 0 ||
      this.selectedRegionsItems[0].id === 10 ||
      this.router.url.includes('regulatorio/calendar')
    ) {
      this.subscription = this.initiativeStateService.initiatives$
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((initiatives) => {
          initiatives.map((initiative) => {
            if (initiative.deadlines) {
              this.events.push({
                title: `${initiative.title}`,
                color: colors.red,
                start: _moment(initiative['presented']).toDate(),
                meta: initiative,
              });
            }

            if (initiative.deadlines) {
              initiative.deadlines.map((deadline) => {
                if (deadline.timestamp && deadline.label) {
                  this.events.push({
                    title: `[${deadline.label}] - ${initiative.title}`,
                    color: colors.red,
                    start: _moment(deadline['timestamp'].substring(0, 10)).toDate(),
                    meta: initiative,
                  });
                }
              });
            }

            if (initiative.past_procedures) {
              initiative.past_procedures.map((past_procedure) => {
                let start = past_procedure['from'] ? _moment(past_procedure['from']).toDate() : undefined;
                let end = past_procedure['to'] ? _moment(past_procedure['to']).toDate() : undefined;
                this.events.push({
                  title: `[${past_procedure['status']}] - ${initiative.title}`,
                  color: colors.red,
                  start: start,
                  end: end,
                  meta: initiative,
                });
              });
            }
          });

          this.initiatives = initiatives;
          // this.subscription.unsubscribe();
        });
    }
  }

  setTitle(): void {
    this.appStateService.addTitle(
      this.selectedRegionsItems.length > 0 ? `Calendario regulatorio (${this.selectedRegionsItems[0].itemName})` : ''
    );
  }

  getSubitemName(): string {
    const activeSubItem = this.findActiveSubItem();

    if (activeSubItem.parent.id === 6) {
      // Vigilancia legislativa
      if (this.router.url.startsWith('/vigilancia/tnor')) {
        return `${activeSubItem?.child?.items.find((i) => this.router.url.startsWith(i.path)).parent_module} | ${
          activeSubItem?.child?.items.find((i) => this.router.url.startsWith(i.path)).title
        }`;
      } else {
        return activeSubItem?.child?.items.find((i) => this.router.url.includes(i.path)).title ?? '';
      }
    } else {
      return '';
    }
  }

  findActiveSubItem(): { parent: MenuItem; child: NavItem } | null {
    for (const menuItem of this.authStateService.userConfig.interface_config.menu_items) {
      for (const subItem of menuItem.items) {
        if (subItem.path && window.location.href.includes(subItem.path)) {
          return {
            parent: menuItem,
            child: subItem,
          };
        }
      }
    }

    return null;
  }

  resetFilters(): void {
    this.filtersForm.patchValue({
      1: true,
      0: true,
      2: true,
      pending: true,
      search: '',
      reference: '',
    });

    this.selectedRegionsItems = [];
    this.setRegions();
  }

  tabChange(evt: MatTabChangeEvent): void {
    if (evt.index === 0) {
      this.initiativeStateService.showList();
    } else if (evt.index === 1) {
      this.initiativeStateService.showCalendar();
    }
  }
}
