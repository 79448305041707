import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { AgendasState } from './agendas.state';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppStateService } from '@app/state/app/app.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AgendasMenuFilters } from '../models/agendas.state.model';
import { ToggleAgendasMenuFilters } from './agendas.actions';

@Injectable({ providedIn: 'root' })
export class AgendasStateService {
  @Select(AgendasState.menuFilters)
  public menuFilters$: Observable<AgendasMenuFilters>;

  @SelectSnapshot(AgendasState.menuFilters)
  public menuFilters: AgendasMenuFilters;

  constructor(
    private _spinner: NgxSpinnerService,
    private _snackbarStateService: SnackbarStateService,
    private _appService: AppStateService
  ) {}

  @Dispatch()
  public toggleAgendasMenuFilters = () => {
    return new ToggleAgendasMenuFilters();
  };
}
