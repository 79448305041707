import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import {
  Agenda,
  AgendaEvent,
  AgendaFilters,
  AppStateModel,
  AppNotification,
  PaginatedResults,
  RegionMappings,
  SearchFilters,
  SearchType,
  SpiderMappings,
  TopicMappings,
  UIStatus,
} from '../models/app.state.model';
import {
  ToggleSideMenu,
  AddTitle,
  ShowTitle,
  HideTitle,
  ShowSideMenu,
  HideSideMenu,
  ShowDates,
  ShowSearchText,
  HideSearchText,
  ShowSubForm,
  HideSubForm,
  ShowRegulatorioSubForm,
  HideRegulatorioSubForm,
  HideDates,
  ToggleSubForm,
  ToggleRegulatorioSubForm,
  AddHistoryUrl,
  AddSpiderMappings,
  UnsetInteresting,
  ShowInteresting,
  HideInteresting,
  AddTopicMappings,
  UpdateUIStatus,
  UpdateSearchContent,
  UpdateSearchFilters,
  AddRegionMappings,
  AddSelectedRegion,
  UpdateAgendaFilters,
  GetAgendas,
  GetAgendasEvents,
  ShowAgendasTutorial,
  GetInitiativesNameMapping,
  AddCategoriesTopics,
  SetSearchType,
  SetNotifications,
  UpdateNotifications,
} from './app.actions';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
@State<AppStateModel>({
  name: 'app',
  defaults: {
    uiStatus: UIStatus.notInitializated,
    toolbar: {
      title: 'Inicio',
      show_title: true,
      search: {
        dates_visible: false,
        search_text_visible: false,
        subform_visible: false,
        subform_regulatorio_visible: false,
        content: '',
        filters: {
          dates: {
            start: '',
            end: '',
          },
          spiders: [],
          categories: [],
          regions: [],
          contexts: [],
          clippingsSelected: {
            escrita: false,
            digital: false,
          },
          searchType: 'literal',
        },
        agendas: { items: [] },
        agendasEvents: [],
        agendaFilters: {
          spiderGroup: undefined,
          items: [],
        },
        showAgendasTutorial: true,
      },
    },
    sidemenu: { opened: true },
    history_url: '',
    spider_mappings: [],
    topic_mappings: [],
    categories_topics: [],
    region_mappings: [],
    selected_regions: [],
    initiativeNameMapping: [],
    searchType: 'literal',
    notifications: [],
  },
})
export class AppState {
  @Selector()
  static sidemenu(state: AppStateModel): boolean | null {
    return state.sidemenu.opened;
  }

  @Selector()
  static title(state: AppStateModel): string | null {
    return state.toolbar.title;
  }

  @Selector()
  static show_title(state: AppStateModel): boolean | null {
    return state.toolbar.show_title;
  }

  @Selector()
  static dates_visible(state: AppStateModel): boolean | null {
    return state.toolbar.search.dates_visible;
  }

  @Selector()
  static interesting_visible(state: AppStateModel): boolean | null {
    return state.toolbar.interesting_visible;
  }

  @Selector()
  static search_text_visible(state: AppStateModel): boolean | null {
    return state.toolbar.search.search_text_visible;
  }

  @Selector()
  static subform_visible(state: AppStateModel): boolean | null {
    return state.toolbar.search.subform_visible;
  }

  @Selector()
  static subform_regulatorio_visible(state: AppStateModel): boolean | null {
    return state.toolbar.search.subform_regulatorio_visible;
  }

  @Selector()
  static content(state: AppStateModel): string {
    return state.toolbar.search.content;
  }

  @Selector()
  static searchFilters(state: AppStateModel): SearchFilters {
    return state.toolbar.search.filters;
  }

  @Selector()
  static agendaFilters(state: AppStateModel): AgendaFilters {
    return state.toolbar.search.agendaFilters;
  }

  @Selector()
  static agendas(state: AppStateModel): { items: AgendaEvent[] } {
    return state.toolbar.search.agendas;
  }

  @Selector()
  static agendasEvents(state: AppStateModel): AgendaEvent[] {
    return state.toolbar.search.agendasEvents;
  }

  @Selector()
  static showAgendasTutorial(state: AppStateModel): boolean {
    return state.toolbar.search.showAgendasTutorial;
  }

  @Selector()
  static history_url(state: AppStateModel): string | null {
    return state.history_url;
  }

  @Selector()
  static spider_mappings(state: AppStateModel): SpiderMappings[] | null {
    return state.spider_mappings;
  }

  @Selector()
  static topic_mappings(state: AppStateModel): TopicMappings[] | null {
    return state.topic_mappings;
  }

  @Selector()
  static categories_topics(state: AppStateModel): any[] | null {
    return state.categories_topics;
  }

  @Selector()
  static region_mappings(state: AppStateModel): RegionMappings[] | null {
    return state.region_mappings;
  }

  @Selector()
  static selected_regions(state: AppStateModel): RegionMappings[] | null {
    return state.selected_regions;
  }

  @Selector()
  static uiStatus(state: AppStateModel): UIStatus | null {
    return state.uiStatus;
  }

  @Selector()
  static initiativeNameMapping(state: AppStateModel): { key: string; value: string }[] | null {
    return state.initiativeNameMapping;
  }

  @Selector()
  static searchType(state: AppStateModel): SearchType {
    return state.searchType;
  }

  @Selector()
  static notifications(state: AppStateModel): AppNotification[] {
    return state.notifications;
  }

  constructor(private _spinner: NgxSpinnerService) {}

  @Action(UpdateUIStatus)
  updateUIStatusByState(ctx: StateContext<AppStateModel>, response: { payload: UIStatus }) {
    ctx.patchState({ uiStatus: response.payload });
  }

  @Action(ToggleSideMenu)
  toggleSideMenuByState(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ sidemenu: { opened: !ctx.getState().sidemenu.opened } });
  }

  @Action(ShowSideMenu)
  showSideMenuByState(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ sidemenu: { opened: true } });
  }

  @Action(HideSideMenu)
  hideSideMenuByState(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ sidemenu: { opened: false } });
  }

  @Action(AddTitle)
  addTitleByState(
    { getState, patchState }: StateContext<AppStateModel>,
    response: { payload: { title: string; show_title: boolean } }
  ) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    toolbar.title = response.payload.title;
    toolbar.show_title = response.payload.show_title;
    patchState({ toolbar: toolbar });
  }

  @Action(ShowTitle)
  showTitleByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    toolbar.show_title = true;
    patchState({ toolbar: toolbar });
  }

  @Action(UpdateSearchContent)
  updateSearchContent(
    { getState, patchState }: StateContext<AppStateModel>,
    response: { payload: { content: string } }
  ) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.content = response.payload.content;
    toolbar.search = search;
    patchState({ toolbar });
  }

  @Action(UpdateSearchFilters)
  updateSearchFilters(
    { getState, patchState }: StateContext<AppStateModel>,
    response: { payload: { filters: SearchFilters } }
  ) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.filters = response.payload.filters;
    toolbar.search = search;
    patchState({ toolbar });
  }

  @Action(UpdateAgendaFilters)
  updateAgendaFilters({ getState, patchState }: StateContext<AppStateModel>, response: { payload: AgendaFilters }) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.agendaFilters = response.payload;
    toolbar.search = search;
    patchState({ toolbar });
  }

  @Action(GetAgendas)
  getAgendas({ getState, patchState }: StateContext<AppStateModel>, response: { payload: { items: AgendaEvent[] } }) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.agendas = response.payload;
    toolbar.search = search;
    patchState({ toolbar });
  }

  @Action(GetAgendasEvents)
  getAgendasEvents({ getState, patchState }: StateContext<AppStateModel>, response: { payload: AgendaEvent[] }) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.agendasEvents = response.payload;
    toolbar.search = search;
    patchState({ toolbar });
  }

  @Action(ShowAgendasTutorial)
  showAgendasTutorial({ getState, patchState }: StateContext<AppStateModel>, response: { payload: boolean }) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.showAgendasTutorial = response.payload;
    toolbar.search = search;
    patchState({ toolbar });
  }

  @Action(HideTitle)
  hideTitleByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    toolbar.show_title = false;
    patchState({ toolbar: toolbar });
  }

  @Action(ShowDates)
  showDatesByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.dates_visible = true;
    toolbar.search = search;
    patchState({ toolbar: toolbar });
  }

  @Action(ShowInteresting)
  showInterestingByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = JSON.parse(JSON.stringify(getState()));
    state.toolbar.interesting_visible = false;
    patchState({ toolbar: state.toolbar });
  }

  @Action(HideInteresting)
  hideInterestingByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = JSON.parse(JSON.stringify(getState()));
    state.toolbar.interesting_visible = true;
    patchState({ toolbar: state.toolbar });
  }

  @Action(UnsetInteresting)
  unsetInterestingByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = JSON.parse(JSON.stringify(getState()));
    state.toolbar.interesting_visible = undefined;
    patchState({ toolbar: state.toolbar });
  }

  @Action(HideDates)
  hideDatesByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.dates_visible = false;
    toolbar.search = search;
    patchState({ toolbar: toolbar });
  }

  @Action(ShowSearchText)
  showSearchTextByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.search_text_visible = true;
    toolbar.search = search;
    patchState({ toolbar: toolbar });
  }

  @Action(HideSearchText)
  hideSearchTextByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.search_text_visible = false;
    toolbar.search = search;
    patchState({ toolbar: toolbar });
  }

  @Action(ToggleSubForm)
  toggleSubFormByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.subform_visible = !toolbar.search.subform_visible;
    toolbar.search = search;
    patchState({ toolbar: toolbar });
  }

  @Action(ShowSubForm)
  showSubFormByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.subform_visible = true;
    toolbar.search = search;
    patchState({ toolbar: toolbar });
  }

  @Action(HideSubForm)
  hideSubFormByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.subform_visible = false;
    toolbar.search = search;
    patchState({ toolbar: toolbar });
  }

  @Action(ToggleRegulatorioSubForm)
  toggleRegulatorioSubFormByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.subform_regulatorio_visible = !toolbar.search.subform_regulatorio_visible;
    toolbar.search = search;
    patchState({ toolbar: toolbar });
  }

  @Action(ShowRegulatorioSubForm)
  showRegulatorioSubFormByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.subform_regulatorio_visible = true;
    toolbar.search = search;
    patchState({ toolbar: toolbar });
  }

  @Action(HideRegulatorioSubForm)
  hideRegulatorioSubFormByState({ getState, patchState }: StateContext<AppStateModel>) {
    const state = getState();
    let toolbar = { ...state.toolbar };
    let search = { ...getState().toolbar.search };
    search.subform_regulatorio_visible = false;
    toolbar.search = search;
    patchState({ toolbar: toolbar });
  }

  @Action(AddHistoryUrl)
  addHistoryUrlByState({ patchState }: StateContext<AppStateModel>, response: { payload: string }) {
    patchState({ history_url: response.payload });
  }

  @Action(AddSpiderMappings)
  addSpiderMappingsByState({ patchState }: StateContext<AppStateModel>, response: { payload: SpiderMappings[] }) {
    patchState({ spider_mappings: response.payload });
  }

  @Action(AddTopicMappings)
  addTopicMappingsByState({ patchState }: StateContext<AppStateModel>, response: { payload: TopicMappings[] }) {
    patchState({ topic_mappings: response.payload });
  }

  @Action(AddCategoriesTopics)
  addCategoriesTopicsByState({ patchState }: StateContext<AppStateModel>, response: { payload: any[] }) {
    patchState({ categories_topics: response.payload });
  }

  @Action(AddRegionMappings)
  addRegionMappingsByState(
    { getState, patchState }: StateContext<AppStateModel>,
    response: { payload: RegionMappings[] }
  ) {
    let region_mappings: RegionMappings[] = response.payload;
    region_mappings = region_mappings.map((region_mapping: RegionMappings) => {
      region_mapping.name = region_mapping.name.replace('- ', '');
      region_mapping.name = region_mapping.name.replace('> ', '');
      return region_mapping;
    });

    patchState({ region_mappings: region_mappings });
  }

  @Action(AddSelectedRegion)
  addSelectedRegionByState({ patchState }: StateContext<AppStateModel>, response: { payload: RegionMappings[] }) {
    patchState({ selected_regions: response.payload });
  }

  @Action(GetInitiativesNameMapping)
  getInitiativesNameMapping({ patchState }: StateContext<AppStateModel>, response: { payload }) {
    patchState({ initiativeNameMapping: response.payload });
  }

  @Action(SetSearchType)
  setSearchType({ patchState }: StateContext<AppStateModel>, response: { payload: SearchType }) {
    patchState({ searchType: response.payload });
  }

  @Action(SetNotifications)
  setNotifications({ patchState }: StateContext<AppStateModel>, response: { payload: AppNotification[] }) {
    patchState({ notifications: response.payload });
  }

  @Action(UpdateNotifications)
  updateNotifications({ getState, patchState }: StateContext<AppStateModel>, response: { payload: AppNotification }) {
    const state = getState();

    const index = state.notifications.findIndex((n) => n.id === response.payload.id);

    if (index !== -1) {
      const updatedNotifications = [...state.notifications];
      updatedNotifications[index] = response.payload;

      patchState({
        notifications: updatedNotifications,
      });
    }
  }
}
