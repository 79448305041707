<h1 mat-dialog-title>Nueva norma manual {{ title.value }}</h1>
<div mat-dialog-content class="container">
  <form class="form-container" #form="ngForm" [formGroup]="formGroup">
    <mat-form-field class="form-field">
      <mat-label>Nombre</mat-label>
      <input autocomplete="off" matInput type="text" formControlName="title" required />
      <button mat-button matSuffix mat-icon-button (click)="title.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>Escriba el nombre descriptivo de la norma que aparecerá en el calendario.</mat-hint>
      <mat-error *ngIf="title.invalid">
        <span *ngIf="title.errors.required || title.invalid">Campo requerido.</span>
      </mat-error>
    </mat-form-field>
  </form>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <button mat-flat-button (click)="onNoClick()" color="warn">Cancelar</button>
  <button mat-flat-button (click)="addNorm()" cdkFocusInitial color="primary">Guardar</button>
</div>
