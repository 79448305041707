import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef, Injector } from '@angular/core';
import { BaseChart } from '../base/base.component';
import { Formater } from '@modules/br-widgets/utils/formater';

@Component({
  selector: 'app-connectedchart',
  templateUrl: './connected.component.html',
  styleUrls: ['./connected.component.css'],
})
export class ConnectedComponent extends BaseChart implements OnInit, AfterViewInit {
  @ViewChild('line_dom') lineDom: ElementRef;
  @ViewChild('pie_dom') pieDom: ElementRef;

  options: any;
  constructor(public injector: Injector, private formater: Formater, public changeDetector: ChangeDetectorRef) {
    super(injector, changeDetector);
  }

  refresh(config: any, response: any) {
    let data = this.formater.formatDataset(config, response);

    this.options['tooltip'] = { trigger: 'axis', showContent: false };
    this.options['legend'] = { show: false, bottom: 10 };
    this.options['dataset'] = data;
    this.options['xAxis'] = { type: 'category' };
    this.options['yAxis'] = { gridIndex: 0 };
    this.options['grid'] = { top: '55%' };

    this.options['series'] = [
      { type: 'line', smooth: false, seriesLayoutBy: 'row' },
      { type: 'line', smooth: false, seriesLayoutBy: 'row' },
      { type: 'line', smooth: false, seriesLayoutBy: 'row' },
      { type: 'line', smooth: false, seriesLayoutBy: 'row' },
      {
        type: 'pie',
        id: 'pie',
        radius: '30%',
        center: ['50%', '25%'],
        label: {
          formatter: '{b}: {@' + config.dateParsed_start.format('YYYY-MM') + '} ({d}%)',
        },
        encode: {
          itemName: 'CATEGORIAS',
          value: config.dateParsed_start.format('YYYY-MM'),
          tooltip: config.dateParsed_start.format('YYYY-MM'),
        },
      },
    ];

    this.echartsInstance.on(
      'updateAxisPointer',
      function (event) {
        var xAxisInfo = event.axesInfo[0];
        if (xAxisInfo) {
          var dimension = xAxisInfo.value + 1;

          this.echartsInstance.setOption({
            series: {
              id: 'pie',
              label: {
                formatter: '{b}: {@[' + dimension + ']} ({d}%)',
              },
              encode: {
                value: dimension,
                tooltip: dimension,
              },
            },
          });
        }
      }.bind(this)
    );

    this.echartsInstance.setOption(this.options);

    this.isLoading = false;
    this.seriesLoaded.emit('connected');
  }

  ngOnInit() {}

  onChartInit(e) {
    this.echartsInstance = e;
  }

  onUpdateAxis() {
    this.echartsInstance.setOption(this.options);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let config = Object.assign({}, this.wconfig.config['query']);
    config['aggregations'] = this.wconfig.config['aggregations'];
    // remove this
    delete config['dateParsed_start'];
    delete config['dateParsed_end'];

    this.update(config, (config, response) => {
      this.refresh(config, response);
    });
  }
}
