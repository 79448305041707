import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import { MatDialog } from '@angular/material/dialog';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { MagazineStateModel, Magazine } from '../models/magazine.state.model';
import {
  GetMagazines,
  SetMagazine,
  AddMagazine,
  EditMagazine,
  DeleteMagazine,
  GetMagazineDrafts,
  GetMagazineDraft,
} from './magazine.actions';
import { Draft } from '../models/draft.state.model';

@Injectable({
  providedIn: 'root',
})
@State<MagazineStateModel>({
  name: 'magazine',
  defaults: {
    magazines: [],
    selectedMagazine: undefined,
    drafts: [],
  },
})
export class MagazineState {
  @Selector()
  static magazines(state: MagazineStateModel): Magazine[] | null {
    return state.magazines;
  }

  @Selector()
  static selectedMagazine(state: MagazineStateModel): Magazine | null {
    return state.selectedMagazine;
  }

  @Selector()
  static drafts(state: MagazineStateModel): Draft[] | null {
    return state.drafts;
  }

  constructor(private _dialogRef: MatDialog, private _snackbarStateService: SnackbarStateService) {}

  @Action(GetMagazines)
  getInitiativesByState({ patchState }: StateContext<MagazineStateModel>, response: { payload }) {
    let magazines = response.payload.map((magazine) => {
      magazine.drafts = magazine.drafts.sort((a, b) => (a.date_created > b.date_created ? -1 : 1));
      return magazine;
    });
    patchState({ magazines: magazines });
  }

  @Action(SetMagazine)
  setInitiativeByState({ patchState }: StateContext<MagazineStateModel>, response: { payload }) {
    patchState({ selectedMagazine: response.payload });
  }

  @Action(AddMagazine)
  addMagazineByState({ getState, patchState }: StateContext<MagazineStateModel>, response: { payload }) {
    let magazines = JSON.parse(JSON.stringify(getState())).magazines;
    magazines.push(response.payload);
    patchState({ magazines: magazines });
  }

  @Action(EditMagazine)
  editMagazineByState({ getState, patchState }: StateContext<MagazineStateModel>, response: { payload }) {
    let magazines = JSON.parse(JSON.stringify(getState())).magazines;

    magazines = magazines.map((magazine) => {
      if (magazine.id === response.payload.id) return response.payload;
      else return magazine;
    });

    patchState({ magazines: magazines });
  }

  @Action(DeleteMagazine)
  deleteMagazineByState({ getState, patchState }: StateContext<MagazineStateModel>, response: { payload }) {
    let magazines = JSON.parse(JSON.stringify(getState())).magazines;
    magazines = magazines.filter((magazine) => magazine.id === response.payload.id);
    patchState({ magazines: magazines });
  }

  @Action(GetMagazineDrafts)
  getMagazineDraftsByState({ getState, patchState }: StateContext<MagazineStateModel>, response: { payload }) {
    let drafts = JSON.parse(JSON.stringify(getState())).drafts;
    drafts.push(response.payload);
    patchState({ drafts: drafts });
  }

  // TODO: Pachear el estado, tal vez esto debería estar en los drafts y no en los magazines
  @Action(GetMagazineDraft)
  getMagazineDraftByState({ patchState }: StateContext<MagazineStateModel>, response: { payload }) {}
}
