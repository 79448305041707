import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import { AppStateService } from '@app/state/app/app.service';
import _moment from 'moment';
import $ from 'jquery';
import { CalendarView, CalendarEvent, DAYS_OF_WEEK } from 'angular-calendar';
import { colors } from '@app/utils/colors';
import { NormFormDialog } from './norm-form.component';
import { MatDialog } from '@angular/material/dialog';
import { isSameMonth, isSameDay } from 'date-fns';
import { InitiativeStateService } from '../../store/initiative/initiative.service';
import { UIInitiativeStatus, Initiative } from '../../store/models/initiative.state.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Agenda, AgendaEvent, PaginatedResults, RegionMappings, UIStatus } from '@app/state/models/app.state.model';
import { Subscription } from 'rxjs';
import { InitiativeDetailComponent } from './initiative-detail/initiative-detail.component';
import { ManualFormDialog } from './manual-form.component';
import Utils from '@app/utils/utils';
import { BrowserJsonp } from '@angular/http/src/backends/browser_jsonp';
import { Spider } from '@app/models';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { GetAgendas, GetAgendasEvents } from '@app/state/app/app.actions';
import { stripGeneratedFileSuffix } from '@angular/compiler/src/aot/util';
import spidersColorsJson from '@assets/spiders-colors.json';
import moment from 'moment';
import { GuidedTour, GuidedTourService, Orientation } from 'ngx-guided-tour';
import { DateRange } from '@angular/material/datepicker';
import { saveAs } from 'file-saver';
import { DateAdapter } from '@angular/material/core';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { Router } from '@angular/router';
import { AppDataService } from '@app/services/app-data.service';
import { AgendasStateService } from '@app/modules/documents/store/agendas/agendas.service';
import { MenuItem, NavItem } from '@app/models/User';
import { timeout } from 'rxjs/operators';

_moment.updateLocale('es', {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0,
  },
});

@Component({
  selector: 'app-agenda',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [animate('0.2s 100ms ease-out'), style({ transform: 'translateX(-100%)' })]),
      transition(':leave', [style({ transform: 'translateX(100%)' }), animate('0.2s 100ms ease-in')]),
    ]),
  ],
})
export class AgendaComponent implements OnInit, OnDestroy {
  subItemName = '';
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  UIInitiativeView = UIInitiativeStatus;
  spidersColors: { id: number; name: string; color: string }[] = spidersColorsJson;

  subscription: Subscription;
  initiatives: Initiative[];

  // displayedColumns: string[] = ['presented', 'title', 'tools'];
  displayedColumns: string[] = ['presented', 'title'];
  agendaEventslegend: { spider: { id: number; name: string }; color: string }[] = [];

  agendasEventsExecutedQuery = false;

  selectedDateRange: DateRange<Date>;
  downloadingExcel = false;
  downloadingPdf = false;

  dropdownSpiderGroupsList = [];
  selectedSpiderGroupsItems = [];
  dropdownSpiderGroupsSettings = {
    text: 'Agenda',
    primaryKey: 'itemName',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Eliminar Selección',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 1,
    // groupBy: 'spiders',
    selectGroup: false,
    showCheckbox: false,
    singleSelection: true,
  };

  dropdownSpidersList = [];
  selectedSpidersItems = [];
  dropdownSpidersSettings = {
    text: 'Fuentes',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Eliminar Selección',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 2,
    // groupBy: 'spiders',
    selectGroup: false,
    showCheckbox: true,
  };

  dropdownTagsList = [];
  selectedTagsItems = [];
  dropdownTagsSettings = {
    text: 'Tags',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Eliminar Selección',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 2,
    // groupBy: 'tags',
    selectGroup: false,
    showCheckbox: true,
  };

  guidedTour: GuidedTour = {
    tourId: 'tour-demo',
    steps: [
      {
        selector: '#am2-1',
        title: 'Selección de agenda',
        content: 'Escoge una agenda sobre la que buscar las entradas',
        orientation: Orientation.Left,
      },
      {
        selector: '#am2-2',
        title: 'Selección de fuentes',
        content: 'Escoge todas las fuentes oportunas para acotar los resultados',
        orientation: Orientation.Left,
      },
      {
        selector: '.search-entries',
        title: 'Buscar entradas',
        content: 'Clica en "Buscar entradas" para obtener los resultados',
        orientation: Orientation.Left,
      },
    ],
  };

  constructor(
    public appStateService: AppStateService,
    public appDataService: AppDataService,
    public dialog: MatDialog,
    public initiativeStateService: InitiativeStateService,
    public agendasStateService: AgendasStateService,
    private authStateService: AuthStateService,
    private actions: Actions,
    private ref: ChangeDetectorRef,
    private router: Router,
    private guidedTourService: GuidedTourService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('es-ES');
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
    this.appStateService.addStateSelectedRegions([
      { id: 1, name: 'España', visibleName: 'España', order: 2, regions: [10] },
    ]);
    this.appStateService.addTitle(`Agenda política y parlamentaria`);

    this.initSelectedDateRange();
    this.getAgendas();
  }

  ngOnInit(): void {
    this.appStateService.showTitle();
    this.appStateService.hideDates();
    this.appStateService.hideSearchText();
    this.appStateService.hideSubForm();
    this.appStateService.updateUIStatus(UIStatus.loading);

    this.subItemName = this.getSubitemName();
    this.showCalendar();
    this.listenSpidersMultiselectDropdown();
    this.showGuidedTour();
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }

  getAgendas(): void {
    this.actions.pipe(ofActionSuccessful(GetAgendas)).subscribe(() => {
      this.getSpiderGroups();
      this.touchScreen();
    });

    this.appStateService.getAgendas({ path: this.router.url.slice(1) });
  }

  getAgendasEvents(): void {
    this.actions.pipe(ofActionSuccessful(GetAgendasEvents)).subscribe(() => {
      this.viewDate = this.selectedDateRange.start;
      this.activeDayIsOpen = false;
      this.setAgendasEvents();
    });

    this.appStateService.getAgendasEvents({
      spiderIds: this.selectedSpidersItems.map((s) => String(s.id)),
      from: moment(this.selectedDateRange.start).format('YYYY-MM-DD'),
      to: moment(this.selectedDateRange.end).format('YYYY-MM-DD'),
    });
  }

  setAgendasEvents(): void {
    this.events = [];
    this.dropdownTagsList = [];
    this.selectedTagsItems = [];
    this.agendasEventsExecutedQuery = true;
    this.appStateService.agendasEvents.map((ag) => {
      this.dropdownTagsList = [
        ...this.dropdownTagsList,
        ...ag.tags.map((t) => {
          return JSON.parse(
            JSON.stringify({
              id: Math.floor(Math.random() * 10000000),
              itemName: t,
            })
          );
        }),
      ];

      this.selectedTagsItems = JSON.parse(JSON.stringify(this.dropdownTagsList));

      ag.events.map((age) => {
        this.pushEvent(age);
      });
    });

    this.touchScreen();
  }

  pushEvent(age: { tags: string[]; body: string; spiderId: number; datetime: Date; title: string }): void {
    if (
      !['Comunidades Autónomas', 'Agendas Parlamentarias'].includes(this.selectedSpiderGroupsItems[0].label) ||
      (['Comunidades Autónomas', 'Agendas Parlamentarias'].includes(this.selectedSpiderGroupsItems[0].label) &&
        age.tags.length > 0)
    ) {
      this.agendaEventslegend = [];

      this.events.push({
        title: `- ${age.title.toUpperCase()}`,
        color: _moment(age.datetime).toDate().getMilliseconds() <= _moment.now() ? colors.green : colors.red,
        start: _moment(age.datetime).toDate(),
        meta: age,
        // cssClass: _moment(age.datetime).toDate().getMilliseconds() <= _moment.now() ? 'color-green' : 'color-red',
      });

      this.events = this.events.sort(
        (a, b) => new Date(a.meta.datetime).getTime() - new Date(b.meta.datetime).getTime()
      );
    }
  }

  filterAgendasEventsByTag(): void {
    this.events = [];

    this.appStateService.agendasEvents.map((ag) => {
      ag.events.map((age) => {
        if (
          age.tags.length === 0 ||
          age.tags.some((t) =>
            this.selectedTagsItems
              .map((tf) => {
                return tf.itemName;
              })
              .includes(t)
          )
        ) {
          this.pushEvent(age);
        }
      });
    });
  }

  touchScreen(): void {
    setTimeout(() => {
      const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
        clientX: 0,
        clientY: 0,
      });
      document.body.dispatchEvent(evt);
      setTimeout(() => {
        document.body.dispatchEvent(evt);
      }, 25);
    }, 0);
  }

  getSpiderGroups(): void {
    this.agendasEventsExecutedQuery = false;

    this.dropdownSpiderGroupsList = JSON.parse(
      JSON.stringify([
        ...this.appStateService.agendas.items.map((obj) => {
          return JSON.parse(JSON.stringify({ ...obj, itemName: obj.label }));
        }),
      ])
    );

    const agendaFilters = JSON.parse(JSON.stringify({ ...this.appStateService.agendaFilters }));

    if (agendaFilters && agendaFilters.spiderGroup) {
      // this.selectedSpiderGroupsItems = JSON.parse(JSON.stringify([...[agendaFilters.spiderGroup]]));
      this.selectedSpiderGroupsItems = JSON.parse(
        JSON.stringify([
          this.dropdownSpiderGroupsList.find((s) => s.itemName == agendaFilters.spiderGroup.itemName)
            ? agendaFilters.spiderGroup
            : this.dropdownSpiderGroupsList[0],
        ])
      );

      this.getSpiders(
        this.dropdownSpiderGroupsList.find((s) => s.itemName == agendaFilters.spiderGroup.itemName)
          ? agendaFilters.spiderGroup
          : this.dropdownSpiderGroupsList[0]
      );
    }
  }

  getSpiders(spiderGroup: { label: string; value: number; items: { label: string; value: number }[] }): void {
    this.agendasEventsExecutedQuery = false;

    this.dropdownSpidersList = JSON.parse(
      JSON.stringify([
        ...spiderGroup.items.map((obj) => {
          return JSON.parse(JSON.stringify({ ...obj, id: obj.value, itemName: obj.label }));
        }),
      ])
    );

    const agendaFilters = JSON.parse(JSON.stringify({ ...this.appStateService.agendaFilters }));

    if (agendaFilters && agendaFilters.spiders && agendaFilters.spiders.length > 0) {
      this.selectedSpidersItems = JSON.parse(JSON.stringify([...agendaFilters.spiders]));
    }

    if (
      this.selectedSpidersItems.length > 0 &&
      !spiderGroup.items.map((s) => s.value).includes(this.selectedSpidersItems[0].value)
    ) {
      this.selectedSpidersItems = [];
    }
  }

  updateSpiderGroupsItems(spiderGroup?: {
    label: string;
    value: number;
    items: { label: string; value: number }[];
  }): void {
    if (spiderGroup) {
      this.getSpiders(spiderGroup);
    }

    this.appStateService.updateAgendaFilters({
      ...this.appStateService.agendaFilters,
      spiderGroup: spiderGroup,
    });
  }

  updateSpidersItems(): void {
    this.appStateService.updateAgendaFilters({
      ...this.appStateService.agendaFilters,
      items: JSON.parse(JSON.stringify([...this.selectedSpidersItems])),
    });
  }

  search(): void {
    this.getAgendasEvents();
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    // this.initiativeStateService.setInitiative(event.meta);
    // const dialogRef = this.dialog.open(InitiativeDetailComponent, {
    //   width: '100%',
    //   height: '90%',
    // });
  }

  showInitiative(initiative: Initiative): void {
    // this.initiativeStateService.setInitiative(initiative);
    // const dialogRef = this.dialog.open(InitiativeDetailComponent, {
    //   width: '100%',
    //   height: '90%',
    // });
  }

  followInitiative(initiative: Initiative): void {
    // if (this.appStateService.selected_regions[0].id == 1) {
    //   this.initiativeStateService.addInitiative({ title: initiative.title, url: initiative.link });
    // } else this.initiativeStateService.followInitiative(initiative);
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent<any>[] }): void {
    if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
      this.viewDate = date;
    }

    this.setAgendaseventsLegend(events);
    this.touchScreen();
  }

  setAgendaseventsLegend(events: CalendarEvent<any>[]): void {
    this.agendaEventslegend = events.reduce((unique, e) => {
      const spiderId = e.meta.spiderId;
      const spiderName = this.getSpiderNameById(spiderId);
      const spiderColor = this.getSpiderColor(spiderId);

      const existingSpider = unique.find((item) => item.spider.id === spiderId);

      if (!existingSpider) {
        unique.push({
          spider: {
            id: spiderId,
            name: spiderName,
          },
          color: spiderColor,
        });
      }

      return unique;
    }, []);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NormFormDialog, {
      width: '650px',
    });
  }

  openManualDialog(): void {
    const dialogRef = this.dialog.open(ManualFormDialog, {
      width: '650px',
    });
  }

  showCalendar(): void {
    this.initiativeStateService.showCalendar();
  }

  showList(): void {
    this.initiativeStateService.showList();
  }

  handleAngularMultiselectHeight(id: string): void {
    const select = $(`#${id} .list-area div:last-child`)[0];

    if (select) {
      const windowHeight = $(window).height();
      const selectTopDistance = select.getBoundingClientRect().top;

      if (select.clientHeight > 0 && windowHeight > 0 && selectTopDistance > 0) {
        select.style.maxHeight = `${windowHeight - selectTopDistance - 24}px`;
      }
    }
  }

  getSpiderNameById(id: number): string {
    const spider = this.dropdownSpidersList.find((s) => s.id === id);
    return spider ? spider.label : '';
  }

  getSpiderIdByName(name: string): number {
    const spider = this.dropdownSpidersList.find((s) => s.label === name);
    return spider ? spider.id : 0;
  }

  getSpiderColor(id: number): string {
    const spiderSelected = this.spidersColors.find((sc) => sc.id === id);
    return spiderSelected ? spiderSelected.color : '#00000010';
  }

  listenSpidersMultiselectDropdown(): void {
    setTimeout(() => {
      const cBtn = document.querySelector('#am2-2 .c-btn');

      if (cBtn) {
        const observer = new MutationObserver((mutations_list) => {
          mutations_list.forEach((mutation) => {
            mutation.addedNodes.forEach(() => {
              for (const child of [].slice.call(cBtn.querySelector('.c-list')?.children)) {
                child.style.backgroundColor = this.getSpiderColor(
                  this.getSpiderIdByName(child.querySelector('.c-label').innerText)
                );
              }
            });
          });
        });

        observer.observe(cBtn, { subtree: true, childList: true });
      }
    }, 0);
  }

  // selectRegion(region: RegionMappings) {
  //   this.appStateService.addStateSelectedRegions([region]);
  //   if (region.id == 1) {
  //     this.initiativeStateService.getOpenInitiatives();
  //   } else {
  //     this.initiativeStateService.getOpenInitiativesCCAA(region.regions[0]);
  //   }

  //   this.appStateService.addTitle(`Agendas`);
  // }

  showGuidedTour(): void {
    setTimeout(() => {
      if (this.appStateService.showAgendasTutorial) {
        this.guidedTourService.startTour(this.guidedTour);
        this.appStateService.showAgendasTutorialF(false);
      }
    }, 0);
  }

  downloadExcel() {
    this.downloadingExcel = true;

    const startDate: string = moment(this.selectedDateRange.start).format('YYYY-MM-DD');
    const endDate: string = moment(this.selectedDateRange.end).format('YYYY-MM-DD');

    this.appDataService
      .downloadWeeklyAgenda({
        spiderIds: this.selectedSpidersItems.map((s) => String(s.id)),
        from: startDate,
        to: endDate,
        export: 'xls',
      })
      .subscribe(
        (data) => {
          const filename = `agenda_${startDate}_${endDate}.xlsx`;
          saveAs(data, filename);

          this.downloadingExcel = false;
          this.touchScreen();
        },
        () => {
          this.downloadingExcel = false;
          this.touchScreen();
        }
      );
  }

  downloadPdf() {
    this.downloadingPdf = true;

    const startDate: string = moment(this.selectedDateRange.start).format('YYYY-MM-DD');
    const endDate: string = moment(this.selectedDateRange.end).format('YYYY-MM-DD');

    this.appDataService
      .downloadWeeklyAgenda({
        spiderIds: this.selectedSpidersItems.map((s) => String(s.id)),
        from: startDate,
        to: endDate,
        export: 'pdf',
      })
      .subscribe(
        (data) => {
          const filename = `agenda_${startDate}_${endDate}.pdf`;
          saveAs(new Blob([data], { type: 'application/pdf' }), filename);

          this.downloadingPdf = false;
          this.touchScreen();
        },
        () => {
          this.downloadingPdf = false;
          this.touchScreen();
        }
      );
  }

  _onSelectedDateChange(date: Date): void {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay() + 1);

    const endOfWeek = new Date(date);
    endOfWeek.setDate(date.getDate() + (6 - date.getDay() + 1));

    this.selectedDateRange = new DateRange(startOfWeek, endOfWeek);
  }

  initSelectedDateRange() {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay() + 1);

    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - today.getDay() + 1));

    this.selectedDateRange = new DateRange(startOfWeek, endOfWeek);
  }

  getSubitemName(): string {
    const activeSubItem = this.findActiveSubItem();

    if (activeSubItem.parent.id === 6) {
      // Vigilancia legislativa
      if (this.router.url.startsWith('/vigilancia/tnor')) {
        return `${activeSubItem?.child?.items.find((i) => this.router.url.startsWith(i.path)).parent_module} | ${
          activeSubItem?.child?.items.find((i) => this.router.url.startsWith(i.path)).title
        }`;
      } else {
        return activeSubItem?.child?.items.find((i) => this.router.url.includes(i.path)).title ?? '';
      }
    } else if (activeSubItem.parent.id === 7) {
      // Europa
      if (this.router.url.startsWith('/europe/actividadpoliticaue')) {
        return `${activeSubItem?.child?.items.find((i) => this.router.url.startsWith(i.path)).parent_module} | ${
          activeSubItem?.child?.items.find((i) => this.router.url.startsWith(i.path)).title
        }`;
      } else {
        return activeSubItem?.child?.items.find((i) => this.router.url.includes(i.path)).title ?? '';
      }
    } else {
      return activeSubItem?.parent?.items.find((i) => this.router.url.includes(i.path)).title ?? '';
    }
  }

  findActiveSubItem(): { parent: MenuItem; child: NavItem } | null {
    for (const menuItem of this.authStateService.userConfig.interface_config.menu_items) {
      for (const subItem of menuItem.items) {
        if (subItem.path && window.location.href.includes(subItem.path)) {
          return {
            parent: menuItem,
            child: subItem,
          };
        }
      }
    }

    return null;
  }

  resetFilters(): void {
    this.initSelectedDateRange();
    this.selectedSpiderGroupsItems = [];
    this.selectedSpidersItems = [];
    this.agendasEventsExecutedQuery = false;
  }
}
