<div class="example-container-parent">
  <div class="example-container">
    <div class="fist-form">
      <p class="title">Mis carpetas</p>

      <mat-grid-list cols="1" rowHeight="250px" *ngIf="noFavs && !isLoadingResults">
        <mat-grid-tile>
          <mat-card class="mat-elevation-z0">
            <img mat-card-image class="search-logo" src="{{ LOGOURL }}" alt="Logo" />
            <mat-card-content>
              <h2>No existen documentos favoritos registrados en el sistema para este usuario</h2>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="1" rowHeight="250px" *ngIf="!noFavs && isRateLimitReached && !isLoadingResults">
        <mat-grid-tile>
          <mat-card class="mat-elevation-z0">
            <mat-card-content>
              <h2>Hemos encontrado un problema con las comunicaciones, inténtelo de nuevo más tarde.</h2>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="1" rowHeight="250px" *ngIf="noFavs && !firstLoad && !isLoadingResults">
        <mat-grid-tile>
          <mat-card class="mat-elevation-z0">
            <mat-card-content>
              <h2>No se han registrado documentos favoritos.</h2>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <div
      [ngClass]="verbarra ? 'example-table-container' : 'ocultabarra'"
      class="example-table-container"
      *ngIf="!firstLoad && (!noFavs || isLoadingResults)"
    >
      <table
        mat-table
        [dataSource]="items"
        class="example-table"
        matSort
        matSortActive="dateParsed"
        matSortDirection="desc"
      >
        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Documentos</th>
          <td mat-cell *matCellDef="let row">
            <div class="abstractRow">
              <h2 (click)="opendocument(row)">{{ row.title.trim() | titlecase }}</h2>
              <div class="header">
                <p>Extraído de {{ row.doc_source }}</p>
                <p>{{ row.date_published }}</p>
              </div>
              <p [innerHTML]="row.highligth?.trim() | ellipsis: 450"></p>
              <!--div class="categorias">{{row.categories.join(', ')}}</div-->
              <mat-chip-list>
                <mat-chip *ngFor="let category of row.categories">{{ category }}</mat-chip>
              </mat-chip-list>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <th
          scope="col"
          mat-row
          *matNoDataRow
          class="no-data-row"
          style="display: flex; align-items: center; gap: 12px; padding: 16px"
        >
          <mat-spinner [diameter]="24" *ngIf="isLoadingResults"></mat-spinner>
          <p style="margin: 0; font-weight: 500" *ngIf="isLoadingResults">Cargando datos...</p>
          <p style="margin: 0; font-weight: 500" *ngIf="!isLoadingResults && items.length === 0">
            No hay carpetas favoritas
          </p>
        </th>
      </table>
    </div>

    <mat-paginator [length]="resultsLength" [pageSize]="30" *ngIf="!firstLoad && !noFavs"></mat-paginator>
  </div>
</div>
<div>
  <app-paneldoc [doc_item]="doc_item" *ngIf="documentpanel !== undefined" (close)="closepanel()"></app-paneldoc>
</div>
