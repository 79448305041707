import { Input, Component, OnInit, OnDestroy } from '@angular/core';
import { UserIdentity } from '@app/models/UserIdentity';
import { AppStatusService } from '@app/services/app-status.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { UtilsService } from '@app/services/utils.service';
import { AppStateService } from '@app/state/app/app.service';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
})
export class NavListComponent implements OnInit, OnDestroy {
  @Input() menu_items = [];

  user: UserIdentity;
  menuItems = [];
  mapaview: boolean = false;
  visible_layers = [];
  loading: boolean = false;
  loaded: boolean = false;
  environment = environment;

  subscriptions: Subscription[] = [];

  constructor(
    public utilsService: UtilsService,
    public authStateService: AuthStateService,
    public router: Router,
    private appStateService: AppStateService,
    private appStatus: AppStatusService
  ) {
    let map_subscription = this.appStatus.map_ready.subscribe((layers) => {
      this.mapaview = true;
      this.visible_layers = [];
      layers.forEach((layer_config) => {
        if (layer_config.layerStack) {
          this.visible_layers.push(layer_config);
        } else if (layer_config.layer.visibility || layer_config.layer.visibility.base)
          this.visible_layers.push(layer_config.layer);
      });
    });

    this.subscriptions.push(map_subscription);

    let app_status_subscription = this.appStatus.page_change.subscribe((page) => {
      if (!page.match('/geo')) this.mapaview = false;
    });

    this.subscriptions.push(app_status_subscription);
  }

  ngOnInit(): void {
    this.appStatus.open_map.emit();
    this.setMenuItems();
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }

  newLayer() {
    this.appStatus.add_layer.emit();
  }

  onChange(layer) {
    this.appStatus.change_layer.emit(layer);
  }

  setLoading() {
    this.loading = true;
  }

  setLoaded() {
    this.loaded = false;
  }

  onMapReady($event) {
    this.appStatus.map_ready.emit($event);
  }

  mapChange($event) {
    this.appStatus.map_change.emit($event);
  }

  setMenuItems(): void {
    this.menuItems = this.authStateService.menuItems.filter((mi) => mi.header !== 'Documentos');
  }

  isAnySubitemActive(parentItem: any): boolean {
    return (
      parentItem.items.some((item) => {
        return this.router.isActive(item.path, false);
      }) &&
      parentItem.items.length > 0 &&
      Object.keys(parentItem.items[0]).length > 0
    );
  }

  isRouterLinkActive(routerLink: string): boolean {
    if (this.router.url.split('?')[0] === routerLink) {
      return true;
    }

    return false;
  }

  routerLinkClick(routerLink: string): void {
    if (this.router.url.split('?')[0] !== routerLink) {
      this.appStateService.hideSearchText();
    }
  }
}
