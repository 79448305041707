<div class="csv-btn-container">
  <button mat-flat-button color="primary" (click)="downloadExcel()" *ngIf="data && showTable">
    Descargar datos
    <mat-icon>download</mat-icon>
  </button>
</div>
<div class="container-main" *ngIf="data && showTable">
  <table mat-table [dataSource]="tableData">
    <ng-container matColumnDef="Frecuencia" sticky>
      <th mat-header-cell *matHeaderCellDef>Frecuencia</th>
      <td mat-cell *matCellDef="let row">
        <p>{{ row.name }}</p>
      </td>
    </ng-container>
    <ng-container [matColumnDef]="data.title" *ngFor="let data of data.data; let i = index">
      <th mat-header-cell *matHeaderCellDef>{{ data.title }}</th>
      <td mat-cell *matCellDef="let row">
        <p>
          {{
            row.values[i] === null
              ? '-'
              : data.is_percentage
              ? (row.values[i] | number: '1.1-1')
              : (row.values[i] | number)
          }}
        </p>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <th scope="col" mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <p style="margin: 0" *ngIf="tableData.length === 0">No hay datos</p>
    </th>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
