<div class="container-main" *ngIf="data && showGraph" [formGroup]="form">
  <!-- <div class="dates" *ngIf="data.graph_type === 'map'">
    <div
      class="date"
      [ngClass]="{ selected: date === dateSelected }"
      *ngFor="let date of dates"
      (click)="selectDate(date)"
    >
      {{ date }}
    </div>
  </div> -->
  <mat-form-field appearance="fill" *ngIf="data.graph_type === 'map'">
    <mat-label>Fecha</mat-label>
    <mat-select panelClass="select-panel select-panel-text-wrap-options" formControlName="date">
      <mat-option *ngFor="let date of dates" [value]="date">
        {{ date }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="chart">
    <div
      echarts
      class="chart"
      [options]="options"
      [ngStyle]="{
        height: page === 'home' ? '400px' : page === 'detail' ? (data.graph_type === 'map' ? '400px' : '600px') : 'auto'
      }"
    ></div>
    <p class="view-detail" *ngIf="page === 'home'" (click)="navigateToDetail()">Ver todos los indicadores</p>
    <button
      mat-stroked-button
      color="primary"
      class="change-graph-btn"
      [class]="data.graph_type"
      *ngIf="page === 'detail' && canUseMap"
      (click)="changeGraph()"
    >
      <ng-container *ngIf="data.graph_type === 'map'">
        Cambiar gráfica
        <mat-icon>bar_chart</mat-icon>
      </ng-container>
      <ng-container *ngIf="data.graph_type === 'lines'">
        Cambiar gráfica
        <mat-icon>map</mat-icon>
      </ng-container>
    </button>
  </div>
</div>
