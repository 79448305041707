import { environment } from '@env/environment';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

export const KC_URL_DEV = 'https://kcdev.ceoe.tech';
export const KC_URL_PROD = 'https://idam.ceoe.tech';

export const KC_REALM_BRAINY = 'BRAINY';
export const KC_REALM_CEOE = 'ceoe';

export const KC_CLIENTID_BRAINY = 'brainy-frontal';
export const KC_CLIENTID_CEOE = 'ceoe-data';

// export const BRAINY_URL = 'https://dashboard.brainyinteligencia.com/';
// export const BRAINYTEST_URL = 'https://testdashboard.brainyinteligencia.com/';
// export const CEOE_URL = 'https://ia.ceoe.tech/';
// export const CEOETEST_URL = 'https://ceoetest.brainyinteligencia.com/';

export function initializeKeycloak(keycloak: KeycloakService) {
  keycloak.keycloakEvents$.subscribe({
    next: (e) => {
      if (e.type == KeycloakEventType.OnTokenExpired) {
        keycloak.updateToken(30);
      }
    },
  });

  return () =>
    keycloak
      .init({
        config: {
          url: getUrl(),
          realm: getRealm(),
          clientId: getClientId(getRealm()),
        },
        initOptions: {
          checkLoginIframe: false,
        },
      })
      .catch((e) => {
        console.log('Error thrown in init ' + e);
      });
}

export function getUrl(): string {
  if (environment.type === 'prod' || environment.type === 'ceoedev' || environment.type === 'ceoe') {
    return KC_URL_PROD;
  } else {
    return KC_URL_DEV;
  }

  // switch (true) {
  //   case window.location.href.startsWith(BRAINY_URL) ||
  //     window.location.href.startsWith(CEOE_URL) ||
  //     window.location.href.startsWith(CEOETEST_URL):
  //     return KC_URL_PROD;
  //   default:
  //     return KC_URL_DEV;
  //     return KC_URL_PROD;
  // }
}

export function getRealm(): string {
  if (environment.type === 'ceoedev' || environment.type === 'ceoe') {
    return KC_REALM_CEOE;
  } else {
    return KC_REALM_BRAINY;
  }

  // switch (true) {
  //   case window.location.href.startsWith(BRAINY_URL) || window.location.href.startsWith(BRAINYTEST_URL):
  //     return KC_REALM_BRAINY;
  //   case window.location.href.startsWith(CEOE_URL) || window.location.href.startsWith(CEOETEST_URL):
  //     return KC_REALM_CEOE;
  //   default:
  //     return KC_REALM_BRAINY;
  //     return KC_REALM_CEOE;
  // }
}

export function getClientId(realm: string): string {
  switch (realm) {
    case KC_REALM_BRAINY:
      return KC_CLIENTID_BRAINY;
    case KC_REALM_CEOE:
      return KC_CLIENTID_CEOE;
    default:
      return KC_CLIENTID_BRAINY;
  }
}
