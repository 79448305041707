import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PutAlert } from '@app/modules/ceoe/store/subventions/subventions.actions';
import { SubventionsStateService } from '@app/modules/ceoe/store/subventions/subventions.service';
import { UtilsService } from '@app/services/utils.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import moment from 'moment';
import { Subvention, SubventionsFilters, SubventionsGetRequest } from '../../../models/subvention';

@Component({
  selector: 'app-subventions-alerts-edit-dialog',
  templateUrl: './subventions-alerts-edit-dialog.component.html',
  styleUrls: ['./subventions-alerts-edit-dialog.component.scss'],
})
export class SubventionsAlertsEditDialogComponent {
  filters?: SubventionsFilters;
  filtersForm: FormGroup;
  filtersPublishmentDateRangeForm: FormGroup;
  filtersFinalizationDateRangeForm: FormGroup;
  loading = false;

  dropdownComunidadesList = [];
  selectedComunidadesItems = [];
  dropdownComunidadesSettings = {
    text: 'Comunidades',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  dropdownSectorEconomicoList = [];
  selectedSectorEconomicoItems = [];
  dropdownSectorEconomicoSettings = {
    text: 'Sector económico',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  dropdownProvinciasList = [];
  selectedProvinciasItems = [];
  dropdownProvinciasSettings = {
    text: 'Provincias',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public subventionsStateService: SubventionsStateService,
    private actions: Actions,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private utilsService: UtilsService
  ) {
    this.filtersForm = this.fb.group({
      titulo: [this.data.titulo],
      presupuesto: this.fb.array([]),
      fondos: this.fb.array([]),
      instrumento_ayuda: this.fb.array([]),
      comunidades: this.fb.array([]),
      provincias: this.fb.array([]),
      sector_economico: this.fb.array([]),
      organismo_convocante: this.fb.array([]),
      estado: this.fb.array([]),
      beneficiarios: this.fb.array([]),
      finalidad: this.fb.array([]),

      comunidadesDropdown: [
        this.data && this.data.busqueda.comunidades
          ? (this.data.busqueda.comunidades as string[]).map((c) => {
              return {
                id: c,
                itemName: this.subventionsStateService.filters.filters
                  .find((f) => f.attr === 'comunidades')
                  .items.find((i) => String(i.value) === c).label,
                name: this.utilsService.removeDiacritics(
                  this.subventionsStateService.filters.filters
                    .find((f) => f.attr === 'comunidades')
                    .items.find((i) => String(i.value) === c).label
                ),
              };
            })
          : [],
      ],
      provinciasDropdown: [
        this.data && this.data.busqueda.provincias
          ? (this.data.busqueda.provincias as string[]).map((c) => {
              return {
                id: c,
                itemName: this.subventionsStateService.filters.filters
                  .find((f) => f.attr === 'provincias')
                  .items.find((i) => String(i.value) === c).label,
                name: this.utilsService.removeDiacritics(
                  this.subventionsStateService.filters.filters
                    .find((f) => f.attr === 'provincias')
                    .items.find((i) => String(i.value) === c).label
                ),
              };
            })
          : [],
      ],
      sector_economicoDropdown: [
        this.data && this.data.busqueda.sector_economico
          ? (this.data.busqueda.sector_economico as string[]).map((c) => {
              return {
                id: c,
                itemName: this.subventionsStateService.filters.filters
                  .find((f) => f.attr === 'sector_economico')
                  .items.find((i) => String(i.value) === c).label,
                name: this.utilsService.removeDiacritics(
                  this.subventionsStateService.filters.filters
                    .find((f) => f.attr === 'sector_economico')
                    .items.find((i) => String(i.value) === c).label
                ),
              };
            })
          : [],
      ],
    });

    this.selectedComunidadesItems = this.filtersForm.controls.comunidadesDropdown.value;
    this.selectedProvinciasItems = this.filtersForm.controls.provinciasDropdown.value;
    this.selectedSectorEconomicoItems = this.filtersForm.controls.sector_economicoDropdown.value;

    this.filtersPublishmentDateRangeForm = this.fb.group({
      start: [
        this.data && this.data.busqueda.fecha_publicacion_desde
          ? moment(this.data.busqueda.fecha_publicacion_desde, 'DD/MM/YYYY').toDate()
          : undefined,
      ],
      end: [
        this.data && this.data.busqueda.fecha_publicacion_hasta
          ? moment(this.data.busqueda.fecha_publicacion_hasta, 'DD/MM/YYYY').toDate()
          : undefined,
      ],
    });

    this.filtersFinalizationDateRangeForm = this.fb.group({
      start: [
        this.data && this.data.busqueda.fecha_finalizacion_desde
          ? moment(this.data.busqueda.fecha_finalizacion_desde, 'DD/MM/YYYY').toDate()
          : undefined,
      ],
      end: [
        this.data && this.data.busqueda.fecha_finalizacion_hasta
          ? moment(this.data.busqueda.fecha_finalizacion_hasta, 'DD/MM/YYYY').toDate()
          : undefined,
      ],
    });

    this.filters = this.subventionsStateService.filters;
    this.setData();
    this.populateDropdowns();
  }

  setData(): void {
    this.subventionsStateService.filters.filters.forEach((filter) => {
      filter.items.forEach((item, i) => {
        (this.filtersForm.controls[filter.attr] as FormArray).controls.push(
          this.fb.group({
            checked:
              this.data && this.data.busqueda[filter.attr] && this.data.busqueda[filter.attr].length > 0
                ? (this.data.busqueda[filter.attr] as any)[0].to || (this.data.busqueda[filter.attr] as any)[0].from
                  ? (this.data.busqueda[filter.attr] as any).some((v) => {
                      return v.from === (item.value as any)[0].from && v.to === (item.value as any)[0].to;
                    })
                    ? true
                    : false
                  : this.data.busqueda[filter.attr].includes(String(item.value)) ||
                    this.data.busqueda[filter.attr].includes(Number(item.value))
                : false,
            label: item.label,
            value: Array.isArray(item.value) ? [item.value] : item.value,
          })
        );
      });
    });
  }

  save(): void {
    this.filtersForm.markAllAsTouched();

    if (this.filtersForm.valid) {
      this.loading = true;

      const request = {
        titulo: this.filtersForm.controls.titulo.value,

        presupuesto: (this.filtersForm.controls.presupuesto as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => v.value)
          .reduce((acc, val) => acc.concat(val), []),

        fondos: (this.filtersForm.controls.fondos as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => v.value)
          .reduce((acc, val) => acc.concat(val), []),

        instrumento_ayuda: (this.filtersForm.controls.instrumento_ayuda as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => String(v.value))
          .reduce((acc, val) => acc.concat(val), []),

        comunidades: (this.filtersForm.controls.comunidadesDropdown as FormArray).value
          .map((v) => String(v.id))
          .reduce((acc, val) => acc.concat(val), []),

        provincias: (this.filtersForm.controls.provinciasDropdown as FormArray).value
          .map((v) => String(v.id))
          .reduce((acc, val) => acc.concat(val), []),

        sector_economico: (this.filtersForm.controls.sector_economicoDropdown as FormArray).value
          .map((v) => String(v.id))
          .reduce((acc, val) => acc.concat(val), []),

        organismo_convocante: (this.filtersForm.controls.organismo_convocante as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => String(v.value))
          .reduce((acc, val) => acc.concat(val), []),

        estado: (this.filtersForm.controls.estado as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => String(v.value))
          .reduce((acc, val) => acc.concat(val), []),

        finalidad: (this.filtersForm.controls.finalidad as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => String(v.value))
          .reduce((acc, val) => acc.concat(val), []),

        beneficiarios: (this.filtersForm.controls.beneficiarios as FormArray)
          .getRawValue()
          .filter((v) => v.checked)
          .map((v) => String(v.value))
          .reduce((acc, val) => acc.concat(val), []),

        fecha_publicacion_desde: moment(this.filtersPublishmentDateRangeForm.controls.start.value)
          .format('DD/MM/YYYY')
          .toString(),

        fecha_publicacion_hasta: moment(this.filtersPublishmentDateRangeForm.controls.end.value)
          .format('DD/MM/YYYY')
          .toString(),

        fecha_finalizacion_desde: moment(this.filtersFinalizationDateRangeForm.controls.start.value)
          .format('DD/MM/YYYY')
          .toString(),
        fecha_finalizacion_hasta: moment(this.filtersFinalizationDateRangeForm.controls.end.value)
          .format('DD/MM/YYYY')
          .toString(),
      };

      this.subventionsStateService.putAlert(request as any, this.data.id);

      this.actions.pipe(ofActionSuccessful(PutAlert)).subscribe(() => {
        this.dialog.closeAll();
      });
    }
  }

  populateDropdowns(): void {
    this.dropdownComunidadesList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'comunidades')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });

    this.dropdownProvinciasList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'provincias')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });

    this.dropdownSectorEconomicoList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'sector_economico')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
  }

  castToFormGroup(ac: AbstractControl): FormGroup {
    return ac as FormGroup;
  }
}
