import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './components/panel/panel.component';
import { BaseChart } from './components/charts/base/base.component';
import { LinechartComponent } from './components/charts/linechart/linechart.component';
import { VbchartComponent } from './components/charts/vbchart/vbchart.component';
import { ConnectedComponent } from './components/charts/connected/connected.component';
import { RowLayoutComponent } from './components/layouts/row-layout.component';
import { ColLayoutComponent } from './components/layouts/col-layout.component';
import { PiechartComponent } from './components/charts/piechart/piechart.component';
import { SpiderchartComponent } from './components/charts/spiderchart/spiderchart.component';
import { WordCloudComponent } from './components/charts/word-cloud/word-cloud.component';
import { MapComponent } from './components/charts/map/map.component';
import { ChartContainerComponent } from './components/layouts/chart-container/chart-container.component';
import { ChartGridContainerComponent } from './components/layouts/chart-grid-container/chart-grid-container.component';
import { LinkPreviewComponent } from './components/charts/linkpreview/linkpreview.component';
import { ContentCardComponent } from '../documents/components/contentcard/contentcard.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    PanelComponent,
    BaseChart,
    LinechartComponent,
    VbchartComponent,
    ConnectedComponent,
    PiechartComponent,
    SpiderchartComponent,
    MapComponent,
    WordCloudComponent,
    RowLayoutComponent,
    ColLayoutComponent,
    ChartContainerComponent,
    ChartGridContainerComponent,
    LinkPreviewComponent,
    ContentCardComponent,
  ],
  exports: [
    PanelComponent,
    VbchartComponent,
    PiechartComponent,
    SpiderchartComponent,
    WordCloudComponent,
    MapComponent,
    RowLayoutComponent,
    ColLayoutComponent,
  ],
})
export class BrWidgetsModule {}
