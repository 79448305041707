import {
  CollectiveAgreement,
  CollectiveAgreementsFilters,
  CollectiveAgreementsGetResponse,
} from '../../models/collective-agreement';

export class GetCollectiveAgreements {
  static readonly type = '[GetCollectiveAgreements] action';
  constructor(public payload: CollectiveAgreementsGetResponse) {}
}

export class GetCollectiveAgreement {
  static readonly type = '[GetCollectiveAgreement] action';
  constructor(public payload: CollectiveAgreement) {}
}

export class GetFilters {
  static readonly type = '[GetFilters] action';
  constructor(
    public payload: {
      id: number;
      seccion: string;
      attr: string;
      items: {
        label: string;
        value: number;
      }[];
    }[]
  ) {}
}

export class ToggleCollectiveAgreementsMenu {
  static readonly type = '[ToggleCollectiveAgreementsMenu] action';
}

export class UpdateCollectiveAgreementsMenuFilters {
  static readonly type = '[UpdateCollectiveAgreementsMenuFilters] action';
  constructor(public payload: CollectiveAgreementsFilters) {}
}
