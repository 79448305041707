import { QueryParams } from '@app/modules/main/components/search/services/search.service';
import moment from 'moment';

export class Favorite {
  id: number;
  name: string;
  created_at: moment.Moment;
  updated_at: moment.Moment;
  data: QueryParams;
  alert: any;

  constructor(favorite: any) {
    this.id = favorite.id;
    this.name = favorite.name;
    this.created_at = favorite.created_at;
    this.updated_at = favorite.updated_at;
    this.data = favorite.data;
    this.alert = favorite.alert;
  }
}
