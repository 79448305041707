import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError, forkJoin, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { TendersState } from './tenders.state';
import {
  AlertPostRequest,
  AlertsGetRequest,
  AlertsGetResponse,
  AlertUpdateRequest,
  Cpv,
  Import,
  Province,
  SearchPostRequest,
  Tender,
  TenderSearchsGetRequest,
  TenderSearchsGetResponse,
  TendersFavoritesGetRequest,
  TendersFilters,
  TendersGetRequest,
  TendersGetResponse,
} from '../../models/tender';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { TendersService } from '../../services/tenders.service';
import {
  DeleteAlert,
  DeleteTenderSearch,
  GetTendersAlerts,
  GetCpvs,
  GetImports,
  GetProvinces,
  GetTender,
  GetTenders,
  GetTenderSearchs,
  GetTendersFavorites,
  PostAlert,
  PostSearch,
  ToggleTendersMenu,
  ToggleTendersMenuFilters,
  UpdateAlert,
  UpdateTenderFavorite,
  UpdateTendersFilters,
  GetCpv,
} from './tenders.actions';

@Injectable({ providedIn: 'root' })
export class TendersStateService {
  @Select(TendersState.tenders)
  public tenders$: Observable<TendersGetResponse>;

  @SelectSnapshot(TendersState.tenders)
  public tenders: TendersGetResponse;

  @Select(TendersState.tender)
  public tender$: Observable<Tender>;

  @SelectSnapshot(TendersState.tender)
  public tender: Tender;

  @Select(TendersState.tendersFavorites)
  public tendersFavorites$: Observable<TendersGetResponse>;

  @SelectSnapshot(TendersState.tendersFavorites)
  public tendersFavorites: TendersGetResponse;

  @Select(TendersState.tendersFilters)
  public tendersFilters$: Observable<TendersFilters>;

  @SelectSnapshot(TendersState.tendersFilters)
  public tendersFilters: TendersFilters;

  @Select(TendersState.provinces)
  public provinces$: Observable<Province[]>;

  @SelectSnapshot(TendersState.provinces)
  public provinces: Province[];

  @Select(TendersState.cpvs)
  public cpvs$: Observable<Cpv[]>;

  @SelectSnapshot(TendersState.cpvs)
  public cpvs: Cpv[];

  @Select(TendersState.cpv)
  public cpv$: Observable<Cpv>;

  @SelectSnapshot(TendersState.cpv)
  public cpv: Cpv;

  @Select(TendersState.imports)
  public imports$: Observable<Import[]>;

  @SelectSnapshot(TendersState.imports)
  public imports: Import[];

  @Select(TendersState.alerts)
  public alerts$: Observable<AlertsGetResponse>;

  @SelectSnapshot(TendersState.alerts)
  public alerts: AlertsGetResponse;

  @Select(TendersState.tenderSearchs)
  public tenderSearchs$: Observable<TenderSearchsGetResponse>;

  @SelectSnapshot(TendersState.tenderSearchs)
  public tenderSearchs: TenderSearchsGetResponse;

  @Select(TendersState.openedMenuTendersFilters)
  public openedMenuTendersFilters$: Observable<boolean>;

  @SelectSnapshot(TendersState.openedMenuTendersFilters)
  public openedMenuTendersFilters: boolean;

  @Select(TendersState.openedMenu)
  public openedMenu$: Observable<boolean>;

  @SelectSnapshot(TendersState.openedMenu)
  public openedMenu: boolean;

  constructor(private _snackbarStateService: SnackbarStateService, private tendersService: TendersService) {}

  @Dispatch()
  public getTenders = (request: TendersGetRequest) => {
    return this.tendersService.getTenders(request).pipe(
      map((response) => {
        return new GetTenders(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getTender = (request: number) => {
    return this.tendersService.getTender(request).pipe(
      map((response) => {
        return new GetTender(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getTendersFavorites = (request: TendersFavoritesGetRequest) => {
    return this.tendersService.getTendersFavorites(request).pipe(
      map((response) => {
        return new GetTendersFavorites(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public updateTendersFilters = (request: TendersFilters) => {
    return new UpdateTendersFilters(request);
  };

  @Dispatch()
  public updateTenderFavoriteAdd = (request: number) => {
    return this.tendersService.updateTenderFavoriteAdd(request).pipe(
      map(() => {
        return new UpdateTenderFavorite(request);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public updateTenderFavoriteDelete = (request: number) => {
    return this.tendersService.updateTenderFavoriteDelete(request).pipe(
      map(() => {
        return new UpdateTenderFavorite(request);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public postSearch = (request: SearchPostRequest) => {
    return this.tendersService.postSearch(request).pipe(
      map((response) => {
        return new PostSearch(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getProvinces = () => {
    return this.tendersService.getProvinces().pipe(
      map((response) => {
        return new GetProvinces(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getCpvs = () => {
    return this.tendersService.getCpvs().pipe(
      map((response) => {
        return new GetCpvs(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getCpv = (code: string) => {
    return this.tendersService.getCpv(code).pipe(
      map((response) => {
        return new GetCpv(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getImports = () => {
    return this.tendersService.getImports().pipe(
      map((response) => {
        return new GetImports(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getTendersAlerts = (request: AlertsGetRequest) => {
    return this.tendersService.getAlerts(request).pipe(
      map((response) => {
        return new GetTendersAlerts(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public deleteAlert = (request: number) => {
    return this.tendersService.deleteAlert(request).pipe(
      map(() => {
        return new DeleteAlert(request);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public postAlert = (request: AlertPostRequest) => {
    return this.tendersService.postAlert(request).pipe(
      map((response) => {
        return new PostAlert(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public updateAlert = (request: AlertUpdateRequest, id: number) => {
    return this.tendersService.updateAlert(request, id).pipe(
      map((response) => {
        return new UpdateAlert(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getTenderSearchs = (request: TenderSearchsGetRequest) => {
    return this.tendersService.getTenderSearchs(request).pipe(
      map((response) => {
        return new GetTenderSearchs(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public deleteTenderSearch = (request: number) => {
    return this.tendersService.deleteTenderSearch(request).pipe(
      map(() => {
        return new DeleteTenderSearch(request);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public toggleTendersMenuFilters = () => {
    return new ToggleTendersMenuFilters();
  };

  @Dispatch()
  public toggleTendersMenu = () => {
    return new ToggleTendersMenu();
  };
}
