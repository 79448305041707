import { Pipe, PipeTransform } from '@angular/core';
import { AppStateService } from '@app/state/app/app.service';

@Pipe({ name: 'initiativeNameMapping' })
export class InitiativeNameMappingPipe implements PipeTransform {
  constructor(private appStateService: AppStateService) {}

  transform(key: string): string {
    const nameMapping: { key: string; value: string } | null = this.appStateService.initiativeNameMapping.find(
      (nm) => nm.key === key
    );

    return nameMapping ? nameMapping.value : key;
  }
}
