import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { DocumentStateService } from '@app/modules/documents/store/report/documents.service';
import { environment } from '@env/environment';
import { AppStateService } from '@app/state/app/app.service';
import { DraftContent } from '@app/modules/documents/store/models/draft.state.model';
import { defaultOptions } from 'ngx-extended-pdf-viewer';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ArticledetailArticlesListComponent } from './articledetail-articles-list/articledetail-articles-list.component';

@Component({
  selector: 'app-articledetail',
  templateUrl: './articledetail.component.html',
  styleUrls: ['./articledetail.component.less'],
})
export class ArticledetailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() docID: string;
  @Input() s3_file_url: string | undefined;
  @Input() draftContent: DraftContent | undefined;
  @Input() smartphoneView: boolean;
  @Output() changeArticle: EventEmitter<any> = new EventEmitter<any>();

  readonly LOGOURL: string = environment.logoApp;

  constructor(
    private _appStateService: AppStateService,
    public documentStateService: DocumentStateService,
    private matBottomSheet: MatBottomSheet
  ) {
    defaultOptions.externalLinkTarget = 2;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.docID.currentValue) {
    //   this.documentStateService.getDocument(this.docID);
    // }

    if (changes.draftContent.currentValue) {
      if (this.draftContent.id) this.documentStateService.getDocument(this.draftContent.id);
      else this.documentStateService.setSelectedDocument(undefined);
    }
  }

  ngOnDestroy(): void {
    this.documentStateService.setSelectedDocument(undefined);
    this._appStateService.addHistoryUrl(undefined);
    this._appStateService.showSideMenu();
  }

  printMagazine() {}

  viewArticles() {
    this.matBottomSheet
      .open(ArticledetailArticlesListComponent, {
        autoFocus: false,
        panelClass: 'panel-bottom-sheet-w-100-h-100',
        data: { articleId: this.docID },
      })
      .afterDismissed()
      .subscribe((data) => {
        if (data) {
          this.changeArticle.emit(data);
        }
      });
  }
}
