import { Injectable, Injector } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError } from 'rxjs';
import { map, finalize, catchError } from 'rxjs/operators';
import {
  ToggleSideMenu,
  AddTitle,
  ShowTitle,
  HideTitle,
  ShowSideMenu,
  HideSideMenu,
  ShowRegulatorioSubForm,
  HideRegulatorioSubForm,
  HideSubForm,
  ShowSubForm,
  ShowSearchText,
  HideSearchText,
  HideDates,
  ShowDates,
  ToggleRegulatorioSubForm,
  ToggleSubForm,
  AddHistoryUrl,
  AddSpiderMappings,
  UnsetInteresting,
  ShowInteresting,
  HideInteresting,
  AddTopicMappings,
  UpdateUIStatus,
  UpdateSearchContent,
  UpdateSearchFilters,
  AddSelectedRegion,
  AddRegionMappings,
  UpdateAgendaFilters,
  GetAgendas,
  GetAgendasEvents,
  ShowAgendasTutorial,
  GetInitiativesNameMapping,
  AddCategoriesTopics,
  SetSearchType,
  SetNotifications,
  UpdateNotifications,
} from './app.actions';
import { AppState } from './app.state';
import {
  Agenda,
  AgendaEvent,
  AgendaFilters,
  AppNotification,
  PaginatedResults,
  RegionMappings,
  SearchFilters,
  SearchType,
  SpiderMappings,
  TopicMappings,
  UIStatus,
} from '../models/app.state.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AppDataService } from '@app/services/app-data.service';
import { SpidersService } from '@app/modules/main/components/search/services/spiders.service';
import { InitiativeState } from '@app/modules/documents/store/initiative/initiative.state';
import { InitiativesService } from '@app/modules/documents/services/initiatives.service';
import { SnackbarStateService } from '../snackbar/snackbar.service';
import { SearchService } from '@app/modules/main/components/search/services/search.service';

@Injectable({ providedIn: 'root' })
export class AppStateService {
  @Select(AppState.sidemenu)
  public sidemenuOpened$: Observable<boolean>;

  @SelectSnapshot(AppState.sidemenu)
  public sidemenuOpened: boolean;

  @Select(AppState.title)
  public title$: Observable<string>;

  @Select(AppState.show_title)
  public show_title$: Observable<boolean>;

  @Select(AppState.dates_visible)
  public dates_visible$: Observable<boolean>;

  @Select(AppState.interesting_visible)
  public interesting_visible$: Observable<boolean>;

  @Select(AppState.search_text_visible)
  public search_text_visible$: Observable<boolean>;

  @Select(AppState.subform_visible)
  public subform_visible$: Observable<boolean>;

  @Select(AppState.subform_regulatorio_visible)
  public subform_regulatorio_visible$: Observable<boolean>;

  @Select(AppState.content)
  public content$: Observable<string>;

  @Select(AppState.searchFilters)
  public searchFilters$: Observable<SearchFilters>;

  @Select(AppState.agendaFilters)
  public agendaFilters$: Observable<AgendaFilters>;

  @SelectSnapshot(AppState.agendaFilters)
  public agendaFilters: AgendaFilters;

  @Select(AppState.agendas)
  public agendas$: Observable<{ label: string; value: number; items: { label: string; value: number }[] }>;

  @SelectSnapshot(AppState.agendas)
  public agendas: { label: string; value: number; items: { label: string; value: number }[] };

  @Select(AppState.agendasEvents)
  public agendasEvents$: Observable<AgendaEvent[]>;

  @SelectSnapshot(AppState.agendasEvents)
  public agendasEvents: AgendaEvent[];

  @Select(AppState.showAgendasTutorial)
  public showAgendasTutorial$: Observable<boolean>;

  @SelectSnapshot(AppState.showAgendasTutorial)
  public showAgendasTutorial: boolean;

  @Select(AppState.uiStatus)
  public uiStatus$: Observable<UIStatus>;

  @Select(AppState.history_url)
  public history_url$: Observable<string>;

  @SelectSnapshot(AppState.content)
  public content: string;

  @SelectSnapshot(AppState.searchFilters)
  public searchFilters: SearchFilters;

  @SelectSnapshot(AppState.spider_mappings)
  public spider_mappings: SpiderMappings[];

  @SelectSnapshot(AppState.topic_mappings)
  public topic_mappings: TopicMappings[];

  @SelectSnapshot(AppState.categories_topics)
  public categories_topics: any[];

  @Select(AppState.region_mappings)
  public region_mappings$: Observable<RegionMappings[]>;

  @SelectSnapshot(AppState.region_mappings)
  public region_mappings: RegionMappings[];

  @Select(AppState.selected_regions)
  public selected_regions$: Observable<RegionMappings[]>;

  @SelectSnapshot(AppState.selected_regions)
  public selected_regions: RegionMappings[];

  @Select(AppState.initiativeNameMapping)
  public initiativeNameMapping$: Observable<{ key: string; value: string }[]>;

  @SelectSnapshot(AppState.initiativeNameMapping)
  public initiativeNameMapping: { key: string; value: string }[];

  @Select(AppState.searchType)
  public searchType$: Observable<SearchType>;

  @SelectSnapshot(AppState.searchType)
  public searchType: SearchType;

  @Select(AppState.notifications)
  public notifications$: Observable<AppNotification[]>;

  @SelectSnapshot(AppState.notifications)
  public notifications: AppNotification[];

  constructor(
    private injector: Injector,
    private _initiativesService: InitiativesService,
    private _snackbarStateService: SnackbarStateService
  ) {}

  @Dispatch()
  public toggleSideMenu = () => {
    return new ToggleSideMenu();
  };

  @Dispatch()
  public showSideMenu = () => {
    return new ShowSideMenu();
  };

  @Dispatch()
  public hideSideMenu = () => {
    return new HideSideMenu();
  };

  @Dispatch()
  public addTitle = (title: string) => {
    return new AddTitle({ title: title, show_title: true });
  };

  @Dispatch()
  public updateSearchContent = (content: string) => {
    return new UpdateSearchContent({ content });
  };

  @Dispatch()
  public updateSearchFilters = (filters: SearchFilters) => {
    return new UpdateSearchFilters({ filters });
  };

  @Dispatch()
  public showTitle = () => {
    return new ShowTitle();
  };

  @Dispatch()
  public hideTitle = () => {
    return new HideTitle();
  };

  @Dispatch()
  public showDates = () => {
    return new ShowDates();
  };

  @Dispatch()
  public hideDates = () => {
    return new HideDates();
  };

  @Dispatch()
  public unsetInteresting = () => {
    return new UnsetInteresting();
  };

  @Dispatch()
  public showInteresting = () => {
    return new ShowInteresting();
  };

  @Dispatch()
  public hideInteresting = () => {
    return new HideInteresting();
  };

  @Dispatch()
  public showSearchText = () => {
    return new ShowSearchText();
  };

  @Dispatch()
  public hideSearchText = () => {
    return new HideSearchText();
  };

  @Dispatch()
  public toggleSubForm = () => {
    return new ToggleSubForm();
  };

  @Dispatch()
  public showSubForm = () => {
    return new ShowSubForm();
  };

  @Dispatch()
  public hideSubForm = () => {
    return new HideSubForm();
  };

  @Dispatch()
  public showRegulatorioSubForm = () => {
    return new ShowRegulatorioSubForm();
  };

  @Dispatch()
  public toggleRegulatorioSubForm = () => {
    return new ToggleRegulatorioSubForm();
  };

  @Dispatch()
  public hideRegulatorioSubForm = () => {
    return new HideRegulatorioSubForm();
  };

  @Dispatch()
  public addHistoryUrl = (url: string) => {
    return new AddHistoryUrl(url);
  };

  @Dispatch()
  public updateUIStatus = (status: UIStatus) => {
    return new UpdateUIStatus(status);
  };

  @Dispatch()
  public addStateMappings = () => {
    return this.injector
      .get(AppDataService)
      .getSpiderGroups()
      .pipe(
        map((response: any) => {
          return new AddSpiderMappings(response.results);
        })
      );
  };

  @Dispatch()
  public addStateTopics = () => {
    return this.injector
      .get(AppDataService)
      .getCategories()
      .pipe(
        map((response: any) => {
          return new AddTopicMappings(response.results);
        })
      );
  };

  @Dispatch()
  public addStateCategoriesTopics = (topicGroupId: number) => {
    return this.injector
      .get(SearchService)
      .getCategories(topicGroupId)
      .pipe(
        map((response: any) => {
          return new AddCategoriesTopics(response);
        })
      );
  };

  @Dispatch()
  public addStateRegions = () => {
    return this.injector
      .get(SpidersService)
      .getRegions()
      .pipe(
        map((response: any) => {
          return new AddRegionMappings(response.results);
        })
      );
  };

  @Dispatch()
  public addStateSelectedRegions = (selectedRegions: RegionMappings[]) => {
    return new AddSelectedRegion(selectedRegions);
  };

  @Dispatch()
  public updateAgendaFilters = (agendaFilters: AgendaFilters) => {
    return new UpdateAgendaFilters(agendaFilters);
  };

  @Dispatch()
  public getAgendas = (request: { path: string }) => {
    this.updateUIStatus(UIStatus.loading);
    return this.injector
      .get(AppDataService)
      .getAgendas(request)
      .pipe(
        map((response) => {
          this.updateUIStatus(UIStatus.loaded);
          return new GetAgendas(response);
        })
      );
  };

  @Dispatch()
  public getAgendasEvents = (request: { spiderIds: string[]; from: string; to: string }) => {
    this.updateUIStatus(UIStatus.loading);
    return this.injector
      .get(AppDataService)
      .getAgendasEvents(request)
      .pipe(
        map((response) => {
          // response = response.map((r) => {
          //   return {
          //     ...r,
          //     events: r.events.map((re) => {
          //       return {
          //         ...re,
          //         location: 'Zaragoza',
          //         url: 'https://www.google.es',
          //       };
          //     }),
          //   };
          // });
          this.updateUIStatus(UIStatus.loaded);
          return new GetAgendasEvents(response);
        }),
        catchError((error) => {
          this.updateUIStatus(UIStatus.loaded);
          let errorMsg = 'No se han encontrado entradas con los filtros actuales';
          this._snackbarStateService.show(errorMsg, 4000, 'cerrar');
          return throwError(error);
        })
      );
  };

  @Dispatch()
  public showAgendasTutorialF = (showAgendasTutorial: boolean) => {
    return new ShowAgendasTutorial(showAgendasTutorial);
  };

  @Dispatch()
  public getInitiativesNameMapping = () => {
    return this._initiativesService
      .getInitiativesNameMapping()
      .pipe(map((response: any) => new GetInitiativesNameMapping(response)));
  };

  @Dispatch()
  public setSearchType = (response: SearchType) => {
    return new SetSearchType(response);
  };

  @Dispatch()
  public setNotifications = (response: AppNotification[]) => {
    return new SetNotifications(response);
  };

  @Dispatch()
  public updateNotifications = (response: AppNotification) => {
    return new UpdateNotifications(response);
  };
}
