import {
  Component,
  AfterViewInit,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  Injector,
} from '@angular/core';
import { SearchService } from '@app/services/search.service';
import { HttpRequestService } from '@app/services/http-request.service';
import { WidgetConfig } from '@modules/br-widgets/models/widget-config';
import { ColorPalette } from '@modules/br-widgets/models/color-palette';
import { AppStatusService } from '@app/services/app-status.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css'],
})
export class BaseChart implements OnInit, AfterViewInit, OnDestroy {
  @Input() results: any[] = [];
  @Input() view = undefined;
  @Input() showXAxis = true;
  @Input() showYAxis = true;
  @Input() showXAxisLabel = true;
  @Input() xAxisLabel: string;
  @Input() showYAxisLabel = true;
  @Input() yAxisLabel: string;
  @Input() colorScheme = { domain: ColorPalette.palette(0) };
  @Input() wconfig?;
  @Input() parent: any;

  options: any;
  echartsInstance: any;
  widgetIndex: number;
  public seriesLoaded: EventEmitter<any> = new EventEmitter();

  //echarts
  @Input() autoResize = true;
  isLoading = true;
  loadingOpts = {
    text: '',
    color: '#7299ad',
    textColor: '#ff0000',
    maskColor: 'rgba(255, 255, 255, 0.6)',
    zlevel: 0,
  };

  theme = 'customed';
  isRateLimitReached = false;

  static MIN_WIDTH = 1024;
  subscriptions = [];
  constructor(public injector: Injector, public changeDetector: ChangeDetectorRef) {
    // if (!this.wconfig)
    //   this.wconfig = new WidgetConfig({config: ''});
    //
  }

  private getRequest() {
    let uri = this.wconfig.config['uri'];
    return { uri: uri };
  }

  private getSearchRequest(args) {
    let config = this.wconfig.config['query'],
      aggregations = this.wconfig.config['aggregations'],
      request = {};
    request['dateParsed_start'] = args.start ? args.start : config['dateParsed_start'];

    request['dateParsed_end'] = args.end ? args.end : config['dateParsed_end'];
    request['text'] = config['text'];

    request['docTypes'] = args.docTypes ? args.docTypes : config['docTypes'];

    ['docTopics', 'docTopicsRegion', 'spiderIds'].forEach((key) => {
      request[key] =
        args[key] && args[key].length
          ? args[key].map((item) => {
              return item.id;
            })
          : config[key];
      if (!request[key] || request[key].length == 0) delete request[key];
    });

    request['aggregations'] = aggregations;
    request['size'] = 0;
    return request;
  }

  refresh(c, r) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  ngAfterViewInit() {
    if (this.wconfig.config['query']) {
      this.subscriptions.push(
        this.injector.get(AppStatusService).search_launch.subscribe((args) => {
          this.isLoading = true;
          this.changeDetector.detectChanges();
          let request = this.getSearchRequest(args);
          this.update(request, (request, response) => {
            this.refresh(request, response);
          });
        })
      );
    } else {
      let request = this.getRequest();
      this.update(request, (request, response) => {
        this.refresh(request, response);
      });
    }
  }

  onSelect(ev) {}

  public update(config, callback) {
    delete config['dateParsed_gte'];
    delete config['dateParsed_lte'];

    if (!config['dateParsed_start']) {
      config['dateParsed_start'] = this.injector.get(AppStatusService).dateParsed_start;
      config['dateParsed_end'] = this.injector.get(AppStatusService).dateParsed_end;
    }

    if (!config['uri']) {
      return this.injector
        .get(SearchService)
        .request(config)
        .subscribe(
          (response) => {
            return callback(config, response);
          },
          (error) => {
            this.isRateLimitReached = true;
            //this.isLoading = false;
            //this.changeDetector.detectChanges();

            this['parent'].dashboard[this.widgetIndex].isLoading = false;
            let incremental = 100 / this['parent'].dashboard.length;
            this['parent'].percentLoad += incremental;
            this['parent'].changeDetector.detectChanges();
          }
        );
    } else {
      return this.injector.get(HttpRequestService).get(config['uri']);
    }
  }
}
