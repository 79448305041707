import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CalendarPPAlertsGetResponse, CalendarPPGetRequest } from '../models/calendar-pp.state.model';

@Injectable({
  providedIn: 'root',
})
export class VigilanciaService {
  constructor(private _http: HttpClient) { }

  getCalendarPPFilters(): Observable<any> {
    return this._http.get<any>(`${environment.vigilanciaCalendarPPFilters}`);
  }

  getCalendarPPItems(request: CalendarPPGetRequest): Observable<any> {
    return this._http.post<any>(`${environment.vigilanciaCalendarPPSearch}`, request);
  }


  getAlerts(request: CalendarPPGetRequest): Observable<CalendarPPAlertsGetResponse> {
    return this._http.get<CalendarPPAlertsGetResponse>(
      `${environment.vigilanciaCalendarPPAlerts}/?page=${request.page}&size=${request.size}`
    );
  }

  postAlert(request: CalendarPPGetRequest): Observable<any> {
    return this._http.post<any>(`${environment.vigilanciaCalendarPPAlerts}`, request);
  }

  putAlert(request: CalendarPPGetRequest, id: number): Observable<any> {
    return this._http.put<any>(`${environment.vigilanciaCalendarPPAlerts}/${id}`, request);
  }

  deleteAlert(request: number): Observable<void> {
    return this._http.delete<void>(`${environment.vigilanciaCalendarPPAlerts}/${request}`);
  }
}
