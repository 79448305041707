import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserIdentity } from '../../../../models/UserIdentity';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';

@Component({
  selector: 'sub-items',
  templateUrl: './sub-items.component.html',
  styleUrls: ['./sub-items.component.css'],
})
export class SubItemsComponent implements OnInit {
  items: Array<any> = [];

  constructor(private authStateService: AuthStateService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');

    this.authStateService.userConfig.interface_config.menu_items.forEach((item) => {
      if (item.id.toString() == id) {
        this.items = item.items;
      }
    });
  }

  navigateTo(i) {
    this.router.navigate([this.items[i].path]);
  }
}
