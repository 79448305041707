export class Me {
  static readonly type = '[Me] action';

  constructor(public payload: any) {}
}

export class ResetMe {
  static readonly type = '[ResetMe] action';

  constructor() {}
}

export class Logout {
  static readonly type = '[Logout] action';

  constructor() {}
}

export class UpdatePassword {
  static readonly type = '[UpdatePassword] action';

  constructor() {}
}

export class ViewMyData {
  static readonly type = '[ViewMyData] action';

  constructor() {}
}
