<router-outlet>
  <ngx-guided-tour
    skipText="Saltar"
    nextText="Siguiente"
    doneText="Terminar"
    closeText="Cerrar"
    backText="Volver"
  ></ngx-guided-tour>
  <div
    id="loading-animation"
    style="display: flex; flex-direction: column; align-items: center; gap: 16px; margin-top: 120px"
  >
    <svg class="loader-circular" viewBox="0 0 75 75">
      <circle class="loader-path" cx="37.5" cy="37.5" r="30" fill="none" stroke-miterlimit="10" />
    </svg>
    <p style="margin: 0; font-size: 18px; font-weight: 400; opacity: 0.85">Cargando...</p>
  </div>
</router-outlet>
