import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmltext',
})
export class HtmltextPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let arval = value.replace('\r', '').split('\n'),
      length = arval.length;
    while (--length >= 0) {
      if (arval[length].trim(' ') == '') arval.splice(length, 1);
    }
    return '<p>' + arval.join('</p><p>') + '</p>';
  }
}
