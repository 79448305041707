<h1 mat-dialog-title>Enviar revista</h1>
<div mat-dialog-content [formGroup]="filters">
  <p>Envía el contenido generado a tu email, {{ authStateService.userConfig.email }}</p>
  <div class="form">
    <mat-checkbox color="primary" formControlName="pdf">Enviar PDF</mat-checkbox>
    <mat-checkbox
      color="primary"
      formControlName="email"
      *ngIf="authStateService.userConfig.interface_config.client_id === 60"
      >Enviar Email</mat-checkbox
    >
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cerrar</button>
  <button
    mat-button
    cdkFocusInitial
    color="primary"
    [disabled]="!filters.controls.pdf.value && !filters.controls.email.value"
    (click)="send()"
  >
    Enviar
  </button>
</div>
