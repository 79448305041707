import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Tender } from '@app/modules/ceoe/models/tender';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GetTenders, UpdateTendersFilters } from '../../store/tenders/tenders.actions';
import { TendersStateService } from '../../store/tenders/tenders.service';
import { TendersSearchSaveSearchDialogComponent } from './tenders-search-save-search-dialog/tenders-search-save-search-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TendersSearchDetailComponent } from './tenders-search-detail/tenders-search-detail.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tenders-search',
  templateUrl: './tenders-search.component.html',
  styleUrls: ['./tenders-search.component.scss'],
})
export class TendersSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  componentDestroyed$: Subject<boolean> = new Subject();
  displayedColumns: string[] = ['card'];
  pageIndex = 0;
  loading = true;
  withFiltersFromAlerts?: number;

  constructor(
    public tendersStateService: TendersStateService,
    private activatedRoute: ActivatedRoute,
    private actions: Actions,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.withFiltersFromAlerts = Number(params.withFiltersFromAlerts);
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetTenders)).subscribe(() => {
      this.loading = false;

      setTimeout(() => {
        if (this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.length = this.tendersStateService.tenders.total;
        }
      }, 0);

      document.querySelector('.container .header .title').scrollIntoView({ block: 'start', inline: 'start' });
    });

    setTimeout(() => {
      this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(UpdateTendersFilters)).subscribe(() => {
        this.loading = true;
        this.pageIndex = 0;
        this.tendersStateService.getTenders({
          ...this.tendersStateService.tendersFilters,
          page: this.pageIndex + 1,
          size: 30,
        });
      });
    }, 0);
  }

  ngOnInit(): void {
    this.pageIndex = 0;
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(debounceTime(500)).subscribe((data) => {
      this.loading = true;
      this.pageIndex = data.pageIndex;
      this.paginator.pageIndex = this.pageIndex;

      this.tendersStateService.getTenders({
        ...this.tendersStateService.tendersFilters,
        page: this.pageIndex + 1,
        size: 30,
      });
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  saveSearch(): void {
    this.dialog.open(TendersSearchSaveSearchDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      data: {
        withFiltersFromAlerts: this.withFiltersFromAlerts,
      },
    });
  }

  saveItem(evt: Event, row: Tender) {
    evt.stopPropagation();

    if (row.favorito) {
      this.tendersStateService.updateTenderFavoriteDelete(row.id);
    } else {
      this.tendersStateService.updateTenderFavoriteAdd(row.id);
    }

    return false;
  }

  openDetail(id: number): void {
    this._bottomSheet.open(TendersSearchDetailComponent, {
      autoFocus: false,
      panelClass: 'panel-bottom-sheet-w-100-h-100',
      data: id,
    });
  }
}
