import { Component, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SectorReportsService } from '@app/modules/ceoe/services/sector-reports.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { AppStateService } from '@app/state/app/app.service';
import { SectorReportsListSectionGenerateDocComponent } from '../sector-reports-list-section-generate-doc/sector-reports-list-section-generate-doc.component';
import { SectorReportsListSectionDeleteDialogComponent } from '../sector-reports-list-section-delete-dialog/sector-reports-list-section-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-sector-reports-list-section-reports',
  templateUrl: './sector-reports-list-section-reports.component.html',
  styleUrls: ['./sector-reports-list-section-reports.component.scss'],
})
export class SectorReportsListSectionReportsComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  data = [];
  displayedColumns = ['name', 'created_at', 'updated_at', 'actions'];
  size = 30;
  pageIndex = 0;
  total = 0;
  loading = true;
  params;

  constructor(
    public appStateService: AppStateService,
    private authStateService: AuthStateService,
    private sectorReportsService: SectorReportsService,
    private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.appStateService.addTitle('Informes sectoriales');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.pageIndex = 0;

    this.route.params.subscribe((params) => {
      this.params = params;
      this.appStateService.addHistoryUrl(`/sector-reports/list/${this.params.id}`);
      this.getData();
    });
  }

  pageEvent(evt: PageEvent): void {
    this.loading = true;
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.getData();
  }

  getData(): void {
    this.loading = true;

    const request: any = {
      page: this.pageIndex + 1,
      size: this.size,
      id: this.params.idSection,
    };

    this.sectorReportsService.getSectorReports(request).subscribe((data) => {
      setTimeout(() => {
        this.data = data.items;

        if (this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.length = data.total;
          this.total = data.total;
        }
      }, 0);

      this.loading = false;
    });
  }

  goToSectorReport(row: any): void {
    this._bottomSheet.open(SectorReportsListSectionGenerateDocComponent, {
      autoFocus: false,
      panelClass: 'panel-bottom-sheet-w-100-h-100',
      data: {
        data: {
          title: row.title,
          desc: row.description,
        },
        request: row,
        id: this.params.idSection,
        idSection: this.params.idSection,
        idReport: row.id,
      },
    });
  }

  downloadReport(row: any): void {
    const request: any = {
      idSection: this.params.idSection,
      idReport: row.id,
    };

    this.sectorReportsService.exportDoc(request).subscribe((data) => {
      saveAs(data, `Informe_${row.title}`);
    });
  }

  deleteReport(row: any): void {
    const dialogRef = this.dialog.open(SectorReportsListSectionDeleteDialogComponent, {
      width: '600px',
      data: {
        title: `Eliminar Informe - ${row.title}`,
        desc: 'Escribe la palabra BORRAR dentro del texto para confirmar la eliminación.',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const request: any = {
          data: {
            title: row.title,
            desc: row.description,
          },
          request: row,
          id: this.params.idSection,
          idReport: row.id,
        };

        this.sectorReportsService.deleteSectorReport(request).subscribe((data) => {
          this.getData();
        });
      }
    });
  }
}
