import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import moment from 'moment';
import { CalendarPPStateService } from '../../store/calendar-pp/calendar-pp.service';
import { PostAlert, PutAlert } from '../../store/calendar-pp/calendar-pp.actions';
import { getRealm, KC_REALM_CEOE } from '@app/init/keycloak-init.factory';

@Component({
  selector: 'app-vigilancia-calendar-pp-create-alert-dialog',
  templateUrl: './vigilancia-calendar-pp-create-alert-dialog.component.html',
  styleUrls: ['./vigilancia-calendar-pp-create-alert-dialog.component.scss'],
})
export class VigilanciaCalendarPPCreateAlertDialogComponent {
  saveSearchForm: FormGroup;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<VigilanciaCalendarPPCreateAlertDialogComponent>,
    private calendarPPStateService: CalendarPPStateService,
    private snackbarStateService: SnackbarStateService,
    private actions: Actions,
    private router: Router
  ) {
    this.saveSearchForm = this.fb.group({
      titulo: [
        this.calendarPPStateService.alerts?.items?.find((ts) => ts.id === Number(this.data.withFiltersFromAlerts))
          ?.titulo ?? '',
        Validators.required,
      ],
    });
  }

  save(): void {
    this.saveSearchForm.markAllAsTouched();

    if (this.saveSearchForm.valid) {
      this.loading = true;

      if (!this.data.withFiltersFromAlerts) {
        this.actions.pipe(ofActionSuccessful(PostAlert)).subscribe(() => {
          this.close();
          this.loading = false;
          this.snackbarStateService.show('Alerta creada correctamente', 3000, 'cerrar');

          if (getRealm() === KC_REALM_CEOE) {
            this.router.navigate(['vigilancia', 'tnor', 'fa', 'calendarPp'], {
              queryParams: {
                withFiltersFromAlerts:
                  this.calendarPPStateService.alerts.items[this.calendarPPStateService.alerts.items.length - 1].id,
              },
            });
          } else {
            this.router.navigate(['regulatorio', 'calendarPp'], {
              queryParams: {
                withFiltersFromAlerts:
                  this.calendarPPStateService.alerts.items[this.calendarPPStateService.alerts.items.length - 1].id,
              },
            });
          }
        });

        this.calendarPPStateService.postAlert(this.getRequest());
      } else {
        this.actions.pipe(ofActionSuccessful(PutAlert)).subscribe(() => {
          this.close();
          this.loading = false;
          this.snackbarStateService.show('Alerta creada correctamente', 3000, 'cerrar');
        });

        this.calendarPPStateService.putAlert(this.getRequest(), this.data.withFiltersFromAlerts);
      }
    }
  }

  getRequest(): any {
    return {
      ...this.data.filters,
      titulo: this.saveSearchForm.controls.titulo.value,
    };
  }

  close(): void {
    this.matDialogRef.close();
  }
}
