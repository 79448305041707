import { Component, Inject, OnInit } from '@angular/core';

import { environment, FRONTURL } from '@env/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import _moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InitiativeStateService } from '../../store/initiative/initiative.service';

export interface ReportDialog {
  title: string;
  searchs: string[];
}

@Component({
  selector: 'report-form-dialog',
  templateUrl: './norm-form.dialog.html',
  styleUrls: ['./norm-form.dialog.scss'],
})
export class NormFormDialog implements OnInit {
  readonly LOGOURL: string = FRONTURL + environment.logoMail;
  formGroup: FormGroup;

  constructor(
    private _initiativeStateService: InitiativeStateService,
    public dialogRef: MatDialogRef<NormFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ReportDialog
  ) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      id: new FormControl(''),
      title: new FormControl('', [Validators.required]),
      url: new FormControl('', [Validators.required]),
    });

    if (this.data) {
      this.formGroup.patchValue(this.data);
    }
  }

  get title() {
    return this.formGroup.get('title');
  }

  get url() {
    return this.formGroup.get('url');
  }

  addNorm() {
    if (this.formGroup.invalid) return;
    this._initiativeStateService.addInitiative(this.formGroup.value);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
