import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AvatarModule } from 'ngx-avatar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'angular2-moment';
import { NgxEchartsModule } from 'ngx-echarts';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { GridsterModule } from 'angular-gridster2';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxPrintModule } from 'ngx-print';

// Videogular imports
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
const videoGularImports = [VgCoreModule, VgControlsModule, VgOverlayPlayModule, VgBufferingModule];

// Youtube player import
import { YTPlayerModule, YTPlayerConfig } from 'angular-youtube-player';

import { NgMatModule } from '@modules/ngmat/ngmat.module';
import { PipesModule } from '@app/pipes/pipes.module';

import { NgLayerSelectorComponent } from '@modules/ng-map/components/ng-layer-selector/ng-layer-selector.component';
import { NgLayerSelectorItemComponent } from '@modules/ng-map/components/ng-layer-selector/ng-layer-selector-item.component';
import { NavListComponent } from '@modules/main/components/nav-list/nav-list.component';
import { ChipsAutocompleteComponent } from './components/chips-autocomplete/chips-autocomplete.component';
import { DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { CalendarModule } from 'angular-calendar';
import moment from 'moment';
import { CalendarHeaderComponent } from './components/calendar-header/calendar-header';
import { CustomSnackBarComponent } from './components/custom-snack-bar/custom-snack-bar.component';
import { TimetableComponent } from './components/timetable/timetable.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { PressSpidersSelectComponent } from './components/press-spiders-select/press-spiders-select.component';
import { DocDetailComponent } from './components/doc-detail/doc-detail.component';
import { ArticledetailComponent } from '../documents/components/magazine/articledetail/articledetail.component';
import { LatestSpidersComponent } from './components/latest-spiders/latest-spiders.component';
import { MatFabMenuModule } from '@angular-material-extensions/fab-menu';
import { QuillModule } from 'ngx-quill';
import { SpainRegionsSelectComponent } from './components/spain-regions-select/spain-regions-select.component';

// File saver
import { FileSaverModule } from 'ngx-filesaver';
import { WindowRef } from '../documents/services/window.ref.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEsp } from '../documents/mat-paginator-int';
import { DirectivesModule } from '@app/directives/directives.module';
import { OperatorsDialogComponent } from './components/operators-dialog/operators-dialog.component';
import { PaneldocComponent } from '../documents/components/paneldoc/paneldoc.component';
import { DocGenComponent } from '../documents/components/docgen/docgen.component';
import { DocCategoriesComponent } from '../documents/components/doccategories/doccategories.component';
import { DocBotoneraComponent } from '../documents/components/docbotonera/docbotonera.component';
import { DocBodyComponent } from '../documents/components/docbody/docbody.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgMatModule,
    FlexLayoutModule,
    RouterModule,
    AvatarModule,
    NgxSpinnerModule,
    videoGularImports,
    NgxExtendedPdfViewerModule,
    NgxPrintModule,
    MomentModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    AngularMultiSelectModule,
    GridsterModule,
    PipesModule,
    DirectivesModule,
    YTPlayerModule,
    DragDropModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    MatFabMenuModule,
    QuillModule.forRoot(),
    FileSaverModule,
  ],
  declarations: [
    NavListComponent,
    NgLayerSelectorComponent,
    NgLayerSelectorItemComponent,
    ChipsAutocompleteComponent,
    CalendarHeaderComponent,
    CustomSnackBarComponent,
    TimetableComponent,
    DeleteDialogComponent,
    PressSpidersSelectComponent,
    DocDetailComponent,
    ArticledetailComponent,
    LatestSpidersComponent,
    SpainRegionsSelectComponent,
    OperatorsDialogComponent,
    PaneldocComponent,
    DocGenComponent,
    DocCategoriesComponent,
    DocBotoneraComponent,
    DocBodyComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgMatModule,
    FlexLayoutModule,
    RouterModule,
    AvatarModule,
    PipesModule,
    DirectivesModule,
    NgxSpinnerModule,
    videoGularImports,
    MomentModule,
    NgxEchartsModule,
    AngularMultiSelectModule,
    GridsterModule,
    NgxExtendedPdfViewerModule,
    NgxPrintModule,
    NavListComponent,
    YTPlayerModule,
    ChipsAutocompleteComponent,
    DragDropModule,
    CalendarModule,
    CalendarHeaderComponent,
    CustomSnackBarComponent,
    TimetableComponent,
    DeleteDialogComponent,
    PressSpidersSelectComponent,
    DocDetailComponent,
    ArticledetailComponent,
    LatestSpidersComponent,
    MatFabMenuModule,
    QuillModule,
    SpainRegionsSelectComponent,
    OperatorsDialogComponent,
    FileSaverModule,
    PaneldocComponent,
    DocGenComponent,
    DocCategoriesComponent,
    DocBotoneraComponent,
    DocBodyComponent,
  ],
  providers: [
    { provide: YTPlayerConfig, useValue: { shouldLoadAPI: true, multiplePlaying: false } },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    WindowRef,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp },
  ],
})
export class SharedModule { }
