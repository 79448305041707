import { Component, Input, EventEmitter, OnInit, AfterViewInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ColorPalette } from '@app/modules/br-widgets/models/color-palette';
import { WidgetConfig } from '@app/modules/br-widgets/models/widget-config';

@Component({
  selector: 'app-base-chart',
  templateUrl: './base-chart.component.html',
  styleUrls: ['./base-chart.component.css'],
})
@Injectable()
export class BaseChartComponent implements OnInit, AfterViewInit {
  @Input() results: any[] = [];
  @Input() view = undefined;
  @Input() showXAxis = true;
  @Input() showYAxis = true;
  @Input() showXAxisLabel = true;
  @Input() xAxisLabel: string;
  @Input() showYAxisLabel = true;
  @Input() yAxisLabel: string;
  @Input() colorScheme = { domain: ColorPalette.palette(0) };
  @Input() wconfig?;
  @Input() parent: any;

  options: any;
  echartsInstance: any;
  widgetIndex: number;
  // http: any = undefined;
  public seriesLoaded: EventEmitter<any> = new EventEmitter();

  //echarts
  @Input() autoResize = true;
  isLoading = true;
  loadingOpts = {
    text: '',
    color: '#7299ad',
    textColor: '#ff0000',
    maskColor: 'rgba(255, 255, 255, 0.6)',
    zlevel: 0,
  };

  theme = 'customed';
  isRateLimitReached = false;

  static MIN_WIDTH = 1024;

  constructor(public http: HttpClient) {
    // this.http = NgChartModule.injector.get(HttpClient);
  }

  ngOnInit() {}

  getRequest() {}

  refresh(config, result) {}

  ngAfterViewInit() {
    this.update(this.getRequest(), (request, response) => {
      this.refresh(request, response);
    });
  }

  public update(config, callback) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(config['uri'], httpOptions).subscribe(
      (response) => {
        return callback(config, response);
      },
      (error) => {
        return error;
      }
    );
  }
}
