import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  Renderer2,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Magazine } from '@app/modules/documents/store/models/magazine.state.model';
import { MagazineStateService } from '@app/modules/documents/store/magazine/magazine.service';
import { Draft } from '@app/modules/documents/store/models/draft.state.model';
import { MatCalendarCellClassFunction, MatDatepicker } from '@angular/material/datepicker';
import _moment from 'moment';
import $ from 'jquery';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { MappingAllPipe } from '@app/pipes/mapping-all.pipe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ThumbEstimatedCostComponent } from './thumb-estimated-cost/thumb-estimated-cost.component';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

const PRESSIDS = ['3338', '3321', '3339', '3340', '3341', '3327'];
const DIGITALIDS = [
  '1633',
  '894',
  '3395',
  '915',
  '3396',
  '695',
  '697',
  '696',
  '1629',
  '1365',
  '3394',
  '1364',
  '1366',
  '698',
  '3397',
  '700',
  '699',
  '3398',
  '1363',
  '1362',
  '3399',
  '1630',
  '3400',
];

@Component({
  selector: 'app-thumb',
  templateUrl: './thumb.component.html',
  styleUrls: ['./thumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThumbComponent implements OnInit, OnDestroy {
  @Input() magazine: Magazine;
  @Output() settings = new EventEmitter<number>();
  @Output() view = new EventEmitter<any>();
  @Output() newDraft = new EventEmitter<number>();
  @Output() showStats = new EventEmitter<number>();
  @ViewChild('picker') picker: MatDatepicker<Date>;

  componentDestroyed$: Subject<boolean> = new Subject();
  press_spiders: any[];
  digital_spiders: any[];
  inputDatePickerValue = '';
  canCloseDatePicker = false;
  isAdmin = false;

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    let drafts: Draft[];

    // Only highligh dates inside the month view.
    if (view === 'month') {
      drafts = this.magazine.drafts.filter((draft: Draft) => {
        return _moment(draft.date_created).format('YYYY-MM-DD') === _moment(cellDate).format('YYYY-MM-DD');
      });

      const date = cellDate.getDate();
      return drafts.length > 0 && date.toString() != _moment().format('DD') ? 'custom-date-class' : '';
    }

    return '';
  };

  constructor(
    public magazineStateService: MagazineStateService,
    private _authStateService: AuthStateService,
    private overlayContainer: OverlayContainer,
    private _mappingPipe: MappingAllPipe,
    private authService: AuthService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private router: Router
  ) {
    if (this._authStateService.userConfig.config.Prensa.Spiders.escrita)
      this.press_spiders = this._mapSpiders(
        this._authStateService.userConfig.config.Prensa.Spiders.escrita.map((s) => s.id.toString())
      );
    else this.press_spiders = this._mapSpiders(PRESSIDS);

    if (this._authStateService.userConfig.config.Prensa.Spiders.digital)
      this.digital_spiders = this._mapSpiders(
        this._authStateService.userConfig.config.Prensa.Spiders.digital.map((s) => s.id.toString())
      ).sort();
    else this.digital_spiders = this._mapSpiders(DIGITALIDS).sort();
  }

  async ngOnInit() {
    // this.magazineStateService.getMagazineDrafts(this.magazine);
    // this.magazineStateService.drafts$.pipe(takeUntil(this.componentDestroyed$)).subscribe((drafts: any) => {
    //   let thumbDraft = drafts.filter((draft) => draft.magazine === this.magazine)[0];
    //   if (thumbDraft) this.drafts = thumbDraft.drafts;
    // });
    this.renderer.setProperty(this.overlayContainer.getContainerElement(), '@.disabled', true);

    const kcToken = await this.authService.getTokenInfo();
    this.isAdmin = kcToken.user.isadmin_press === 'true';
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setup() {
    this.magazineStateService.setMagazine(this.magazine);
    this.settings.emit(this.magazine.id);
  }

  show(id: number) {
    this.view.emit({ magazine: this.magazine.id, draft: id });
  }

  new() {
    const dialogRef = this.dialog.open(ThumbEstimatedCostComponent, {
      width: '600px',
      data: this.magazine,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.newDraft.emit(this.magazine.id);
        this.magazineStateService.setMagazine(
          this.magazineStateService.magazines.filter((magazine) => magazine.id === result.id)[0]
        );
        this.router.navigate(['clipping', 'magazine', result.id, 'draft']);
      }
    });
  }

  stats() {
    this.showStats.emit(this.magazine.id);
  }

  showDrafts(event) {
    this.inputDatePickerValue = event.value;
    // let draft: Draft;
    // draft = this.drafts.filter((draft: Draft) => {
    //   return _moment(draft.date_created).format('YYYY-MM-DD') === _moment(event.value).format('YYYY-MM-DD');
    // })[0];
    // if (draft) this.show(draft.id);
  }

  onCloseDatepickerDrafts(): void {
    setTimeout(() => {
      if (this.canCloseDatePicker) {
        return;
      } else {
        this.picker.open();
      }

      const datePickerContent = $(`mat-datepicker-content`).get(0);

      datePickerContent.style.maxHeight = '95vh';
      datePickerContent.style.height = '100vh';

      let template = `<div style="padding: 0 24px;"><p style="font-size: 18px; font-weight: 500; border-bottom: 1px solid #00000040; padding-bottom: 6px;">Borradores</p><div style="display: flex; flex-wrap: wrap; gap: 12px;">`;

      let draftsCounter = 0;
      this.magazine.drafts.forEach((draft) => {
        if (
          _moment(draft.date_created).format('YYYY-MM-DD') === _moment(this.inputDatePickerValue).format('YYYY-MM-DD')
        ) {
          draftsCounter++;
          template += `<div id="draft-item-${
            draft.id
          }" style="cursor: pointer; cursor: pointer; padding: 10px 16px; background: #00000015; width: fit-content; border-radius: 4px; font-size: 16px; font-weight: 400;">${_moment(
            draft.date_created
          ).format('YYYY-MM-DD HH:mm')}</div>`;
        }
      });

      if (draftsCounter === 0) {
        template += `</p>No hay borradores para el día seleccionado</p>`;
      }

      template += `</div></div>`;

      $(`mat-calendar`).append(template);
      $(`mat-calendar [id^=draft-item-]`).each((i, node) => {
        node.addEventListener('click', () => {
          this.inputDatePickerValue = '';
          this.canCloseDatePicker = true;
          const self = this;
          self.picker.close();

          setTimeout(() => {
            this.show(Number(node.id.split('-')[2]));
            this.canCloseDatePicker = false;
          }, 150);
        });
      });
    }, 0);
  }

  getLastDraft(): Draft {
    return this.magazine.drafts.reduce((prev, current) => {
      return new Date(prev.date_created) > new Date(current.date_created) ? prev : current;
    });
  }

  _mapSpiders(spiderList: string[]): string[] {
    return JSON.parse(JSON.stringify(spiderList)).map((spider: number) => this._mappingPipe.transform(spider));
  }

  getPressSpidersLength(): number {
    let count = 0;

    for (const spiderId of this.press_spiders.map((ds) => ds.id?.toString()?.trim())) {
      if (this.magazine.spiders.map((s) => s.toString().trim()).includes(spiderId)) {
        count++;
      }
    }

    return count;
  }

  getDigitalSpidersLength(): number {
    let count = 0;

    for (const spiderId of this.digital_spiders.map((ds) => ds.id.toString().trim())) {
      if (this.magazine.spiders.map((s) => s.toString().trim()).includes(spiderId)) {
        count++;
      }
    }

    return count;
  }
}
