<div class="container">
  <p class="title">Alertas</p>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Crear alerta</mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="alertForm">
        <mat-form-field>
          <mat-label>Título</mat-label>
          <input matInput formControlName="titulo" placeholder="Título de la alerta" />
        </mat-form-field>
        <!-- <mat-form-field>
          <mat-label>Objeto</mat-label>
          <input matInput formControlName="objeto" />
        </mat-form-field> -->

        <mat-form-field class="example-chip-list">
          <mat-chip-list #chipList aria-label="Search selection">
            <mat-chip
              *ngFor="let chip of objectChipFormArray.controls; let i = index"
              [selectable]="true"
              [removable]="true"
              (click)="editObjeto(i)"
              (keydown.backspace)="removeObjeto(i)"
              [class]="'type-' + chip.get('type')?.value"
              [matTooltip]="
                chip.get('type')?.value === 'operator'
                  ? chip.get('name')?.value === 'AND'
                    ? 'Cambiar a operador OR'
                    : 'Cambiar a operador AND'
                  : undefined
              "
              matTooltipClass="tooltip-bigger-font-size"
            >
              <ng-container
                *ngIf="!chip.get('editing')?.value || chip.get('type')?.value === 'operator'; else editMode"
              >
                {{ chip.get('name')?.value }}
                {{ removable }}
                <mat-icon matChipRemove *ngIf="true" (click)="removeObjeto(i)">cancel</mat-icon>
              </ng-container>
              <ng-template #editMode>
                <form [formGroup]="chip" (ngSubmit)="$event.preventDefault(); saveObjeto(i)">
                  <input
                    type="text"
                    formControlName="name"
                    (keydown.backspace)="$event.stopPropagation()"
                    (keydown.space)="$event.stopPropagation()"
                    (blur)="saveObjeto(i)"
                  />
                  <button mat-button type="submit">Guardar</button>
                </form>
              </ng-template>
            </mat-chip>
            <input
              class="input-search"
              placeholder="Objeto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (keydown.enter)="addOperator()"
              (matChipInputTokenEnd)="addObjeto($event)"
            />
            <mat-icon
              matSuffix
              style="opacity: 0.5; font-size: 18px; cursor: pointer"
              (click)="$event.stopPropagation(); showOperatorsDialog()"
            >
              help
            </mat-icon>
          </mat-chip-list>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Organismo</mat-label>
          <input matInput formControlName="organismo" />
        </mat-form-field>
        <angular2-multiselect
          fixSearchBug
          fixAccents
          class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
          name="provinces"
          formControlName="provinces"
          [data]="dropdownProvinciasList"
          [(ngModel)]="selectedProvinciasItems"
          [settings]="dropdownProvinciasSettings"
        ></angular2-multiselect>
        <app-tenders-search-filters-cpvs
          [selectedSectoresInput]="[]"
          (selectedSectoresOutput)="setSelectedSectores($event)"
        ></app-tenders-search-filters-cpvs>
        <app-tenders-search-filters-organism
          [selectedSectoresInput]="[]"
          (selectedSectoresOutput)="setSelectedOrganismo($event)"
        ></app-tenders-search-filters-organism>
        <angular2-multiselect
          fixSearchBug
          fixAccents
          class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
          name="imports"
          formControlName="imports"
          [data]="dropdownImportesList"
          [(ngModel)]="selectedImportesItems"
          [settings]="dropdownImportesSettings"
        ></angular2-multiselect>
        <button mat-flat-button color="primary" [disabled]="loading" (click)="saveAlert()">
          <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
          Crear alerta
        </button>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- <table
    mat-table
    [dataSource]="(tendersStateService.alerts$ | async).items"
    *ngIf="dropdownProvinciasList.length > 0 && dropdownSectoresList.length > 0 && dropdownImportesList.length > 0"
  >
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Título</th>
      <td mat-cell *matCellDef="let row">
        {{ row.titulo }}
      </td>
    </ng-container>
    <ng-container matColumnDef="filters">
      <th mat-header-cell *matHeaderCellDef>Filtros</th>
      <td mat-cell *matCellDef="let row">
        <div class="filters">
          <div class="filter" *ngIf="row.busqueda.objeto">
            Objeto:
            <p>{{ row.busqueda.objeto }}</p>
          </div>
          <div class="filter" *ngIf="row.busqueda.organismo">
            Organismo:
            <p>{{ row.busqueda.organismo }}</p>
          </div>
          <mat-accordion *ngIf="row.busqueda.provincias.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Provincias ({{ row.busqueda.provincias.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let provincia of row.busqueda.provincias">• {{ getProvinceById(provincia).itemName }}</p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.cpvs.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Cpvs ({{ row.busqueda.cpvs.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let cpv of row.busqueda.cpvs">• {{ getCpvById(cpv).itemName }}</p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.importes.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Importes ({{ row.busqueda.importes.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let importe of row.busqueda.importes">
                • De {{ importe.de | number }}€ a {{ importe.hasta | number }}€
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="tools">
      <th mat-header-cell *matHeaderCellDef>Acciones</th>
      <td mat-cell *matCellDef="let row">
        <a mat-icon-button [matTooltip]="'Lanzar búsqueda'" (click)="search(row)">
          <mat-icon>search</mat-icon>
        </a>
        <a mat-icon-button [matTooltip]="'Editar'" (click)="edit(row)">
          <mat-icon>edit</mat-icon>
        </a>
        <a mat-icon-button [matTooltip]="'Eliminar'" (click)="delete(row)">
          <mat-icon>delete</mat-icon>
        </a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <th mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
      <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
      <p style="margin: 0" *ngIf="!loading">No se han encontrado datos</p>
    </th>
  </table> -->

  <table
    mat-table
    [dataSource]="(tendersStateService.alerts$ | async).items"
    *ngIf="dropdownProvinciasList.length > 0 && dropdownSectoresList.length > 0 && dropdownImportesList.length > 0"
  >
    <ng-container matColumnDef="tenders">
      <th mat-header-cell *matHeaderCellDef>Alertas</th>
      <td mat-cell *matCellDef="let row">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="align-items: center"> {{ row.titulo }} </mat-panel-title>
              <div class="actions">
                <a mat-icon-button [matTooltip]="'Lanzar búsqueda'" (click)="search(row); $event.stopPropagation()">
                  <mat-icon>search</mat-icon>
                </a>
                <a mat-icon-button [matTooltip]="'Editar'" (click)="edit(row); $event.stopPropagation()">
                  <mat-icon>edit</mat-icon>
                </a>
                <a mat-icon-button [matTooltip]="'Eliminar'" (click)="delete(row); $event.stopPropagation()">
                  <mat-icon>delete</mat-icon>
                </a>
              </div>
            </mat-expansion-panel-header>
            <div class="content">
              <div class="filters">
                <div class="filter" *ngIf="row.busqueda.objeto && row.busqueda.objeto.length > 0">
                  Objeto:
                  <p>{{ getObjectValues(row.busqueda.objeto) }}</p>
                </div>
                <div class="filter" *ngIf="row.busqueda.organismo">
                  Organismo:
                  <p>{{ row.busqueda.organismo }}</p>
                </div>
                <mat-accordion *ngIf="row.busqueda.provincias.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Provincias ({{ row.busqueda.provincias.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let provincia of row.busqueda.provincias">• {{ getProvinceById(provincia).itemName }}</p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.cpvs.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Cpvs ({{ row.busqueda.cpvs.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let cpv of row.busqueda.cpvs">• {{ cpv }}</p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.importes.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Importes ({{ row.busqueda.importes.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let importe of row.busqueda.importes">
                      • De {{ importe.de | number }}€ a {{ importe.hasta | number }}€
                    </p>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <th mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
      <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
      <p style="margin: 0" *ngIf="!loading">No se han encontrado datos</p>
    </th>
  </table>

  <mat-paginator [pageIndex]="pageIndex" pageSize="30" (page)="pageEvent($event)" fixed></mat-paginator>
</div>
