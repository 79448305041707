<div class="container">
  <div class="cards">
    <a class="card" [routerLink]="['/tenders/search']">
      <div class="image">
        <mat-icon>search</mat-icon>
        <p>Buscador de licitaciones</p>
      </div>
      <div class="divider"></div>
      <div class="content">
        <p>Busca, encuentra y consigue todas las licitaciones que puedes obtener</p>
        <a [routerLink]="['/tenders/search']">Navegar</a>
      </div>
    </a>
    <a class="card" [routerLink]="['/tenders/alerts']">
      <div class="image">
        <mat-icon>notifications</mat-icon>
        <p>Alertas</p>
      </div>
      <div class="divider"></div>
      <div class="content">
        <p>Recibe tus licitaciones favoritas en tu email</p>
        <a [routerLink]="['/tenders/alerts']">Navegar</a>
      </div>
    </a>
    <a class="card" [routerLink]="['/tenders/favorites']">
      <div class="image">
        <mat-icon>favorite</mat-icon>
        <p>Licitaciones favoritas</p>
      </div>
      <div class="divider"></div>
      <div class="content">
        <p>Accede a los listados de licitaciones favoritas</p>
        <a [routerLink]="['/tenders/favorites']">Navegar</a>
      </div>
    </a>
    <a class="card" [routerLink]="['/tenders/saved-searchs']">
      <div class="image">
        <mat-icon>star</mat-icon>
        <p>Búsquedas guardadas</p>
      </div>
      <div class="divider"></div>
      <div class="content">
        <p>Accede a los listados de búsquedas guardadas</p>
        <a [routerLink]="['/tenders/saved-searchs']">Navegar</a>
      </div>
    </a>
  </div>
</div>
