<form [formGroup]="filtersForm">
  <angular2-multiselect
    fixSearchBug
    fixAccents
    id="organismos-selector"
    class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
    name="sections"
    formControlName="sections"
    [data]="dropdownSectoresList"
    [(ngModel)]="selectedSectoresItems"
    [settings]="dropdownSectoresSettings"
    (onSelect)="onItemSelect($event)"
  ></angular2-multiselect>
  <span>Escribe 3 caracteres en el buscador para encontrar resultados</span>
</form>
