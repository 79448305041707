<h1 mat-dialog-title>Editar alerta</h1>
<div mat-dialog-content>
  <form [formGroup]="alertForm">
    <mat-form-field>
      <mat-label>Título</mat-label>
      <input matInput formControlName="titulo" placeholder="Título de la alerta" />
    </mat-form-field>
    <!-- <mat-form-field>
      <mat-label>Objeto</mat-label>
      <input matInput formControlName="objeto" />
    </mat-form-field> -->

    <mat-form-field class="example-chip-list">
      <mat-chip-list #chipList aria-label="Search selection">
        <mat-chip
          *ngFor="let chip of objectChipFormArray.controls; let i = index"
          [selectable]="true"
          [removable]="true"
          (click)="editObjeto(i)"
          (keydown.backspace)="removeObjeto(i)"
          [class]="'type-' + chip.get('type')?.value"
          [matTooltip]="
            chip.get('type')?.value === 'operator'
              ? chip.get('name')?.value === 'AND'
                ? 'Cambiar a operador OR'
                : 'Cambiar a operador AND'
              : undefined
          "
          matTooltipClass="tooltip-bigger-font-size"
        >
          <ng-container *ngIf="!chip.get('editing')?.value || chip.get('type')?.value === 'operator'; else editMode">
            {{ chip.get('name')?.value }}
            {{ removable }}
            <mat-icon matChipRemove *ngIf="true" (click)="removeObjeto(i)">cancel</mat-icon>
          </ng-container>
          <ng-template #editMode>
            <form [formGroup]="chip" (ngSubmit)="$event.preventDefault(); saveObjeto(i)">
              <input
                type="text"
                formControlName="name"
                (keydown.backspace)="$event.stopPropagation()"
                (keydown.space)="$event.stopPropagation()"
                (blur)="saveObjeto(i)"
              />
              <button mat-button type="submit">Guardar</button>
            </form>
          </ng-template>
        </mat-chip>
        <input
          class="input-search"
          placeholder="Objeto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (keydown.enter)="addOperator()"
          s
          (matChipInputTokenEnd)="addObjeto($event)"
        />
        <mat-icon
          matSuffix
          style="opacity: 0.5; font-size: 18px; cursor: pointer"
          (click)="$event.stopPropagation(); showOperatorsDialog()"
        >
          help
        </mat-icon>
      </mat-chip-list>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Organismo</mat-label>
      <input matInput formControlName="organismo" />
    </mat-form-field>
    <angular2-multiselect
      fixSearchBug
      fixAccents
      class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
      name="provinces"
      formControlName="provinces"
      [data]="dropdownProvinciasList"
      [(ngModel)]="selectedProvinciasItems"
      [settings]="dropdownProvinciasSettings"
    ></angular2-multiselect>
    <angular2-multiselect
      fixSearchBug
      fixAccents
      class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
      name="sections"
      formControlName="sections"
      [data]="dropdownSectoresList"
      [(ngModel)]="selectedSectoresItems"
      [settings]="dropdownSectoresSettings"
    ></angular2-multiselect>
    <angular2-multiselect
      fixSearchBug
      fixAccents
      class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
      name="imports"
      formControlName="imports"
      [data]="dropdownImportesList"
      [(ngModel)]="selectedImportesItems"
      [settings]="dropdownImportesSettings"
    ></angular2-multiselect>
  </form>
</div>
<div mat-dialog-actions class="actions">
  <button mat-button [mat-dialog-close]="true">Cancelar</button>
  <button mat-flat-button cdkFocusInitial color="primary" [disabled]="loading" (click)="save()">
    <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
    Guardar
  </button>
</div>
