<div class="example-container-parent">
  <div class="example-container">
    <div class="fist-form">
      <p class="title">Mis alertas</p>

      <div class="example-container" *ngIf="noDocs && !isLoadingResults">
        <mat-grid-list cols="1" rowHeight="250px">
          <mat-grid-tile>
            <mat-card class="mat-elevation-z0">
              <img mat-card-image class="search-logo" src="{{ LOGOURL }}" alt="Logo" />
              <mat-card-content>
                <h2>No existen alertas registradas en el sistema para este usuario</h2>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </div>

      <div class="example-table-container" *ngIf="!noDocs || isLoadingResults">
        <table mat-table [dataSource]="items" class="example-table">
          <!-- Title Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Alerta</th>
            <td mat-cell *matCellDef="let row">
              <div matRipple class="abstractRow" (click)="doSearch(row.id)">
                {{ row.name }}
              </div>
            </td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Creación</th>
            <td mat-cell *matCellDef="let row">{{ row.created_at | date }}</td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef>Actualización</th>
            <td mat-cell *matCellDef="let row">{{ row.updated_at | date }}</td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="tools">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <button
                type="button"
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Buscar"
                matTooltip="Ejecutar búsqueda"
                (click)="doSearch(row.id)"
                class="greybutton"
              >
                <mat-icon>send</mat-icon>
              </button>
              <button
                type="button"
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Editar"
                matTooltip="Eliminar Alerta"
                (click)="openDialog(row.id)"
                class="greybutton"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <th
            scope="col"
            mat-row
            *matNoDataRow
            class="no-data-row"
            style="display: flex; align-items: center; gap: 12px; padding: 16px"
          >
            <mat-spinner [diameter]="24"></mat-spinner>
            <p style="margin: 0; font-weight: 500">Cargando datos...</p>
          </th>
        </table>
      </div>

      <mat-paginator [length]="resultsLength" [pageSize]="100" *ngIf="!noDocs"></mat-paginator>
    </div>
  </div>
</div>
