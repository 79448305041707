import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { SectorReportsService } from '@app/modules/ceoe/services/sector-reports.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { UtilsService } from '@app/services/utils.service';
import { AppStateService } from '@app/state/app/app.service';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { saveAs } from 'file-saver';
import { SectorReportsListSectionGenerateDocComponent } from './sector-reports-list-section-generate-doc/sector-reports-list-section-generate-doc.component';
import { SectorReportsListSectionDeleteDialogComponent } from './sector-reports-list-section-delete-dialog/sector-reports-list-section-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sector-reports-list-section',
  templateUrl: './sector-reports-list-section.component.html',
  styleUrls: ['./sector-reports-list-section.component.scss'],
})
export class SectorReportsListSectionComponent {
  @ViewChild('stepper') stepper!: MatStepper;

  dataForm: FormGroup;
  sectionsForm: FormGroup;
  loading = true;
  sources;
  template;
  item;
  params;
  stepperIndex = -1;
  isPanelExpanded = true;
  saving = false;

  constructor(
    public appStateService: AppStateService,
    public utilsService: UtilsService,
    public router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private authStateService: AuthStateService,
    private sectorReportsService: SectorReportsService,
    private _snackbarStateService: SnackbarStateService
  ) {
    this.appStateService.addTitle('Informes sectoriales');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.dataForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.sectionsForm = this.fb.group({
      sections: this.fb.array([]),
    });

    this.route.params.subscribe((params) => {
      this.params = JSON.parse(JSON.stringify(params));

      this.appStateService.addHistoryUrl(`/sector-reports/list/${this.params.id}`);

      this.item = this.authStateService.userConfig.interface_config.menu_items
        .find((mi) => mi.id === 25)
        .items.find((i: any) => i.id == this.params.id);

      this.getSources();
      // this.getSourcesLatest();
    });
  }

  get sectionsFormArray(): FormArray {
    return this.sectionsForm.controls.sections as FormArray;
  }

  getSectionQuestionsFormArray(index: number): FormArray {
    return (this.sectionsFormArray.at(index) as FormGroup).controls.questions as FormArray;
  }

  // getSourcesLatest(): void {
  //   this.sectorReportsService.getSourcesLatests({ page: 1, size: 30 }).subscribe((data) => {
  //     this.sourcesLatest = data.items;
  //     this.loadingSourcesLatest = false;
  //   });
  // }

  getSources(): void {
    const request: any = { page: 1, size: 999, categoryId: this.params.id };

    this.sectorReportsService.getSources(request).subscribe((data) => {
      this.sources = data;

      if (this.params.idSection === 'create') {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      } else {
        this.sectorReportsService.getTemplate(this.params.idSection).subscribe((data) => {
          this.template = data;
          this.dataForm.controls.title.setValue(data.title);
          this.dataForm.controls.description.setValue(data.description);
          data.sections.forEach((s) => {
            this.addSection(s);
          });

          this.isPanelExpanded = false;

          setTimeout(() => {
            this.stepperIndex = data.sections.length - 1;
          }, 0);

          this.loading = false;
        });
      }
    });
  }

  generateAnswer(indexParent: number, indexChild: number): void {
    const fg = this.getSectionQuestionsFormArray(indexParent).at(indexChild) as FormGroup;

    fg.markAllAsTouched();

    if (fg.controls.question.valid && fg.controls.source.valid && fg.controls.period.valid) {
      fg.controls.loadingAnswer.setValue(true);

      const request: any = {
        document_id: this.getDocumentBySourceAndPeriod(fg.controls.source.value, fg.controls.period.value).id,
        question: fg.controls.question.value,
      };

      this.sectorReportsService.postQuestion(request).subscribe(
        (data) => {
          fg.controls.loadingAnswer.setValue(false);
          fg.controls.answer.setValue(data.answer);
        },
        () => {
          fg.controls.loadingAnswer.setValue(false);
          this._snackbarStateService.show('Se ha producido un error generando la respuesta', 2000);
        }
      );
    }
  }

  setPeriods(indexParent: number, indexChild: number, source: any): void {
    const fg = this.getSectionQuestionsFormArray(indexParent).at(indexChild) as FormGroup;
    fg.controls.periodsList.setValue(this.getSource(source.value).documents.map((d) => d.period));
    fg.controls.period.setValue(undefined);
  }

  addSection(section?: any): void {
    this.sectionsFormArray.push(
      this.fb.group({
        title: [section?.title || '', Validators.required],
        questions: this.fb.array(
          (section?.questions || []).map((a) => {
            return this.fb.group({
              question: [a?.question || '', Validators.required],
              source: [this.getSourceByDocumentId(a.document_id)?.id || undefined, Validators.required],
              periodsList: [
                this.getSourceByDocumentId(a.document_id)
                  ? this.getSourceByDocumentId(a.document_id).documents.map((d) => d.period)
                  : [],
              ],
              period: [
                this.getSourceByDocumentId(a.document_id).documents.find((d) => d.id == a.document_id)?.period ||
                  undefined,
                Validators.required,
              ],
              answer: [a?.answer || undefined, Validators.required],
              loadingAnswer: [false],
            });
          })
        ),
      })
    );
  }

  addSectionAnswer(index: number, question?: any): void {
    this.getSectionQuestionsFormArray(index).push(
      this.fb.group({
        question: [question?.question || '', Validators.required],
        source: [question?.source || undefined, Validators.required],
        periodsList: [[]],
        period: [question?.period || undefined, Validators.required],
        answer: [question?.answer || undefined, Validators.required],
        loadingAnswer: [false],
      })
    );

    setTimeout(() => {
      const element = document.querySelector('.steps-content-forms');
      element.scrollTop = element.scrollHeight;

      (
        document.querySelector(
          `#input-section-${index}-question-${this.getSectionQuestionsFormArray(index).length - 1}`
        ) as HTMLInputElement
      ).focus();
    }, 0);
  }

  deleteSection(index: number): void {
    const dialogRef = this.dialog.open(SectorReportsListSectionDeleteDialogComponent, {
      width: '600px',
      data: {
        title: `Eliminar sección - ${
          this.utilsService.castAbstractControlToFormGroup(this.sectionsFormArray.at(this.stepperIndex)).controls.title
            .value
        }`,
        desc: 'Escribe la palabra BORRAR dentro del texto para confirmar la eliminación.',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sectionsFormArray.removeAt(index);
        if (this.stepperIndex > 0) {
          this.stepperIndex--;
        } else {
          this.stepperIndex = -2;
        }
      }
    });
  }

  deleteSectionQuestion(indexParent: number, indexChild: number): void {
    this.getSectionQuestionsFormArray(indexParent).removeAt(indexChild);
  }

  dropSectionQuestion(event: CdkDragDrop<string[]>, indexSection: number): void {
    this.moveItemInFormArray(this.getSectionQuestionsFormArray(indexSection), event.previousIndex, event.currentIndex);
  }

  moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
    const dir = toIndex > fromIndex ? 1 : -1;
    const item = formArray.at(fromIndex);

    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }

    formArray.setControl(toIndex, item);
  }

  saveTemplate(): void {
    if (this.dataForm.valid && this.allSectionsAreValid()) {
      this.saving = true;

      if (this.params.idSection === 'create') {
        this.sectorReportsService.postTemplate(this.getTemplateRequest()).subscribe((data) => {
          this.params.idSection = data.id;
          this.location.replaceState(this.router.url.replace('create', data.id));
          this._snackbarStateService.show('Plantilla guardada correctamente', 3000);
          this.saving = false;
        });
      } else {
        const request = this.getTemplateRequest();
        request.id = this.params.idSection;

        this.sectorReportsService.putTemplate(request).subscribe((data) => {
          this._snackbarStateService.show('Plantilla guardada correctamente', 3000);
          this.saving = false;
        });
      }
    } else {
      this._snackbarStateService.show('Se han encontrado errores en el formulario', 3000);
    }
  }

  getTemplateRequest(): any {
    this.sectionsFormArray.controls.forEach((controlParent, indexParent) => {
      const fgParent = controlParent as FormGroup;
      this.getSectionQuestionsFormArray(indexParent).controls.forEach((controlChild, indexChild) => {
        const fgChild = controlChild as FormGroup;
      });
    });

    const request: any = {
      category_id: this.params.id,
      title: this.dataForm.controls.title.value,
      description: this.dataForm.controls.description.value,
      prompt: '',
      sections: this.sectionsFormArray.controls.map((controlParent, indexParent) => {
        const fgParent = controlParent as FormGroup;
        return {
          title: fgParent.controls.title.value,
          questions: this.getSectionQuestionsFormArray(indexParent).controls.map((controlChild, indexChild) => {
            const fgChild = controlChild as FormGroup;
            return {
              document_id: this.getDocumentBySourceAndPeriod(
                fgChild.controls.source.value,
                fgChild.controls.period.value
              ).id,
              question: fgChild.controls.question.value,
              answer: fgChild.controls.answer.value,
            };
          }),
        };
      }),
    };

    return request;
  }

  generateReport(): void {
    if (this.dataForm.valid && this.allSectionsAreValid()) {
      if (this.params.idSection === 'create') {
        this.saving = true;
        this.sectorReportsService.postTemplate(this.getTemplateRequest()).subscribe((data) => {
          this.params.idSection = data.id;

          const request: any = {
            ...this.getTemplateRequest(),
            report: '',
          };

          delete request.category_id;

          this.location.replaceState(this.router.url.replace('create', data.id));
          this._snackbarStateService.show('Plantilla guardada correctamente', 3000);
          this.saving = false;

          this.sectorReportsService.postSectorReport(this.params.idSection, request).subscribe((data2) => {
            this.openGenerateReportBottomSheet(data2.id, request);
          });
        });
      } else {
        const request: any = {
          ...this.getTemplateRequest(),
          report: '',
        };

        delete request.category_id;

        this.sectorReportsService.postSectorReport(this.params.idSection, request).subscribe((data2) => {
          this.openGenerateReportBottomSheet(data2.id, request);
        });
      }
    } else {
      this._snackbarStateService.show('Se han encontrado errores en el formulario', 3000);
    }
  }

  openGenerateReportBottomSheet(idReport: number, request: any): void {
    this._bottomSheet.open(SectorReportsListSectionGenerateDocComponent, {
      autoFocus: false,
      panelClass: 'panel-bottom-sheet-w-100-h-100',
      data: {
        data: this.dataForm.value,
        request,
        idSection: this.params.idSection,
        idReport,
      },
    });
  }

  allSectionsAreValid(): boolean {
    const invalidOrHasNotQuestions = this.sectionsFormArray.controls.some((control) => {
      return control.invalid || (control as FormGroup).controls.questions.value.length === 0;
    });

    return !invalidOrHasNotQuestions;
  }

  getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  completeIntroductionStep(): void {
    this.dataForm.markAllAsTouched();

    if (this.dataForm.valid) {
      this.isPanelExpanded = false;

      if (this.sectionsFormArray.controls.length === 0) {
        this.addSection();
      }

      setTimeout(() => {
        this.stepperIndex++;
      }, 0);
    }
  }

  addSectionBtn(): void {
    this.addSection();

    setTimeout(() => {
      this.stepperIndex = this.stepper.steps.length - 1;
    }, 0);
  }

  selectionStepperChange(event: any): void {
    this.stepperIndex = event.selectedIndex;
  }

  getSource(id: number): any {
    return this.sources.items.find((i) => i.id == id);
  }

  getDocumentBySourceAndPeriod(sourceId: number, periodLabel: string): any {
    const source = this.sources.items.find((i) => i.id == sourceId);
    return source.documents.find((d) => d.period == periodLabel);
  }

  getSourceByDocumentId(documentId: number): any {
    return this.sources.items.find((i) => i.documents.some((d) => d.id == documentId));
  }

  viewReports(): void {
    this.router.navigate(['sector-reports', 'list', this.params.id, this.params.idSection, 'reports']);
  }
}
