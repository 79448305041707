import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-magazine-draft-estimated-price-dialog',
  templateUrl: './magazine-draft-estimated-price-dialog.component.html',
  styleUrls: ['./magazine-draft-estimated-price-dialog.component.scss'],
})
export class MagazineDraftEstimatedPriceDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MagazineDraftEstimatedPriceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { cost: number; provision: number }
  ) {}

  submit(): void {
    this.dialogRef.close(true);
  }
}
