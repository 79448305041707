import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { DocumentStateModel } from '../models/document.state.model';
import { BrainyDoc } from '@app/models/Brainy.document';
import {
  AddSelectedDocument,
  GetDocuments,
  ToggleInteresting,
  UnsetDocuments,
  UpdateTabDocuments,
  AddDocumentsToCategory,
} from './document.actions';

@Injectable({
  providedIn: 'root',
})
@State<DocumentStateModel>({
  name: 'documents',
  defaults: {
    searchs: [],
  },
})
export class DocumentState {
  @Selector()
  static searchs(state: DocumentStateModel): { title: string; documents: BrainyDoc[] }[] | null {
    return state.searchs;
  }

  @Selector()
  static interestingSearch(state: DocumentStateModel): { title: string; documents: BrainyDoc[] }[] | null {
    let interesting = state.searchs.map((search) => {
      return {
        id: search.id,
        title: search.title,
        documents: search.documents.filter((document) => document.interesting === true),
      };
    });

    return interesting;
  }

  @Selector()
  static document(state: DocumentStateModel): BrainyDoc | null {
    return state.selectedDocument;
  }

  @Selector()
  static filteredDocs(state: DocumentStateModel): any | null {
    return state.filteredDocs;
  }

  constructor(private _dialogRef: MatDialog) {}

  @Action(GetDocuments)
  addDocumentsByState({ getState, patchState }: StateContext<DocumentStateModel>, response: { payload }) {
    let searchs = JSON.parse(JSON.stringify(getState())).searchs;
    searchs.push(response.payload[0]);
    patchState({ searchs: searchs });
  }

  @Action(AddDocumentsToCategory)
  addDocumentsToCategoryByState({ getState, patchState }: StateContext<DocumentStateModel>, response: { payload }) {
    let searchs = JSON.parse(JSON.stringify(getState())).searchs;

    searchs = searchs.map((search) => {
      if (search.title === response.payload.title) {
        search.documents = search.documents.concat(response.payload.documents);
      }

      return search;
    });

    patchState({ searchs: searchs });
  }

  @Action(UpdateTabDocuments)
  updateTabDocumentsByState({ patchState }: StateContext<DocumentStateModel>, response: { payload }) {
    patchState({ filteredDocs: response.payload });
  }

  @Action(ToggleInteresting)
  toggleInterestingByState({ getState, patchState }: StateContext<DocumentStateModel>, payload: { docId: string }) {
    let state = JSON.parse(JSON.stringify(getState()));
    state = state.searchs.map((search) => {
      return {
        title: search.title,
        documents: search.documents.map((document) => {
          if (document.docId === payload.docId) {
            document.interesting = !document.interesting;
          }

          return document;
        }),
      };
    });

    patchState({ searchs: state });
  }

  @Action(UnsetDocuments)
  unsetDocumentsByState({ patchState }: StateContext<DocumentStateModel>) {
    patchState({ searchs: [] });
  }

  @Action(AddSelectedDocument)
  addSelectedReportByState({ patchState }: StateContext<DocumentStateModel>, response: { payload }) {
    patchState({ selectedDocument: response.payload });
  }
}
