import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SectorReportsWebSocketService {
  socket: WebSocket;
  dataReceived: Subject<string> = new Subject<string>();

  constructor() {}

  connect(url: string): void {
    this.socket = new WebSocket(url);

    this.socket.onopen = () => {
      // console.log('WebSocket connection established.');
    };

    this.socket.onmessage = (event) => {
      // console.log('Received message:', event.data);
      this.dataReceived.next(event.data);
    };

    this.socket.onclose = (event) => {
      // console.log('WebSocket connection closed:', event);
      this.closeConnection();
    };

    this.socket.onerror = (error) => {
      // console.error('WebSocket error:', error);
      this.closeConnection();
    };
  }

  sendData(data: { type: string; data: any }): void {
    this.socket.send(JSON.stringify(data));
  }

  closeConnection(): void {
    if (this.socket) {
      this.socket.close();
      this.socket = undefined;
    }
  }
}
