<!-- https://github.com/tahashahid/ng2-floating-button -->

<div class="buttons">
  <button
    id="docbotonera-favorite-button"
    mat-fab
    class="fav"
    color="primary"
    [matTooltip]="!docFavorite ? 'Añadir a favoritos' : 'Eliminar de favoritos'"
  >
    <mat-icon class="icon-2x" fontSet="material-icons-outlined" *ngIf="!docFavorite" (click)="addfav()"
      >folder_special</mat-icon
    >
    <mat-icon class="icon-2x" *ngIf="docFavorite" (click)="delfav()">folder_special</mat-icon>
  </button>

  <button
    mat-fab
    class="link"
    color="primary"
    (click)="openUrl()"
    matTooltip="Link al original"
    *ngIf="!isPrensaModule"
  >
    <mat-icon class="icon-2x">link</mat-icon>
  </button>

  <button mat-fab class="email" color="primary" (click)="openEmail()" matTooltip="Compartir Documento">
    <mat-icon class="icon-2x">email</mat-icon>
  </button>

  <!--a
mat-fab
href="/#/printdoc/{{docID}}"
class="print"
color="primary"
matTooltip="Imprimir Documento"
target="_blank">
<mat-icon  class="icon-2x">local_printshop</mat-icon>
</a-->

  <button
    *ngIf="docprint.type == 'html'"
    mat-fab
    class="print"
    color="primary"
    printSectionId="print-section"
    ngxPrint
    printTitle="Brainy Inteligencia Semántica"
    [printStyle]="{
      h1: { color: '#015048', 'font-family': 'Arial' },
      p: { margin: '10px 0px 15px 0px', 'text-align': 'justify', 'font-family': 'Helvetica' },
      body: { margin: '20px 55px 2px 55px' },
      divFooter: { background: 'LOGOURL', position: 'fixed', height: '100px', width: '100px', bottom: '0' }
    }"
    matTooltip="Imprimir Documento"
  >
    <mat-icon class="icon-2x">local_printshop</mat-icon>
  </button>

  <button
    *ngIf="docprint.type == 'pdf'"
    mat-fab
    class="print"
    color="primary"
    (click)="printPDF()"
    matTooltip="Imprimir Documento"
  >
    <mat-icon class="icon-2x">local_printshop</mat-icon>
  </button>

  <button
    mat-fab
    class="draft"
    color="primary"
    (click)="addDraft()"
    matTooltip="Añadir a revista de prensa"
    *ngIf="hasMagazineAllowed"
  >
    <mat-icon class="icon-2x">content_paste</mat-icon>
  </button>

  <!--button disabled
mat-fab
class="download"
color="primary"
(click)="destroy()"
matTooltip="Descargar Documento">
<mat-icon (click)="destroy()" class="icon-2x">save_alt</mat-icon>
, 'background-image':'LOGOURL'
;'font-family': 'Roboto'
</button-->
</div>

<div id="print-section">
  <table>
    <thead>
      <tr>
        <td>
          <div class="header-space" style="width: 100%; text-align: right"></div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div class="content">
            <h1>{{ docprint.title }}</h1>
            <div>
              <strong>{{ docprint.source }}</strong>
            </div>
            <div>
              <i>{{ docprint.date }}</i>
            </div>
            <p [innerHTML]="docprint.body"></p>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          <div class="footer-space">&nbsp;</div>
        </td>
      </tr>
    </tfoot>
  </table>
  <div class="header"></div>
  <div class="divFooter"></div>
</div>
<ng-content></ng-content>
