import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrainyDoc } from '@app/models/Brainy.document';
import { DocumentStateService } from '../../store/report/documents.service';
import { defaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'doc-detail-dialog',
  templateUrl: './doc-detail.dialog.html',
  styleUrls: ['./doc-detail.dialog.scss'],
})
export class DocDetailDialog implements OnInit {
  currentRecipients: string[] = [];

  constructor(
    public documentStateService: DocumentStateService,
    public dialogRef: MatDialogRef<DocDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: BrainyDoc
  ) {
    defaultOptions.externalLinkTarget = 2;
  }

  ngOnInit() {
    this.documentStateService.getDocument(this.data.docId);
    setTimeout(() => {
      document.getElementById('mypdf').getElementsByClassName('zoom')[0]['style']['height'] = '1024px';
    }, 1000);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
