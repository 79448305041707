import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from '@app/services/utils.service';
import { AppStateService } from '@app/state/app/app.service';

@Component({
  selector: 'search-type-operators-dialog',
  templateUrl: './search-type-operators-dialog.component.html',
  styleUrls: ['./search-type-operators-dialog.component.scss'],
})
export class SearchTypeOperatorsDialogComponent implements OnInit {
  form: FormGroup;
  result = '';

  operatorOptions = [
    { label: 'Y', value: 'operator-and' },
    { label: 'O', value: 'operator-or' },
    { label: 'SIN', value: 'operator-not' },
  ];
  textOptions = [
    { label: 'Literal', value: 'text-literal' },
    { label: 'Amplia', value: 'text-amplia' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      text: string;
    },
    public appStateService: AppStateService,
    public utilsService: UtilsService,
    private matDialogRef: MatDialogRef<SearchTypeOperatorsDialogComponent>,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      groups: this.fb.array([]),
    });

    this.groupsFormArray.valueChanges.subscribe(() => {
      this.result = this.getTextByGroups();
    });
  }

  get groupsFormArray(): FormArray {
    return this.form.controls.groups as FormArray;
  }

  getGroupBlocksFormArray(index: number): FormArray {
    return (this.groupsFormArray.at(index) as FormGroup).controls.blocks as FormArray;
  }

  ngOnInit(): void {
    if (this.data.text) {
      this.getGroupsByText();
    } else {
      this.addGroup();
      this.addGroupBlock(0);
    }
  }

  addGroup(group?: any): void {
    this.groupsFormArray.push(
      this.fb.group({
        id: this.getRandomInt(1, 9999999999),
        separatorValue: group?.separatorValue,
        blocks: this.fb.array(
          (group?.blocks || []).map((b) => {
            this.addGroupBlock(this.groupsFormArray.length, b);
          })
        ),
      })
    );
  }

  deleteGroup(groupIndex: number): void {
    this.groupsFormArray.removeAt(groupIndex);
    this.groupsFormArray.removeAt(groupIndex - 1);
  }

  addGroupBlock(groupIndex: number, block?: any): void {
    const id = this.getRandomInt(1, 9999999999);

    this.getGroupBlocksFormArray(groupIndex).push(
      this.fb.group({
        id,
        text: [block?.text || '', Validators.required],
        type: [block?.type || 'text-literal', Validators.required],
      })
    );

    setTimeout(() => {
      (document.querySelector(`#group-block-input-text-${id}`) as HTMLInputElement)?.focus();
    }, 0);
  }

  deleteGroupBlock(groupIndex: number, groupBlockIndex: number): void {
    this.getGroupBlocksFormArray(groupIndex).removeAt(groupBlockIndex);

    if (groupBlockIndex <= this.getGroupBlocksFormArray(groupIndex).length - 1) {
      this.getGroupBlocksFormArray(groupIndex).removeAt(groupBlockIndex);
    }
  }

  howToUse(): void {
    window.open(`${window.location.origin}/assets/imgs/search-type-illustration.svg`);
  }

  applyFilters(): void {
    const text = this.getTextByGroups();
    this.matDialogRef.close(text);
  }

  getTextByGroups(): string {
    return this.groupsFormArray.value
      .map((g, i) => {
        if (!g.separatorValue) {
          let text = `${g.blocks
            .map((b, i2) => {
              switch (b.type) {
                case 'operator-and':
                  return `+`;
                case 'operator-or':
                  return ` `;
                case 'operator-not':
                  return i === 0 && i2 === 0 ? `-` : `+-`;
                case 'text-literal':
                  return `'${b.text}'`;
                case 'text-amplia':
                  return `"${b.text}"`;
              }
            })
            .join('')}`;

          if (this.groupsFormArray.length > 1) {
            return `(${text})`;
          } else {
            return text;
          }
        } else {
          switch (g.separatorValue) {
            case 'operator-and':
              return `+`;
            case 'operator-or':
              return ` `;
            case 'operator-not':
              return `+-`;
          }
        }
      })
      .join('');
  }

  getGroupsByText(): void {
    if (!this.data.text.includes('(') && !this.data.text.includes(')')) {
      this.addGroup();
    }

    for (let i = 0; i < this.data.text.length; i++) {
      const char = this.data.text[i];

      if (char === '(') {
        this.addGroup();
      } else if (char === '+') {
        if (this.data.text[i + 1] === '-') {
          if (i > 0 && this.data.text[i - 1] === ')') {
            this.addGroup({ separatorValue: 'operator-not' });
          } else {
            this.addGroupBlock(this.groupsFormArray.length - 1, { type: 'operator-not' });
          }
        } else {
          if (i > 0 && this.data.text[i - 1] === ')') {
            this.addGroup({ separatorValue: 'operator-and' });
          } else {
            this.addGroupBlock(this.groupsFormArray.length - 1, { type: 'operator-and' });
          }
        }
      } else if (char === ' ') {
        if (i > 0 && this.data.text[i - 1] === ')') {
          this.addGroup({ separatorValue: 'operator-or' });
        } else {
          this.addGroupBlock(this.groupsFormArray.length - 1, { type: 'operator-or' });
        }
      } else if (char === '-') {
        if (this.data.text[i - 1] !== '+') {
          if (i > 0 && this.data.text[i - 1] === ')') {
            this.addGroup({ separatorValue: 'operator-not' });
          } else {
            this.addGroupBlock(this.groupsFormArray.length - 1, { type: 'operator-not' });
          }
        }
      } else if (char === "'") {
        const endIndex = this.data.text.indexOf("'", i + 1);
        this.addGroupBlock(this.groupsFormArray.length - 1, {
          text: this.data.text.slice(i + 1, endIndex),
          type: 'text-literal',
        });
        i = endIndex;
      } else if (char === '"') {
        const endIndex = this.data.text.indexOf('"', i + 1);
        this.addGroupBlock(this.groupsFormArray.length - 1, {
          text: this.data.text.slice(i + 1, endIndex),
          type: 'text-amplia',
        });
        i = endIndex;
      }
    }
  }

  getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
