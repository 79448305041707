import { Pipe } from '@angular/core';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { AppStateService } from '@app/state/app/app.service';
import { SpiderMappings } from '@app/state/models/app.state.model';

@Pipe({
  name: 'mapcats',
})
export class MappingCatsPipe {
  constructor(private _authStateService: AuthStateService) {}

  transform(value: number, args?: any) {
    let mapping = undefined;

    this._authStateService.userConfig.config.Documentos.Topics.map((topic_group) => {
      topic_group.topics.filter((topic) => {
        if (topic == value) {
          mapping = topic_group['visibleName'].replace('- ', '');
        }
      });
    });

    return mapping;
  }
}
