<h1 mat-dialog-title>Eliminar contenido</h1>
<div mat-dialog-content *ngIf="!data">
  <p>¿Seguro que desea eliminar el elemento?</p>
  <p>Esta acción no se puede deshacer.</p>
</div>
<div mat-dialog-content *ngIf="data?.id && !data?.name">
  <p>¿Seguro que desea eliminar el registro con identificador {{ data.id }}?</p>
  <p>Esta acción no se puede deshacer.</p>
</div>
<div mat-dialog-content *ngIf="data?.name">
  <p>¿Seguro que desea eliminar el registro "{{ data.name }}"?</p>
  <p>Esta acción no se puede deshacer.</p>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <button mat-flat-button (click)="onNoClick()" color="warn">Cancelar</button>
  <button mat-flat-button (click)="deleteField()" cdkFocusInitial color="primary">Confimar</button>
</div>
