import { Component, ChangeDetectorRef, Input, ViewChild, Renderer2, Output, EventEmitter } from '@angular/core';
import { DocumentService } from '@modules/documents/services/document.service';
import { HtmltextPipe } from '@app/pipes/htmltext.pipe';
import { MatDrawer } from '@angular/material/sidenav';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { AppStatusService } from '@app/services/app-status.service';
import { AppDataService } from '@app/services/app-data.service';
import { SearchService } from '@app/modules/main/components/search/services/search.service';
import { SpidersService } from '@app/modules/main/components/search/services/spiders.service';
import moment from 'moment';
import { MainSearch } from '@app/modules/main/components/search/search.component';

@Component({
  selector: 'doc-gen',
  templateUrl: './docgen.component.html',
  styleUrls: ['./docgen.component.less'],
})
export class DocGenComponent {
  doc: any;
  isLoadingDoc = true;
  isLoadingTopics = true;
  isRateLimitReached = false;
  topics;
  querytext: string;
  searchterms: string[];
  text_search: string;
  region: string;
  docprint: any;
  page: number = 1;

  @Input() docID;
  @Input() lookfortext;
  @Input() openwithsearch;
  @Output() docLoaded = new EventEmitter();
  @ViewChild('drawer') public drawer: MatDrawer;

  constructor(
    public authStateService: AuthStateService,
    private appStatus: AppStatusService,
    private sDocumento: DocumentService,
    private sService: SearchService,
    private changeDetector: ChangeDetectorRef,
    private sappData: AppDataService,
    private renderer: Renderer2,
    private sSpider: SpidersService
  ) {
    let cleanUpTopics = (item) => {
      if (item.id && item.id != MainSearch.CATEGORIES_ALL) {
        let name = item.visibleName ? item.visibleName : item.name;
        return {
          id: item.topics && item.topics.length > 0 ? item.topics[0] : item.id,
          itemName: name.replace('-', '').trim(),
        };
      }
      return undefined;
    };

    setTimeout(() => {
      this.sSpider.getTopics().subscribe((response) => {
        this.topics = this.prepare(response.results.map(cleanUpTopics));
        this.isLoadingTopics = false;
      });

      this.sDocumento.getDocument(this.docID).subscribe(
        (doc) => {
          this.doc = doc;
          this.docID = this.doc.docId;
          let reg = [];
          if (this.doc.docTopicsRegion && this.doc.docTopicsRegion.length > 0) {
            this.doc.docTopicsRegion.forEach((element) => {
              reg.push(this.sappData.map('regions_id', element));
            });
          }
          reg = reg.filter((n) => isNaN(parseFloat(n)));
          this.region = reg.length > 0 ? reg.join() + ' - ' : '';
          this.docprint = {
            title: this.doc.titleParsed,
            body: this.bodytohtml(this.doc.bodyParsed),
            type: this.doc.docType,
            date: this.region + moment(this.doc.dateParsed).format('LLL'),
            // "date": this.region + moment(this.doc.dateParsed).tz('Europe/Madrid').format('LLL'),
          };
          this.isLoadingDoc = false;
          if (this.lookfortext !== undefined) {
            this.text_search = this.lookfortext;
            var that = this;
            if (this.openwithsearch)
              setTimeout(function () {
                that.textsearch(that.text_search);
              }, 1000);
          }

          this.docLoaded.emit();
        },
        (error) => {
          this.isRateLimitReached = true;
          this.isLoadingDoc = false;
          this.docLoaded.emit();
        }
      );

      this.appStatus.brain_click.subscribe((event) => {
        this.drawer.toggle();
      });
    }, 100);
  }

  OnResize(event) {}

  goToPage($event) {
    if (typeof $event == 'number') {
      this.page = $event;
      window['PDFViewerApplication'].page = $event || 1;
    }
  }

  textsearch($event) {
    this.querytext = '';
    this.changeDetector.detectChanges();
    this.querytext = $event;
    this.changeDetector.detectChanges();
  }

  setterms($event) {
    this.searchterms = $event;
  }

  addfav() {
    this.sService.saveFavorite(this.doc.titleParsed, this.docID).subscribe((ev) => {
      this.doc._favorite = true;
      this.appStatus.favorite_save.emit(this.docID);
    });
  }

  delfav() {
    this.sService.deleteFavorite(this.docID).subscribe((ev) => {
      this.doc._favorite = false;
      this.appStatus.favorite_delete.emit(this.docID);
    });
  }

  bodytohtml(doc: string) {
    var thepipe: HtmltextPipe = new HtmltextPipe();
    return thepipe.transform(doc);
  }

  private prepare(arr) {
    arr = arr && arr[0] && arr[0].constructor.toString().match(/Array/) ? arr[0] : arr;
    return !arr ? undefined : arr.filter((item) => item != undefined);
  }
}
