<div class="container" *ngIf="getRealm() === KC_REALM_CEOE">
  <p>
    En nuestro continúo esfuerzo por ofrecer el mejor servicio estamos evolucionando la actual plataforma de
    CEOEXEuropa.
  </p>
  <p>
    En caso de encontrar una incidencia técnica, por favor <a href="mailto:ceoexeuropa@ceoe.es">contáctenos</a> a través
    del correo ceoexeuropa@ceoe.es con una descripción de la incidencia y le contestaremos a la mayor brevedad posible.
  </p>
  <p>El equipo de CEOE | data.</p>
</div>
<div class="container" *ngIf="getRealm() === KC_REALM_BRAINY">
  <p>
    En caso de encontrar una incidencia técnica, por favor <a href="mailto:rmarin@brainy.technology">contáctenos</a> a
    través del correo rmarin@brainy.technology con una descripción de la incidencia y le contestaremos a la mayor
    brevedad posible.
  </p>
  <p>El equipo de Brainy.</p>
</div>
