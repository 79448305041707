<div class="container" [formGroup]="form">
  <div class="header">
    <p>{{ data.title }}</p>
    <p>{{ data.desc }}</p>
  </div>
  <div class="content">
    <mat-form-field appearance="fill">
      <input matInput formControlName="delete" placeholder="BORRAR" />
      <mat-error class="error-validator" *ngIf="form.controls.delete.hasError('required')"> Campo requerido </mat-error>
    </mat-form-field>
  </div>
  <div class="buttons">
    <button
      (click)="dialogRef.close(true)"
      mat-dialog-close
      mat-raised-button
      color="warn"
      [disabled]="form.controls.delete.value.toLowerCase() !== 'borrar'"
    >
      Eliminar
    </button>
  </div>
</div>
