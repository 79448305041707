import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { CalendarPPAlertsGetResponse, CalendarPPStateModel } from '../../models/calendar-pp.state.model';
import { DeleteAlert, GetAlerts, PostAlert, PutAlert, ShowFiltersMenu } from './calendar-pp.actions';

@Injectable({
  providedIn: 'root',
})
@State<CalendarPPStateModel>({
  name: 'calendarPP',
  defaults: {
    showFiltersMenu: true,
    alerts: {
      items: [],
      page: 0,
      size: 0,
      total: 0,
      total_pages: 0,
    },
  },
})
export class CalendarPPState {
  @Selector()
  static showFiltersMenu(state: CalendarPPStateModel): boolean | null {
    return state.showFiltersMenu;
  }

  @Selector()
  static alerts(state: CalendarPPStateModel): CalendarPPAlertsGetResponse {
    return state.alerts;
  }

  constructor() { }

  @Action(ShowFiltersMenu)
  showFiltersMenu({ patchState }: StateContext<CalendarPPStateModel>, response: { payload: boolean }) {
    patchState({ showFiltersMenu: response.payload });
  }


  @Action(GetAlerts)
  getAlerts(
    { getState, patchState }: StateContext<CalendarPPStateModel>,
    response: { payload: CalendarPPAlertsGetResponse }
  ) {
    const state = getState();
    let alerts = state.alerts;
    alerts = response.payload;
    patchState({ alerts });
  }

  @Action(DeleteAlert)
  deleteAlert({ getState, patchState }: StateContext<CalendarPPStateModel>, response: { payload: number }) {
    const state = JSON.parse(JSON.stringify(getState()));

    const alerts = state.alerts as CalendarPPAlertsGetResponse;
    const alertIndex = alerts.items.findIndex((i) => i.id === response.payload);

    if (alertIndex !== -1) {
      alerts.items.splice(alertIndex, 1);
    }

    patchState({ alerts });
  }

  @Action(PostAlert)
  postAlert({ getState, patchState }: StateContext<CalendarPPStateModel>, response: { payload: any }) {
    const state = JSON.parse(JSON.stringify(getState()));
    const alerts = state.alerts as CalendarPPAlertsGetResponse;
    alerts.items.push(response.payload);
    patchState({ alerts });
  }

  @Action(PutAlert)
  putAlert({ getState, patchState }: StateContext<CalendarPPStateModel>, response: { payload: any }) {
    const state = JSON.parse(JSON.stringify(getState()));
    const alerts = state.alerts as CalendarPPAlertsGetResponse;
    const alertIndex = alerts.items.findIndex((i) => i.id === response.payload.id);

    if (alertIndex !== -1) {
      alerts.items[alertIndex] = response.payload;
      patchState({ alerts });
    }
  }
}
