<div class="container">
  <button class="cerrar" mat-icon-button color="primary" [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
  <h1 mat-dialog-title>Seleccionar borrador</h1>
  <div mat-dialog-content>
    <mat-accordion *ngIf="magazineService.magazines$ | async as magazines">
      <mat-expansion-panel (closed)="(true)" *ngFor="let magazine of magazines">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ magazine.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list #documents [multiple]="false" (selectionChange)="updateDraft($event)">
          <mat-list-option
            *ngFor="let draft of magazine?.drafts"
            color="primary"
            [value]="{ magazine: magazine.id, draft: draft.id }"
          >
            <div mat-line>
              {{ draft.date_created | date }}
            </div>
            <mat-divider></mat-divider>
          </mat-list-option>
        </mat-selection-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button cdkFocusInitial (click)="addDocument()" [disabled]="!draftID">Adjuntar documento</button>
  </mat-dialog-actions>
</div>
