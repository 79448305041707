import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { MagazineState } from './magazine.state';
import { Magazine } from '../models/magazine.state.model';
import {
  GetMagazines,
  SetMagazine,
  AddMagazine,
  DeleteMagazine,
  EditMagazine,
  GetMagazineDrafts,
  GetMagazineDraft,
} from './magazine.actions';
import { MagazineService } from '@app/services/magazine.service';
import { AppStateService } from '@app/state/app/app.service';
import { UIStatus } from '@app/state/models/app.state.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Router } from '@angular/router';
import { Draft } from '../models/draft.state.model';
import { DraftService } from '@app/services/draft.service';

@Injectable({ providedIn: 'root' })
export class MagazineStateService {
  @Select(MagazineState.magazines)
  public magazines$: Observable<Magazine[]>;

  @SelectSnapshot(MagazineState.magazines)
  public magazines: Magazine[];

  @Select(MagazineState.selectedMagazine)
  public selectedMagazine$: Observable<Magazine>;

  @SelectSnapshot(MagazineState.selectedMagazine)
  public selectedMagazine: Magazine;

  @Select(MagazineState.drafts)
  public drafts$: Observable<Draft[]>;

  constructor(
    private _snackbarStateService: SnackbarStateService,
    private _appStateService: AppStateService,
    private _magazineService: MagazineService,
    private _draftService: DraftService,
    private _router: Router
  ) {}

  @Dispatch()
  public getMagazines = () => {
    this._appStateService.updateUIStatus(UIStatus.loading);
    return this._magazineService.getMagazines().pipe(
      map((response: any) => {
        if (response.data.items.length === 0) this._appStateService.updateUIStatus(UIStatus.empty);
        else this._appStateService.updateUIStatus(UIStatus.loaded);

        return new GetMagazines(response.data.items);
      }),
      catchError((error) => {
        setTimeout(() => this._appStateService.updateUIStatus(UIStatus.error), 2000);
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public setMagazine = (magazine: Magazine) => {
    return new SetMagazine(magazine);
  };

  @Dispatch()
  public addMagazine = (payload) => {
    return this._magazineService.newMagazine(payload).pipe(
      map(() => new AddMagazine(payload)),
      finalize(() => {
        this.getMagazines();
        this._router.navigate(['clipping', 'magazine']);
      }),
      catchError(() => {
        let error = 'Problema al crear la revista, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public editMagazine = (payload) => {
    return this._magazineService.editMagazine(payload).pipe(
      map((response) => new EditMagazine(payload)),
      finalize(() => {
        this._snackbarStateService.show('Revista actualizada', 5000, 'cerrar');
        this._router.navigate(['clipping', 'magazine']);
      }),
      catchError(() => {
        let error = 'Problema al editar la revista, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public deleteMagazine = (payload) => {
    return this._magazineService.deleteMagazine(payload).pipe(
      map((response) => new DeleteMagazine(payload)),
      finalize(() => {
        this._snackbarStateService.show('Revista eliminada', 5000, 'cerrar');
        this._router.navigate(['clipping', 'magazine']);
      }),
      catchError(() => {
        let error = 'Problema al borrar la revista, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getMagazineDrafts = (payload) => {
    return this._magazineService.getMagazineDrafts(payload).pipe(
      map((response: any) => {
        let data = { magazine: payload, drafts: response.data.items };
        return new GetMagazineDrafts(data);
      }),
      catchError(() => {
        let error = 'Problema al obtener los borradores, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getMagazineDraft = (payload: Magazine) => {
    return this._magazineService.getMagazineDraft(payload).pipe(
      map((response: any) => new GetMagazineDraft(response.data.items)),
      catchError(() => {
        let error = 'Problema al imprimir el borrador, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };
}
