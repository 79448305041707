import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { getRealm } from '@app/init/keycloak-init.factory';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { KeycloakService } from 'keycloak-angular';
import { DraftService } from '@app/services/draft.service';
import { environment } from '@env/environment';

@Injectable()
export class NewAuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    public authStateService: AuthStateService,
    private draftService: DraftService,
    private keycloakservice: KeycloakService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const kcInstance = this.keycloakservice.getKeycloakInstance();

    // if (
    //   !kcInstance.authenticated &&
    //   window.location.href.includes('/clipping/magazine') &&
    //   window.location.href.includes('?visor=true')
    // ) {
    //   return from(this.draftService.getVisorToken()).pipe(
    //     switchMap((visorToken: string) => {
    //       request = request.clone({
    //         setHeaders: {
    //           realm: getRealm(),
    //           Authorization: visorToken,
    //         },
    //       });
    //       return this.handleRequest(request, next);
    //     })
    //   );
    // } else {

    if (
      !request.url.includes(environment.clippingCedroUserAccess) &&
      !request.url.includes(environment.clippingCedroSendTraceability) &&
      !request.url.includes(environment.clippingCedroSendDossier)
    ) {
      request = request.clone({
        setHeaders: { realm: getRealm() },
      });
    } else {
      request = request.clone({
        headers: request.headers.delete('Authorization'),
      });
    }

    return this.handleRequest(request, next);

    // }
  }

  handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // Client side error
        } else {
          // Server side error
          if (
            error.status === 401 &&
            this.router.url !== '' &&
            this.router.url !== '/' &&
            this.router.url !== '/login'
          ) {
            this.authStateService.logout();
          }
        }
        return throwError(error.message ?? error.error.message ?? '');
      })
    );
  }
}
