import { Injectable } from '@angular/core';
import { AppDataService } from '@app/services/app-data.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class Formater {
  constructor(private dataService: AppDataService) {}

  public format(query, results) {
    let res = [],
      self = this,
      aggr_nav = function (results, aggregations, types, _name) {
        let agg = aggregations.pop();
        let _type = query.aggregations[0].aggregations ? 'dataset' : types.pop();

        if (!agg && results.doc_count !== undefined) return results.doc_count;

        return results[agg.name].buckets.map((items: any) => {
          let name = _name;
          switch (_type) {
            case 'date_histogram':
              name = moment(items.key_as_string).format('YYYY-MM');
              break;
            case 'dataset':
              name = moment(items.key_as_string).format('YYYY-MM');
              break;
            default:
              name = self.dataService.map(agg.map_name_with, items.key_as_string ? items.key_as_string : items.key);
              break;
          }
          return {
            name: name,
            value: aggr_nav(items, aggregations, types, name),
          };
        });
      };
    if (query.aggregations) {
      let aggrs = [],
        types = [],
        get_aggrnames = function (aggregations) {
          aggregations.forEach((aggr) => {
            aggrs.push({ name: aggr.name_aggregation, map_name_with: aggr.map_name_with });
            if (aggr.aggregations) get_aggrnames(aggr.aggregations);
            if (aggr.type) types.push(aggr.type);
          });
        };
      get_aggrnames(query.aggregations);
      //

      let _aggr_nav = aggr_nav(results.aggregations, aggrs.reverse(), types, undefined),
        hash = {},
        res = [];
      //
      _aggr_nav.forEach((item) => {
        if (!/^[\d\.]+$/.test(item.name))
          hash[item.name] = hash[item.name] == undefined ? item.value : hash[item.name] + item.value;
      });
      Object.keys(hash).forEach((key) => {
        res.push({
          name: key,
          value: hash[key],
        });
      });
      //
      return res;
    }
  }

  public formatDataset(query, results) {
    let keys: any[] = [];
    let months: string[] = [];
    let mySource = new Array([]);
    let tempBuckets = [];
    let myDataset: any[] = new Array([]);
    mySource[0].push('CATEGORIAS');

    let aggregations: Object[] = results.aggregations[Object.keys(results.aggregations)[0]].buckets;

    aggregations.forEach((item) => {
      let month: string = moment(item['key_as_string']).format('YYYY-MM');
      months.push(month);
      mySource[0].push(month);

      let buckets = item[Object.keys(item)[0]].buckets;

      buckets.forEach((bucket) => {
        let key: string = bucket['key'];
        let doc_count: number = bucket['doc_count'];
        let cat_month: string = month;

        if (typeof tempBuckets[key] === 'undefined') {
          tempBuckets[key] = [];
        }

        tempBuckets[key].push({ month: cat_month, doc_count: doc_count });
      });
    });

    months.forEach((month, index) => {
      if (typeof myDataset[index] === 'undefined') myDataset[index] = [];
      tempBuckets.forEach((bucket) => {
        let key = tempBuckets.indexOf(bucket);

        if (!keys.includes(key)) {
          keys.push(key);
        }

        if (bucket.findIndex((x) => x.month == month) == -1) {
          myDataset[index].push(0);
        }

        bucket.forEach((item) => {
          if (item.month == month) {
            myDataset[index].push(item.doc_count);
          }
        });
      });
    });

    keys.forEach((key, index) => {
      let category = this.dataService.mappings['topics_id']['values'][key];
      if (typeof category != 'undefined') {
        keys[index] = category;
      }
    });

    myDataset = this.transpose(myDataset);
    myDataset.forEach((row, index) => myDataset[index].unshift(keys[index]));
    myDataset = mySource.concat(myDataset);
    myDataset.forEach((row, index) => {
      //
      if (typeof row[0] != 'string') {
        myDataset.splice(index, 1);
      }
    });
    myDataset.forEach((row, index) => {
      if (typeof row[0] != 'string') {
        myDataset.splice(index, 1);
      }
    });
    myDataset.forEach((row, index) => {
      if (typeof row[0] != 'string') {
        myDataset.splice(index, 1);
      }
    });

    let dataset: Object = { source: myDataset };

    return dataset;
  }

  private transpose(matrix) {
    return Object.keys(matrix[0]).map((colNumber) => matrix.map((rowNumber) => rowNumber[colNumber]));
  }
}
