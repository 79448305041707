import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AppStateService } from '@app/state/app/app.service';
import { CalendarPPState } from './calendar-pp.state';
import { VigilanciaService } from '../../services/vigilancia.service';
import { DeleteAlert, GetAlerts, PostAlert, PutAlert, ShowFiltersMenu } from './calendar-pp.actions';
import { CalendarPPAlertsGetResponse, CalendarPPGetRequest } from '../../models/calendar-pp.state.model';

@Injectable({ providedIn: 'root' })
export class CalendarPPStateService {
  @Select(CalendarPPState.showFiltersMenu)
  public showFiltersMenu$: Observable<boolean>;

  @SelectSnapshot(CalendarPPState.showFiltersMenu)
  public showFiltersMenu: boolean;

  @Select(CalendarPPState.alerts)
  public alerts$: Observable<CalendarPPAlertsGetResponse>;

  @SelectSnapshot(CalendarPPState.alerts)
  public alerts: CalendarPPAlertsGetResponse;

  constructor(
    public appStateService: AppStateService,
    private _snackbarStateService: SnackbarStateService,
    private _vigilanciaService: VigilanciaService
  ) { }

  @Dispatch()
  public showFiltersMenuFn = (payload: boolean) => {
    return new ShowFiltersMenu(payload);
  };

  @Dispatch()
  public getAlerts = (request: CalendarPPGetRequest) => {
    return this._vigilanciaService.getAlerts(request).pipe(
      map((response) => {
        return new GetAlerts(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public postAlert = (request: CalendarPPGetRequest) => {
    return this._vigilanciaService.postAlert(request).pipe(
      map((response) => {
        return new PostAlert(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public deleteAlert = (request: number) => {
    return this._vigilanciaService.deleteAlert(request).pipe(
      map(() => {
        return new DeleteAlert(request);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public putAlert = (request: CalendarPPGetRequest, id: number) => {
    return this._vigilanciaService.putAlert(request, id).pipe(
      map((response) => {
        return new PutAlert(response);
      }),
      catchError((error) => {
        this._snackbarStateService.show(
          'En estos momentos este servicio ha sufrido una incidencia, por favor inténtenlo de nuevo pasados unos minutos.',
          5000,
          'cerrar'
        );
        return throwError(error);
      })
    );
  };
}
