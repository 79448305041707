<mat-toolbar
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="32px"
  [ngClass]="{ opened: appStateService.sidemenuOpened$ | async }"
>
  <h1 *ngIf="appStateService.show_title$ | async">
    {{ appStateService.title$ | async }}
  </h1>
  <div fxFlex="1 1 auto"></div>
  <button mat-flat-button color="primary" (click)="viewCredits()">Ver mis créditos</button>
</mat-toolbar>
<div class="container">
  <span [ngSwitch]="appStateService.uiStatus$ | async">
    <div *ngSwitchCase="uiStatus.loaded" fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="12px grid">
      <div *ngFor="let item of magazineService.magazines$ | async">
        <app-thumb
          [magazine]="item"
          (settings)="editMagazine($event)"
          (view)="editDraft($event)"
          (newDraft)="newDraft($event)"
          (showStats)="showStats($event)"
        ></app-thumb>
      </div>
      <p class="footer-text">
        Para la elaboración de revistas de prensa Brainy Inteligencia Semántica ha suscrito el correspondiente contrato
        con CEDRO. Su posterior reproducción, distribución, transformación o comunicación pública en cualquier medio y
        de cualquier forma, requiere acuerdo con CEDRO.
      </p>
    </div>
    <div *ngSwitchCase="uiStatus.error" fxLayout="column" fxLayoutAlign="start center" class="no-doc">
      <!-- <img class="brainy-logo" src="{{ LOGOURL }}" alt="Logo" /> -->
      <h2>Lo sentimos, se ha producido un error.</h2>
    </div>
    <div *ngSwitchDefault fxLayout="column" fxLayoutAlign="start center" class="no-doc">
      <!-- <img class="brainy-logo" src="{{ LOGOURL }}" alt="Logo" /> -->
      <h2>No hay revistas disponibles.</h2>
    </div>
  </span>
  <button
    mat-fab
    color="primary"
    class="new-magazine"
    matTooltip="Nueva revista"
    (click)="newMagazine()"
    *ngIf="isAdmin"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
