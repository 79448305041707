<mat-sidenav-container
  class="example-container"
  [ngClass]="{ 'menu-filters-opened': calendarPPStateService.showFiltersMenu }"
>
  <mat-sidenav mode="side" position="end" *ngIf="calendarPPStateService.showFiltersMenu">
    <div class="header">
      <p>Filtros</p>
      <button mat-stroked-button color="primary" (click)="resetFilters()">Restablecer filtros</button>
    </div>
    <mat-selection-list #ministries style="margin-bottom: 120px" [multiple]="false">
      <ng-container [formGroup]="form">
        <mat-form-field appearance="standard" style="width: calc(100% - 32px); margin: 16px; margin-bottom: 0">
          <mat-label>Búsqueda</mat-label>
          <input matInput formControlName="search" />
        </mat-form-field>
        <div mat-subheader>Tipo de búsqueda</div>
        <div formArrayName="types" style="display: flex; flex-direction: column; padding: 0 16px">
          <mat-checkbox color="primary" *ngFor="let item of dropdownTypesList; let i = index" [formControlName]="i">
            <span [style]="'border-radius: 4px; padding: 2px 6px; font-weight: 500; background: ' + item.color + '99'">
              {{ item.itemName }}
            </span>
          </mat-checkbox>
        </div>
      </ng-container>
      <div mat-subheader style="display: flex; align-items: center; gap: 6px; padding-bottom: 6px">
        <mat-icon style="width: 20px; height: 20px; font-size: 20px">account_balance</mat-icon>
        <p style="margin: 0">Ministerio</p>
      </div>
      <angular2-multiselect
        fixSearchBug
        fixAccents
        id="am2-1"
        class="angular2-multiselect-search angular2-multiselect-auto-width"
        name="ministries"
        [data]="dropdownMinistriesList"
        [(ngModel)]="selectedMinistriesItems"
        [settings]="dropdownMinistriesSettings"
        *ngIf="dropdownMinistriesList.length > 0"
      >
      </angular2-multiselect>
      <div mat-subheader style="display: flex; align-items: center; gap: 6px; padding-bottom: 6px">
        <mat-icon style="width: 20px; height: 20px; font-size: 20px">public</mat-icon>
        <p style="margin: 0">Comunidad autónoma</p>
      </div>
      <angular2-multiselect
        fixSearchBug
        fixAccents
        id="am2-1"
        class="angular2-multiselect-search angular2-multiselect-auto-width"
        name="ccaa"
        [data]="dropdownCCAAList"
        [(ngModel)]="selectedCCAAItems"
        [settings]="dropdownCCAASettings"
        *ngIf="dropdownCCAAList.length > 0"
      >
      </angular2-multiselect>
      <div
        style="
          margin-left: 10px;
          position: fixed;
          bottom: 0;
          background: white;
          padding: 0 6px;
          font-size: 15px;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          padding-bottom: 16px;
          width: calc(300px - 20px);
        "
      >
        <button
          mat-flat-button
          color="primary"
          style="width: 100%"
          (click)="appStateService.updateUIStatus(uiStatus.loading); search()"
        >
          Aplicar filtros
        </button>
        <button
          mat-stroked-button
          color="primary"
          style="width: 100%"
          (click)="calendarPPStateService.showFiltersMenuFn(false)"
        >
          <mat-icon style="font-size: 20px; width: 20px; height: 20px">keyboard_double_arrow_right</mat-icon>
          Ocultar menú de filtros
        </button>
      </div>
    </mat-selection-list>
  </mat-sidenav>
  <button
    mat-mini-fab
    matTooltip="Expandir menú de filtros"
    color="primary"
    *ngIf="!calendarPPStateService.showFiltersMenu"
    (click)="calendarPPStateService.showFiltersMenuFn(true)"
    style="position: fixed; z-index: 999; bottom: 6px; right: 6px"
  >
    <mat-icon>keyboard_double_arrow_left</mat-icon>
  </button>
  <div>
    <div class="main-header">
      <p style="font-size: 24px; font-weight: 600; line-height: 100%; color: #1f2937; flex: 1; min-width: 300px">
        {{ subItemName }}
      </p>
      <button
        id="vigilancia-calendar-pp-alert-btn"
        mat-flat-button
        color="primary"
        class="alert-btn"
        (click)="createAlert()"
      >
        <mat-icon>star</mat-icon>
        <p *ngIf="!withFiltersFromAlerts">Crear búsqueda y alerta</p>
        <p *ngIf="withFiltersFromAlerts">Editar búsqueda y alerta</p>
      </button>
    </div>
    <table mat-table [dataSource]="itemsDataSource" class="initiatives">
      <ng-container matColumnDef="presented">
        <th mat-header-cell *matHeaderCellDef>Fin de plazo</th>
        <td mat-cell *matCellDef="let row">{{ row.plazo | date }}</td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Título</th>
        <td mat-cell *matCellDef="let row" style="font-weight: 500">{{ row.titulo }}</td>
      </ng-container>
      <ng-container matColumnDef="origin">
        <th mat-header-cell *matHeaderCellDef>Origen</th>
        <td mat-cell *matCellDef="let row">
          <div style="display: flex; gap: 6px; align-items: center">
            <mat-icon style="width: 20px; height: 20px; font-size: 20px; color: #0000008a">
              <ng-container *ngIf="row.tipo_origen === 'ministries'">account_balance</ng-container>
              <ng-container *ngIf="row.tipo_origen === 'ccaa'">public</ng-container>
            </mat-icon>
            <p style="margin: 0">{{ row.origen }}</p>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let row">
          <span
            [style]="'border-radius: 4px; padding: 2px 6px; font-weight: 500; background: ' + getColor(row.tipo) + '99'"
          >
            {{ row.tipo }}
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        style="cursor: pointer"
        (click)="openUrlInNewWindow(row.enlace_original)"
      ></tr>
    </table>
  </div>
  <mat-paginator
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="pageEvent($event)"
    style="position: sticky; bottom: 0"
  ></mat-paginator>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#60869b" type="ball-clip-rotate">
    <p style="font-size: 20px; color: #60869b">Cargando...</p>
  </ngx-spinner>
</mat-sidenav-container>
