import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError, forkJoin } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import {
  AddSelectedDocument,
  GetDocuments,
  ToggleInteresting,
  UnsetDocuments,
  UpdateTabDocuments,
  AddDocumentsToCategory,
} from './document.actions';
import { BrainyDoc } from '@app/models/Brainy.document';
import { DocumentPayload, Search } from '../models/document.state.model';
import { SearchService } from '@app/services/search.service';
import { DocumentState } from './documents.state';
import { AppStateService } from '@app/state/app/app.service';
import { UIStatus } from '@app/state/models/app.state.model';

@Injectable({ providedIn: 'root' })
export class DocumentStateService {
  @Select(DocumentState.searchs)
  public searchs$: Observable<Search[]>;

  @Select(DocumentState.interestingSearch)
  public interesting$: Observable<Search[]>;

  @SelectSnapshot(DocumentState.document)
  public document: BrainyDoc;

  @SelectSnapshot(DocumentState.filteredDocs)
  public filteredDocs: BrainyDoc;

  @Select(DocumentState.document)
  public document$: Observable<BrainyDoc>;

  constructor(
    private _spinner: NgxSpinnerService,
    private _searchService: SearchService,
    private _snackbarStateService: SnackbarStateService,
    private _appStateService: AppStateService
  ) {}

  @Dispatch()
  public getDocuments = (payload: { title: string; request: DocumentPayload; hideLoadingWhenFinish: boolean }[]) => {
    let documentsStatePayload: Search[] = [];
    this._appStateService.updateUIStatus(UIStatus.loading);
    return forkJoin(
      payload.map((item) => {
        return this._searchService.getDocuments(item.request).pipe(
          map((response, index) => {
            let payload: Search = {
              title: item.title,
              documents: response.hits.map((hit) => {
                hit.source.interesting = true;
                return hit.source;
              }),
            };

            documentsStatePayload.push(payload);
          }),
          catchError(() => {
            let error = 'Servidor en mantenimiento, inténtelo más tarde';
            this._snackbarStateService.show(error, 5000, 'cerrar');
            this._appStateService.updateUIStatus(UIStatus.loading);
            return throwError(error);
          })
        );
      })
    ).pipe(
      map(() => {
        return new GetDocuments(documentsStatePayload);
      }),
      finalize(() => {
        if (payload[0].hideLoadingWhenFinish) {
          this._appStateService.updateUIStatus(UIStatus.loaded);
        }
      })
    );
  };

  public getDocumentsNoState = (request: DocumentPayload) => {
    return this._searchService.getDocuments(request).pipe(
      map((response) => response.hits),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        this._appStateService.updateUIStatus(UIStatus.loading);
        return throwError(error);
      }),
      finalize(() => {
        this._appStateService.updateUIStatus(UIStatus.loaded);
      })
    );
  };

  @Dispatch()
  public addDocumentsToCategory = (payload: { title: string; request: DocumentPayload }) => {
    this._appStateService.updateUIStatus(UIStatus.loading);
    return this._searchService.getDocuments(payload.request).pipe(
      map((response: any) => {
        response.title = payload.title;
        let search: Search = {
          title: payload.title,
          documents: response.hits.map((hit) => {
            hit.source.interesting = true;
            return hit.source;
          }),
        };

        return new AddDocumentsToCategory(search);
      }),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        this._appStateService.updateUIStatus(UIStatus.error);
        return throwError(error);
      }),
      finalize(() => {
        this._appStateService.updateUIStatus(UIStatus.loaded);
      })
    );
  };

  @Dispatch()
  public updateTabDocuments = (payload: any) => {
    return new UpdateTabDocuments(payload);
  };

  @Dispatch()
  public getDocument = (docId: string) => {
    this._appStateService.updateUIStatus(UIStatus.loading);
    return this._searchService.getDocument(docId).pipe(
      map((response) => new AddSelectedDocument(response)),
      catchError(() => {
        this._appStateService.updateUIStatus(UIStatus.loading);
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      }),
      finalize(() => {
        this._appStateService.updateUIStatus(UIStatus.loaded);
      })
    );
  };

  @Dispatch()
  public orderDocuments = (payload: Search[]) => {
    this._spinner.show();
    return new GetDocuments(payload);
  };

  @Dispatch()
  public unsetDocuments = () => {
    return new UnsetDocuments();
  };

  @Dispatch()
  public toggleInteresting = (docId: string) => {
    return new ToggleInteresting(docId);
  };

  @Dispatch()
  public setSelectedDocument = (payload: BrainyDoc) => new AddSelectedDocument(payload);
}
