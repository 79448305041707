import { Component, OnInit, Input } from '@angular/core';

export interface BrainyLinkPreview {
  title: string;
  description: string;
  image: string;
  url: string;
  date: string;
  preview: object;
  abstract: string;
}

@Component({
  selector: 'app-contentcard',
  templateUrl: './contentcard.component.html',
  styleUrls: ['./contentcard.component.css'],
})
export class ContentCardComponent implements OnInit {
  @Input() cardInfo: BrainyLinkPreview;
  @Input() color = 'primary'; // accent | warn

  constructor() {}

  ngOnInit() {}
}
