//import { XMLHttpRequest } from 'xmlhttprequest';

class ObjectInstance {
  static create(Constructor, ...args: any[]) {
    var Temp = function () {},
      inst;

    Temp.prototype = Constructor.prototype;

    inst = new Temp();

    if (Constructor.constructor) Constructor.apply(inst, args);
    else Constructor.prototype.apply(inst, args);
    return inst;
  }
}

class MSIterator implements IterableIterator<any> {
  private result: any[];
  private length: number;
  private index: number;
  private classConstructor;

  constructor(result: any, classConstructor) {
    this.result = result || [];
    this.length = result.length;
    this.index = 0;
    this.classConstructor = classConstructor;
  }

  public next(): IteratorResult<any> {
    if (this.index < this.length) {
      return {
        done: false,
        value: ObjectInstance.create(this.classConstructor, this.result[this.index++]),
      };
    } else {
      this.index = 0;
      return {
        done: true,
        value: undefined,
      };
    }
  }

  public forEach(callback) {
    this.index = -1;
    let ret = true;
    while (++this.index < this.length && ret !== false)
      ret = callback(ObjectInstance.create(this.classConstructor, this.result[this.index]), this.index);
    this.index = 0;
  }

  [Symbol.iterator](): IterableIterator<any> {
    return this;
  }
}

export class ModelService {
  private getUri: string;
  private saveUri: string;
  private deleteUri: string;
  private httpClient;
  private httpOptions;
  private classConstructor;

  constructor(args) {
    this.getUri = args.getUri;
    this.saveUri = args.saveUri || args.getUri;
    this.classConstructor = args.classConstructor;
    this.httpClient = args.httpClient;
    this.httpOptions = args.httpOptions;
  }

  static prepareData(obj) {
    let out: string[] = [];
    let _encode = function (params, name = undefined) {
      let is_array = params && params.constructor.toString().match(/Array/),
        is_object = params && params.constructor.toString().match(/Object/),
        is_string = typeof params == 'string',
        is_number = typeof params == 'number';
      if (!is_array && !is_object) {
        return name + '=' + (params == undefined || params == null ? '' : params);
      } else if (is_object) {
        Object.getOwnPropertyNames(params).forEach(function (key, idx, array) {
          if (typeof params[key] != 'function') {
            if (!name) {
              let ret = _encode(params[key], key);
              if (ret != undefined && ret != null) out.push(ret);
            } else {
              let ret = _encode(params[key], name + '[' + key + ']');
              if (ret != undefined && ret != null) out.push(ret);
            }
          }
        });
      } else if (is_array) {
        let length = params.length;
        while (--length >= 0) {
          let ret = _encode(params[length], name + '[' + length + ']');
          if (ret != undefined && ret != null) out.push(ret);
        }
      }
    };
    _encode(obj);
    return out.join('&');
  }

  get(filter?: any) {
    let self = this;
    return new Promise(function (resolve, reject) {
      if (!self.httpClient) {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            let result = JSON.parse(this.responseText),
              is_array = result.constructor.toString().match(/Array/),
              is_object = result.constructor.toString().match(/Object/);
            // Assuming json response from server
            if (is_array) resolve(new MSIterator(result, self.classConstructor));
            else if (is_object) resolve(Model.model(self.classConstructor, result));
          } else if (this.readyState == 4 && this.status != 200)
            reject(Error('ERROR - [' + this.status + ']: ' + this.responseText));
        };
        let uri = filter ? self.getUri + ModelService.prepareData(filter) : self.getUri;
        xhttp.open('GET', uri, true);
        xhttp.send();
      } else {
        self.httpClient.get(self.getUri, self.httpOptions).subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  }

  post(obj) {
    return this.save(obj);
  }

  save(obj) {
    let self = this;
    return new Promise(function (resolve, reject) {
      if (!self.httpClient) {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            // Assuming json response from server
            resolve(JSON.stringify(this.responseText));
          } else if (this.readyState == 4 && this.status != 200)
            reject(Error('ERROR - [' + this.status + ']:  ' + this.responseText));
        };
        // Todo, let service to post files
        xhttp.open('POST', self.saveUri, true);
        xhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhttp.send(ModelService.prepareData(obj));
      } else {
        self.httpClient.post(self.saveUri, self.httpOptions).subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  }
}

export abstract class Model {
  static copy(SourceObject, targetObject) {
    //Object.keys(SourceObject).forEach(function(key){
    targetObject = Object.assign(targetObject, SourceObject);
    //});
    return targetObject;
    //        Model.defineGettersAndSetters(SourceObject,targetObject);
  }

  /*  static defineGettersAndSetters(SourceObject, targetObject) {

        Object.keys(SourceObject).forEach(function(key) {
            Object.defineProperty(targetObject.prototype, key, {
                get: function() {
                    return SourceObject[key]
                },
                set: function(val) {
                    SourceObject[key] = val;
                },
                enumerable: true,
                configurable: true
            })
            });

    }*/

  static model(classConstructor, args) {
    args = args || {};
    return ObjectInstance.create(classConstructor, args);
  }

  private _attributes = {};
  protected pkField: any = null;

  constructor(attr = null) {
    this._attributes = attr || {};
    Model.copy(this._attributes, this);
  }

  get attributes() {
    return this._attributes;
  }

  set attributes(attr) {
    this._attributes = attr;
    Model.copy(this._attributes, this);
  }

  className() {
    var funcNameRegex = /function (.{1,})\(/;
    var results = funcNameRegex.exec((<any>this).constructor.toString());
    return results && results.length > 1 ? results[1] : '';
  }

  /**
   * This method should be overloaded.
   */
  service(): any {
    return {
      get: function (filter) {
        return null;
      },
      delete: function (obj) {
        return false;
      },
      save: function (obj) {
        return false;
      },
    };
  }

  getAll(filter: any) {
    return this.service().get(filter);
  }

  getByPk(pk: any) {
    let filter = {};
    filter[this.pkField] = pk;
    return this.service().get(filter);
  }

  save() {
    return this.service().save(this);
  }

  serialize() {
    let copy = Model.copy(this.attributes, {});
    return JSON.stringify(copy);
  }

  attr(key, value) {
    if (!value) return this._attributes[key];
    else {
      //check whether value is array or object or string or number
      if (this.attributes) {
        let attr = this.attributes;
        attr[key] = {};
        attr[key] = Model.copy(attr[key], value);
        this.attributes = attr;
        return this;
      } else {
        return this._attributes[key];
      }
    }
  }
  save_locally(key) {
    localStorage.setItem(key, this.serialize());
    return this;
  }

  load_locally(key) {
    this.attributes = JSON.parse(localStorage.getItem(key));
    return this;
  }

  remove_locally(key) {
    localStorage.removeItem(key);
    return this;
  }

  eq(_obj) {
    var equal = function (obj1, obj2) {
      let eq = true;
      Object.keys(obj1).forEach((key) => {
        if (obj1[key] && obj1[key].constructor) {
          if (!obj2[key].constructor) eq = false;
          else if (obj1[key].constructor !== obj2[key].constructor) eq = false;
          else if (obj1[key].constructor.toString().match(/Object/)) eq = eq && equal(obj1[key], obj2[key]);
          else if (obj1[key].constructor.toString().match(/Array/)) {
            if (obj1[key].length != obj2[key].length) return false;
            else {
              let length = obj1[key].length;

              while (--length) eq = eq && equal(obj1[key][length], obj2[key][length]);

              if (!eq) return eq;
            }
          }
        } else if (obj1[key] !== obj2[key]) eq = false;
        if (!eq) return false;
      });
      return eq;
    };

    return equal(this, _obj);
  }
}
