<div class="container">
  <div class="header">
    <p class="title">Buscador de licitaciones</p>
    <button mat-flat-button (click)="saveSearch()">
      <mat-icon>star</mat-icon>
      <p *ngIf="!withFiltersFromAlerts">Crear búsqueda y alerta</p>
      <p *ngIf="withFiltersFromAlerts">Editar búsqueda y alerta</p>
    </button>
  </div>
  <table mat-table [dataSource]="(tendersStateService.tenders$ | async).items" *ngIf="!loading">
    <ng-container matColumnDef="card">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <a class="container-card" (click)="openDetail(row.id)">
          <div class="header">
            <p>{{ row.objeto }}</p>
            <button mat-icon-button (click)="saveItem($event, row)" [ngClass]="{ favorite: row.favorito }">
              <mat-icon>folder_special</mat-icon>
            </button>
          </div>
          <div class="content">
            <div class="sections">
              <div class="section">
                <mat-icon>description</mat-icon>
                <div class="content">
                  <p class="name">Expediente</p>
                  <p class="value">{{ row.expediente }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>event</mat-icon>
                <div class="content">
                  <p class="name">Actualizado el</p>
                  <p class="value">{{ row.fecha_publicacion | date }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>schedule</mat-icon>
                <div class="content">
                  <p class="name">Plazo</p>
                  <p class="value" *ngIf="row.plazo">{{ row.plazo | date }}</p>
                  <p class="value" *ngIf="!row.plazo">n/d</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>euro</mat-icon>
                <div class="content">
                  <p class="name">Presupuesto</p>
                  <p class="value">{{ row.presupuesto | number }} €</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>euro</mat-icon>
                <div class="content">
                  <p class="name">Valor estimado</p>
                  <p class="value">{{ row.valor | number }} €</p>
                </div>
              </div>
            </div>
            <a (click)="openDetail(row.id)">Más información</a>
          </div>
        </a>
      </td>
    </ng-container>
    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr> -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <th mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <!-- <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
      <p style="margin: 0" *ngIf="loading">Cargando datos...</p> -->
      <!-- <p style="margin: 0" *ngIf="!loading">No se han encontrado datos</p> -->
    </th>
  </table>
  <div style="display: flex; align-items: center; gap: 12px; padding: 16px">
    <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
    <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
  </div>

  <mat-paginator
    [pageIndex]="pageIndex"
    pageSize="30"
    [ngStyle]="{ opacity: !loading && (tendersStateService.tenders$ | async).total > 0 ? 1 : 0 }"
    style="position: sticky; bottom: 0"
  >
    <p>Página {{ pageIndex + 1 }}</p>
  </mat-paginator>

  <div class="no-data" *ngIf="!loading && (tendersStateService.tenders$ | async).total === 0">
    <img src="assets/imgs/404-illustration-no-data.svg" />
    <p class="title">Sin datos</p>
    <p class="description">
      No se han encontrado resultados con los filtros actuales. Prueba a realizar otra búsqueda.
    </p>
  </div>
</div>
