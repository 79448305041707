import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[fixAccents]',
})
export class MultiselectSearchAccentsDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('input')
  onInput() {
    const inputElement = this.elementRef.nativeElement.querySelector('.list-filter .c-input');

    if (inputElement) {
      inputElement.value.replace(/`([aeiou])/g, (match: boolean, p1: string) => {
        inputElement.value = inputElement.value.slice(0, -2) + this.getAccentedChar(p1);
      });
    }
  }

  getAccentedChar(char: string): string {
    switch (char) {
      case 'a':
        return 'á';
      case 'e':
        return 'é';
      case 'i':
        return 'í';
      case 'o':
        return 'ó';
      case 'u':
        return 'ú';
      default:
        return '';
    }
  }
}
