import {
  PalliativeCaresFilters,
  PalliativeCaresFiltersBackend,
  PalliativeCaresGetResponse,
} from "../models/regulatory";

export class GetPalliativeCares {
  static readonly type = "[GetPalliativeCares] action";
  constructor(public payload: PalliativeCaresGetResponse) {}
}

export class UpdatePalliativeCaresFilters {
  static readonly type = "[UpdatePalliativeCaresFilters] action";
  constructor(public payload: PalliativeCaresFilters) {}
}

export class GetPalliativeCaresFiltersBackend {
  static readonly type = "[GetPalliativeCaresFiltersBackend] action";
  constructor(public payload: PalliativeCaresFiltersBackend) {}
}

export class TogglePalliativeCaresMenuFilters {
  static readonly type = "[TogglePalliativeCaresMenuFilters] action";
}
