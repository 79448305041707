import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class CustomValidators {
  maxValues(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.value.length > number) {
        return { maxValues: true };
      }

      return null;
    };
  }

  minAlphanumeric(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const alphanumericCount = (control.value || '').replace(/[^a-zA-Z0-9]/g, '').length;

      if (alphanumericCount < min) {
        return { minAlphanumeric: { required: min, actual: alphanumericCount } };
      }

      return null;
    };
  }
}
