<div class="example-loading-shade" *ngIf="isLoadingDoc || isLoadingTopics || isRateLimitReached">
  <mat-spinner class="spinner-center" *ngIf="isLoadingResults"></mat-spinner>
  <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
    <h2>Hemos encontrado un problema con las comunicaciones, inténtelo de nuevo más tarde.</h2>
  </div>
</div>
<div style="height: 100%" *ngIf="!isLoadingDoc && !isLoadingTopics && !isRateLimitReached">
  <div class="form-group pull-right contextual form-inline">
    <input
      type="text"
      autocomplete="off"
      class="form-control"
      id="isearch"
      placeholder="Búsqueda en documento"
      name="search_text"
      [(ngModel)]="text_search"
      (keyup.enter)="textsearch(text_search)"
    />
    <i class="fa fa-search" (click)="textsearch(text_search)"></i>
  </div>
  <mat-sidenav-container style="height: auto !important; display: contents">
    <mat-sidenav
      mode="side"
      #drawer
      style="height: 100vh"
      [opened]="
        (authStateService.userConfig.interface_config.categories === 'legacy' && doc.categories?.length > 0) ||
        (authStateService.userConfig.interface_config.categories === 'eurovoc' && doc.taxonomies?.length > 0)
      "
    >
      <doc-categories
        *ngIf="
          (authStateService.userConfig.interface_config.categories === 'legacy' && doc.categories?.length > 0) ||
          (authStateService.userConfig.interface_config.categories === 'eurovoc' && doc.taxonomies?.length > 0)
        "
        [docID]="doc.docId"
        [categorias]="
          authStateService.userConfig.interface_config.categories === 'legacy'
            ? doc.categories
            : authStateService.userConfig.interface_config.categories === 'eurovoc'
            ? doc.taxonomies
            : []
        "
        [topics]="topics"
        (querytext)="textsearch($event)"
        (searchterms)="setterms($event)"
        (gotopage)="goToPage($event)"
      ></doc-categories>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="{ opened: drawer.opened }">
      <div fxLayout="row">
        <div fxFlex="1 0 85%">
          <div fxLayout="row">
            <h2 class="doc-title" fxFlex="1 0 93%">{{ doc.titleParsed | ellipsis: 450 }}</h2>
            <!-- <span fxFlex="1 1 auto"></span>
            <div fxFlex="1 0 auto">
              <button mat-icon-button color="primary">
                <mat-icon *ngIf="!doc._favorite" (click)="addfav()" class="futurofavorito">star_border</mat-icon>
                <mat-icon *ngIf="doc._favorite" (click)="delfav()" class="favorito">star</mat-icon>
              </button>
            </div> -->
          </div>
          <div fxLayout="row">
            <div class="fuente" fxFlex="1 0 auto">
              {{ doc.sourceName }}
            </div>
            <span fxFlex="1 1 100%"></span>
            <div class="regionhora" fxFlex="1 0 auto">{{ region }} {{ doc.dateParsed | date: medium }}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="doc-body">
            <doc-body
              #docbody
              [doc]="doc"
              [querytext]="querytext"
              [searchterms]="searchterms"
              [lookfortext]="lookfortext"
              [page]="page"
            ></doc-body>
          </div>
        </div>
        <div fxFlex="1 0 auto">
          <doc-botonera
            [docID]="doc.docId"
            [docUrl]="doc.url"
            [docTitleParsed]="doc.titleParsed"
            [docFavorite]="doc._favorite"
            [docprint]="docprint"
            class="botonera"
          ></doc-botonera
          ><ng-content></ng-content>
        </div>
      </div>
      <button
        *ngIf="
          drawer.opened &&
          ((authStateService.userConfig.interface_config.categories === 'legacy' && doc.categories?.length > 0) ||
            (authStateService.userConfig.interface_config.categories === 'eurovoc' && doc.taxonomies?.length > 0))
        "
        class="mat-bottom-left"
        color="primary"
        (click)="drawer.toggle()"
        matTooltip="Ocultar Categorías"
      >
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <button
        *ngIf="
          !drawer.opened &&
          ((authStateService.userConfig.interface_config.categories === 'legacy' && doc.categories?.length > 0) ||
            (authStateService.userConfig.interface_config.categories === 'eurovoc' && doc.taxonomies?.length > 0))
        "
        class="mat-bottom-left"
        color="primary"
        (click)="drawer.toggle()"
        matTooltip="Mostrar Categorías"
      >
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<ng-content></ng-content>
