<div class="container">
  <div class="example-table-container">
    <div class="header" *ngIf="item">
      <h3>
        {{ item.title }} <span *ngIf="data.length > 0">- {{ data.length }} Plantillas</span>
      </h3>
      <button
        mat-flat-button
        style="float: right"
        color="primary"
        class="btn-same-color btn-same-color-primary"
        (click)="goToSectorReport('create')"
      >
        Crear informe
      </button>
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="data" class="example-table">
        <ng-container matColumnDef="card">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="card">
              <div class="data">
                <div class="container-header">
                  <div class="header">
                    <p>{{ row.title }}</p>
                    <p>{{ row.created_at }}</p>
                  </div>
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Opciones">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="deleteTemplate(row)">
                      <mat-icon>delete</mat-icon>
                      <span>Eliminar plantilla</span>
                    </button>
                  </mat-menu>
                </div>
                <div class="content">
                  <p class="item" *ngFor="let section of row.sections">• {{ section.title }}</p>
                </div>
              </div>
              <button
                mat-flat-button
                color="primary"
                class="btn-same-color btn-same-color-primary"
                (click)="viewSectorReports(row.id)"
              >
                <mat-icon>article</mat-icon>
                Ver informes ({{ row.n_report }})
              </button>
              <button
                mat-flat-button
                color="primary"
                class="btn-same-color btn-same-color-primary"
                (click)="goToSectorReport(row.id)"
              >
                <mat-icon>auto_awesome</mat-icon>
                Ver plantilla
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background: #ededed"></tr>
        <th
          scope="col"
          mat-row
          *matNoDataRow
          class="no-data-row"
          style="display: flex; align-items: center; gap: 12px; padding: 16px"
        >
          <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
          <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
          <p style="margin: 0" *ngIf="!loading && data.length === 0">No hay datos</p>
        </th>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="row-table"></tr>
      </table>
      <mat-paginator
        [pageIndex]="pageIndex"
        [pageSize]="size"
        (page)="pageEvent($event)"
        fixed
        [ngStyle]="{ opacity: total > 0 ? 1 : 0 }"
      ></mat-paginator>
    </div>
  </div>
</div>
