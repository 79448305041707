<div class="custom-snack" fxLayout="row" fxLayoutAlign="space-between">
  <div class="">
    {{ data.message }}<br />
    {{ data.items | i18nPlural: clippingMapping }}
  </div>
  <div class="">
    <button mat-button (click)="doAction()">Aceptar</button>
    <button mat-icon-button (click)="cancel()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
