import { Component, OnInit } from '@angular/core';
import { InitiativeStateService } from '@app/modules/documents/store/initiative/initiative.service';
import { Initiative, PastProcedure } from '@app/modules/documents/store/models/initiative.state.model';
import { Observable, Subscription, throwError } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import _moment from 'moment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { finalize, catchError } from 'rxjs/operators';
import { URL } from '@env/environment';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';

@Component({
  selector: 'app-initiative-detail',
  templateUrl: './initiative-detail.component.html',
  styleUrls: ['./initiative-detail.component.scss'],
})
export class InitiativeDetailComponent implements OnInit {
  selectedInitiative$: Observable<Initiative>;
  subscription: Subscription;
  initiative: Initiative;
  deadLineFormGroup: FormGroup;
  documentFormGroup: FormGroup;
  fileName: string;
  formData = new FormData();
  uploadProgress: number;
  uploadSub: Subscription;

  constructor(
    public initiativeStateService: InitiativeStateService,
    private http: HttpClient,
    private _snackbarStateService: SnackbarStateService
  ) {}

  ngOnInit(): void {
    this.subscription = this.initiativeStateService.selectedInitiative$.subscribe((initiative) => {
      this.initiative = JSON.parse(JSON.stringify(initiative));
    });

    this.deadLineFormGroup = new FormGroup({
      timestamp: new FormControl('', [Validators.required]),
      label: new FormControl('', [Validators.required]),
    });

    this.documentFormGroup = new FormGroup({
      date: new FormControl(new Date()),
      pag: new FormControl(''),
      link: new FormControl('', [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    // this.initiativeStateService.getInitiatives();
    this.subscription.unsubscribe();
  }

  newDeadline() {
    if (this.deadLineFormGroup.invalid) {
      return;
    }

    let deadline = {
      date: _moment(this.deadLineFormGroup.get('timestamp').value).format('YYYY-MM-DD'),
      name: this.deadLineFormGroup.get('label').value,
      id: this.initiative.id,
    };

    this.initiative.deadlines.push({
      timestamp: this.deadLineFormGroup.get('timestamp').value,
      label: this.deadLineFormGroup.get('label').value,
    });

    let past_procedure = {
      comision: 'Nuevo vencimiento: ' + this.deadLineFormGroup.get('label').value,
      status: '',
      since: _moment(this.deadLineFormGroup.get('timestamp').value).format('YYYY-MM-DD'),
      to: undefined,
      id: this.initiative.id,
    };

    this.initiative.past_procedures.push({
      comision: 'Nuevo vencimiento: ' + this.deadLineFormGroup.get('label').value,
      status: '',
      from: new Date(this.deadLineFormGroup.get('timestamp').value),
      to: undefined,
    });

    this.deadLineFormGroup.get('timestamp').setValue('');
    this.deadLineFormGroup.get('label').setValue('');

    this.initiativeStateService.addDeadLine(deadline);
    this.initiativeStateService.addPastProcedure(past_procedure);
  }

  newDocument() {
    const upload$ = this.http
      .post(URL + 'congreso/iniciativas/' + this.initiative.id, this.formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        finalize(() => this.reset()),
        catchError((errorResponse) => {
          let errorMsg = errorResponse.error;
          this._snackbarStateService.show(errorMsg, 5000, 'cerrar');
          return throwError(errorResponse);
        })
      );

    this.uploadSub = upload$.subscribe((event) => {
      if (event.type == HttpEventType.UploadProgress) {
        this.uploadProgress = Math.round(100 * (event.loaded / event.total));
      }
    });

    // this.initiative.documents.push({
    //   date: this.documentFormGroup.get('date').value,
    //   label: this.documentFormGroup.get('label').value,
    //   pag: this.documentFormGroup.get('pag').value,
    //   link: this.documentFormGroup.get('link').value
    // });
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
    let past_procedure = {
      comision: 'Documento incorporado ' + this.fileName,
      status: '',
      since: _moment().format('YYYY-MM-DD'),
      to: undefined,
      id: this.initiative.id,
    };

    this.initiative.past_procedures.push({
      comision: 'Documento incorporado ' + this.fileName,
      status: '',
      from: undefined,
      to: new Date(),
    });
    this.initiativeStateService.addPastProcedure(past_procedure);
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      this.formData.append('title', file.name);
      this.formData.append('file', file);
    }
  }
}
