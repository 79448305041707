import { tokenName } from '@angular/compiler';
import { Component, Inject, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { AppDataService } from '@app/services/app-data.service';
import { AppStatusService } from '@app/services/app-status.service';
import { AppStateService } from '@app/state/app/app.service';

@Component({
  selector: 'doc-categories',
  templateUrl: './doccategories.component.html',
  styleUrls: ['./doccategories.component.css'],
})
export class DocCategoriesComponent implements OnInit {
  categories: any = [];
  terminos: any = [];
  context: any = undefined;
  isLoadingCats = true;
  cats = false;

  @Input() docID;
  @Input() categorias;
  @Input() topics;
  @Output() querytext: EventEmitter<string> = new EventEmitter();
  @Output() searchterms: EventEmitter<string[]> = new EventEmitter();
  @Output() gotopage: EventEmitter<number> = new EventEmitter();
  constructor(
    private appStatus: AppStatusService,
    private sAppData: AppDataService,
    private appStateService: AppStateService
  ) {}

  getContextPage(context) {
    let position = 1;
    if (context) {
      position = context.terms && context.terms.length > 0 ? JSON.parse(context.terms[0]).start : 1;
    }
    return position;
  }

  goToPage($event) {
    this.gotopage.emit($event);
  }

  ngOnInit() {
    this.context = this.categorias.find((elem) => elem.context);
    let exist = {},
      categ = this.categorias.filter((element) => {
        let name = this.sAppData.map('topics_id', element.id),
          ret = name !== element.id && !element.context,
          texist = {};
        if (ret) {
          element.name = name;
          if (!exist[name]) exist[name] = element;
          else {
            element.terms = element.terms.concat(exist[name].terms);
          }
          exist[name].terms = element.terms.filter(
            (term) => !texist[term.toLowerCase()] && (texist[term.toLowerCase()] = true)
          );
        }
        return ret;
      });

    if (categ.length > 0) {
      this.cats = true;
      categ.forEach((element) => {
        Object.values(element.terms).forEach((terms: string[] | null) => {
          if (this.terminos.indexOf(terms) === -1) {
            this.terminos.push(terms);
          }
        });
      });
      this.searchterms.emit(this.terminos);
      // this.categories = Object.values(exist);
    }
    // this.categories =
    //   this.appStateService.topic_mappings.filter((el) => el.topics.some(this.categorias?.map((c) => c.id))) || [];

    this.categorias.forEach((c) => {
      this.categories.push({ ...c, name: this.topics.find((t) => t.id === c.id)?.itemName });
    });

    this.categories = this.categories.filter((item, index) => {
      return this.categories.indexOf(item) === index && item.name;
    });

    this.isLoadingCats = false;
  }

  textsearch(query: string) {
    this.querytext.emit(query);
  }
}
