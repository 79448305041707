import { Layout } from '@app/models/User';

export class AddLayout {
  static readonly type = '[AddLayout] action';

  constructor(public layout: Layout) {}
}

export class ClearLayouts {
  static readonly type = '[ClearLayouts] action';

  constructor() {}
}
