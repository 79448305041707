<mat-sidenav-container class="example-container">
  <mat-sidenav opened mode="side" position="end" *ngIf="agendasStateService.menuFilters.opened">
    <!-- <div *ngIf="(initiativeStateService.uiStatus$ | async) === UIInitiativeView.calendar" class="toggle-view">
      <button mat-stroked-button matTooltip="Mostrar normas a seguir" (click)="showList()" color="primary">
        Mostrar listado
      </button>
    </div>
    <div *ngIf="(initiativeStateService.uiStatus$ | async) === UIInitiativeView.list" class="toggle-view">
      <button mat-stroked-button matTooltip="Abrir vista calendario" (click)="showCalendar()">
        Mostrar calendario
      </button>
    </div> -->
    <!-- <button mat-mini-fab matTooltip="Nueva norma manual" (click)="openManualDialog()" class="new-manual">
      <mat-icon>feed</mat-icon>
    </button>
    <button mat-mini-fab matTooltip="Seguir nueva norma" (click)="openDialog()" class="new-norm">
      <mat-icon>add</mat-icon>
    </button> -->
    <div class="header">
      <p>Filtros</p>
      <button mat-stroked-button color="primary" (click)="resetFilters()">Restablecer filtros</button>
    </div>
    <mat-selection-list #regions style="margin-bottom: 120px" [multiple]="false">
      <div mat-subheader class="mat-subheader">Selección de agenda</div>
      <!-- <mat-list-option
        *ngFor="let region of appStateService.region_mappings$ | async"
        [selected]="region.name == (appStateService.selected_regions$ | async)[0].name"
        (click)="selectRegion(region)"
      >
        {{ region.name }}
      </mat-list-option> -->
      <angular2-multiselect
        fixSearchBug
        fixAccents
        id="am2-1"
        class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width"
        name="spiderGroups"
        [data]="dropdownSpiderGroupsList"
        [(ngModel)]="selectedSpiderGroupsItems"
        [settings]="dropdownSpiderGroupsSettings"
        (onDeSelect)="updateSpiderGroupsItems()"
        (onDeSelectAll)="updateSpiderGroupsItems()"
        (onSelect)="updateSpiderGroupsItems($event)"
      >
        <div class="header">
          <p>Filtros</p>
          <a color="primary" (click)="resetFilters()">Restablecer filtros</a>
        </div>
      </angular2-multiselect>
      <angular2-multiselect
        fixSearchBug
        fixAccents
        id="am2-2"
        class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width"
        name="spiders"
        [data]="dropdownSpidersList"
        [(ngModel)]="selectedSpidersItems"
        [settings]="dropdownSpidersSettings"
        (onSelect)="updateSpidersItems()"
        (onSelectAll)="updateSpidersItems()"
        (onDeSelect)="updateSpidersItems()"
        (onDeSelectAll)="updateSpidersItems()"
      >
        <c-item>
          <ng-template let-item="item">
            <label style="padding: 4px 8px; border-radius: 4px" [style.background-color]="getSpiderColor(item.id)">
              {{ item.itemName }}
            </label>
          </ng-template>
        </c-item>
      </angular2-multiselect>
      <div mat-subheader class="mat-subheader">Fecha de búsqueda</div>
      <mat-calendar [selected]="selectedDateRange" (selectedChange)="_onSelectedDateChange($event)"></mat-calendar>
      <span
        *ngIf="selectedSpiderGroupsItems.length > 0 && selectedSpidersItems.length > 0 && agendasEventsExecutedQuery"
      >
        <div mat-subheader class="mat-subheader">Filtros</div>
      </span>
      <angular2-multiselect
        fixSearchBug
        fixAccents
        id="am2-3"
        class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width"
        name="tags"
        [data]="dropdownTagsList"
        [(ngModel)]="selectedTagsItems"
        [settings]="dropdownTagsSettings"
        (onSelect)="filterAgendasEventsByTag()"
        (onSelectAll)="filterAgendasEventsByTag()"
        (onDeSelect)="filterAgendasEventsByTag()"
        (onDeSelectAll)="filterAgendasEventsByTag()"
        *ngIf="selectedSpiderGroupsItems.length > 0 && selectedSpidersItems.length > 0 && agendasEventsExecutedQuery"
      >
      </angular2-multiselect>
      <div
        style="
          z-index: 9;
          position: fixed;
          bottom: 0;
          background: white;
          padding: 16px 6px;
          font-size: 15px;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-bottom: 16px;
          width: calc(300px - 43px);
        "
      >
        <button
          mat-flat-button
          class="search-entries"
          style="width: 100%"
          color="primary"
          [disabled]="selectedSpiderGroupsItems.length === 0 || selectedSpidersItems.length === 0"
          (click)="search()"
        >
          Buscar entradas
        </button>
        <!-- <p class="tutorial-text" *ngIf="selectedSpiderGroupsItems.length === 0">
        Selecciona una agenda y una o varias fuentes para filtrar las entradas
      </p> -->
        <!-- <p
        class="exceeded-events-text"
        *ngIf="agendasEventsExecutedQuery && events.length === 0"
      >
        No se ha encontrado ningún resultado con los filtros seleccionados
      </p> -->
        <!-- <div class="card-download-excel">
        <p class="title">Descargar entradas semanales</p>
        <p class="no-data" *ngIf="showNoDataExcel">
          No hay datos para la fecha seleccionada
        </p>
        <button
          mat-flat-button
          class="search-entries"
          color="primary"
          [disabled]="downloadingExcel"
          (click)="downloadExcel()"
        >
          <mat-spinner [diameter]="24" *ngIf="downloadingExcel"></mat-spinner>
          <mat-icon *ngIf="!downloadingExcel">download</mat-icon>
          Exportar a Excel
        </button>
      </div> -->
        <button
          mat-stroked-button
          color="primary"
          style="width: 100%; padding: 0 6px; font-size: 15px; font-weight: 500; margin-top: 16px"
          *ngIf="agendasStateService.menuFilters.opened"
          (click)="agendasStateService.toggleAgendasMenuFilters()"
        >
          <mat-icon style="font-size: 20px; width: 20px; height: 20px">keyboard_double_arrow_right</mat-icon>
          Ocultar menú de filtros
        </button>
      </div>
    </mat-selection-list>
  </mat-sidenav>
  <div class="start-no-data-selected" *ngIf="!agendasEventsExecutedQuery">
    <p class="title">{{ subItemName }}</p>
    <p class="description">Selecciona una agenda y una o varias fuentes para filtrar las entradas</p>
    <!-- <img src="assets/imgs/404-illustration-no-data.svg" /> -->
    <img src="assets/imgs/agendas-calendar.svg" />
  </div>
  <div class="calendar-header" [ngStyle]="{ opacity: agendasEventsExecutedQuery ? 1 : 0 }">
    <div class="header">
      <calendar-header [(view)]="view" [(viewDate)]="viewDate" [viewOptions]="['month']"> </calendar-header>
      <button
        mat-flat-button
        color="primary"
        [disabled]="downloadingPdf"
        (click)="downloadPdf()"
        *ngIf="events.length > 0"
      >
        <mat-spinner [diameter]="24" *ngIf="downloadingPdf"></mat-spinner>
        <mat-icon *ngIf="!downloadingPdf">download</mat-icon>
        Exportar a Pdf
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="downloadingExcel"
        (click)="downloadExcel()"
        *ngIf="events.length > 0"
      >
        <mat-spinner [diameter]="24" *ngIf="downloadingExcel"></mat-spinner>
        <mat-icon *ngIf="!downloadingExcel">download</mat-icon>
        Exportar a Excel
      </button>
    </div>
    <div
      class="calendar-month-view-container"
      [ngSwitch]="view"
      [ngStyle]="{ opacity: agendasEventsExecutedQuery ? 1 : 0 }"
    >
      <mwl-calendar-month-view
        *ngSwitchCase="'month'"
        [viewDate]="viewDate"
        [events]="events"
        [activeDayIsOpen]="activeDayIsOpen"
        [openDayEventsTemplate]="customOpenDayEventsTemplate"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="eventClicked($event)"
      >
      </mwl-calendar-month-view>

      <ng-template #customOpenDayEventsTemplate let-events="events" let-isOpen="isOpen">
        <div class="cal-open-day-events" *ngIf="isOpen">
          <div class="header">
            <p>Agenda {{ viewDate | date: 'longDate' }}</p>
            <div class="legend">
              <div class="legend-item" *ngFor="let legendItem of agendaEventslegend">
                <div class="color" [style]="'background: ' + (legendItem.color | slice: 0:7)"></div>
                <p>{{ legendItem.spider.name }}</p>
              </div>
            </div>
          </div>
          <div *ngFor="let event of events; let i = index" [ngClass]="event?.cssClass">
            <div
              class="separator"
              *ngIf="
                i === 0 ||
                (event.meta.datetime | date: 'HH:mm':'UTC') !== (events[i - 1].meta.datetime | date: 'HH:mm':'UTC')
              "
            ></div>
            <div class="event">
              <p
                class="time"
                [style.opacity]="(event.meta.datetime | date: 'HH:mm':'UTC') !== '00:00' ? 1 : 0"
                *ngIf="
                  i === 0 ||
                  (event.meta.datetime | date: 'HH:mm':'UTC') !== (events[i - 1].meta.datetime | date: 'HH:mm':'UTC')
                "
              >
                {{ event.meta.datetime | date: 'HH:mm':'UTC' }}
              </p>
              <div
                class="data"
                [style]="'border-left: 5px solid ' + (getSpiderColor(event.meta.spiderId) | slice: 0:7)"
              >
                <mwl-calendar-event-title [event]="event" view="month"></mwl-calendar-event-title>
                <div class="description">
                  <p class="tags">{{ event.meta.tags.join(', ') | uppercase }}</p>
                  <p class="location" *ngIf="event.meta.location">{{ event.meta.location }}</p>
                </div>
                <a
                  class="url"
                  *ngIf="event.meta.url"
                  [style]="'color: ' + (getSpiderColor(event.meta.spiderId) | slice: 0:7)"
                  [href]="event.meta.url"
                  target="_blank"
                >
                  Ver fuente original
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- <mwl-calendar-week-view
        *ngSwitchCase="'week'"
        [viewDate]="viewDate"
        [events]="events"
        (eventClicked)="eventClicked($event)"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        *ngSwitchCase="'day'"
        [viewDate]="viewDate"
        [events]="events"
        (eventClicked)="eventClicked($event)"
      >
      </mwl-calendar-day-view> -->

      <p style="margin: 24px; font-size: 15px" *ngIf="events.length === 0">
        No se han encontrado datos con los filtros actuales
      </p>
    </div>
  </div>
  <button
    mat-mini-fab
    matTooltip="Expandir menú de filtros"
    color="primary"
    *ngIf="!agendasStateService.menuFilters.opened"
    (click)="agendasStateService.toggleAgendasMenuFilters()"
    style="position: fixed; z-index: 999; bottom: 6px; right: 6px"
  >
    <mat-icon>keyboard_double_arrow_left</mat-icon>
  </button>
</mat-sidenav-container>
