export class ShowFiltersMenu {
  static readonly type = '[ShowFiltersMenu] action';
  constructor(public payload: boolean) { }
}

export class GetAlerts {
  static readonly type = '[GetAlerts] action';
  constructor(public payload: any) { }
}

export class DeleteAlert {
  static readonly type = '[DeleteAlert] action';
  constructor(public payload: number) { }
}

export class PostAlert {
  static readonly type = '[PostAlert] action';
  constructor(public payload: any) { }
}

export class PutAlert {
  static readonly type = '[PutAlert] action';
  constructor(public payload: any) { }
}