<!-- ul (click)="show_layers = !show_layers"
     [ngClass]="{'control':true,'expand':show_layers}" -->
<div class="inputGroup" *ngIf="layer.name" (change)="onChange(layer.uuid)">
  <input
    id="i{{ layer.uuid }}"
    [ngClass]="{
      loading: layer.status == 'loading'
    }"
    name="layer"
    type="radio"
    value="{{ layer.uuid }}"
  />
  <label for="i{{ layer.uuid }}">{{ layer.name }}</label>
</div>
<div class="inputGroup" *ngIf="layer.meta" (change)="onChangeStack(layer)">
  <input id="i{{ layer.uuid }}" name="layer" type="radio" value="{{ layer.uuid }}" />
  <label for="i{{ layer.uuid }}">{{ layer.meta.name }}</label>
</div>
<div class="inputGroup" *ngIf="layer.meta" (change)="onChangeStack(layer)">
  <input id="i{{ layer.uuid }}" name="layer" type="radio" value="{{ layer.uuid }}" />
  <label for="i{{ layer.uuid }}">{{ layer.meta.name }}</label>
</div>
<!-- /ul -->
