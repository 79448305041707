import { Spider } from '@app/models';
import { SearchRequest } from '@app/models/SearchRequest';

export enum UIStatus {
  notInitializated,
  loading,
  loaded,
  empty,
  error,
}

export interface SpiderMappings {
  id: number;
  name: string;
  visible_name: string;
  spiders: {
    id: number;
    name: string;
    description: string;
    titlePrefix: string;
  }[];
}

export interface TopicMappings {
  id: number;
  name: string;
  visible_name: string;
  order: number;
  topics: number[];
}

export interface SearchFilters {
  dates: {
    start: string;
    end: string;
  };
  spiders: {
    id: string;
    group: string;
    itemName: string;
  }[];
  categories: {
    id: string;
    itemName: string;
  }[];
  regions: {
    id: string;
    itemName: string;
  }[];
  contexts: {
    id: string;
    itemName: string;
  }[];
  clippingsSelected: {
    escrita: boolean;
    digital: boolean;
  };
  searchType: 'literal' | 'semantic';
  sort?: number;
}

export interface AgendaFilters {
  spiderGroup?: {
    label: string;
    items: { label: string; value: number }[];
  };
  items: { label: string; value: number }[];
}

export interface AppStateModel {
  search?: SearchRequest;
  uiStatus: UIStatus;
  snackbar?: {
    message: string;
    time?: number;
    dismissable?: string;
  };
  toolbar: {
    title: string;
    show_title: boolean;
    search: {
      dates_visible: boolean;
      search_text_visible: boolean;
      subform_visible: boolean;
      subform_regulatorio_visible: boolean;
      content: string;
      filters: SearchFilters;
      agendas: { items: AgendaEvent[] };
      agendasEvents: AgendaEvent[];
      agendaFilters: AgendaFilters;
      showAgendasTutorial: boolean;
    };
    interesting_visible?: boolean;
  };
  sidemenu: {
    opened: boolean;
  };
  history_url?: string;
  spider_mappings?: SpiderMappings[];
  topic_mappings?: TopicMappings[];
  categories_topics: TopicMappings[];
  region_mappings: RegionMappings[];
  selected_regions: RegionMappings[];
  initiativeNameMapping: { key: string; value: string }[];
  searchType: SearchType;
  notifications: AppNotification[];
}

export interface AppNotification {
  id: number;
  title: string;
  desc: string;
  type: 'download';
  url: string;
  viewed: boolean;
}

export interface RegionMappings {
  id: number;
  name: string;
  visibleName: string;
  order: number;
  regions: number[];
}

export interface PaginatedResults<T> {
  count: number;
  results: Array<T>;
}

export interface Agenda {
  label: string;
  items: { label: string; value: number }[];
}

export interface AgendaEvent {
  spiderId: number;
  tags: string[];
  events: {
    tags: string[];
    body: string;
    spiderId: number;
    datetime: Date;
    title: string;
    location: string;
    url: string;
  }[];
}

export type SearchType = 'literal' | 'operators';
