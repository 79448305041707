<div class="container">
  <h2>Créditos de este mes</h2>
  <div style="display: flex; align-items: center; gap: 12px; padding: 16px" *ngIf="loading">
    <mat-spinner [diameter]="24"></mat-spinner>
    <p style="margin: 0">Cargando datos...</p>
  </div>
  <!-- <div class="form" *ngIf="!loading">
    <mat-form-field>
      <mat-label>Período de búsqueda</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Fecha inicio" />
        <input matEndDate formControlName="end" placeholder="Fecha fin" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button mat-flat-button color="primary" [disabled]="loading" (click)="save()">
      <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
      Buscar
    </button>
  </div> -->
  <!-- <div
    echarts
    [options]="chartsData"
    [autoResize]="true"
    [loading]="loading"
    [loadingOpts]="loadingOpts"
    class="echart"
  ></div> -->
  <p *ngIf="!loading" style="font-size: 16px; font-weight: 400">Gastado: {{ credits.data.credits }} €</p>
</div>
