import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IndicadorService } from '@app/modules/estadisticas/services/indicador.service';

@Component({
  selector: 'app-business-statistics-section',
  templateUrl: './business-statistics-section.component.html',
  styleUrls: ['./business-statistics-section.component.scss'],
})
export class BusinessStatisticsSectionComponent {
  themeId?: number;
  kpis: any[] = [];
  graphs: any[] = [];
  loadingKPIs = true;
  loadingGraphs = true;

  constructor(private indicadorService: IndicadorService, private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.themeId = parseInt(params['themeId']);

      this.getKPIs();

      setTimeout(() => {
        this.getPanels();
      }, 250);
    });
  }

  getKPIs(): void {
    const request = {
      tematica: this.themeId,
    };

    this.indicadorService.getBusinessStatisticsKPIs(request).subscribe((data) => {
      this.kpis = data;
      this.loadingKPIs = false;
    });
  }

  getPanels(): void {
    const request = {
      tematica: this.themeId,
    };

    this.indicadorService.getBusinessStatisticsPanels(request).subscribe((data) => {
      this.graphs = data;
      this.loadingGraphs = false;
    });
  }
}
