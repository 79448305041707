import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { appInjector } from './app/utils/app-injector';
import { AppModule } from './app/app.module';
import { environment } from '@env/environment';

import '@assets/theme/brainy.js';
import '@assets/theme/customed.js';
import '../node_modules/echarts-wordcloud/dist/echarts-wordcloud.min.js';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((appRef) => {
    appInjector(appRef.injector);
  })
  .catch((err) => console.error(err));
