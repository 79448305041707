import { Component, OnInit, OnDestroy, Injectable, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import Utils from '@app/utils/utils';
import { environment, URL } from '@env/environment';
import _moment from 'moment';
import $ from 'jquery';
import { AppStateService } from '@app/state/app/app.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AppNotification, SearchFilters } from '@app/state/models/app.state.model';
import categoriesColorsJson from '@assets/categories-colors.json';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { MenuItem } from '@app/models/User';
import { DocumentService } from '@app/modules/documents/services/document.service';
import { AppStatusService } from '@app/services/app-status.service';
import { AppDataService } from '@app/services/app-data.service';
import { SpidersService } from './services/spiders.service';
import { SearchService } from './services/search.service';
import { Subject } from 'rxjs';
import { getRealm, KC_REALM_CEOE } from '@app/init/keycloak-init.factory';
import { MatDialog } from '@angular/material/dialog';
import { SearchTypeOperatorsDialogComponent } from '@app/modules/documents/components/overview/search-type-operators-dialog/search-type-operators-dialog.component';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'main-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class MainSearch implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  @ViewChild('picker') picker;
  rangePresetSelected = '';

  getRealm = getRealm;
  KC_REALM_CEOE = KC_REALM_CEOE;

  range = new FormGroup({
    start: new FormControl(_moment('2020-01-01').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')),
    end: new FormControl(_moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')),
  });

  clippingFG = new FormGroup({
    escrita: new FormControl(true),
    digital: new FormControl(true),
  });

  searchTypeFG = new FormGroup({
    searchType: new FormControl('literal'),
  });

  searchTypeOptions = [
    { label: 'Literal', value: 'literal' },
    { label: 'Semántica', value: 'semantic' },
  ];

  search: FormGroup = new FormGroup({
    text_search: new FormControl(''),
  });

  show_subform: boolean;
  pagechange = false;
  toolbar_fixed: boolean = false;
  hide_search: boolean = true;
  subscription: any;
  clipping: boolean = false;
  sectorReports: boolean = false;
  regulatorio: boolean = false;
  vigilancia: boolean = false;
  europa: boolean = false;
  spidersSectorReports: any;
  showFiltersInSearchInput = false;
  categoriesColors: { category: string; color: string }[] = categoriesColorsJson;
  documentsMenuItem?: MenuItem;
  documentsMenuItemsData = {
    favorites: 0,
    savedSearches: 0,
    alerts: 0,
  };
  environment = environment;
  canOpenSearchOperatorsDialog = true;

  private data_lock: number = 0;
  private previous_hash: string;
  public show: boolean = false;
  public exec_query: boolean = true;
  static CATEGORIES_ALL = 37;
  static REGIONS_ALL = 1;
  static CONTEXTS_ALL = Infinity;

  //Dropdown Contexts
  dropdownContextList = [];
  selectedContextItems = [];
  dropdownContextSettings = {
    text: 'Selección de contextos',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  //Dropdown categories
  dropdownCategoryList = [];
  selectedCategoryItems = [];
  dropdownCategorySettings = {
    text: 'Selección de tema',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Eliminar Selección',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  //Dropdown Spiders
  dropdownSpiderGroupsList = [];
  selectedSpiderGroupsItems = [];
  dropdownSpiderGroupsSettings = {
    text: 'Selección de fuentes',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Eliminar Selección',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    groupBy: 'group',
    selectGroup: true,
    showCheckbox: true,
  };

  //Dropdown Regions
  dropdownRegionsList = [];
  selectedRegionsItems = [];
  dropdownRegionsSettings = {
    text: 'Selección de ámbito geográfico',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Eliminar Selección',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  get start() {
    return this.range.get('start');
  }

  get end() {
    return this.range.get('end');
  }

  cleanUpSelectors(paths) {
    paths.forEach((path) => {
      let aux = {
        topics: { lists: ['dropdownCategoryList', 'selectedCategoryItems'] },
        regions: { lists: ['dropdownRegionsList', 'selectedRegionsItems'] },
        contexts: { lists: ['dropdownContextList', 'selectedContextItems'] },
        spiderGroups: {
          lists: ['dropdownSpiderGroupsList', 'selectedSpiderGroupsItems'],
        },
      };
      for (let i = aux[path].lists.length - 1; i >= 0; i--) this[aux[path].lists[i]] = [];
    });
  }

  constructor(
    public appStateService: AppStateService,
    public router: Router,
    private sSpider: SpidersService,
    private sSearch: SearchService,
    private appStatus: AppStatusService,
    private appData: AppDataService,
    public authStateService: AuthStateService,
    private documentService: DocumentService,
    private dialog: MatDialog
  ) {
    this.showFiltersInSearchInput = false;

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.showFiltersInSearchInput = false;
    });

    _moment().locale('es');

    let cleanUpTopics = (item) => {
      if (item.id && item.id != MainSearch.CATEGORIES_ALL) {
        let name = item.visibleName ? item.visibleName : item.name;
        return {
          id: item.topics && item.topics.length > 0 ? item.topics[0] : item.id,
          itemName: name.replace('-', '').trim(),
        };
      }
      return undefined;
    },
      cleanUpRegions = (item) => {
        if (item.regions && item.regions[0] && item.regions[0] != MainSearch.REGIONS_ALL) {
          let name = item.visibleName ? item.visibleName : item.name;
          return {
            id: item.regions[0],
            itemName: name.replace('-', '').trim(),
          };
        }
        return undefined;
      },
      cleanUpContexts = (item) => {
        if (item.id && item.id != MainSearch.CONTEXTS_ALL) {
          let name = item.visibleName ? item.visibleName : item.name;
          return {
            id: item.topics && item.topics.length > 0 ? item.topics[0] : item.id,
            itemName: name.replace('-', '').trim(),
          };
        }
        return undefined;
      },
      cleanUpSpiders = (group) => {
        let mappings = {
          spiders_id: { next: null, values: {} },
          spider_groups_id: { next: null, values: {} },
        };

        for (let i = group.spiders.length - 1; i >= 0; i--) {
          let id = group.spiders[i].id;
          mappings.spiders_id.values[id] = group.spiders[i].visibleName ?? group.spiders[i].name;
          mappings.spider_groups_id.values[id] = group.visibleName ?? group.name;
        }
        return this.prepareSpiders(mappings);
      };

    appStatus.query_change.subscribe((query) => {
      this.search.controls.text_search.setValue(query.text);
    });

    appStatus.reset_search.subscribe((url) => {
      if (this.router.url.match(/sector-reports/)) {
        this.start.setValue(_moment().startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'));
        this.end.setValue(_moment().endOf('month').format('YYYY-MM-DDTHH:mm:ssZ'));
      } else {
        this.start.setValue(_moment().startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'));
        this.end.setValue(_moment().endOf('month').endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'));
      }
    });

    this.subscription = appStatus.search_launch.subscribe((args) => {
      setTimeout(() => {
        const spidersClippingSelected = [];

        if (this.clipping) {
          if (args.clippingsSelected) {
            this.clippingFG.controls.escrita.setValue(args.clippingsSelected.escrita);
            this.clippingFG.controls.digital.setValue(args.clippingsSelected.digital);
          }

          if (this.clippingFG.controls.escrita.value) {
            spidersClippingSelected.push(
              ...this.authStateService.userConfig.config.Prensa.Spiders.escrita.map((d) => {
                return { id: d.id };
              })
            );
          }

          if (this.clippingFG.controls.digital.value) {
            spidersClippingSelected.push(
              ...this.authStateService.userConfig.config.Prensa.Spiders.digital.map((d) => {
                return { id: d.id };
              })
            );
          }
        }

        if (this.router.url.includes('vigilancia/') || this.router.url.includes('europe/')) {
          this.searchTypeFG.controls.searchType.setValue(args.searchType);
        } else {
          this.searchTypeFG.controls.searchType.setValue('literal');
        }

        this.sSearch.searchType = this.searchTypeFG.controls.searchType.value;

        let auxSpiderIds =
          args.spiderIds && args.spiderIds.length > 0
            ? this.mapSpiderIds(args.spiderIds)
            : this.selectedSpiderGroupsItems.length == 0
              ? this.dropdownSpiderGroupsList
              : this.selectedSpiderGroupsItems;

        this.sSearch.text =
          args.text_search != undefined && args.text_search != null
            ? args.text_search
            : this.search.controls.text_search.value;
        this.sSearch.dateParsed_start = args.start
          ? _moment(args.start).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
          : _moment(this.start.value).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        this.sSearch.dateParsed_end = args.end
          ? _moment(args.end).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
          : _moment(this.end.value).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        this.sSearch.spiderIds = this.clipping
          ? spidersClippingSelected
          : this.sectorReports
            ? this.spidersSectorReports
            : this.getSelectedSpiderIds(auxSpiderIds);
        this.sSearch.docTopics = args.docTopics ? args.docTopics : this.selectedCategoryItems;
        this.sSearch.docTopicsRegion = args.docTopicsRegion ? args.docTopicsRegion : this.selectedRegionsItems;
        this.sSearch.docContexts = args.docContexts ? args.docContexts : this.selectedContextItems;
        this.search.controls.text_search.setValue(this.sSearch.text);
        this.start.setValue(_moment(this.sSearch.dateParsed_start).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'));
        this.end.setValue(_moment(this.sSearch.dateParsed_end).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'));
        this.selectedSpiderGroupsItems = auxSpiderIds != this.dropdownSpiderGroupsList ? auxSpiderIds : [];
        this.selectedCategoryItems = this.sSearch.docTopics;
        this.selectedRegionsItems = this.sSearch.docTopicsRegion;
        this.selectedContextItems = this.sSearch.docContexts;

        const scoreElement = document.getElementById('input-score-search');
        const candidatesElement = document.getElementById('input-candidates-search');

        this.sSearch.score =
          args.score != null && args.score != undefined
            ? args.score
            : scoreElement
              ? Number(scoreElement.getAttribute('aria-valuenow')) / 100
              : this.sSearch.score ?? 0.6;
        this.sSearch.candidates =
          args.candidates != null && args.candidates != undefined
            ? args.candidates
            : candidatesElement
              ? Number((candidatesElement as HTMLInputElement)?.value)
              : this.sSearch.candidates ?? 30;

        if (args.sort) {
          this.sSearch.sort = args.sort;
        }

        if (this.exec_query) {
          this.sSearch.saveParamsInLocalStorage();
          this.sSearch.emitParams();
        }
      }, 0);
    });

    appStatus.page_change.pipe(takeUntil(this.componentDestroyed$)).subscribe((page) => {
      page = this.removeQueryParamsFromUrl(page);

      if (!this.pagechange) {
        this.pagechange = true;
        this.regulatorio = false;
        this.vigilancia = false;
        this.europa = false;

        if (!page.match(/documents/) || !page.match(/dashboard/) || !page.match(/sector-reports/)) {
          this.clipping = false;
        }

        if (!page.match(/documents/) || !page.match(/dashboard/) || !page.match(/clipping/)) {
          this.sectorReports = false;
        }

        if (!page.match(/regulatorio/) && !page.match(/vigilancia/) && !page.match(/europe/)) {
          this.selectedContextItems = [];
        }

        if (!page.match(/regulatorio/) && !page.match(/vigilancia/) && !page.match(/europe/) && 0 >= this.data_lock) {
          this.data_lock = 3;
          if (!appData.loaded) {
            appData.load();
          } else {
            appData.loadFilters();
          }

          this.appData.filters_loaded.subscribe((mappings) => {
            this.dropdownSpiderGroupsList = this.prepareSpiders(mappings);
            this.data_lock--;
          });

          if (!this.router.url.includes('/login') && !this.router.url.includes('/tenders') && this.router.url !== '/') {
            this.dropdownCategoryList = this.prepare(
              authStateService.userConfig.config.Documentos.Topics.map(cleanUpTopics)
            );
            this.data_lock--;

            this.dropdownRegionsList = this.prepare(
              authStateService.userConfig.config.Documentos.Regions.map(cleanUpRegions)
            );
            this.data_lock--;
          }
        }

        if (page.match(/dashboard/)) {
          this.setDashboardQueryText();
        } else {
          this.exec_query = true;
        }

        if (page == 'documents_home') {
          this.hide_search = true;
        }

        if (page.match(/clipping/)) {
          this.clipping = true;
        }

        if (page.match(/sector-reports/)) {
          this.sectorReports = true;
          this.spidersSectorReports = [
            {
              id: '3778',
            },
          ];
        }

        if (page.match(/regulatorio/)) {
          if (page != this.previous_hash) {
            this.reset();
            this.previous_hash = page;
          }

          this.regulatorio = true;

          let item = this.authStateService.userConfig.interface_config.menu_items
            .find((item) => item.path.match(/regulatorio/) != undefined)
            .items.filter((item) => item.path == page);

          if (item && item.length) {
            let fn = (item) => {
              if (0 >= this.data_lock && item[0].config) {
                this.data_lock = item[0].config.paths.length;

                item[0].config.paths
                  .map((path) => path.slice(1))
                  .forEach((path) => {
                    let url = `${URL}${page.slice(1)}/${path}`;

                    if (path === 'topicsEu') {
                      url = environment.topicsUrlEurovoc;
                    }

                    let response = [];

                    if (url.includes('spiderGroups')) {
                      response =
                        this.authStateService.userConfig.config.Regulatorio[page.slice(1).split('/')[1]].SpiderGroups;
                    } else if (url.includes('topics')) {
                      response =
                        this.authStateService.userConfig.config.Regulatorio[page.slice(1).split('/')[1]].Topics;
                    } else if (url.includes('regions')) {
                      response =
                        this.authStateService.userConfig.config.Regulatorio[page.slice(1).split('/')[1]].Regions;
                    } else if (url.includes('contexts')) {
                      response =
                        this.authStateService.userConfig.config.Regulatorio[page.slice(1).split('/')[1]].Context;
                    }

                    let auxMap = {
                      topics: 'topics_id',
                      spiderGroups: 'spider_groups_id',
                      regions: 'regions_id',
                      contexts: 'contexts_id',
                    },
                      aux = {
                        contexts: {
                          clean: cleanUpContexts,
                          dropdown: 'dropdownContextList',
                        },
                        topics: {
                          clean: cleanUpTopics,
                          dropdown: 'dropdownCategoryList',
                        },
                        spiderGroups: {
                          clean: cleanUpSpiders,
                          dropdown: 'dropdownSpiderGroupsList',
                        },
                        regions: {
                          clean: cleanUpRegions,
                          dropdown: 'dropdownRegionsList',
                        },
                      },
                      toMapping = {};

                    response.forEach((item) => {
                      let i = aux[path.replace('topicsEu', 'topics')].clean(item);
                      if (i) {
                        toMapping[i.id] = i.itemName;
                      }
                    });

                    this.appData.cleanMappings(auxMap[path]);
                    this.appData.addMappings(auxMap[path], toMapping);
                    this[aux[path.replace('topicsEu', 'topics')].dropdown] = this.prepare(
                      response.map(aux[path.replace('topicsEu', 'topics')].clean)
                    );
                    this.data_lock--;
                  });
              } else
                setTimeout(() => {
                  fn(item);
                }, 1000);
            };
            fn(item);
          }
        }

        if (page.match(/vigilancia/)) {
          this.appStateService.addTitle('Vigilancia legislativa');
          if (page != this.previous_hash) {
            // this.resetSearch();
            this.previous_hash = page;
          }

          this.vigilancia = true;

          let item = this.authStateService.userConfig.interface_config.menu_items
            .find((item) => item.id === 6)
            .items.filter((item) => page.includes(item.path));

          if (item && item.length) {
            let fn = (item) => {
              if (0 >= this.data_lock && item[0].items.find((i) => i.path === page).config) {
                this.data_lock = item[0].items.find((i) => i.path === page).config.paths.length;

                item[0].items
                  .find((i) => i.path === page)
                  .config.paths.map((path) => path.slice(1))
                  .forEach((path) => {
                    let url = `${URL}${page.slice(1)}/${path}`;

                    if (path === 'topicsEu') {
                      url = environment.topicsUrlEurovoc;
                    }

                    let response = [];

                    if (url.includes('spiderGroups')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].SpiderGroups;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].SpiderGroups;
                      }
                    } else if (url.includes('topics')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Topics;
                      } else {
                        this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                          page.slice(1).split('/')[2]
                        ][page.slice(1).split('/')[3]].Topics;
                      }
                    } else if (url.includes('regions')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Regions;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].Regions;
                      }
                    } else if (url.includes('contexts')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Context;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Vigilancia[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].Context;
                      }
                    }

                    let auxMap = {
                      topics: 'topics_id',
                      spiderGroups: 'spider_groups_id',
                      regions: 'regions_id',
                      contexts: 'contexts_id',
                    },
                      aux = {
                        contexts: {
                          clean: cleanUpContexts,
                          dropdown: 'dropdownContextList',
                        },
                        topics: {
                          clean: cleanUpTopics,
                          dropdown: 'dropdownCategoryList',
                        },
                        spiderGroups: {
                          clean: cleanUpSpiders,
                          dropdown: 'dropdownSpiderGroupsList',
                        },
                        regions: {
                          clean: cleanUpRegions,
                          dropdown: 'dropdownRegionsList',
                        },
                      },
                      toMapping = {};

                    response.forEach((item) => {
                      let i = aux[path.replace('topicsEu', 'topics')].clean(item);
                      if (i) {
                        toMapping[i.id] = i.itemName;
                      }
                    });

                    this.appData.cleanMappings(auxMap[path]);
                    this.appData.addMappings(auxMap[path], toMapping);
                    this[aux[path.replace('topicsEu', 'topics')].dropdown] = this.prepare(
                      response.map(aux[path.replace('topicsEu', 'topics')].clean)
                    );
                    this.data_lock--;
                  });
              } else
                setTimeout(() => {
                  fn(item);
                }, 1000);
            };
            fn(item);
          }
        }

        if (page.match(/europe/)) {
          this.appStateService.addTitle('Europa');
          if (page != this.previous_hash) {
            // this.resetSearch();
            this.previous_hash = page;
          }

          this.europa = true;

          let item = this.authStateService.userConfig.interface_config.menu_items
            .find((item) => item.id === 7)
            .items.filter((item) => page.includes(item.path));

          if (item && item.length) {
            let fn = (item) => {
              if (0 >= this.data_lock && item[0].items.find((i) => i.path === page).config) {
                this.data_lock = item[0].items.find((i) => i.path === page).config.paths.length;

                item[0].items
                  .find((i) => i.path === page)
                  .config.paths.map((path) => path.slice(1))
                  .forEach((path) => {
                    let url = `${URL}${page.slice(1)}/${path}`;

                    if (path === 'topicsEu') {
                      url = environment.topicsUrlEurovoc;
                    }

                    let response = [];

                    if (url.includes('spiderGroups')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].SpiderGroups;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].SpiderGroups;
                      }
                    } else if (url.includes('topics')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Topics;
                      } else {
                        this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                          page.slice(1).split('/')[2]
                        ][page.slice(1).split('/')[3]].Topics;
                      }
                    } else if (url.includes('regions')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Regions;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].Regions;
                      }
                    } else if (url.includes('contexts')) {
                      if (page.slice(1).split('/').length === 3) {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ].Context;
                      } else {
                        response =
                          this.authStateService.userConfig.config.Europa[page.slice(1).split('/')[1]][
                            page.slice(1).split('/')[2]
                          ][page.slice(1).split('/')[3]].Context;
                      }
                    }

                    let auxMap = {
                      topics: 'topics_id',
                      spiderGroups: 'spider_groups_id',
                      regions: 'regions_id',
                      contexts: 'contexts_id',
                    },
                      aux = {
                        contexts: {
                          clean: cleanUpContexts,
                          dropdown: 'dropdownContextList',
                        },
                        topics: {
                          clean: cleanUpTopics,
                          dropdown: 'dropdownCategoryList',
                        },
                        spiderGroups: {
                          clean: cleanUpSpiders,
                          dropdown: 'dropdownSpiderGroupsList',
                        },
                        regions: {
                          clean: cleanUpRegions,
                          dropdown: 'dropdownRegionsList',
                        },
                      },
                      toMapping = {};

                    response.forEach((item) => {
                      let i = aux[path.replace('topicsEu', 'topics')].clean(item);
                      if (i) {
                        toMapping[i.id] = i.itemName;
                      }
                    });

                    this.appData.cleanMappings(auxMap[path]);
                    this.appData.addMappings(auxMap[path], toMapping);
                    this[aux[path.replace('topicsEu', 'topics')].dropdown] = this.prepare(
                      response.map(aux[path.replace('topicsEu', 'topics')].clean)
                    );
                    this.data_lock--;
                  });
              } else
                setTimeout(() => {
                  fn(item);
                }, 1000);
            };
            fn(item);
          }
        }

        if (page == 'clipped') {
          this.clipping = true;
        } else if (page == 'sector-reports') {
          this.sectorReports = true;
        }
      }

      setTimeout(() => {
        this.pagechange = false;
      }, 500);
    });

    appStatus.reset_search.subscribe((ev) => {
      this.start.setValue(_moment().subtract(1, 'months').toISOString());
      this.end.setValue(_moment().toISOString());

      if (ev && ev.match(/clipping/)) {
        this.start.setValue(_moment().toISOString());
        this.end.setValue(_moment().toISOString());
        this.show = true;
        this.clipping = true;
        this.hide_search = true;
      } else if (ev && ev.match(/sector-reports/)) {
        this.show = true;
        this.sectorReports = true;
        this.hide_search = true;
      }

      if (this.router.url.match(/sector-reports/)) {
        this.start.setValue(_moment().startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'));
        this.end.setValue(_moment().endOf('month').format('YYYY-MM-DDTHH:mm:ssZ'));
      } else {
        this.start.setValue(_moment().startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'));
        this.end.setValue(_moment().endOf('month').endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'));
      }
    });

    appStateService.searchFilters$.subscribe((data) => {
      setTimeout(() => {
        this.setSearchFilters(data);
      }, 100);
    });

    this.start.valueChanges.subscribe((_) => {
      this.handleDateUpdated();
    });

    this.end.valueChanges.subscribe((_) => {
      this.handleDateUpdated();
    });

    setTimeout(() => {
      if (this.router.url.match(/clipping/)) {
        const spidersClippingSelected = [];

        if (this.clipping) {
          if (this.clippingFG.controls.escrita.value) {
            spidersClippingSelected.push(
              ...this.authStateService.userConfig.config.Prensa.Spiders.escrita.map((d) => {
                return { id: d.id };
              })
            );
          }
          if (this.clippingFG.controls.digital.value) {
            spidersClippingSelected.push(
              ...this.authStateService.userConfig.config.Prensa.Spiders.digital.map((d) => {
                return { id: d.id };
              })
            );
          }
        }

        this.appStateService.updateSearchFilters({
          dates: {
            start: _moment().toISOString(),
            end: _moment().toISOString(),
          },
          spiders: this.clipping ? spidersClippingSelected : [...this.selectedSpiderGroupsItems],
          categories: [...this.selectedCategoryItems],
          regions: [...this.selectedRegionsItems],
          contexts: [...this.selectedContextItems],
          clippingsSelected: {
            escrita: this.clippingFG.controls.escrita.value,
            digital: this.clippingFG.controls.digital.value,
          },
          searchType: this.searchTypeFG.controls.searchType.value,
        });
      }
    }, 1000);
  }

  ngOnInit() {
    // this.appStateService.showDates();
    // this.appStateService.showSearchText();
    // this.appStateService.showSubForm();
    // this.appStateService.showRegulatorioSubForm();
    this.show_subform = false;
    this.appStatus.show_subform.emit(this.show_subform);
    this.documentsMenuItem = this.authStateService.userConfig.interface_config.menu_items.find(
      (mi) => mi.header === 'Documentos'
    );

    if (this.documentsMenuItem) {
      this.documentsMenuItem = {
        ...this.documentsMenuItem,
        items: this.documentsMenuItem.items.filter((i) => i.path !== '/documents'),
      };

      // this.getDocumentsMenuItems();
    }

    this.start.setValue(_moment().startOf('month').toISOString());
    this.end.setValue(_moment().endOf('month').toISOString());

    if (this.router.url.match(/clipping/)) {
      this.start.setValue(_moment().toISOString());
      this.end.setValue(_moment().toISOString());
    }
  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getDocumentsMenuItems(): void {
    this.sSearch.getFavorites().subscribe((data) => {
      this.documentService.refreshToolbarCounterFavoriteDocuments$.next(data.count);
    });

    // this.sSearch.getFavoriteSearch().subscribe((data) => {
    //   this.documentService.refreshToolbarCounterFavorites$.next(data.count);
    //   this.documentService.refreshToolbarCounterAlerts$.next(data.results.filter((r) => r.alert).length);
    // });

    // this.documentService.refreshToolbarCounterFavorites$.subscribe((value) => {
    //   this.documentsMenuItemsData.savedSearches = value || 0;
    // });

    // this.documentService.refreshToolbarCounterFavoriteDocuments$.subscribe((value) => {
    //   this.documentsMenuItemsData.favorites = value || 0;
    // });

    // this.documentService.refreshToolbarCounterAlerts$.subscribe((value) => {
    //   this.documentsMenuItemsData.alerts = value || 0;
    // });
  }

  setSearchFilters(filters: SearchFilters): void {
    this.selectedSpiderGroupsItems = [...filters.spiders];
    this.selectedCategoryItems = [...filters.categories];
    this.selectedRegionsItems = [...filters.regions];
    this.selectedContextItems = [...filters.contexts];

    this.start.setValue(filters.dates.start);
    this.end.setValue(filters.dates.end);

    this.search.controls.text_search.setValue(this.appStateService.content);
  }

  public getSelectedSpiderIds(selectedSpiderGroupsItems) {
    let ids = [];
    selectedSpiderGroupsItems.forEach((item) => {
      item.id.split(',').forEach((id) => {
        ids.push({
          id: id,
          itemName: item.itemName.toString() ? item.itemName.toString().trim() : '',
          group: item.group,
        });
      });
    });
    return ids;
  }

  private mapSpiderIds(listOfIds) {
    let aggrs = {},
      ret = [];
    listOfIds.forEach((item) => {
      if (!aggrs[item.itemName])
        aggrs[item.itemName] = {
          id: [],
          itemName: item.itemName,
          group: this.appData.map('spider_groups_id', item.id),
        };
      aggrs[item.itemName].id.push(item.id);
    });
    Object.keys(aggrs).forEach((name) => {
      aggrs[name].id = aggrs[name].id.join(',');
    });
    return Object.values(aggrs);
  }

  private prepare(arr) {
    // arr = arr && arr[0] && arr[0].constructor.toString().match(/Array/) ? arr[0] : arr;
    return !arr ? undefined : [].concat(...arr).filter((item) => item != undefined);
  }

  private prepareSpiders(mappings) {
    let spiders_id = (mappings['spiders_id'] && mappings['spiders_id'].values) || [],
      spider_groups_id = (mappings['spider_groups_id'] && mappings['spider_groups_id'].values) || [],
      aux = [];

    if (this.clipping) {
      let filteredSpiders = {};

      [
        ...this.authStateService.userConfig.config.Prensa.Spiders.escrita.map((d) => {
          return { id: d.id };
        }),
        ...this.authStateService.userConfig.config.Prensa.Spiders.digital.map((d) => {
          return { id: d.id };
        }),
      ].forEach((item) => {
        const id = item.id.toString();
        if (spiders_id[id]) {
          filteredSpiders[id] = spiders_id[id];
        }
      });

      spiders_id = filteredSpiders;
    }

    Object.keys(spiders_id).forEach((key) => {
      aux.push({
        id: key,
        itemName: spiders_id[key],
        group: spider_groups_id[key],
      });
    });

    aux = aux.sort((a, b) => {
      let _a = Utils.cleanUpDiacritic(a.group).toUpperCase(),
        _b = Utils.cleanUpDiacritic(b.group).toUpperCase(),
        _ia = Utils.cleanUpDiacritic(a.itemName).toUpperCase(),
        _ib = Utils.cleanUpDiacritic(b.itemName).toUpperCase();
      if (_a < _b) return -1;
      if (_a > _b) return 1;
      if (_ia < _ib) return -1;
      if (_ia > _ib) return 1;
      return 0;
    });
    let last_group = undefined,
      last_name = undefined,
      id = [],
      aux2 = [];

    aux.splice(0, aux.length).forEach((item) => {
      if (last_name == undefined) {
        last_name = item.itemName;
        last_group = item.group;
      }
      if (last_name != item.itemName && last_group == item.group) {
        aux2.push({
          id: id.join(','),
          itemName: last_name,
          group: last_group,
        });
        id = [item.id];
        last_name = item.itemName;
      } else if (last_group == item.group) {
        id.push(item.id);
      } else {
        aux2.push({
          id: id.join(','),
          itemName: last_name,
          group: last_group,
        });
        id = [item.id];
        last_name = item.itemName;
        last_group = item.group;
      }
    });
    aux2.push({
      id: id.join(','),
      itemName: last_name,
      group: last_group,
    });
    //this.dropdownSpiderGroupsList = aux2;
    return [...aux2];
  }

  public reset() {
    this.search.controls.text_search.setValue('');
    this.sSearch.resetQuery();
    this.selectedCategoryItems = [];
    this.selectedRegionsItems = [];
    this.selectedSpiderGroupsItems = [];
    this.selectedContextItems = [];
  }

  public submenutoggle() {
    this.appStateService.toggleSubForm();
  }

  public showInteresting() {
    this.appStateService.showInteresting();
  }

  public hideInteresting() {
    this.appStateService.hideInteresting();
  }

  seek(starting: FormControl, ending: FormControl) {
    this.showFiltersInSearchInput = false;

    const spidersClippingSelected = [];

    if (this.clipping) {
      if (this.clippingFG.controls.escrita.value) {
        spidersClippingSelected.push(
          ...this.authStateService.userConfig.config.Prensa.Spiders.escrita.map((d) => {
            return { id: d.id };
          })
        );
      }
      if (this.clippingFG.controls.digital.value) {
        spidersClippingSelected.push(
          ...this.authStateService.userConfig.config.Prensa.Spiders.digital.map((d) => {
            return { id: d.id };
          })
        );
      }
    }

    let sort = '1';

    if (this.searchTypeFG.controls.searchType.value === 'semantic') {
      sort = '3';
    }

    let selectedSpiders = this.clipping
      ? spidersClippingSelected
      : this.sectorReports
        ? this.spidersSectorReports
        : this.getSelectedSpiderIds(this.selectedSpiderGroupsItems);

    let request = {
      text_search: this.search.controls.text_search.value,
      start: _moment(starting.value).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
      end: _moment(ending.value).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
      docTopics: this.selectedCategoryItems,
      docContexts: this.selectedContextItems,
      docTopicsRegion: this.selectedRegionsItems,
      spiderIds: this.clipping
        ? spidersClippingSelected
        : this.sectorReports
          ? this.spidersSectorReports
          : selectedSpiders,
      searchType: this.searchTypeFG.controls.searchType.value,
      sort,
    };

    this.appStatus.search_launch.emit(request);

    setTimeout(() => {
      this.appStateService.updateSearchContent(this.sSearch.text || '');
      this.appStateService.updateSearchFilters({
        dates: {
          start: this.sSearch.dateParsed_start,
          end: this.sSearch.dateParsed_end,
        },
        spiders: this.clipping ? spidersClippingSelected : [...this.selectedSpiderGroupsItems],
        categories: [...this.selectedCategoryItems],
        regions: [...this.selectedRegionsItems],
        contexts: [...this.selectedContextItems],
        clippingsSelected: {
          escrita: this.clippingFG.controls.escrita.value,
          digital: this.clippingFG.controls.digital.value,
        },
        searchType: this.searchTypeFG.controls.searchType.value,
      });
    }, 100);

    this.hideSectorReportsImageAndText();
  }

  handleSearchFilters(): void {
    this.showFiltersInSearchInput = !this.showFiltersInSearchInput;
    this.listenCategoriesMultiselectDropdown();
  }

  handleAngularMultiselectHeight(id: string): void {
    const select = $(`#${id} .list-area div:last-child`)[0];
    const windowHeight = $(window).height();
    const selectTopDistance = select?.getBoundingClientRect()?.top;

    if (select && select.clientHeight > 0 && windowHeight > 0 && selectTopDistance > 0) {
      select.style.maxHeight = `${windowHeight - selectTopDistance - 24}px`;
    }
  }

  getCategoryColor(category: string): string {
    const categorySelected = this.categoriesColors.find((cc) => cc.category === category);
    return categorySelected ? categorySelected.color : '#00000010';
  }

  listenCategoriesMultiselectDropdown(): void {
    setTimeout(() => {
      const cBtn = document.querySelector('#am2-3-categories .c-btn') as HTMLElement;
      if (cBtn) {
        cBtn.click();

        setTimeout(() => {
          cBtn.click();

          const observer = new MutationObserver((mutations_list) => {
            mutations_list.forEach((mutation) => {
              mutation.addedNodes.forEach(() => {
                for (const child of [].slice.call(cBtn.querySelector('.c-list')?.children ?? [])) {
                  child.style.backgroundColor = this.getCategoryColor(child.querySelector('.c-label').innerText);
                }
              });
            });
          });

          observer.observe(cBtn, { subtree: true, childList: true });
        }, 0);
      }
    }, 0);
  }

  hideSectorReportsImageAndText(): void {
    const container = document.getElementById('sector-reports-search-container');

    if (container) {
      const imgElement: HTMLImageElement = container.querySelector('img');
      const textElement: HTMLDivElement = container.querySelector('.text');

      imgElement.style.display = 'none';
      textElement.style.display = 'none';
    }
  }

  logout() {
    this.authStateService.logout();
  }

  updatePassword() {
    this.authStateService.updatePassword();
  }

  viewMyData() {
    this.authStateService.viewMyData();
  }

  setDashboardQueryText(): void {
    const item = this.authStateService.userConfig.interface_config.menu_items
      .find((mi) => mi.path === '/dashboards')
      .items.find((i: any) => i.path === this.router.url);

    if (item) {
      const dashboard = this.authStateService.userConfig.interface_config.dashboards.find(
        (d) => d.title === item.title
      );

      // this.exec_query = false;

      if (dashboard) {
        this.search.controls.text_search.setValue(dashboard.query.text);
      }
    }
  }

  openedDatepicker(): void {
    this.insertDatepickerRangePresetsComponent();
  }

  closedDatepicker(): void {
    this.deleteDatepickerRangePresetsComponent();
  }

  insertDatepickerRangePresetsComponent(): void {
    const pStyle =
      'margin: 0; width: 100%; height: 40px; display: flex; align-items: center; padding: 0 14px; border-radius: 4px; font-size: 14px; font-weight: 500; color: #1F2937; cursor: pointer;';

    document.querySelector('mat-calendar').insertAdjacentHTML(
      'beforebegin',
      `<div id="datepicker-range-presets" style="padding: 20px; padding-right: 0; display: flex; flex-direction: column;">
          <p id="datepicker-range-presets-0" style="${pStyle}">Hoy</p>
          <p id="datepicker-range-presets-1" style="${pStyle}">Semana actual</p>
          <p id="datepicker-range-presets-2" style="${pStyle}">Últimas 2 semanas</p>
          <p id="datepicker-range-presets-3" style="${pStyle}">Mes actual</p>
          <p id="datepicker-range-presets-4" style="${pStyle}">Último semestre</p>
          <p id="datepicker-range-presets-5" style="${pStyle}">Año actual</p>
        </div`
    );

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-0').addEventListener('click', () => {
      this.start.setValue(_moment().toISOString());
      this.end.setValue(_moment().toISOString());
      this.reOpenCalendar();
    });

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-1').addEventListener('click', () => {
      this.start.setValue(_moment().startOf('week').toISOString());
      this.end.setValue(_moment().endOf('week').toISOString());
      this.reOpenCalendar();
    });

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-2').addEventListener('click', () => {
      this.start.setValue(_moment().subtract(2, 'weeks').toISOString());
      this.end.setValue(_moment().toISOString());
      this.reOpenCalendar();
    });

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-3').addEventListener('click', () => {
      this.start.setValue(_moment().startOf('month').toISOString());
      this.end.setValue(_moment().endOf('month').toISOString());
      this.reOpenCalendar();
    });

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-4').addEventListener('click', () => {
      this.start.setValue(_moment().subtract(6, 'months').toISOString());
      this.end.setValue(_moment().toISOString());
      this.reOpenCalendar();
    });

    document.querySelector('#datepicker-range-presets #datepicker-range-presets-5').addEventListener('click', () => {
      this.start.setValue(_moment().startOf('year').toISOString());
      this.end.setValue(_moment().endOf('year').toISOString());
      this.reOpenCalendar();
    });

    this.handleDateUpdated();
  }

  deleteDatepickerRangePresetsComponent(): void {
    document.querySelector('#datepicker-range-presets').remove();
  }

  handleDateUpdated(): void {
    if (_moment(this.start.value).toISOString() && _moment(this.end.value).toISOString()) {
      this.rangePresetSelected = '';

      document
        ?.querySelector('#datepicker-range-presets')
        ?.querySelectorAll('p')
        ?.forEach((preset) => {
          preset.classList.remove('selected');
        });

      if (
        _moment(this.start.value).toISOString().split('T')[0] == _moment().toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-0')?.classList.add('selected');
        this.rangePresetSelected = 'Hoy';
      } else if (
        _moment(this.start.value).toISOString().split('T')[0] ==
        _moment().startOf('week').toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().endOf('week').toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-1')?.classList.add('selected');
        this.rangePresetSelected = 'Semana actual';
      } else if (
        _moment(this.start.value).toISOString().split('T')[0] ==
        _moment().subtract(2, 'weeks').toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-2')?.classList.add('selected');
        this.rangePresetSelected = 'Últimas 2 semanas';
      } else if (
        _moment(this.start.value).toISOString().split('T')[0] ==
        _moment().startOf('month').toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().endOf('month').toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-3')?.classList.add('selected');
        this.rangePresetSelected = 'Mes actual';
      } else if (
        _moment(this.start.value).toISOString().split('T')[0] ==
        _moment().subtract(6, 'months').toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-4')?.classList.add('selected');
        this.rangePresetSelected = 'Último semestre';
      } else if (
        _moment(this.start.value).toISOString().split('T')[0] ==
        _moment().startOf('year').toISOString().split('T')[0] &&
        _moment(this.end.value).toISOString().split('T')[0] == _moment().endOf('year').toISOString().split('T')[0]
      ) {
        document.querySelector('#datepicker-range-presets #datepicker-range-presets-5')?.classList.add('selected');
        this.rangePresetSelected = 'Año actual';
      }
    }
  }

  reOpenCalendar() {
    this.picker.close();

    setTimeout(() => {
      this.picker.open();
    }, 0);
  }

  openSearchTypeOperatorsDialog(): void {
    const dialog = this.dialog.open(SearchTypeOperatorsDialogComponent, {
      autoFocus: false,
      width: '100%',
      maxWidth: '790px',
      data: {
        text: this.search.controls.text_search.value,
      },
    });

    dialog.afterClosed().subscribe((data) => {
      this.search.controls.text_search.setValue(data);
    });
  }

  openNotificationUrl(notification: AppNotification): void {
    window.open(notification.url, '_blank');

    const notificationSelected = JSON.parse(
      JSON.stringify(this.appStateService.notifications.find((n) => n.id === notification.id))
    );

    notificationSelected.viewed = true;

    this.appStateService.updateNotifications(notificationSelected);
  }

  hasNotificationsNotViewed(): boolean {
    return this.appStateService.notifications.some((n) => !n.viewed);
  }

  readAllNotifications(): void {
    const notificationsSelected = JSON.parse(
      JSON.stringify(
        this.appStateService.notifications.map((n) => {
          return {
            ...n,
            viewed: true,
          };
        })
      )
    );

    this.appStateService.setNotifications(notificationsSelected);
  }

  removeQueryParamsFromUrl(url: string): string {
    return url.replace(/\?.*$/, '');
  }
}
