import { Model, ModelService } from './base/Model';
import { environment } from '@env/environment';
import { appInjector } from 'app/utils/app-injector';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export class UserIdentity extends Model {
  static readonly configUri = environment.userConfig;
  config: any = {};
  user_id: string = undefined;
  authInfo: any = undefined;
  constructor() {
    super(arguments[0]);
  }

  static model(args?: any) {
    args = args || {};
    return Model.model(UserIdentity, args);
  }

  service(): any {
    let injector = appInjector();
    return new ModelService({
      getUri: UserIdentity.configUri,
      classConstructor: this.constructor,
      httpClient: injector.get(HttpClient),
      httpOptions: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    });
  }

  store() {
    return super.save_locally('userIdentity');
  }

  load() {
    return super.load_locally('userIdentity');
  }

  remove() {
    super.remove_locally('userIdentity');
  }

  loadConfig() {
    return Object.keys(this.config).length > 0
      ? new Promise((resolve) => resolve(this.config))
      : this.service()
          .get({ id: this.user_id })
          .then((conf) => {
            conf['interface_config'] = JSON.parse(conf['interface_config']);
            this.attr('config', conf);

            this.store();
            return conf;
          })
          .catch((error) => console.log(error));
  }
}
