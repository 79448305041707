import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import {
  CollectiveAgreement,
  CollectiveAgreementsFilters,
  CollectiveAgreementsGetResponse,
  CollectiveAgreementsStateModel,
} from '../../models/collective-agreement';
import {
  GetCollectiveAgreement,
  GetCollectiveAgreements,
  GetFilters,
  ToggleCollectiveAgreementsMenu,
  UpdateCollectiveAgreementsMenuFilters,
} from './collective-agreements.actions';

@Injectable({
  providedIn: 'root',
})
@State<CollectiveAgreementsStateModel>({
  name: 'collectiveAgreements',
  defaults: {
    collectiveAgreements: {
      items: [],
      page: 0,
      size: 0,
      total: 0,
      total_pages: 0,
    },
    filters: {
      filters: undefined,
      selectedFilters: {},
      openedMenu: true,
    },
    openedMenu: true,
  },
})
export class CollectiveAgreementsState {
  @Selector()
  static collectiveAgreements(state: CollectiveAgreementsStateModel): CollectiveAgreementsGetResponse {
    return state.collectiveAgreements;
  }

  @Selector()
  static collectiveAgreement(state: CollectiveAgreementsStateModel): CollectiveAgreement {
    return state.collectiveAgreement;
  }

  @Selector()
  static openedMenu(state: CollectiveAgreementsStateModel): boolean {
    return state.openedMenu;
  }

  @Selector()
  static filters(state: CollectiveAgreementsStateModel): CollectiveAgreementsFilters {
    return state.filters;
  }

  @Action(GetCollectiveAgreements)
  getCollectiveAgreements(
    { getState, patchState }: StateContext<CollectiveAgreementsStateModel>,
    response: { payload: CollectiveAgreementsGetResponse }
  ) {
    const state = getState();
    let collectiveAgreements = state.collectiveAgreements;
    collectiveAgreements = response.payload;
    patchState({ collectiveAgreements });
  }

  @Action(GetCollectiveAgreement)
  getCollectiveAgreement(
    { getState, patchState }: StateContext<CollectiveAgreementsStateModel>,
    response: { payload: CollectiveAgreement }
  ) {
    const state = getState();
    let collectiveAgreement = state.collectiveAgreement;
    collectiveAgreement = response.payload;
    patchState({ collectiveAgreement });
  }

  @Action(ToggleCollectiveAgreementsMenu)
  toggleCollectiveAgreementsMenu({ getState, patchState }: StateContext<CollectiveAgreementsStateModel>) {
    const state = getState();
    const openedMenu = !state.openedMenu;
    patchState({ openedMenu });
  }

  @Action(GetFilters)
  getFilters(
    { getState, patchState }: StateContext<CollectiveAgreementsStateModel>,
    response: {
      payload: {
        id: number;
        attr: string;
        label: string;
        type: number;
        isMultiSelectList: boolean;
        items: {
          label: string;
          color?: string;
          value: number;
          items: {
            label: string;
            color?: string;
            value: number;
          }[];
        }[];
      }[];
    }
  ) {
    const state = JSON.parse(JSON.stringify(getState()));
    let filters = state.filters as CollectiveAgreementsFilters;
    filters.filters = response.payload;
    patchState({ filters });
  }

  @Action(UpdateCollectiveAgreementsMenuFilters)
  updateCollectiveAgreementsMenuFilters(
    { getState, patchState }: StateContext<CollectiveAgreementsStateModel>,
    response: { payload: CollectiveAgreementsFilters }
  ) {
    const state = getState();
    let filters = state.filters;
    filters = response.payload;
    patchState({ filters });
  }
}
