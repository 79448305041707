import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import { DraftStateModel, Draft } from '../models/draft.state.model';
import { DeleteDraft, EditDraft, AddDraft, SetDraft, GetDrafts, GenerateDraftPdf } from './draft.actions';

@Injectable({
  providedIn: 'root',
})
@State<DraftStateModel>({
  name: 'draft',
  defaults: {
    drafts: [],
    selectedDraft: undefined,
  },
})
export class DraftState {
  @Selector()
  static drafts(state: DraftStateModel): Draft[] | null {
    return state.drafts;
  }

  @Selector()
  static selectedDraft(state: DraftStateModel): Draft | null {
    return state.selectedDraft;
  }

  constructor() {}

  @Action(GetDrafts)
  getDraftsByState({ patchState }: StateContext<DraftStateModel>, response: { payload }) {
    patchState({ drafts: response.payload });
  }

  @Action(SetDraft)
  setDraftByState({ patchState }: StateContext<DraftStateModel>, response: { payload }) {
    patchState({ selectedDraft: response.payload });
  }

  @Action(AddDraft)
  addDraftByState({ getState, patchState }: StateContext<DraftStateModel>, response: { payload }) {
    let drafts = JSON.parse(JSON.stringify(getState())).drafts;
    drafts.push(response.payload);
    patchState({ drafts: drafts });
  }

  @Action(EditDraft)
  editDraftByState({ getState, patchState }: StateContext<DraftStateModel>, response: { payload }) {
    let drafts = JSON.parse(JSON.stringify(getState())).drafts;

    drafts = drafts.map((draft) => {
      if (draft.id === response.payload.id) return response.payload;
      else return draft;
    });

    patchState({ drafts: drafts });
  }

  @Action(DeleteDraft)
  deleteDraftByState({ getState, patchState }: StateContext<DraftStateModel>, response: { payload }) {
    let drafts = JSON.parse(JSON.stringify(getState())).drafts;
    drafts = drafts.filter((draft) => draft.id === response.payload.draftID);
    patchState({ drafts: drafts });
  }

  @Action(GenerateDraftPdf)
  generateDraftPdfByState() {}
}
