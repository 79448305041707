import { Pipe } from '@angular/core';

@Pipe({
  name: 'short',
})
export class ShortPipe {
  transform(val, args) {
    let shortText = '';

    if (args === undefined) {
      return val;
    }

    if (val.length > args) {
      val.split(' ').forEach(function (value, index) {
        if (index < args) {
          shortText = shortText + value.substring(0, 1) + '. ';
        } else {
          shortText = shortText + value + ' ';
        }
      });
      return shortText;
    } else {
      return val;
    }
  }
}
