import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sector-reports-list-section-delete-dialog',
  templateUrl: './sector-reports-list-section-delete-dialog.component.html',
  styleUrls: ['./sector-reports-list-section-delete-dialog.component.scss'],
})
export class SectorReportsListSectionDeleteDialogComponent {
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; desc: string },
    public dialogRef: MatDialogRef<SectorReportsListSectionDeleteDialogComponent>,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      delete: [''],
    });
  }

  close(): void {
    this.dialogRef.close(true);
  }
}
