<div class="container">
  <div>
    <p>Error: Acceso denegado</p>
  </div>
  <div>
    <p>Se ha producido un error al intentar acceder a la plataforma, por favor contacta con soporte técnico.</p>
  </div>
  <div>
    <button mat-raised-button>
      <a href="mailto:rmarin@brainy.technology" target="_blank">Contactar</a>
    </button>
  </div>
</div>
