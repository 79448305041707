<div
  id="sector-reports-search-container"
  class="container"
  [ngStyle]="{ height: loading ? 'auto' : 'calc(100vh - 60px)' }"
>
  <!-- <img src="assets/imgs/search_magnifying_glass.svg" />
  <div class="text">
    <p>Buscador de informes sectoriales</p>
    <p>Consulta resultados en todo el repositorio de documentos</p>
  </div> -->
  <!-- <div class="categories-container" *ngIf="!loading">
    <div class="header-container">
      <div class="header">
        <p>Grupos de tópicos</p>
        <p>Filtra los documentos en base a distintas categorías</p>
      </div>
      <button mat-flat-button color="primary" (click)="search()">Buscar</button>
    </div>
    <div class="categories">
      <div
        class="category"
        [ngClass]="{ selected: categoriesSelected.includes(category.id) }"
        *ngFor="let category of appService.categories_topics; let i = index"
        [style.--category-color]="i < rainbowColors.length ? rainbowColors[i] : rainbowColors[rainbowColors.length - 1]"
        (click)="selectCategory(category.id)"
      >
        <div class="circle"></div>
        <p class="name">{{ category.name }}</p>
      </div>
    </div>
  </div> -->
  <div style="display: flex; align-items: center; gap: 12px; padding: 16px" *ngIf="loading">
    <mat-spinner [diameter]="24"></mat-spinner>
    <p style="margin: 0">Cargando datos...</p>
  </div>
  <app-overview></app-overview>
</div>
