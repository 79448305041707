import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

const ALL_REGIONS = [
  {
    ccaa: 'ANDALUCÍA',
    provincias: [
      { id: '01', viewName: 'CCAA ANDALUCÍA' },
      { id: '0104', viewName: 'ALMERÍA' },
      { id: '0111', viewName: 'CÁDIZ' },
      { id: '0114', viewName: 'CÓRDOBA' },
      { id: '0118', viewName: 'GRANADA' },
      { id: '0121', viewName: 'HUELVA' },
      { id: '0123', viewName: 'JAEN' },
      { id: '0129', viewName: 'MÁLAGA' },
      { id: '0141', viewName: 'SEVILLA' },
    ],
  },
  {
    ccaa: 'ARAGÓN',
    provincias: [
      { id: '02', viewName: 'CCAA ARAGÓN' },
      { id: '0222', viewName: 'HUESCA' },
      { id: '0244', viewName: 'TERUEL' },
      { id: '0250', viewName: 'ZARAGOZA' },
    ],
  },
  {
    ccaa: 'PRINCIPADO DE ASTURIAS',
    provincias: [
      { id: '03', viewName: 'CCAA PRINCIPADO DE ASTURIAS' },
      { id: '0333', viewName: 'ASTURIAS' },
    ],
  },
  {
    ccaa: 'ILLES BALEARS',
    provincias: [
      { id: '04', viewName: 'CCAA ILLES BALEARS' },
      { id: '0407', viewName: 'ILLES BALEARS' },
    ],
  },
  {
    ccaa: 'CANARIAS',
    provincias: [
      { id: '05', viewName: 'CCAA CANARIAS' },
      { id: '0535', viewName: 'LAS PALMAS' },
      { id: '0538', viewName: 'SANTA CRUZ DE TENERIFE' },
    ],
  },
  {
    ccaa: 'CANTABRIA',
    provincias: [
      { id: '06', viewName: 'CCAA CANTABRIA' },
      { id: '0639', viewName: 'CANTABRIA' },
    ],
  },
  {
    ccaa: 'CASTILLA Y LEÓN',
    provincias: [
      { id: '07', viewName: 'CCAA CASTILLA Y LEÓN' },
      { id: '0705', viewName: 'AVILA' },
      { id: '0709', viewName: 'BURGOS' },
      { id: '0724', viewName: 'LEON' },
      { id: '0734', viewName: 'PALENCIA' },
      { id: '0737', viewName: 'SALAMANCA' },
      { id: '0740', viewName: 'SEGOVIA' },
      { id: '0742', viewName: 'SORIA' },
      { id: '0747', viewName: 'VALLADOLID' },
      { id: '0749', viewName: 'ZAMORA' },
    ],
  },
  {
    ccaa: 'CASTILLA-LA MANCHA',
    provincias: [
      { id: '08', viewName: 'CCAA CASTILLA-LA MANCHA' },
      { id: '0802', viewName: 'ALBACETE' },
      { id: '0813', viewName: 'CIUDAD REAL' },
      { id: '0816', viewName: 'CUENCA' },
      { id: '0819', viewName: 'GUADALAJARA' },
      { id: '0845', viewName: 'TOLEDO' },
    ],
  },
  {
    ccaa: 'CATALUÑA',
    provincias: [
      { id: '09', viewName: 'CCAA CATALUÑA' },
      { id: '0908', viewName: 'BARCELONA' },
      { id: '0917', viewName: 'GIRONA' },
      { id: '0925', viewName: 'LLEIDA' },
      { id: '0943', viewName: 'TARRAGONA' },
    ],
  },
  {
    ccaa: 'COMUNITAT VALENCIANA',
    provincias: [
      { id: '10', viewName: 'CCAA COMUNITAT VALENCIANA' },
      { id: '1003', viewName: 'ALICANTE' },
      { id: '1012', viewName: 'CASTELLÓN' },
      { id: '1046', viewName: 'VALENCIA' },
    ],
  },
  {
    ccaa: 'EXTREMADURA',
    provincias: [
      { id: '11', viewName: 'CCAA EXTREMADURA' },
      { id: '1106', viewName: 'BADAJOZ' },
      { id: '1110', viewName: 'CÁCERES' },
    ],
  },
  {
    ccaa: 'GALICIA',
    provincias: [
      { id: '12', viewName: 'CCAA GALICIA' },
      { id: '1215', viewName: 'A CORUÑA' },
      { id: '1227', viewName: 'LUGO' },
      { id: '1232', viewName: 'OURENSE' },
      { id: '1236', viewName: 'PONTEVEDRA' },
    ],
  },
  {
    ccaa: 'COMUNIDAD DE MADRID',
    provincias: [
      { id: '13', viewName: 'CCAA COMUNIDAD DE MADRID' },
      { id: '1328', viewName: 'MADRID' },
    ],
  },
  {
    ccaa: 'REGIÓN DE MURCIA',
    provincias: [
      { id: '14', viewName: 'CCAA REGIÓN DE MURCIA' },
      { id: '1430', viewName: 'MURCIA' },
    ],
  },
  {
    ccaa: 'COMUNIDAD FORAL DE NAVARRA',
    provincias: [
      { id: '15', viewName: 'CCAA COMUNIDAD FORAL DE NAVARRA' },
      { id: '1531', viewName: 'NAVARRA' },
    ],
  },
  {
    ccaa: 'PAIS VASCO',
    provincias: [
      { id: '16', viewName: 'CCAA PAIS VASCO' },
      { id: '1601', viewName: 'ALAVA' },
      { id: '1648', viewName: 'BIZKAIA' },
      { id: '1620', viewName: 'GIPUZKOA' },
    ],
  },
  {
    ccaa: 'LA RIOJA',
    provincias: [
      { id: '17', viewName: 'CCAA LA RIOJA' },
      { id: '1726', viewName: 'LA RIOJA' },
    ],
  },
  {
    ccaa: 'CIUDAD AUTONOMA DE CEUTA',
    provincias: [
      { id: '18', viewName: 'CIUDAD AUTONOMA DE CEUTA' },
      { id: '1851', viewName: 'CEUTA' },
    ],
  },
  {
    ccaa: 'CIUDAD AUTONOMA DE MELILLA',
    provincias: [
      { id: '19', viewName: 'CIUDAD AUTONOMA DE MELILLA' },
      { id: '1952', viewName: 'MELILLA' },
    ],
  },
];

@Component({
  selector: 'app-spain-regions-select',
  templateUrl: './spain-regions-select.component.html',
  styleUrls: ['./spain-regions-select.component.less'],
})
export class SpainRegionsSelectComponent implements OnInit {
  @Output() selection: EventEmitter<any> = new EventEmitter();
  formGroup: FormGroup;
  all_regions = ALL_REGIONS;

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      region: ['', Validators.required],
    });
  }

  get region() {
    return this.formGroup.get('region');
  }

  regionSelected($event) {
    const output = {
      type: $event.value.length === 2 ? 'ccaa' : 'provincia',
      name: $event.source.triggerValue,
      code: $event.value,
    };

    this.selection.emit(output);
  }
}
