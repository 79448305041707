import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';

import { AgendasMenuFilters, AgendasStateModel } from '../models/agendas.state.model';
import { ToggleAgendasMenuFilters } from './agendas.actions';

@Injectable({
  providedIn: 'root',
})
@State<AgendasStateModel>({
  name: 'agendas',
  defaults: {
    menuFilters: {
      opened: true,
    },
  },
})
export class AgendasState {
  @Selector()
  static menuFilters(state: AgendasStateModel): AgendasMenuFilters | null {
    return state.menuFilters;
  }

  constructor(
    private _dialogRef: MatDialog,
    private _snackbarStateService: SnackbarStateService,
    private _spinner: NgxSpinnerService
  ) {}

  @Action(ToggleAgendasMenuFilters)
  toggleAgendasMenuFilters({ getState, patchState }: StateContext<AgendasStateModel>) {
    let menuFilters = JSON.parse(JSON.stringify(getState())).menuFilters;
    menuFilters.opened = !menuFilters.opened;
    patchState({ menuFilters });
  }
}
