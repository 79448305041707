import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
  selector: 'ng-chart-radar',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.css'],
})
export class RadarChartComponent extends BaseChartComponent implements OnInit, AfterViewInit {
  @ViewChild('radar_container') radarcontainer: ElementRef;
  @Input() width: number = 0;
  @Input() height: number = 0;

  options: any;

  ngOnInit() {}

  getRequest() {
    return { uri: this.wconfig.config['uri'] };
  }

  refresh(request, response) {
    let series = [],
      indicator = [],
      max: number = 0;

    if (response && response.data) {
      let rdata = [...response.data],
        rheader = [...response.header],
        config = this.wconfig.config,
        start = config['slice'] && config['slice'][1] ? config['slice'][1] : rdata[0].length - 1,
        end = config['slice'] ? config['slice'][0] : 0;
      rheader =
        typeof rheader[0] != undefined && rheader[0].constructor.toString().match(/Array/) ? rheader[0] : rheader;
      for (var i = start; i >= end; --i) {
        let data = 0;
        for (var row = rdata.length - 1; row >= 0; --row) data += rdata[row][i];
        max = data > max ? data : max;
        series.push(data);
      }
      for (var i = start; i >= end; --i) {
        indicator.push({ name: rheader[i], max: max + max * 0.25 });
      }
      this.options['tooltip'] = {
        trigger: 'item',
      };

      this.options['radar'] = {
        name: {
          textStyle: {
            color: '#000',
          },
          formatter: function (value, indicator) {
            let w = 20;
            let t = value;
            let l = 0;
            let o = '';

            for (var tw of t) {
              if (l + tw.length >= w) {
                o += (l ? '\n' : '') + tw + ' ';
                l = tw.length + 1;
              } else {
                o += tw + ' ';
                l += tw.length + 1;
              }
            }
            return o;
          },
        },
        indicator: indicator,
      };

      this.options['series'] = [
        {
          type: 'radar',
          data: [{ value: series, name: this.wconfig.config['title'] }],
        },
      ];

      let f = () => {
        if (!this.echartsInstance) setTimeout(f, 500);
        else this.echartsInstance.setOption(this.options);
      };
      f();

      this.seriesLoaded.emit('radar');
    }
  }

  onChartInit(e) {
    this.echartsInstance = e;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
}
