<div class="wrapper" [formGroup]="form">
  <h2>Nuevo borrador</h2>
  <section *ngIf="!loadingEstimatedPrice">
    <div class="header">
      <h3>Temas</h3>
      <p>Selecciona los temas que quieres incluir para generar el borrador</p>
    </div>
    <div class="categories" formArrayName="checkboxes">
      <div
        class="category"
        [ngClass]="{ selected: checkboxes.at(i).value }"
        *ngFor="let search of data.searches; let i = index"
      >
        <mat-checkbox color="primary" [formControlName]="i">
          <p>
            {{ search.category }}
          </p>
          <p>
            <span>{{ estimatedPriceData[i].num_docs }}</span> noticias
          </p>
        </mat-checkbox>
        <p>
          <span>Coste de descarga: {{ estimatedPriceData[i].credits | number: '1.2-2' }} €</span>
        </p>
        <p>
          <span>Coste de disposición: {{ estimatedPriceData[i].provision | number: '1.2-2' }} €</span>
        </p>
      </div>
    </div>
  </section>
  <section>
    <div class="header">
      <h3>Coste estimado</h3>
      <mat-spinner [diameter]="24" *ngIf="loadingEstimatedPrice"></mat-spinner>
      <p style="font-weight: 400; display: flex; align-items: flex-end; gap: 6px" *ngIf="!loadingEstimatedPrice">
        <span>{{ estimatedPrice | number: '1.2-2' }}</span
        >€
      </p>
    </div>
  </section>
  <button
    mat-flat-button
    color="primary"
    [disabled]="loadingEstimatedPrice || generating || !hasAtLeastOneCheckboxChecked()"
    (click)="submit()"
  >
    <mat-spinner [diameter]="24" *ngIf="generating"></mat-spinner>
    Generar borrador
  </button>
</div>
