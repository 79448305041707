<div class="overlay" [ngStyle]="{ maxWidth: maxWidth + 'px' }" fxLayout="row wrap">
  <mat-icon (click)="destroy()" class="dismiss">close</mat-icon>
  <div class="mygridster">
    <app-chart-grid-container
      [wconfigs]="widgets"
      [gconfig]="grid"
      [title]="panel_config.title"
      [subtitle]="panel_config.subtitle"
    ></app-chart-grid-container>
  </div>
</div>
