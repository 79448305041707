import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipsisPipe } from './ellipsis.pipe';
import { HtmltextPipe } from './htmltext.pipe';
import { SafePipe } from './safe.pipe';
import { ShortPipe } from './short.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { MappingPipe } from './mapping.pipe';
import { MappingCatsPipe } from './mapping-cats.pipe';
import { MappingIDPipe } from './mapping-id.pipe';
import { PluralPipe } from './plural.pipe';
import { InitiativeNameMappingPipe } from './initiative-name-mapping.pipe';
import { MappingAllPipe } from './mapping-all.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    EllipsisPipe,
    SafePipe,
    ShortPipe,
    HtmltextPipe,
    ShortNumberPipe,
    MappingPipe,
    MappingCatsPipe,
    MappingIDPipe,
    PluralPipe,
    InitiativeNameMappingPipe,
    MappingAllPipe,
  ],
  exports: [
    EllipsisPipe,
    SafePipe,
    ShortPipe,
    HtmltextPipe,
    ShortNumberPipe,
    MappingPipe,
    MappingCatsPipe,
    MappingIDPipe,
    PluralPipe,
    InitiativeNameMappingPipe,
    MappingAllPipe,
  ],
})
export class PipesModule {}
