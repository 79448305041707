import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  constructor(private http: HttpClient) {}

  getNames(): Observable<any> {
    return of([
      'Andalucía',
      'Aragón',
      'Madrid',
      'Murcia',
      'Asturias',
      'Cantabria',
      'Castilla La Mancha',
      'Castilla y León',
      'Valencia',
      'Extremadura',
      'Catalunya',
      'Canarias',
      'Baleares',
      'La Rioja',
      'Galicia',
    ]);
  }
  provpub(): Observable<any> {
    return of({
      fuente: { '1940': 'BOE: Anuncios productos farmacéuticos' },
      provincia: {
        'A CORUÑA': { cc: '12', cp: '15', total: 0 },
        ALBACETE: { cc: '8', cp: '2', total: 0 },
        ALICANTE: { cc: '10', cp: '3', total: 2 },
        ALMERÍA: { cc: '1', cp: '4', total: 25 },
        ASTURIAS: { cc: '3', cp: '33', total: 6 },
        BADAJOZ: { cc: '11', cp: '6', total: 8 },
        BARCELONA: { cc: '9', cp: '8', total: 10 },
        BURGOS: { cc: '7', cp: '9', total: 2 },
        CANTABRIA: { cc: '6', cp: '39', total: 2 },
        CASTELLÓN: { cc: '10', cp: '12', total: 3 },
        CEUTA: { cc: '18', cp: '51', total: 0 },
        'CIUDAD REAL': { cc: '8', cp: '13', total: 9 },
        CUENCA: { cc: '8', cp: '16', total: 0 },
        CÁCERES: { cc: '11', cp: '10', total: 0 },
        CÁDIZ: { cc: '1', cp: '11', total: 1 },
        CÓRDOBA: { cc: '1', cp: '14', total: 24 },
        GIRONA: { cc: '9', cp: '17', total: 0 },
        GRANADA: { cc: '1', cp: '18', total: 33 },
        GUADALAJARA: { cc: '8', cp: '19', total: 0 },
        GUIPÚZCOA: { cc: '16', cp: '20', total: 0 },
        HUELVA: { cc: '1', cp: '21', total: 2 },
        HUESCA: { cc: '2', cp: '22', total: 0 },
        'ILLES BALEARS': { cc: '4', cp: '7', total: 5 },
        JAÉN: { cc: '1', cp: '23', total: 1 },
        'LA RIOJA': { cc: '17', cp: '26', total: 0 },
        'LAS PALMAS': { cc: '5', cp: '35', total: 32 },
        LEÓN: { cc: '7', cp: '24', total: 3 },
        LLEIDA: { cc: '9', cp: '25', total: 0 },
        LUGO: { cc: '   12', cp: '27', total: 0 },
        MADRID: { cc: ' 13', cp: '28', total: 165 },
        MALAGA: { cc: ' 1', cp: '29', total: 11 },
        MELILLA: { cc: '19', cp: '52', total: 0 },
        MURCIA: { cc: ' 14', cp: '30', total: 8 },
        NAVARRA: { cc: '15', cp: '31', total: 0 },
        OURENSE: { cc: '12', cp: '32', total: 1 },
        PALENCIA: { cc: '7', cp: '34', total: 0 },
        PONTEVEDRA: { cc: '12', cp: '36', total: 0 },
        SALAMANCA: { cc: '7', cp: '37', total: 1 },
        SEGOVIA: { cc: '7', cp: '40', total: 0 },
        SEVILLA: { cc: '1', cp: '41', total: 0 },
        SORIA: { cc: '7', cp: '42', total: 1 },
        TARRAGONA: { cc: '9', cp: '43', total: 1 },
        TENERIFE: { cc: '5', cp: '38', total: 6 },
        TERUEL: { cc: '2', cp: '44', total: 0 },
        TOLEDO: { cc: '8', cp: '45', total: 0 },
        VALENCIA: { cc: '10', cp: '46', total: 1 },
        VALLADOLID: { cc: '7', cp: '47', total: 1 },
        VIZCAYA: { cc: '16', cp: '48', total: 0 },
        ZAMORA: { cc: '7', cp: '49', total: 0 },
        ZARAGOZA: { cc: '2', cp: '50', total: 6 },
        ÁLAVA: { cc: '16', cp: '1', total: 0 },
        ÁVILA: { cc: '7', cp: '5', total: 0 },
      },
    });
  }
  geo(geojson) {
    return this.http.get<any>('/assets/geo/' + geojson + '.geojson');
  }
}
