import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OverviewComponent } from './components/overview/overview.component';
import { AlertsViewComponent } from './components/alerts-overview/alerts-overview.component';
import { SubItemsComponent } from './components/sub-items/sub-items.component';
import { PdfsComponent } from './components/pdfs/pdfs.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { MagazineComponent } from './components/magazine/magazine.component';
import { MagazineSettingsComponent } from './components/magazine/magazine-settings/magazine-settings.component';
import { MagazineDraftComponent } from './components/magazine/magazine-draft/magazine-draft.component';
import { MagazineViewComponent } from './components/magazine/magazine-view/magazine-view.component';
import { AgendaComponent } from './components/calendar/agenda.component';
import { AuthKeycloakGuard } from '@app/utils/auth-keycloak,guard';
import { VigilanciaCalendarPPComponent } from '../vigilancia/components/vigilancia-calendar-pp/vigilancia-calendar-pp.component';

export const DocumentsRoutes: Routes = [
  { path: '', component: OverviewComponent },
  { path: 'calendar', component: CalendarComponent },
  { path: 'calendarPp', component: VigilanciaCalendarPPComponent },
  {
    path: 'drafts',
    loadChildren: () =>
      import('../documents/components/regulatory-drafts/regulatory-drafts.module').then(
        (m) => m.RegulatoryDraftsModule
      ),
    canActivate: [AuthKeycloakGuard],
    data: { roles: [] },
  },
  { path: 'agendas', component: AgendaComponent },
  { path: 'magazine', component: MagazineComponent },
  { path: 'magazine/settings', component: MagazineSettingsComponent },
  { path: 'magazine/:id/settings', component: MagazineSettingsComponent },
  { path: 'magazine/:magazineID/draft', component: MagazineDraftComponent },
  { path: 'magazine/:magazineID/draft/:draftID', component: MagazineDraftComponent },
  { path: 'magazine/:magazineID/view/:draftID', component: MagazineViewComponent },
  { path: 'magazine/:magazineID/view/:draftID/:catId', component: MagazineViewComponent },
  { path: 'magazine/:magazineID/view/:draftID/:catId/:docId', component: MagazineViewComponent },
  { path: 'pdfs', component: PdfsComponent },
  { path: 'open/:id', component: OverviewComponent },
  { path: 'open/:id/:searchtext', component: OverviewComponent },
  { path: 'alerts', component: AlertsViewComponent },
  { path: ':code', component: OverviewComponent },
  { path: 'subitems/:id', component: SubItemsComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(DocumentsRoutes)],
  exports: [RouterModule],
})
export class DocumentsRoutingModule { }
