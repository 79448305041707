<form [formGroup]="filtersForm" style="margin-bottom: 120px">
  <div class="header">
    <p>Filtros</p>
    <button mat-stroked-button color="primary" (click)="resetFilters()">Restablecer filtros</button>
  </div>
  <p class="loader" *ngIf="applyingFilters">
    <mat-spinner [diameter]="20" *ngIf="loading || applyingFilters"></mat-spinner>
    Aplicando filtros...
  </p>
  <div>
    <angular2-multiselect
      fixSearchBug
      fixAccents
      class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
      name="provinces"
      formControlName="provinces"
      [data]="dropdownProvinciasList"
      [(ngModel)]="selectedProvinciasItems"
      [settings]="dropdownProvinciasSettings"
    ></angular2-multiselect>
    <span style="display: flex; margin-top: 4px; font-size: 10.5px"
      >Lugar de ejecución por demarcación territorial (NUTS)</span
    >
  </div>
  <app-tenders-search-filters-cpvs
    [selectedSectoresInput]="selectedSectoresInput"
    (selectedSectoresOutput)="setSelectedSectores($event)"
  ></app-tenders-search-filters-cpvs>
  <app-tenders-search-filters-organism
    [selectedSectoresInput]="selectedOrganismoInput"
    (selectedSectoresOutput)="setSelectedOrganismo($event)"
  ></app-tenders-search-filters-organism>
  <mat-form-field>
    <mat-label>Fecha de actualización</mat-label>
    <mat-date-range-input [formGroup]="filtersDateRangeForm" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="dd/mm/aaaa" />
      <input matEndDate formControlName="end" placeholder="dd/mm/aaaa" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Expediente</mat-label>
    <input matInput formControlName="expediente" placeholder="Buscar por n. expediente" />
  </mat-form-field>
  <!-- <mat-form-field>
    <mat-label>Objeto</mat-label>
    <input matInput formControlName="objeto" placeholder="Buscar por objeto" />
    <mat-icon
      matSuffix
      style="opacity: 0.5; font-size: 18px; cursor: pointer"
      (click)="$event.stopPropagation(); showOperatorsDialog()"
    >
      help
    </mat-icon>
  </mat-form-field> -->

  <mat-form-field class="example-chip-list">
    <mat-chip-list #chipList aria-label="Search selection">
      <mat-chip
        *ngFor="let chip of objectChipFormArray.controls; let i = index"
        [selectable]="true"
        [removable]="true"
        (click)="editObjeto(i)"
        (keydown.backspace)="removeObjeto(i)"
        [class]="'type-' + chip.get('type')?.value"
        [matTooltip]="
          chip.get('type')?.value === 'operator'
            ? chip.get('name')?.value === 'AND'
              ? 'Cambiar a operador OR'
              : 'Cambiar a operador AND'
            : undefined
        "
        matTooltipClass="tooltip-bigger-font-size"
      >
        <ng-container *ngIf="!chip.get('editing')?.value || chip.get('type')?.value === 'operator'; else editMode">
          {{ chip.get('name')?.value }}
          {{ removable }}
          <mat-icon matChipRemove *ngIf="true" (click)="removeObjeto(i)">cancel</mat-icon>
        </ng-container>
        <ng-template #editMode>
          <form [formGroup]="chip" (ngSubmit)="$event.preventDefault(); saveObjeto(i)">
            <input
              type="text"
              formControlName="name"
              (keydown.backspace)="$event.stopPropagation()"
              (keydown.space)="$event.stopPropagation()"
              (blur)="saveObjeto(i)"
            />
            <button mat-button type="submit">Guardar</button>
          </form>
        </ng-template>
      </mat-chip>
      <input
        class="input-search"
        placeholder="Objeto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (keydown.enter)="addOperator()"
        (matChipInputTokenEnd)="addObjeto($event)"
      />
      <mat-icon
        matSuffix
        style="opacity: 0.5; font-size: 18px; cursor: pointer"
        (click)="$event.stopPropagation(); showOperatorsDialog()"
      >
        help
      </mat-icon>
    </mat-chip-list>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Importe mínimo en €</mat-label>
    <input
      matInput
      type="number"
      numeric
      min="0"
      step="1"
      formControlName="importe_minimo"
      (keypress)="numberOnly($event)"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Importe máximo en €</mat-label>
    <input
      matInput
      type="number"
      numeric
      min="0"
      step="1"
      formControlName="importe_maximo"
      (keypress)="numberOnly($event)"
    />
  </mat-form-field>
  <div
    class="actions"
    style="
      position: fixed;
      bottom: 0;
      background: white;
      padding: 16px 6px;
      font-size: 15px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding-bottom: 16px;
      width: calc(300px - 16px);
    "
  >
    <button
      mat-flat-button
      color="primary"
      style="width: 100%"
      [disabled]="loading || applyingFilters"
      (click)="applyFilters()"
    >
      <mat-spinner [diameter]="20" *ngIf="loading || applyingFilters"></mat-spinner>
      Aplicar filtros
    </button>
    <button mat-stroked-button style="width: 100%" (click)="tendersStateService.toggleTendersMenuFilters()">
      <mat-icon style="font-size: 20px; width: 20px; height: 20px">keyboard_double_arrow_right</mat-icon>
      Ocultar menú de filtros
    </button>
  </div>
</form>
