import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import _moment from 'moment';
import { Observable, of } from 'rxjs';
import {
  AlertsGetRequest,
  AlertsGetResponse,
  AlertPostRequest,
  Cpv,
  Import,
  Province,
  Search,
  SearchPostRequest,
  Tender,
  TendersGetRequest,
  TendersGetResponse,
  Alert,
  TendersFavoritesGetRequest,
  TenderSearchsGetRequest,
  TenderSearchsGetResponse,
  TenderSearch,
  AlertUpdateRequest,
} from '../models/tender';
import { environment } from '@env/environment';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TendersService {
  constructor(private _http: HttpClient) {}

  getTenders(request: TendersGetRequest): Observable<TendersGetResponse> {
    return this._http.post<TendersGetResponse>(
      `${environment.tendersSearch}?page=${request.page}&size=${request.size}`,
      request
    );
  }

  getTender(request: number): Observable<Tender> {
    return this._http.get<Tender>(`${environment.tenders}/${request}`);
  }

  getTendersFavorites(request: TendersFavoritesGetRequest): Observable<TendersGetResponse> {
    return this._http.get<TendersGetResponse>(
      `${environment.tendersFavorites}?page=${request.page}&size=${request.size}`
    );
  }

  updateTenderFavoriteAdd(request: number): Observable<void> {
    return this._http.post<void>(`${environment.tendersFavorites}/${request}`, {});
  }

  updateTenderFavoriteDelete(request: number): Observable<void> {
    return this._http.delete<void>(`${environment.tendersFavorites}/${request}`);
  }

  postSearch(request: SearchPostRequest): Observable<Search> {
    return this._http.post<Search>(environment.tendersSearchs, request);
  }

  getProvinces(): Observable<Province[]> {
    return this._http.get<Province[]>(environment.tendersProvinces);
  }

  getCpvs(): Observable<Cpv[]> {
    return this._http.get<Cpv[]>(environment.tendersCpvs);
  }

  getCpv(code: string): Observable<Cpv> {
    return of(this.getFakeCpv()).pipe(delay(1000));
    // return this._http.get<Cpv>(`${environment.tendersCpvs}/${id}`);
  }

  getCpvsDetailItems(request: { code: string }): Observable<{ items: { code: string; descripcion: string }[] }> {
    return this._http.post<any>(`${environment.tendersCpvs}/search?page=1&size=999`, request);
  }

  getOrganoDetailItems(request: { organo: string }): Observable<any> {
    return this._http.post<any>(`${environment.tendersOrganos}/search?page=1&size=999`, request);
  }

  getImports(): Observable<Import[]> {
    return this._http.get<Import[]>(environment.tendersImports);
  }

  getAlerts(request: AlertsGetRequest): Observable<AlertsGetResponse> {
    return this._http.get<AlertsGetResponse>(`${environment.tendersAlerts}?page=${request.page}&size=${request.size}`);
  }

  deleteAlert(request: number): Observable<AlertsGetResponse> {
    return this._http.delete<AlertsGetResponse>(`${environment.tendersAlerts}/${request}`);
  }

  postAlert(request: AlertPostRequest): Observable<Alert> {
    return this._http.post<Alert>(`${environment.tendersAlerts}`, request);
  }

  updateAlert(request: AlertUpdateRequest, id: number): Observable<Alert> {
    return this._http.put<Alert>(`${environment.tendersAlerts}/${id}`, request);
  }

  getTenderSearchs(request: TenderSearchsGetRequest): Observable<TenderSearchsGetResponse> {
    return this._http.get<TenderSearchsGetResponse>(
      `${environment.tendersSearchs}?page=${request.page}&size=${request.size}`
    );
  }

  deleteTenderSearch(request: number): Observable<TenderSearch> {
    return this._http.delete<TenderSearch>(`${environment.tendersSearchs}/${request}`);
  }

  getFakeCpv(): Cpv {
    return {
      code: '03',
      descripcion: '030 Productos de la agricultura, ganadería, pesca, silvicultura y productos afines.',
    };
  }
}
