<div class="container">
  <p class="title">Alertas</p>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Crear alerta</mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="filtersForm" *ngIf="!loadingFilters">
        <mat-form-field>
          <mat-label>Título</mat-label>
          <input matInput formControlName="titulo" placeholder="Título de la alerta" />
        </mat-form-field>
        <span *ngIf="filters">
          <div class="section" *ngFor="let filter of filters.filters; let i = index" [formArrayName]="filter.attr">
            <span *ngIf="i < filters.filters.length - 2">
              <span
                *ngIf="
                  filter.attr !== 'comunidades' && filter.attr !== 'provincias' && filter.attr !== 'sector_economico'
                "
              >
                <p class="title">{{ filter.seccion }}</p>
                <div
                  class="checkboxes"
                  *ngFor="let control of filtersForm.controls[filter.attr].controls"
                  [formGroup]="castToFormGroup(control)"
                >
                  <input type="checkbox" formControlName="checked" />
                  <mat-label>{{ control.get('label')?.value }}</mat-label>
                </div>
              </span>
            </span>
          </div>

          <span class="section section-dropdowns">
            <div *ngFor="let filter of filters.filters; let i = index">
              <span *ngIf="i < filters.filters.length - 2">
                <span *ngIf="filter.attr === 'comunidades'">
                  <angular2-multiselect
                    fixSearchBug
                    fixAccents
                    class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
                    name="comunidades"
                    [formControlName]="filter.attr + 'Dropdown'"
                    [data]="dropdownComunidadesList"
                    [(ngModel)]="selectedComunidadesItems"
                    [settings]="dropdownComunidadesSettings"
                  ></angular2-multiselect>
                </span>
                <span *ngIf="filter.attr === 'provincias'">
                  <angular2-multiselect
                    fixSearchBug
                    fixAccents
                    class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
                    name="provincias"
                    [formControlName]="filter.attr + 'Dropdown'"
                    [data]="dropdownProvinciasList"
                    [(ngModel)]="selectedProvinciasItems"
                    [settings]="dropdownProvinciasSettings"
                  ></angular2-multiselect>
                </span>
                <span *ngIf="filter.attr === 'sector_economico'">
                  <angular2-multiselect
                    fixSearchBug
                    fixAccents
                    class="angular2-multiselect-search angular2-multiselect-search-contexts angular2-multiselect-auto-width c-token-ceoe panel-always-at-top"
                    name="sector_economico"
                    [formControlName]="filter.attr + 'Dropdown'"
                    [data]="dropdownSectorEconomicoList"
                    [(ngModel)]="selectedSectorEconomicoItems"
                    [settings]="dropdownSectorEconomicoSettings"
                  ></angular2-multiselect>
                </span>
              </span>
            </div>
          </span>

          <div class="section">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>Filtros avanzados</mat-panel-title>
                </mat-expansion-panel-header>
                <div
                  class="section"
                  *ngFor="let filter of filters.filters; let i = index"
                  [formArrayName]="filter.attr"
                >
                  <span *ngIf="i >= filters.filters.length - 2">
                    <p class="title">{{ filter.seccion }}</p>
                    <div
                      class="checkboxes"
                      *ngFor="let control of filtersForm.controls[filter.attr].controls"
                      [formGroup]="castToFormGroup(control)"
                    >
                      <input type="checkbox" formControlName="checked" />
                      <mat-label>{{ control.get('label')?.value }}</mat-label>
                    </div>
                  </span>
                </div>
                <mat-form-field class="section">
                  <mat-label>Fecha de publicación</mat-label>
                  <mat-date-range-input [formGroup]="filtersPublishmentDateRangeForm" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="dd/mm/aaaa" />
                    <input matEndDate formControlName="end" placeholder="dd/mm/aaaa" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-form-field class="section">
                  <mat-label>Fecha de finalización del periodo de solicitud</mat-label>
                  <mat-date-range-input [formGroup]="filtersFinalizationDateRangeForm" [rangePicker]="picker2">
                    <input matStartDate formControlName="start" placeholder="dd/mm/aaaa" />
                    <input matEndDate formControlName="end" placeholder="dd/mm/aaaa" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker2></mat-date-range-picker>
                </mat-form-field>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </span>

        <button mat-flat-button color="primary" [disabled]="loading" (click)="saveAlert()">
          <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
          Crear alerta
        </button>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- <table mat-table [dataSource]="(subventionsStateService.alerts$ | async).items">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Título</th>
      <td mat-cell *matCellDef="let row">
        {{ row.titulo }}
      </td>
    </ng-container>
    <ng-container matColumnDef="filters">
      <th mat-header-cell *matHeaderCellDef>Filtros</th>
      <td mat-cell *matCellDef="let row">
        <div class="filters">
          <mat-accordion *ngIf="row.busqueda.estado && row.busqueda.estado.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Estado ({{ row.busqueda.estado.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let estado of row.busqueda.estado">• {{ getLabelById('estado', estado) }}</p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.presupuesto.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Presupuesto ({{ row.busqueda.presupuesto.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let presupuesto of row.busqueda.presupuesto">
                • {{ getLabelById('presupuesto', presupuesto) }}
              </p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.organismo_convocante.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Organismos convocantes ({{ row.busqueda.organismo_convocante.length }})
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let organismo_convocante of row.busqueda.organismo_convocante">
                • {{ getLabelById('organismo_convocante', organismo_convocante) }}
              </p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.beneficiarios.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Beneficiarios ({{ row.busqueda.beneficiarios.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let beneficiario of row.busqueda.beneficiarios">
                • {{ getLabelById('beneficiarios', beneficiario) }}
              </p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.fondos.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Fondos ({{ row.busqueda.fondos.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let fondo of row.busqueda.fondos">• {{ getLabelById('fondos', fondo) }}</p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.comunidades.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Comunidades ({{ row.busqueda.comunidades.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let comunidades of row.busqueda.comunidades">
                • {{ getLabelById('comunidades', comunidades) }}
              </p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.provincias.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Provincias ({{ row.busqueda.provincias.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let provincias of row.busqueda.provincias">• {{ getLabelById('provincias', provincias) }}</p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.sector_economico.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Sector económico ({{ row.busqueda.sector_economico.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let sector_economico of row.busqueda.sector_economico">
                • {{ getLabelById('sector_economico', sector_economico) }}
              </p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.instrumento_ayuda.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Tipo ayuda ({{ row.busqueda.instrumento_ayuda.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let instrumento_ayuda of row.busqueda.instrumento_ayuda">
                • {{ getLabelById('instrumento_ayuda', instrumento_ayuda) }}
              </p>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="row.busqueda.finalidad.length > 0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Área de gasto ({{ row.busqueda.finalidad.length }}) </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let finalidad of row.busqueda.finalidad">• {{ getLabelById('finalidad', finalidad) }}</p>
            </mat-expansion-panel>
          </mat-accordion>
          <div
            class="filter"
            *ngIf="
              row.busqueda.fecha_publicacion_desde &&
              row.busqueda.fecha_publicacion_hasta &&
              row.busqueda.fecha_publicacion_desde !== 'Fecha inválida' &&
              row.busqueda.fecha_publicacion_hasta !== 'Fecha inválida'
            "
          >
            Fecha de publicación:
            <p>
              {{ row.busqueda.fecha_publicacion_desde ? row.busqueda.fecha_publicacion_desde : 'X' }}
              <span>-</span>
              {{ row.busqueda.fecha_publicacion_hasta ? row.busqueda.fecha_publicacion_hasta : 'X' }}
            </p>
          </div>
          <div
            class="filter"
            *ngIf="
              row.busqueda.fecha_finalizacion_desde &&
              row.busqueda.fecha_finalizacion_hasta &&
              row.busqueda.fecha_finalizacion_desde !== 'Fecha inválida' &&
              row.busqueda.fecha_finalizacion_hasta !== 'Fecha inválida'
            "
          >
            Fecha de finalización:
            <p>
              {{ row.busqueda.fecha_finalizacion_desde ? row.busqueda.fecha_finalizacion_desde : 'X' }}
              <span>-</span>
              {{ row.busqueda.fecha_finalizacion_hasta ? row.busqueda.fecha_finalizacion_hasta : 'X' }}
            </p>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="tools">
      <th mat-header-cell *matHeaderCellDef>Acciones</th>
      <td mat-cell *matCellDef="let row">
        <a mat-icon-button [matTooltip]="'Lanzar búsqueda'" (click)="search(row)">
          <mat-icon>search</mat-icon>
        </a>
        <a mat-icon-button [matTooltip]="'Editar'" (click)="edit(row)">
          <mat-icon>edit</mat-icon>
        </a>
        <a mat-icon-button [matTooltip]="'Eliminar'" (click)="delete(row)">
          <mat-icon>delete</mat-icon>
        </a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <th mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
      <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
      <p style="margin: 0" *ngIf="!loading">No se han encontrado datos</p>
    </th>
  </table> -->

  <table mat-table [dataSource]="(subventionsStateService.alerts$ | async).items">
    <ng-container matColumnDef="subventions">
      <th mat-header-cell *matHeaderCellDef>Alertas</th>
      <td mat-cell *matCellDef="let row">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="align-items: center"> {{ row.titulo }} </mat-panel-title>
              <div class="actions">
                <a mat-icon-button [matTooltip]="'Lanzar búsqueda'" (click)="search(row); $event.stopPropagation()">
                  <mat-icon>search</mat-icon>
                </a>
                <a mat-icon-button [matTooltip]="'Editar'" (click)="edit(row); $event.stopPropagation()">
                  <mat-icon>edit</mat-icon>
                </a>
                <a mat-icon-button [matTooltip]="'Eliminar'" (click)="delete(row); $event.stopPropagation()">
                  <mat-icon>delete</mat-icon>
                </a>
              </div>
            </mat-expansion-panel-header>
            <div class="content">
              <div class="filters">
                <mat-accordion *ngIf="row.busqueda.estado && row.busqueda.estado.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Estado ({{ row.busqueda.estado.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let estado of row.busqueda.estado">• {{ getLabelById('estado', estado) }}</p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.presupuesto.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Presupuesto ({{ row.busqueda.presupuesto.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let presupuesto of row.busqueda.presupuesto">
                      • {{ getLabelById('presupuesto', presupuesto) }}
                    </p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.organismo_convocante.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Organismos convocantes ({{ row.busqueda.organismo_convocante.length }})
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let organismo_convocante of row.busqueda.organismo_convocante">
                      • {{ getLabelById('organismo_convocante', organismo_convocante) }}
                    </p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.beneficiarios.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Beneficiarios ({{ row.busqueda.beneficiarios.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let beneficiario of row.busqueda.beneficiarios">
                      • {{ getLabelById('beneficiarios', beneficiario) }}
                    </p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.fondos.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Fondos ({{ row.busqueda.fondos.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let fondo of row.busqueda.fondos">• {{ getLabelById('fondos', fondo) }}</p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.comunidades.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Comunidades ({{ row.busqueda.comunidades.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let comunidades of row.busqueda.comunidades">
                      • {{ getLabelById('comunidades', comunidades) }}
                    </p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.provincias.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Provincias ({{ row.busqueda.provincias.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let provincias of row.busqueda.provincias">
                      • {{ getLabelById('provincias', provincias) }}
                    </p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.sector_economico.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Sector económico ({{ row.busqueda.sector_economico.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let sector_economico of row.busqueda.sector_economico">
                      • {{ getLabelById('sector_economico', sector_economico) }}
                    </p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.instrumento_ayuda.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Tipo ayuda ({{ row.busqueda.instrumento_ayuda.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let instrumento_ayuda of row.busqueda.instrumento_ayuda">
                      • {{ getLabelById('instrumento_ayuda', instrumento_ayuda) }}
                    </p>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="row.busqueda.finalidad.length > 0">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> Área de gasto ({{ row.busqueda.finalidad.length }}) </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let finalidad of row.busqueda.finalidad">• {{ getLabelById('finalidad', finalidad) }}</p>
                  </mat-expansion-panel>
                </mat-accordion>
                <div
                  class="filter"
                  *ngIf="
                    row.busqueda.fecha_publicacion_desde &&
                    row.busqueda.fecha_publicacion_hasta &&
                    row.busqueda.fecha_publicacion_desde !== 'Fecha inválida' &&
                    row.busqueda.fecha_publicacion_hasta !== 'Fecha inválida'
                  "
                >
                  Fecha de publicación:
                  <p>
                    {{ row.busqueda.fecha_publicacion_desde ? row.busqueda.fecha_publicacion_desde : 'X' }}
                    <span>-</span>
                    {{ row.busqueda.fecha_publicacion_hasta ? row.busqueda.fecha_publicacion_hasta : 'X' }}
                  </p>
                </div>
                <div
                  class="filter"
                  *ngIf="
                    row.busqueda.fecha_finalizacion_desde &&
                    row.busqueda.fecha_finalizacion_hasta &&
                    row.busqueda.fecha_finalizacion_desde !== 'Fecha inválida' &&
                    row.busqueda.fecha_finalizacion_hasta !== 'Fecha inválida'
                  "
                >
                  Fecha de finalización:
                  <p>
                    {{ row.busqueda.fecha_finalizacion_desde ? row.busqueda.fecha_finalizacion_desde : 'X' }}
                    <span>-</span>
                    {{ row.busqueda.fecha_finalizacion_hasta ? row.busqueda.fecha_finalizacion_hasta : 'X' }}
                  </p>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <th mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
      <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
      <p style="margin: 0" *ngIf="!loading">No se han encontrado datos</p>
    </th>
  </table>

  <mat-paginator [pageIndex]="pageIndex" pageSize="30" (page)="pageEvent($event)" fixed></mat-paginator>
</div>
