import { Injectable } from '@angular/core';
import { State, Selector, Action } from '@ngxs/store';
import { SnackbarStateModel } from '../models/snackbar.state.model';
import { Show } from './snackbar.actions';

@Injectable({
  providedIn: 'root',
})
@State<SnackbarStateModel>({
  name: 'snackbar',
  defaults: {
    message: null,
    time: 5000,
    dismissable: null,
  },
})
export class SnackbarState {
  @Selector()
  static message(state: SnackbarStateModel): string | null {
    return state.message;
  }

  @Selector()
  static time(state: SnackbarStateModel): number | null {
    return state.time;
  }

  @Selector()
  static dismissable(state: SnackbarStateModel): string | null {
    return state.dismissable;
  }

  constructor() {}

  @Action(Show)
  show() {}
}
