import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoadingService {
  loading = new EventEmitter<boolean>();

  constructor() {}

  setLoading(status: boolean) {
    this.loading.emit(status);
  }
}
