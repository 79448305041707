import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Router } from '@angular/router';
import { DraftState } from './draft.state';
import { Draft } from '../models/draft.state.model';
import { AddDraft, SetDraft, EditDraft, DeleteDraft, GenerateDraftPdf } from './draft.actions';
import { DraftService } from '@app/services/draft.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class DraftStateService {
  @Select(DraftState.drafts)
  public drafts$: Observable<Draft[]>;

  @SelectSnapshot(DraftState.drafts)
  public drafts: Draft[];

  @Select(DraftState.selectedDraft)
  public selectedDraft$: Observable<Draft>;

  @SelectSnapshot(DraftState.selectedDraft)
  public selectedDraft: Draft;

  constructor(
    private _snackbarStateService: SnackbarStateService,
    private _draftService: DraftService,
    private _router: Router,
    private _matDialog: MatDialog
  ) {}

  @Dispatch()
  public getDraft = (dossier_id: number, draft_id: number) => {
    let payload = { dossier_id: dossier_id, draft_id: draft_id };
    return this._draftService.getDraft(payload).pipe(
      map((response: any) => new SetDraft(response.data)),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public setDraft = (Draft: Draft) => {
    return new SetDraft(Draft);
  };

  @Dispatch()
  public addDraft = (payload) => {
    return this._draftService.newDraft(payload).pipe(
      map(() => new AddDraft(payload)),
      finalize(() => {
        // this.getDrafts();
        this._snackbarStateService.show('Borrador creado', 5000, 'cerrar');
        this._router.navigate(['clipping', 'magazine']);
      }),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public editDraft = (payload) => {
    return this._draftService.editDraft(payload).pipe(
      map((response) => new EditDraft(payload)),
      finalize(() => {
        // this._router.navigate(['clipping', 'magazine', payload.dossier_id, 'draft', payload.id ]);
        this._matDialog.closeAll();
        // this.getDraft(payload.dossier_id, payload.id);
        this._snackbarStateService.show('Borrador actualizado', 5000, 'cerrar');
      }),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public deleteDraft = (dossier_id: number, draft_id: number) => {
    let payload = { dossier_id: dossier_id, draft_id: draft_id };
    return this._draftService.deleteDraft(payload).pipe(
      map((response) => {
        return new DeleteDraft(payload);
      }),
      finalize(() => {
        this._snackbarStateService.show('Borrador eliminado', 5000, 'cerrar');
        this._router.navigate(['clipping', 'magazine']);
      }),
      catchError((err) => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public generateDraftPdf = (
    dossier_id: number,
    draft_id: number,
    covers_date: any,
    position_covers_date: 'start' | 'end',
    send_pdf: boolean,
    send_clipping: boolean
  ) => {
    let payload = {
      dossier_id: dossier_id,
      draft_id: draft_id,
      covers_date,
      position_covers_date,
      send_pdf,
      send_clipping,
    };
    return this._draftService.generateDraftPdf(payload).pipe(
      map(() => {
        this._snackbarStateService.show(
          'Generando borrador, recibirá el enlace en su correo electrónico en unos minutos.',
          10000,
          'cerrar'
        );
        return new GenerateDraftPdf(payload);
      }),
      finalize(() => {
        this._router.navigate(['clipping', 'magazine']);
      }),
      catchError((err) => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };
}
