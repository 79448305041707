import { Component, OnInit } from '@angular/core';
import { AppStateService } from '@app/state/app/app.service';
import { MatDialog } from '@angular/material/dialog';
import { MagazineStateService } from '../../store/magazine/magazine.service';
import { UIStatus } from '@app/state/models/app.state.model';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { MagazineCreditsComponent } from './magazine-credits/magazine-credits.component';
import { AuthService } from '@app/services/auth.service';
import { DraftService } from '@app/services/draft.service';
import moment from 'moment';

@Component({
  selector: 'app-magazine',
  templateUrl: './magazine.component.html',
  styleUrls: ['./magazine.component.scss'],
})
export class MagazineComponent implements OnInit {
  readonly LOGOURL: string = environment.logoApp;

  uiStatus = UIStatus;
  isAdmin = false;

  constructor(
    public magazineService: MagazineStateService,
    public appStateService: AppStateService,
    public dialog: MatDialog,
    private draftService: DraftService,
    private authService: AuthService,
    private _router: Router
  ) {
    this.appStateService.addTitle('Revistas de prensa');
  }

  async ngOnInit() {
    this.appStateService.showTitle();
    this.appStateService.hideDates();
    this.appStateService.hideSearchText();
    this.appStateService.hideSubForm();
    this.magazineService.getMagazines();

    const kcToken = await this.authService.getTokenInfo();
    this.isAdmin = kcToken.user.isadmin_press === 'true';

    if (!this.draftService.cedroTokenFlowData$.value) {
      this.draftService.getCedroToken({}).subscribe((dataToken) => {
        (document.getElementById('cedroUserId') as HTMLInputElement).value = dataToken.data.user_id;

        this.draftService
          .cedroUserAccess({
            token: dataToken.data.trace_token,
            date: moment.utc(new Date()).format('YYYY-MM-DD HH:mm'),
            userID: dataToken.data.user_id,
            user_type: dataToken.data.user_type,
          })
          .subscribe(() => {
            this.draftService.cedroTokenFlowData$.next(dataToken);
          });
      });
    }
  }

  newMagazine() {
    this._router.navigate(['clipping', 'magazine', 'settings']);
  }

  editMagazine(id: number) {
    this._router.navigate(['clipping', 'magazine', id, 'settings']);
  }

  newDraft(id: number) {
    // this.magazineService.setMagazine(this.magazineService.magazines.filter((magazine) => magazine.id === id)[0]);
    // this._router.navigate(['clipping', 'magazine', id, 'draft']);
  }

  editDraft(event) {
    this.magazineService.setMagazine(
      this.magazineService.magazines.filter((magazine) => magazine.id === event.magazine)[0]
    );
    this._router.navigate(['clipping', 'magazine', event.magazine, 'draft', event.draft]);
  }

  showStats(event) {
    this.magazineService.setMagazine(this.magazineService.magazines.filter((magazine) => magazine.id === event)[0]);
    this._router.navigate(['clipping', 'magazine', event, 'stats']);
  }

  viewCredits(): void {
    this.dialog.open(MagazineCreditsComponent, {
      width: '600px',
    });
  }
}
