import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { AppStateService } from '@app/state/app/app.service';
import { filter, map } from 'rxjs/operators';
import { TendersStateService } from '../store/tenders/tenders.service';

interface SidenavItem {
  name: string;
  icon: string;
  path: string;
  selected: boolean;
}

@Component({
  selector: 'app-sector-reports',
  templateUrl: './sector-reports.component.html',
  styleUrls: ['./sector-reports.component.scss'],
})
export class SectorReportsComponent implements OnInit, OnDestroy {
  sidenavItems: SidenavItem[] = [
    {
      name: 'INE',
      icon: 'bar_chart',
      path: 'ine',
      selected: false,
    },
  ];
  url = this.router.url;

  constructor(
    public appStateService: AppStateService,
    public authStateService: AuthStateService,
    public tendersStateService: TendersStateService,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter((event: unknown): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.slice(1))
      )
      .subscribe(() => {
        this.url = this.router.url;
        this.getSelectedItem();
      });

    this.appStateService.addTitle('Informes sectoriales');

    if (this.appStateService.sidemenuOpened) {
      this.appStateService.toggleSideMenu();
    }

    this.getSelectedItem();
  }

  ngOnInit(): void {
    // this.tendersStateService.updateTendersFilters({
    //   provincias: [],
    //   publicacion_desde: '01/01/2023',
    //   publicacion_hasta: null,
    //   cpvs: [],
    //   expediente: '',
    //   objeto: '',
    //   organismo: '',
    //   importe_minimo: null,
    //   importe_maximo: null,
    // });

    this.enableTenderStyles();
  }

  ngOnDestroy(): void {
    this.disableTenderStyles();
  }

  getSelectedItem(): void {
    const url = this.router.url.split('/').splice(2);
    this.selectItem(
      this.sidenavItems.find((si) => si.path.startsWith((url.length > 0 ? url[0].split('?')[0] : '') || ''))!
    );
  }

  selectItem(sidenavItem: SidenavItem): void {
    this.sidenavItems.map((si) => (si.selected = false));
    sidenavItem.selected = true;
  }

  enableTenderStyles(): void {
    const toolbar = document.getElementsByTagName('mat-toolbar')[0];
    toolbar.classList.add('mat-toolbar-tenders');
  }

  disableTenderStyles(): void {
    const toolbar = document.getElementsByTagName('mat-toolbar')[0];
    toolbar.classList.remove('mat-toolbar-tenders');
  }
}
