import { Spider } from '../models/spider.state.model';

export class GetImportantSpiders {
  static readonly type = '[GetImportantSpiders] action';

  constructor(public payload: Spider[]) {}
}

export class GetLatestSpiders {
  static readonly type = '[GetLatestSpiders] action';

  constructor(public payload: Spider[]) {}
}
