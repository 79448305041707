import { Pipe, PipeTransform } from '@angular/core';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { AppStateService } from '@app/state/app/app.service';
import { SpiderMappings } from '@app/state/models/app.state.model';

@Pipe({
  name: 'mapping',
})
export class MappingPipe {
  constructor(private _authStateService: AuthStateService) {}

  transform(value: number, args?: any) {
    let mapping;

    this._authStateService.userConfig.config.Documentos.SpiderGroups.map((spider_group) => {
      let spider = spider_group.spiders.filter((spider) => spider.id == value);

      if (spider[0]) {
        mapping = spider[0];
      }
    });

    if (mapping) return mapping.name;
    else return value;
  }
}
