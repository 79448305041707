import { Pipe } from '@angular/core';

@Pipe({
  name: 'plural',
})
export class PluralPipe {
  transform(val) {
    if (val !== 1) {
      return 's';
    }
  }
}
