<div class="container">
  <p class="title">Búsquedas guardadas</p>
  <table mat-table [dataSource]="(tendersStateService.tenderSearchs$ | async).items">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Título</th>
      <td mat-cell *matCellDef="let row">
        {{ row.titulo }}
      </td>
    </ng-container>
    <ng-container matColumnDef="tools">
      <th mat-header-cell *matHeaderCellDef>Acciones</th>
      <td mat-cell *matCellDef="let row">
        <a mat-icon-button [matTooltip]="'Lanzar búsqueda'" (click)="search(row)">
          <mat-icon>search</mat-icon>
        </a>
        <a mat-icon-button [matTooltip]="'Eliminar'" (click)="delete(row)">
          <mat-icon>delete</mat-icon>
        </a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <th mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
      <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
      <p style="margin: 0" *ngIf="!loading">No se han encontrado datos</p>
    </th>
  </table>
  <mat-paginator [pageIndex]="pageIndex" pageSize="30" (page)="pageEvent($event)" fixed></mat-paginator>
</div>
