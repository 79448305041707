<div mat-dialog-content *ngIf="documentStateService.document$ | async as document">
  <ngx-extended-pdf-viewer
    #mypdf
    id="mypdf"
    [src]="document.s3_pdf"
    useBrowserLocale="false"
    delayFirstView="1000"
    [height]="1000"
    [handTool]="false"
    [showHandToolButton]="true"
    spread="true"
  >
  </ngx-extended-pdf-viewer>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <button mat-flat-button (click)="onNoClick()" color="warn">Cerrar</button>
</div>
