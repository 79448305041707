import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import {
  Alert,
  AlertsGetResponse,
  Cpv,
  Import,
  Province,
  Tender,
  TenderSearchsGetResponse,
  TendersFilters,
  TendersGetResponse,
  TendersStateModel,
} from '../../models/tender';
import {
  DeleteAlert,
  DeleteTenderSearch,
  GetTendersAlerts,
  GetCpvs,
  GetImports,
  GetProvinces,
  GetTender,
  GetTenders,
  GetTenderSearchs,
  GetTendersFavorites,
  PostAlert,
  PostSearch,
  ToggleTendersMenu,
  ToggleTendersMenuFilters,
  UpdateAlert,
  UpdateTenderFavorite,
  UpdateTendersFilters,
  GetCpv,
} from './tenders.actions';

@Injectable({
  providedIn: 'root',
})
@State<TendersStateModel>({
  name: 'tenders',
  defaults: {
    tenders: {
      items: [],
      page: 0,
      size: 0,
      total: 0,
      total_pages: 0,
    },
    tendersFavorites: {
      items: [],
      page: 0,
      size: 0,
      total: 0,
      total_pages: 0,
    },
    alerts: {
      items: [],
      page: 0,
      size: 0,
      total: 0,
      total_pages: 0,
    },
    tenderSearchs: {
      items: [],
      page: 0,
      size: 0,
      total: 0,
      total_pages: 0,
    },
    provinces: [],
    cpvs: [],
    cpv: undefined,
    imports: [],
    openedMenuTendersFilters: true,
    openedMenu: true,
  },
})
export class TendersState {
  @Selector()
  static tenders(state: TendersStateModel): TendersGetResponse {
    return state.tenders;
  }

  @Selector()
  static tender(state: TendersStateModel): Tender | undefined {
    return state.tender;
  }

  @Selector()
  static tendersFavorites(state: TendersStateModel): TendersGetResponse {
    return state.tendersFavorites;
  }

  @Selector()
  static tendersFilters(state: TendersStateModel): TendersFilters {
    return state.tendersFilters;
  }

  @Selector()
  static provinces(state: TendersStateModel): Province[] {
    return state.provinces;
  }

  @Selector()
  static cpvs(state: TendersStateModel): Cpv[] {
    return state.cpvs;
  }

  @Selector()
  static cpv(state: TendersStateModel): Cpv {
    return state.cpv;
  }

  @Selector()
  static imports(state: TendersStateModel): Import[] {
    return state.imports;
  }

  @Selector()
  static alerts(state: TendersStateModel): AlertsGetResponse {
    return state.alerts;
  }

  @Selector()
  static tenderSearchs(state: TendersStateModel): TenderSearchsGetResponse {
    return state.tenderSearchs;
  }

  @Selector()
  static openedMenuTendersFilters(state: TendersStateModel): boolean {
    return state.openedMenuTendersFilters;
  }

  @Selector()
  static openedMenu(state: TendersStateModel): boolean {
    return state.openedMenu;
  }

  @Action(GetTenders)
  getTenders({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: TendersGetResponse }) {
    const state = getState();
    let tenders = state.tenders;
    tenders = response.payload;
    patchState({ tenders });
  }

  @Action(GetTender)
  getTender({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: Tender }) {
    const state = getState();
    let tender = state.tender;
    tender = response.payload;
    patchState({ tender });
  }

  @Action(GetTendersFavorites)
  getTendersFavorites(
    { getState, patchState }: StateContext<TendersStateModel>,
    response: { payload: TendersGetResponse }
  ) {
    const state = getState();
    let tendersFavorites = state.tendersFavorites;
    tendersFavorites = response.payload;
    patchState({ tendersFavorites });
  }

  @Action(UpdateTendersFilters)
  updateTendersFilters(
    { getState, patchState }: StateContext<TendersStateModel>,
    response: { payload: TendersFilters }
  ) {
    const state = getState();
    let tendersFilters = state.tendersFilters;
    tendersFilters = response.payload;
    patchState({ tendersFilters });
  }

  @Action(UpdateTenderFavorite)
  updateTenderFavorite({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: number }) {
    const state = JSON.parse(JSON.stringify(getState()));

    const tenders = state.tenders as TendersGetResponse;
    let tender = state.tender as Tender;
    const tenderIndex = tenders.items.findIndex((i) => i.id === response.payload);

    if (tenderIndex !== -1) {
      tenders.items[tenderIndex] = { ...tenders.items[tenderIndex], favorito: !tenders.items[tenderIndex].favorito };
      patchState({ tenders });
    }

    if (tender) {
      tender = { ...tender, favorito: !tender.favorito };
      patchState({ tender });
    }
  }

  @Action(PostSearch)
  postSearch() {
    //
  }

  @Action(GetProvinces)
  getProvinces({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: Province[] }) {
    const state = getState();
    let provinces = state.provinces;
    provinces = response.payload;
    patchState({ provinces });
  }

  @Action(GetCpvs)
  getCpvs({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: Cpv[] }) {
    const state = getState();
    let cpvs = state.cpvs;
    cpvs = response.payload;
    patchState({ cpvs });
  }

  @Action(GetCpv)
  getCpv({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: Cpv }) {
    const state = getState();
    let cpv = state.cpv;
    cpv = response.payload;
    patchState({ cpv });
  }

  @Action(GetImports)
  getImports({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: Import[] }) {
    const state = getState();
    let imports = state.imports;
    imports = response.payload;
    patchState({ imports });
  }

  @Action(GetTendersAlerts)
  getTendersAlerts(
    { getState, patchState }: StateContext<TendersStateModel>,
    response: { payload: AlertsGetResponse }
  ) {
    const state = getState();
    let alerts = state.alerts;
    alerts = response.payload;
    patchState({ alerts });
  }

  @Action(DeleteAlert)
  deleteAlert({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: number }) {
    const state = JSON.parse(JSON.stringify(getState()));

    const alerts = state.alerts as AlertsGetResponse;
    const alertIndex = alerts.items.findIndex((i) => i.id === response.payload);

    if (alertIndex !== -1) {
      alerts.items.splice(alertIndex, 1);
    }

    patchState({ alerts });
  }

  @Action(PostAlert)
  postAlert({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: Alert }) {
    const state = JSON.parse(JSON.stringify(getState()));
    const alerts = state.alerts as AlertsGetResponse;
    alerts.items.push(response.payload);
    patchState({ alerts });
  }

  @Action(UpdateAlert)
  updateAlert({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: Alert }) {
    const state = JSON.parse(JSON.stringify(getState()));
    const alerts = state.alerts as AlertsGetResponse;
    const alertIndex = alerts.items.findIndex((i) => i.id === response.payload.id);

    if (alertIndex !== -1) {
      alerts.items[alertIndex] = response.payload;
      patchState({ alerts });
    }
  }

  @Action(GetTenderSearchs)
  GetTenderSearchs(
    { getState, patchState }: StateContext<TendersStateModel>,
    response: { payload: TenderSearchsGetResponse }
  ) {
    const state = getState();
    let tenderSearchs = state.tenderSearchs;
    tenderSearchs = response.payload;
    patchState({ tenderSearchs });
  }

  @Action(DeleteTenderSearch)
  deleteTenderSearch({ getState, patchState }: StateContext<TendersStateModel>, response: { payload: number }) {
    const state = JSON.parse(JSON.stringify(getState()));

    const tenderSearchs = state.tenderSearchs as TenderSearchsGetResponse;
    const tenderSearchIndex = tenderSearchs.items.findIndex((i) => i.id === response.payload);

    if (tenderSearchIndex !== -1) {
      tenderSearchs.items.splice(tenderSearchIndex, 1);
    }

    patchState({ tenderSearchs });
  }

  @Action(ToggleTendersMenuFilters)
  toggleTendersMenuFilters({ getState, patchState }: StateContext<TendersStateModel>) {
    const state = getState();
    const openedMenuTendersFilters = !state.openedMenuTendersFilters;
    patchState({ openedMenuTendersFilters });
  }

  @Action(ToggleTendersMenu)
  toggleTendersMenu({ getState, patchState }: StateContext<TendersStateModel>) {
    const state = getState();
    const openedMenu = !state.openedMenu;
    patchState({ openedMenu });
  }
}
