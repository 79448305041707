<div class="container">
  <p class="title">Licitaciones favoritas</p>
  <table mat-table [dataSource]="(tendersStateService.tendersFavorites$ | async).items">
    <ng-container matColumnDef="card">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <a class="container-card" (click)="openDetail(row.id)">
          <div class="header">
            <p>{{ row.objeto }}</p>
            <button mat-icon-button (click)="saveItem($event, row)" [ngClass]="{ favorite: row.favorito }">
              <mat-icon>favorite</mat-icon>
            </button>
          </div>
          <div class="content">
            <div class="sections">
              <div class="section">
                <mat-icon>description</mat-icon>
                <div class="content">
                  <p class="name">Expediente</p>
                  <p class="value">{{ row.expediente }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>event</mat-icon>
                <div class="content">
                  <p class="name">Publicación</p>
                  <p class="value">{{ row.fecha_publicacion | date }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>schedule</mat-icon>
                <div class="content">
                  <p class="name">Plazo</p>
                  <p class="value">{{ row.plazo | date }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>euro</mat-icon>
                <div class="content">
                  <p class="name">Presupuesto</p>
                  <p class="value">{{ row.presupuesto | number }} €</p>
                </div>
              </div>
            </div>
            <a (click)="openDetail(row.id)">Más información</a>
          </div>
        </a>
      </td>
    </ng-container>
    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr> -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <th mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
      <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
      <!-- <p style="margin: 0" *ngIf="!loading">No se han encontrado datos</p> -->
    </th>
  </table>
  <mat-paginator
    [pageIndex]="pageIndex"
    pageSize="30"
    (page)="pageEvent($event)"
    fixed
    [ngStyle]="{ opacity: (tendersStateService.tendersFavorites$ | async).total > 0 ? 1 : 0 }"
  ></mat-paginator>
  <div class="no-data" *ngIf="!loading && (tendersStateService.tendersFavorites$ | async).total === 0">
    <img src="assets/imgs/404-illustration-no-data.svg" />
    <p class="title">Sin datos</p>
    <p class="description">No se han encontrado resultados, prueba a añadir a favoritos una licitación.</p>
  </div>
</div>
