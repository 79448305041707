<h1 mat-dialog-title>Añadir noticia en {{ data.searchItem.category }}</h1>
<div mat-dialog-content>
  <form class="form-container" #form="ngForm" [formGroup]="formGroup">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
      <section class="article-type" fxFlex="auto">
        <mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="docType">
          <mat-button-toggle value="custom"> PDF </mat-button-toggle>
          <mat-button-toggle value="multimedia"> Multimedia </mat-button-toggle>
        </mat-button-toggle-group>
      </section>
      <mat-form-field fxFlex="auto">
        <mat-label>Fecha de la noticia</mat-label>
        <input matInput type="text" [formControl]="date" [matDatepicker]="picker" required />
        <mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Mostrar calendario"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="date.invalid">
          <span *ngIf="date.errors.required || date.invalid">Campo requerido.</span>
        </mat-error>
        <mat-hint>Introduzca la fecha de la noticia.</mat-hint>
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>Título de la noticia</mat-label>
      <input matInput type="text" [formControl]="title" required />
      <mat-error *ngIf="title.invalid">
        <span *ngIf="title.errors.required || title.invalid">Campo requerido.</span>
      </mat-error>
      <mat-hint>Introduzca el título de la noticia.</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Fuente de la noticia</mat-label>
      <input matInput type="text" [formControl]="source" required />
      <mat-error *ngIf="source.invalid">
        <span *ngIf="source.errors.required || source.invalid">Campo requerido.</span>
      </mat-error>
      <mat-hint>Introduzca la fuente de la noticia.</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Resumen</mat-label>
      <textarea matInput rows="8" cols="80" [formControl]="abstract" required></textarea>
      <mat-error *ngIf="abstract.invalid">
        <span *ngIf="abstract.errors.required || abstract.invalid">Campo requerido.</span>
      </mat-error>
      <mat-hint>Introduzca el resumen de la noticia.</mat-hint>
    </mat-form-field>
    <div class="file-container" *ngIf="group.value === 'custom'">
      <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload accept="application/pdf" />
      <div class="file-upload">
        {{ fileName || 'Archivo PDF noticia *' }}
        <button mat-flat-button color="primary" class="upload-btn" (click)="fileUpload.click()">
          Seleccionar archivo
          <mat-icon>attach_file</mat-icon>
        </button>
      </div>
      <div class="progress" *ngIf="uploadProgress">
        <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress"> </mat-progress-bar>
        <mat-icon class="cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress">delete_forever</mat-icon>
      </div>
    </div>
    <mat-form-field *ngIf="group.value === 'multimedia'">
      <mat-label>Enlace a la noticia</mat-label>
      <input matInput type="text" [formControl]="s3_file_url" required />
      <mat-error *ngIf="s3_file_url.invalid">
        <span *ngIf="s3_file_url.errors.required || s3_file_url.invalid">Campo requerido.</span>
      </mat-error>
      <mat-hint>Introduzca el enlace a la noticia.</mat-hint>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Descartar</button>
  <button mat-button (click)="onSaveClick()" cdkFocusInitial>Guardar</button>
</div>
