<mat-nav-list role="list" class="list-centered">
  <ng-container *ngFor="let item of items; let i = index">
    <mat-list-item [ngClass]="'dash' + (i % 4)" role="listitem" (click)="navigateTo(i)" class="mat-elevation-z1">
      <mat-icon mat-list-icon class="icon-48">{{ item.icon }}</mat-icon>
      <h3 mat-line class="mat-card-title">{{ item.title }}</h3>
      <p mat-line class="mat-card-subtitle" [ngClass]="'subtitle' + (i % 4)">{{ item.subtitle }}</p>
      <p mat-line class="item-content">{{ item.description }}</p>
    </mat-list-item>
  </ng-container>
</mat-nav-list>
