import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
  selector: 'ng-chart-line',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css'],
})
export class LineChartComponent extends BaseChartComponent implements OnInit, AfterViewInit {
  type: string = 'line';

  ngOnInit() {}

  getRequest() {
    return { uri: this.wconfig.config['uri'] };
  }

  refresh(config, response) {
    if (response && response.data) {
      let rdata = [...response.data],
        rheader = [...response.header],
        legend = [],
        xAxis = [],
        config = this.wconfig.config,
        series = [],
        start = config['slice'] && config['slice'][1] ? config['slice'][1] : rdata[0].length - 1,
        end = config['slice'] ? config['slice'][0] : 0;
      rheader =
        typeof rheader[0] != undefined && rheader[0].constructor.toString().match(/Array/) ? rheader[0] : rheader;

      rdata.reverse();
      for (var col = start; col >= end; col--) {
        var data = [];
        for (var row = rdata.length - 1; row >= 0; row--) {
          data.push(rdata[row][col]);
          if (config['xAxisCol'] !== undefined) xAxis.push(rdata[row][config['xAxisCol']]);
        }
        // Only get xAxis label once
        config['xAxisCol'] = undefined;
        legend.push(rheader[col]);
        series.push({
          name: rheader[col],
          type: this.type,
          data: data,
        });
      }
      this.isLoading = true;
      this.options = {
        title: {
          text: this.wconfig.config['title'],
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: legend,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        series: series,
      };
      if (xAxis.length > 0) this.options['xAxis']['data'] = xAxis;

      let f = () => {
        if (!this.echartsInstance) setTimeout(f, 500);
        else this.echartsInstance.setOption(this.options);
      };
      f();
    }
    this.isLoading = false;
    this.seriesLoaded.emit('linechart');
  }

  onChartInit(e) {
    this.echartsInstance = e;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
}
