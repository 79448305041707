import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
  selector: 'ng-pie',
  templateUrl: './ng-pie.component.html',
  styleUrls: ['./ng-pie.component.css'],
})
export class NgPieComponent extends BaseChartComponent implements OnInit, AfterViewInit {
  ngOnInit() {}

  getRequest() {
    return { uri: this.wconfig.config['uri'] };
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  refresh(config, response) {
    if (response && response.data) {
      let rdata = [...response.data],
        rheader = [...response.header],
        config = this.wconfig.config,
        start = config['slice'] && config['slice'][1] ? config['slice'][1] : rdata[0].length - 1,
        end = config['slice'] ? config['slice'][0] : 0,
        legend = [],
        series = [];

      for (var col = start; col >= end; --col) {
        var data = 0;
        for (var row = rdata.length - 1; row >= 0; row--) {
          data += rdata[row][col];
        }
        legend.push(rheader[col]);
        series.push({
          name: rheader[col],
          value: data,
        });
      }

      this.options = {
        title: {
          text: this.wconfig.config['title'],
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: [],
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: series,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0,0,0,0.5)',
              },
            },
          },
        ],
      };
      let f = () => {
        if (!this.echartsInstance) setTimeout(f, 500);
        else this.echartsInstance.setOption(this.options);
      };
      f();
      this.seriesLoaded.emit('pie');
    }
  }

  onChartInit(e) {
    this.echartsInstance = e;
  }
}
