import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TextanalysisService {
  stopwords: Object = {};
  constructor(private httpClient: HttpClient) {
    httpClient.get('/assets/stopwords.json').subscribe((stopwords) => {
      this.stopwords = stopwords;
    });
  }

  jaro_winkler(s1: string, s2: string): number {
    let dj = 0,
      s1_length: number = s1.length,
      s2_length: number = s2.length,
      SCALING_FACTOR: number = 0.1,
      arr_s1: number[] = [],
      arr_s2: number[] = [],
      matches: number = 0,
      transpositions: number = 0,
      range: number = Math.max(0, Math.floor(Math.max(s1_length, s2_length) / 2), -1);

    for (let i = 0; i < s1_length; i++) arr_s1[i] = 0;
    for (let i = 0; i < s2_length; i++) arr_s2[i] = 0;

    // Calculate matches
    for (let i = 0; i < s2_length; i++)
      for (let j = Math.max(i - range, 0), l = Math.min(i + range + 1, s1_length); j < l; j++) {
        if (s2[i] == s1[j] && !arr_s1[j]) {
          arr_s2[i] = 1;
          arr_s1[j] = 1;
          matches++;
          break;
        }
      }

    if (!matches) return 0.0;

    // Calculate tranposition
    let l = 0;
    for (let i = 0; i < s1_length; i++) {
      if (arr_s1[i]) {
        let j = 0;
        for (j = l; j < s1_length; j++) {
          if (arr_s2[j]) {
            l = j + 1;
            break;
          }
        }
        if (s1[i] != s2[j]) transpositions++;
      }
    }
    transpositions /= 2;
    transpositions = Math.floor(transpositions);
    dj = (matches / s1_length + matches / s2_length + (matches - transpositions) / matches) / 3;
    l = 0;
    for (let i = 0; i < Math.min(s1_length, s2_length, 4); i++) if (s1[i] == s2[i]) l++;
    return dj + l * SCALING_FACTOR * (1 - dj);
  }

  levenshtein(str1: string, str2: string): number {
    let length1: number = str1.length,
      length2: number = str2.length;

    if (length1 < length2) return this.levenshtein(str2, str1);
    if (length1 == 0) return length2;
    if (str1 == str2) return 0;
    let prevRow: number[] = Array.from(Array(length2 + 1).keys()),
      currentRow: number[] = [];
    for (let i = 0; i < length1; i++) {
      currentRow = [];
      currentRow[0] = i + 1;
      let z = 0,
        c1 = str1.substr(i, 1);
      for (let j = 0; j < length2; j++) {
        let c2: string = str2.substr(j, 1),
          insertions: number = prevRow[j + 1] + 1,
          deletions: number = currentRow[j] + 1,
          substitutions: number = prevRow[j] + (c1 != c2 ? 1 : 0);
        currentRow[j + 1] = Math.min(insertions, deletions, substitutions);
      }
      prevRow = currentRow;
    }
    return prevRow[length2];
  }

  hamming(s1: string, s2: string): number {
    let length: number = s1.length;
    if (length != s2.length) throw 'Strings have different length';
    let c: number = 0;
    for (let i = 0; i < length; i++) c += s1[i] != s2[i] ? 1 : 0;
    return c;
  }

  smithWatermanScore(s1: string, s2: string, callback: any): number {
    let weight =
        callback != undefined
          ? callback
          : (i: number, j: number, s1: string, s2: string) => {
              return s1[i - 1] == s2[j - 1] ? 1 : -1;
            },
      length1: number = s1.length,
      length2: number = s2.length,
      mat: number[][] = new Array<Array<number>>();

    for (let i = 0; i < length1; i++) {
      mat[i][0] = 0;
      if (i == 0)
        for (let j = 0; j < length2; j++) {
          mat[0][j] = 0;
        }
    }
    let max = 0;
    for (let i = 1; i < length1; i++)
      for (let j = 1; j < length2; j++) {
        let scoreDig: number = mat[i - 1][j - 1] + weight(i, j, s1, s2),
          scoreLeft: number = mat[i][j - 1] - 1,
          scoreUp: number = mat[i - 1][j] - 1;
        mat[i][j] = Math.max(scoreDig, scoreLeft, scoreUp, 0);
        if (mat[i][j] > max) max = mat[i][j];
      }
    return max;
  }

  public clean_stopwords(text) {
    if (text instanceof String) {
      text = text.split(' ');
    }
    let ret = [];
    text.forEach((w) => {
      if (!this.stopwords[w.toLowerCase()] && !w.match(/[0-9]/) && w.length > 2) ret.push(w);
    });
    return ret;
  }
}
