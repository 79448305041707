<div class="card">
  <div class="data">
    <div class="container-header">
      <div class="header">
        <p>{{ magazine.name | ellipsis: 25 }}</p>
        <p>{{ magazine.date_created | date }}</p>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Acciones de la revista">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="setup()" *ngIf="isAdmin">
          <mat-icon>settings</mat-icon>
          <span>Configuración</span>
        </button>
        <button mat-menu-item (click)="picker.open()">
          <mat-icon>newspaper</mat-icon>
          <span>Buscar borradores ({{ magazine.drafts?.length || 0 }})</span>
        </button>
        <button mat-menu-item disabled (click)="stats()">
          <mat-icon>query_stats</mat-icon>
          <span>Ver informe</span>
        </button>
      </mat-menu>
      <mat-form-field style="width: 1px; height: 1px; visibility: hidden">
        <input matInput [matDatepicker]="picker" (dateChange)="showDrafts($event)" />
        <mat-datepicker [dateClass]="dateClass" touchUi #picker (closed)="onCloseDatepickerDrafts()"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="content">
      <div class="section">
        <p class="title">Temas</p>
        <div class="tags">
          <div class="tag" *ngFor="let search of magazine.searches">
            <p>{{ search.category }}</p>
          </div>
        </div>
      </div>
      <div class="section">
        <p class="title">Fuentes</p>
        <div class="tags">
          <div class="tag">
            <mat-icon>article</mat-icon>
            <p>Prensa escrita ({{ getPressSpidersLength() }})</p>
            <div [id]="'section-tag-prensa-escrita-' + magazine.id" class="tooltip tooltip-section">
              <!-- <p *ngFor="let ccaa of row.params.show.ccaa">• {{ getCCAAName(ccaa.ca) }}</p> -->
            </div>
          </div>
          <div class="tag">
            <mat-icon>laptop</mat-icon>
            <p>Prensa digital ({{ getDigitalSpidersLength() }})</p>
            <div [id]="'section-tag-prensa-digital-' + magazine.id" class="tooltip tooltip-section">
              <!-- <p *ngFor="let ccaa of row.params.show.ccaa">• {{ getCCAAName(ccaa.ca) }}</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions">
    <button mat-stroked-button color="primary" class="btn-same-color btn-same-color-primary" (click)="new()">
      Nuevo borrador
    </button>
    <button
      mat-flat-button
      color="primary"
      class="btn-same-color btn-same-color-primary"
      (click)="show(getLastDraft().id)"
    >
      Ver último borrador
    </button>
  </div>
</div>

<!-- <mat-card class="thumb-card">
  <mat-card-header>
    <div mat-card-avatar class="thumb-header-image"></div>
    <mat-card-title [matTooltip]="magazine.name">{{ magazine.name | ellipsis: 25 }}</mat-card-title>
    <mat-card-subtitle>Creada el {{ magazine.date_created | date }}</mat-card-subtitle>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Acciones de la revista" class="actions">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="setup()">
        <mat-icon>settings</mat-icon>
        <span>Configuración</span>
      </button>
      <button mat-menu-item (click)="picker.open()">
        <mat-icon>newspaper</mat-icon>
        <span>Buscar borradores ({{ drafts.length }})</span>
      </button>
      <button mat-menu-item disabled (click)="stats()">
        <mat-icon>query_stats</mat-icon>
        <span>Ver informe</span>
      </button>
    </mat-menu>
    <mat-form-field style="width: 1px; visibility: hidden">
      <input matInput [matDatepicker]="picker" (dateChange)="showDrafts($event)" />
      <mat-datepicker [dateClass]="dateClass" touchUi #picker (closed)="onCloseDatepickerDrafts()"></mat-datepicker>
    </mat-form-field>
  </mat-card-header>
  <img mat-card-image src="/assets/imgs/prensa-escrita.jpg" alt="Fondo prensa" />
  <mat-card-content fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" fxLayoutGap="8px">
      <mat-icon>category</mat-icon>
      <span>{{ magazine.searches.length }} tema{{ magazine.searches.length | plural }}</span>
    </div>
    <div fxLayoutAlign="center center" fxLayoutGap="8px">
      <mat-icon>newspaper</mat-icon>
      <span
        >{{ magazine.spiders.length }} fuente{{ magazine.spiders.length | plural }} seleccionada{{
          magazine.spiders.length | plural
        }}</span
      >
    </div>
  </mat-card-content>
  <mat-card-actions fxLayoutAlign="space-between center">
    <button mat-flat-button (click)="show(getLastDraft().id)">ÚLTIMO BORRADOR</button>
    <button mat-flat-button (click)="new()">NUEVO BORRADOR</button>
  </mat-card-actions>
</mat-card> -->

<!-- <button mat-icon-button [matMenuTriggerFor]="menuDraft">
  <mat-icon>search</mat-icon>
</button> -->
<!-- <mat-menu #menuDraft="matMenu">
  <button mat-menu-item (click)="show(item.id)" *ngFor="let item of drafts">
    <mat-icon>mode</mat-icon>
    <span>{{ item.date_created | date:'short' }}</span>
  </button>
</mat-menu> -->
