<h2>Enviar documento por email</h2>
<div class="input_container">
  <mat-form-field>
    <input required #inputText matInput [(ngModel)]="menssage" placeholder="Introduce un mensaje..." />
  </mat-form-field>
  <mat-form-field>
    <input required #inputText matInput [formControl]="email" placeholder="Email destino" />
    <mat-error *ngIf="email.invalid">{{ getErrorMessage() }}</mat-error>
  </mat-form-field>
</div>
<button *ngIf="menssage && menssage.length > 0 && !email.invalid" type="submit" (click)="enviarEmail()" mat-button>
  Enviar
</button>
