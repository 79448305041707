<div class="container">
  <!-- <p class="title">Buscador de licitaciones - {{ id }}</p> -->
  <div class="loader" *ngIf="loading">
    <mat-spinner [diameter]="32"></mat-spinner>
    <p>Cargando datos...</p>
  </div>
  <div class="container-card" *ngIf="!loading && tender">
    <div class="header">
      <p>{{ tender.objeto }}</p>
      <button mat-icon-button (click)="saveItem()" [ngClass]="{ favorite: tender.favorito }">
        <mat-icon>folder_special</mat-icon>
      </button>
    </div>
    <div class="content">
      <p class="finish-date">Hasta el día {{ tender.informacion.fecha_fin_solicitud }}</p>
      <div class="sections">
        <div class="section">
          <mat-icon>home</mat-icon>
          <div class="content">
            <p class="name">Órgano convocante</p>
            <p class="value">{{ tender.organo }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>euro</mat-icon>
          <div class="content">
            <p class="name">Presupuesto convocatoria</p>
            <p class="value">{{ tender.presupuesto | number }} €</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>euro</mat-icon>
          <div class="content">
            <p class="name">Valor estimado</p>
            <p class="value">{{ tender.valor | number }} €</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>notifications</mat-icon>
          <div class="content">
            <p class="name">Estado</p>
            <p class="value">{{ tender.estado }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>store</mat-icon>
          <div class="content">
            <p class="name">Sectores</p>
            <p class="value">{{ tender.cpv }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>description</mat-icon>
          <div class="content">
            <p class="name">Expediente</p>
            <p class="value">{{ tender.expediente }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>article</mat-icon>
          <div class="content">
            <p class="name">Tipo de contrato</p>
            <p class="value">{{ tender.tipo }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>place</mat-icon>
          <div class="content">
            <p class="name">Ámbito</p>
            <p class="value">{{ tender.ambito }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>publish</mat-icon>
          <div class="content">
            <p class="name">Actualizado el</p>
            <p class="value">{{ tender.fecha_publicacion | date }}</p>
          </div>
        </div>
        <div class="section">
          <mat-icon>event</mat-icon>
          <div class="content">
            <p class="name">Fecha de obtención</p>
            <p class="value">{{ tender.created_at | date }}</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="section section-long">
          <mat-icon>info</mat-icon>
          <div class="content">
            <p class="name">Información</p>
            <p class="value">Adjudicatario: {{ tender.informacion.adjudicatario || '- No disponible -' }}</p>
            <p class="value">Importe: {{ tender.informacion.importe_adjudicacion || '- No disponible -' }}</p>
            <p class="value">Número de licitadores: {{ tender.informacion.num_licitadores || '- No disponible -' }}</p>
            <p class="value">Resultado: {{ tender.informacion.resultado || '- No disponible -' }}</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="section section-long">
          <mat-icon>source</mat-icon>
          <div class="content">
            <p class="name">Documentos</p>
            <p class="value" *ngIf="tender.documentos.length === 0">- Sin documentos -</p>
            <p class="value" *ngFor="let documento of tender.documentos">
              - {{ documento.fecha | date }} <span class="separator">|</span> {{ documento.titulo }}
              <a (click)="openUrlInNewWindow(documento.url)">Ver documento</a>
            </p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="section section-long">
          <mat-icon>history</mat-icon>
          <div class="content">
            <p class="name">Histórico</p>
            <p class="value" *ngIf="tender.historico.length === 0">- Sin histórico -</p>
            <p class="value" *ngFor="let historico of tender.historico">
              - {{ historico.fecha | date }} <span class="separator">|</span> {{ historico.titulo }}
            </p>
          </div>
        </div>
      </div>
      <a (click)="openUrlInNewWindow(tender.url)">Ver enlace original</a>
    </div>
  </div>
  <!-- <button mat-flat-button class="return-button" (click)="return()">
    <mat-icon>chevron_left</mat-icon>
    <p>Volver</p>
  </button> -->
</div>
