import { Location } from '@angular/common';
import { AfterContentChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SubventionsFilters } from '@app/modules/ceoe/models/subvention';
import { Alert } from '@app/modules/ceoe/models/subvention';
import {
  GetFilters,
  GetSubventions,
  UpdateSubventionsMenuFilters,
} from '@app/modules/ceoe/store/subventions/subventions.actions';
import { SubventionsStateService } from '@app/modules/ceoe/store/subventions/subventions.service';
import { UtilsService } from '@app/services/utils.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import moment from 'moment';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-subventions-search-filters',
  templateUrl: './subventions-search-filters.component.html',
  styleUrls: ['./subventions-search-filters.component.scss'],
})
export class SubventionsSearchFiltersComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  filters?: SubventionsFilters;
  filtersForm: FormGroup;
  filtersPublishmentDateRangeForm: FormGroup;
  filtersFinalizationDateRangeForm: FormGroup;
  loading = true;
  applyingFilters = true;
  static nextGenerationIds = [530, 522];
  advancedFiltersOpened = false;

  dropdownFondosList = [];
  selectedFondosItems = [];
  dropdownFondosSettings = {
    text: 'Fondos',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  dropdownFinalidadList = [];
  selectedFinalidadItems = [];
  dropdownFinalidadSettings = {
    text: 'Área de gasto',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  dropdownComunidadesList = [];
  selectedComunidadesItems = [];
  dropdownComunidadesSettings = {
    text: 'Comunidades',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  dropdownSectorEconomicoList = [];
  selectedSectorEconomicoItems = [];
  dropdownSectorEconomicoSettings = {
    text: 'Sector económico',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  dropdownProvinciasList = [];
  selectedProvinciasItems = [];
  dropdownProvinciasSettings = {
    text: 'Provincias',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  constructor(
    public subventionsStateService: SubventionsStateService,
    private fb: FormBuilder,
    private actions: Actions,
    private location: Location,
    private router: Router,
    private utilsService: UtilsService,
    private route: ActivatedRoute
  ) {
    this.filtersForm = this.fb.group({
      texto: [this.subventionsStateService.filters.selectedFilters.texto || ''],
      presupuesto: this.fb.array([]),
      instrumento_ayuda: this.fb.array([]),
      fondos: this.fb.array([]),
      finalidad: this.fb.array([]),
      comunidades: this.fb.array([]),
      provincias: this.fb.array([]),
      sector_economico: this.fb.array([]),
      organismo_convocante: this.fb.array([]),
      beneficiarios: this.fb.array([]),
      estado: this.fb.array([]),

      fondosDropdown: [
        this.subventionsStateService.filters.selectedFilters &&
        this.subventionsStateService.filters.selectedFilters.fondos
          ? (this.subventionsStateService.filters.selectedFilters.fondos as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.subventionsStateService.filters.filters
                  .find((f) => f.attr === 'fondos')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.subventionsStateService.filters.filters
                    .find((f) => f.attr === 'fondos')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      finalidadDropdown: [
        this.subventionsStateService.filters.selectedFilters &&
        this.subventionsStateService.filters.selectedFilters.finalidad
          ? (this.subventionsStateService.filters.selectedFilters.finalidad as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.subventionsStateService.filters.filters
                  .find((f) => f.attr === 'finalidad')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.subventionsStateService.filters.filters
                    .find((f) => f.attr === 'finalidad')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      comunidadesDropdown: [
        this.subventionsStateService.filters.selectedFilters &&
        this.subventionsStateService.filters.selectedFilters.comunidades
          ? (this.subventionsStateService.filters.selectedFilters.comunidades as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.subventionsStateService.filters.filters
                  .find((f) => f.attr === 'comunidades')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.subventionsStateService.filters.filters
                    .find((f) => f.attr === 'comunidades')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      provinciasDropdown: [
        this.subventionsStateService.filters.selectedFilters &&
        this.subventionsStateService.filters.selectedFilters.provincias
          ? (this.subventionsStateService.filters.selectedFilters.provincias as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.subventionsStateService.filters.filters
                  .find((f) => f.attr === 'provincias')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.subventionsStateService.filters.filters
                    .find((f) => f.attr === 'provincias')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      sector_economicoDropdown: [
        this.subventionsStateService.filters.selectedFilters &&
        this.subventionsStateService.filters.selectedFilters.sector_economico
          ? (this.subventionsStateService.filters.selectedFilters.sector_economico as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.subventionsStateService.filters.filters
                  .find((f) => f.attr === 'sector_economico')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.subventionsStateService.filters.filters
                    .find((f) => f.attr === 'sector_economico')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
    });

    this.selectedComunidadesItems = this.filtersForm.controls.comunidadesDropdown.value;
    this.selectedProvinciasItems = this.filtersForm.controls.provinciasDropdown.value;
    this.selectedSectorEconomicoItems = this.filtersForm.controls.sector_economicoDropdown.value;
    this.selectedFondosItems = this.filtersForm.controls.fondosDropdown.value;
    this.selectedFinalidadItems = this.filtersForm.controls.finalidadDropdown.value;

    this.filtersPublishmentDateRangeForm = this.fb.group({
      start: [
        this.subventionsStateService.filters.selectedFilters &&
        this.subventionsStateService.filters.selectedFilters.fecha_publicacion_desde
          ? moment(this.subventionsStateService.filters.selectedFilters.fecha_publicacion_desde, 'DD/MM/YYYY').toDate()
          : undefined,
      ],
      end: [
        this.subventionsStateService.filters.selectedFilters &&
        this.subventionsStateService.filters.selectedFilters.fecha_publicacion_hasta
          ? moment(this.subventionsStateService.filters.selectedFilters.fecha_publicacion_hasta, 'DD/MM/YYYY').toDate()
          : undefined,
      ],
    });

    this.filtersFinalizationDateRangeForm = this.fb.group({
      start: [
        this.subventionsStateService.filters.selectedFilters &&
        this.subventionsStateService.filters.selectedFilters.fecha_finalizacion_desde
          ? moment(this.subventionsStateService.filters.selectedFilters.fecha_finalizacion_desde, 'DD/MM/YYYY').toDate()
          : undefined,
      ],
      end: [
        this.subventionsStateService.filters.selectedFilters &&
        this.subventionsStateService.filters.selectedFilters.fecha_finalizacion_hasta
          ? moment(this.subventionsStateService.filters.selectedFilters.fecha_finalizacion_hasta, 'DD/MM/YYYY').toDate()
          : undefined,
      ],
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetSubventions)).subscribe(() => {
      this.loading = false;
    });

    route.queryParams.subscribe((qp) => {
      if (qp.setNextGeneration && qp.setNextGeneration == 'true') {
        this.location.replaceState('/subventions/search');
        setTimeout(() => {
          this.filtersForm.controls.fondosDropdown.setValue(
            [
              ...this.filtersForm.controls.fondosDropdown.value,
              ...this.subventionsStateService.filters.filters
                .find((f) => f.attr == 'fondos')
                .items.filter((i) =>
                  SubventionsSearchFiltersComponent.nextGenerationIds.some(
                    (j) => (i.value as unknown as number[]).indexOf(j) >= 0
                  )
                )
                .map((c) => {
                  return {
                    id: String(c.value),
                    itemName: c.label,
                    name: this.utilsService.removeDiacritics(c.label),
                  };
                }),
            ].filter((item, index, self) => index === self.findIndex((obj) => obj.id === item.id))
          );
          this.applyFilters();
          this.router.navigate(['subventions', 'search'], { queryParams: {} });
        }, 0);
      } else if (qp.setNextGeneration && qp.setNextGeneration == 'false') {
        this.location.replaceState('/subventions/search');
        setTimeout(() => {
          const fondosWithoutNextGenrationIds = this.filtersForm.controls.fondosDropdown.value.filter(
            (item) => !SubventionsSearchFiltersComponent.nextGenerationIds.some((id) => item.id == id.toString())
          );
          this.filtersForm.controls.fondosDropdown.setValue(fondosWithoutNextGenrationIds);
          this.applyFilters();
          this.router.navigate(['subventions', 'search'], { queryParams: {} });
        }, 0);
      }
    });

    route.queryParams.pipe(take(1)).subscribe((qp) => {
      if (qp.withFiltersFromAlerts) {
        let alert: Alert | undefined = undefined;

        alert = (this.subventionsStateService.alerts as any).items.find(
          (ts) => ts.id === Number(qp.withFiltersFromAlerts)
        );

        setTimeout(() => {
          if (alert) {
            this.setAlertsData(alert);
            this.applyFilters();
          }
          this.applyingFilters = false;
        }, 2000);
      }
    });
  }

  ngOnInit(): void {
    if (
      this.applyingFilters &&
      this.subventionsStateService.filters.filters &&
      this.subventionsStateService.filters.filters.length > 0
    ) {
      this.filters = this.subventionsStateService.filters;
      this.setData();
      this.populateDropdowns();
      this.applyingFilters = false;
    } else {
      this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetFilters)).subscribe(() => {
        setTimeout(() => {
          this.filters = this.subventionsStateService.filters;
          this.setData();
          this.populateDropdowns();
          this.applyingFilters = false;
        }, 0);
      });
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  applyFilters(): void {
    this.filtersForm.markAllAsTouched();

    if (this.filtersForm.valid) {
      this.loading = true;

      this.subventionsStateService.updateSubventionsMenuFilters({
        ...this.subventionsStateService.filters,
        selectedFilters: {
          texto: this.filtersForm.controls.texto.value,

          presupuesto: (this.filtersForm.controls.presupuesto as FormArray)
            .getRawValue()
            .filter((v) => v.checked)
            .map((v) => v.value)
            .reduce((acc, val) => acc.concat(val), [])
            .reduce((acc, val) => acc.concat(val), []),

          fondos: (this.filtersForm.controls.fondosDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),

          finalidad: (this.filtersForm.controls.finalidadDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),

          instrumento_ayuda: (this.filtersForm.controls.instrumento_ayuda as FormArray)
            .getRawValue()
            .filter((v) => v.checked)
            .map((v) => String(v.value))
            .reduce((acc, val) => acc.concat(val), []),

          comunidades: (this.filtersForm.controls.comunidadesDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),

          provincias: (this.filtersForm.controls.provinciasDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),

          sector_economico: (this.filtersForm.controls.sector_economicoDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),

          organismo_convocante: (this.filtersForm.controls.organismo_convocante as FormArray)
            .getRawValue()
            .filter((v) => v.checked)
            .map((v) => String(v.value))
            .reduce((acc, val) => acc.concat(val), []),

          estado: (this.filtersForm.controls.estado as FormArray)
            .getRawValue()
            .filter((v) => v.checked)
            .map((v) => String(v.value))
            .reduce((acc, val) => acc.concat(val), []),

          beneficiarios: (this.filtersForm.controls.beneficiarios as FormArray)
            .getRawValue()
            .filter((v) => v.checked)
            .map((v) => String(v.value))
            .reduce((acc, val) => acc.concat(val), []),

          fecha_publicacion_desde: moment(this.filtersPublishmentDateRangeForm.controls.start.value)
            .format('DD/MM/YYYY')
            .toString(),

          fecha_publicacion_hasta: moment(this.filtersPublishmentDateRangeForm.controls.end.value)
            .format('DD/MM/YYYY')
            .toString(),

          fecha_finalizacion_desde: moment(this.filtersFinalizationDateRangeForm.controls.start.value)
            .format('DD/MM/YYYY')
            .toString(),
          fecha_finalizacion_hasta: moment(this.filtersFinalizationDateRangeForm.controls.end.value)
            .format('DD/MM/YYYY')
            .toString(),
        },
      });
    }
  }

  setData(): void {
    this.subventionsStateService.filters.filters.forEach((filter) => {
      // this.location.replaceState('/subventions/search');
      if (filter.items) {
        filter.items.forEach((item, i) => {
          (this.filtersForm.controls[filter.attr] as FormArray).controls.push(
            this.fb.group({
              checked: false,
              label: item.label,
              value: Array.isArray(item.value) ? [item.value] : item.value,
            })
          );

          if (this.subventionsStateService.filters.selectedFilters) {
            const value = {
              checked:
                this.subventionsStateService.filters.selectedFilters[filter.attr] &&
                this.subventionsStateService.filters.selectedFilters[filter.attr].length > 0
                  ? (this.subventionsStateService.filters.selectedFilters[filter.attr] as any)[0].to ||
                    (this.subventionsStateService.filters.selectedFilters[filter.attr] as any)[0].from
                    ? (this.subventionsStateService.filters.selectedFilters[filter.attr] as any).some((v) => {
                        return v.from === (item.value as any)[0].from && v.to === (item.value as any)[0].to;
                      })
                      ? true
                      : false
                    : this.subventionsStateService.filters.selectedFilters[filter.attr].includes(String(item.value))
                  : false,
              label: item.label,
              value: Array.isArray(item.value) ? [item.value] : item.value,
            };
            (this.filtersForm.controls[filter.attr] as FormArray).at(i).patchValue(value);
          }
        });
      }
    });
  }

  populateDropdowns(): void {
    this.dropdownComunidadesList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'comunidades')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });

    this.dropdownProvinciasList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'provincias')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });

    this.dropdownSectorEconomicoList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'sector_economico')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });

    this.dropdownFondosList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'fondos')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });

    this.dropdownFinalidadList = this.subventionsStateService.filters.filters
      .find((f) => f.attr === 'finalidad')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
  }

  toggleMenuFilters(): void {
    this.subventionsStateService.updateSubventionsMenuFilters({
      ...this.subventionsStateService.filters,
      openedMenu: false,
    });
  }

  resetFilters(): void {
    this.filtersForm.patchValue({
      texto: '',
      fondosDropdown: [],
      finalidadDropdown: [],
      comunidadesDropdown: [],
      provinciasDropdown: [],
      sector_economicoDropdown: [],
    });

    (this.filtersForm.controls.presupuesto as FormArray).clear();
    (this.filtersForm.controls.instrumento_ayuda as FormArray).clear();
    (this.filtersForm.controls.fondos as FormArray).clear();
    (this.filtersForm.controls.finalidad as FormArray).clear();
    (this.filtersForm.controls.comunidades as FormArray).clear();
    (this.filtersForm.controls.provincias as FormArray).clear();
    (this.filtersForm.controls.sector_economico as FormArray).clear();
    (this.filtersForm.controls.organismo_convocante as FormArray).clear();
    (this.filtersForm.controls.beneficiarios as FormArray).clear();
    (this.filtersForm.controls.estado as FormArray).clear();

    this.filtersPublishmentDateRangeForm.patchValue({
      start: undefined,
      end: undefined,
    });

    this.filtersFinalizationDateRangeForm.patchValue({
      start: undefined,
      end: undefined,
    });

    this.subventionsStateService.updateSubventionsMenuFilters({
      ...this.subventionsStateService.filters,
      selectedFilters: {},
    });

    setTimeout(() => {
      this.setData();

      if (this.router.url.includes('withFiltersFromAlerts')) {
        this.router.navigate(['subventions', 'search']);
      }
    }, 100);
  }

  castToFormGroup(ac: AbstractControl): FormGroup {
    return ac as FormGroup;
  }

  setAlertsData(alert: Alert): void {
    this.filtersForm.patchValue({
      texto: alert.busqueda.texto,
    });

    ['organismo_convocante', 'beneficiarios', 'estado', 'presupuesto', 'instrumento_ayuda'].forEach((category) => {
      alert.busqueda[category].forEach((x) => {
        return (
          (this.filtersForm.controls[category] as FormArray).controls.find(
            (c) =>
              JSON.stringify(c.value.value[0][0]).toString() == JSON.stringify(x).toString() ||
              JSON.stringify(c.value.value[0][0]).toString() == x
          ) as FormGroup
        ).controls.checked.setValue(true);
      });
    });

    ['fondos', 'comunidades', 'provincias', 'sector_economico', 'finalidad'].forEach((category) => {
      this.filtersForm.controls[`${category}Dropdown`].setValue(
        alert.busqueda[category].map((x) => {
          return {
            id: String(x),
            itemName: this.subventionsStateService.filters.filters
              .find((f) => f.attr === category)
              .items.find((i) => String(i.value) == x).label,
            name: this.utilsService.removeDiacritics(
              this.subventionsStateService.filters.filters
                .find((f) => f.attr === category)
                .items.find((i) => String(i.value) == x).label
            ),
          };
        })
      );
    });

    this.filtersPublishmentDateRangeForm.patchValue({
      start: alert.busqueda.fecha_publicacion_desde
        ? moment(alert.busqueda.fecha_publicacion_desde, 'DD/MM/YYYY').toDate()
        : undefined,
      end: alert.busqueda.fecha_publicacion_hasta
        ? moment(alert.busqueda.fecha_publicacion_hasta, 'DD/MM/YYYY').toDate()
        : undefined,
    });

    this.filtersFinalizationDateRangeForm.patchValue({
      start: alert.busqueda.fecha_finalizacion_desde
        ? moment(alert.busqueda.fecha_finalizacion_desde, 'DD/MM/YYYY').toDate()
        : undefined,
      end: alert.busqueda.fecha_finalizacion_hasta
        ? moment(alert.busqueda.fecha_finalizacion_hasta, 'DD/MM/YYYY').toDate()
        : undefined,
    });
  }
}
