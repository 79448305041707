import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CollectiveAgreementsFilters } from '@app/modules/ceoe/models/collective-agreement';
import {
  GetCollectiveAgreements,
  GetFilters,
} from '@app/modules/ceoe/store/collective-agreements/collective-agreements.actions';
import { CollectiveAgreementsStateService } from '@app/modules/ceoe/store/collective-agreements/collective-agreements.service';
import { UtilsService } from '@app/services/utils.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import moment from 'moment';

@Component({
  selector: 'app-collective-agreements-search-filters',
  templateUrl: './collective-agreements-search-filters.component.html',
  styleUrls: ['./collective-agreements-search-filters.component.scss'],
})
export class CollectiveAgreementsSearchFiltersComponent implements OnInit {
  filters?: CollectiveAgreementsFilters;
  filtersForm: FormGroup;
  // filtersPublishmentDateRangeForm: FormGroup;
  loading = true;
  applyingFilters = true;

  estado_vigenciaList = [];
  selectedestado_vigenciaItems = [];
  dropdownestado_vigenciaSettings = {
    text: 'Estado de vigencia',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  naturalezaList = [];
  selectednaturalezaItems = [];
  dropdownnaturalezaSettings = {
    text: 'Naturaleza',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  ambito_funcionalList = [];
  selectedambito_funcionalItems = [];
  dropdownambito_funcionalSettings = {
    text: 'Ámbito funcional',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  ambito_territorialList = [];
  selectedambito_territorialItems = [];
  dropdownambito_territorialSettings = {
    text: 'Ámbito territorial',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  ambito_personalList = [];
  selectedambito_personalItems = [];
  dropdownambito_personalSettings = {
    text: 'Ámbito personal',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  cnaeList = [];
  selectedcnaeItems = [];
  dropdowncnaeSettings = {
    text: 'CNAE',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  sindicatosList = [];
  selectedsindicatosItems = [];
  dropdownsindicatosSettings = {
    text: 'Sindicatos',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  variacion_salarial_pactadaList = [];
  selectedvariacion_salarial_pactadaItems = [];
  dropdownvariacion_salarial_pactadaSettings = {
    text: 'Variación salarial pactada',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
    singleSelection: true,
  };

  vocesList = [];
  selectedvocesItems = [];
  dropdownvocesSettings = {
    text: 'Voces',
    selectAllText: 'Seleccionarlas todas',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    groupBy: 'group',
    selectGroup: true,
    showCheckbox: true,
    singleSelection: false,
  };

  locationList = [];
  selectedlocationItems = [];
  dropdownlocationSettings = {
    text: 'Ámbito geográfico',
    selectAllText: 'Seleccionarlas todas',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    showCheckbox: true,
  };

  constructor(
    public collectiveAgreementsStateService: CollectiveAgreementsStateService,
    private fb: FormBuilder,
    private actions: Actions,
    private location: Location,
    private utilsService: UtilsService,
    private router: Router
  ) {
    this.filtersForm = this.fb.group({
      titulo: [this.collectiveAgreementsStateService.filters.selectedFilters.titulo || ''],
      cod_convenio: [this.collectiveAgreementsStateService.filters.selectedFilters.cod_convenio || ''],
      denominacion: [this.collectiveAgreementsStateService.filters.selectedFilters.denominacion || ''],
      nif: [this.collectiveAgreementsStateService.filters.selectedFilters.nif || ''],
      search: [this.collectiveAgreementsStateService.filters.selectedFilters.search || ''],
      fecha_vigenciaStart:
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.fecha_vigencia?.from
          ? moment(this.collectiveAgreementsStateService.filters.selectedFilters.fecha_vigencia.from as string).format(
              'YYYY-MM-DD'
            )
          : '',
      fecha_vigenciaEnd:
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.fecha_vigencia?.to
          ? moment(this.collectiveAgreementsStateService.filters.selectedFilters.fecha_vigencia.to as string).format(
              'YYYY-MM-DD'
            )
          : '',
      fecha_publicacionStart:
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.fecha_publicacion?.from
          ? moment(
              this.collectiveAgreementsStateService.filters.selectedFilters.fecha_publicacion.from as string
            ).format('YYYY-MM-DD')
          : '',
      fecha_publicacionEnd:
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.fecha_publicacion?.to
          ? moment(this.collectiveAgreementsStateService.filters.selectedFilters.fecha_publicacion.to as string).format(
              'YYYY-MM-DD'
            )
          : '',
      estado_vigencia: this.fb.array([]),
      variacion_salarial_pactada: this.fb.array([]),
      naturaleza: this.fb.array([]),
      ambito_funcional: this.fb.array([]),
      ambito_territorial: this.fb.array([]),
      ambito_personal: this.fb.array([]),
      cnae: this.fb.array([]),
      sindicatos: this.fb.array([]),
      voces: this.fb.array([]),
      location: this.fb.array([]),
      tipo_tramiteDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.tipo_tramite
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.tipo_tramite as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.collectiveAgreementsStateService.filters.filters
                  .find((f) => f.attr === 'tipo_tramite')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'tipo_tramite')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      estado_vigenciaDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.estado_vigencia
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.estado_vigencia as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.collectiveAgreementsStateService.filters.filters
                  .find((f) => f.attr === 'estado_vigencia')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'estado_vigencia')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      variacion_salarial_pactadaDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.variacion_salarial_pactada
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.variacion_salarial_pactada as boolean[]).map(
              (c) => {
                return {
                  id: String(c),
                  itemName: this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'variacion_salarial_pactada')
                    .items.find((i) => Boolean(i.value) == c).label,
                  name: this.utilsService.removeDiacritics(
                    this.collectiveAgreementsStateService.filters.filters
                      .find((f) => f.attr === 'variacion_salarial_pactada')
                      .items.find((i) => Boolean(i.value) == c).label
                  ),
                };
              }
            )
          : [],
      ],
      naturalezaDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.naturaleza
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.naturaleza as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.collectiveAgreementsStateService.filters.filters
                  .find((f) => f.attr === 'naturaleza')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'naturaleza')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      ambito_funcionalDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.ambito_funcional
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.ambito_funcional as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.collectiveAgreementsStateService.filters.filters
                  .find((f) => f.attr === 'ambito_funcional')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'ambito_funcional')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      ambito_territorialDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.ambito_territorial
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.ambito_territorial as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.collectiveAgreementsStateService.filters.filters
                  .find((f) => f.attr === 'ambito_territorial')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'ambito_territorial')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      ambito_personalDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.ambito_personal
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.ambito_personal as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.collectiveAgreementsStateService.filters.filters
                  .find((f) => f.attr === 'ambito_personal')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'ambito_personal')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      cnaeDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.cnae
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.cnae as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.collectiveAgreementsStateService.filters.filters
                  .find((f) => f.attr === 'cnae')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'cnae')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      sindicatosDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.sindicatos
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.sindicatos as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.collectiveAgreementsStateService.filters.filters
                  .find((f) => f.attr === 'sindicatos')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'sindicatos')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      vocesDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.voces
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.voces as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.collectiveAgreementsStateService.filters.filters
                  .find((f) => f.attr === 'voces')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'voces')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
      locationDropdown: [
        this.collectiveAgreementsStateService.filters.selectedFilters &&
        this.collectiveAgreementsStateService.filters.selectedFilters.location
          ? (this.collectiveAgreementsStateService.filters.selectedFilters.location as string[]).map((c) => {
              return {
                id: String(c),
                itemName: this.collectiveAgreementsStateService.filters.filters
                  .find((f) => f.attr === 'location')
                  .items.find((i) => String(i.value) == c).label,
                name: this.utilsService.removeDiacritics(
                  this.collectiveAgreementsStateService.filters.filters
                    .find((f) => f.attr === 'location')
                    .items.find((i) => String(i.value) == c).label
                ),
              };
            })
          : [],
      ],
    });

    this.selectedcnaeItems = this.filtersForm.controls.cnaeDropdown.value;
    this.selectedambito_funcionalItems = this.filtersForm.controls.ambito_funcionalDropdown.value;
    this.selectednaturalezaItems = this.filtersForm.controls.naturalezaDropdown.value;
    this.selectedambito_territorialItems = this.filtersForm.controls.ambito_territorialDropdown.value;
    this.selectedambito_personalItems = this.filtersForm.controls.ambito_personalDropdown.value;
    this.selectedsindicatosItems = this.filtersForm.controls.sindicatosDropdown.value;
    this.selectedestado_vigenciaItems = this.filtersForm.controls.estado_vigenciaDropdown.value;
    this.selectedvariacion_salarial_pactadaItems = this.filtersForm.controls.variacion_salarial_pactadaDropdown.value;
    this.vocesList = this.filtersForm.controls.vocesDropdown.value;

    // this.filtersPublishmentDateRangeForm = this.fb.group({
    //   start: [
    //     this.collectiveAgreementsStateService.filters.selectedFilters &&
    //     this.collectiveAgreementsStateService.filters.selectedFilters.fecha_publicacion_desde
    //       ? moment(this.collectiveAgreementsStateService.filters.selectedFilters.fecha_publicacion_desde, 'DD/MM/YYYY').toDate()
    //       : undefined,
    //   ],
    //   end: [
    //     this.collectiveAgreementsStateService.filters.selectedFilters &&
    //     this.collectiveAgreementsStateService.filters.selectedFilters.fecha_publicacion_hasta
    //       ? moment(this.collectiveAgreementsStateService.filters.selectedFilters.fecha_publicacion_hasta, 'DD/MM/YYYY').toDate()
    //       : undefined,
    //   ],
    // });

    this.actions.pipe(ofActionSuccessful(GetCollectiveAgreements)).subscribe(() => {
      this.loading = false;
    });
  }

  ngOnInit(): void {
    if (
      this.applyingFilters &&
      this.collectiveAgreementsStateService.filters.filters &&
      this.collectiveAgreementsStateService.filters.filters.length > 0
    ) {
      this.filters = this.collectiveAgreementsStateService.filters;
      this.setData();
      this.populateDropdowns();
      this.applyingFilters = false;
    } else {
      this.actions.pipe(ofActionSuccessful(GetFilters)).subscribe(() => {
        setTimeout(() => {
          this.filters = this.collectiveAgreementsStateService.filters;
          this.setData();
          this.populateDropdowns();
          this.applyingFilters = false;
        }, 0);
      });
    }
  }

  applyFilters(): void {
    this.filtersForm.markAllAsTouched();

    if (this.filtersForm.valid) {
      this.loading = true;
      this.collectiveAgreementsStateService.updateCollectiveAgreementsMenuFilters({
        ...this.collectiveAgreementsStateService.filters,
        selectedFilters: {
          search: this.filtersForm.controls.search.value,
          titulo: this.filtersForm.controls.titulo.value,
          cod_convenio: this.filtersForm.controls.cod_convenio.value,
          denominacion: this.filtersForm.controls.denominacion.value,
          nif: this.filtersForm.controls.nif.value,
          fecha_vigencia: {
            from: this.filtersForm.controls.fecha_vigenciaStart.value
              ? moment(this.filtersForm.controls.fecha_vigenciaStart.value).format('YYYY-MM-DD').toString()
              : '',
            to: this.filtersForm.controls.fecha_vigenciaEnd.value
              ? moment(this.filtersForm.controls.fecha_vigenciaEnd.value).format('YYYY-MM-DD').toString()
              : '',
          },
          fecha_publicacion: {
            from: this.filtersForm.controls.fecha_publicacionStart.value
              ? moment(this.filtersForm.controls.fecha_publicacionStart.value).format('YYYY-MM-DD').toString()
              : '',
            to: this.filtersForm.controls.fecha_publicacionEnd.value
              ? moment(this.filtersForm.controls.fecha_publicacionEnd.value).format('YYYY-MM-DD').toString()
              : '',
          },
          sindicatos: (this.filtersForm.controls.sindicatosDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),
          cnae: (this.filtersForm.controls.cnaeDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),
          ambito_territorial: (this.filtersForm.controls.ambito_territorialDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),
          ambito_personal: (this.filtersForm.controls.ambito_personalDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),
          ambito_funcional: (this.filtersForm.controls.ambito_funcionalDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),
          naturaleza: (this.filtersForm.controls.naturalezaDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),
          estado_vigencia: (this.filtersForm.controls.estado_vigenciaDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),
          variacion_salarial_pactada: (this.filtersForm.controls.variacion_salarial_pactadaDropdown as FormArray).value
            .map((v) => Boolean(v.id))
            .reduce((acc, val) => acc.concat(val), []),
          voces: (this.filtersForm.controls.vocesDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),
          location: (this.filtersForm.controls.locationDropdown as FormArray).value
            .map((v) => String(v.id))
            .reduce((acc, val) => acc.concat(val), []),
        },
      });
    }
  }

  setData(): void {
    this.collectiveAgreementsStateService.filters.filters.forEach((filter) => {
      this.location.replaceState('/collective-agreements/search');
      if (filter.items) {
        filter.items.forEach((item, i) => {
          (this.filtersForm.controls[filter.attr] as FormArray).controls.push(
            this.fb.group({
              checked: false,
              label: item.label,
              value: Array.isArray(item.value) ? [item.value] : item.value,
            })
          );

          if (this.collectiveAgreementsStateService.filters.selectedFilters) {
            const value = {
              checked:
                this.collectiveAgreementsStateService.filters.selectedFilters[filter.attr] &&
                typeof this.collectiveAgreementsStateService.filters.selectedFilters[filter.attr] === 'boolean'
                  ? this.collectiveAgreementsStateService.filters.selectedFilters[filter.attr]
                  : typeof this.collectiveAgreementsStateService.filters.selectedFilters[filter.attr] === 'string' ||
                    Array.isArray(this.collectiveAgreementsStateService.filters.selectedFilters[filter.attr])
                  ? (this.collectiveAgreementsStateService.filters.selectedFilters[filter.attr] as string | string[])
                      .length > 0
                    ? (this.collectiveAgreementsStateService.filters.selectedFilters[filter.attr] as any)[0].to ||
                      (this.collectiveAgreementsStateService.filters.selectedFilters[filter.attr] as any)[0].from
                      ? (this.collectiveAgreementsStateService.filters.selectedFilters[filter.attr] as any).some(
                          (v) => {
                            return v.from === (item.value as any)[0].from && v.to === (item.value as any)[0].to;
                          }
                        )
                        ? true
                        : false
                      : (
                          this.collectiveAgreementsStateService.filters.selectedFilters[filter.attr] as
                            | string[]
                            | string
                        ).includes(String(item.value))
                    : false
                  : false,
              label: item.label,
              value: Array.isArray(item.value) ? [item.value] : item.value,
            };
            (this.filtersForm.controls[filter.attr] as FormArray).at(i).patchValue(value);
          }
        });
      }
    });
  }

  populateDropdowns(): void {
    this.ambito_funcionalList = this.collectiveAgreementsStateService.filters.filters
      .find((f) => f.attr === 'ambito_funcional')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
    this.cnaeList = this.collectiveAgreementsStateService.filters.filters
      .find((f) => f.attr === 'cnae')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
    this.naturalezaList = this.collectiveAgreementsStateService.filters.filters
      .find((f) => f.attr === 'naturaleza')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
    this.sindicatosList = this.collectiveAgreementsStateService.filters.filters
      .find((f) => f.attr === 'sindicatos')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
    this.estado_vigenciaList = this.collectiveAgreementsStateService.filters.filters
      .find((f) => f.attr === 'estado_vigencia')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
    this.variacion_salarial_pactadaList = this.collectiveAgreementsStateService.filters.filters
      .find((f) => f.attr === 'variacion_salarial_pactada')
      .items.map((i) => {
        return {
          id: Boolean(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
    this.ambito_territorialList = this.collectiveAgreementsStateService.filters.filters
      .find((f) => f.attr === 'ambito_territorial')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
    this.ambito_personalList = this.collectiveAgreementsStateService.filters.filters
      .find((f) => f.attr === 'ambito_personal')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
    this.vocesList = [];
    this.collectiveAgreementsStateService.filters.filters
      .find((f) => f.attr === 'voces')
      .items.map((i) => {
        i.items.map((i2) => {
          this.vocesList.push({
            id: String(i2.value),
            itemName: i2.label,
            // name: this.utilsService.removeDiacritics(i.label),
            group: i.label,
            color: i.color,
          });
        });
      });

    //   {
    //     "id": "3289",
    //     "itemName": "Gobierno de Cantabria - Agenda",
    //     "group": "Agenda Autonómica"
    // }

    this.locationList = this.collectiveAgreementsStateService.filters.filters
      .find((f) => f.attr === 'location')
      .items.map((i) => {
        return {
          id: String(i.value),
          itemName: i.label,
          name: this.utilsService.removeDiacritics(i.label),
        };
      });
  }

  toggleMenuFilters(): void {
    this.collectiveAgreementsStateService.updateCollectiveAgreementsMenuFilters({
      ...this.collectiveAgreementsStateService.filters,
      openedMenu: false,
    });
  }

  resetFilters(): void {
    this.filtersForm.patchValue({
      search: '',
      titulo: '',
      cod_convenio: '',
      denominacion: '',
      nif: '',
      fecha_vigenciaStart: '',
      fecha_vigenciaEnd: '',
      fecha_publicacionStart: '',
      fecha_publicacionEnd: '',
      variacion_salarial_pactada: [],
      vocesDropdown: [],
      locationDropdown: [],
      estado_vigenciaDropdown: [],
      variacion_salarial_pactadaDropdown: [],
      naturalezaDropdown: [],
      ambito_funcionalDropdown: [],
      ambito_territorialDropdown: [],
      ambito_personalDropdown: [],
      cnaeDropdown: [],
      sindicatosDropdown: [],
    });

    (this.filtersForm.controls.estado_vigencia as FormArray).clear();
    (this.filtersForm.controls.variacion_salarial_pactada as FormArray).clear();
    (this.filtersForm.controls.naturaleza as FormArray).clear();
    (this.filtersForm.controls.ambito_funcional as FormArray).clear();
    (this.filtersForm.controls.ambito_territorial as FormArray).clear();
    (this.filtersForm.controls.ambito_personal as FormArray).clear();
    (this.filtersForm.controls.cnae as FormArray).clear();
    (this.filtersForm.controls.sindicatos as FormArray).clear();
    (this.filtersForm.controls.voces as FormArray).clear();
    (this.filtersForm.controls.location as FormArray).clear();

    // this.filtersPublishmentDateRangeForm.patchValue({
    //   start: undefined,
    //   end: undefined,
    // });

    this.collectiveAgreementsStateService.updateCollectiveAgreementsMenuFilters({
      ...this.collectiveAgreementsStateService.filters,
      selectedFilters: {},
    });

    setTimeout(() => {
      this.setData();

      if (this.router.url.includes('withFiltersFromAlerts')) {
        this.router.navigate(['collective-agreements', 'search']);
      }
    }, 100);
  }

  castToFormGroup(ac: AbstractControl): FormGroup {
    return ac as FormGroup;
  }

  checkIfDropdownHasColorField(dropdown: any[]): boolean {
    return dropdown.length > 0 && dropdown[0].hasOwnProperty('color') && dropdown[0].color;
  }
}
