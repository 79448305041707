<div fxLayout="row" class="filters" fxFlexAlign="center center" fxLayoutAlign="space-between center">
  <div fxFlex="1" class="search-container main-search-container">
    <div *ngIf="appStateService.search_text_visible$ | async" [formGroup]="search">
      <mat-form-field class="search_input" appearance="fill">
        <mat-icon id="main-search-icon" (click)="$event.stopPropagation(); seek(start, end)">search</mat-icon>
        <input
          matInput
          autocomplete="off"
          placeholder="Búsqueda"
          type="text"
          id="isearch"
          formControlName="text_search"
          [readonly]="!exec_query"
          (keyup.enter)="$event.stopPropagation(); seek(start, end)"
        />
        <button
          mat-button
          matSuffix
          mat-icon-button
          matTooltip="Mostrar editor de búsquedas"
          aria-label="Tipo de buscador"
          (click)="$event.stopPropagation(); openSearchTypeOperatorsDialog()"
          *ngIf="!router.url.includes('vigilancia/') && !router.url.includes('europe/')"
        >
          <mat-icon
            style="width: 24px; height: 24px; font-size: 24px"
            [ngStyle]="{ color: getRealm() === KC_REALM_CEOE ? 'var(--color-primary-main)' : 'white' }"
            >grid_view</mat-icon
          >
        </button>
        <button mat-button matSuffix mat-icon-button (click)="$event.stopPropagation(); handleSearchFilters()">
          <mat-icon>tune</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="advanced-filters" *ngIf="showFiltersInSearchInput">
      <div
        class="search-type-container"
        [formGroup]="searchTypeFG"
        *ngIf="router.url.includes('vigilancia/') || router.url.includes('europe/')"
      >
        <p class="title-field">Tipo de búsqueda</p>
        <mat-radio-group formControlName="searchType">
          <mat-radio-button color="primary" [value]="searchType.value" *ngFor="let searchType of searchTypeOptions">
            {{ searchType.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxD fxFlex="1">
        <form [formGroup]="range" *ngIf="appStateService.dates_visible$ | async">
          <p class="title-field">Periodo de búsqueda</p>
          <mat-form-field class="my-form-field" appearance="fill">
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate [formControl]="start" readonly placeholder="Fecha inicio" />
              <input matEndDate [formControl]="end" readonly placeholder="Fecha fin" style="border: none" />
            </mat-date-range-input>
            <p
              *ngIf="rangePresetSelected"
              style="
                opacity: 0.85;
                font-size: 13.5px;
                position: absolute;
                top: -6.5px;
                right: 2px;
                background: #dbdcdd;
                border-radius: 3px;
                overflow: hidden;
                padding: 6px;
                color: black;
              "
            >
              {{ rangePresetSelected }}
            </p>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker
              (opened)="openedDatepicker()"
              (closed)="closedDatepicker()"
              #picker
            ></mat-date-range-picker>
          </mat-form-field>
        </form>
      </div>
      <div
        fxFlex="1"
        [ngStyle]="{
          display: !sectorReports ? 'block' : 'none'
        }"
      >
        <p class="title-field" *ngIf="clipping">Tipo de prensa</p>
        <section class="section-checkboxes" [formGroup]="clippingFG" *ngIf="clipping">
          <mat-checkbox formControlName="escrita" color="primary">Escrita</mat-checkbox>
          <mat-checkbox formControlName="digital" color="primary">Digital</mat-checkbox>
        </section>
        <p class="title-field" *ngIf="!clipping && !sectorReports">Fuentes</p>
        <angular2-multiselect
          fixSearchBug
          fixAccents
          id="am2-1"
          class="angular2-multiselect-search angular2-multiselect-search-contexts"
          name="spiderGroups"
          [data]="dropdownSpiderGroupsList"
          [(ngModel)]="selectedSpiderGroupsItems"
          [settings]="dropdownSpiderGroupsSettings"
          (click)="handleAngularMultiselectHeight('am2-1')"
          *ngIf="!clipping && !sectorReports"
        >
        </angular2-multiselect>
      </div>
      <div fxFlex="1" *ngIf="regulatorio || vigilancia">
        <p class="title-field">Contextos</p>
        <angular2-multiselect
          fixSearchBug
          fixAccents
          id="am2-2"
          class="angular2-multiselect-search"
          name="contexts"
          [data]="dropdownContextList"
          [(ngModel)]="selectedContextItems"
          [settings]="dropdownContextSettings"
          (click)="handleAngularMultiselectHeight('am2-2')"
        >
        </angular2-multiselect>
      </div>
      <div fxFlex="1" *ngIf="!vigilancia && !europa">
        <p class="title-field">Temas</p>
        <angular2-multiselect
          fixSearchBug
          fixAccents
          id="am2-3-categories"
          class="angular2-multiselect-search"
          name="categories"
          [data]="dropdownCategoryList"
          [(ngModel)]="selectedCategoryItems"
          [settings]="dropdownCategorySettings"
          (click)="handleAngularMultiselectHeight('am2-3-categories')"
        >
          <c-item>
            <ng-template let-item="item">
              <label
                style="padding: 4px 8px; border-radius: 4px"
                [style.background-color]="getCategoryColor(item.itemName)"
              >
                {{ item.itemName }}</label
              >
            </ng-template>
          </c-item>
        </angular2-multiselect>
      </div>
      <div fxFlex="1" *ngIf="!europa">
        <p class="title-field">Región</p>
        <angular2-multiselect
          fixSearchBug
          fixAccents
          id="am2-4"
          class="angular2-multiselect-search"
          name="regions"
          [data]="dropdownRegionsList"
          [(ngModel)]="selectedRegionsItems"
          [settings]="dropdownRegionsSettings"
          (click)="handleAngularMultiselectHeight('am2-4')"
        >
        </angular2-multiselect>
      </div>
      <button mat-button color="primary" class="btn-search" (click)="seek(start, end)">Buscar</button>
    </div>
  </div>
  <div fxLayout="row">
    <div class="container-document-items">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menuAlerts"
        *ngIf="(appStateService.notifications$ | async).length > 0"
      >
        <mat-icon>campaign</mat-icon>
        <div class="badgeMenuItem badgeMenuItemSmall" *ngIf="hasNotificationsNotViewed()"></div>
      </button>
      <button
        mat-icon-button
        routerLink="/favorites/search"
        routerLinkActive="router-link-active"
        matTooltip="Mis búsquedas"
      >
        <mat-icon>star_border</mat-icon>
        <div class="badgeMenuItem" *ngIf="documentsMenuItemsData.favorites > 0">
          {{ documentsMenuItemsData.favorites }}
        </div>
      </button>
      <button mat-icon-button routerLink="/favorites" routerLinkActive="router-link-active" matTooltip="Mis carpetas">
        <mat-icon>folder_open</mat-icon>
        <div class="badgeMenuItem" *ngIf="documentsMenuItemsData.savedSearches > 0">
          {{ documentsMenuItemsData.savedSearches }}
        </div>
      </button>
    </div>
    <div>
      <button mat-icon-button *ngIf="(appStateService.interesting_visible$ | async) !== undefined">
        <mat-icon
          *ngIf="!(appStateService.interesting_visible$ | async)"
          (click)="hideInteresting()"
          matTooltip="Mostrar todas las noticias"
          >visibility_off</mat-icon
        >
        <mat-icon
          *ngIf="appStateService.interesting_visible$ | async"
          (click)="showInteresting()"
          matTooltip="Ocultar noticias no interesantes"
          >visibility</mat-icon
        >
      </button>
    </div>
    <div
      class="iconosperfil user-data"
      fxLayout="row"
      [matMenuTriggerFor]="menuAvatar"
      *ngIf="authStateService.userConfig$ | async"
    >
      <ngx-avatar
        [name]="
          ((authStateService.userConfig$ | async).first_name | slice: 0:1) +
          ' ' +
          ((authStateService.userConfig$ | async).last_name | slice: 0:1)
        "
        [bgColor]="getRealm() === KC_REALM_CEOE ? 'var(--color-primary-main)' : 'var(--tailwind-color-white)'"
        size="32"
        class="my-avatar"
        textSizeRatio="2.3"
        [fgColor]="
          getRealm() === KC_REALM_CEOE ? 'var(--tailwind-color---tailwind-color-gray-900)' : 'var(--color-primary-main)'
        "
      ></ngx-avatar>
      <div></div>
      <mat-icon style="color: rgba(255, 255, 255, 0.4)">expand_more</mat-icon>
    </div>
    <mat-menu #menuAvatar="matMenu">
      <div
        class="name"
        style="
          display: flex;
          flex-direction: column;
          padding: 0 16px;
          padding-bottom: 8px;
          border-bottom: 1px solid #00000025;
        "
      >
        <p style="font-size: 16px; margin: 0; font-weight: 600; color: var(--tailwind-color-gray-800)">
          {{
            (authStateService.userConfig$ | async).first_name + ' ' + (authStateService.userConfig$ | async).last_name
          }}
        </p>
        <p style="font-size: 14px; margin: 0; font-weight: 400; color: var(--tailwind-color-gray-600)">
          {{ (authStateService.userConfig$ | async).interface_config.company.name }}
        </p>
      </div>
      <button mat-menu-item (click)="viewMyData()">
        <mat-icon mat-list-icon color="primary" style="color: rgba(0, 0, 0, 0.54)">manage_accounts</mat-icon>
        <span>Ver mis datos</span>
      </button>
      <button mat-menu-item (click)="updatePassword()">
        <mat-icon mat-list-icon color="primary" style="color: rgba(0, 0, 0, 0.54)">lock</mat-icon>
        <span>Cambio de contraseña</span>
      </button>
      <button mat-menu-item routerLink="/help">
        <mat-icon mat-list-icon color="primary" style="color: rgba(0, 0, 0, 0.54)">help</mat-icon>
        <span>Página de Ayuda</span>
      </button>
      <button mat-menu-item routerLink="/login" (click)="logout()">
        <mat-icon mat-list-icon color="warn" style="color: var(--tailwind-color-red-600)">power_settings_new</mat-icon>
        <span style="color: var(--tailwind-color-red-600)">Cerrar sesión</span>
      </button>
    </mat-menu>
    <mat-menu #menuAlerts="matMenu" class="container-mat-menu-alerts-panel">
      <div class="container-mat-menu-alerts">
        <div class="title">
          <p>Notificaciones</p>
          <p (click)="readAllNotifications()">Marcar todo como leído</p>
        </div>
        <div class="notifications">
          <div class="notification" *ngFor="let notification of appStateService.notifications$ | async">
            <div class="content">
              <div class="label">
                <div class="dot-not-viewed" *ngIf="!notification.viewed"></div>
                <p>{{ notification.title }}</p>
              </div>
              <p class="desc">{{ notification.desc }}</p>
            </div>
            <button
              mat-icon-button
              color="primary"
              (click)="openNotificationUrl(notification)"
              *ngIf="notification.type === 'download' && notification.url"
            >
              <mat-icon>download</mat-icon>
            </button>
            <mat-spinner
              [diameter]="20"
              style="margin-right: 10px"
              *ngIf="notification.type === 'download' && !notification.url"
            ></mat-spinner>
          </div>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
