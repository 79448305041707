import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AppStateService } from '@app/state/app/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MagazineStateService } from '@app/modules/documents/store/magazine/magazine.service';
import { MagazineSearch } from '@app/modules/documents/store/models/magazine.state.model';
import { DocumentStateService } from '@app/modules/documents/store/report/documents.service';
import { DocumentPayload } from '@app/modules/documents/store/models/document.state.model';
import { interval, Subject, Subscription } from 'rxjs';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import _moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DraftStateService } from '@app/modules/documents/store/draft/draft.service';
import { Draft, DraftContent } from '@app/modules/documents/store/models/draft.state.model';
import { DocDetailComponent } from '@app/modules/shared/components/doc-detail/doc-detail.component';
import { CustomDocumentComponent } from '../custom-document/custom-document.component';
import { NameDialogComponent } from './name-dialog/name-dialog.component';
import { UIStatus } from '@app/state/models/app.state.model';
import moment from 'moment';
import { MagazineService } from '@app/services/magazine.service';
import { DraftService } from '@app/services/draft.service';

export interface DragDropListItem {
  id: string;
  title: string;
  description: string;
}

export interface DragDropSearchItem {
  id: string;
  title: string;
  source: string;
  abstract: string;
  date: string;
}

export interface SearchItem {
  category: string;
  items: DragDropSearchItem[];
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-magazine-draft',
  templateUrl: './magazine-draft.component.html',
  styleUrls: ['./magazine-draft.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MagazineDraftComponent implements OnInit, AfterViewInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  subscription: Subscription;
  categories = [];
  history = [];
  searchesLoadedCount = 0;
  provisionCost = 0;
  downloadCost = 0;

  range = new FormGroup({
    start: new FormControl(_moment().startOf('day').toISOString()),
    end: new FormControl(_moment().endOf('day').toISOString()),
  });

  addRange = new FormGroup({
    rangeStart: new FormControl(_moment().startOf('day').toISOString()),
    rangeEnd: new FormControl(_moment().endOf('day').toISOString()),
  });

  magazineID: number;
  draftID: number;

  constructor(
    public appStateService: AppStateService,
    public magazineStateService: MagazineStateService,
    public documentStateService: DocumentStateService,
    private _draftStateService: DraftStateService,
    private _draftService: DraftService,
    private _snackbarStateService: SnackbarStateService,
    private magazineService: MagazineService,
    public dialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    if (!this.magazineStateService.selectedMagazine) this._router.navigate(['/clipping/magazine']);
    this.appStateService.addHistoryUrl('/clipping/magazine');

    this._route.paramMap.subscribe((params) => {
      this.magazineID = +params.get('magazineID');

      if (!params.get('draftID')) {
        // this._setPayload();
        this._setupData();
      } else {
        this.draftID = +params.get('draftID');
        this._draftStateService.getDraft(+params.get('magazineID'), +params.get('draftID'));
        this._loadDraft();
      }
    });
  }

  ngOnInit(): void {
    this.appStateService.showTitle();
    this.appStateService.hideDates();
    this.appStateService.hideSearchText();
    this.appStateService.hideSubForm();
  }

  ngAfterViewInit(): void {
    this.range.valueChanges.pipe(debounceTime(200)).subscribe((event) => {
      if (event.start && event.end) {
        this._setPayload();
        this._setupData();
      }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.appStateService.addHistoryUrl(undefined);
  }

  drop(event: CdkDragDrop<DragDropListItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

    this.categories = this.categories.map((category) => {
      const unique = category.items
        .map((e) => e['title'])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((obj) => category.items[obj])
        .map((e) => category.items[e]);

      // if (category.items.length !== unique.length) this._snackbarStateService.show('Duplicado eliminado', 5000, 'X');

      category.items = unique;

      return category;
    });
  }

  hide(selectedOptions) {
    var selected = selectedOptions.selected.map((s) => s.value);

    this.categories = this.categories.map((category) => {
      let diff = category.items.filter((item) => !selected.includes(item));

      if (diff.length !== category.items.length) {
        const history = JSON.parse(JSON.stringify(category));
        this.history.push(history);
      }

      category.items = diff;
      return category;
    });

    this._snackbarStateService.show('Se han eliminado ' + selected.length + ' artículo(s)', 5000, 'X');

    this.calculateTotalCost();
  }

  checkHistory(name: string) {
    return this.history.filter((category) => category.category === name).length === 1;
  }

  undoHide(name: string) {
    let history;

    this.categories = this.categories.map((category) => {
      if (category.category === name) {
        let restore = this.history.filter((history) => history.category === name)[0];
        this.history = this.history.filter((history) => history !== restore);
        return restore;
      } else return category;
    });

    this._snackbarStateService.show('Se ha deshecho la operación de borrado', 5000, 'X');

    this.calculateTotalCost();
  }

  saveDraft(draftID?: number) {
    let draft: Draft = {
      dossier_id: this.magazineID,
      date_start: this.range.get('start').value.toISOString(),
      date_end: this.range.get('end').value.toISOString(),
      data: this.categories.map((search) => {
        return {
          category: search.category,
          docs: search.items,
        };
      }),
      visible: true,
    };

    if (!this.draftID) {
      // const dialogRef = this.dialog.open(MagazineDraftEstimatedPriceDialogComponent, {
      //   width: '100%',
      //   maxWidth: '520px',
      //   data: {
      //     cost: totalCost,
      //     provision: totalProvision,
      //   },
      // });

      // dialogRef.afterClosed().subscribe((data) => {
      //   if (data) {
      //     this._draftStateService.addDraft(draft);
      //   }
      // });

      this._draftStateService.addDraft(draft);
    } else {
      draft.id = this.draftID;
      this._draftStateService.editDraft(draft);
    }
  }

  detail(event, draft: DraftContent) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DocDetailComponent, {
      width: '80%',
      height: '80%',
      data: { docID: draft.id, s3_file_url: draft.s3_file_url, draftContent: draft },
    });

    dialogRef.afterClosed().subscribe(() => this.appStateService.addHistoryUrl('/clipping/magazine'));
  }

  edit(event, draft: DraftContent) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(NameDialogComponent, {
      width: '50%',
      height: '30%',
      data: { title: draft.title },
    });

    dialogRef.afterClosed().subscribe((data: string) => (data ? (draft.title = data) : draft.title));
  }

  deleteDraft() {
    this._draftStateService.deleteDraft(this.magazineID, this.draftID);
  }

  showMagazine() {
    this._router.navigate(['clipping', 'magazine', this.magazineID, 'view', this.draftID]);
  }

  public manual(searchItem: SearchItem) {
    const dialogRef = this.dialog.open(CustomDocumentComponent, {
      width: '80%',
      height: '80%',
      data: { searchItem: searchItem },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.categories = this.categories.map((search) => {
          let localSearch = JSON.parse(JSON.stringify(search));

          if (search === searchItem) localSearch.items.push(result);

          return localSearch;
        });
      }
    });
  }

  addCategoryNews(searchItem: SearchItem, searchIndex: number) {
    const search = this.magazineStateService.selectedMagazine.searches[searchIndex];
    let payload: DocumentPayload = {
      dateParsed_end: _moment(this.addRange.get('rangeEnd').value).endOf('day').toISOString(),
      dateParsed_start: _moment(this.addRange.get('rangeStart').value).startOf('day').toISOString(),
      docTypes: ['html', 'pdf'],
      from: 0,
      sort: ['-dateParsed'],
      spiderIds: search.spiders.map((spider) => spider.toString()),
      text: search.search,
    };

    if (!this.draftID) {
      this.documentStateService.addDocumentsToCategory({ title: search.category, request: payload });
    } else {
      this.documentStateService.getDocumentsNoState(payload).subscribe((response) => {
        response.map((document) => {
          let item: DragDropSearchItem = {
            id: document.source.docId,
            abstract: document.source.abstract,
            date: document.source.dateParsed,
            source: document.source.toString(),
            title: document.source.titleParsed,
          };

          searchItem.items.push(item);
        });
      });
    }
  }

  private _setPayload() {
    this.magazineStateService.selectedMagazine.searches.map((search: MagazineSearch) => {
      let payload: DocumentPayload = {
        dateParsed_end: _moment(this.range.get('end').value).endOf('day').toISOString(),
        dateParsed_start: _moment(this.range.get('start').value).startOf('day').toISOString(),
        docTypes: ['html', 'pdf'],
        from: 0,
        sort: ['-dateParsed'],
        spiderIds: search.spiders.map((spider) => spider.toString()),
        text: search.search,
        section: search.section ?? [],
      };
      if (payload.section.length === 0) {
        delete payload.section;
      }
      this.documentStateService.getDocuments([
        { title: search.category, request: payload, hideLoadingWhenFinish: false },
      ]);
    });
  }

  private _setupData() {
    // let sort = this.magazineStateService.selectedMagazine.searches.map((search) => search.category);

    // this.documentStateService.unsetDocuments();
    // this.subscription = this.documentStateService.searchs$.subscribe((searchs) => {
    //   this.categories = searchs.map((document) => {
    //     document = JSON.parse(JSON.stringify(document));
    //     document.documents = document.documents.sort((a, b) => (a.docType < b.docType ? 1 : -1));

    //     let data = {
    //       category: document.title,
    //       items: document.documents.map((document) => {
    //         return {
    //           id: document.docId,
    //           title: document.titleParsed,
    //           source: document.spiderId.toString(),
    //           abstract: document.abstract,
    //           date: document.dateParsed,
    //         };
    //       }),
    //     };

    //     return data;
    //   });

    //   if (sort.length === this.categories.length) {
    //     this.categories = this._sorting(this.categories, sort, 'category');
    //   }

    //   this.categories = this.categories.map((category) => {
    //     const unique = category.items
    //       .map((e) => e['title'])
    //       .map((e, i, final) => final.indexOf(e) === i && i)
    //       .filter((obj) => category.items[obj])
    //       .map((e) => category.items[e]);

    //     category.items = unique;
    //     return category;
    //   });

    //   if (this.magazineStateService.selectedMagazine.searches.length == this.searchesLoadedCount) {
    //     this.appStateService.updateUIStatus(UIStatus.loaded);
    //   }

    //   this.searchesLoadedCount++;
    // });

    this.appStateService.updateUIStatus(UIStatus.loading);

    this.magazineService.postDossierGenerate(this.magazineStateService.selectedMagazine).subscribe((data) => {
      this.categories = data.data.data.map((documentCat) => {
        documentCat = JSON.parse(JSON.stringify(documentCat));
        documentCat.docs = documentCat.docs.sort((a, b) => (a.docType < b.docType ? 1 : -1));

        let data = {
          category: documentCat.category,
          items: documentCat.docs,
        };

        this.provisionCost = documentCat.docs.reduce((sum, doc) => sum + doc.provision, 0);

        return data;
      });

      if (this.magazineStateService.selectedMagazine.searches.filter((s) => s.show).length === this.categories.length) {
        this.categories = this._sorting(
          this.categories,
          this.magazineStateService.selectedMagazine.searches.map((s) => s.category),
          'category'
        );
        this.calculateTotalCost();
        this.sendDossierToCedro(data.data.data);
        this.appStateService.updateUIStatus(UIStatus.loaded);
      }

      // this.categories = this.categories.map((category) => {
      //   const unique = category.items
      //     .map((e) => e['title'])
      //     .map((e, i, final) => final.indexOf(e) === i && i)
      //     .filter((obj) => category.items[obj])
      //     .map((e) => category.items[e]);

      //   category.items = unique;
      //   return category;
      // });

      // if (
      //   this.magazineStateService.selectedMagazine.searches.filter((s) => s.show).length ==
      //   this.categories.length
      // ) {

      // }
    });
  }

  private _loadDraft() {
    this.documentStateService.unsetDocuments();
    this._draftStateService.selectedDraft$.subscribe((draft) => {
      if (draft) {
        draft = JSON.parse(JSON.stringify(draft));
        this.categories = draft.data.map((category) => {
          let data = { category: category.category, items: category.docs };
          return data;
        });

        this.sendDossierToCedro(draft.data);
      }
    });
  }

  private _sorting(array, order, key) {
    array.sort(function (a, b) {
      var A = a[key],
        B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  }

  calculateTotalCost(): void {
    this.downloadCost = this.categories.reduce((sum, category) => {
      return sum + category.items.reduce((itemSum, item) => itemSum + (item.cost || 0), 0);
    }, 0);
  }

  sendDossierToCedro(data: any): void {
    const dataToken = this._draftService.cedroTokenFlowData$.getValue();

    if (dataToken) {
      this._draftService
        .cedroSendDossier({
          token: dataToken.data.trace_token,
          userID: dataToken.data.user_id,
          batch_additional_info: '',
          articles: data.flatMap((category) =>
            category.docs
              .filter((doc) => doc.article_id)
              .map((doc) => {
                return {
                  article_id: doc.article_id,
                  article_additional_info: doc.title,
                };
              })
          ),
        })
        .subscribe();
    }
  }
}
