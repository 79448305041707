import { Component, Input, OnChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, of as ObservableOf } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.less'],
})
export class ChipsAutocompleteComponent implements OnChanges {
  @Input() list: string[];
  @Input() selectedItems: string[];
  @Input() hint: string;
  @Input() label: string;
  @Output() itemsUpdatedEvent = new EventEmitter<string[]>();
  @Output() allItemsSelected = new EventEmitter<boolean>();

  @ViewChild('itemInput', { static: false }) itemInput: ElementRef<HTMLInputElement>;

  formGroup: FormGroup;
  workingList: string[] = [];
  filteredList: Observable<string[]>;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() {
    this.formGroup = new FormGroup({
      items: new FormControl(''),
    });
  }

  ngOnChanges() {
    if (this.list && this.selectedItems) {
      this.workingList = this.list.filter((x) => !this.selectedItems.includes(x));
      this.filteredList = ObservableOf(this.workingList);
      this.updateList();
    }
  }

  get items() {
    return this.formGroup.get('items');
  }

  private setFilters() {
    this.filteredList = this.items.valueChanges.pipe(
      startWith(null),
      map((email: string | '') => {
        return this.filteredList ? this._filter(email) : this.workingList.slice();
      })
    );
  }

  private updateList() {
    this.setFilters();
    this.itemsUpdatedEvent.emit(this.selectedItems);
    this.allItemsSelected.emit(this.workingList.length === 0);
  }

  remove(item: string): void {
    const index = this.selectedItems.indexOf(item);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.workingList.push(item);
      this.filteredList = ObservableOf(this.workingList);
      this.updateList();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItems.push(event.option.value);
    this.itemInput.nativeElement.value = '';
    this.itemInput.nativeElement.blur();
    this.items.setValue('');
    this.workingList = this.workingList.filter((x) => !this.selectedItems.includes(x));
    this.filteredList = ObservableOf(this.workingList);
    this.updateList();
  }

  checkAll() {
    this.selectedItems = JSON.parse(JSON.stringify(this.list));
    this.workingList = [];
    this.filteredList = ObservableOf(this.workingList);
    this.updateList();
  }

  uncheckAll() {
    this.selectedItems = [];
    this.workingList = JSON.parse(JSON.stringify(this.list));
    this.filteredList = ObservableOf(this.workingList);
    this.updateList();
  }

  private _filter(filter: string): string[] {
    if (filter) {
      let filterValue: string;
      filterValue = filter.toLowerCase();
      return this.workingList.filter((item: string) => item.toLowerCase().includes(filterValue));
    } else {
      return this.workingList;
    }
  }
}
