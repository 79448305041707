import { Component, OnDestroy, OnInit } from '@angular/core';

import { environment } from '@env/environment';
import { AppStateService } from '@app/state/app/app.service';

import _moment from 'moment';

@Component({
  selector: 'app-pdfs',
  templateUrl: './pdfs.component.html',
  styleUrls: ['./pdfs.component.scss'],
})
export class PdfsComponent implements OnInit {
  readonly LOGOURL: string = environment.logoApp;

  constructor(public appStateService: AppStateService) {}

  ngOnInit() {
    this.appStateService.hideTitle();
    this.appStateService.hideDates();
    this.appStateService.hideSearchText();
    this.appStateService.hideSubForm();
    this.appStateService.hideSideMenu();
  }
}
