import { Injectable } from '@angular/core';
import { Router, NavigationEnd, UrlSegmentGroup, PRIMARY_OUTLET } from '@angular/router';
import { AppStateService } from '@app/state/app/app.service';
import { filter, map } from 'rxjs/operators';
import _moment from 'moment';
import { SectorReportsWebSocketService } from './sector-reports-web-socket.service';
import { RegulatoryDraftsService } from '@app/modules/documents/services/regulatory-drafts.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { REGULATORYDRAFTSSOCKETSURL } from '@env/environment';

@Injectable()
export class RouterExtService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(
    private router: Router,
    private appStateService: AppStateService,
    private authStateService: AuthStateService,
    private sectorReportsWebSocketService: SectorReportsWebSocketService,
    private regulatoryDraftsService: RegulatoryDraftsService
  ) {
    this.currentUrl = this.router.url;

    this.router.events
      .pipe(
        filter((event: unknown): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.slice(1))
      )
      .subscribe((url) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = url;

        if (
          !this.previousUrl ||
          (!this.previousUrl.startsWith(this.currentUrl) && !this.previousUrl.startsWith(this.currentUrl))
        ) {
          this.clearSearchFilters();
        }

        this.connectSectorReportsWebSocketService();
        this.connectRegulatoryDraftsWebSocketService();
      });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getCurrentUrl() {
    return this.currentUrl;
  }

  public getFirstSegment() {
    const tree = this.router.parseUrl(this.currentUrl);
    const primary: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    return '/' + primary.segments[0].path;
  }

  public goToPrevious() {
    if (this.getPreviousUrl() == this.getCurrentUrl()) {
      this.router.navigate([this.getFirstSegment()]);
    } else {
      this.router.navigate([this.getCurrentUrl()]);
    }
  }

  clearSearchFilters(): void {
    this.appStateService.updateSearchContent('');
    this.appStateService.updateSearchFilters({
      dates: {
        start: _moment().startOf('month').toISOString(),
        end: _moment().endOf('month').toISOString(),
      },
      spiders: [],
      categories: [],
      regions: [],
      contexts: [],
      clippingsSelected: {
        escrita: false,
        digital: false,
      },
      searchType: 'literal',
    });
  }

  connectSectorReportsWebSocketService(): void {
    if (this.currentUrl.startsWith('sector-reports') && !this.sectorReportsWebSocketService.socket) {
      this.sectorReportsWebSocketService.connect('wss://echo.websocket.org');
    } else if (!this.currentUrl.startsWith('sector-reports')) {
      this.sectorReportsWebSocketService.closeConnection();
    }
  }

  connectRegulatoryDraftsWebSocketService(): void {
    if (this.authStateService.userConfig) {
      const hasAccess = this.authStateService.userConfig.interface_config.menu_items
        .find((mi) => mi.id === 4)
        .items.find((i) => i.path === '/regulatorio/drafts');

      if (!this.regulatoryDraftsService.socket && hasAccess) {
        this.regulatoryDraftsService.connect(REGULATORYDRAFTSSOCKETSURL);
      }
    }
  }
}
