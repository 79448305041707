<div class="dialog-container" *ngIf="!initiative.date">
  <h1 mat-dialog-title>{{ initiative.title }}</h1>
  <div mat-dialog-content class="container">
    <div class="chips">
      <div class="chip" *ngIf="initiative.legislature">
        <p>Legislatura</p>
        {{ initiative.legislature }}
      </div>
      <div class="chip" *ngIf="initiative.author">
        <p>Autor</p>
        {{ initiative.author }}
      </div>
      <div class="chip">
        <p>Presentado</p>
        {{ initiative.presented | date }}
      </div>
      <div class="chip" *ngIf="initiative.qualified">
        <p>Calificado</p>
        {{ initiative.qualified | date }}
      </div>
      <div class="chip" *ngIf="initiative.processing_type">
        <p>Tipo</p>
        {{ initiative.processing_type }}
      </div>
      <div class="chip" *ngIf="initiative.actual_status">
        <p>Estado</p>
        {{ initiative.actual_status }}
      </div>
      <div class="chip" *ngIf="initiative.result">
        <p>Estado</p>
        {{ initiative.result }}
      </div>
    </div>
    <div class="divider"></div>
    <div fxLayout="row" class="row">
      <mat-list fxFlex="100%">
        <div mat-subheader>Documentos</div>
        <input class="file-input" type="file" (change)="onFileSelected($event)" #fileUpload />
        <div fxLayout="row" fxLayoutGap="10px" [formGroup]="documentFormGroup" class="file-upload">
          <button mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
            {{ fileName || 'No se ha seleccionado ningún archivo.' }}
          </button>
          <button
            class="add-document"
            mat-button
            (click)="newDocument()"
            matTooltip="Añadir documento"
            *ngIf="fileName"
          >
            Enviar documento
          </button>
        </div>
        <div class="progress-congress">
          <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress">
          </mat-progress-bar>
          <mat-icon class="cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress">delete_forever</mat-icon>
        </div>
        <div class="table-list">
          <mat-list-item *ngFor="let doc of initiative.documents">
            <div mat-line>
              {{ doc.label }} <span>{{ doc.date | date }}</span>
              <a href="{{ doc.link }}" target="_blank">Ver documento</a>
            </div>
            <mat-divider></mat-divider>
          </mat-list-item>
          <mat-list-item *ngFor="let doc of initiative.user_docs">
            <div mat-line>
              {{ doc.titulo }} <span>{{ doc.date | date }}</span>
              <a href="{{ doc.s3_file_url }}" target="_blank">Ver documento</a>
            </div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </div>
      </mat-list>
    </div>
    <div fxLayout="row" class="row">
      <mat-list fxFlex="100%" *ngIf="initiative.deadlines">
        <div class="header">
          <div mat-subheader>Plazos</div>
          <mat-form-field>
            <mat-label>Descripción vencimiento</mat-label>
            <input matInput required formControlName="label" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="picker" required formControlName="timestamp" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <button mat-raised-button color="primary" class="upload-btn" (click)="newDeadline()">Nuevo plazo</button>
        </div>
        <div class="items">
          <div class="table-list">
            <mat-list-item *ngFor="let deadline of initiative.deadlines">
              <div mat-line>{{ deadline.label }}</div>
              <div mat-line>{{ deadline.timestamp.substring(0, 10) | date }}</div>
              <mat-divider></mat-divider>
            </mat-list-item>
          </div>
        </div>
      </mat-list>
    </div>
    <div fxLayout="row" class="row">
      <mat-list fxFlex="100%" *ngIf="initiative.journal_session">
        <div mat-subheader>Diarios de sesiones</div>
        <div class="items">
          <div class="table-list">
            <mat-list-item *ngFor="let journal_session of initiative.journal_sessions">
              <div mat-line [matTooltip]="journal_session.details">{{ journal_session.details }}</div>
              <div mat-line>
                <span>{{ journal_session.date | date }}</span
                >&nbsp;<a href="{{ journal_session.link }}" target="_blank">{{ journal_session.subject }}</a>
              </div>
              <mat-divider></mat-divider>
            </mat-list-item>
          </div>
        </div>
      </mat-list>
      <mat-list fxFlex="100%">
        <div mat-subheader>Histórico</div>
        <div class="items">
          <div class="table-list">
            <mat-list-item *ngFor="let past_procedure of initiative.past_procedures">
              <div mat-line>{{ past_procedure.comision }}</div>
              <div mat-line>
                <mat-chip-list>
                  <span *ngIf="past_procedure.from" color="warn">Desde {{ past_procedure.from | date }}</span>
                  <span *ngIf="past_procedure.to">
                    <span *ngIf="past_procedure.from">Hasta&nbsp;</span>
                    {{ past_procedure.to | date }}
                  </span>
                  <mat-chip *ngIf="past_procedure.status">{{ past_procedure.status }}</mat-chip>
                </mat-chip-list>
              </div>
              <mat-divider></mat-divider>
            </mat-list-item>
          </div>
        </div>
      </mat-list>
    </div>
  </div>
</div>

<div class="dialog-container dialog-container-new" *ngIf="initiative.date">
  <h1 mat-dialog-title>{{ initiative.title }}</h1>
  <div mat-dialog-content class="container">
    <div class="chips">
      <div class="chip" *ngIf="initiative.parlament">
        <p>Parlamento</p>
        {{ initiative.parlament }}
      </div>
      <div class="chip" *ngIf="initiative.expedient">
        <p>Expediente</p>
        {{ initiative.expedient }}
      </div>
      <div class="chip" *ngIf="initiative.legislature">
        <p>Legislatura</p>
        {{ initiative.legislature }} Legislatura
      </div>
      <div class="chip">
        <p>Fecha</p>
        {{ initiative.date | date }}
      </div>
      <div class="chip" *ngIf="initiative.proponentes">
        <p>Proponentes</p>
        {{ initiative.proponentes }}
      </div>
      <div class="chip" *ngIf="initiative.type">
        <p>Tipo</p>
        {{ initiative.type | initiativeNameMapping }}
      </div>
      <div class="chip" *ngIf="initiative.pending">
        <p>Estado</p>
        Abierto
      </div>
      <div class="chip" *ngIf="!initiative.pending">
        <p>Estado</p>
        Cerrado
      </div>
    </div>
    <a href="{{ initiative.url }}" target="_blank" *ngIf="initiative.url">Ver enlace oficial</a>
    <div class="divider"></div>
    <!-- <div fxLayout="row" class="row">
      <mat-list fxFlex="100%">
        <div mat-subheader>Documentos ({{ initiative.documents.length }})</div>
        <div class="table-list" *ngIf="initiative.documents.length > 0" style="max-height: 500px; overflow: auto">
          <mat-list-item *ngFor="let doc of initiative.documents">
            <div mat-line>
              {{ doc.title }} <span></span>
              <a href="{{ doc.url }}" target="_blank">Ver documento</a>
            </div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </div>
        <div class="table-list" *ngIf="initiative.documents.length === 0">
          <mat-list-item>
            <div mat-line>- Sin documentos -</div>
          </mat-list-item>
        </div>
      </mat-list>
    </div> -->
    <div fxLayout="row" class="row">
      <mat-list fxFlex="100%">
        <div mat-subheader>Trámites ({{ initiative.tramits.length }})</div>
        <div class="table-list" *ngIf="initiative.tramits.length > 0" style="max-height: 500px; overflow: auto">
          <mat-list-item *ngFor="let tramit of initiative.tramits" style="height: fit-content; min-height: 48px">
            <div mat-line style="row-gap: 4px; flex-wrap: nowrap; align-items: center">
              <span
                style="
                  margin-left: 0;
                  min-width: 100px;
                  font-style: italic;
                  font-weight: 300;
                  flex: inherit;
                  margin-right: 16px;
                "
              >
                {{ tramit.date | date }}</span
              >
              {{ tramit.title }}
            </div>
            <mat-divider></mat-divider>
            <mat-accordion style="display: flex; flex: 1" *ngIf="tramit.docs.length > 0">
              <mat-expansion-panel style="box-shadow: none; background: transparent; width: 100%">
                <mat-expansion-panel-header>
                  <mat-panel-title color="primary"><a color="primary">Más información</a></mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let doc of tramit.docs">
                  {{ doc.title }} <span></span>
                  <a href="{{ doc.url }}" target="_blank" *ngIf="doc.url">Ver documento</a>
                </div>
                <mat-divider></mat-divider>
              </mat-expansion-panel>
            </mat-accordion>
            <p
              *ngIf="tramit.docs.length === 0"
              style="
                display: flex;
                flex: 1;
                padding-left: 40px;
                margin: 0;
                min-height: 48px;
                align-items: center;
                font-size: 14px;
              "
            ></p>
          </mat-list-item>
        </div>
        <div class="table-list" *ngIf="initiative.tramits.length === 0">
          <mat-list-item style="height: fit-content; min-height: 48px">
            <div mat-line style="row-gap: 4px; flex-wrap: nowrap; align-items: center">
              <span
                style="
                  margin-left: 0;
                  min-width: 100px;
                  font-style: italic;
                  font-weight: 300;
                  flex: inherit;
                  margin-right: 16px;
                "
              ></span>
            </div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </div>
      </mat-list>
    </div>
    <div fxLayout="row" class="row">
      <mat-list fxFlex="100%">
        <div mat-subheader>Plazos ({{ initiative.events.length }})</div>
        <div class="table-list" *ngIf="initiative.events.length > 0" style="max-height: 500px; overflow: auto">
          <mat-list-item *ngFor="let event of initiative.events" style="height: fit-content; min-height: 48px">
            <div mat-line style="row-gap: 4px; flex-wrap: nowrap; align-items: center">
              <span
                style="
                  margin-left: 0;
                  min-width: 100px;
                  font-style: italic;
                  font-weight: 300;
                  flex: inherit;
                  margin-right: 16px;
                "
              >
                {{ event.date | date }}</span
              >
              {{ event.title }}
            </div>
            <mat-divider></mat-divider>
            <mat-accordion style="display: flex; flex: 1" *ngIf="event.docs.length > 0">
              <mat-expansion-panel style="box-shadow: none; background: transparent; width: 100%">
                <mat-expansion-panel-header>
                  <mat-panel-title color="primary"><a color="primary">Más información</a></mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let doc of event.docs">
                  {{ doc.title }} <span></span>
                  <a href="{{ doc.url }}" target="_blank" *ngIf="doc.url">Ver documento</a>
                </div>
                <mat-divider></mat-divider>
              </mat-expansion-panel>
            </mat-accordion>
            <p
              *ngIf="event.docs.length === 0"
              style="
                display: flex;
                flex: 1;
                padding-left: 40px;
                margin: 0;
                min-height: 48px;
                align-items: center;
                font-size: 14px;
              "
            ></p>
          </mat-list-item>
        </div>
        <div class="table-list" *ngIf="initiative.events.length === 0">
          <mat-list-item style="height: fit-content; min-height: 48px">
            <div mat-line style="row-gap: 4px; flex-wrap: nowrap; align-items: center">
              <span
                style="
                  margin-left: 0;
                  min-width: 100px;
                  font-style: italic;
                  font-weight: 300;
                  flex: inherit;
                  margin-right: 16px;
                "
              ></span>
            </div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </div>
      </mat-list>
    </div>
  </div>
</div>
