import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { WidgetConfig } from '../../models/widget-config';
import { AppStateService } from '@app/state/app/app.service';
import { GridWidget } from '@app/models/User';
import { Router } from '@angular/router';
import { AppStatusService } from '@app/services/app-status.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css'],
})
export class PanelComponent implements OnInit, OnDestroy {
  @ViewChild('target', { read: ViewContainerRef }) target: ViewContainerRef;
  @Output() close = new EventEmitter();
  @Input() panel_config: GridWidget;

  public widgets = [];
  public grid = {};
  public maxWidth: number = 1200;

  constructor(
    private appStatusService: AppStatusService,
    private _appStateService: AppStateService,
    private cfr: ComponentFactoryResolver,
    private router: Router
  ) {}

  ngOnInit() {
    this.appStatusService.page_change.emit(this.router.url);

    this._appStateService.hideSideMenu();
    this._appStateService.hideTitle();

    if (this.panel_config) {
      let widgets = [];

      this.panel_config.layouts.forEach((layout) => {
        layout.widgets.forEach((widget) => {
          if (widget.type == 'linkpreview') widget = Object.assign(widget, { query: widget.query });
          else if (this.panel_config['query']) widget = Object.assign(widget, { query: this.panel_config.query });

          widgets.push(new WidgetConfig({ config: widget }));
        });
      });

      this.widgets = widgets;

      if (this.panel_config.gridsetup) this.grid = this.panel_config.gridsetup;

      this.onResize(window);
    }
  }

  ngOnDestroy(): void {
    this._appStateService.showSideMenu();
    this._appStateService.showTitle();
  }

  @HostListener('window:resize', ['$event.target'])
  onResize(window) {
    this.maxWidth = window.innerWidth;
  }

  addWidget(wc: WidgetConfig) {
    let factory = this.cfr.resolveComponentFactory(wc.getConstructor()),
      comp = this.target.createComponent(factory);
    comp.instance['wconfig'] = wc;
    return comp;
  }

  destroy() {
    this.close.emit(null);
  }
}
