<mat-progress-bar *ngIf="isLoadingCats" mode="query"></mat-progress-bar>
<h2 class="titlecat" *ngIf="context">Contexto</h2>
<a (click)="goToPage(getContextPage(context))" *ngIf="context" class="context"> {{ context.name }} </a>
<div class="navcat" *ngIf="!isLoadingCats">
  <h2 class="titlecat">Categorías</h2>
  <mat-expansion-panel *ngFor="let category of categories; let i = index" style="cursor: pointer">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ category.name }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let term of category.terms">
      <div class="catitem">
        <a (click)="textsearch(term)"> {{ term }} </a>
      </div>
    </div>
  </mat-expansion-panel>
</div>
<ng-content></ng-content>
