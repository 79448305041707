import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[fixSearchBug]',
})
export class MultiselectSearchFixDirective {
  // trigger an additional change detection cycle
  @HostListener('keydown') onKeydownHandler() {
    // setTimeout(() => {});
    setTimeout(() => {
      (document.querySelector('.list-filter .c-input') as HTMLElement).blur();
      (document.querySelector('.list-filter .c-input') as HTMLElement).focus();
    }, 0);
  }
}
