export class Utils {
  static isArray(val: any) {
    return val.constructor.toString().match(/Array/);
  }
  static isObject(val: any) {
    return val.constructor.toString().match(/Object/);
  }
  static isString(val) {
    return typeof val == 'string';
  }
  static isFunction(val: any) {
    return typeof val == 'function';
  }
}
