import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  Injector,
} from '@angular/core';
import { WidgetConfig } from '../../models/widget-config';
import { ColLayoutComponent } from './col-layout.component';

@Component({
  selector: 'row-layout',
  templateUrl: './row-layout.component.html',
  styleUrls: ['./row-layout.component.css'],
})
export class RowLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('rlayout', { read: ViewContainerRef }) target: ViewContainerRef;
  @Input() wconfig?;

  extra_layout: string = '';
  layout_alignment: string = '';
  layout_gap: string = '';
  flex: number = 0;

  constructor(private cfr: ComponentFactoryResolver, private injector: Injector) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.wconfig && this.wconfig.config['layouts']) {
      this.generateLayout(this.wconfig.config['layouts']);
    } else if (this.wconfig && this.wconfig.config['widgets']) this.generateLayout(this.wconfig.config['widgets']);
  }

  createComponent(factory) {
    return this.target.createComponent(factory);
  }

  generateLayout(layouts: Array<any>) {
    if (layouts && layouts.length)
      layouts.forEach((layout) => {
        layout = Object.assign(layout, { query: this.wconfig.config['query'] });
        let wc = { config: layout },
          factory = this.cfr.resolveComponentFactory((wc as WidgetConfig).getConstructor());
        if (layout.type != 'col') {
          let f = this.cfr.resolveComponentFactory(ColLayoutComponent);
          let comp = this.createComponent(f);
          let comp2 = comp.instance['createComponent'](factory);
          comp2.instance['wconfig'] = wc;
          if (wc.config['width']) comp.instance['flex'] += wc.config['width'];
        } else {
          let comp = this.createComponent(factory);
          comp.instance['wconfig'] = wc;
        }
      });
  }
}
