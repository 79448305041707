<div class="wrapper">
  <h2>Guardar borrador</h2>
  <section>
    <div class="header">
      <p>El coste estimado para generar el borrador se descontará de los créditos.</p>
      <p style="font-weight: 400; display: flex; align-items: flex-end; gap: 6px">
        <span>Coste de descarga: {{ data.cost | number: '1.0-2' }}</span
        >€
      </p>
      <p style="font-weight: 400; display: flex; align-items: flex-end; gap: 6px">
        <span>Coste de disposición: {{ data.provision | number: '1.0-2' }}</span
        >€ - Ya descontado
      </p>
    </div>
  </section>
  <button mat-flat-button color="primary" (click)="submit()">Guardar borrador</button>
</div>
