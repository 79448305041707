<div class="errorbox" *ngIf="isRateLimitReached">
  Hemos encontrado un problema con las comunicaciones, inténtelo de nuevo más tarde...
</div>
<mat-card *ngIf="!isRateLimitReached" class="prev_card">
  <mat-card-header class="previewhead" fxLayoutAlign="space-between">
    <mat-card-title class="titlewidget" fxLayout="row">
      <div>{{ wconfig.config.title }}</div>
    </mat-card-title>
    <div>
      <a
        *ngIf="asyncTabs[0]"
        mat-button
        matSuffix
        mat-icon-button
        href="{{ asyncTabs[carouselIndex].content.url }}"
        target="_blank"
      >
        <mat-icon
          matTooltip="Abrir documento la Web"
          aria-hidden="false"
          aria-label="Abrir documento en la Web"
          style="color: grey"
        >
          link
        </mat-icon>
      </a>
      <a
        *ngIf="asyncTabs[0]"
        mat-button
        matSuffix
        mat-icon-button
        href="#/documents/open/{{ asyncTabs[carouselIndex].docId }}"
        target="_blank"
      >
        <mat-icon
          matTooltip="Abrir documento en Brainy"
          aria-hidden="false"
          aria-label="Abrir documento en Brainy"
          style="color: grey"
        >
          view_compact
        </mat-icon>
      </a>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group
      mat-align-tabs="start"
      (selectedTabChange)="onChange($event)"
      headerPosition="below"
      class="tabcontent"
    >
      <mat-tab *ngFor="let preview of asyncTabs; let i = index" label="Contenido {{ i + 1 }}">
        <app-contentcard [cardInfo]="preview.content"></app-contentcard>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
