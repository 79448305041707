import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IndicadorService } from '@app/modules/estadisticas/services/indicador.service';

@Component({
  selector: 'app-business-statistics-section-detail',
  templateUrl: './business-statistics-section-detail.component.html',
  styleUrls: ['./business-statistics-section-detail.component.scss'],
})
export class BusinessStatisticsSectionDetailComponent {
  themeId?: number;
  categoryId?: number;
  category?: any;
  data;

  constructor(private route: ActivatedRoute, private indicadorService: IndicadorService, private router: Router) {
    this.route.params.subscribe((params) => {
      this.themeId = parseInt(params['themeId']);
      this.categoryId = parseInt(params['categoryId']);

      this.getCategory();
    });
  }

  getCategory(): void {
    const request = {
      tematica: this.themeId,
    };

    this.indicadorService.getBusinessStatisticsCategories(request).subscribe((data) => {
      this.category = data.find((d) => d.id === this.categoryId);
    });
  }

  setData(data: any): void {
    this.data = data;
  }

  goBack(): void {
    this.router.navigate(['business-statistics', this.themeId]);
  }
}
