import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { Tender } from '@app/modules/ceoe/models/tender';
import { GetTender, UpdateTenderFavorite } from '@app/modules/ceoe/store/tenders/tenders.actions';
import { TendersStateService } from '@app/modules/ceoe/store/tenders/tenders.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tenders-search-detail',
  templateUrl: './tenders-search-detail.component.html',
  styleUrls: ['./tenders-search-detail.component.scss'],
})
export class TendersSearchDetailComponent implements OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  id = '';
  tender?: Tender;
  loading = true;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<TendersSearchDetailComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public matData: number,
    private activatedRoute: ActivatedRoute,
    private actions: Actions,
    private router: Router,
    private location: Location,
    private tendersStateService: TendersStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    setTimeout(() => {
      this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetTender)).subscribe(() => {
        setTimeout(() => {
          this.loading = false;
          this.tender = this.tendersStateService.tender;
          this.changeDetectorRef.detectChanges();
        }, 0);
      });

      this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(UpdateTenderFavorite)).subscribe(() => {
        setTimeout(() => {
          this.loading = false;
          this.tender = this.tendersStateService.tender;
          this.changeDetectorRef.detectChanges();
        }, 0);
      });

      this.tendersStateService.getTender(Number(this.matData));
    }, 0);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  saveItem() {
    if (this.tender.favorito) {
      this.tendersStateService.updateTenderFavoriteDelete(this.tender.id);
    } else {
      this.tendersStateService.updateTenderFavoriteAdd(this.tender.id);
    }
  }

  return(): void {
    this.location.back();
  }

  openUrlInNewWindow(url: string) {
    window.open(url, '_blank');
  }
}
