import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { WidgetConfig } from '@app/modules/br-widgets/models/widget-config';
import { AppStatusService } from '@app/services/app-status.service';
import { CompactType, GridsterConfig, GridsterItem, GridsterItemComponent, GridType } from 'angular-gridster2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chart-grid-container',
  templateUrl: './chart-grid-container.component.html',
  styleUrls: ['./chart-grid-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChartGridContainerComponent implements OnInit, OnDestroy {
  @ViewChild('target', { read: ViewContainerRef }) target: ViewContainerRef;
  @ViewChildren('cmp', { read: ViewContainerRef })
  components: QueryList<ViewContainerRef>;

  @Input() wconfigs: WidgetConfig[];
  @Input() gconfig: any;
  @Input() title: string;
  @Input() subtitle: string;

  options: GridsterConfig;
  dashboard: Array<GridsterItem> = [];
  itemToPush: GridsterItemComponent;
  percentLoad = 0;
  subscriptions: Subscription[] = [];
  static haschanged: boolean;

  constructor(
    private cfr: ComponentFactoryResolver,
    public changeDetector: ChangeDetectorRef,
    appStatus: AppStatusService
  ) {
    ChartGridContainerComponent.haschanged = false;
    this.subscriptions.push(
      appStatus.search_launch.subscribe((args) => {
        this.percentLoad = 2;
        this.changeDetector.detectChanges();
      })
    );
  }

  get statichaschanged() {
    return ChartGridContainerComponent.haschanged;
  }

  static haschanges(item: GridsterItem, itemComponent: GridsterItemComponent) {
    ChartGridContainerComponent.haschanged = true;
  }

  ngOnInit() {
    var cell_height = 250;
    var cell_width = 250;
    var maxcols = 100;
    var maxrows = 100;

    if (this.gconfig.cell_height) cell_height = this.gconfig.cell_height;
    if (this.gconfig.cell_width) cell_width = this.gconfig.cell_width;
    if (this.gconfig.maxcols) maxcols = this.gconfig.maxcols;
    if (this.gconfig.maxrows) maxrows = this.gconfig.maxrows;

    this.options = {
      gridType: GridType.VerticalFixed,
      compactEnabled: true,
      compactType: CompactType.CompactLeftAndUp,
      displayGrid: 'onDrag&Resize',
      pushItems: true,
      draggable: {
        enabled: true,
        stop: function (item, gridsterItem, event) {},
      },
      resizable: {
        enabled: true,
      },
      itemChangeCallback: ChartGridContainerComponent.haschanges,
      itemResizeCallback: function (gridsterItem, gridsterItemComponent) {
        let width = gridsterItemComponent.width;
        let height = gridsterItemComponent.height;
        if (gridsterItemComponent.item.target != '') {
          //gridsterItemComponent.item.target.instance.onResize(gridsterItemComponent);
        }
      },
      fixedColWidth: cell_width,
      fixedRowHeight: cell_height,
      margin: 10,
      maxCols: maxcols,
      maxRows: maxrows,
    };

    this.addItems();
  }

  changedOptions() {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  removeItem($event, item) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dashboard.splice(this.dashboard.indexOf(item), 1);
  }

  addItem() {
    this.dashboard.push({ x: 0, y: 0, cols: 1, rows: 1 });
  }

  addItems() {
    this.wconfigs.forEach((element, index) => {
      this.dashboard.push({
        cols: element.config.cols,
        rows: element.config.rows,
        y: element.config.y,
        x: element.config.x,
        dragEnabled: element.config.dragEnabled,
        resizeEnabled: element.config.resizeEnabled,
        title: element.config.title,
        target: '',
        config: '',
        type: element.config.type,
      });
    });
  }

  undo() {
    this.dashboard.forEach((element, index) => {
      element.cols = this.wconfigs[index].config.cols;
      element.rows = this.wconfigs[index].config.rows;
      element.y = this.wconfigs[index].config.y;
      element.x = this.wconfigs[index].config.x;
    });
    this.options.api.optionsChanged();
    ChartGridContainerComponent.haschanged = false;
  }

  save() {}

  ngAfterViewInit() {
    let components: any[] = this.components.toArray();
    let numwidgets = this.wconfigs.length;
    let incremental = 100 / numwidgets;

    this.wconfigs.forEach((element, index) => {
      if (element) {
        let factory = this.cfr.resolveComponentFactory(element.getConstructor());
        let comp = components[index].createComponent(factory);
        comp.instance['wconfig'] = element;
        comp.instance['isLoading'] = true;
        comp.instance.widgetIndex = index;
        comp.instance['options'] = {
          title: {
            text: element.config['title'],
            padding: [15, 0, 0, 20],
          },
          series: [],
          tooltip: {
            show: true, // include tooltip component for the feature
          },
          toolbox: {
            show: true,
            right: 25,
            top: 10,
            showTitle: false, // hide the default text so they don't overlap each other
            feature: {
              saveAsImage: {
                show: true,
                title: 'Descargar Imagen',
              },
              restore: {
                show: true,
                title: 'Resetear',
              },
            },
            tooltip: {
              // same as option.tooltip
              show: true,
              formatter: function (param) {
                return '<div>' + param.title + '</div>'; // user-defined DOM structure
              },
              backgroundColor: '#222',
              textStyle: {
                fontSize: 12,
              },
              extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);', // user-defined CSS styles
            },
          },
        };
        comp.instance['parent'] = this;
        this.dashboard[index].target = comp;
        if (comp.instance.seriesLoaded) {
          comp.instance.seriesLoaded.subscribe((e) => {
            this.dashboard[index].isLoading = false;
            this.percentLoad += incremental;
            this.changeDetector.detectChanges();
            if (this.percentLoad > 99) this.percentLoad = 0;
          });

          this.dashboard[index].isLoading = true;
        } else {
          this.dashboard[index].isLoading = false;
          this.percentLoad += incremental;
          this.changeDetector.detectChanges();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }

  initItem(item: GridsterItem, itemComponent: GridsterItemComponent) {}

  pushItem() {}

  getSearchTerms(): string {
    return (document.querySelector('.main-search-container #isearch') as HTMLInputElement)?.value ?? '';
  }
}
