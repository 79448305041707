import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdateAlert } from '@app/modules/ceoe/store/tenders/tenders.actions';
import { UtilsService } from '@app/services/utils.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Alert, AlertPostRequest, AlertUpdateRequest } from '../../../models/tender';
import { TendersStateService } from '../../../store/tenders/tenders.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { OperatorsDialogComponent } from '@app/modules/shared/components/operators-dialog/operators-dialog.component';
import { ENTER } from '@angular/cdk/keycodes';

export interface Chip {
  name: string;
  type: 'text' | 'operator';
  editing: boolean;
}

@Component({
  selector: 'app-tenders-alerts-edit-dialog',
  templateUrl: './tenders-alerts-edit-dialog.component.html',
  styleUrls: ['./tenders-alerts-edit-dialog.component.scss'],
})
export class TendersAlertsEditDialogComponent {
  readonly separatorKeysCodes: number[] = [ENTER];

  alertForm: FormGroup;
  objectChipFormArray: FormArray;
  loading = false;

  dropdownProvinciasList = [];
  selectedProvinciasItems = [];
  dropdownProvinciasSettings = {
    text: 'Provincias',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  dropdownSectoresList = [];
  selectedSectoresItems = [];
  dropdownSectoresSettings = {
    text: 'Sectores',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  dropdownImportesList = [];
  selectedImportesItems = [];
  dropdownImportesSettings = {
    text: 'Importes',
    selectAllText: 'Seleccionarlos todos',
    unSelectAllText: 'Eliminar Seleccion',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 999,
    showCheckbox: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Alert,
    public tendersStateService: TendersStateService,
    private actions: Actions,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private utilsService: UtilsService
  ) {
    this.alertForm = this.fb.group({
      titulo: [this.data.titulo, Validators.required],
      provinces: [[]],
      sections: [[]],
      imports: [[]],
      objeto: [this.data.busqueda.objeto],
      organismo: [this.data.busqueda.organismo],
    });

    this.objectChipFormArray = this.fb.array([]);

    this.setObjetoFieldValues(this.data.busqueda.objeto);

    this.populateProvinces();
    this.populateSections();
    this.populateImports();
  }

  save(): void {
    this.alertForm.markAllAsTouched();

    if (this.alertForm.valid) {
      this.loading = true;

      const object = [];

      const firstObject = this.objectChipFormArray.at(0) as FormGroup;
      const lastObject = this.objectChipFormArray.at(this.objectChipFormArray.length - 1) as FormGroup;

      if (firstObject && firstObject.controls.type.value === 'operator') {
        this.removeObjeto(0);
      }

      if (lastObject && lastObject.controls.type.value === 'operator') {
        this.removeObjeto(this.objectChipFormArray.length - 1);
      }

      this.objectChipFormArray.controls.forEach((c) => {
        const fg = c as FormGroup;
        object.push({
          name: fg.controls.name.value,
          type: fg.controls.type.value,
        });
      });

      const request: AlertUpdateRequest = {
        provincias: this.selectedProvinciasItems.map((spi) => String(spi.id)),
        cpvs: this.selectedSectoresItems.map((ssi) => String(ssi.id)),
        importes: this.selectedImportesItems.map((sii) => this.tendersStateService.imports[sii.id]),
        objeto: object,
        organismo: this.alertForm.controls.organismo.value,
        titulo: this.alertForm.controls.titulo.value,
        expediente: undefined,
        publicacion_desde: undefined,
        publicacion_hasta: undefined,
      };

      this.tendersStateService.updateAlert(request, this.data.id);

      this.actions.pipe(ofActionSuccessful(UpdateAlert)).subscribe(() => {
        this.dialog.closeAll();
      });
    }
  }

  populateProvinces(): void {
    this.dropdownProvinciasList = this.tendersStateService.provinces.map((p) => {
      return {
        id: p.code,
        itemName: p.nombre,
        name: this.utilsService.removeDiacritics(p.nombre),
      };
    });

    this.alertForm.controls.provinces.setValue(
      this.dropdownProvinciasList.filter((el) => {
        return this.data.busqueda.provincias
          .map((pc) => Number(pc))
          .some((f) => {
            return f === el.id;
          });
      })
    );
    this.selectedProvinciasItems = this.alertForm.controls.provinces.value;
  }

  populateSections(): void {
    this.dropdownSectoresList = this.tendersStateService.cpvs.map((c) => {
      return {
        id: Number(c.code),
        itemName: c.descripcion,
        name: this.utilsService.removeDiacritics(c.descripcion),
      };
    });

    this.alertForm.controls.sections.setValue(
      this.dropdownSectoresList.filter((el) => {
        return this.data.busqueda.cpvs
          .map((pc) => Number(pc))
          .some((f) => {
            return f === el.id;
          });
      })
    );
    this.selectedSectoresItems = this.alertForm.controls.sections.value;
  }

  populateImports(): void {
    this.dropdownImportesList = this.tendersStateService.imports.map((c, i) => {
      return {
        id: i,
        itemName: `De ${c.de}€ a ${c.hasta}€`,
      };
    });

    this.alertForm.controls.imports.setValue(
      this.dropdownImportesList.filter((el) => {
        return this.data.busqueda.importes
          .map((pc) => `De ${pc.de}€ a ${pc.hasta}€`)
          .some((f) => {
            return f === el.itemName;
          });
      })
    );
    this.selectedImportesItems = this.alertForm.controls.imports.value;
  }

  showOperatorsDialog(): void {
    this.dialog.open(OperatorsDialogComponent, {
      width: '100%',
      maxWidth: '650px',
    });
  }

  addObjeto(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.objectChipFormArray.push(this.fb.group({ name: [value.trim()], type: 'text', editing: false }));
      this.objectChipFormArray.push(this.fb.group({ name: ['AND'], type: 'operator', editing: false }));
    }

    if (input) {
      input.value = '';
    }
  }

  addOperator(): void {
    const lastObject = this.objectChipFormArray.at(this.objectChipFormArray.length - 1) as FormGroup;

    if (lastObject && lastObject.controls.type.value !== 'operator') {
      this.objectChipFormArray.push(this.fb.group({ name: ['AND'], type: 'operator', editing: false }));
    }
  }

  removeObjeto(index: number): void {
    this.objectChipFormArray.removeAt(index);
  }

  editObjeto(index: number): void {
    this.objectChipFormArray.at(index).get('editing')?.setValue(true);

    if (this.objectChipFormArray.at(index).get('type')?.value === 'operator') {
      if (this.objectChipFormArray.at(index).get('name')?.value === 'AND') {
        this.objectChipFormArray.at(index).get('name')?.setValue('OR');
      } else {
        this.objectChipFormArray.at(index).get('name')?.setValue('AND');
      }
    }
  }

  saveObjeto(index: number): void {
    const chipFormGroup = this.objectChipFormArray.at(index);
    if (chipFormGroup.valid) {
      const name = chipFormGroup.get('name')?.value;
      this.objectChipFormArray.at(index).get('name')?.setValue(name);
      this.objectChipFormArray.at(index).get('editing')?.setValue(false);
    }
  }

  splitStringWithOperators(input: string): string[] {
    const regex = /\s*(AND|OR)\s*/;
    return input.split(regex).filter((str) => str.trim() !== '');
  }

  setObjetoFieldValues(values: any): void {
    values.forEach((v) => {
      const formGroup = this.fb.group({
        name: v.name,
        type: v.type,
        editing: false,
      });

      this.objectChipFormArray.push(formGroup);
    });
  }
}
