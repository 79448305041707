import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetSubventionsFavorites, UpdateSubventionFavorite } from '../../store/subventions/subventions.actions';
import { SubventionsStateService } from '../../store/subventions/subventions.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SubventionsSearchDetailComponent } from '../subventions-search/subventions-search-detail/subventions-search-detail.component';
import { Subvention } from '../../models/subvention';

@Component({
  selector: 'app-subventions-favorites',
  templateUrl: './subventions-favorites.component.html',
  styleUrls: ['./subventions-favorites.component.scss'],
})
export class SubventionsFavoritesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  componentDestroyed$: Subject<boolean> = new Subject();
  displayedColumns: string[] = ['card'];
  pageIndex = 0;
  loading = true;

  constructor(
    public subventionsStateService: SubventionsStateService,
    private _bottomSheet: MatBottomSheet,
    private actions: Actions
  ) {
    this.actions.pipe(ofActionSuccessful(GetSubventionsFavorites)).subscribe(() => {
      this.loading = false;

      setTimeout(() => {
        if (this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.length = this.subventionsStateService.favorites.total;
        }
      }, 0);
    });

    this.actions
      .pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(UpdateSubventionFavorite))
      .subscribe(() => {
        this.loading = false;

        this.pageIndex = 0;
        this.subventionsStateService.getSubventionsFavorites({
          abierta: true,
          page: this.pageIndex + 1,
          size: 30,
        });
      });
  }

  ngOnInit(): void {
    this.subventionsStateService.getSubventionsFavorites({
      abierta: true,
      page: this.pageIndex + 1,
      size: 30,
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  saveItem(evt: Event, row: Subvention) {
    evt.stopPropagation();

    if (row.favorito) {
      this.subventionsStateService.updateSubventionFavoriteeDelete(row.id);
    } else {
      this.subventionsStateService.updateSubventionFavoriteAdd(row.id);
    }

    return false;
  }

  pageEvent(evt: PageEvent): void {
    this.loading = true;
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.subventionsStateService.getSubventionsFavorites({
      abierta: true,
      page: this.pageIndex + 1,
      size: 30,
    });
  }

  openDetail(id: number): void {
    this._bottomSheet.open(SubventionsSearchDetailComponent, {
      autoFocus: false,
      panelClass: 'panel-bottom-sheet-w-100-h-100',
      data: id,
    });
  }
}
