import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LineChartComponent } from '../line-chart/line-chart.component';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
})
export class BarChartComponent extends LineChartComponent implements OnInit, AfterViewInit {
  type: string = 'bar';

  ngOnInit() {}

  getRequest() {
    return super.getRequest();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  refresh(config, response) {
    super.refresh(config, response);
  }
}
