import { CommonModule, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEsp } from '@app/modules/documents/mat-paginator-int';
import { WindowRef } from '@app/modules/documents/services/window.ref.service';
import { MappingIDPipe } from '@app/pipes/mapping-id.pipe';
import { MappingPipe } from '@app/pipes/mapping.pipe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SectorReportsRoutingModule } from './sector-reports-routing.module';
import { SectorReportsComponent } from './sector-reports.component';
import { SectorReportsListComponent } from './sector-reports-list/sector-reports-list.component';
import { SectorReportsListSectionComponent } from './sector-reports-list/sector-reports-list-section/sector-reports-list-section.component';
import { SectorReportsListSectionGenerateDocComponent } from './sector-reports-list/sector-reports-list-section/sector-reports-list-section-generate-doc/sector-reports-list-section-generate-doc.component';
import { LottieModule } from 'ngx-lottie';
import { SectorReportsListSectionDeleteDialogComponent } from './sector-reports-list/sector-reports-list-section/sector-reports-list-section-delete-dialog/sector-reports-list-section-delete-dialog.component';
import { SectorReportsListSectionReportsComponent } from './sector-reports-list/sector-reports-list-section/sector-reports-list-section-reports/sector-reports-list-section-reports.component';
import { SectorReportsSearchComponent } from './sector-reports-search/sector-reports-search.component';
import { SectorReportsSearchFiltersComponent } from './sector-reports-search-filters/sector-reports-search-filters.component';
import { DocumentModule } from '@app/modules/documents/documents.module';
import { SharedModule } from '@app/modules/shared/shared.module';

registerLocaleData(es);

@NgModule({
  imports: [CommonModule, SharedModule, SectorReportsRoutingModule, LottieModule, DocumentModule],
  declarations: [
    SectorReportsComponent,
    SectorReportsSearchComponent,
    SectorReportsListComponent,
    SectorReportsListSectionComponent,
    SectorReportsListSectionGenerateDocComponent,
    SectorReportsListSectionDeleteDialogComponent,
    SectorReportsListSectionReportsComponent,
    SectorReportsSearchFiltersComponent,
  ],
  exports: [SectorReportsSearchFiltersComponent],
  providers: [
    WindowRef,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    MappingPipe,
    MappingIDPipe,
  ],
})
export class SectorReportsModule {}
