import { Component, OnInit, ChangeDetectorRef, Injector } from '@angular/core';
import { BaseChart } from '@modules/br-widgets/components/charts/base/base.component';
import { Formater } from '@modules/br-widgets/utils/formater';
import { StrfuzzycompService } from '@app/utils';
import echarts from 'echarts';
import { GeoService } from '@app/services/geo.service';
import rainbowColorsJson from '@assets/rainbow-colors.json';
import { UtilsService } from '@app/services/utils.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import tinycolor from 'tinycolor2';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent extends BaseChart implements OnInit {
  options: any;
  rainbowColors: string[] = this.utilsService.extendArray(rainbowColorsJson, 10);

  constructor(
    public injector: Injector,
    private formater: Formater,
    public changeDetector: ChangeDetectorRef,
    private authStateService: AuthStateService,
    private utilsService: UtilsService
  ) {
    super(injector, changeDetector);
  }

  refresh(config, response) {
    let data = this.formater.format(config, response),
      dlength = data.length,
      max = -Infinity;
    this.injector
      .get(GeoService)
      .geo(this.wconfig.config['map'])
      .subscribe((geo) => {
        let features = geo.features,
          _data = [];
        features.forEach((feature) => {
          let i = -1,
            mmax = -Infinity,
            value = 0;
          while (++i < dlength) {
            let data_item = data[i];
            if (typeof data_item['name'] == 'string' && data_item['name'] !== 'Nacional') {
              let sim = this.injector
                .get(StrfuzzycompService)
                .jw_ssim2(data_item.name.replace(/^-/, ''), feature.properties.texto);
              if (sim > mmax) {
                mmax = sim;
                value += parseFloat(data_item['value']);
              }
              if (!feature.properties.name) feature.properties.name = feature.properties.texto;
            } else if (typeof data_item['name'] == 'string') {
              value += parseFloat(data_item['value']);
            }
          }
          max = value > max ? value : max;
          if (mmax >= 0.1) {
            _data.push({ name: feature.properties.texto, value: value });
          }
        });

        echarts.registerMap(this.wconfig.config['map'], geo, {
          Canarias: {
            left: -14,
            top: 35,
            width: 5,
          },
        });

        const userEchartsColors =
          this.authStateService.userConfig.interface_config.company.color_palette?.echarts?.split(',');

        this.options['series'] = [];

        this.options['visualMap'] = {
          show: true,
          min: 0,
          max: max,
          left: 'right',
          top: 'bottom',
          text: ['max', 'min'],
          calculable: true,
          inRange: {
            color: userEchartsColors
              ? [tinycolor(userEchartsColors[0]).lighten(70).toHexString(), userEchartsColors[0]]
              : ['#ffdc6e', '#EF4444'],
          },
        };

        this.options['series'] = Object.assign(this.options['series'], [
          {
            name: this.wconfig.config['title'],
            type: 'map',
            roam: this.wconfig.config['roam'] ? this.wconfig.config['roam'] : true,
            map: this.wconfig.config['map'],
            scaleLimit: {
              min: 0.3,
            },
            //                    coordinateSystem:'geo',
            data: _data,
            itemStyle: {
              emphasis: { label: { show: true } },
            },
          },
        ]);

        this.echartsInstance.setOption(this.options);
        this.isLoading = false;
        this.seriesLoaded.emit('map');
      });
  }

  ngOnInit() {}

  onChartInit(e) {
    this.echartsInstance = e;
    this.loadData();
  }

  loadData() {
    super.ngAfterViewInit();
    let config = Object.assign({}, this.wconfig.config['query']);
    config['aggregations'] = this.wconfig.config['aggregations'];
    // remove this
    delete config['dateParsed_start'];
    delete config['dateParsed_end'];
    config['aggregations'][0]['field'] = 'docTopicsRegion';

    this.update(config, (config, response) => {
      this.refresh(config, response);
    });
  }
}
