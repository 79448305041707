import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import { WidgetStateModel } from '../models/widget.state.model';
import { AddLayout, ClearLayouts } from './widget.actions';
import { Layout } from '@app/models/User';

@Injectable({
  providedIn: 'root',
})
@State<WidgetStateModel>({
  name: 'widgets',
  defaults: {
    layouts: [],
  },
})
export class WidgetState {
  @Selector()
  static widgets(state: WidgetStateModel): Layout[] | null {
    return state.layouts;
  }

  @Action(ClearLayouts)
  clearLayoutsByState(ctx: StateContext<WidgetStateModel>) {
    ctx.setState({ layouts: [] });
  }

  @Action(AddLayout)
  addWidgetByState(ctx: StateContext<WidgetStateModel>, payload: { layout: Layout }) {
    let layouts: Layout[] = [...ctx.getState().layouts];
    layouts.push(payload.layout);
    ctx.setState({ layouts: layouts });
  }
}
