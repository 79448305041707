<div class="container-main">
  <div class="header">
    <p class="title">Tipos de buscador</p>
    <p class="description">Elige el buscador que mejor se adapte a tus preferencias</p>
  </div>
  <div class="container-img">
    <img src="assets/imgs/search-type-illustration.svg" />
  </div>
  <div class="types">
    <div class="type">
      <div class="header">
        <p class="title">Buscador de texto literal</p>
        <p class="description">
          Realiza búsquedas mediante combinaciones de textos escribiendo directamente sobre el campo del buscador
        </p>
      </div>
      <div class="content">
        <div class="data">
          <mat-form-field>
            <button mat-icon-button matPrefix>
              <mat-icon>search</mat-icon>
            </button>
            <mat-label>Introduce un texto de búsqueda</mat-label>
            <input matInput readonly value="&quot;Congreso de los diputados&quot;+'pacto educativo'" />
          </mat-form-field>
        </div>
        <div class="actions">
          <button mat-stroked-button color="primary" (click)="howToUse('literal')">¿Cómo usar el buscador?</button>
          <button
            mat-flat-button
            color="primary"
            (click)="setSearchType('literal')"
            *ngIf="appStateService.searchType !== 'literal'"
          >
            Establecer buscador
          </button>
          <p *ngIf="appStateService.searchType === 'literal'">Este es tu buscador actual</p>
        </div>
      </div>
    </div>
    <div class="type">
      <div class="header">
        <p class="title">Buscador con operadores</p>
        <p class="description">
          Realiza búsquedas mediante combinaciones de bloques con operadores utilizando una interfaz más visual.
          Concatena grupos de filtros y visualiza el texto formado
        </p>
      </div>
      <div class="content">
        <div class="data">
          <div class="group">
            <p>Cuando</p>
            <div class="chips">
              <div class="chip">
                <p>Congreso de los diputados</p>
                <mat-icon>clear</mat-icon>
              </div>
              <div class="chip">
                <p>AND</p>
                <mat-icon>expand_more</mat-icon>
              </div>
              <div class="chip">
                <p>pacto educativo</p>
                <mat-icon>clear</mat-icon>
              </div>
              <div class="chip">
                <mat-icon>add</mat-icon>
              </div>
            </div>
          </div>
          <button mat-stroked-button>Agregar búsqueda</button>
          <div class="result">
            <p>"Congreso de los diputados"+'pacto educativo'</p>
          </div>
        </div>
        <div class="actions">
          <button mat-stroked-button color="primary" (click)="howToUse('operators')">¿Cómo usar el buscador?</button>
          <button
            mat-flat-button
            color="primary"
            (click)="setSearchType('operators')"
            *ngIf="appStateService.searchType !== 'operators'"
          >
            Establecer buscador
          </button>
          <p *ngIf="appStateService.searchType === 'operators'">Este es tu buscador actual</p>
        </div>
      </div>
    </div>
  </div>
</div>
