import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import { SpiderStateModel, Spider } from '../models/spider.state.model';
import { GetImportantSpiders, GetLatestSpiders } from './spider.actions';

@Injectable({
  providedIn: 'root',
})
@State<SpiderStateModel>({
  name: 'spider',
  defaults: {
    important: [],
    latest: [],
  },
})
export class SpiderState {
  @Selector()
  static important(state: SpiderStateModel): Spider[] | null {
    return state.important;
  }

  @Selector()
  static latest(state: SpiderStateModel): Spider[] | null {
    return state.latest;
  }

  constructor() {}

  @Action(GetImportantSpiders)
  updateUIStatusByState(ctx: StateContext<SpiderStateModel>, response: { payload: Spider[] }) {
    ctx.patchState({ important: response.payload });
  }

  @Action(GetLatestSpiders)
  getCCAAGroupsByState({ patchState }: StateContext<SpiderStateModel>, response: { payload: Spider[] }) {
    patchState({ latest: response.payload });
  }
}
