<div class="wrapper">
  <h2>Artículos</h2>
  <p>Pulsa en un artículo para previsualizar su contenido</p>
  <ng-container *ngIf="draftStateService.selectedDraft$ | async as dossier">
    <mat-accordion>
      <mat-expansion-panel
        [@.disabled]="true"
        [expanded]="hasDocSelected(category)"
        *ngFor="let category of dossier.data; let i = index"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>{{ category.category }} ({{ category.docs.length }} artículos)</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list-item
          *ngFor="let article of category.docs; let i = index"
          [ngClass]="{ selected: matData.articleId === article.id }"
          (click)="setArticle(article)"
        >
          <div mat-line>
            <strong class="article-title">{{ article.source | mapping }}</strong>
            {{ article.date | date }}
          </div>
          <div mat-line>
            <strong class="article-subtitle">{{ article.title }}</strong>
          </div>
          <div mat-line class="article-abstract">{{ article.abstract }}</div>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</div>
