import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss'],
})
export class TimetableComponent implements OnInit {
  @Input() schedule;
  @Output() scheduleOutput: EventEmitter<any> = new EventEmitter();

  daysList: string[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  formGroup: FormGroup;

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      schedules: this._formBuilder.array([]),
    });

    this.schedule.map((schedule) => this.addSchedule(schedule));
  }

  get schedules() {
    return this.formGroup.get('schedules') as FormArray;
  }

  addSchedule(schedule?) {
    const scheduleFormArray: FormGroup = this._formBuilder.group({
      days: new FormControl([], [Validators.required]),
      hour: new FormControl(0, [Validators.required]),
      minute: new FormControl(0, [Validators.required]),
    });

    if (schedule) scheduleFormArray.patchValue(schedule);

    this.schedules.push(scheduleFormArray);
  }

  saveSchedule() {
    this.scheduleOutput.emit(this.formGroup.value);
  }

  deleteSchedule(index: number) {
    this.schedules.removeAt(index);
  }

  updateItems($event, index: number) {
    this.schedules.at(index)['controls']['days'].setValue($event);
  }
}
