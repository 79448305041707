<div class="overlay">
  <button
    mat-stroked-button
    color="primary"
    style="align-self: flex-end; margin-top: 72px; margin-right: 12px"
    (click)="destroy()"
    *ngIf="docLoaded"
  >
    <mat-icon>chevron_left</mat-icon>
    Volver a la búsqueda
  </button>
  <doc-gen
    [docID]="docID"
    [lookfortext]="lookfortext"
    [openwithsearch]="openwithsearch"
    (docLoaded)="docLoaded = true"
    style="width: 100%; height: 100%"
  ></doc-gen>
</div>
<ng-content></ng-content>
