import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { Observable, of as ObservableOf, merge, ReplaySubject, BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  refreshToolbarCounterFavorites$ = new BehaviorSubject<number>(0);
  refreshToolbarCounterAlerts$ = new BehaviorSubject<number>(0);
  refreshToolbarCounterFavoriteDocuments$ = new BehaviorSubject<number>(0);

  static readonly itemsUrl = environment.documentItem;
  static readonly itemCats = environment.documentCats;

  constructor(private http: HttpClient) {}

  getDocument(docID: string) {
    return this.http.get(DocumentService.itemsUrl + docID);
  }

  getCategories(docID: string): Observable<any> {
    return this.http.get(DocumentService.itemCats).pipe(delay(2000));
  }

  emailSend(msg, emailto, docid) {
    let params = { message: msg, emailto: emailto, docid: docid };
    return this.http.post<any>(environment.documentSend, params, httpOptions);
  }
}
