import { Component } from '@angular/core';
import { RouterExtService } from './services/routerext.service';
import { environment } from '@env/environment';
import { SeoService } from './services/seo.service';
import { AuthStateService } from './modules/login-form/state/auth/auth.service';
import { KC_REALM_CEOE, getRealm } from '@app/init/keycloak-init.factory';
import { AppStateService } from './state/app/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    private routerExtService: RouterExtService,
    private seoService: SeoService,
    private appStateService: AppStateService
  ) {
    this.handleMetaInfo();
    this.removeUselessNotifications();

    if (!this.appStateService.searchType) {
      this.appStateService.setSearchType('literal');
    }
  }

  handleMetaInfo(): void {
    if (getRealm() == KC_REALM_CEOE) {
      this.seoService.changeTitle('CEOE | data');

      const favicon = document.getElementById('favicon-app') as HTMLLinkElement;
      favicon.href = './assets/favicon.ico';
    }
  }

  removeUselessNotifications(): void {
    this.appStateService.setNotifications(
      (this.appStateService.notifications ?? [])
        .filter((notification) => {
          if (notification.type === 'download') {
            return notification.url;
          }

          return true;
        })
        .slice(-50)
    );
  }
}
