import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { InitiativeState } from './initiative.state';
import { UIInitiativeStatus, Initiative, PastProcedure, InitiativeMenuFilters } from '../models/initiative.state.model';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ShowCalendar,
  ShowList,
  GetInitiatives,
  SetInitiative,
  AddInitiative,
  AddCustomInitiative,
  AddDeadline,
  AddPastProcedure,
  GetOpenInitiatives,
  FollowInitiative,
  ToggleInitiativeMenuFilters,
  DeleteInitiative,
} from './initiative.actions';
import { InitiativesService } from '../../services/initiatives.service';
import { UIStatus } from '@app/state/models/app.state.model';
import { AppStateService } from '@app/state/app/app.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';

@Injectable({ providedIn: 'root' })
export class InitiativeStateService {
  @Select(InitiativeState.uiStatus)
  public uiStatus$: Observable<UIInitiativeStatus>;

  @Select(InitiativeState.initiatives)
  public initiatives$: Observable<Initiative[]>;

  @SelectSnapshot(InitiativeState.initiatives)
  public initiatives: Initiative[];

  @Select(InitiativeState.openInitiatives)
  public openInitiatives$: Observable<{ initiatives: Initiative[]; total: number }>;

  @SelectSnapshot(InitiativeState.openInitiatives)
  public openInitiatives: { initiatives: Initiative[]; total: number };

  @Select(InitiativeState.selectedInitiative)
  public selectedInitiative$: Observable<Initiative>;

  @Select(InitiativeState.menuFilters)
  public menuFilters$: Observable<InitiativeMenuFilters>;

  @SelectSnapshot(InitiativeState.menuFilters)
  public menuFilters: InitiativeMenuFilters;

  constructor(
    private _spinner: NgxSpinnerService,
    private _snackbarStateService: SnackbarStateService,
    private _initiativesService: InitiativesService,
    private _appService: AppStateService
  ) {}

  @Dispatch()
  public showCalendar = () => {
    return new ShowCalendar();
  };

  @Dispatch()
  public showList = () => {
    return new ShowList();
  };

  @Dispatch()
  public getInitiatives = () => {
    return this._initiativesService.getInitiatives().pipe(
      map((response: any) => new GetInitiatives(response)),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getInitiativesCCA = () => {
    return this._initiativesService.getInitiativesCCA().pipe(
      map((response: any) => new GetInitiatives(response.items)),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getOpenInitiatives = (payload: {
    page: number;
    size: number;
    sections: string[];
    result: number;
    pending: boolean;
    normativa_id: string;
    ref: string;
  }) => {
    this._appService.updateUIStatus(UIStatus.loading);
    return this._initiativesService.getOpenInitiatives(payload).pipe(
      map((response: any) => {
        this._appService.updateUIStatus(UIStatus.loaded);
        return new GetOpenInitiatives(response);
      }),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getOpenInitiativesCCAA = (payload: {
    page: number;
    size: number;
    ccaa: number;
    result: number;
    pending: boolean;
  }) => {
    this._appService.updateUIStatus(UIStatus.loading);
    return this._initiativesService.getOpenInitiativesCCAA(payload).pipe(
      map((response: any) => {
        this._appService.updateUIStatus(UIStatus.loaded);
        return new GetOpenInitiatives(response);
      }),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public getOpenInitiativesCCA = (payload: {
    page: number;
    size: number;
    cca: string[];
    type: string[];
    pending: boolean;
    text: string;
    ref: string;
  }) => {
    this._appService.updateUIStatus(UIStatus.loading);
    return this._initiativesService.getOpenInitiativesCCA(payload).pipe(
      map((response: any) => {
        this._appService.updateUIStatus(UIStatus.loaded);
        return new GetOpenInitiatives({ iniciativas: response.items, total: response.total });
      }),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public setInitiative = (initiative: Initiative) => {
    return new SetInitiative(initiative);
  };

  @Dispatch()
  public followInitiative = (id: number) => {
    return this._initiativesService.followInitiative(id).pipe(
      map((response: any) => {
        this._snackbarStateService.show('Iniciativa marcada para seguimiento', 5000, 'cerrar');
        return new FollowInitiative(response);
      }),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public unfollowInitiative = (id: number) => {
    return this._initiativesService.unfollowInitiative(id).pipe(
      map((response: any) => {
        this._snackbarStateService.show('Iniciativa eliminada de seguimiento', 5000, 'cerrar');
        return new FollowInitiative(response);
      }),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public addInitiative = (payload: { title: string; url: string }) => {
    return this._initiativesService.addInitiatives(payload).pipe(
      map(() => {
        return new AddInitiative();
      }),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public deleteInitiative = (payload: number) => {
    return this._initiativesService.deleteInitiative(payload).pipe(
      map(() => {
        return new DeleteInitiative();
      }),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public addCustomInitiative = (payload: { title: string }) => {
    return this._initiativesService.addCustomInitiative(payload).pipe(
      map((response) => new AddCustomInitiative(response)),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public addPastProcedure = (payload) => {
    return this._initiativesService.addPastProcedure(payload).pipe(
      map((response) => new AddPastProcedure(response)),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public addDeadLine = (payload) => {
    return this._initiativesService.addDeadLine(payload).pipe(
      map((response) => new AddDeadline(response)),
      catchError(() => {
        let error = 'Servidor en mantenimiento, inténtelo más tarde';
        this._snackbarStateService.show(error, 5000, 'cerrar');
        return throwError(error);
      })
    );
  };

  @Dispatch()
  public toggleInitiativeMenuFilters = () => {
    return new ToggleInitiativeMenuFilters();
  };
}
