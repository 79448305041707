import { CommonModule, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEsp } from '@app/modules/documents/mat-paginator-int';
import { WindowRef } from '@app/modules/documents/services/window.ref.service';
import { MappingIDPipe } from '@app/pipes/mapping-id.pipe';
import { MappingPipe } from '@app/pipes/mapping.pipe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BusinessStatisticsRoutingModule } from './business-statistics-routing.module';
import { BusinessStatisticsComponent } from './business-statistics.component';
import { BusinessStatisticsSectionComponent } from './business-statistics-section/business-statistics-section.component';
import { CustomValidators } from '@app/modules/shared/validators/custom-validators';
import { NgxEchartsModule } from 'ngx-echarts';
import { RouterModule } from '@angular/router';
import { BrWidgetsModule } from '@app/modules/br-widgets/br-widgets.module';
import { BusinessStatisticsSectionDetailComponent } from './business-statistics-section/business-statistics-section-detail/business-statistics-section-detail.component';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { BusinessStatisticsSectionDetailSearchComponent } from './business-statistics-section/business-statistics-section-detail/business-statistics-section-detail-search/business-statistics-section-detail-search.component';
import { BusinessStatisticsSectionDetailTableComponent } from './business-statistics-section/business-statistics-section-detail/business-statistics-section-detail-table/business-statistics-section-detail-table.component';
import { BusinessStatisticsSectionDetailGraphComponent } from './business-statistics-section/business-statistics-section-detail/business-statistics-section-detail-graph/business-statistics-section-detail-graph.component';
import { SharedModule } from '@app/modules/shared/shared.module';

registerLocaleData(es);

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BrWidgetsModule,
    RouterModule,
    BusinessStatisticsRoutingModule,
    NgxEchartsModule,
  ],
  declarations: [
    BusinessStatisticsComponent,
    BusinessStatisticsSectionComponent,
    BusinessStatisticsSectionDetailComponent,
    BusinessStatisticsSectionDetailSearchComponent,
    BusinessStatisticsSectionDetailTableComponent,
    BusinessStatisticsSectionDetailGraphComponent,
  ],
  exports: [],
  providers: [
    WindowRef,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    MappingPipe,
    MappingIDPipe,
    CustomValidators,
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class BusinessStatisticsModule {}
