import { Component, Inject, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { DocumentService } from '@modules/documents/services/document.service';
import { DialogEmailComponent } from '@modules/documents/components/dialogemail/dialogemail.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '@env/environment';
import { DocToDraftComponent } from '../magazine/doc-to-draft/doc-to-draft.component';
import { AppStatusService } from '@app/services/app-status.service';
import { SearchService } from '@app/modules/main/components/search/services/search.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'doc-botonera',
  templateUrl: './docbotonera.component.html',
  styleUrls: ['./docbotonera.component.css'],
})
export class DocBotoneraComponent implements OnInit {
  readonly LOGOURL: string = environment.logoApp;

  @Input() docID;
  @Input() docUrl;
  @Input() docTitleParsed;
  @Input() docFavorite;
  @Input() docprint;

  isPrensaModule = false;
  hasAccessToPrensaModule = false;
  hasMagazineAllowed = false;

  constructor(
    private authStateService: AuthStateService,
    private appStatus: AppStatusService,
    private sDocumento: DocumentService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private sService: SearchService,
    private router: Router
  ) {
    const prensaModule = this.authStateService.userConfig.interface_config.menu_items.find((mi) => mi.id === 3);

    this.hasAccessToPrensaModule = prensaModule.is_allowed === 'true';

    if (router.url.includes(prensaModule.path) && this.hasAccessToPrensaModule) {
      this.isPrensaModule = true;
    }

    if (prensaModule && this.hasAccessToPrensaModule) {
      this.hasMagazineAllowed = prensaModule.items.find((i) => i.path === '/clipping/magazine') != undefined;
    }
  }

  ngOnInit() {}

  openUrl() {
    window.open(this.docUrl, '_blank');
  }

  openEmail() {
    const dialogRef = this.dialog.open(DialogEmailComponent, {
      data: { docid: this.docID },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.send_mail == 'correct')
        this.snackBar.open('Se envió un email!', '', {
          duration: 2000,
        });
    });
  }

  addfav() {
    this.sService.saveFavorite(this.docTitleParsed, this.docID).subscribe((ev) => {
      this.docFavorite = true;
      this.appStatus.favorite_save.emit(this.docID);
    });
  }

  delfav() {
    this.sService.deleteFavorite(this.docID).subscribe((ev) => {
      this.docFavorite = false;
      this.appStatus.favorite_delete.emit(this.docID);
    });
  }

  printPDF() {
    let print = window['PDFViewerApplication'] ? window['PDFViewerApplication'].toolbar.items.print : undefined;
    print.click();
  }

  addDraft() {
    const dialogRef = this.dialog.open(DocToDraftComponent, {
      width: '1024px',
      data: { docID: this.docID },
    });
  }

  destroy() {}
}
