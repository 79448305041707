import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Tender } from '../../models/tender';
import { GetTenders, GetTendersFavorites, UpdateTenderFavorite } from '../../store/tenders/tenders.actions';
import { TendersStateService } from '../../store/tenders/tenders.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TendersSearchDetailComponent } from '../tenders-search/tenders-search-detail/tenders-search-detail.component';

@Component({
  selector: 'app-tenders-favorites',
  templateUrl: './tenders-favorites.component.html',
  styleUrls: ['./tenders-favorites.component.scss'],
})
export class TendersFavoritesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  componentDestroyed$: Subject<boolean> = new Subject();
  displayedColumns: string[] = ['card'];
  pageIndex = 0;
  loading = true;

  constructor(
    public tendersStateService: TendersStateService,
    private actions: Actions,
    private _bottomSheet: MatBottomSheet
  ) {
    this.actions.pipe(ofActionSuccessful(GetTendersFavorites)).subscribe(() => {
      this.loading = false;

      setTimeout(() => {
        if (this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.length = this.tendersStateService.tendersFavorites.total;
        }
      }, 0);
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(UpdateTenderFavorite)).subscribe(() => {
      this.loading = false;

      this.pageIndex = 0;
      this.tendersStateService.getTendersFavorites({
        page: this.pageIndex + 1,
        size: 30,
      });
    });
  }

  ngOnInit(): void {
    this.tendersStateService.getTendersFavorites({
      page: this.pageIndex + 1,
      size: 30,
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  saveItem(evt: Event, row: Tender) {
    evt.stopPropagation();

    if (row.favorito) {
      this.tendersStateService.updateTenderFavoriteDelete(row.id);
    } else {
      this.tendersStateService.updateTenderFavoriteAdd(row.id);
    }

    return false;
  }

  pageEvent(evt: PageEvent): void {
    this.loading = true;
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.tendersStateService.getTendersFavorites({
      page: this.pageIndex + 1,
      size: 30,
    });
  }

  openDetail(id: number): void {
    this._bottomSheet.open(TendersSearchDetailComponent, {
      autoFocus: false,
      panelClass: 'panel-bottom-sheet-w-100-h-100',
      data: id,
    });
  }
}
