import { Component, OnInit, Input, ChangeDetectorRef, Injector } from '@angular/core';
import { BaseChart } from '@modules/br-widgets/components/charts/base/base.component';
import { Formater } from '@modules/br-widgets/utils/formater';
import { TextanalysisService } from '@app/utils/textanalysis.service';

@Component({
  selector: 'app-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.css'],
})
export class WordCloudComponent extends BaseChart implements OnInit {
  @Input() words: Array<any> = [];

  options: any;
  datawords = new Array();
  isLoading = true;

  constructor(public injector: Injector, private formater: Formater, public changeDetector: ChangeDetectorRef) {
    super(injector, changeDetector);
  }

  private filter_words(words, max) {
    let aux = [],
      dict = {},
      res = [],
      length = words.length,
      i = -1,
      added = 0;
    setTimeout(() => {
      while (++i < length) {
        if (words[i].text.length > 3) aux.push(words[i].text);
      }
    });
    setTimeout(() => {
      this.injector
        .get(TextanalysisService)
        .clean_stopwords(aux)
        .forEach((w) => {
          dict[w] = 1;
        });
    });

    setTimeout(() => {
      i = -1;

      while (++i < length && added < max)
        if (dict[words[i].text]) {
          res.push(words[i]);
          added++;
        }
    });
    return res;
  }

  refresh(config, response) {
    let words = this.formater.format(config, response).map((res) => {
      return { text: res.name.trim(), size: res.value };
    });

    this.words = this.filter_words(words, 100);

    setTimeout(() => {
      this.datawords = this.words.map((element) => {
        let obj = {};
        obj = { name: element.text, value: element.size };
        //obj[element.text] = element.size;
        return obj;
      });

      this.options['series'] = [
        {
          type: 'wordCloud',

          // The shape of the "cloud" to draw. Can be any polar equation represented as a
          // callback function, or a keyword present. Available presents are circle (default),
          // cardioid (apple or heart shape curve, the most known polar equation), diamond (
          // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.

          shape: 'square',

          // A silhouette image which the white area will be excluded from drawing texts.
          // The shape option will continue to apply as the shape of the cloud to grow.

          // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
          // Default to be put in the center and has 75% x 80% size.

          left: 'center',
          top: 'center',
          width: '85%',
          height: '70%',
          right: null,
          bottom: null,

          // Text size range which the value in data will be mapped to.
          // Default to have minimum 12px and maximum 60px size.

          sizeRange: [12, 60],

          // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45

          rotationRange: [-90, 90],
          rotationStep: 45,

          // size of the grid in pixels for marking the availability of the canvas
          // the larger the grid size, the bigger the gap between words.

          gridSize: 8,

          // set to true to allow word being draw partly outside of the canvas.
          // Allow word bigger than the size of the canvas to be drawn
          drawOutOfBound: false,

          // Global text style
          textStyle: {
            normal: {
              fontFamily: 'roboto',
              // Color can be a callback function or a color string
              color: function () {
                // Random color
                return (
                  'rgb(' +
                  [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                  ].join(',') +
                  ')'
                );
              },
            },
            emphasis: {
              shadowBlur: 10,
              shadowColor: '#333',
            },
          },

          // Data is an array. Each array item must have name and value property.
          data: this.datawords,
        },
      ];

      this.echartsInstance.setOption(this.options);

      this.isLoading = false;
      this.seriesLoaded.emit('wordcloud');
    });
  }

  ngOnInit() {}

  public update(config, callback) {
    this.isLoading = true;
    this.changeDetector.detectChanges();
    return super.update(config, callback);
  }

  onChartInit(e) {
    this.echartsInstance = e;
    this.loadData();
  }

  loadData() {
    super.ngAfterViewInit();
    if (this.wconfig.config['query']) {
      let config = Object.assign({}, this.wconfig.config['query']);
      config['aggregations'] = this.wconfig.config['aggregations'];

      // remove this
      delete config['dateParsed_start'];
      delete config['dateParsed_end'];
      config['aggregations'][0]['size'] = 500;
      config['aggregations'][0]['field'] = 'bodyParsed';
      this.update(config, (config, response) => {
        this.refresh(config, response);
      });
    }
  }
}
