import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { WidgetConfig } from '@app/modules/br-widgets/models/widget-config';

@Component({
  selector: 'ng-title',
  templateUrl: './ng-title.component.html',
  styleUrls: ['./ng-title.component.css'],
})
export class NgTitleComponent implements OnInit, AfterViewInit {
  @Input() wconfig?;
  title: string = '';
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.title = this.wconfig.config['title'];
  }
}
