<div class="container">
  <ng-container *ngIf="getRealm() === KC_REALM_CEOE">
    <p>
      En nuestro continúo esfuerzo por ofrecer el mejor servicio estamos evolucionando la actual plataforma de
      CEOEXEuropa.
    </p>
    <p>
      En caso de encontrar una incidencia técnica, por favor envíe un ticket a través de la plataforma
      <a href="https://help.brainyinteligencia.com/" target="_blank">https://help.brainyinteligencia.com</a> y le
      contestaremos a la mayor brevedad posible.
    </p>
    <p>El equipo de CEOE | data.</p>
  </ng-container>

  <ng-container *ngIf="getRealm() === KC_REALM_BRAINY">
    <p>
      En caso de encontrar una incidencia técnica, por favor envíe un ticket a través de la plataforma
      <a href="https://help.brainyinteligencia.com/" target="_blank">https://help.brainyinteligencia.com</a> y le
      contestaremos a la mayor brevedad posible.
    </p>
    <p>El equipo de Brainy.</p>
  </ng-container>
</div>
