import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, of as ObservableOf } from 'rxjs';
import { map, finalize, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

import { Logout, Me, UpdatePassword, ResetMe, ViewMyData } from './auth.actions';
import { AuthState } from './auth.state';
import { UserConfig, NavItem } from '@app/models/User';
import tinycolor from 'tinycolor2';
import { AuthenticationService } from '../../services/authentication.service';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthStateService {
  @Select(AuthState)
  public authState$: Observable<AuthState>;

  @Select(AuthState.config)
  public userConfig$: Observable<UserConfig>;

  @SelectSnapshot(AuthState.config)
  public userConfig: UserConfig;

  @Select(AuthState.menuItems)
  public menuItems$: NavItem[];

  @SelectSnapshot(AuthState.menuItems)
  public menuItems: NavItem[];

  @SelectSnapshot(AuthState.template)
  public template: string;

  constructor(private _router: Router, private injector: Injector, private _spinner: NgxSpinnerService) { }

  @Dispatch()
  public me = () => {
    return this.injector
      .get(AuthenticationService)
      .me()
      .pipe(
        map((response: any) => {
          response.interface_config = JSON.parse(response.interface_config);
          response.config = JSON.parse(response.config);

          if (!response.interface_config.categories) {
            response.interface_config = { ...response.interface_config, categories: 'legacy' };
          }

          // let responseMapped = this.setParentModules(response);
          this.setThemeStyles(response.interface_config);

          return new Me(response);
        }),
        catchError(() => {
          this._router.navigate(['/error-data']);
          return ObservableOf('');
        }),
        finalize(() => {
          this._spinner.hide('login');
          // this._router.navigate(['/home']).then(() => {
          //   window.location.reload();
          // });
        })
      );
  };

  @Dispatch()
  public resetMe = () => {
    return new ResetMe();
  };

  @Dispatch()
  public logout = () => {
    return this.injector
      .get(AuthenticationService)
      .logout('')
      .pipe(map(() => new Logout()));
  };

  @Dispatch()
  public updatePassword = () => {
    return this.injector
      .get(AuthenticationService)
      .updatePassword()
      .pipe(map(() => new UpdatePassword()));
  };

  @Dispatch()
  public viewMyData = () => {
    return this.injector
      .get(AuthenticationService)
      .viewMyData()
      .pipe(map(() => new ViewMyData()));
  };

  hexToRgb(hexString: string): [number, number, number] {
    if (hexString.startsWith('#')) {
      hexString = hexString.slice(1);
    }

    if (!/^[0-9A-Fa-f]{6}$/.test(hexString)) {
      throw new Error('Invalid hexadecimal color code');
    }

    const r = parseInt(hexString.slice(0, 2), 16);
    const g = parseInt(hexString.slice(2, 4), 16);
    const b = parseInt(hexString.slice(4, 6), 16);

    return [r, g, b];
  }

  setThemeStyles(interfaceConfig: any): void {
    let primaryColor = interfaceConfig.company.color_palette.theme.primary;
    let secondaryColor = interfaceConfig.company.color_palette.theme.accent;
    let allLight = true;

    let variables = [
      {
        label: '--light-mode',
        value: allLight.toString(),
      },
      {
        label: '--color-primary-main',
        value: primaryColor,
      },
      {
        label: '--color-secondary-main',
        value: secondaryColor,
      },
      {
        label: '--color-background-toolbar',
        value: primaryColor,
      },
      {
        label: '--color-background-sidebar',
        value: '#FFFFFF',
      },
      {
        label: '--color-background-snackbar',
        value: '#444444',
      },
    ];

    if (interfaceConfig.company.company_template === 'ceoe') {
      primaryColor = interfaceConfig.company.color_palette.theme.primary;
      secondaryColor = interfaceConfig.company.color_palette.theme.accent;
      allLight = false;

      variables = [
        {
          label: '--light-mode',
          value: allLight.toString(),
        },
        {
          label: '--color-primary-main',
          value: primaryColor,
        },
        {
          label: '--color-secondary-main',
          value: secondaryColor,
        },
        {
          label: '--color-background-toolbar',
          value: secondaryColor,
        },
        {
          label: '--color-background-sidebar',
          value: secondaryColor,
        },
        {
          label: '--color-background-snackbar',
          value: secondaryColor,
        },
      ];
    } else if (interfaceConfig.company.company_template === 'acento') {
      primaryColor = interfaceConfig.company.color_palette.theme.primary;
      secondaryColor = interfaceConfig.company.color_palette.theme.accent;
      allLight = true;

      variables = [
        {
          label: '--light-mode',
          value: allLight.toString(),
        },
        {
          label: '--color-primary-main',
          value: primaryColor,
        },
        {
          label: '--color-secondary-main',
          value: secondaryColor,
        },
        {
          label: '--color-background-toolbar',
          value: primaryColor,
        },
        {
          label: '--color-background-sidebar',
          value: '#ffffff',
        },
        {
          label: '--color-background-snackbar',
          value: '#444444',
        },
      ];
    }

    variables.forEach((v) => {
      document.documentElement.style.setProperty(v.label, v.value);

      if (v.label !== '--light-mode') {
        document.documentElement.style.setProperty(`${v.label}-rgb`, this.hexToRgb(v.value).join(', '));
      }
    });

    this.updateTheme(this.computeColors(primaryColor, allLight), 'primary', allLight);
    this.updateTheme(this.computeColors(secondaryColor, allLight), 'secondary', allLight);
    this.updateFontFamily(interfaceConfig);
  }

  updateTheme(colors: Color[], theme: 'primary' | 'secondary', allLight: boolean) {
    colors.forEach((color) => {
      document.documentElement.style.setProperty(`--theme-${theme}-${color.name}`, color.hex);
      document.documentElement.style.setProperty(
        `--theme-${theme}-contrast-${color.name}`,
        color.darkContrast && !allLight ? 'rgba(black, 0.87)' : 'white'
      );
    });
  }

  updateFontFamily(interfaceConfig: any): void {
    document.body.style.setProperty('font-family', interfaceConfig.company.company_font);

    const elements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'a', 'li', 'div', 'tr', 'th', 'td'];
    elements.forEach((element) => {
      const elements = document.querySelectorAll(element);
      elements.forEach((el) => {
        (el as HTMLElement).style.setProperty('font-family', interfaceConfig.company.company_font);
      });
    });
  }

  computeColors(hex: string, allLight: boolean): Color[] {
    return [
      this.getColorObject(tinycolor(hex).lighten(52), '50'),
      this.getColorObject(tinycolor(hex).lighten(37), '100'),
      this.getColorObject(tinycolor(hex).lighten(26), '200'),
      this.getColorObject(tinycolor(hex).lighten(12), '300'),
      this.getColorObject(tinycolor(hex).lighten(6), '400'),
      this.getColorObject(tinycolor(hex), '500'),
      this.getColorObject(tinycolor(hex).darken(6), '600'),
      this.getColorObject(tinycolor(hex).darken(12), '700'),
      this.getColorObject(tinycolor(hex).darken(18), '800'),
      this.getColorObject(tinycolor(hex).darken(24), '900'),
      this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700'),
    ];
  }

  getColorObject(value, name): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }

  // setParentModules(response: UserConfig): any {
  //   response.interface_config.menu_items.forEach((mi, indexParent) => {
  //     mi.items.forEach((i) => {
  //       if (i.parent_module && i.title != i.parent_module) {
  //         response.interface_config.menu_items[indexParent].items.push({
  //           ...i,
  //           items: response.interface_config.menu_items[indexParent].items.filter(
  //             (i2) => i.parent_module === i2.parent_module
  //           ),
  //           parent_module: '',
  //           path: i.path.split('/').slice(0, 3).join('/'),
  //         });
  //       }
  //     });

  //     response.interface_config.menu_items[indexParent].items = response.interface_config.menu_items[
  //       indexParent
  //     ].items.filter((i) => !i.parent_module);
  //   });
  //   return response;
  // }
}
