import { Component, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AddCategoriesTopics } from '@app/state/app/app.actions';
import { AppStateService } from '@app/state/app/app.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import rainbowColorsJson from '@assets/rainbow-colors.json';
import { AppStatusService } from '@app/services/app-status.service';
import _moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-sector-reports-search-filters',
  templateUrl: './sector-reports-search-filters.component.html',
  styleUrls: ['./sector-reports-search-filters.component.scss'],
})
export class SectorReportsSearchFiltersComponent implements OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  mclick = 0;
  rainbowColors: string[] = rainbowColorsJson;
  filtersForm: FormGroup;
  categoriesOptions = [];
  loading = true;

  constructor(
    private appService: AppStateService,
    private appStatus: AppStatusService,
    private fb: FormBuilder,
    private actions: Actions
  ) {
    this.filtersForm = this.fb.group({
      text: ['', []],
      startDate: [_moment().startOf('month').toISOString(), []],
      endDate: [_moment().endOf('month').toISOString(), []],
      categories: this.fb.array([]),
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(AddCategoriesTopics)).subscribe(() => {
      this.categoriesOptions = this.appService.categories_topics;
      this.buildCategoryCheckboxes();
      this.loading = false;
    });
  }

  get categoriesFormArray(): FormArray {
    return this.filtersForm.controls.categories as FormArray;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  buildCategoryCheckboxes() {
    this.categoriesFormArray.clear();

    this.categoriesOptions.forEach((c) => {
      this.categoriesFormArray.push(new FormControl(true));
    });

    this.resetFilters();
  }

  search(): void {
    this.filtersForm.markAllAsTouched();

    if (this.filtersForm.valid) {
      this.appStatus.sector_reports_search_launched.emit({
        text_search: this.filtersForm.controls.text.value,
        dateParsed_start: this.filtersForm.controls.startDate.value,
        dateParsed_end: this.filtersForm.controls.endDate.value,
        categories: this.filtersForm.value.categories
          .map((checked, i) => (checked ? this.categoriesOptions[i].id : null))
          .filter((v) => v !== null),
      });
    }
  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>): void {
    this.mclick++;

    if (this.mclick == 1) {
      const ctrlValue: Date = this.filtersForm.controls.startDate.value;
      ctrlValue.setMonth(normalizedMonth.getMonth());
      ctrlValue.setFullYear(normalizedMonth.getFullYear());
      ctrlValue.setDate(1);
      this.filtersForm.controls.startDate.setValue(ctrlValue);

      datepicker.close();

      //It can improve
      var inter = setInterval(() => {
        datepicker.open();
        clearInterval(inter);
      }, 50);
    } else if (this.mclick == 2) {
      const ctrlValue: Date = this.filtersForm.controls.endDate.value;
      ctrlValue.setMonth(normalizedMonth.getMonth());
      ctrlValue.setFullYear(normalizedMonth.getFullYear());
      ctrlValue.setDate(new Date(normalizedMonth.getFullYear(), normalizedMonth.getMonth() + 1, 0).getDate());
      this.filtersForm.controls.endDate.setValue(ctrlValue);
      datepicker.close();
      this.mclick = 0;
    }
  }

  categoriesSelectAll(): void {
    this.categoriesFormArray.controls.map((c) => c.setValue(true));
  }

  categoriesDeselectAll(): void {
    this.categoriesFormArray.controls.map((c) => c.setValue(false));
  }

  resetFilters(): void {
    this.categoriesSelectAll();

    this.filtersForm.patchValue({
      text: '',
      startDate: _moment().startOf('month').toISOString(),
      endDate: _moment().endOf('month').toISOString(),
    });

    this.search();
  }
}
