import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectiveAgreement } from '@app/modules/ceoe/models/collective-agreement';
import { GetCollectiveAgreement } from '@app/modules/ceoe/store/collective-agreements/collective-agreements.actions';
import { CollectiveAgreementsStateService } from '@app/modules/ceoe/store/collective-agreements/collective-agreements.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { NgxExtendedPdfViewerService, defaultOptions } from 'ngx-extended-pdf-viewer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-collective-agreements-search-detail',
  templateUrl: './collective-agreements-search-detail.component.html',
  styleUrls: ['./collective-agreements-search-detail.component.scss'],
})
export class CollectiveAgreementsSearchDetailComponent implements OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  id = '';
  collectiveAgreement?: CollectiveAgreement;
  text = '';
  textSafe: SafeHtml;
  loading = true;

  centralesSindicalesTableData = [];
  centralesSindicalesDisplayedColumns = ['name', 'total', 'mujeres', 'hombres'];

  representantesEmpresarialesTableData = [];
  representantesEmpresarialesDisplayedColumns = ['name', 'total', 'mujeres', 'hombres'];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<CollectiveAgreementsSearchDetailComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public matData: string,
    private activatedRoute: ActivatedRoute,
    private actions: Actions,
    private router: Router,
    private location: Location,
    private collectiveAgreementsStateService: CollectiveAgreementsStateService,
    private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService,
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    defaultOptions.externalLinkTarget = 2;

    setTimeout(() => {
      this.actions
        .pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetCollectiveAgreement))
        .subscribe(() => {
          setTimeout(() => {
            this.collectiveAgreement = this.collectiveAgreementsStateService.collectiveAgreement;
            // this.modifyTextStyles();

            this.centralesSindicalesTableData = this.collectiveAgreement?.centrales_sindicales ?? [];
            this.representantesEmpresarialesTableData = this.collectiveAgreement?.representantes_empresariales ?? [];

            this.changeDetectorRef.detectChanges();
            this.loading = false;
            this.changeDetectorRef.detectChanges();
          }, 0);
        });

      this.collectiveAgreementsStateService.getCollectiveAgreement(this.matData);
    }, 0);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  viewOfficialPublishment(url: string): void {
    window.open(url, '_blank');
  }

  return(): void {
    this.location.back();
  }

  downloadSummary() {
    //
  }

  getCNAESMapped() {
    return `• ${this.collectiveAgreement.cnae.map((c) => c.description).join('\n• ')}`;
  }

  getVozColor(voz: any, alpha: string): string {
    if (this.collectiveAgreementsStateService.filters.filters) {
      const color = `${
        this.collectiveAgreementsStateService.filters.filters
          .find((f) => f.attr === 'voces')
          .items.find((v) => v.label === voz.name).color
      }${alpha}`;

      return color;
    }
  }

  changeOpacity(elementId: string, opacity: number): void {
    const element = document.getElementById(elementId);

    if (element) {
      element.style.opacity = opacity.toString();
    }
  }

  modifyTextStyles(): void {
    const tablePattern = /-start-table-(.*?)-end-table-/g;
    this.collectiveAgreement.text = this.collectiveAgreement.text.replace(tablePattern, '');
    this.text = this.collectiveAgreement.text;

    this.collectiveAgreement.voces.forEach((voz) => {
      const color = this.getVozColor(voz, '');

      (voz as any).terms.forEach((term) => {
        // this.text = this.text
        //   .toLowerCase()
        //   .replace(term.toLowerCase(), `<span style="color: ${color}" class="test">${term}</span>`);

        this.text = this.text.replace(
          new RegExp(term, 'gi'),
          `‎<span style="background-color: ${color}" class="test">${term}</span>‎`
        );
      });
    });

    this.textSafe = this.sanitizer.bypassSecurityTrustHtml(this.text);
  }

  search(voz: any, term: any): void {
    let findBar = window['PDFViewerApplication'] ? window['PDFViewerApplication'].findBar : undefined;

    if (findBar) {
      if (!findBar.opened) {
        findBar.toggleButton.click();
      }

      findBar.findField.value = term;
      // findBar.findNextButton.click();
    }

    this.ngxExtendedPdfViewerService.find(term, {
      highlightAll: true,
      matchCase: false,
      wholeWords: false,
      ignoreAccents: true,
    });

    setTimeout(() => {
      const textLayers = document.querySelectorAll('.textLayer');
      const selectedSpans = document.querySelectorAll('.highlight');

      textLayers.forEach((div: HTMLDivElement) => {
        div.style.opacity = '0.4';
      });

      selectedSpans.forEach((span: HTMLSpanElement) => {
        span.style.backgroundColor = this.getVozColor(voz, '');
      });
    }, 500);
  }
}
