<div id="container-main-dialog-search-operators" class="container-main" [formGroup]="form">
  <div class="header">
    <p class="title">Asistente para búsquedas</p>
    <p class="how-to-use" (click)="howToUse()">¿Cómo usar el buscador?</p>
  </div>
  <div class="data" formArrayName="groups">
    <!-- <div class="group">
      <p>Cuando</p>
      <div class="chips">
        <div class="chip">
          <p>Congreso de los diputados</p>
          <mat-icon>clear</mat-icon>
        </div>
        <div class="chip">
          <p>AND</p>
          <mat-icon>expand_more</mat-icon>
        </div>
        <div class="chip">
          <p>pacto educativo</p>
          <mat-icon>clear</mat-icon>
        </div>
        <div class="chip">
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </div> -->

    <div class="group group-where" *ngIf="groupsFormArray.controls.length > 1">
      <div class="separator">
        <p>Cuando</p>
      </div>
    </div>
    <div
      [class]="'group' + ' ' + 'group-' + i"
      [formGroup]="utilsService.castAbstractControlToFormGroup(group)"
      *ngFor="let group of groupsFormArray.controls; let i = index"
    >
      <div class="separator" *ngIf="utilsService.castAbstractControlToFormGroup(group).controls.separatorValue.value">
        <mat-form-field appearance="fill" class="form-field-no-padding-bottom">
          <mat-select formControlName="separatorValue">
            <mat-option *ngFor="let option of operatorOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="chips" *ngIf="!utilsService.castAbstractControlToFormGroup(group).controls.separatorValue.value">
        <div
          class="chip"
          [class]="utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.value"
          [formGroup]="utilsService.castAbstractControlToFormGroup(groupBlock)"
          *ngFor="let groupBlock of getGroupBlocksFormArray(i).controls; let i2 = index"
        >
          <mat-form-field
            appearance="fill"
            class="form-field-no-padding-bottom"
            *ngIf="
              utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.value === 'text-literal' ||
              utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.value === 'text-amplia'
            "
          >
            <input
              [id]="
                'group-block-input-text-' + utilsService.castAbstractControlToFormGroup(groupBlock).controls.id.value
              "
              matInput
              formControlName="text"
              placeholder="Texto..."
            />
            <button
              mat-button
              mat-icon-button
              matSuffix
              [aria-label]="
                utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.value === 'text-literal'
                  ? 'Cambiar a búsqueda amplia'
                  : 'Cambiar a búsqueda literal'
              "
              [matTooltip]="
                utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.value === 'text-literal'
                  ? 'Cambiar a búsqueda amplia'
                  : 'Cambiar a búsqueda literal'
              "
              (click)="
                utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.value === 'text-literal'
                  ? utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.setValue('text-amplia')
                  : utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.setValue('text-literal')
              "
            >
              <mat-icon>sync</mat-icon>
            </button>
            <button
              mat-button
              mat-icon-button
              matSuffix
              aria-label="Eliminar texto"
              matTooltip="Eliminar texto"
              (click)="deleteGroupBlock(i, i2)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            class="form-field-no-padding-bottom"
            *ngIf="
              utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.value === 'operator-and' ||
              utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.value === 'operator-or' ||
              utilsService.castAbstractControlToFormGroup(groupBlock).controls.type.value === 'operator-not'
            "
          >
            <mat-select formControlName="type">
              <mat-option
                (click)="deleteGroupBlock(i, i2)"
                style="
                  font-size: 14px;
                  font-weight: 400;
                  color: var(--tailwind-color-red-600);
                  border-bottom: 1px solid var(--tailwind-color-gray-300);
                "
              >
                Eliminar
              </mat-option>
              <mat-option
                *ngFor="let option of i2 === 0 ? [operatorOptions[2]] : operatorOptions"
                [value]="option.value"
              >
                {{ option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="chip chip-outline" [matMenuTriggerFor]="menu" aria-label="Añadir opción" matTooltip="Añadir opción">
          <mat-icon>add</mat-icon>
          <mat-menu #menu="matMenu">
            <ng-container
              *ngIf="
                getGroupBlocksFormArray(i).length === 0 ||
                utilsService.castAbstractControlToFormGroup(
                  getGroupBlocksFormArray(i).at(getGroupBlocksFormArray(i).length - 1)
                ).controls.type.value === 'operator-and' ||
                utilsService.castAbstractControlToFormGroup(
                  getGroupBlocksFormArray(i).at(getGroupBlocksFormArray(i).length - 1)
                ).controls.type.value === 'operator-or' ||
                utilsService.castAbstractControlToFormGroup(
                  getGroupBlocksFormArray(i).at(getGroupBlocksFormArray(i).length - 1)
                ).controls.type.value === 'operator-not'
              "
            >
              <p
                style="
                  padding: 8px;
                  font-size: 13px;
                  font-weight: 400;
                  color: var(--tailwind-color-gray-500);
                  border-bottom: 1px solid var(--tailwind-color-gray-300);
                "
              >
                Texto
              </p>
              <button
                mat-menu-item
                style="background: #23dd1330"
                [ngStyle]="{ background: io === 0 ? '#009cdd30' : io === 1 ? '#23dd1330' : '' }"
                [value]="option.value"
                (click)="addGroupBlock(i, { type: option.value })"
                *ngFor="let option of textOptions; let io = index"
              >
                <span style="font-weight: 500">{{ option.label }}</span>
              </button>
            </ng-container>
            <ng-container
              *ngIf="
                getGroupBlocksFormArray(i).length === 0 ||
                utilsService.castAbstractControlToFormGroup(
                  getGroupBlocksFormArray(i).at(getGroupBlocksFormArray(i).length - 1)
                ).controls.type.value === 'text-literal' ||
                utilsService.castAbstractControlToFormGroup(
                  getGroupBlocksFormArray(i).at(getGroupBlocksFormArray(i).length - 1)
                ).controls.type.value === 'text-amplia'
              "
            >
              <p
                style="
                  padding: 8px;
                  font-size: 13px;
                  font-weight: 400;
                  color: var(--tailwind-color-gray-500);
                  border-bottom: 1px solid var(--tailwind-color-gray-300);
                "
              >
                Operadores
              </p>
              <button
                mat-menu-item
                [value]="option.value"
                (click)="addGroupBlock(i, { type: option.value })"
                *ngFor="
                  let option of getGroupBlocksFormArray(i).controls.length === 0
                    ? [operatorOptions[2]]
                    : operatorOptions
                "
              >
                <span style="font-weight: 500">{{ option.label }}</span>
              </button>
            </ng-container>
          </mat-menu>
        </div>
        <div
          class="chip"
          aria-label="Eliminar grupo"
          matTooltip="Eliminar grupo"
          (click)="deleteGroup(i)"
          *ngIf="i > 0"
        >
          <mat-icon>delete</mat-icon>
        </div>
      </div>
    </div>
    <button
      mat-stroked-button
      (click)="addGroup({ separatorValue: 'operator-and' }); addGroup(); addGroupBlock(groupsFormArray.length - 1)"
    >
      Agregar búsqueda
    </button>
    <div class="result">
      <p>Texto:</p>
      <p>{{ result }}</p>
    </div>
  </div>
  <div class="actions">
    <button mat-flat-button color="primary" (click)="applyFilters()">Cargar búsqueda</button>
  </div>
</div>
