import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import _moment from 'moment';
import { Observable } from 'rxjs';
import {
  AlertsGetRequest,
  AlertsGetResponse,
  AlertPostRequest,
  Cpv,
  Import,
  Province,
  Search,
  SearchPostRequest,
  Tender,
  TendersGetRequest,
  TendersGetResponse,
  Alert,
  TendersFavoritesGetRequest,
  TenderSearchsGetRequest,
  TenderSearchsGetResponse,
  TenderSearch,
  AlertUpdateRequest,
} from '../models/tender';
import { environment } from '@env/environment';
import {
  Subvention,
  SubventionMinimis,
  SubventionMinimisGetRequest,
  SubventionsFilters,
  SubventionsGetRequest,
  SubventionsGetResponse,
} from '../models/subvention';

@Injectable({
  providedIn: 'root',
})
export class SubventionsService {
  constructor(private _http: HttpClient) {}

  getSubventions(request: SubventionsGetRequest): Observable<SubventionsGetResponse> {
    return this._http.post<SubventionsGetResponse>(
      `${environment.subventionsSearch}?page=${request.page}&size=${request.size}`,
      request
    );
  }

  getSubventionsFavorites(request: SubventionsGetRequest): Observable<SubventionsGetResponse> {
    return this._http.get<SubventionsGetResponse>(
      `${environment.subventionsFavorites}/?page=${request.page}&size=${request.size}`
    );
  }

  getAlerts(request: SubventionsGetRequest): Observable<SubventionsGetResponse> {
    return this._http.get<SubventionsGetResponse>(
      `${environment.subventionsAlerts}/?page=${request.page}&size=${request.size}`
    );
  }

  postAlert(request: SubventionsGetRequest): Observable<Subvention> {
    return this._http.post<Subvention>(`${environment.subventionsAlerts}`, request);
  }

  putAlert(request: SubventionsGetRequest, id: number): Observable<Subvention> {
    return this._http.put<Subvention>(`${environment.subventionsAlerts}/${id}`, request);
  }

  deleteAlert(request: number): Observable<void> {
    return this._http.delete<void>(`${environment.subventionsAlerts}/${request}`);
  }

  getSubvention(id: number): Observable<Subvention> {
    return this._http.get<Subvention>(`${environment.subventions}/${id}`);
  }

  getFilters(): Observable<
    {
      id: number;
      seccion: string;
      attr: string;
      items: {
        label: string;
        value: number;
      }[];
    }[]
  > {
    return this._http.get<
      {
        id: number;
        seccion: string;
        attr: string;
        items: {
          label: string;
          value: number;
        }[];
      }[]
    >(`${environment.subventionsFilters}`);
  }

  updateSubventionFavoriteAdd(request: number): Observable<void> {
    return this._http.post<void>(`${environment.subventionsFavorites}/${request}`, {});
  }

  updateSubventionFavoriteDelete(request: number): Observable<void> {
    return this._http.delete<void>(`${environment.subventionsFavorites}/${request}`);
  }

  getSubventionMinimis(request: SubventionMinimisGetRequest): Observable<SubventionMinimis> {
    return this._http.get<SubventionMinimis>(
      `${environment.subventions}${request.subventionId}/minimis?page=${request.page}&size=${request.size}`
    );
  }
  exportFile(request: SubventionsGetRequest, exportFile: 'xls' | 'pdf'): Observable<Blob> {
    request.format = exportFile;

    return this._http.post<Blob>(
      `${environment.subventionsExport}?page=${request.page}&size=${request.size}&format=${exportFile}`,
      request,
      { responseType: 'blob' as 'json' }
    );
  }
}
