import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

import moment from 'moment';

import { Favorite } from '@app/models/Favorite';
import { MsgyesnoComponent } from '@app/components/msgyesno/msgyesno.component';

import { environment } from '@env/environment';
import { AppStatusService } from '@app/services/app-status.service';
import { AppDataService } from '@app/services/app-data.service';
import { QueryParams, SearchService } from '@app/modules/main/components/search/services/search.service';

@Component({
  selector: 'app-alerts-overview',
  templateUrl: './alerts-overview.component.html',
  styleUrls: ['./alerts-overview.component.scss'],
})
export class AlertsViewComponent implements OnInit {
  readonly LOGOURL: string = environment.logoApp;

  value = '';

  items: Favorite[] = [];

  displayedColumns: string[] = ['name', 'createdAt', 'updatedAt', 'tools'];

  resultsLength = 0;
  isLoadingResults = true;
  noDocs = true;
  message: string;
  response: boolean;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private sService: SearchService,
    private router: Router,
    private appStatus: AppStatusService,
    private appData: AppDataService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getAlertas();
    this.appStatus.page_change.emit('alertas');
  }

  doSearch(id: number) {
    let query: QueryParams = this.items.find((item) => item.id === id).data;

    this.router.navigate(['/documents']);

    setTimeout(() => {
      this.appStatus.search_launch.emit({
        text_search: query.text,
        start: query.dateParsed_start,
        //end:query.dateParsed_end,
        spiderIds: query.spiderIds.map((_id) => {
          return { id: _id, itemName: this.appData.map('spiders_id', _id) };
        }),
        docTopics: query.docTopics.map((_id) => {
          return { id: _id, itemName: this.appData.map('topics_id', _id) };
        }),
      });
    }, 100);
  }

  delAlerta(id: number) {
    let query: QueryParams = this.items.find((item) => item.id === id).data;
    let name: string = this.items.find((item) => item.id === id).name;
    let param = { name: name, data: query };
    this.sService.saveAlertSearch(id, param).subscribe((ev) => {
      this.items = this.items.filter((item) => item.id != id);
    });
  }

  openDialog(id: number): void {
    this.message = '¿Seguro que quieres eliminar la alerta?';
    const dialogRef = this.dialog.open(MsgyesnoComponent, {
      width: '250px',
      data: { message: this.message, response: this.response },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.response = result;
      if (result) {
        this.delAlerta(id);
      }
    });
  }

  getAlertas() {
    this.sService!.getFavoriteSearch({ tipo: 'documents' }).subscribe((data) => {
      this.resultsLength = data.count;
      this.items = data.results
        .filter((favorite) => {
          return new Favorite({
            id: favorite.id,
            name: favorite.name,
            created_at: moment(favorite.created_at),
            updated_at: moment(favorite.updated_at),
            data: favorite.data,
            alert: favorite.alert,
          });
        })
        .filter((item) => item.alert != null);

      this.noDocs = this.items.length === 0 ? true : false;
      this.isLoadingResults = false;
    });
  }
}
