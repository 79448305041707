import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import {
  InitiativeStateModel,
  Initiative,
  UIInitiativeStatus,
  InitiativeMenuFilters,
} from '../models/initiative.state.model';
import {
  ShowCalendar,
  ShowList,
  GetInitiatives,
  SetInitiative,
  EditInitiative,
  AddInitiative,
  AddCustomInitiative,
  AddPastProcedure,
  AddDeadline,
  FollowInitiative,
  GetOpenInitiatives,
  ToggleInitiativeMenuFilters,
  DeleteInitiative,
} from './initiative.actions';

@Injectable({
  providedIn: 'root',
})
@State<InitiativeStateModel>({
  name: 'initiatives',
  defaults: {
    initiatives: [],
    openInitiatives: {
      initiatives: [],
      total: 0,
    },
    menuFilters: {
      opened: true,
    },
    uiStatus: UIInitiativeStatus.calendar,
  },
})
export class InitiativeState {
  @Selector()
  static initiatives(state: InitiativeStateModel): Initiative[] | null {
    return state.initiatives;
  }

  @Selector()
  static openInitiatives(state: InitiativeStateModel): {
    initiatives: Initiative[];
    total: number;
  } | null {
    return state.openInitiatives;
  }

  @Selector()
  static selectedInitiative(state: InitiativeStateModel): Initiative | null {
    return state.selectedInitiative;
  }

  @Selector()
  static menuFilters(state: InitiativeStateModel): InitiativeMenuFilters | null {
    return state.menuFilters;
  }

  @Selector()
  static uiStatus(state: InitiativeStateModel): UIInitiativeStatus | null {
    return state.uiStatus;
  }

  constructor(
    private _dialogRef: MatDialog,
    private _snackbarStateService: SnackbarStateService,
    private _spinner: NgxSpinnerService
  ) {}

  @Action(ShowCalendar)
  showCalendarByState({ patchState }: StateContext<InitiativeStateModel>) {
    patchState({ uiStatus: UIInitiativeStatus.calendar });
  }

  @Action(ShowList)
  showListByState({ patchState }: StateContext<InitiativeStateModel>) {
    patchState({ uiStatus: UIInitiativeStatus.list });
  }

  @Action(GetInitiatives)
  getInitiativesByState({ patchState }: StateContext<InitiativeStateModel>, response: { payload }) {
    patchState({ initiatives: response.payload });
  }

  @Action(SetInitiative)
  setInitiativeByState({ patchState }: StateContext<InitiativeStateModel>, response: { payload }) {
    patchState({ selectedInitiative: response.payload });
  }

  @Action(AddInitiative)
  addInitiativeByState({ getState, patchState }: StateContext<InitiativeStateModel>, response: { payload }) {
    let initiatives = JSON.parse(JSON.stringify(getState())).initiatives;

    // initiatives.push(response.payload);
    patchState({ initiatives: initiatives });
  }

  @Action(DeleteInitiative)
  deleteInitiativeByState({ getState, patchState }: StateContext<InitiativeStateModel>, response: { payload }) {
    let initiatives = JSON.parse(JSON.stringify(getState())).initiatives;

    // initiatives.push(response.payload);
    patchState({ initiatives: initiatives });
  }

  @Action(AddCustomInitiative)
  addCustomInitiativeByState({ getState, patchState }: StateContext<InitiativeStateModel>, response: { payload }) {
    let initiatives = JSON.parse(JSON.stringify(getState())).initiatives;
    initiatives.push(response.payload);
    this._snackbarStateService.show('Iniciativa creada con éxito', 5000, 'cerrar');
    patchState({ initiatives: initiatives });
  }

  @Action(AddPastProcedure)
  addPastProcedureByState({ getState, patchState }: StateContext<InitiativeStateModel>, response: { payload }) {
    let initiatives = JSON.parse(JSON.stringify(getState())).initiatives;
    // initiatives.push(response.payload);
    // patchState({ initiatives:  initiatives});
  }

  @Action(AddDeadline)
  addDeadLineByState({ getState, patchState }: StateContext<InitiativeStateModel>, response: { payload }) {
    let initiatives = JSON.parse(JSON.stringify(getState())).initiatives;
    // initiatives.push(response.payload);
    // patchState({ initiatives:  initiatives});
  }

  @Action(EditInitiative)
  editInitiativeByState({ getState, patchState }: StateContext<InitiativeStateModel>, response: { payload }) {
    let initiatives = JSON.parse(JSON.stringify(getState())).initiatives;
    initiatives.map((initiative) => {
      if (initiative.id === response.payload.id) {
        initiative = response.payload;
      }
    });
    patchState({ initiatives: initiatives });
  }

  @Action(GetOpenInitiatives)
  getOpenInitiativesByState({ patchState }: StateContext<InitiativeStateModel>, response: { payload }) {
    // let openInitiatives: Initiative[] = response.payload.iniciativas.filter(
    //   (initiative) => (initiative.pending || initiative.actual_status == 'PENDIENTE') && !initiative.result
    // );

    patchState({
      openInitiatives: {
        initiatives: response.payload.iniciativas || response.payload.normativas,
        total: response.payload.total,
      },
    });
  }

  @Action(FollowInitiative)
  followInitiativeByState({ getState, patchState }: StateContext<InitiativeStateModel>, response: { payload }) {
    let initiatives = JSON.parse(JSON.stringify(getState())).initiatives;
    initiatives.map((initiative) => {
      if (initiative.id === response.payload.id) {
        initiative.siguiendo = true;
      }
    });
    patchState({ initiatives: initiatives });
  }

  @Action(ToggleInitiativeMenuFilters)
  toggleInitiativeMenuFilters({ getState, patchState }: StateContext<InitiativeStateModel>) {
    let menuFilters = JSON.parse(JSON.stringify(getState())).menuFilters;
    menuFilters.opened = !menuFilters.opened;
    patchState({ menuFilters });
  }
}
