import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subvention, SubventionsGetRequest } from '../../models/subvention';
import { GetSubventions, UpdateSubventionsMenuFilters } from '../../store/subventions/subventions.actions';
import { SubventionsStateService } from '../../store/subventions/subventions.service';
import { SubventionsSearchCreateAlertDialogComponent } from './subventions-search-create-alert-dialog/subventions-search-create-alert-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SubventionsSearchDetailComponent } from './subventions-search-detail/subventions-search-detail.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SubventionsSearchFiltersComponent } from './subventions-search-filters/subventions-search-filters.component';
import { SubventionsService } from '../../services/subventions.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-subventions',
  templateUrl: './subventions-search.component.html',
  styleUrls: ['./subventions-search.component.scss'],
})
export class SubventionsSearchComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  componentDestroyed$: Subject<boolean> = new Subject();
  displayedColumns: string[] = ['card'];
  pageIndex = 0;
  loading = true;
  withFiltersFromAlerts?: number;
  downloadingExcel = false;
  downloadingPdf = false;

  constructor(
    public subventionsStateService: SubventionsStateService,
    private subventionsService: SubventionsService,
    private actions: Actions,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.withFiltersFromAlerts = Number(params.withFiltersFromAlerts);
    });

    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetSubventions)).subscribe(() => {
      this.loading = false;

      setTimeout(() => {
        if (this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.length = this.subventionsStateService.subventions.total;
        }
      }, 0);

      document.querySelector('.container .header .title').scrollIntoView({ block: 'start', inline: 'start' });
    });

    setTimeout(() => {
      this.actions
        .pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(UpdateSubventionsMenuFilters))
        .subscribe(() => {
          this.pageIndex = 0;
          this.subventionsStateService.getSubventions(this.getSubventionsRequest());
        });
    }, 0);
  }

  ngOnInit(): void {
    this.pageIndex = 0;
    this.subventionsStateService.getSubventions(this.getSubventionsRequest());
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  createAlert(): void {
    this.dialog.open(SubventionsSearchCreateAlertDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      data: {
        withFiltersFromAlerts: this.withFiltersFromAlerts,
      },
    });
  }

  nextGeneration(): void {
    setTimeout(() => {
      this.router.navigate(['subventions', 'search'], {
        queryParams: { setNextGeneration: !this.nextGenerationFiltersAreActive() },
      });
    }, 250);
  }

  saveItem(evt: Event, row: Subvention) {
    evt.stopPropagation();

    if (row.favorito) {
      this.subventionsStateService.updateSubventionFavoriteeDelete(row.id);
    } else {
      this.subventionsStateService.updateSubventionFavoriteAdd(row.id);
    }

    return false;
  }

  pageEvent(evt: PageEvent): void {
    this.loading = true;
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.subventionsStateService.getSubventions(this.getSubventionsRequest());
  }

  getSubventionsRequest(): SubventionsGetRequest {
    return {
      abierta: true,
      texto: (this.subventionsStateService.filters.selectedFilters?.texto as string) || '',
      organismo_convocante:
        (this.subventionsStateService.filters.selectedFilters?.organismo_convocante as string[]) || [],
      fondos: (this.subventionsStateService.filters.selectedFilters?.fondos as string[]) || [],
      beneficiario: (this.subventionsStateService.filters.selectedFilters?.beneficiarios as string[]) || [],
      instrumento_ayuda: (this.subventionsStateService.filters.selectedFilters?.instrumento_ayuda as string[]) || [],
      estado: (this.subventionsStateService.filters.selectedFilters?.estado as string[]) || [],
      finalidad: (this.subventionsStateService.filters.selectedFilters?.finalidad as string[]) || [],
      provincias: (this.subventionsStateService.filters.selectedFilters?.provincias as string[]) || [],
      comunidades: (this.subventionsStateService.filters.selectedFilters?.comunidades as string[]) || [],
      sector_economico: this.getSectorEconomicoRequest(),
      presupuesto:
        (this.subventionsStateService.filters.selectedFilters?.presupuesto as unknown as {
          from: number;
          to: number;
        }[]) || [],
      fecha_publicacion_ini:
        !this.subventionsStateService.filters.selectedFilters?.fecha_publicacion_desde ||
        this.subventionsStateService.filters.selectedFilters?.fecha_publicacion_desde === 'Fecha inválida'
          ? undefined
          : (this.subventionsStateService.filters.selectedFilters?.fecha_publicacion_desde as string),
      fecha_publicacion_fin:
        !this.subventionsStateService.filters.selectedFilters?.fecha_publicacion_hasta ||
        this.subventionsStateService.filters.selectedFilters?.fecha_publicacion_hasta === 'Fecha inválida'
          ? undefined
          : (this.subventionsStateService.filters.selectedFilters?.fecha_publicacion_hasta as string),
      fecha_plazo_ini:
        !this.subventionsStateService.filters.selectedFilters?.fecha_finalizacion_desde ||
        this.subventionsStateService.filters.selectedFilters?.fecha_finalizacion_desde === 'Fecha inválida'
          ? undefined
          : (this.subventionsStateService.filters.selectedFilters?.fecha_finalizacion_desde as string),
      fecha_plazo_fin:
        !this.subventionsStateService.filters.selectedFilters?.fecha_finalizacion_hasta ||
        this.subventionsStateService.filters.selectedFilters?.fecha_finalizacion_hasta === 'Fecha inválida'
          ? undefined
          : (this.subventionsStateService.filters.selectedFilters?.fecha_finalizacion_hasta as string),
      page: this.pageIndex + 1,
      size: 30,
    };
  }

  openDetail(id: number): void {
    this._bottomSheet.open(SubventionsSearchDetailComponent, {
      autoFocus: false,
      panelClass: 'panel-bottom-sheet-w-100-h-100',
      data: id,
    });
  }

  getSectorEconomicoRequest(): string[] {
    return this.arraySplitChildItems(
      (this.subventionsStateService.filters.selectedFilters?.sector_economico as string[]) || []
    );
  }

  arraySplitChildItems(input: string[]): string[] {
    const output: string[] = [];

    for (const item of input) {
      if (item.includes(',')) {
        const childItems = item.split(',');
        output.push(...childItems);
      } else {
        output.push(item);
      }
    }

    return output;
  }

  nextGenerationFiltersAreActive(): boolean {
    // return (this.subventionsStateService.filters.selectedFilters['fondos'] as string[])?.every((f) =>
    //   SubventionsSearchFiltersComponent.nextGenerationIds.map((i) => String(i)).includes(f)
    // );

    return SubventionsSearchFiltersComponent.nextGenerationIds
      .map((i) => String(i))
      .every((f) => (this.subventionsStateService.filters.selectedFilters['fondos'] as string[])?.includes(f));
  }

  exportFile(exportFile: 'xls' | 'pdf'): void {
    if (exportFile === 'xls') {
      this.downloadingExcel = true;
    } else if (exportFile === 'pdf') {
      this.downloadingPdf = true;
    }

    this.subventionsService.exportFile(this.getSubventionsRequest(), exportFile).subscribe(
      (data) => {
        if (exportFile === 'xls') {
          const filename = `ayudas_y_subvenciones.xls`;
          saveAs(data, filename);
        } else if (exportFile === 'pdf') {
          const filename = `ayudas_y_subvenciones.pdf`;
          saveAs(new Blob([data], { type: 'application/pdf' }), filename);
        }

        this.downloadingPdf = false;
      },
      () => {
        this.downloadingExcel = false;
      }
    );
  }
}
