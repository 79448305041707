import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppStateService } from '@app/state/app/app.service';
import { ActivatedRoute } from '@angular/router';
import { MagazineStateService } from '@app/modules/documents/store/magazine/magazine.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Magazine, MagazineSearch } from '@app/modules/documents/store/models/magazine.state.model';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '@app/modules/shared/components/delete-dialog/delete-dialog.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MagazineService } from '@app/services/magazine.service';
import { CustomValidators } from '@app/modules/shared/validators/custom-validators';

@Component({
  selector: 'app-magazine-settings',
  templateUrl: './magazine-settings.component.html',
  styleUrls: ['./magazine-settings.component.scss'],
})
export class MagazineSettingsComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  magazine: Magazine = this.magazineStateService.selectedMagazine;
  sections: { label: string; value: any }[] = [];
  hideCategories: boolean = false;

  constructor(
    public appStateService: AppStateService,
    public magazineStateService: MagazineStateService,
    public magazineService: MagazineService,
    public dialog: MatDialog,
    private customValidators: CustomValidators,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute
  ) {
    this.appStateService.addHistoryUrl('clipping/magazine');

    this._route.paramMap.subscribe((params) => {
      if (!params.get('id')) {
        this.magazine = undefined;
        this.appStateService.addTitle('Nueva revista');

        setTimeout(() => {
          this.selectAllSpiders();
        }, 0);
      } else {
        this.appStateService.addTitle('Editar revista');
      }
    });
  }

  ngOnInit() {
    this.appStateService.showTitle();
    this.appStateService.hideDates();
    this.appStateService.hideSearchText();
    this.appStateService.hideSubForm();

    this.magazineService.getMagazineSections().subscribe((data) => {
      this.sections = data.items[0].items;
    });

    this.formGroup = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      searches: new FormArray([], [Validators.required]),
      spiders: new FormControl([], [Validators.required]),
    });

    if (this.magazine) {
      this.formGroup.patchValue(this.magazine);
      this.magazine.searches.map((search) => this.addSearch(search));
    } else {
      this.magazine = this.formGroup.value;
      this.addSearch();
    }
  }

  ngOnDestroy(): void {
    this.appStateService.addHistoryUrl(undefined);
  }

  get id() {
    return this.formGroup.get('id');
  }

  get name() {
    return this.formGroup.get('name');
  }

  get searches() {
    return this.formGroup.get('searches') as FormArray;
  }

  get spiders() {
    return this.formGroup.get('spiders');
  }

  setSpiders($event) {
    this.spiders.setValue($event);
  }

  addSearch(search?: MagazineSearch) {
    const searchFormArray: FormGroup = this._formBuilder.group({
      escrita: [true],
      digital: [true],
      spiders: [],
      category: [''],
      section: [[]],
      search: ['', [Validators.required, this.customValidators.minAlphanumeric(3)]],
      max_articles: [0, [Validators.required, Validators.min(0)]],
      show: [true, Validators.required],
      alert_period: [0, [Validators.required, Validators.min(0)]],
    });

    if (search) searchFormArray.patchValue(search);

    this.searches.push(searchFormArray);
  }

  deleteSearch(index: number) {
    if (this.searches.length > 1) this.searches.removeAt(index);
  }

  submitForm() {
    if (this.formGroup.invalid) return;

    this.searches.controls.map((search) => {
      if (search['controls']['escrita'].value && search['controls']['digital'].value)
        search['controls']['spiders'].setValue(
          this.spiders.value.escrita
            .concat(this.spiders.value.digital)
            .filter((s) => s)
            .map((s) => Number(s))
        );
      else if (search['controls']['escrita'].value && !search['controls']['digital'].value)
        search['controls']['spiders'].setValue(this.spiders.value.escrita.filter((s) => s).map((s) => Number(s)));
      else if (!search['controls']['escrita'].value && search['controls']['digital'].value)
        search['controls']['spiders'].setValue(this.spiders.value.digital.filter((s) => s).map((s) => Number(s)));
    });

    this.spiders.setValue(
      this.spiders.value.escrita
        .concat(this.spiders.value.digital)
        .filter((s) => s)
        .map((s) => Number(s))
    );

    if (this.id.value === '') this.magazineStateService.addMagazine(this.formGroup.value);
    else this.magazineStateService.editMagazine(this.formGroup.value);
  }

  deleteMagazine() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '650px',
      data: this.magazine,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.delete) this.magazineStateService.deleteMagazine(this.magazine);
    });
  }

  dropSectionQuestion(event: CdkDragDrop<string[]>, indexSection: number): void {
    this.moveItemInFormArray(this.searches, event.previousIndex, event.currentIndex);
  }

  moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
    const dir = toIndex > fromIndex ? 1 : -1;
    const item = formArray.at(fromIndex);

    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }

    formArray.setControl(toIndex, item);
  }

  selectAllSpiders(): void {
    document.querySelectorAll('#chips-autocomplete-select-all').forEach((button) => {
      (button as HTMLButtonElement).click();
    });

    // document.querySelectorAll('#button-chips-autocomplete-toggle').forEach((button) => {
    //   (button as HTMLButtonElement).click();
    // });
  }

  sectionCompareValue(attr1, attr2) {
    if (JSON.stringify(attr1) === JSON.stringify(attr2)) {
      return attr1;
    } else {
      return null;
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
