import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from '@app/http-interceptors';
import { TendersModule } from '../ceoe/tenders/tenders.module';
import { SubventionsModule } from '../ceoe/subventions/subventions.module';
import { CollectiveAgreementsModule } from '../ceoe/collective-agreements/collective-agreements.module';
import { InsufficientPermissionsComponent } from './components/insufficient-permissions/insufficient-permissions.component';
import { LoadingService } from '@app/services/loading.service';
import { AppDataFactory, AppDataService } from '@app/services/app-data.service';
import { BusinessStatisticsModule } from '../ceoe/business-statistics/business-statistics.module';
import { SectorReportsModule } from '../ceoe/sector-reports/sector-reports.module';
import { ErrorUserDataComponent } from './components/error-user-data/error-user-data.component';
import { FavSearchComponent } from '../documents/components/fav-search/fav-search.component';
import { FavDocsComponent } from '../documents/components/fav-docs/fav-docs.component';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './components/home/home.component';
import { HomeHelpComponent } from './components/homehelp/homehelp.component';
import { MainComponent } from './components/main/main.component';
import { MainSearch } from './components/search/search.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    TendersModule,
    BusinessStatisticsModule,
    SectorReportsModule,
    SubventionsModule,
    CollectiveAgreementsModule,
  ],
  declarations: [
    MainComponent,
    HomeComponent,
    InsufficientPermissionsComponent,
    ErrorUserDataComponent,
    FavSearchComponent,
    FavDocsComponent,
    HomeHelpComponent,
    MainSearch,
  ],
  exports: [],
  providers: [
    LoadingService,
    AppDataService,
    httpInterceptorProviders,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_INITIALIZER, useFactory: AppDataFactory, deps: [AppDataService], multi: true },
  ],
})
export class MainModule { }
