import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarPPStateService } from '../../store/calendar-pp/calendar-pp.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AppStateService } from '@app/state/app/app.service';
import { UIStatus } from '@app/state/models/app.state.model';
import { MatTableDataSource } from '@angular/material/table';
import { VigilanciaService } from '../../services/vigilancia.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, NavItem } from '@app/models/User';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { VigilanciaCalendarPPCreateAlertDialogComponent } from '../vigilancia-calendar-pp-create-alert-dialog/vigilancia-calendar-pp-create-alert-dialog.component';
import { getRealm, KC_REALM_CEOE } from '@app/init/keycloak-init.factory';

@Component({
  selector: 'app-vigilancia-calendar-pp',
  templateUrl: './vigilancia-calendar-pp.component.html',
  styleUrls: ['./vigilancia-calendar-pp.component.scss'],
})
export class VigilanciaCalendarPPComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  rainbowColors: string[] = [
    "#F59E0B",
    "#10B981",
    "#0EA5E9",
    "#6366F1",
    "#D946EF",
    "#F43F5E",
  ];
  itemsDataSource: MatTableDataSource<any> = new MatTableDataSource();
  uiStatus = UIStatus;
  form: FormGroup;
  subItemName = '';

  pageIndex = 0;
  pageSize = 30;
  withFiltersFromAlerts?: number;

  displayedColumns: string[] = ['presented', 'title', 'origin', 'type'];

  dropdownTypesList = [];

  dropdownMinistriesList = [];
  selectedMinistriesItems = [];
  dropdownMinistriesSettings = {
    text: 'Selección de ministerio',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    selectGroup: false,
    showCheckbox: true,
    singleSelection: false,
  };

  dropdownCCAAList = [];
  selectedCCAAItems = [];
  dropdownCCAASettings = {
    text: 'Selección de CCAA',
    searchPlaceholderText: 'Filtrar',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    selectGroup: false,
    showCheckbox: true,
    singleSelection: false,
  };

  constructor(
    public calendarPPStateService: CalendarPPStateService,
    public appStateService: AppStateService,
    private vigilanciaService: VigilanciaService,
    private authStateService: AuthStateService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.form = this.fb.group({
      search: ['', []],
      types: this.fb.array([])
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.withFiltersFromAlerts = Number(params.withFiltersFromAlerts);
    });
  }

  ngOnInit(): void {
    this.appStateService.showTitle();
    this.appStateService.hideDates();
    this.appStateService.hideSearchText();
    this.appStateService.hideSubForm();
    this.appStateService.updateUIStatus(UIStatus.loading);
    this.subItemName = this.getSubitemName();

    this.vigilanciaService.getCalendarPPFilters().subscribe((data) => {
      this.dropdownTypesList = data.items.find(i => i.attr === 'type').items.map((i, index) => {
        return {
          id: i.value,
          itemName: i.label,
          color: this.rainbowColors[index]
        };
      });

      this.form.setControl('types', this.fb.array(this.dropdownTypesList.map(() => this.fb.control(false))));

      this.dropdownMinistriesList = data.items.find(i => i.attr === 'ministries').items.map((i) => {
        return {
          id: i.value,
          itemName: i.label,
        };
      });

      this.dropdownCCAAList = data.items.find(i => i.attr === 'ccaa').items.map((i) => {
        return {
          id: i.value,
          itemName: i.label,
        };
      });

      this.setAlertFilters();
      this.search();
    });
  }

  setAlertFilters(): void {
    let alert: any | undefined = undefined;

    if (this.calendarPPStateService.alerts) {
      alert = (this.calendarPPStateService.alerts as any).items.find(
        (ts) => ts.id === Number(this.withFiltersFromAlerts)
      );
    } else {
      this.calendarPPStateService.getAlerts({ page: 1, size: 999, ccaa: '', min: '', search: '', type: '' });
    }


    if (alert) {
      this.form.controls.search.setValue(alert.busqueda.search);
      alert.busqueda.type.split(",").filter(n => n !== "").map(Number).forEach(value => {
        (this.form.controls.types as FormArray).at(this.dropdownTypesList.findIndex(t => t.id === value)).setValue(true);
      });
      this.selectedMinistriesItems = alert.busqueda.min
        ? alert.busqueda.min.split(",").filter(n => n !== "").map(Number).map((i) => {
          return {
            id: i,
            itemName: this.getMinistryItem(i).itemName,
          };
        })
        : [];
      this.selectedCCAAItems = alert.busqueda.ccaa
        ? alert.busqueda.ccaa.split(",").filter(n => n !== "").map(Number).map((i) => {
          return {
            id: i,
            itemName: this.getCcaaItem(i).itemName,
          };
        })
        : [];
    }
  }

  search(page?: number): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      if (!page) {
        this.pageIndex = 0;
      }

      this.vigilanciaService
        .getCalendarPPItems({
          page: this.pageIndex + 1,
          size: this.pageSize,
          search: this.form.controls.search.value,
          min: this.selectedMinistriesItems.length > 0 ? this.selectedMinistriesItems.map((i) => i.id).join(',') : '',
          ccaa: this.selectedCCAAItems.length > 0 ? this.selectedCCAAItems.map((i) => i.id).join(',') : '',
          type: this.form.value.types.length > 0 ? this.form.value.types.map((t, i) => t ? this.dropdownTypesList[i].id : null).filter((t) => t !== null).join(',') : '',
        })
        .subscribe((data) => {
          this.itemsDataSource = new MatTableDataSource(data.items);

          setTimeout(() => {
            if (this.paginator) {
              this.paginator.pageIndex = this.pageIndex;
              this.paginator.length = data.total;
            }

            this.appStateService.updateUIStatus(UIStatus.loaded);
          }, 0);
        });
    }
  }

  pageEvent(evt: PageEvent): void {
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.search(this.pageIndex);
  }

  openUrlInNewWindow(url: string) {
    window.open(url, '_blank');
  }

  getSubitemName(): string {
    const activeSubItem = this.findActiveSubItem();

    if (activeSubItem.parent.id === 6) {
      // Vigilancia legislativa
      if (this.router.url.startsWith('/vigilancia/tnor')) {
        return `${activeSubItem?.child?.items.find((i) => this.router.url.startsWith(i.path)).parent_module} | ${activeSubItem?.child?.items.find((i) => this.router.url.startsWith(i.path)).title
          }`;
      } else {
        return activeSubItem?.child?.items.find((i) => this.router.url.includes(i.path)).title ?? '';
      }
    } else {
      return activeSubItem?.parent?.items.find((i) => this.router.url.includes(i.path)).title ?? '';
    }
  }

  findActiveSubItem(): { parent: MenuItem; child: NavItem } | null {
    for (const menuItem of this.authStateService.userConfig.interface_config.menu_items) {
      for (const subItem of menuItem.items) {
        if (subItem.path && window.location.href.includes(subItem.path)) {
          return {
            parent: menuItem,
            child: subItem,
          };
        }
      }
    }

    return null;
  }

  resetFilters(): void {
    this.appStateService.updateUIStatus(this.uiStatus.loading);

    this.form.patchValue({
      search: '',
    });

    this.form.setControl('types', this.fb.array(this.dropdownTypesList.map(() => this.fb.control(false))));

    this.selectedMinistriesItems = [];
    this.selectedCCAAItems = [];

    setTimeout(() => {
      this.search();

      if (this.router.url.includes('withFiltersFromAlerts')) {
        if (getRealm() === KC_REALM_CEOE) {
          this.router.navigate(['vigilancia', 'tnor', 'fa', 'calendarPp']);
        } else {
          this.router.navigate(['regulatorio', 'calendarPp']);
        }
      }
    }, 100);
  }

  createAlert(): void {
    this.dialog.open(VigilanciaCalendarPPCreateAlertDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      data: {
        withFiltersFromAlerts: this.withFiltersFromAlerts,
        filters: {
          search: this.form.controls.search.value,
          min: this.selectedMinistriesItems.length > 0 ? this.selectedMinistriesItems.map((i) => i.id).join(',') : '',
          ccaa: this.selectedCCAAItems.length > 0 ? this.selectedCCAAItems.map((i) => i.id).join(',') : '',
          type: this.form.value.types.length > 0 ? this.form.value.types.map((t, i) => t ? this.dropdownTypesList[i].id : null).filter((t) => t !== null).join(',') : '',
        }
      },
    });
  }

  getMinistryItem(id: number): any {
    return this.dropdownMinistriesList.find(m => m.id === id);
  }

  getCcaaItem(id: number): any {
    return this.dropdownCCAAList.find(c => c.id === id);
  }

  getColor(type: string): string {
    return this.dropdownTypesList.find(t => t.itemName === type)?.color ?? '';
  }
}
