import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '@app/modules/main/components/main/main.component';
import { HomeComponent } from '@app/modules/main/components/home/home.component';
import { PageNotFoundComponent } from '@app/components/page-not-found/page-not-found.component';
import { HomeHelpComponent } from '@modules/main/components/homehelp/homehelp.component';
import { AuthKeycloakGuard } from '@app/utils/auth-keycloak,guard';
import { InsufficientPermissionsComponent } from '../main/components/insufficient-permissions/insufficient-permissions.component';
import { ErrorUserDataComponent } from '../main/components/error-user-data/error-user-data.component';
import { FavDocsComponent } from '../documents/components/fav-docs/fav-docs.component';
import { FavSearchComponent } from '../documents/components/fav-search/fav-search.component';
import { MagazineViewComponent } from '../documents/components/magazine/magazine-view/magazine-view.component';

const appRoutesKeycloak: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  // {
  //   path: 'clipping/magazine/:magazineID/view/:draftID',
  //   component: MagazineViewComponent,
  // },
  // {
  //   path: 'clipping/magazine/:magazineID/view/:draftID/:catId',
  //   component: MagazineViewComponent,
  // },
  // {
  //   path: 'clipping/magazine/:magazineID/view/:draftID/:catId/:docId',
  //   component: MagazineViewComponent,
  // },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'insufficient-permissions',
        component: InsufficientPermissionsComponent,
        canActivate: [AuthKeycloakGuard],
        data: { roles: [] },
      },
      { path: 'home', component: HomeComponent, canActivate: [AuthKeycloakGuard], data: { roles: [] } },
      {
        path: 'bulletin',
        loadChildren: () => import('../bulletin/bulletin.module').then((m) => m.BulletinModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: [] },
      },
      { path: 'help', component: HomeHelpComponent, canActivate: [AuthKeycloakGuard], data: { roles: [] } },
      {
        path: 'viewer',
        loadChildren: () => import('../viewers/viewers.module').then((m) => m.ViewersModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: [] },
      },
      {
        path: 'dashboards',
        loadChildren: () => import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: [] },
      },
      { path: 'favorites', component: FavDocsComponent, canActivate: [AuthKeycloakGuard], data: { roles: [] } },
      {
        path: 'favorites/search',
        component: FavSearchComponent,
        canActivate: [AuthKeycloakGuard],
        data: { roles: [] },
      },
      {
        path: 'documents',
        loadChildren: () => import('../documents/documents.module').then((m) => m.DocumentModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['documentos'] },
      },
      {
        path: 'clipping',
        loadChildren: () => import('../documents/documents.module').then((m) => m.DocumentModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['clipping'] },
      },
      {
        path: 'regulatorio',
        loadChildren: () => import('../documents/documents.module').then((m) => m.DocumentModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['seguimiento'] },
      },
      {
        path: 'vigilancia',
        loadChildren: () => import('../vigilancia/vigilancia.module').then((m) => m.VigilanciaModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['vigilancia'] },
      },
      {
        path: 'regulatory',
        loadChildren: () => import('../regulatory/regulatory.module').then((m) => m.RegulatoryModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: [] },
      },
      {
        path: 'estadisticas',
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['estadisticas'] },
        loadChildren: () => import('../estadisticas/estadisticas.module').then((m) => m.EstadisticasModule),
      },
      {
        path: 'tenders',
        loadChildren: () => import('../ceoe/tenders/tenders.module').then((m) => m.TendersModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['licitaciones'] },
      },
      {
        path: 'subventions',
        loadChildren: () => import('../ceoe/subventions/subventions.module').then((m) => m.SubventionsModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['ayudas'] },
      },
      {
        path: 'collective-agreements',
        loadChildren: () =>
          import('../ceoe/collective-agreements/collective-agreements.module').then(
            (m) => m.CollectiveAgreementsModule
          ),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['convenios'] },
      },
      {
        path: 'business-statistics',
        loadChildren: () =>
          import('../ceoe/business-statistics/business-statistics.module').then((m) => m.BusinessStatisticsModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['businessstatistics'] },
      },
      {
        path: 'europe',
        loadChildren: () => import('../video/video.module').then((m) => m.VideoModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['europa'] },
      },
      {
        path: 'europa',
        loadChildren: () => import('../europe/europe.module').then((m) => m.EuropeModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['europa'] },
      },
      {
        path: 'sector-reports',
        loadChildren: () => import('../ceoe/sector-reports/sector-reports.module').then((m) => m.SectorReportsModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['informesectoriales'] },
      },
      {
        path: 'alert-feedback',
        loadChildren: () => import('../alert-feedback/alert-feedback.module').then((m) => m.AlertFeedbackModule),
        canActivate: [AuthKeycloakGuard],
        data: { roles: [] },
      },
      {
        path: 'test-locked-module',
        component: PageNotFoundComponent,
        canActivate: [AuthKeycloakGuard],
        data: { roles: ['test_rol'] },
      },
    ],
  },
  { path: 'error-data', component: ErrorUserDataComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutesKeycloak, { enableTracing: false })],
  exports: [RouterModule],
})
export class RoutingModule { }
