<div class="container">
  <div class="header">
    <p class="title">Buscador de convenios colectivos</p>
    <div class="buttons">
      <!-- <button mat-flat-button (click)="createAlert()">
        <mat-icon>notifications</mat-icon>
        <p>Crear alerta</p>
      </button> -->
    </div>
  </div>
  <table mat-table [dataSource]="(collectiveAgreementsStateService.collectiveAgreements$ | async).items">
    <ng-container matColumnDef="card">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <a class="container-card" (click)="openDetail(row.docId)">
          <div class="header">
            <p>{{ row.denominacion }}</p>
            <!-- <button mat-icon-button (click)="saveItem($event, row)" [ngClass]="{ favorite: row.favorito }">
              <mat-icon>favorite</mat-icon>
            </button> -->
          </div>
          <div class="content">
            <div class="sections">
              <div class="section">
                <mat-icon>pin</mat-icon>
                <div class="content">
                  <p class="name">Cód. Convenio</p>
                  <p class="value">{{ row.cod_convenio }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>public</mat-icon>
                <div class="content">
                  <p class="name">Ámbito territorial</p>
                  <p class="value">
                    {{ row.ambito_territorial }}
                    <mat-icon
                      (mouseover)="changeDisplay('section-territorial-ambit-' + row.docId, 'flex')"
                      (mouseout)="changeDisplay('section-territorial-ambit-' + row.docId, 'none')"
                      >help</mat-icon
                    >
                  </p>
                  <div
                    [id]="'section-territorial-ambit-' + row.docId"
                    class="tooltip tooltip-section"
                    [ngStyle]="{ background: '#F3F4F6' }"
                  >
                    <!-- • {{ row.location.join('&#13;') }} -->
                    <p *ngFor="let location of row.location">• {{ location }}</p>
                  </div>
                </div>
              </div>
              <div class="section">
                <mat-icon>apartment</mat-icon>
                <div class="content">
                  <p class="name">Ámbito funcional</p>
                  <p class="value">{{ row.ambito_funcional }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>groups</mat-icon>
                <div class="content">
                  <p class="name">Representantes</p>
                  <p class="value">• {{ row.sindicatos.join('\n• ') }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>event</mat-icon>
                <div class="content">
                  <p class="name">Publicación</p>
                  <p class="value">{{ row.fecha_publicacion | date }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>event</mat-icon>
                <div class="content">
                  <p class="name">Vigencia</p>
                  <p class="value">{{ row.fecha_vigencia.from | date }} - {{ row.fecha_vigencia.to | date }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>schedule</mat-icon>
                <div class="content">
                  <p class="name">Horas</p>
                  <p class="value">Año: {{ row.horas.year || '-' }}</p>
                  <p class="value">Semana: {{ row.horas.week || '-' }}</p>
                </div>
              </div>
              <!-- <div class="section">
                <mat-icon>event</mat-icon>
                <div class="content">
                  <p class="name">Plazo</p>
                  <p class="value">{{ row.fecha_cierre | date }}</p>
                </div>
              </div>
              <div class="section">
                <mat-icon>euro</mat-icon>
                <div class="content">
                  <p class="name">Presupuesto</p>
                  <p class="value">{{ row.presupuesto | number }} €</p>
                </div>
              </div> -->
            </div>
            <div class="footer">
              <a (click)="openDetail(row.docId)">Más información</a>
              <div class="chips">
                <div
                  class="chip"
                  [ngStyle]="{ 'background-color': getVozColor(voz, '80') }"
                  (mouseover)="changeDisplay(voz.name + '-' + row.cod_convenio, 'block')"
                  (mouseout)="changeDisplay(voz.name + '-' + row.cod_convenio, 'none')"
                  (click)="$event.stopPropagation()"
                  *ngFor="let voz of row.voces"
                >
                  {{ voz.name }}
                  <div
                    [id]="voz.name + '-' + row.cod_convenio"
                    class="tooltip"
                    [ngStyle]="{ background: getVozColor(voz, '') }"
                  >
                    {{ voz.terms.join('&#13;') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </td>
    </ng-container>
    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr> -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <th mat-row *matNoDataRow style="display: flex; align-items: center; gap: 12px; padding: 16px">
      <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
      <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
      <!-- <p style="margin: 0" *ngIf="!loading">No se han encontrado datos</p> -->
    </th>
  </table>
  <mat-paginator
    [pageIndex]="pageIndex"
    pageSize="30"
    (page)="pageEvent($event)"
    fixed
    [ngStyle]="{ opacity: (collectiveAgreementsStateService.collectiveAgreements$ | async).total > 0 ? 1 : 0 }"
  ></mat-paginator>
  <div class="no-data" *ngIf="!loading && (collectiveAgreementsStateService.collectiveAgreements$ | async).total === 0">
    <img src="assets/imgs/404-illustration-no-data.svg" />
    <p class="title">Sin datos</p>
    <p class="description">
      No se han encontrado resultados con los filtros actuales. Prueba a realizar otra búsqueda.
    </p>
  </div>
</div>
