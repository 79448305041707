import { Model } from '@app/models/base/Model';
import { LinechartComponent } from '@modules/br-widgets/components/charts/linechart/linechart.component';
import { ConnectedComponent } from '@modules/br-widgets/components/charts/connected/connected.component';
import { VbchartComponent } from '@modules/br-widgets/components/charts/vbchart/vbchart.component';
import { PiechartComponent } from '@modules/br-widgets/components/charts/piechart/piechart.component';
import { SpiderchartComponent } from '@modules/br-widgets/components/charts/spiderchart/spiderchart.component';
import { WordCloudComponent } from '@modules/br-widgets/components/charts/word-cloud/word-cloud.component';
import { MapComponent } from '@modules/br-widgets/components/charts/map/map.component';
import { LinkPreviewComponent } from '@modules/br-widgets/components/charts/linkpreview/linkpreview.component';
import { LineChartComponent } from '@modules/ng-chart/components/line-chart/line-chart.component';
import { RadarChartComponent } from '@modules/ng-chart/components/radar-chart/radar-chart.component';
import { BarChartComponent } from '@modules/ng-chart/components/bar-chart/bar-chart.component';
import { NgTitleComponent } from '@modules/ng-chart/components/ng-title/ng-title.component';
import { NgGridComponent } from '@modules/ng-chart/components/ng-grid/ng-grid.component';
import { NgPieComponent } from '@modules/ng-chart/components/ng-pie/ng-pie.component';
import { NgPyramidComponent } from '@modules/ng-chart/components/ng-pyramid/ng-pyramid.component';
import { RowLayoutComponent } from '../components/layouts/row-layout.component';
import { ColLayoutComponent } from '../components/layouts/col-layout.component';

export class WidgetConfig extends Model {
  static mappings: Object = {
    line_chart: LinechartComponent,
    connected_chart: ConnectedComponent,
    bar_chart: VbchartComponent,
    pie_chart: PiechartComponent,
    spider_chart: SpiderchartComponent,
    word_cloud: WordCloudComponent,
    map: MapComponent,
    row: RowLayoutComponent,
    col: ColLayoutComponent,
    linkpreview: LinkPreviewComponent,
    'ng-chart-line': LineChartComponent,
    'ng-chart-radar': RadarChartComponent,
    'ng-chart-bar': BarChartComponent,
    'ng-title': NgTitleComponent,
    'ng-grid': NgGridComponent,
    'ng-pie': NgPieComponent,
    'ng-pyramid': NgPyramidComponent,
  };

  config: any;
  constructor(...args: any[]) {
    super(args[0]);
  }

  getConstructor() {
    return WidgetConfig.mappings[this.config.type];
  }
}
