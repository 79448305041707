export const URLQA = 'https://qa.brainyinteligencia.com/api/';
export const URL = 'https://dockerapi01.brainyinteligencia.com/api/';
export const RRSSURL = 'https://devrrss.brainyinteligencia.com/';
export const AUTHURL = 'https://devautentica.brainyinteligencia.com/';
export const PRESSURL = 'https://press.brainyinteligencia.com/';
export const FRONTURL = 'https://qadashboard.brainyinteligencia.com/';
export const CEOEAUTHURL = 'https://ia.ceoe.tech/api/v1/';
export const CEOEURL = 'https://ia.ceoe.tech/api/v1/';
export const TENDERSURL = 'https://tenders.brainyinteligencia.com/api/v1/';
export const SUBVENTIONSURL = 'https://subventions.brainyinteligencia.com/api/v1/';
export const COLLECTIVEAGREEMENTSURL = 'https://collectiveaggrements.brainyinteligencia.com/api/v1/';
export const STATSURL = 'https://test.stats.brainyinteligencia.com/';
export const SCHEDULEURL = 'https://schedule.brainyinteligencia.com/';
export const REGULATORYDRAFTSURL = 'https://drafts.brainyinteligencia.com/api/v2/';
export const CALENDARURL = 'https://calendar.brainyinteligencia.com/';
export const REGULATORYDRAFTSSOCKETSURL = 'wss://socket.brainyinteligencia.com';
export const EMBEDDINGSEUROPEURL = 'https://dockerapi01.brainyinteligencia.com/api/euplenary';
export const CEDROURL = 'https://apisclipping.cedro.org/api/';
export const PPUBLICURL = 'https://ppublic.brainyinteligencia.com/api/v1/';

export const environment = {
  production: true,
  type: 'prod',
  documentItem: URL + 'documents/',
  documentCats: URL + 'documents/cats/',
  documentSearchUrl: URL + 'documents/search/',
  favDocsUrl: URL + 'documents/favorites/',
  documentFavoritesUrl: URL + 'documents/search/favorites/',
  spidersGroupUrl: URL + 'spiderGroups/',
  spidersUrl: URL + 'spiders/',
  topicsUrlLegacy: URL + 'topics/',
  topicsUrlEurovoc: URL + 'topicsEu/',
  regionsUrl: URL + 'regions/',
  userConfig: URL + 'users/me',
  documentSend: URL + 'documents/send_email/',
  linkPreiviewURLService: URL + 'link_preview',
  logoApp: 'assets/imgs/brainy-logo.svg',
  logoMail: 'assets/imgs/avatar.png',
  logoAelec: 'assets/imgs/logo_aelec_calidad.png',
  logoHome: 'assets/imgs/login-logo.png',
  brainSVG: 'assets/imgs/wirebrain.svg',
  helppdf: 'busquedas.pdf',
  mapas: 'https://mapas.brainyinteligencia.com',
  tiles: 'https://tilemap.calistolive.com',
  clippingPPqa: URL + 'documents/pressreport/',
  clippingDigitalPPqa: URL + 'documents/digitalpressreport/',
  clippingDigitalLinksPP: URL + 'documents/linksdigitalreport/',
  clippingDigitalLinksPPqa: URL + 'documents/digitalpressreportlinks/',
  clippingCedroUserAccess: CEDROURL + 'clipping/userAccess/',
  clippingCedroToken: PRESSURL + 'api/v1/trace_data',
  clippingCedroSendTraceability: CEDROURL + 'clipping/report/',
  clippingCedroSendDossier: CEDROURL + 'clipping-control/articulos/',
  recipients: ['rmarin@brainy.technology', 'nperez@brainy.technology', 'nacho.clemente@pp.es', 'jmarin@pp.es'],
  agendas: URL + 'agendas/sources',
  agendasEvents: URL + 'agendas/events',
  agendasFilters: URL + 'agendas/filtros',
  calendarFilters: URL + 'congreso/filtros',
  ceoeToken: CEOEAUTHURL + 'licitaciones/user/token',
  ceoeRefresh: CEOEAUTHURL + 'licitaciones/user/refresh',
  tenders: TENDERSURL + '',
  tendersSearch: TENDERSURL + 'search',
  tendersProvinces: TENDERSURL + 'provincias',
  tendersCpvs: TENDERSURL + 'cpvs',
  tendersOrganos: TENDERSURL + 'organo',
  tendersImports: TENDERSURL + 'importes',
  tendersFavorites: TENDERSURL + 'favoritos',
  tendersSearchs: TENDERSURL + 'busquedas',
  tendersAlerts: TENDERSURL + 'alertas',
  subventions: SUBVENTIONSURL + '',
  subventionsSearch: SUBVENTIONSURL + 'search',
  subventionsExport: SUBVENTIONSURL + 'export',
  subventionsFilters: SUBVENTIONSURL + 'filtros',
  subventionsFavorites: SUBVENTIONSURL + 'favoritos',
  subventionsAlerts: SUBVENTIONSURL + 'alertas',
  collectiveAgreements: CEOEAUTHURL + 'collective-agreements',
  collectiveAgreementsSearch: COLLECTIVEAGREEMENTSURL + 'convenios/',
  collectiveAgreementsFilters: COLLECTIVEAGREEMENTSURL + 'convenios/filters',
  palliativeCaresSearch: URL + 'iniciativas/search',
  palliativeCaresFilters: URL + 'iniciativas/filters',
  palliativeCaresGraphCloud: URL + 'iniciativas/graphcloud',
  palliativeCaresGraphMap: URL + 'iniciativas/graphmap',
  palliativeCaresGraphSpider: URL + 'iniciativas/graphspider',
  palliativeCaresGraphPie: URL + 'iniciativas/graphpie',
  statsIndicators: STATSURL + 'indicadores',
  statsProperty: STATSURL + 'property',
  statsData: STATSURL + 'data',
  statsCategories: STATSURL + 'categorias',
  statsKPIs: STATSURL + 'kpis',
  statsPanel: STATSURL + 'panel',
  sectorReportsTopicsGroups: URL + 'reports/groups',
  sectorReportsCategories: URL + 'reports/topic_group',
  regulatoryDrafts: REGULATORYDRAFTSURL + 'drafts',
  magazineSections: URL + 'section/filtros',
  vigilanciaCalendarPPFilters: PPUBLICURL + 'filtros',
  vigilanciaCalendarPPSearch: PPUBLICURL + 'search',
  vigilanciaCalendarPPAlerts: PPUBLICURL + 'alertas',
  pressUserCredits: PRESSURL + 'stats/credits',
  pressEstimateDossier: PRESSURL + 'drafts/estimate',
  pressGenerate: PRESSURL + 'drafts/generate',
  alertFeedbackStatus: URL + 'documents/status/',
};
