import { CommonModule, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEsp } from '@app/modules/documents/mat-paginator-int';
import { WindowRef } from '@app/modules/documents/services/window.ref.service';
import { MappingIDPipe } from '@app/pipes/mapping-id.pipe';
import { MappingPipe } from '@app/pipes/mapping.pipe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CollectiveAgreementsRoutingModule } from './collective-agreements-routing.module';
import { CollectiveAgreementsComponent } from './collective-agreements.component';
import { CollectiveAgreementsSearchComponent } from './collective-agreements-search/collective-agreements-search.component';
import { CollectiveAgreementsSearchFiltersComponent } from './collective-agreements-search/collective-agreements-search-filters/collective-agreements-search-filters.component';
import { CollectiveAgreementsSearchDetailComponent } from './collective-agreements-search/collective-agreements-search-detail/collective-agreements-search-detail.component';
import { SharedModule } from '@app/modules/shared/shared.module';

registerLocaleData(es);

@NgModule({
  imports: [CommonModule, SharedModule, CollectiveAgreementsRoutingModule],
  declarations: [
    CollectiveAgreementsComponent,
    CollectiveAgreementsSearchComponent,
    CollectiveAgreementsSearchFiltersComponent,
    CollectiveAgreementsSearchDetailComponent,
  ],
  exports: [CollectiveAgreementsSearchFiltersComponent],
  providers: [
    WindowRef,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    MappingPipe,
    MappingIDPipe,
  ],
})
export class CollectiveAgreementsModule {}
