import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SpidersService {
  static readonly itemsUrl = environment.spidersGroupUrl;

  constructor(private http: HttpClient, private injector: Injector) {}

  public get(url) {
    return this.http.get<any>(url, {});
  }
  public getSpiderGroups() {
    return this.http.get<any>('', {});
  }

  public getTopics() {
    const userConfig = this.injector.get(AuthStateService).userConfig;

    if (userConfig) {
      switch (userConfig.interface_config.categories) {
        case 'legacy':
          return this.http.get<any>(environment.topicsUrlLegacy, {});
        case 'eurovoc':
          return this.http.get<any>(environment.topicsUrlEurovoc, {});
      }
    } else {
      return this.http.get<any>(environment.topicsUrlLegacy, {});
    }
  }

  public getRegions() {
    return this.http.get<any>(environment.regionsUrl, {});
  }
}
