import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ElementRef,
  ViewEncapsulation,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'ng-layer-selector',
  templateUrl: './ng-layer-selector.component.html',
  styleUrls: ['./ng-layer-selector.component.scss'],
  host: {
    class: 'ng-layer-selector',
  },
  encapsulation: ViewEncapsulation.None,
})
export class NgLayerSelectorComponent implements OnInit {
  @Input() position: string = 'right';
  @Input() state: string = 'closed';

  constructor(private _elementRef: ElementRef<HTMLElement>) {
    let position = this.position == 'right' ? this.position : 'left',
      state = this.state == 'closed' ? this.state : 'opened';

    _elementRef.nativeElement.classList.add(position);
    _elementRef.nativeElement.classList.add(state);
  }

  ngOnInit() {
    this._elementRef.nativeElement.classList.add('opened');
  }

  onClickToggle() {
    this._elementRef.nativeElement.classList.remove(this.state);
    this.state = this.state == 'closed' ? 'opened' : 'closed';
    this._elementRef.nativeElement.classList.add(this.state);
  }
}
