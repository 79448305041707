import { NgModule, LOCALE_ID } from '@angular/core';
import es from '@angular/common/locales/es';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEsp } from './mat-paginator-int';
import { DocumentsRoutingModule } from './documents-routing.module';
import { ClippingClickDialog, OverviewComponent } from './components/overview/overview.component';
import { DateClickDialog } from './components/overview/overview.component';
import { AlertsViewComponent } from './components/alerts-overview/alerts-overview.component';
import { DialogImg } from './components/docbody/docbody.component';
import { DialogfavComponent } from './components/dialogfav/dialogfav.component';
import { DialogAlertComponent } from './components/dialogalert/dialogalert.component';
import { DialogEmailComponent } from '../../modules/documents/components/dialogemail/dialogemail.component';
import { SubItemsComponent } from './components/sub-items/sub-items.component';
import { WindowRef } from './services/window.ref.service';
import { PdfsComponent } from './components/pdfs/pdfs.component';
import { DocDetailDialog } from './components/report-filter/doc-detail.dialog';
import { CalendarComponent } from './components/calendar/calendar.component';
import { NormFormDialog } from './components/calendar/norm-form.component';
import { InitiativeDetailComponent } from './components/calendar/initiative-detail/initiative-detail.component';
import { ManualFormDialog } from './components/calendar/manual-form.component';
import { MappingPipe } from '@app/pipes/mapping.pipe';
import { MappingIDPipe } from '@app/pipes/mapping-id.pipe';
import { MagazineComponent } from './components/magazine/magazine.component';
import { ThumbComponent } from './components/magazine/thumb/thumb.component';
import { MagazineSettingsComponent } from './components/magazine/magazine-settings/magazine-settings.component';
import { MagazineDraftComponent } from './components/magazine/magazine-draft/magazine-draft.component';
import { MagazineViewComponent } from './components/magazine/magazine-view/magazine-view.component';
import { DocToDraftComponent } from './components/magazine/doc-to-draft/doc-to-draft.component';
import { CustomDocumentComponent } from './components/magazine/custom-document/custom-document.component';
import { NameDialogComponent } from './components/magazine/magazine-draft/name-dialog/name-dialog.component';
import { AgendaComponent } from './components/calendar/agenda.component';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomDateAdapter } from '@app/utils/custom-date-adapter';
import { SearchTypeDialogComponent } from './components/overview/search-type-dialog/search-type-dialog.component';
import { SearchTypeOperatorsDialogComponent } from './components/overview/search-type-operators-dialog/search-type-operators-dialog.component';
import { MappingAllPipe } from '@app/pipes/mapping-all.pipe';
import { SharedModule } from '../shared/shared.module';
import { CalendarRangePresetsComponent } from '@app/components/calendar-range-presets/calendar-range-presets.component';
import { HelpSearchDialogComponent } from '@app/components/help-search-dialog/help-search-dialog.component';
import { MsgyesnoComponent } from '@app/components/msgyesno/msgyesno.component';
import { MagazineViewSendDialogComponent } from './components/magazine/magazine-view/magazine-view-send-dialog/magazine-view-send-dialog.component';
import { ArticledetailArticlesListComponent } from './components/magazine/articledetail/articledetail-articles-list/articledetail-articles-list.component';
import { ThumbEstimatedCostComponent } from './components/magazine/thumb/thumb-estimated-cost/thumb-estimated-cost.component';
import { MagazineCreditsComponent } from './components/magazine/magazine-credits/magazine-credits.component';
import { MagazineDraftEstimatedPriceDialogComponent } from './components/magazine/magazine-draft/magazine-draft-estimated-price-dialog/magazine-draft-estimated-price-dialog.component';
import { VigilanciaCalendarPPComponent } from '../vigilancia/components/vigilancia-calendar-pp/vigilancia-calendar-pp.component';
import { VigilanciaCalendarPPCreateAlertDialogComponent } from '../vigilancia/components/vigilancia-calendar-pp-create-alert-dialog/vigilancia-calendar-pp-create-alert-dialog.component';

registerLocaleData(es);

@NgModule({
  imports: [CommonModule, SharedModule, DocumentsRoutingModule, DragDropModule],
  declarations: [
    OverviewComponent,
    DialogImg,
    DialogfavComponent,
    DialogAlertComponent,
    DateClickDialog,
    ClippingClickDialog,
    AlertsViewComponent,
    DialogEmailComponent,
    SubItemsComponent,
    PdfsComponent,
    DocDetailDialog,
    CalendarComponent,
    AgendaComponent,
    NormFormDialog,
    InitiativeDetailComponent,
    ManualFormDialog,
    MagazineComponent,
    ThumbComponent,
    ThumbEstimatedCostComponent,
    MagazineDraftEstimatedPriceDialogComponent,
    MagazineCreditsComponent,
    MagazineSettingsComponent,
    MagazineDraftComponent,
    MagazineViewComponent,
    MagazineViewSendDialogComponent,
    ArticledetailArticlesListComponent,
    DocToDraftComponent,
    CustomDocumentComponent,
    NameDialogComponent,
    MsgyesnoComponent,
    HelpSearchDialogComponent,
    CalendarRangePresetsComponent,
    SearchTypeDialogComponent,
    SearchTypeOperatorsDialogComponent,
    VigilanciaCalendarPPComponent,
    VigilanciaCalendarPPCreateAlertDialogComponent,
  ],
  exports: [OverviewComponent],
  providers: [
    WindowRef,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp },
    MappingPipe,
    MappingIDPipe,
    MappingAllPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
})
export class DocumentModule { }
