<form [formGroup]="formGroup" class="container">
  <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="12px">
    <mat-toolbar fxFlex="1 0 10%" fxLayoutAlign="end">
      <button mat-icon-button matTooltip="Añadir programación de envío" (click)="addSchedule()" color="primary">
        <mat-icon color="primary">add</mat-icon>
      </button>
      <button mat-icon-button (click)="saveSchedule()" matTooltip="Guardar programaciones" color="primary">
        <mat-icon color="primary">update</mat-icon>
      </button>
    </mat-toolbar>
    <div fxFlex="1 0 90%">
      <div formArrayName="schedules" *ngFor="let item of schedules['controls']; let i = index" fx-item-align>
        <div [formGroupName]="i" class="full-width" fxLayout="row" fxFlexAlign="space-between" fxLayoutGap="15px">
          <app-chips-autocomplete
            [list]="daysList"
            [selectedItems]="item.controls.days.value"
            (itemsUpdatedEvent)="updateItems($event, i)"
            fxFlex="calc(80% - 15px)"
          >
          </app-chips-autocomplete>
          <mat-form-field fxFlex="calc(5% - 15px)" fxFlexAlign="end">
            <mat-label>Hora</mat-label>
            <input
              matInput
              formControlName="hour"
              required
              type="number"
              min="0"
              max="23"
              step="1"
              onchange="if(parseInt(this.value,10)<10)this.value='0'+this.value;"
            />
            <mat-error *ngIf="item.controls.hour.invalid">
              <span *ngIf="item.controls.hour.errors.required">Campo requerido.</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="calc(5% - 15px)" fxFlexAlign="end">
            <mat-label>Minuto</mat-label>
            <input
              matInput
              formControlName="minute"
              required
              type="number"
              min="0"
              max="59"
              step="1"
              onchange="if(parseInt(this.value,10)<10)this.value='0'+this.value;"
            />
            <mat-error *ngIf="item.controls.minute.invalid">
              <span *ngIf="item.controls.minute.errors.required">Campo requerido.</span>
            </mat-error>
          </mat-form-field>
          <button mat-icon-button matTooltip="Eliminar programación" (click)="deleteSchedule(i)" class="delete-button">
            <mat-icon color="primary" fxFlexAlign="center">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
