<div fxLayout="row" fxLayoutAlign="space-between" class="container">
  <div class="" fxFlex="1 0 16%" fxLayoutAlign="start" fxLayoutGap="8px">
    <button
      mat-stroked-button
      mwlCalendarToday
      [(viewDate)]="viewDate"
      (viewDateChange)="viewDateChange.next(viewDate)"
    >
      Hoy
    </button>
    <button
      mat-icon-button
      mwlCalendarPreviousView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="viewDateChange.next(viewDate)"
    >
      <mat-icon matTooltip="Mes anterior">chevron_left</mat-icon>
    </button>
    <button
      mat-icon-button
      mwlCalendarNextView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="viewDateChange.next(viewDate)"
    >
      <mat-icon matTooltip="Mes siguiente">chevron_right</mat-icon>
    </button>
  </div>
  <div class="month-title" fxFlex="1 0 51%" fxLayoutAlign="start">
    {{ viewDate | calendarDate: view + 'ViewTitle':locale | titlecase }}
  </div>
  <div class="" fxFlex="1 0 33%" fxLayoutAlign="end" *ngIf="viewOptions.length > 1">
    <button
      mat-stroked-button
      [matMenuTriggerFor]="menu"
      *ngIf="view === CalendarView.Month && viewOptions.includes('month')"
    >
      Mes <mat-icon>expand_more</mat-icon>
    </button>
    <button
      mat-stroked-button
      [matMenuTriggerFor]="menu"
      *ngIf="view === CalendarView.Week && viewOptions.includes('week')"
    >
      Semana <mat-icon>expand_more</mat-icon>
    </button>
    <button
      mat-stroked-button
      [matMenuTriggerFor]="menu"
      *ngIf="view === CalendarView.Day && viewOptions.includes('day')"
    >
      Día <mat-icon>expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="viewChange.emit(CalendarView.Month)" [class.active]="view === CalendarView.Month">
        Mes (M)
      </button>
      <button mat-menu-item (click)="viewChange.emit(CalendarView.Week)" [class.active]="view === CalendarView.Week">
        Semana (S)
      </button>
      <button mat-menu-item (click)="viewChange.emit(CalendarView.Day)" [class.active]="view === CalendarView.Day">
        Día (D)
      </button>
    </mat-menu>
  </div>
</div>
