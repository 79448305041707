import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

// NGXS
import { NgxsModule } from '@ngxs/store';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

// States
import { AppState } from './state/app/app.state';
import { AuthState } from './modules/login-form/state/auth/auth.state';
import { WidgetState } from './modules/br-widgets/state/widget/widget.state';
import { SnackbarState } from './state/snackbar/snackbar.state';

import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { environment } from '@env/environment';
import { DocumentState } from './modules/documents/store/report/documents.state';
import { InitiativeState } from './modules/documents/store/initiative/initiative.state';
import { MagazineState } from './modules/documents/store/magazine/magazine.state';
import { DraftState } from './modules/documents/store/draft/draft.state';
import { SpiderState } from './modules/shared/store/spider/spider.state';
import { IndicadorState } from './modules/estadisticas/store/indicador/indicador.state';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { TendersState } from './modules/ceoe/store/tenders/tenders.state';
import { SubventionsState } from './modules/ceoe/store/subventions/subventions.state';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { RouterExtService } from './services/routerext.service';
import { CollectiveAgreementsState } from './modules/ceoe/store/collective-agreements/collective-agreements.state';
import { SectorReportsWebSocketService } from './services/sector-reports-web-socket.service';
import { PalliativeCaresState } from './modules/regulatory/state/regulatory.state';
import { LOCALE_ID } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';
import { LottieModule } from 'ngx-lottie';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import localeEs from '@angular/common/locales/es';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomDateAdapter } from './utils/custom-date-adapter';
import { AuthenticationService } from './modules/login-form/services/authentication.service';
import { MainModule } from './modules/main/main.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RoutingModule } from './modules/routing/routing.module';
import { AgendasState } from './modules/documents/store/agendas/agendas.state';
import { CalendarPPState } from './modules/vigilancia/store/calendar-pp/calendar-pp.state';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

moment.locale('es');
registerLocaleData(localeEs);

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    NgxsModule.forRoot(
      [
        AuthState,
        AppState,
        WidgetState,
        SnackbarState,
        DocumentState,
        InitiativeState,
        AgendasState,
        MagazineState,
        DraftState,
        SpiderState,
        IndicadorState,
        TendersState,
        SubventionsState,
        CollectiveAgreementsState,
        PalliativeCaresState,
        CalendarPPState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['userIdentity', 'app', 'widgets', 'subventions', 'collectiveAgreements', 'palliativeCares', 'calendarPP'],
    }),
    NgxsLoggerPluginModule.forRoot(),
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RoutingModule,
    KeycloakAngularModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    MainModule,
    GuidedTourModule,
    LottieModule.forRoot({ player: playerFactory }),
    MatDatepickerModule,
  ],
  providers: [
    RouterExtService,
    GuidedTourService,
    AuthenticationService,
    SectorReportsWebSocketService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    {
      provide: MAT_MOMENT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: () => {
        moment.locale('es');
        moment.updateLocale('es', {
          week: {
            dow: 1,
          },
        });
        return 'es';
      },
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
