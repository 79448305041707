<form [formGroup]="filtersForm">
  <div class="header">
    <p>Filtros</p>
    <a color="primary" (click)="resetFilters()">Restablecer filtros</a>
  </div>
  <p class="loader" *ngIf="loading">
    <mat-spinner [diameter]="20"></mat-spinner>
    Aplicando filtros...
  </p>
  <mat-form-field appearance="fill">
    <mat-label>Búsqueda</mat-label>
    <input matInput formControlName="text" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate formControlName="startDate" placeholder="Fecha inicio" />
      <input matEndDate formControlName="endDate" placeholder="Fecha fin" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker
      #picker
      startView="multi-year"
      (monthSelected)="chosenMonthHandler($event, picker)"
    ></mat-date-range-picker>
  </mat-form-field>
  <div class="categories" formArrayName="categories" *ngIf="!loading">
    <p>Tópico</p>
    <section>
      <button mat-button color="primary" (click)="categoriesSelectAll()">Seleccionar todo</button>
      <button mat-button color="primary" (click)="categoriesDeselectAll()">Eliminar selección</button>
    </section>
    <div class="categories">
      <div
        [style.--category-color]="i < rainbowColors.length ? rainbowColors[i] : rainbowColors[rainbowColors.length - 1]"
        *ngFor="let control of categoriesFormArray.controls; let i = index"
      >
        <mat-checkbox color="primary" [formControl]="control">
          {{ categoriesOptions[i].name }}
        </mat-checkbox>
      </div>
    </div>
  </div>
  <button mat-flat-button color="primary" (click)="search()">Buscar</button>
</form>
