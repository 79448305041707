import { Component, OnInit, AfterViewInit, ChangeDetectorRef, Injector } from '@angular/core';
import { BaseChart } from '@modules/br-widgets/components/charts/base/base.component';
import { linkPreviewService } from '@modules/br-widgets/utils/linkpreview.service';
import { EllipsisPipe } from '@app/pipes/ellipsis.pipe';

export interface previewTab {
  content: any;
  docId: string;
}

@Component({
  selector: 'app-linkpreview',
  templateUrl: './linkpreview.component.html',
  styleUrls: ['./linkpreview.component.less'],
})
export class LinkPreviewComponent extends BaseChart implements OnInit, AfterViewInit {
  linkDocument: any;

  asyncTabs: previewTab[] = [];
  carouselIndex = 0;

  constructor(public injector: Injector, public changeDetector: ChangeDetectorRef) {
    super(injector, changeDetector);
  }

  refresh(config, response) {
    //this.isLoading = true;
    let lenresponse = response.hits.length;
    let i = 0;
    var ellipsis: EllipsisPipe = new EllipsisPipe();

    response.hits.forEach((element, index) => {
      let defaultPreview = {
        title: ellipsis.transform(element.source.titleParsed, 200),
        description: element.source.abstract,
        image: '',
        date: element.source.dateParsed,
        preview: {},
        url: element.source.url,
      };

      if (element.source.docType == 'pdf') {
        defaultPreview.image = 'assets/imgs/pdf_bigcanvas.svg';
        defaultPreview.preview['image'] = 'assets/imgs/pdf_bigcanvas.svg';
        this.asyncTabs.push({ content: defaultPreview, docId: element.id });
        this.changeDetector.detectChanges();
        i++;
        if (i == lenresponse) this.isLoading = false;
      } else {
        this.injector
          .get(linkPreviewService)
          .getPreview(element.source.url, index)
          .subscribe((pre) => {
            defaultPreview.preview = pre;
            this.asyncTabs.push({ content: defaultPreview, docId: element.id });
            this.changeDetector.detectChanges();
            i++;
            if (i == lenresponse) this.isLoading = false;
          });
      }
    });
    this.seriesLoaded.emit('linkpreview');
  }

  onChange($event) {
    this.carouselIndex = $event.index;
  }
  openDocument(docId) {}

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this.wconfig && this.wconfig.config) {
      let config = Object.assign({}, this.wconfig.config['query']);
      config['aggregations'] = this.wconfig.config['aggregations'];
      // remove this
      delete config['dateParsed_start'];
      delete config['dateParsed_end'];
      this.update(config, (config, response) => {
        this.refresh(config, response);
      });
    }
  }
}
