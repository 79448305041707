import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TenderSearch } from '../../models/tender';
import { GetTenderSearchs } from '../../store/tenders/tenders.actions';
import { TendersStateService } from '../../store/tenders/tenders.service';

@Component({
  selector: 'app-tenders-saved-searchs',
  templateUrl: './tenders-saved-searchs.component.html',
  styleUrls: ['./tenders-saved-searchs.component.scss'],
})
export class TendersSavedSearchsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  componentDestroyed$: Subject<boolean> = new Subject();
  displayedColumns: string[] = ['title', 'tools'];
  pageIndex = 0;
  loading = true;

  constructor(public tendersStateService: TendersStateService, private actions: Actions, private router: Router) {
    this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetTenderSearchs)).subscribe(() => {
      this.loading = false;

      setTimeout(() => {
        if (this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.length = this.tendersStateService.tenderSearchs.total;
        }
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    this.pageIndex = 0;
    this.tendersStateService.getTenderSearchs({
      ...this.tendersStateService.tendersFilters,
      page: this.pageIndex + 1,
      size: 30,
    });
  }

  search(row: TenderSearch) {
    this.router.navigate(['tenders', 'search'], { queryParams: { withFiltersFromSavedSearches: row.id } });
  }

  delete(row: TenderSearch) {
    this.tendersStateService.deleteTenderSearch(row.id);
  }

  pageEvent(evt: PageEvent): void {
    this.loading = true;
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.tendersStateService.getTenderSearchs({
      page: this.pageIndex + 1,
      size: 30,
    });
  }
}
