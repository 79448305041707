import { UIStatus } from '@app/state/models/app.state.model';
import { Indicador, IndicadorSearchResult } from '../models/indicador.state.model';

export class AddIndicadorEpa {
  static readonly type = '[AddIndicadorEpa] action';

  constructor(public payload: any) {}
}

export class AddIndicadoresEpa {
  static readonly type = '[AddIndicadoresEpa] action';

  constructor(public payload: Indicador[]) {}
}

export class AddIndicadoresEpaResult {
  static readonly type = '[AddIndicadoresEpaResult] action';

  constructor(public payload: IndicadorSearchResult[]) {}
}

export class AddIndicadorIpc {
  static readonly type = '[AddIndicadorIpc] action';

  constructor(public payload: any) {}
}

export class AddIndicadoresIpc {
  static readonly type = '[AddIndicadoresIpc] action';

  constructor(public payload: Indicador[]) {}
}

export class AddIndicadoresIpcResult {
  static readonly type = '[AddIndicadoresIpcResult] action';

  constructor(public payload: IndicadorSearchResult[]) {}
}

export class UpdateIndicadoresUIStatus {
  static readonly type = '[UpdateIndicadoresUIStatus] action';

  constructor(public payload: UIStatus) {}
}
