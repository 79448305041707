<div class="container" *ngIf="getRealm() === KC_REALM_CEOE">
  <div>
    <p>Error: Permiso denegado</p>
  </div>
  <div>
    <p>El módulo al que intenta acceder no ha sido activado en su usuario.</p>
    <p>
      Si cree que es un error puede reportar su incidencia a través de nuestro portal de soporte
      <a href="https://help.ceoe.tech" target="_blank">https://help.ceoe.tech</a>
    </p>
    <p>
      Si desea contratar un paquete adicional, el administrador de la organización puede hacerlo en el portal de CEOE |
      data <a href="https://ceoe.tech" target="_blank">https://ceoe.tech</a>
    </p>
  </div>
  <div>
    <button mat-raised-button color="primary" routerLink="/">Volver al inicio</button>
  </div>
</div>
<div class="container" *ngIf="getRealm() === KC_REALM_BRAINY">
  <div>
    <p>Error: Permiso denegado</p>
  </div>
  <div>
    <p>El módulo al que intenta acceder no ha sido activado en su usuario.</p>
    <p>
      Si cree que es un error o desea contratar un paquete adicional, contacte con nuestro
      <a href="mailto:rmarin@brainy.technology">soporte técnico</a>
    </p>
  </div>
  <div>
    <button mat-raised-button color="primary" routerLink="/">Volver al inicio</button>
  </div>
</div>
