import { Component, OnInit, ViewChild } from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenavContainer, MatSidenav } from '@angular/material/sidenav';
import { AuthStateService } from '@modules/login-form/state/auth/auth.service';
import { AppStateService } from '@app/state/app/app.service';
import { version } from '../../../../../../package.json';
import { environment } from '@env/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Show } from '@app/state/snackbar/snackbar.actions';
import { UpdateUIStatus } from '@app/state/app/app.actions';
import { UIStatus } from '@app/state/models/app.state.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { TendersStateService } from '@app/modules/ceoe/store/tenders/tenders.service';
import { SubventionsStateService } from '@app/modules/ceoe/store/subventions/subventions.service';
import { map, filter } from 'rxjs/operators';
import { CollectiveAgreementsStateService } from '@app/modules/ceoe/store/collective-agreements/collective-agreements.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;
  @ViewChild(MatSidenav) snav: MatSidenav;

  items: Object[] = [];
  title_menu_show: boolean = true;
  hide_only_title: boolean = false;
  environment = environment;
  url = this.router.url;
  appVersion = version;
  sidenavReservedUrls = ['documents/open'];

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private _actions: Actions,
    private _router: Router,
    public authStateService: AuthStateService,
    public appStateService: AppStateService,
    public tendersStateService: TendersStateService,
    public subventionsStateService: SubventionsStateService,
    public collectiveAgreementsStateService: CollectiveAgreementsStateService,
    private _snackBar: MatSnackBar,
    private _spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.getInitiativesNameMapping();
    this.registerIcons(iconRegistry, sanitizer);

    this.router.events
      .pipe(
        filter((event: unknown): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.slice(1))
      )
      .subscribe(() => {
        this.url = this.router.url;
      });
  }

  ngOnInit() {
    this._actions.pipe(ofActionDispatched(Show)).subscribe((snackData) => {
      this._snackBar.open(snackData.message, snackData.dismissable, {
        duration: snackData.time,
      });
    });

    this._actions.pipe(ofActionDispatched(UpdateUIStatus)).subscribe((status) => {
      switch (status.payload) {
        case UIStatus.loading:
          this._spinner.show('main');
          break;
        default:
          this._spinner.hide('main');
      }
    });
  }

  logout() {
    this.authStateService.logout().subscribe(() => {
      setTimeout(() => {
        window.location.href = '';
        window.location.reload();
      }, 150);
    });
  }

  private registerIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('brain', sanitizer.bypassSecurityTrustResourceUrl(environment.brainSVG));

    iconRegistry.addSvgIcon('brainblack', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/wirebrain_black.svg'));

    iconRegistry.addSvgIcon('solidbrain', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/cerebro.svg'));

    iconRegistry.addSvgIcon('html', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/html.svg'));

    iconRegistry.addSvgIcon('pdf', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/pdf.svg'));

    iconRegistry.addSvgIcon('twitter', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-twitter.svg'));

    iconRegistry.addSvgIcon('alertas', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-alertas.svg'));

    iconRegistry.addSvgIcon('busqueda', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-busqueda.svg'));

    iconRegistry.addSvgIcon(
      'icon-busqueda-fav',
      sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-busqueda-fav.svg')
    );

    iconRegistry.addSvgIcon('icon-digital', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-digital.svg'));

    iconRegistry.addSvgIcon('icon-doc-fav', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-doc-fav.svg'));

    iconRegistry.addSvgIcon('icon-escrita', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-escrita.svg'));

    iconRegistry.addSvgIcon(
      'icon-especializada',
      sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-especializada.svg')
    );

    iconRegistry.addSvgIcon('icon-pass', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-pass.svg'));

    iconRegistry.addSvgIcon('icon-video', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-video.svg'));

    iconRegistry.addSvgIcon('icon-uso', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-uso.svg'));

    iconRegistry.addSvgIcon('salir', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-salir.svg'));

    iconRegistry.addSvgIcon('redes', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/icon-redes.svg'));

    iconRegistry.addSvgIcon('lupa', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/lupa.svg'));

    iconRegistry.addSvgIcon('dashboards', sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/dashboards.svg'));
  }

  togglesubventionsMenuFilters(): void {
    this.subventionsStateService.updateSubventionsMenuFilters({
      ...this.subventionsStateService.filters,
      openedMenu: !this.subventionsStateService.filters.openedMenu,
    });
  }

  toggleCollectiveAgreementsMenuFilters(): void {
    this.collectiveAgreementsStateService.updateCollectiveAgreementsMenuFilters({
      ...this.collectiveAgreementsStateService.filters,
      openedMenu: !this.collectiveAgreementsStateService.filters.openedMenu,
    });
  }

  updatePassword() {
    this.authStateService.updatePassword();
  }

  getInitiativesNameMapping() {
    if (!this.appStateService.initiativeNameMapping || this.appStateService.initiativeNameMapping.length === 0) {
      this.appStateService.getInitiativesNameMapping();
    }
  }

  changeDisplay(elementId: string, display: string): void {
    const element = document.getElementById(elementId);

    if (element) {
      element.style.display = display;
    }
  }

  hasAnySubItemActive(): boolean {
    return document.querySelector('.main-app-sidenav-container mat-sidenav mat-expansion-panel a.active') != null;
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
    this.changeDisplay('view-modules-tooltip', 'none');
  }

  navigateToBuyUrl(url: string) {
    window.open(url, '_blank');
  }

  getCardsFiltered(): any[] {
    return this.authStateService.userConfig.interface_config.menu_items.filter((mi) => mi.active.toString() == 'true');
  }

  hasAnyReservedUrl(): boolean {
    return this.sidenavReservedUrls.some((sru) => window.location.href.includes(sru));
  }

  getMatSidenavContentHeight(): string {
    if (this.router.url.includes('/calendar')) {
      return 'initial';
    } else {
      return '100%';
    }
  }
}
