<div class="container">
  <div class="example-table-container">
    <div class="header" *ngIf="!loading && data.length > 0">
      <h3>
        {{ data[0].template.title }} <span *ngIf="data.length > 0">- {{ data.length }} Informes</span>
      </h3>
      <!-- <button
        mat-flat-button
        style="float: right"
        color="primary"
        class="btn-same-color btn-same-color-primary"
        (click)="goToSectorReport('create')"
      >
        Crear informe
      </button> -->
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="data" class="example-table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let row">
            <div>
              <p>{{ row.title }}</p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>Fecha de creación</th>
          <td mat-cell *matCellDef="let row">
            <div>
              <p>{{ row.created_at }}</p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef>Última actualización</th>
          <td mat-cell *matCellDef="let row">
            <div>
              <p>{{ row.updated_at }}</p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let row">
            <div>
              <button mat-icon-button (click)="$event.stopPropagation(); downloadReport(row)">
                <mat-icon>download</mat-icon>
              </button>
              <button mat-icon-button (click)="$event.stopPropagation(); deleteReport(row)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background: #ededed"></tr>
        <th
          scope="col"
          mat-row
          *matNoDataRow
          class="no-data-row"
          style="display: flex; align-items: center; gap: 12px; padding: 16px"
        >
          <mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
          <p style="margin: 0" *ngIf="loading">Cargando datos...</p>
          <p style="margin: 0" *ngIf="!loading && data.length === 0">No hay datos</p>
        </th>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="row-table"
          (click)="goToSectorReport(row)"
        ></tr>
      </table>
      <mat-paginator
        [pageIndex]="pageIndex"
        [pageSize]="size"
        (page)="pageEvent($event)"
        fixed
        [ngStyle]="{ opacity: total > 0 ? 1 : 0 }"
      ></mat-paginator>
    </div>
  </div>
</div>
