<gridster [options]="options">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div>
      <div class="title" fxLayout="row">
        <h2>{{ title }}</h2>
        <h4 style="margin: 6px 0px 0px 6px; color: gray">Incluye los términos {{ getSearchTerms() }}</h4>
      </div>
    </div>
    <div style="text-align: right">
      <button [disabled]="!statichaschanged" mat-button matSuffix mat-icon-button (click)="undo()">
        <mat-icon
          matTooltip="Deshacer cambios en Dashboard"
          aria-hidden="false"
          aria-label="Deshacer cambios en Dashboard"
          >undo</mat-icon
        >
      </button>
      <button disabled mat-button matSuffix mat-icon-button (click)="save()">
        <mat-icon
          matTooltip="Guardar cambios en Dashboard"
          aria-hidden="false"
          aria-label="Guardar cambios en Dashboard"
          >save</mat-icon
        >
      </button>
    </div>
  </div>
  <mat-progress-bar
    mode="determinate"
    value="{{ percentLoad }}"
    *ngIf="percentLoad > 0 && percentLoad < 99"
  ></mat-progress-bar>
  <gridster-item [item]="item" *ngFor="let item of dashboard" class="griditem {{ item.type }}">
    <div class="fullheight">
      <div class="wrapper2">
        <div class="overlay2" [hidden]="!item.isLoading">
          <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
            <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [strokeWidth]="4"> </mat-progress-spinner>
          </div>
        </div>
        <template #cmp></template>
      </div>
    </div>
  </gridster-item>
</gridster>
