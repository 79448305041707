import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { WidgetConfig } from '@app/modules/br-widgets/models/widget-config';

@Component({
  selector: 'app-chart-container',
  templateUrl: './chart-container.component.html',
  styleUrls: ['./chart-container.component.css'],
})
export class ChartContainerComponent implements OnInit, AfterViewInit {
  @ViewChild('target', { read: ViewContainerRef }) target: ViewContainerRef;
  @Input() wconfig: WidgetConfig;
  @Input() width: number;
  @Input() height: number = 200;
  haswrapper: boolean = true;
  constructor(private cfr: ComponentFactoryResolver) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.wconfig) {
      let factory = this.cfr.resolveComponentFactory(this.wconfig.getConstructor()),
        comp = this.target.createComponent(factory);
      comp.instance['wconfig'] = this.wconfig;
      comp.instance['parent'] = this;
      if (!this.width) {
        let width =
          this.wconfig && this.wconfig['config'] && this.wconfig['config']['width']
            ? parseFloat(this.wconfig['config']['width']) * 0.01
            : 0.33;
      }
    }
  }
}
