<mat-tab-group mat-stretch-tabs animationDuration="0ms" mat-align-tabs="center" (selectedTabChange)="tabChange($event)">
  <mat-tab [label]="tab.label" *ngFor="let tab of tabs"></mat-tab>
</mat-tab-group>
<mat-sidenav-container class="example-container">
  <mat-sidenav
    [opened]="(initiativeStateService.uiStatus$ | async) === UIInitiativeView.list"
    mode="side"
    position="end"
    *ngIf="initiativeStateService.menuFilters.opened"
  >
    <!-- <div *ngIf="(initiativeStateService.uiStatus$ | async) === UIInitiativeView.list" class="toggle-view">
      <button mat-stroked-button matTooltip="Abrir vista calendario" (click)="showCalendar()">
        Mostrar calendario
      </button>
    </div> -->
    <!-- <button mat-mini-fab matTooltip="Nueva norma manual" (click)="openManualDialog()" class="new-manual">
      <mat-icon>feed</mat-icon>
    </button>
    <button mat-mini-fab matTooltip="Seguir nueva norma" (click)="openDialog()" class="new-norm">
      <mat-icon>add</mat-icon>
    </button> -->
    <div #regions style="display: flex; flex-direction: column; align-items: flex-start; margin-bottom: 168px">
      <div class="header">
        <p>Filtros</p>
        <button mat-stroked-button color="primary" (click)="resetFilters()">Restablecer filtros</button>
      </div>
      <form [formGroup]="filtersForm" class="search-reference-container" style="width: calc(100% - 40px)">
        <mat-form-field>
          <mat-label>Búsqueda libre</mat-label>
          <input matInput formControlName="search" />
        </mat-form-field>
        <mat-form-field *ngIf="dropdownRegionsList.length > 0 && selectedRegionsItems[0]?.id != 10">
          <mat-label>Referencia</mat-label>
          <input matInput formControlName="reference" />
        </mat-form-field>
      </form>
      <div>
        <div mat-subheader style="padding-top: 0">Filtro de estado</div>
        <form [formGroup]="filtersForm">
          <mat-radio-group
            formControlName="pending"
            style="display: flex; flex-direction: column; gap: 16px; margin: 10px 16px"
          >
            <mat-radio-button color="primary" [value]="true">En tramitación</mat-radio-button>
            <mat-radio-button color="primary" [value]="false">Concluida</mat-radio-button>
          </mat-radio-group>
        </form>
      </div>
      <div>
        <div mat-subheader>Filtro de sección</div>
        <form
          [formGroup]="filtersForm"
          style="display: flex; flex-direction: column; align-items: flex-start; gap: 16px; margin: 10px 16px"
        >
          <div>
            <mat-checkbox
              color="primary"
              formControlName="0"
              *ngIf="dropdownRegionsList.length === 0 || selectedRegionsItems[0].id == 10"
              >Real Decreto Ley</mat-checkbox
            >
            <mat-checkbox
              color="primary"
              formControlName="0"
              *ngIf="dropdownRegionsList.length > 0 && selectedRegionsItems[0].id != 10"
              >Decreto Ley</mat-checkbox
            >
          </div>
          <mat-checkbox color="primary" formControlName="1">Proyecto de Ley</mat-checkbox>
          <mat-checkbox color="primary" formControlName="2">Proposición de ley</mat-checkbox>
        </form>
      </div>
      <div mat-subheader *ngIf="dropdownRegionsList.length > 0">Filtro geográfico</div>
      <!-- <mat-list-option
        *ngFor="let region of appStateService.region_mappings$ | async"
        [selected]="region.name == (appStateService.selected_regions$ | async)[0].name"
        (click)="selectRegion(region)"
      >
        {{ region.name }}
      </mat-list-option> -->
      <angular2-multiselect
        fixSearchBug
        fixAccents
        id="am2-1"
        class="angular2-multiselect-search angular2-multiselect-auto-width"
        style="width: calc(100% - 32px)"
        name="regions"
        [data]="dropdownRegionsList"
        [(ngModel)]="selectedRegionsItems"
        [settings]="dropdownRegionsSettings"
        *ngIf="dropdownRegionsList.length > 0"
      >
      </angular2-multiselect>
      <div
        style="
          position: fixed;
          bottom: 0;
          background: white;
          padding: 0 6px;
          font-size: 15px;
          font-weight: 500;
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          padding-bottom: 16px;
          width: calc(300px - 43px);
        "
      >
        <button mat-flat-button color="primary" style="width: 100%" (click)="search()">Aplicar filtros</button>
        <div
          style="width: 100%"
          *ngIf="initiativeStateService.menuFilters.opened"
          (click)="initiativeStateService.toggleInitiativeMenuFilters()"
        >
          <button mat-stroked-button color="primary" style="width: 100%">
            <mat-icon style="font-size: 20px; width: 20px; height: 20px">keyboard_double_arrow_right</mat-icon>
            Ocultar menú de filtros
          </button>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div *ngIf="(initiativeStateService.uiStatus$ | async) === UIInitiativeView.calendar; else list">
      <!-- <div
      *ngIf="(initiativeStateService.uiStatus$ | async) === UIInitiativeView.calendar"
      class="toggle-view"
      style="float: right; margin: 12px"
    >
      <button mat-stroked-button matTooltip="Mostrar normas a seguir" (click)="showList()" color="primary">
        Configurar seguimiento
      </button>
    </div> -->
      <calendar-header [(view)]="view" [(viewDate)]="viewDate"> </calendar-header>
      <div [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events"
          [activeDayIsOpen]="activeDayIsOpen"
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="eventClicked($event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events"
          (eventClicked)="eventClicked($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events"
          (eventClicked)="eventClicked($event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
    <button
      mat-mini-fab
      matTooltip="Expandir menú de filtros"
      color="primary"
      *ngIf="!initiativeStateService.menuFilters.opened"
      (click)="initiativeStateService.toggleInitiativeMenuFilters()"
      style="position: fixed; z-index: 999; bottom: 6px; right: 6px"
    >
      <mat-icon>keyboard_double_arrow_left</mat-icon>
    </button>
    <ng-template #list>
      <div>
        <p
          style="font-size: 24px; font-weight: 600; line-height: 100%; color: #1f2937; margin: 16px"
          *ngIf="subItemName"
        >
          {{ subItemName }}
        </p>
        <table mat-table [dataSource]="openInitiativesDataSource" class="initiatives">
          <ng-container matColumnDef="presented">
            <th mat-header-cell *matHeaderCellDef>Presentado</th>
            <td mat-cell *matCellDef="let row">{{ row.presented || row.date | date }}</td>
          </ng-container>
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let row" style="font-weight: 500">{{ row.iniciativa?.titulo || row.title }}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="result">
          <th mat-header-cell *matHeaderCellDef>Resultado</th>
          <td mat-cell *matCellDef="let row">{{ row.result || '' }}</td>
        </ng-container> -->
          <ng-container matColumnDef="tools">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let row">
              <button
                mat-flat-button
                color="primary"
                (click)="$event.stopPropagation(); followInitiative(row)"
                style="width: 100%; transform: scale(0.9)"
                *ngIf="row.siguiendo"
              >
                Dejar de seguir
              </button>
              <button
                mat-button
                color="primary"
                (click)="$event.stopPropagation(); followInitiative(row)"
                style="width: 100%; transform: scale(0.9)"
                *ngIf="!row.siguiendo"
              >
                Seguir
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            style="cursor: pointer"
            (click)="showInitiative(row)"
          ></tr>
        </table>
      </div>
      <mat-paginator
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        (page)="pageEvent($event)"
        style="position: sticky; bottom: 0"
      ></mat-paginator>
      <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#60869b" type="ball-clip-rotate">
        <p style="font-size: 20px; color: #60869b">Cargando...</p>
      </ngx-spinner>
    </ng-template>
  </mat-sidenav-content>
</mat-sidenav-container>
