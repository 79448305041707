import { Injectable, Injector, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CollectiveAgreementsStateService } from '@app/modules/ceoe/store/collective-agreements/collective-agreements.service';
import {
  GetFilters as GetFiltersSubventions,
  GetSubventionsAlerts,
  UpdateSubventionsMenuFilters,
} from '@app/modules/ceoe/store/subventions/subventions.actions';
import {
  GetFilters as GetFiltersCollectiveAgreements,
  UpdateCollectiveAgreementsMenuFilters,
} from '@app/modules/ceoe/store/collective-agreements/collective-agreements.actions';
import { SubventionsStateService } from '@app/modules/ceoe/store/subventions/subventions.service';
import { GetTendersAlerts, UpdateTendersFilters } from '@app/modules/ceoe/store/tenders/tenders.actions';
import { TendersStateService } from '@app/modules/ceoe/store/tenders/tenders.service';
import { Me } from '@app/modules/login-form/state/auth/auth.actions';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { AppDataService } from '@app/services/app-data.service';
import { AppStateService } from '@app/state/app/app.service';
import { environment } from '@env/environment';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthKeycloakGuard extends KeycloakAuthGuard implements OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private authStateService: AuthStateService,
    private subventionsStateService: SubventionsStateService,
    private collectiveAgreementsStateService: CollectiveAgreementsStateService,
    private tendersStateService: TendersStateService,
    private appStateService: AppStateService,
    private injector: Injector,
    private actions: Actions
  ) {
    super(router, keycloak);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // setTimeout(() => {
      if (this.authenticated) {
        this.checkRoles(route, state).then((value) => {
          this.deleteLoadingAnimation();
          resolve(value);
        });
      } else {
        this.keycloak.login();
      }
      // }, 9999999);
    });
  }

  checkRoles(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (state.url === '/home' && this.roles.length > 0) {
        this.login().then(() => {
          resolve(true);
        });
      } else {
        if (route.data.roles.every((role) => this.roles.includes(role))) {
          this.login().then(() => {
            if (state.url.startsWith('/subventions')) {
              this.handleSubventionsGuard().then(() => {
                resolve(true);
              });
            } else if (state.url.startsWith('/tenders')) {
              this.handleTendersGuard().then(() => {
                resolve(true);
              });
            } else if (state.url.startsWith('/collective-agreements')) {
              this.handleCollectiveAgreementsGuard().then(() => {
                resolve(true);
              });
            } else {
              resolve(true);
            }
          });
        } else {
          this.router.navigate(['insufficient-permissions']);
          this.login().then(() => {
            resolve(false);
          });
        }
      }
    });
  }

  login(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.handleLoginData().then(() => {
        resolve(true);
      });
    });
  }

  handleSubventionsGuard(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      zip(
        this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetSubventionsAlerts)),
        this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetFiltersSubventions)),
        this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(UpdateSubventionsMenuFilters))
      ).subscribe(() => {
        this.appStateService.addTitle('Subvenciones');
        resolve(true);
      });

      this.subventionsStateService.getSubventionsAlerts({ page: 1, size: 999, abierta: true });
      this.subventionsStateService.getFilters();
      this.subventionsStateService.updateSubventionsMenuFilters({
        ...this.subventionsStateService.filters,
        openedMenu: this.subventionsStateService.filters.openedMenu,
      });
    });
  }

  handleTendersGuard(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      zip(
        this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetTendersAlerts)),
        this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(UpdateTendersFilters))
      ).subscribe(() => {
        this.appStateService.addTitle('Licitaciones');
        resolve(true);
      });

      this.tendersStateService.getTendersAlerts({ page: 1, size: 999 });
      this.tendersStateService.updateTendersFilters({
        provincias: [],
        publicacion_desde: '01/01/2023',
        publicacion_hasta: null,
        cpvs: [],
        organismo: [],
        expediente: '',
        objeto: [],
        importe_minimo: null,
        importe_maximo: null,
      });
    });
  }

  handleCollectiveAgreementsGuard(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      zip(
        this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(GetFiltersCollectiveAgreements)),
        this.actions.pipe(
          takeUntil(this.componentDestroyed$),
          ofActionSuccessful(UpdateCollectiveAgreementsMenuFilters)
        )
      ).subscribe(() => {
        this.appStateService.addTitle('Convenios colectivos');
        resolve(true);
      });

      this.collectiveAgreementsStateService.getFilters();
      this.collectiveAgreementsStateService.updateCollectiveAgreementsMenuFilters({
        ...this.collectiveAgreementsStateService.filters,
        openedMenu: this.collectiveAgreementsStateService.filters.openedMenu,
      });
    });
  }

  handleLoginData(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.authStateService.userConfig) {
        resolve(true);
      } else {
        this.actions.pipe(takeUntil(this.componentDestroyed$), ofActionSuccessful(Me)).subscribe(() => {
          this.setTopicsUrl();
          resolve(true);
        });

        this.authStateService.me();
      }
    });
  }

  setTopicsUrl(): void {
    const appDataService = this.injector.get(AppDataService);

    appDataService.changeURLS({
      ...appDataService.urlsCopy,
      topics_id: this.injector.get(AuthStateService).userConfig
        ? this.injector.get(AuthStateService).userConfig.interface_config.categories === 'legacy'
          ? environment.topicsUrlLegacy
          : this.injector.get(AuthStateService).userConfig.interface_config.categories === 'eurovoc'
            ? environment.topicsUrlEurovoc
            : ''
        : environment.topicsUrlLegacy,
    });
  }

  deleteLoadingAnimation(): void {
    (document.querySelector('#loading-animation') as HTMLDivElement).style.display = 'none';
  }
}
