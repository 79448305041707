<mat-card *ngIf="cardInfo">
  <mat-card-content>
    <div class="container">
      <div class="img-container columna1" *ngIf="cardInfo.preview.image">
        <img mat-card-image [src]="cardInfo?.preview.image" />
        <div style="text-align: left; width: 100%">{{ cardInfo?.date | date: 'short' }}</div>
      </div>
      <div class="columna2">
        <mat-card-title style="line-height: 28px">{{ cardInfo?.title }}</mat-card-title>
        <mat-card-subtitle>{{ cardInfo?.description }}</mat-card-subtitle>
        <div *ngIf="cardInfo.preview.abstract">{{ cardInfo?.preview.abstract }}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
