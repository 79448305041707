<div *ngIf="doc.docType == 'html'">
  <div class="capturaimg" *ngIf="doc.s3_screenshot">
    <img (click)="openDialog()" width="100%" src="{{ doc.s3_screenshot }}" />
  </div>
  <div class="thebody" [innerHTML]="bodyhtml"></div>
</div>
<div *ngIf="doc.docType == 'pdf'" class="thebody">
  <ngx-extended-pdf-viewer
    #mypdf
    id="mypdf"
    [src]="pdfSrc"
    useBrowserLocale="false"
    delayFirstView="1000"
    height="1600"
    [handTool]="false"
    [showHandToolButton]="true"
    (pagesLoaded)="pagesLoaded()"
    spread="true"
  >
  </ngx-extended-pdf-viewer>
</div>
<ng-content></ng-content>
