import { Component, Input, OnChanges, SimpleChanges, SimpleChange, ChangeDetectorRef, Injector } from '@angular/core';
import { BaseChart } from '@modules/br-widgets/components/charts/base/base.component';
import { WidgetConfig } from '@modules/br-widgets/models/widget-config';
import { Formater } from '@modules/br-widgets/utils/formater';

@Component({
  selector: 'app-vbchart',
  templateUrl: './vbchart.component.html',
  styleUrls: ['./vbchart.component.css'],
})
export class VbchartComponent extends BaseChart implements OnChanges {
  @Input() wconfig?;

  showLegend: boolean = true;
  gradient: string = '';

  constructor(public injector: Injector, private formater: Formater, public changeDetector: ChangeDetectorRef) {
    super(injector, changeDetector);
  }

  refresh(config, response) {
    this.isLoading = true;

    this.results = new Array({ name: 'month', series: this.formater.format(config, response) });

    this.options.xAxis = {
      type: 'category',
      data: this.results[0].series.map((a) => a.name),
    };

    this.options.yAxis = {
      type: 'value',
    };

    this.options.tooltip = {
      show: true,
      trigger: 'axis',
    };

    this.options.series = [
      {
        data: this.results[0].series,
        type: 'line',
      },
    ];

    this.options.dataZoom = [
      {
        id: 'dataZoomX',
        type: 'slider',
        xAxisIndex: [0],
        filterMode: 'filter',
      },
    ];

    this.echartsInstance.setOption(this.options);
    this.isLoading = false;
    this.seriesLoaded.emit('linechart');
  }

  ngOnChanges(changes: SimpleChanges) {
    const wc: SimpleChange = changes.wconfig;
  }

  onChartInit(e) {
    this.echartsInstance = e;
    this.loadData();
  }

  loadData() {
    super.ngAfterViewInit();

    if (this.wconfig && this.wconfig.config) {
      this.xAxisLabel = this.wconfig.config['config']['xAxisLabel'];
      this.yAxisLabel = this.wconfig.config['config']['yAxisLabel'];
      let config = Object.assign({}, this.wconfig.config['query']);
      config['aggregations'] = this.wconfig.config['aggregations'];
      // remove this
      delete config['dateParsed_start'];
      delete config['dateParsed_end'];
      this.update(config, (config, response) => {
        this.refresh(config, response);
      });
    }
  }
}
