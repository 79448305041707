import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertPostRequest, AlertUpdateRequest, TendersFilters } from '@app/modules/ceoe/models/tender';
import { PostAlert, PostSearch, UpdateAlert } from '@app/modules/ceoe/store/tenders/tenders.actions';
import { TendersStateService } from '@app/modules/ceoe/store/tenders/tenders.service';
import { SnackbarStateService } from '@app/state/snackbar/snackbar.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';

@Component({
  selector: 'app-tenders-search-save-search-dialog',
  templateUrl: './tenders-search-save-search-dialog.component.html',
  styleUrls: ['./tenders-search-save-search-dialog.component.scss'],
})
export class TendersSearchSaveSearchDialogComponent {
  saveSearchForm: FormGroup;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<TendersSearchSaveSearchDialogComponent>,
    private tendersStateService: TendersStateService,
    private snackbarStateService: SnackbarStateService,
    private actions: Actions,
    private router: Router
  ) {
    this.saveSearchForm = this.fb.group({
      titulo: [
        this.tendersStateService.alerts.items.find((ts) => ts.id === Number(this.data.withFiltersFromAlerts))?.titulo ??
          '',
        Validators.required,
      ],
    });
  }

  save(): void {
    this.saveSearchForm.markAllAsTouched();

    if (this.saveSearchForm.valid) {
      this.loading = true;

      if (!this.data.withFiltersFromAlerts) {
        this.actions.pipe(ofActionSuccessful(PostAlert)).subscribe(() => {
          this.close();
          this.loading = false;
          this.snackbarStateService.show('Alerta guardada correctamente', 3000, 'cerrar');

          this.router.navigate(['tenders', 'search'], {
            queryParams: {
              withFiltersFromAlerts:
                this.tendersStateService.alerts.items[this.tendersStateService.alerts.items.length - 1].id,
            },
          });
        });

        this.tendersStateService.postAlert(this.getRequest() as AlertPostRequest);
      } else {
        this.actions.pipe(ofActionSuccessful(UpdateAlert)).subscribe(() => {
          this.close();
          this.loading = false;
          this.snackbarStateService.show('Alerta guardada correctamente', 3000, 'cerrar');
        });

        this.tendersStateService.updateAlert(this.getRequest() as AlertUpdateRequest, this.data.withFiltersFromAlerts);
      }
    }
  }

  getRequest(): any {
    return {
      titulo: this.saveSearchForm.controls.titulo.value,
      cpvs: this.tendersStateService.tendersFilters.cpvs,
      expediente: this.tendersStateService.tendersFilters.expediente,
      objeto: this.tendersStateService.tendersFilters.objeto,
      organismo: this.tendersStateService.tendersFilters.organismo,
      provincias: this.tendersStateService.tendersFilters.provincias,
      publicacion_desde: this.tendersStateService.tendersFilters.publicacion_desde,
      publicacion_hasta: this.tendersStateService.tendersFilters.publicacion_hasta,
      importes: [
        {
          de: this.tendersStateService.tendersFilters.importe_minimo,
          hasta: this.tendersStateService.tendersFilters.importe_maximo,
        },
      ],
    };
  }

  close(): void {
    this.matDialogRef.close();
  }
}
