<div class="example-container-parent">
  <div class="example-container">
    <p class="title">Mis búsquedas</p>

    <mat-tab-group mat-stretch-tabs animationDuration="0ms" mat-align-tabs="center" (selectedTabChange)="setItems()">
      <mat-tab *ngFor="let tab of tabs">
        <ng-template mat-tab-label>
          <span [attr.id]="'fav-search-tab-' + tab.identifier">{{ tab.label }}</span>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <div class="example-table-container" *ngIf="moduleSelected">
      <table mat-table [dataSource]="allItems[moduleSelected]" class="example-table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nombre de la búsqueda</th>
          <td mat-cell matRipple *matCellDef="let row" (click)="doSearch(row)">
            <div class="abstractRow">
              {{ row.name || row.titulo }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="origin">
          <th mat-header-cell *matHeaderCellDef>Origen</th>
          <td mat-cell *matCellDef="let row">
            <div class="abstractRow">{{ row.origen }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Creación</th>
          <td mat-cell *matCellDef="let row">{{ row.created_at | date }}</td>
        </ng-container>

        <ng-container matColumnDef="tools">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngIf="
                row.alert == undefined &&
                moduleSelected !== 'licitaciones' &&
                moduleSelected !== 'ayudas_y_subvenciones' &&
                moduleSelected !== 'ppublic'
              "
              type="button"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Alert"
              matTooltip="Crear Alerta"
              (click)="manAlert(row.id, $event, 'create')"
              class="greybutton"
            >
              <mat-icon fontSet="material-icons-outlined">notifications_off</mat-icon>
            </button>
            <button
              *ngIf="
                row.alert != undefined &&
                moduleSelected !== 'licitaciones' &&
                moduleSelected !== 'ayudas_y_subvenciones'
              "
              type="button"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Alert"
              color="primary"
              matTooltip="Modificar Alerta"
              (click)="manAlert(row.id, $event, 'edit')"
              class="greenbutton"
            >
              <mat-icon>notifications_active</mat-icon>
            </button>
            <button
              id="fav-search-execute-btn"
              type="button"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Buscar"
              matTooltip="Ejecutar búsqueda"
              (click)="doSearch(row)"
              class="greybutton"
            >
              <mat-icon>send</mat-icon>
            </button>
            <button
              id="fav-search-delete-btn"
              type="button"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Editar"
              matTooltip="Borrar búsqueda"
              (click)="openDialog(row.id)"
              class="greybutton"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <th
          scope="col"
          mat-row
          *matNoDataRow
          class="no-data-row"
          style="display: flex; align-items: center; gap: 12px; padding: 16px"
        >
          <mat-spinner [diameter]="24" *ngIf="isLoadingResults"></mat-spinner>
          <p style="margin: 0; font-weight: 500" *ngIf="isLoadingResults">Cargando datos...</p>
          <p style="margin: 0; font-weight: 500" *ngIf="!isLoadingResults && allItems[moduleSelected].length === 0">
            No hay búsquedas favoritas para el módulo seleccionado
          </p>
        </th>
      </table>
    </div>

    <mat-paginator [length]="allItems[moduleSelected].length" [pageSize]="200" *ngIf="moduleSelected"></mat-paginator>
  </div>
</div>
