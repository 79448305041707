import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { Show } from './snackbar.actions';
import { SnackbarState } from './snackbar.state';

@Injectable({ providedIn: 'root' })
export class SnackbarStateService {
  @Select(SnackbarState)
  public data$: Observable<SnackbarState>;

  constructor() {}

  @Dispatch()
  public show = (title: string, time?: number, dismissable?: string) => {
    return new Show(title, time, dismissable);
  };
}
