<ng-container *ngFor="let item of menuItems">
  <mat-accordion
    [id]="'container-accordion-' + item.header"
    class="container-accordion-ceoe"
    *ngIf="!mapaview && isAnySubitemActive(item)"
  >
    <mat-expansion-panel [expanded]="true" [disabled]="true">
      <mat-expansion-panel-header *ngIf="item.id != 99" [ngClass]="{ 'expansion-panel-header-hidden': true }">
        <!-- [ngClass]="{ 'expansion-panel-header-hidden': hideModuleTitle() }" -->
        <mat-panel-title
          [ngStyle]="{
            color:
              utilsService.getComputedStyle('--light-mode') === 'false'
                ? 'var(--tailwind-color-white)'
                : 'var(--tailwind-color-gray-900)'
          }"
        >
          {{ item.header }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-accordion
        [id]="'container-accordion-' + subitem.title"
        class="container-accordion-ceoe container-accordion-ceoe-child"
        [ngClass]="{
          'container-accordion-ceoe-child-light-mode-true': utilsService.getComputedStyle('--light-mode') === 'true'
        }"
        *ngFor="let subitem of item.items; let indexChild = index"
      >
        <mat-expansion-panel
          [expanded]="!subitem.items || subitem.items?.length === 0"
          [disabled]="!subitem.items || subitem.items?.length === 0"
        >
          <mat-expansion-panel-header
            *ngIf="item.id != 99"
            [ngStyle]="{
              'padding-bottom': !subitem.items || subitem.items?.length === 0 ? '0' : '16px',
              'padding-top': indexChild === 0 ? '0' : '24px'
            }"
            [ngClass]="{ 'expansion-panel-header-hidden': !subitem.items || subitem.items?.length === 0 }"
          >
            <!-- [ngClass]="{ 'expansion-panel-header-hidden': hideModuleTitle() }" -->
            <mat-panel-title
              [ngStyle]="{
                color:
                  utilsService.getComputedStyle('--light-mode') === 'false'
                    ? 'var(--tailwind-color-white)'
                    : 'var(--tailwind-color-gray-900)'
              }"
            >
              {{ subitem.title }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            mat-list-item
            target="{{ subitem.target }}"
            [attr.href]="subitem.target === '_blank' ? subitem.path : null"
            [routerLink]="subitem.target !== '_blank' ? subitem.path : null"
            [class.active]="isRouterLinkActive(subitem.path)"
            (click)="routerLinkClick(subitem.path)"
            *ngIf="!subitem.items || subitem.items?.length === 0"
          >
            <mat-icon
              *ngIf="!subitem.svg"
              mat-list-icon
              color="primary"
              [ngStyle]="{
                color:
                  utilsService.getComputedStyle('--light-mode') === 'false'
                    ? 'var(--tailwind-color-gray-300)'
                    : 'var(--color-primary-main)'
              }"
              >{{ subitem.icon }}</mat-icon
            >
            <mat-icon
              *ngIf="subitem.svg"
              mat-list-icon
              color="primary"
              svgIcon="{{ subitem.svg }}"
              [ngStyle]="{
                color:
                  utilsService.getComputedStyle('--light-mode') === 'false'
                    ? 'var(--tailwind-color-gray-300)'
                    : 'var(--color-primary-main)'
              }"
            ></mat-icon>
            <span
              mat-line
              [ngStyle]="{
                color:
                  utilsService.getComputedStyle('--light-mode') === 'false'
                    ? 'var(--tailwind-color-gray-300)'
                    : 'var(--tailwind-color-gray-700)'
              }"
              >{{ subitem.title }}</span
            >
          </a>
          <ng-container *ngIf="subitem.items && subitem.items?.length > 0">
            <a
              mat-list-item
              target="{{ subsubitem.target }}"
              [attr.href]="subsubitem.target === '_blank' ? subsubitem.path : null"
              [routerLink]="subsubitem.target !== '_blank' ? subsubitem.path : null"
              [class.active]="isRouterLinkActive(subsubitem.path)"
              (click)="routerLinkClick(subsubitem.path)"
              *ngFor="let subsubitem of subitem.items"
            >
              <mat-icon
                *ngIf="subsubitem.icon"
                mat-list-icon
                color="primary"
                [ngStyle]="{
                  color:
                    utilsService.getComputedStyle('--light-mode') === 'false'
                      ? 'var(--tailwind-color-gray-300)'
                      : 'var(--color-primary-main)'
                }"
                >{{ subsubitem.icon }}</mat-icon
              >
              <mat-icon
                *ngIf="subsubitem.svg && !subsubitem.icon"
                mat-list-icon
                color="primary"
                svgIcon="{{ subsubitem.svg }}"
                [ngStyle]="{
                  color:
                    utilsService.getComputedStyle('--light-mode') === 'false'
                      ? 'var(--tailwind-color-gray-300)'
                      : 'var(--color-primary-main)'
                }"
              ></mat-icon>
              <span
                mat-line
                [ngStyle]="{
                  color:
                    utilsService.getComputedStyle('--light-mode') === 'false'
                      ? 'var(--tailwind-color-gray-300)'
                      : 'var(--tailwind-color-gray-700)'
                }"
                >{{ subsubitem.title }}</span
              >
            </a>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-expansion-panel>
  </mat-accordion>

  <ng-layer-selector *ngIf="mapaview">
    <ng-layer-selector-item *ngFor="let layer of visible_layers" [layer]="layer" (onToggle)="onChange(layer)">
    </ng-layer-selector-item>
    <button mat-mini-fab (click)="newLayer()" style="position: absolute; bottom: 10px; left: 5px" color="primary">
      <mat-icon>add</mat-icon>
    </button>
  </ng-layer-selector>
</ng-container>
