import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { MappingPipe } from '@app/pipes/mapping.pipe';
import { MappingIDPipe } from '@app/pipes/mapping-id.pipe';

const PRESSIDS = ['3338', '3321', '3339', '3340', '3341', '3327'];
const DIGITALIDS = [
  '1633',
  '894',
  '3395',
  '915',
  '3396',
  '695',
  '697',
  '696',
  '1629',
  '1365',
  '3394',
  '1364',
  '1366',
  '698',
  '3397',
  '700',
  '699',
  '3398',
  '1363',
  '1362',
  '3399',
  '1630',
  '3400',
];

@Component({
  selector: 'app-press-spiders-select',
  templateUrl: './press-spiders-select.component.html',
  styleUrls: ['./press-spiders-select.component.scss'],
})
export class PressSpidersSelectComponent implements OnInit, OnChanges {
  @Input() selectedSpiders: string[];
  @Output() spiders: EventEmitter<{ escrita: string[]; digital: string[] }> = new EventEmitter<{
    escrita: string[];
    digital: string[];
  }>();

  press_spiders: string[];
  digital_spiders: string[];
  pressList: string[];
  digitalList: string[];
  press: string[];
  digital: string[];
  isAllDigitalItemsSelected = false;
  isAllPressItemsSelected = false;

  hintPress: string = 'Seleccione las fuentes de prensa escrita.';
  hintDigital: string = 'Seleccione las fuentes de prensa digital.';
  label: string = 'Fuentes';

  hidePress = false;
  hideDigital = false;

  constructor(
    private _mappingPipe: MappingPipe,
    private _mappingIDPipe: MappingIDPipe,
    private _authStateService: AuthStateService
  ) {
    if (this._authStateService.userConfig.config.Prensa.Spiders.escrita)
      this.press_spiders = this._mapSpiders(
        this._authStateService.userConfig.config.Prensa.Spiders.escrita.map((s) => s.id.toString())
      );
    else this.press_spiders = this._mapSpiders(PRESSIDS);

    if (this._authStateService.userConfig.config.Prensa.Spiders.digital)
      this.digital_spiders = this._mapSpiders(
        this._authStateService.userConfig.config.Prensa.Spiders.digital.map((s) => s.id.toString())
      ).sort();
    else this.digital_spiders = this._mapSpiders(DIGITALIDS).sort();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._bootstrapSpiders();
  }

  ngOnInit(): void {
    this.press = this.pressList;
    this.digital = this.digitalList.sort();
  }

  updatePressItems(event) {
    this.press = event.map((name: string) => this._mappingIDPipe.transform(name));
    this._emitSpiders();
  }

  updateDigitalItems(event) {
    this.digital = event.map((name: string) => this._mappingIDPipe.transform(name));
    this._emitSpiders();
  }

  private _emitSpiders() {
    this.spiders.emit({ escrita: this.press, digital: this.digital });
  }

  private _mapSpiders(spiderList: string[]): string[] {
    return JSON.parse(JSON.stringify(spiderList)).map((spider: number) => this._mappingPipe.transform(spider));
  }

  private _bootstrapSpiders() {
    if (this.selectedSpiders) {
      this.pressList = this.press = this._mapSpiders(this.selectedSpiders).filter((x) =>
        this.press_spiders.includes(x)
      );
      this.digitalList = this.digital = this._mapSpiders(this.selectedSpiders).filter((x) =>
        this.digital_spiders.includes(x)
      );
    }

    this._emitSpiders();
  }

  allPressItemsSelected(evt): void {
    this.isAllPressItemsSelected = evt;
  }

  allDigitalItemsSelected(evt): void {
    this.isAllDigitalItemsSelected = evt;
  }
}
