import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BusinessStatisticsComponent } from './business-statistics.component';
import { BusinessStatisticsSectionComponent } from './business-statistics-section/business-statistics-section.component';
import { BusinessStatisticsSectionDetailComponent } from './business-statistics-section/business-statistics-section-detail/business-statistics-section-detail.component';

export const BusinessAtatisticsRoutes: Routes = [
  { path: '', component: BusinessStatisticsComponent },
  { path: ':themeId', component: BusinessStatisticsSectionComponent },
  { path: ':themeId/:categoryId', component: BusinessStatisticsSectionDetailComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(BusinessAtatisticsRoutes)],
  exports: [RouterModule],
})
export class BusinessStatisticsRoutingModule {}
