import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-calendar-range-presets',
  templateUrl: './calendar-range-presets.component.html',
  styleUrls: ['./calendar-range-presets.component.scss'],
})
export class CalendarRangePresetsComponent {
  @Input() startDate: string;
  @Input() endDate: string;
}
