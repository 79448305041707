import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SectorReportsService } from '../../services/sector-reports.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStateService } from '@app/state/app/app.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SectorReportsListSectionDeleteDialogComponent } from './sector-reports-list-section/sector-reports-list-section-delete-dialog/sector-reports-list-section-delete-dialog.component';

@Component({
  selector: 'app-sector-reports-list',
  templateUrl: './sector-reports-list.component.html',
  styleUrls: ['./sector-reports-list.component.scss'],
})
export class SectorReportsListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  data = [];
  displayedColumns = ['card'];
  size = 30;
  pageIndex = 0;
  total = 0;
  loading = true;
  params;
  item;

  constructor(
    public appStateService: AppStateService,
    private authStateService: AuthStateService,
    private sectorReportsService: SectorReportsService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.appStateService.addTitle('Informes sectoriales');
    this.appStateService.addHistoryUrl(undefined);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.pageIndex = 0;

    this.route.params.subscribe((params) => {
      this.params = params;
      this.getData();
    });
  }

  ngOnDestroy(): void {
    this.appStateService.addHistoryUrl(undefined);
  }

  pageEvent(evt: PageEvent): void {
    this.loading = true;
    this.pageIndex = evt.pageIndex;
    this.paginator.pageIndex = this.pageIndex;
    this.getData();
  }

  getData(): void {
    this.item = this.authStateService.userConfig.interface_config.menu_items
      .find((mi) => mi.id === 25)
      .items.find((i: any) => i.id == this.params.id);

    const request: any = { page: this.pageIndex + 1, size: this.size, categoryId: this.params.id };

    this.sectorReportsService.getTemplates(request).subscribe((data) => {
      this.loading = false;

      setTimeout(() => {
        this.data = data.items;

        if (this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.length = data.total;
          this.total = data.total;
        }
      }, 0);
    });
  }

  deleteTemplate(row: any): void {
    const dialogRef = this.dialog.open(SectorReportsListSectionDeleteDialogComponent, {
      width: '600px',
      data: {
        title: `Eliminar plantilla - ${row.title}`,
        desc: 'Escribe la palabra BORRAR dentro del texto para confirmar la eliminación. TODOS los reportes serán BORRADOS.',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sectorReportsService.deleteTemplate({ id: row.id }).subscribe((data) => {
          this.loading = true;
          this.getData();
        });
      }
    });
  }

  viewSectorReports(id: string): void {
    this.router.navigate(['sector-reports', 'list', this.params.id, id, 'reports']);
  }

  goToSectorReport(id: string): void {
    this.router.navigate(['sector-reports', 'list', this.params.id, id]);
  }
}
