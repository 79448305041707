<mat-toolbar>
  <mat-menu #filter="matMenu">
    <mat-form-field>
      <mat-label>Período de búsqueda</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Fecha inicio" />
        <input matEndDate formControlName="end" placeholder="Fecha fin" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </mat-menu>
  <div class="header" *ngIf="draftID">
    <div class="text">
      <p class="title" style="height: auto">{{ magazineStateService.selectedMagazine.name }}</p>
      <p class="desc" style="display: none">Editar borrador</p>
    </div>
  </div>
  <div class="header" *ngIf="!draftID">
    <div class="text">
      <p class="title">{{ magazineStateService.selectedMagazine.name }}</p>
      <p class="desc">Nuevo borrador</p>
    </div>
    <p class="chip-cost">Coste {{ downloadCost | number: '1.2-2' }}€</p>
  </div>
  <span class="draft-spacer"></span>
  <button mat-flat-button color="primary" class="right-draft-icon" (click)="saveDraft()">
    <mat-icon>save</mat-icon>
    Guardar borrador
  </button>
  <button
    mat-flat-button
    matTooltip="Generar revista"
    class="right-draft-icon"
    (click)="showMagazine()"
    color="primary"
    *ngIf="draftID"
  >
    <mat-icon>send</mat-icon>
    Generar revista
  </button>
  <button
    mat-flat-button
    matTooltip="Descartar borrador"
    class="right-draft-icon"
    (click)="deleteDraft()"
    color="warn"
    *ngIf="draftID"
  >
    <mat-icon>delete</mat-icon>
    Descartar borrador
  </button>
</mat-toolbar>
<div class="container" cdkDropListGroup>
  <div *ngFor="let search of categories; let i = index; last as isParentLast">
    <mat-accordion>
      <mat-expansion-panel [expanded]="search.items?.length > 0 ? true : false">
        <mat-expansion-panel-header class="category" collapsedHeight="50px" expandedHeight="50px">
          <mat-panel-title>
            {{ search.category }} <span>({{ search.items.length }} elementos)</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list
          #list
          dense
          role="list"
          cdkDropList
          [cdkDropListData]="search.items"
          (cdkDropListDropped)="drop($event)"
        >
          <mat-list-option
            color="primary"
            role="listitem"
            class="mat-list-item cdk-drag-preview-magazine-draft"
            style="height: auto !important"
            cdkDrag
            *ngFor="let item of search.items; last as isLast"
            [value]="item"
          >
            <div
              style="display: flex; flex-direction: row; align-items: center; gap: 16px; opacity: 0.75"
              *ngIf="!draftID"
            >
              <!-- <div>Coste de descarga: {{ item.cost | number: '1.2-2' }}€</div> -->
              <!-- <div>Coste de disposición: {{ item.provision | number: '1.2-2' }}€</div> -->
              <div
                style="
                  border-radius: 4px;
                  background: var(--tailwind-color-yellow-100);
                  color: var(--tailwind-color-yellow-600);
                  font-weight: 500;
                  font-size: 13px;
                  width: 72px;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
                *ngIf="item.cost && item.provision === 0"
              >
                Coste: {{ item.cost | number: '1.2-2' }}€
              </div>
              <div
                style="
                  border-radius: 4px;
                  background: var(--tailwind-color-green-100);
                  color: var(--tailwind-color-green-600);
                  font-weight: 500;
                  font-size: 13px;
                  width: 72px;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
                *ngIf="item.provision && item.provision > 0"
              >
                Pagado
              </div>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="4px" style="flex: 1">
              <strong fxFlex="1 1 100%" style="display: flex; align-items: center" class="headline">
                <button
                  mat-icon-button
                  color="primary"
                  class="edit-button"
                  matTooltip="Editar título de la noticia"
                  (click)="edit($event, item)"
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <span
                  style="
                    flex: 1;
                    margin: 0 6px;
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--tailwind-color-gray-700);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                  "
                  [matTooltip]="item.title"
                >
                  {{ item.title }}
                </span>
                <span style="font-size: 11px; font-weight: 300; color: var(--tailwind-color-gray-500)">
                  {{ item.date | date }}
                </span>
              </strong>
              <div fxFlex="1 1 100%" style="display: flex; align-items: center">
                <div
                  class="abstract"
                  style="font-size: 11px; font-weight: 400; color: var(--tailwind-color-gray-500)"
                  [matTooltip]="item.abstract"
                >
                  {{ item.abstract }}
                </div>
                <!-- <button
                  *ngIf="draftID || item.docType !== 'custom'"
                  mat-icon-button
                  color="primary"
                  matTooltip="Detalle noticia"
                  (click)="detail($event, item)"
                  class="detail"
                >
                  <mat-icon>search</mat-icon>
                </button> -->
              </div>
              <div fxFlex="1 1 100%" style="display: flex; align-items: center; gap: 16px">
                <span class="source">{{ item.source | mapping }}</span>
                <p
                  style="margin: 0; font-weight: 500; color: var(--color-primary-main); text-decoration: underline"
                  (click)="$event.stopPropagation(); detail($event, item)"
                  *ngIf="(item.docType !== 'custom' && item.provision && item.provision > 0) || draftID"
                >
                  Ver artículo
                </p>
              </div>
            </div>
            <ng-template *cdkDragPreview>
              <div class="task-preview">{{ item.title }} | {{ item.source }}</div>
            </ng-template>
            <mat-divider *ngIf="!isLast"></mat-divider>
          </mat-list-option>
        </mat-selection-list>
        <mat-action-row>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
            <div>
              <!-- <button mat-flat-button matTooltip="Añadir nueva noticia de forma manual" (click)="manual(search)">
                Nueva noticia
                <mat-icon>article</mat-icon>
              </button> -->
              <!-- <button
                mat-flat-button
                matTooltip="Añadir noticias de otras fechas a la categoría"
                [matMenuTriggerFor]="addNews"
              >
                Más noticias
                <mat-icon>add</mat-icon>
              </button> -->
              <mat-menu #addNews="matMenu">
                <mat-form-field>
                  <mat-label>Rango de fechas</mat-label>
                  <mat-date-range-input [formGroup]="addRange" [rangePicker]="addPicker">
                    <input matStartDate formControlName="rangeStart" placeholder="Fecha inicio" />
                    <input matEndDate formControlName="rangeEnd" placeholder="Fecha fin" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="addPicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #addPicker></mat-date-range-picker>
                </mat-form-field>
                <button
                  color="primary"
                  mat-icon-button
                  matTooltip="Añadir noticias"
                  matSuffix
                  (click)="addCategoryNews(search, i)"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </mat-menu>
              <span class="draft-spacer"></span>
            </div>
            <div *ngIf="search.items?.length > 0 ? true : false">
              <button mat-flat-button matTooltip="Eliminar selección" (click)="list.selectAll()">
                Seleccionar todo
                <mat-icon>done_all</mat-icon>
              </button>
            </div>
            <div *ngIf="list.selectedOptions.selected.length > 0">
              Opciones seleccionadas {{ list.selectedOptions.selected.length }}
              <button mat-icon-button matTooltip="Eliminar selección" (click)="list.selectedOptions.clear()">
                <mat-icon>cancel</mat-icon>
              </button>
              <button mat-icon-button matTooltip="Eliminar selección del borrador" (click)="hide(list.selectedOptions)">
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
            </div>
            <div *ngIf="checkHistory(search.category)">
              <button mat-flat-button matTooltip="Deshacer" (click)="undoHide(search.category)">
                Deshacer
                <mat-icon>undo</mat-icon>
              </button>
            </div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-divider *ngIf="!isParentLast"></mat-divider>
  </div>
</div>
