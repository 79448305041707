import {
  Alert,
  AlertsGetResponse,
  Cpv,
  Import,
  Province,
  Search,
  Tender,
  TenderSearchsGetResponse,
  TendersFilters,
  TendersGetResponse,
} from '../../models/tender';

export class GetTenders {
  static readonly type = '[GetTenders] action';
  constructor(public payload: TendersGetResponse) {}
}

export class GetTender {
  static readonly type = '[GetTender] action';
  constructor(public payload: Tender) {}
}

export class GetTendersFavorites {
  static readonly type = '[GetTendersFavorites] action';
  constructor(public payload: TendersGetResponse) {}
}

export class UpdateTendersFilters {
  static readonly type = '[UpdateTendersFilters] action';
  constructor(public payload: TendersFilters) {}
}

export class UpdateTenderFavorite {
  static readonly type = '[UpdateTenderFavorite] action';
  constructor(public payload: number) {}
}

export class PostSearch {
  static readonly type = '[PostSearch] action';
  constructor(public payload: Search) {}
}

export class GetProvinces {
  static readonly type = '[GetProvinces] action';
  constructor(public payload: Province[]) {}
}

export class GetCpvs {
  static readonly type = '[GetCpvs] action';
  constructor(public payload: Cpv[]) {}
}

export class GetCpv {
  static readonly type = '[GetCpv] action';
  constructor(public payload: Cpv) {}
}

export class GetImports {
  static readonly type = '[GetImports] action';
  constructor(public payload: Import[]) {}
}

export class GetTendersAlerts {
  static readonly type = '[GetTendersAlerts] action';
  constructor(public payload: AlertsGetResponse) {}
}

export class DeleteAlert {
  static readonly type = '[DeleteAlert] action';
  constructor(public payload: number) {}
}

export class PostAlert {
  static readonly type = '[PostAlert] action';
  constructor(public payload: Alert) {}
}

export class UpdateAlert {
  static readonly type = '[UpdateAlert] action';
  constructor(public payload: Alert) {}
}

export class GetTenderSearchs {
  static readonly type = '[GetTenderSearchs] action';
  constructor(public payload: TenderSearchsGetResponse) {}
}

export class DeleteTenderSearch {
  static readonly type = '[DeleteTenderSearch] action';
  constructor(public payload: number) {}
}

export class ToggleTendersMenuFilters {
  static readonly type = '[ToggleTendersMenuFilters] action';
}

export class ToggleTendersMenu {
  static readonly type = '[ToggleTendersMenu] action';
}
