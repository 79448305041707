import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, Injector } from '@angular/core';
import { AppDataService } from '@app/services/app-data.service';
import { BaseChart } from '@modules/br-widgets/components/charts/base/base.component';
import rainbowColorsJson from '@assets/rainbow-colors.json';
import { UtilsService } from '@app/services/utils.service';
import { AuthStateService } from '@app/modules/login-form/state/auth/auth.service';

@Component({
  selector: 'app-spiderchart',
  templateUrl: './spiderchart.component.html',
  styleUrls: ['./spiderchart.component.css'],
})
export class SpiderchartComponent extends BaseChart implements OnInit {
  @ViewChild('radar_container') radarcontainer: ElementRef;
  @Input() width: number = 0;
  @Input() height: number = 0;

  options: any;
  RADAR: any;
  rainbowColors: string[] = this.utilsService.extendArray(rainbowColorsJson, 10);

  constructor(
    public injector: Injector,
    public changeDetector: ChangeDetectorRef,
    private authStateService: AuthStateService,
    private utilsService: UtilsService
  ) {
    super(injector, changeDetector);
  }

  format(config, response) {
    let res = [];
    if (
      !response ||
      !response.aggregations ||
      !response.aggregations.aggr_topic_id ||
      !response.aggregations.aggr_topic_id.buckets ||
      !config ||
      !config.aggregations ||
      !config.aggregations.length
    ) {
      return res;
    }

    res = response.aggregations.aggr_topic_id.buckets.map((item) => {
      return {
        name: this.injector.get(AppDataService).map(config.aggregations[0].map_name_with, item.key),
        value: item.doc_count,
      };
    });
    let aux = {};
    res.forEach((item) => {
      if (!aux[item.name] && typeof item.name == 'string') aux[item.name] = [];
      if (typeof item.name == 'string') aux[item.name].push(item.value);
    });

    res = Object.keys(aux).map((key) => {
      return {
        name: key,
        value: Math.max(...aux[key]),
      };
    });

    return res;
  }

  refresh(config, response) {
    let data = this.format(config, response),
      indicator = [],
      values = [],
      max: number = 0;
    data.forEach((item) => {
      max = item.value > max ? item.value : max;
      values.push(item.value);
    });

    data.forEach((item) => {
      indicator.push({ name: item.name, max: max + max * 0.25 });
    });

    this.options['tooltip'] = {
      trigger: 'item',
    };

    this.options['radar'] = {
      name: {
        textStyle: {
          color: '#000',
        },
        formatter: function (value, indicator) {
          let w = 20;
          let t = value.split(' ');
          let l = 0;
          let o = '';

          for (var tw of t) {
            if (l + tw.length >= w) {
              o += (l ? '\n' : '') + tw + ' ';
              l = tw.length + 1;
            } else {
              o += tw + ' ';
              l += tw.length + 1;
            }
          }
          return o;
        },
      },
      indicator: indicator,
    };

    const userEchartsColors =
      this.authStateService.userConfig.interface_config.company.color_palette?.echarts?.split(',') ??
      this.rainbowColors;

    this.options['series'] = [
      {
        type: 'radar',
        data: [
          {
            value: values,
            name: 'Categorias',
            itemStyle: {
              color: userEchartsColors[0],
            },
          },
        ],
      },
    ];

    this.echartsInstance.setOption(this.options);
    this.isLoading = false;
    this.seriesLoaded.emit('spider');
  }

  ngOnInit() {}

  onChartInit(e) {
    this.echartsInstance = e;
    this.loadData();
  }

  loadData() {
    super.ngAfterViewInit();
    let config = Object.assign({}, this.wconfig.config['query']);
    config['aggregations'] = this.wconfig.config['aggregations'];
    // remove this
    delete config['dateParsed_start'];
    delete config['dateParsed_end'];
    this.update(config, (config, response) => {
      this.refresh(config, response);
    });
  }
}
