<div class="tenders-theme">
  <mat-toolbar
    style="z-index: 2; background: var(--color-background-toolbar)"
    *ngIf="!(authStateService.userConfig$ | async)"
  >
    <button mat-icon-button (click)="tendersStateService.toggleTendersMenu()" style="color: white">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 style="color: white">Licitaciones</h1>
  </mat-toolbar>
  <mat-sidenav-container
    class="sidenav-container full"
    [ngClass]="{ 'sidenav-container-space-top': !(authStateService.userConfig$ | async) }"
    fullscreen
  >
    <mat-sidenav #snav mode="side" [disableClose]="true" [opened]="tendersStateService.openedMenu">
      <mat-nav-list>
        <img src="assets/imgs/ceoe/logo_ceoedata-white.svg" />
        <mat-list-item
          role="listitem"
          (click)="selectItem(sidenavItem)"
          [ngClass]="{ selected: sidenavItem.selected }"
          [routerLink]="['/tenders/' + sidenavItem.path]"
          *ngFor="let sidenavItem of sidenavItems"
        >
          <mat-icon mat-list-icon color="accent">
            {{ sidenavItem.icon }}
          </mat-icon>
          <p mat-line color="accent">{{ sidenavItem.name }}</p>
        </mat-list-item>
        <!-- <div class="user-data" *ngIf="authStateServiceCeoe.userConfig$ | async">
          <p>{{ (authStateServiceCeoe.userConfig$ | async).name }}</p>
          <p>{{ (authStateServiceCeoe.userConfig$ | async).email }}</p>
        </div> -->
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
      <button
        mat-mini-fab
        matTooltip="Expandir menú de filtros"
        color="primary"
        *ngIf="
          !(tendersStateService.openedMenuTendersFilters$ | async) &&
          url.startsWith('/tenders/search') &&
          !url.startsWith('/tenders/search/')
        "
        (click)="tendersStateService.toggleTendersMenuFilters()"
        style="position: fixed; z-index: 999; bottom: 6px; right: 6px"
      >
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>
    </mat-sidenav-content>
    <mat-sidenav
      #snav
      mode="side"
      position="end"
      [disableClose]="true"
      [opened]="tendersStateService.openedMenuTendersFilters$ | async"
      *ngIf="url.startsWith('/tenders/search') && !url.startsWith('/tenders/search/')"
      class="search-filters-sidenav"
    >
      <app-tenders-search-filters></app-tenders-search-filters>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
