import { Component, OnInit } from '@angular/core';
import { KC_REALM_BRAINY, KC_REALM_CEOE, getRealm } from '@app/init/keycloak-init.factory';

@Component({
  selector: 'app-tenders-support',
  templateUrl: './tenders-support.component.html',
  styleUrls: ['./tenders-support.component.scss'],
})
export class TendersSupportComponent implements OnInit {
  getRealm = getRealm;
  KC_REALM_CEOE = KC_REALM_CEOE;
  KC_REALM_BRAINY = KC_REALM_BRAINY;

  constructor() {}

  ngOnInit(): void {}
}
