import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.scss'],
})
export class CustomSnackBarComponent implements OnInit {
  clippingMapping: { [k: string]: string } = {
    '=1': '# elemento seleccionado.',
    other: '# elementos seleccionados.',
  };

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _snackBarRef: MatSnackBarRef<CustomSnackBarComponent>
  ) {}

  ngOnInit(): void {}

  doAction() {
    this._snackBarRef.dismissWithAction();
    // you can do some action here
  }

  cancel() {
    this._snackBarRef.dismiss();
  }
}
